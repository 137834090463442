import VisibilityIcon from '@mui/icons-material/Visibility';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableButton } from '@silinfo/front-end-template';
import OnClickDownloadButton from '../../pages/Administration/Documents/OnClickDownloadButton';
import { endpoints } from '../../utils/endPoints';
import instance from '../../api';
import { useState } from 'react';
import CircularProgess from '@mui/material/CircularProgress';

export default function OperationCellRenderer(params: GridRenderCellParams) {
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        instance
            .get(`${endpoints.students.document.download.replace('{id}', params.row.id as string)}`, {
                responseType: 'blob',
            })
            .then((response) => {
                window.open(URL.createObjectURL(response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item>
                <TableButton color="primary" tooltip="Dokumentum előnézete" onClick={handleClick}>
                    {loading ? <CircularProgess size={24} /> : <VisibilityIcon />}
                </TableButton>
            </Grid>
            <Grid item>
                <OnClickDownloadButton
                    filename={params.row.fileName || 'dokumentum.pdf'}
                    url={`${endpoints.students.document.download.replace('{id}', params.row.id as string)}`}
                />
            </Grid>
        </Grid>
    );
}
