import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { TextField } from '@silinfo/front-end-template';

const Input = ({
    label,
    helperText,
    error,
    ...params
}: AutocompleteRenderInputParams & { label: string; helperText?: string; error?: boolean }) => (
    <TextField {...params} label={label} helperText={helperText} error={error} />
);

export default Input;
