// eslint-disable-next-line regex/invalid-warn
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage, TableEditButton, Tooltip } from '@silinfo/front-end-template';
import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MCCLoading from '../../../components/MCCLoading';
import approvalsService from '../../../services/administration/approvals';
import { create } from '../../../store/notification';
import { Option } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import ApprovalTableEditButton from './ApprovalTableEditButton';
import ChangesView from './ChangesView';
import { ForeignScholarshipSemesterStatusForm } from '../../../studentPages/Profile/Tabs/SemesterStatusDialog';
import { RootState } from '../../../store';
import useFetch from '../../../utils/useFetch';
import { endpoints } from '../../../utils/endPoints';
import { SESSION_KEY } from './utils';
import { TooltipRenderer } from '../../../utils/AppConstElements';
import { useTenant } from '../../../components/TenantContext';

export interface ApprovalTableRow {
    id: number;
    student: string;
    typeOrig: string;
    type: string;
    status: string;
    statusOrig: string;
    reason: string;
    requestData: ForeignScholarshipSemesterStatusForm;
    date: string;
}

const initCountries: Option[] = [];
export const CountryContext = createContext(initCountries);

function BtnCellRenderer({
    row,
    refreshing,
}: GridRenderCellParams<unknown, ApprovalTableRow> & { refreshing: () => void }) {
    const { user } = useSelector((state: RootState) => state.auth);
    if (row.statusOrig !== 'waiting') {
        return <ChangesView row={row} />;
    }

    return (
        <>
            {!user.archive ? (
                <ApprovalTableEditButton
                    setTableLoading={refreshing}
                    opener={<TableEditButton sx={{ marginRight: '5px' }} tooltip="Szerkesztés" />}
                    objectId={row.id}
                />
            ) : null}
            <ChangesView row={row} />
        </>
    );
}

export default function Approvals() {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const countries = useFetch<Option[]>(endpoints.masterData.students.countries.main, '');
    const tenant = useTenant();
    const refreshing = () => setRefresh(!refresh);

    const [options, setOptions] = useState<{ statuses: Option[]; types: Option[]; guestTenant: Option[] }>({
        statuses: [],
        types: [],
        guestTenant: [
            { label: 'Igen', value: '1' },
            { label: 'Nem', value: '0' },
        ],
    });

    useEffect(() => {
        setLoading(true);
        axios
            .all([approvalsService.filterDataStatuses(), approvalsService.filterDataTypes()])
            .then((res) => {
                setOptions((prev) => {
                    return {
                        ...prev,
                        statuses: res[0].data,
                        types: res[1].data,
                    };
                });
            })
            .catch(() =>
                dispatch(
                    create({
                        type: 'error',
                        message: 'Sikertelen betöltés',
                    }),
                ),
            )
            .finally(() => setLoading(false));
    }, [dispatch]);

    if (loading || countries.loading) {
        return <MCCLoading />;
    }

    const header = {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_approvals_list]: 'Jóváhagyások',
        },
        title: 'Jóváhagyások',
        project: tenant || 'TAR',
    };

    return (
        <CountryContext.Provider value={countries?.data}>
            <FormikListPage
                header={header}
                refresh={refresh}
                service={approvalsService}
                autoload
                responseKey="data"
                sessionKey={SESSION_KEY}
                defaultSort={{ date: 'desc' }}
                filter={{
                    fields: [
                        {
                            name: 'student',
                            label: 'Hallgató',
                            format: { xs: 12, md: 4, lg: 4 },
                        },
                        {
                            name: 'type',
                            label: 'Feladattípus',
                            type: 'multiselect',
                            options: options.types,
                            format: { xs: 12, md: 4, lg: 4 },
                        },
                        {
                            name: 'datef',
                            label: 'Kérelem időpontja (-tól)',
                            format: { xs: 12, md: 3, lg: 2 },
                            type: 'datetime-local',
                        },
                        {
                            name: 'datet',
                            label: 'Kérelem időpontja (-ig)',
                            format: { xs: 12, md: 3, lg: 2 },
                            type: 'datetime-local',
                        },
                        {
                            name: 'status',
                            label: 'Státusz',
                            type: 'multiselect',
                            options: options.statuses,
                            format: { xs: 12, md: 4, lg: 4 },
                        },
                        {
                            name: 'guestTenant',
                            label: 'Vendég tenant',
                            type: 'multiselect',
                            options: options.guestTenant,
                            format: { xs: 12, md: 4, lg: 4 },
                        },
                    ],
                }}
                columns={[
                    {
                        headerName: 'Hallgató',
                        field: 'student',
                        renderHeader: () => {
                            return (
                                <>
                                    <div
                                        style={{
                                            background: 'transparent',
                                            minWidth: '10px',
                                            height: '100%',
                                            marginLeft: '-10px',
                                            marginRight: '8px',
                                        }}
                                    />
                                    {'Hallgató'}
                                </>
                            );
                        },
                        renderCell: (params: GridRenderCellParams) => {
                            if (params.row?.tenant) {
                                return (
                                    <>
                                        <Tooltip title={`${params.row.tenant} tenanthoz tartozó hallgató`}>
                                            <div
                                                style={{
                                                    background: '#35A899',
                                                    minWidth: '10px',
                                                    height: '100%',
                                                    marginLeft: '-10px',
                                                    marginRight: '8px',
                                                }}
                                            />
                                        </Tooltip>
                                        {TooltipRenderer(params.row?.student)}
                                    </>
                                );
                            }

                            return (
                                <>
                                    <div
                                        style={{
                                            background: 'transparent',
                                            minWidth: '10px',
                                            height: '100%',
                                            marginLeft: '-10px',
                                            marginRight: '8px',
                                        }}
                                    />
                                    {TooltipRenderer(params.row?.student)}
                                </>
                            );
                        },
                    },
                    { headerName: 'Feladattípus', field: 'type' },
                    { headerName: 'Kérelem időpontja', field: 'date' },
                    { headerName: 'Státusz', field: 'status', minWidth: 100 },
                    {
                        headerName: 'Műveletek',
                        field: 'operations',
                        renderCell: (params: GridRenderCellParams) => (
                            <BtnCellRenderer {...params} refreshing={refreshing} />
                        ),
                        sortable: false,
                        minWidth: 150,
                    },
                ].map((col) => ({ minWidth: 250, flex: 1, ...col }))}
            />
        </CountryContext.Provider>
    );
}
