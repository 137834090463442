import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Dispatch, SetStateAction } from 'react';
import EndpointProvider from '../../../../components/EndpointProvider';
import { QuestionnaireProgram, QuestionnaireTemplateType, TYPE_QUESTIONNAIRE, TYPE_SUMMARY } from '../utils';
import ActivationSwitch from './ActivationSwitch';
import PreviewButton from './Preview';
import UpdateTemplateDialog from './UpdateTemplateDialog';

export type OperationsCellRendererProps = {
    setRefresh: Dispatch<SetStateAction<boolean>>;
    params: GridRenderCellParams<unknown, QuestionnaireProgram>;
    type: QuestionnaireTemplateType;
};

const OperationsCellRenderer = (props: OperationsCellRendererProps) => {
    const { setRefresh, params, type } = props;
    const questionnaireTemplateId =
        type === TYPE_QUESTIONNAIRE
            ? params.row.questionnaireTemplate?.id
            : params.row.questionnaireTemplateSummary?.id;

    let searchType = '';
    if (type === TYPE_QUESTIONNAIRE) {
        searchType = 'self_evaluation_questionnaire_' + params.row.semesterStatus;
    } else if (type === TYPE_SUMMARY) {
        searchType = 'self_evaluation_questionnaire_summary';
    }

    return (
        <>
            <EndpointProvider endpoints={['self_evaluation_edit']}>
                <UpdateTemplateDialog setRefresh={setRefresh} row={params.row} searchType={searchType} type={type} />
            </EndpointProvider>
            {questionnaireTemplateId ? <PreviewButton id={questionnaireTemplateId} /> : null}
            <EndpointProvider endpoints={['self_evaluation_edit']}>
                <ActivationSwitch {...props} />
            </EndpointProvider>
        </>
    );
};

export default OperationsCellRenderer;
