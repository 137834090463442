import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import DependentSelectInputGenerator from '../../components/Form/DependentSelectInputGenerator';
import { FieldGenerator } from '../../components/Form/FieldGenerator';
import { Option } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import { createOptions, inputProps } from '../MasterData/General/Students/Form/AddressInput/utils';
import { TBaseData } from './Courses/BaseData/types';
import GoogleMapsAutocomplete from './Courses/Events/GoogleMapsAutocomplete';
import { ILocation } from './Courses/Events/types';

export default function Address({
    isOutside,
    campusOptions,
    campus,
    readonly = false,
}: {
    isOutside: boolean;
    campusOptions: Option[];
    campus?: string;
    readonly?: boolean;
}) {
    const location = useLocation().pathname;

    const formikProps = useFormikContext<TBaseData>();
    const { values, setFieldValue } = formikProps;
    const input = useMemo(() => inputProps(isOutside, location), [isOutside, location]);
    const setter = useCallback(
        (location: ILocation) => {
            if (location) {
                setFieldValue('externalLocationName', location);
            }
        },
        [setFieldValue],
    );

    useEffect(() => {
        //casting campus to int so the input tyoe change can be done
        const campus = formikProps.values.campus;
        if (typeof campus === 'string') {
            formikProps.setFieldValue('campus', parseInt(campus));
        }
    }, [formikProps]);

    return !isOutside ? (
        <>
            <DependentSelectInputGenerator
                parentInput={{ ...input, format: { xs: 12, sm: 6 } }}
                endpoint={`${endpoints.courseManagement.courseList.campuses}`}
                keyOfResponse={''}
                optionCreator={createOptions}
                disabled={readonly}
                campus={campus}
            />
        </>
    ) : (
        <>
            {FieldGenerator({
                name: 'campus',
                label: 'Campus',
                type: 'multiselect',
                options: campusOptions,
                fieldType: 'base',
                format: { xs: 12, sm: 6 },
                props: {
                    multiple: false,
                    isOptionEqualToValue: (option: Option, value: string) => option.value == value,
                    onChange: (_: unknown, value: Option) => formikProps.setFieldValue('campus', value?.value || ''),
                    disabled: readonly,
                },
                ...formikProps,
            })}
            <Grid item xs={12}>
                <GoogleMapsAutocomplete
                    setter={setter}
                    disabled={readonly}
                    initialValue={{
                        ...(values.externalLocationName || {}),
                        description: (values.externalLocationName as Record<string, string>)?.location || '',
                    }}
                    error={'' + (formikProps.errors.externalLocationName || '')}
                />
            </Grid>
        </>
    );
}
