import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import useOpen from '../../../../utils/useOpen';
import { ClosingDialogProps } from './types';
import { useEffect } from 'react';

const unclosableStatus = ['init', 'processing'];

const openStatus = [...unclosableStatus, 'check_error_unknown', 'close_error_unknown', 'close_ok', 'check_ok'];
const successStatus = ['close_ok', 'check_ok'];
const errorStatus = ['close_error_unknown', 'check_error_unknown'];

export default function InformDialog({ checkResponse, isClosing, setPageLoading }: ClosingDialogProps) {
    const { isOpen, close, open } = useOpen(true);

    useEffect(() => {
        if (checkResponse && openStatus.includes(checkResponse.status)) {
            open();
        }
    }, [checkResponse, open]);

    if (!checkResponse) return null;

    const statusText = successStatus.includes(checkResponse.status) ? 'success' : 'warning';
    const process = isClosing ? 'szemeszterzárás' : 'ellenőrzés';

    return (
        <Dialog open={openStatus.includes(checkResponse?.status) && isOpen} maxWidth="md" fullWidth>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DialogContent style={{ paddingTop: '12px', paddingBottom: 0 }}>
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs={12}>
                                <Alert
                                    severity={errorStatus.includes(checkResponse.status) ? 'error' : statusText}
                                    variant="filled"
                                    sx={{ color: 'white' }}
                                >
                                    {(errorStatus.includes(checkResponse.status) && checkResponse.error) ||
                                        (successStatus.includes(checkResponse.status)
                                            ? `Sikeres ${process}!`
                                            : `${
                                                  process[0].toUpperCase() + process.slice(1)
                                              } folyamatban, kérlek, várj...`)}
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>
                                {[...successStatus, ...errorStatus].includes(checkResponse.status) ? null : (
                                    <>{checkResponse.process ? checkResponse.process + '%' : 'Inicializálás...'}</>
                                )}
                                {unclosableStatus.includes(checkResponse.status) ? (
                                    <LinearProgress
                                        variant={
                                            checkResponse.status === 'processing' ? 'determinate' : 'indeterminate'
                                        }
                                        value={checkResponse.status === 'processing' ? checkResponse.process : 100}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <Button
                            variant="contained"
                            disabled={unclosableStatus.includes(checkResponse.status)}
                            onClick={() => {
                                close();
                                if (isClosing) {
                                    setPageLoading(true);
                                    window.location.reload();
                                }
                            }}
                        >
                            Bezárás
                        </Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}
