import Grid from '@mui/material/Grid';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';
import FinanceTable from '../FinanceTable';
import { IMeasurements } from './types';
import { columns } from './utils';

interface ScholarshipsTableProps {
    currency?: string;
}
export default function ScholarshipsTable(props: ScholarshipsTableProps) {
    const { currency } = props;
    const { data, loading } = useFetch<IMeasurements[]>(endpoints.students.finances.scholarships.paid);

    return (
        <Grid item xs={12} md={8}>
            <FinanceTable
                title="Kifizetett ösztöndíjak"
                rows={data}
                columns={columns(currency)}
                loading={loading}
                currency={currency}
            />
        </Grid>
    );
}
