import Templates from '../../pages/Administration/QuestionnaireTemplate';
import TemplatesForm from '../../pages/Administration/QuestionnaireTemplate/Form';
import GroupFromTemplate from '../../pages/Administration/QuestionnaireTemplate/GroupForm';

export const questionnaireTemplates = {
    path: 'questionnaire-templates',
    children: [
        {
            path: '',
            element: <Templates />,
            name: 'questionnaire_templates',
        },
        {
            path: 'new',
            element: <TemplatesForm />,
            name: 'questionnaire_templates_new',
        },
        {
            path: ':id/edit',
            element: <TemplatesForm />,
            name: 'questionnaire_templates_edit',
        },
        {
            path: 'new-group',
            element: <GroupFromTemplate />,
            name: 'questionnaire_groups_new',
        },
        {
            path: ':id/edit-group',
            element: <GroupFromTemplate />,
            name: 'questionnaire_groups_edit',
        },
    ],
};
