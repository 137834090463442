import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { fixDateRangeFields } from '../../utils/AppConst';
import { IForm } from '../../utils/Interfaces/interfaces';
import { IPageForm } from '../../pages/Administration/Pages/types';
import axios from 'axios';

export interface IPagesVersionsForm {
    content: {
        hu: {
            content: string;
        };
        en: {
            content: string;
        };
    };
    startDate: string;
}

export const initialPagesVersionsForm = {
    content: {
        hu: {
            content: '',
        },
        en: {
            content: '',
        },
    },
    startDate: '',
};

const pagesService = {
    //...crudTemplate<IPagesVersionsForm>(endpoints.administration.pages.versions), // Verziók
    getActivePrivacyStatement: () => API.get(endpoints.administration.pages.getActivePrivacyStatement),
    getActivePrivacyStatementAdmission: () =>
        axios.get(endpoints.administration.pages.getActivePrivacyStatementAdmission),
    setPrivacyStatement: (id: number) => API.post(endpoints.administration.pages.setPrivacyStatement, { id }),
    filterPages: (form: IForm) => API.get(endpoints.administration.pages.main, { params: fixDateRangeFields(form) }),
    getVersion: (pageId: string | number, versionId: string | number) =>
        API.get(endpoints.administration.pages.versions.replace('{pageId}', pageId.toString()) + '/' + versionId),
    getPage: (pageId: string | number) => API.get(endpoints.administration.pages.main + '/' + pageId),
    filterVersions: (pageId: number | string, form: IForm) =>
        API.get(endpoints.administration.pages.versions.replace('{pageId}', pageId.toString()), {
            params: fixDateRangeFields(form),
        }),
    createPage: (form: IPageForm) => API.post(endpoints.administration.pages.new, form),
    createVersion: (pageId: number | string, form: IPagesVersionsForm) =>
        API.post(endpoints.administration.pages.versions.replace('{pageId}', pageId.toString()), form),
    updateVersion: (pageId: number | string, id: string | number, form: IPagesVersionsForm) =>
        API.patch(endpoints.administration.pages.versions.replace('{pageId}', pageId.toString()) + '/' + id, form),
    deleteVersion: (pageId: number | string, id: string | number) =>
        API.delete(endpoints.administration.pages.versions.replace('{pageId}', pageId.toString()) + '/' + id),
    restoreVersion: (pageId: number | string, id: string | number) =>
        API.post(endpoints.administration.pages.versions.replace('{pageId}', pageId.toString()) + '/' + id),
};

export default pagesService;
