import Error from '@mui/icons-material/Error';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { createInitialValues } from '@silinfo/front-end-template';
import { Children, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import instance from '../../../../../api';
import { FieldGenerator } from '../../../../../components/Form/FieldGenerator';
import Form from '../../../../../components/Form/Form';
import { RootState } from '../../../../../store';
import { IForm } from '../../../../../utils/Interfaces/interfaces';
import { theme } from '../../../../../utils/theme';
import DataTable from './DataTable';
import { DetailedFormSection } from './interfaces';
import ExtraStudentForm from './ExtraStudentForm';
import CertificateInstituteAutocomplete from './CertificateInstituteAutocomplete';
import { FormikProps } from 'formik';
import { IStudentsForm } from '../../../../../services/masterData/students';
import { Option } from '../../../../../utils/AppConst';

interface SectionProps {
    section: DetailedFormSection;
    loading: boolean;
    activeSection: number;
    setActiveSection: (section: number) => void;
    number: number;
    readOnly?: boolean;
}

export const exceptionFields = ['jobCountry', 'internshipCountry', 'hostCountry'];

export type SectionRow = { id: string; [key: string]: string | Record<string, string> };

export const getFileName = (row: SectionRow) => {
    const f = Object.entries(row).find(([key]) => key.toLowerCase().includes('filename'));
    return f ? f[1] : '';
};

export const getFilePath = (row: SectionRow) => {
    const f = Object.entries(row).find(([key]) => key.toLowerCase().includes('filepath'));
    return f && typeof f[1] === 'string' ? f[1]?.split('/')[1] : '';
};

export function newRow(row: SectionRow) {
    const result = Object.entries(row).find(([key]) => exceptionFields.includes(key));

    if (result) {
        return {
            [result[0]]: typeof result[1] === 'string' ? result[1] : result[1]?.id || '',
        };
    }
    return {};
}

const SectionGenerator = ({ section, loading, activeSection, setActiveSection, number, readOnly }: SectionProps) => {
    const id = useParams().id as string;
    const [row, setRow] = useState<SectionRow | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(false);
    const { count } = useSelector((state: RootState) => state.loading);
    const { user } = useSelector((state: RootState) => state.auth);

    const hasError = () => {
        if (!section.formikProps) return false;
        return section.fields.some(
            (field) =>
                Object.keys({ ...section.formikProps.errors, ...(section.formikProps.status?.errors || {}) }).includes(
                    field.name,
                ) && Object.keys(section.formikProps.touched).includes(field.name),
        );
    };

    if (loading) return <Skeleton height={35} />;

    if (!section.name) return section.custom || null;

    return (
        <Accordion expanded={activeSection === number} onChange={() => setActiveSection(number)}>
            <AccordionSummary
                expandIcon={
                    <ExpandMore
                        style={{
                            fontSize: 'xx-large',
                            color: theme.palette.secondary.contrastText,
                        }}
                    />
                }
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                }}
            >
                {section.name} {hasError() && <Error style={{ marginLeft: '2px' }} color="error" />}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    {section.custom ? (
                        section.custom
                    ) : !section.formikProps ? (
                        <>
                            {!readOnly && !user.archive && (
                                <Grid item xs={12} justifyContent="flex-end">
                                    <Button color="success" variant="contained" onClick={() => setOpen(!open)}>
                                        {section.name} hozzáadása
                                    </Button>

                                    {(open || !!row) && (
                                        <Dialog open={open || !!row}>
                                            <DialogTitle>
                                                {section.name + (row ? ' szerkesztése' : ' hozzáadása')}
                                            </DialogTitle>
                                            <Form
                                                fields={[]}
                                                onSubmit={async (form: IForm) => {
                                                    await instance.post(
                                                        section.url.split('/').slice(0, -1).join('/') +
                                                            (row ? '/' + row.id : ''),
                                                        form,
                                                    );
                                                    setOpen(false);
                                                    setRow(undefined);
                                                    setRefresh((prev) => !prev);
                                                }}
                                                submitTransformData={(form) =>
                                                    Object.entries(form).reduce(
                                                        (prev, [key, value]: [string, unknown]) => ({
                                                            ...prev,
                                                            student: id,
                                                            [key]: Array.isArray(value)
                                                                ? value.map((elem) => elem?.value || elem)
                                                                : (value as Option)?.value || value,
                                                        }),
                                                        {} as IForm,
                                                    )
                                                }
                                                hideButtons
                                                initialValues={
                                                    row
                                                        ? {
                                                              ...row,
                                                              ...newRow(row),
                                                              uuidFileName: getFilePath(row),
                                                              origFileName: getFileName(row),
                                                          }
                                                        : (createInitialValues(section.fields) as IForm)
                                                }
                                            >
                                                {(formikProps) => (
                                                    <Grid item container xs={12}>
                                                        <Grid item xs={12}>
                                                            <DialogContent>
                                                                {Children.toArray(
                                                                    section.fields.map((field) => {
                                                                        if (
                                                                            field.name === 'institute' ||
                                                                            field.name === 'competitionInstitution'
                                                                        ) {
                                                                            return (
                                                                                <CertificateInstituteAutocomplete
                                                                                    key={field.name}
                                                                                    value={
                                                                                        formikProps.values[
                                                                                            field.name
                                                                                        ] as string
                                                                                    }
                                                                                    onChange={(value) =>
                                                                                        formikProps.setFieldValue(
                                                                                            field.name,
                                                                                            value,
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        formikProps.values
                                                                                            .readonly as boolean
                                                                                    }
                                                                                    errorMessage={
                                                                                        formikProps.errors[
                                                                                            field.name
                                                                                        ] as string
                                                                                    }
                                                                                />
                                                                            );
                                                                        }

                                                                        return FieldGenerator({
                                                                            ...field,
                                                                            format: {
                                                                                xs: 12,
                                                                            },
                                                                            ...formikProps,
                                                                            ...(field.type === 'file'
                                                                                ? {
                                                                                      downloadUrl: row
                                                                                          ? field.downloadUrl.replace(
                                                                                                '{documentId}',
                                                                                                row.id?.toString(),
                                                                                            )
                                                                                          : field.downloadUrl,
                                                                                  }
                                                                                : {}),
                                                                            props: {
                                                                                ...field.props,
                                                                                ...(readOnly ? { disabled: true } : {}),
                                                                            },
                                                                        });
                                                                    }),
                                                                )}
                                                            </DialogContent>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <DialogActions>
                                                                <Button
                                                                    variant="outlined"
                                                                    onClick={() => {
                                                                        setOpen(false);
                                                                        setRow(undefined);
                                                                    }}
                                                                >
                                                                    Mégsem
                                                                </Button>
                                                                <LoadingButton
                                                                    loading={count > 0}
                                                                    variant="contained"
                                                                    type="submit"
                                                                >
                                                                    Mentés
                                                                </LoadingButton>
                                                            </DialogActions>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Form>
                                        </Dialog>
                                    )}
                                </Grid>
                            )}
                            <Grid item xs={12} style={{ overflow: 'auto' }}>
                                <DataTable
                                    section={section}
                                    setRow={setRow}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                    readOnly={readOnly}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            {Children.toArray(
                                section.fields.map((field) => FieldGenerator({ ...field, ...section.formikProps })),
                            )}
                            {section.name === 'Alapadatok' && (
                                <ExtraStudentForm
                                    {...(section.formikProps as unknown as FormikProps<IStudentsForm>)}
                                    readOnly={readOnly || user.archive}
                                    baseLoading={loading}
                                    detailed={true}
                                />
                            )}
                        </>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const propsAreEquals = (prev: SectionProps, next: SectionProps): boolean => {
    let names = prev.section.fields.map((f) => f.name);

    if (prev.section.name === 'Alapadatok') {
        names = [...names, 'building', 'floor', 'room', 'disabled', 'terminating', 'functionalUser'];
    }

    const equalizationArray = names.map(
        (name) =>
            JSON.stringify(prev.section.formikProps?.values[name]) ===
                JSON.stringify(next.section.formikProps?.values[name]) &&
            JSON.stringify(prev.section.formikProps?.errors[name]) ===
                JSON.stringify(next.section.formikProps?.errors[name]) &&
            JSON.stringify(prev.section.formikProps?.touched[name]) ===
                JSON.stringify(next.section.formikProps?.touched[name]) &&
            prev.loading === next.loading &&
            prev.activeSection === next.activeSection,
    );

    return equalizationArray.every((e) => e);
};

const MemoizedSectionGenerator = memo(SectionGenerator, propsAreEquals);

export default MemoizedSectionGenerator;
