import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatNumberToThousands } from '../../../utils/AppConst';
import useFetch from '../../../utils/useFetch';
import { endpoints } from '../../../utils/endPoints';
import SemesterContext from '../../../components/SemesterSelector/SemesterContextProvider';
import { useContext, useEffect, useState } from 'react';
import { ISchedule } from './types';
import api from '../../../api';

export default function PaymentTable({ studentId, semesterId }: { studentId: string; semesterId?: string }) {
    const semester = useContext(SemesterContext);
    const [currency, setCurrency] = useState<string>('');
    const { data, loading } = useFetch<ISchedule[]>(
        !semesterId
            ? endpoints.scholarships.report.schedule(semester || '', studentId)
            : endpoints.masterData.students.scholarship.schedule
                  .replace('{semesterId}', semesterId)
                  .replace('{studentId}', studentId),
        '',
    );

    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setCurrency(response.data.currencyTrans);
            })
            .catch((error) => {
                console.error('Error fetching current tenant currency:', error);
            });
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <strong>Időszak</strong>
                        </TableCell>
                        <TableCell align="right">
                            <strong>Összeg</strong>
                        </TableCell>
                        <TableCell align="center">
                            <strong>Státusz</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell align="center" colSpan={3}>
                                Betöltés...
                            </TableCell>
                        </TableRow>
                    ) : (
                        data.map((row) => (
                            <TableRow key={row.month} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{row.month}</TableCell>
                                <TableCell align="right">
                                    {formatNumberToThousands(row.amount)} {currency}
                                </TableCell>
                                <TableCell align="center">{row.status}</TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
