import FinanceAdministrationFees from '../../pages/Finance/AdministrationFees';
import FinanceAdministrationFeeForm from '../../pages/Finance/AdministrationFees/Form';

export const administrationFees = {
    path: 'administration-fees',
    children: [
        {
            path: '',
            element: <FinanceAdministrationFees />,
            name: 'finance_administration_fees_list',
        },
        {
            path: 'new',
            element: <FinanceAdministrationFeeForm />,
            name: 'finance_administration_fees_edit',
        },
    ],
};
