import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountry } from '../pages/MasterData/General/Universities/useUniversityFields';
import { Address, IActualUniversityStudies, IForm } from '../utils/Interfaces/interfaces';
import { SemesterStatus } from '../studentPages/Profile/Tabs/SemesterStatusDialog';
import { TranslationKey } from '../studentPages/Profile/Tabs/MCCStudies/types';
import { IBuilding, IEntity } from '../pages/MasterData/General/Students/Form/ProcessDialogs/types';

interface ProfileObject {
    id: number;
    name: string;
}

interface BaseForeignScholarshipObject extends IForm {
    id: number;
    stayAbroadStart: string;
    stayAbroadEnd: string;
    hostName: string;
    hostCountry: ICountry;
    hostCity: string;
    hostMajor: string;
}

interface RawForeignScholarshipObject extends BaseForeignScholarshipObject {
    stayAbroadType: string;
}

interface ForeignScholarshipObject extends BaseForeignScholarshipObject {
    stayAbroadType: string[];
}

export interface BaseIStudentProfile extends Address, IForm {
    omId: string;
    taxId: string;
    idNumber: string;
    taj: string;
    idCardNumber: string;
    bankAccountNumber: string;
    id: number;
    email: string;
    phone: string;
    gender: number;
    firstName: string;
    lastName: string;
    campus: ProfileObject;
    trainingProgram: ProfileObject;
    trainingLevel: ProfileObject;
    collegeYear: ProfileObject;
    semesterStatus: SemesterStatus;
    actualStatus: TranslationKey;
    status: TranslationKey;
    mccId: string;
    functionalUser: number;
    filePath?: string;
    fileName?: string;
    primaryContactPersonFirstName: string;
    primaryContactPersonLastName: string;
    primaryContactPersonPhone: string;
    primaryContactPersonEmail: string;
    secondaryContactPersonFirstName: string;
    secondaryContactPersonLastName: string;
    secondaryContactPersonPhone: string;
    secondaryContactPersonEmail: string;
    workplace: IForm[];
    responsibilities?: string[];
    building?: IBuilding;
    floor?: IEntity;
    room?: IEntity;
    nationality: string[];
}

export interface RawIStudentProfile extends BaseIStudentProfile {
    foreignScholarship: RawForeignScholarshipObject[];
    actualUniversityStudies: IActualUniversityStudies[];
}

export interface IStudentProfile extends BaseIStudentProfile {
    foreignScholarship: ForeignScholarshipObject[];
    actualUniversityStudies: IActualUniversityStudies[];
}

interface StudentProfileState {
    isEdit: boolean;
    profile: IStudentProfile;
    refresh: boolean;
}
const initProfileObject: ProfileObject = {
    id: 0,
    name: '',
};

const initProfile: IStudentProfile = {
    omId: '',
    taxId: '',
    idNumber: '',
    taj: '',
    idCardNumber: '',
    bankAccountNumber: '',
    id: 0,
    email: '',
    phone: '',
    foreignScholarship: [],
    gender: 0,
    firstName: '',
    lastName: '',
    campus: initProfileObject,
    trainingProgram: initProfileObject,
    trainingLevel: initProfileObject,
    collegeYear: initProfileObject,
    semesterStatus: 'active',
    actualStatus: 'active',
    status: 'active',
    mccId: '',
    functionalUser: 0,
    postCode: '',
    city: '',
    streetType: '',
    streetName: '',
    houseNumber: '',
    primaryContactPersonEmail: '',
    primaryContactPersonFirstName: '',
    primaryContactPersonLastName: '',
    primaryContactPersonPhone: '',
    secondaryContactPersonEmail: '',
    secondaryContactPersonFirstName: '',
    secondaryContactPersonLastName: '',
    secondaryContactPersonPhone: '',
    workplace: [],
    responsibilities: [],
    actualUniversityStudies: [],
    nationality: [],
};

const initialState: StudentProfileState = {
    isEdit: false,
    refresh: false,
    profile: initProfile,
};

const studentProfileSlice = createSlice({
    name: 'studentProfile',
    initialState,
    reducers: {
        setEdit(state: StudentProfileState, action: PayloadAction<boolean | undefined>) {
            state.isEdit = action.payload === undefined ? !state.isEdit : !!action.payload;
        },
        setProfile(state: StudentProfileState, action: PayloadAction<IStudentProfile>) {
            state.profile = action.payload;
        },
        refreshAction(state: StudentProfileState) {
            state.refresh = !state.refresh;
        },
    },
});

export const { setEdit, setProfile, refreshAction } = studentProfileSlice.actions;
export default studentProfileSlice.reducer;
