import instance from '../../api';
import { fixDateRangeFields } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import { IForm, ItemEntity } from '../../utils/Interfaces/interfaces';

const courseListService = {
    ...crudTemplate(endpoints.courseManagement.courseList.main),
    filter: (form: IForm) =>
        instance.get(endpoints.courseManagement.courseList.list, { params: fixDateRangeFields(form) }),
    userSearch: (term: string, campus?: string, onlyWithStudent?: boolean) =>
        instance.get(
            endpoints.courseManagement.courseList.userSearch +
                '?term=' +
                term +
                '&campus=' +
                (campus ?? '') +
                (onlyWithStudent === true ? '&onlyWithStudent=1' : ''),
        ),
    centralStudentSearch: (term: string, tenant?: string) =>
        instance.get(
            endpoints.courseManagement.courseList.centralStudentSearch + '?term=' + term + '&tenant=' + tenant || 'TAR',
        ),
    centralStudentSearchByCourse: (term: string, course: string | number) =>
        instance.get(
            endpoints.courseManagement.courseList.centralStudentSearchByCourse + '?term=' + term + '&course=' + course,
        ),
    userSearchTeacher: (term: string, campus?: string, onlyWithStudent?: boolean) =>
        instance.get(
            endpoints.courseManagement.courseList.userSearch +
                '?type=teacher&term=' +
                term +
                '&campus=' +
                (campus ?? '') +
                (onlyWithStudent === true ? '&onlyWithStudent=1' : ''),
        ),
    userSearchCourseManager: (term: string, campus?: string, onlyWithStudent?: boolean) =>
        instance.get(
            endpoints.courseManagement.courseList.userSearch +
                '?type=courseManager&term=' +
                term +
                '&campus=' +
                (campus ?? '') +
                (onlyWithStudent === true ? '&onlyWithStudent=1' : ''),
        ),
    userSearchCourseManagerOrSuperAdmin: (term: string, campus?: string, onlyWithStudent?: boolean) =>
        instance.get(
            endpoints.courseManagement.courseList.userSearch +
                '?type=courseManagerOrSuperAdmin&term=' +
                term +
                '&campus=' +
                (campus ?? '') +
                (onlyWithStudent === true ? '&onlyWithStudent=1' : ''),
        ),
    guestPerformerSearch: (term: string) =>
        instance.get(endpoints.courseManagement.courseList.guestPerformerSearch + '?term=' + term),
    guestPerformerSave: (val: string) =>
        instance.post(endpoints.courseManagement.courseList.guestPerformerSearch, { val }),
    otherOrganiserSearch: (term: string) =>
        instance.get(endpoints.courseManagement.courseList.otherOrganiserSearch + '?term=' + term),
    otherOrganiserSave: (val: string) =>
        instance.post(endpoints.courseManagement.courseList.otherOrganiserSearch, { val }),
    campuses: (listAll = false) =>
        listAll
            ? instance.get(endpoints.courseManagement.courseList.listAllCampuses)
            : instance.get(endpoints.courseManagement.courseList.campuses),
    campusesByTenant: (tenant: string | null) =>
        instance.get(endpoints.courseManagement.courseList.tenantCampuses + '?tenant=' + tenant || 'TAR'),
    buildings: (identifier: string) =>
        instance.get(endpoints.courseManagement.courseList.buildings + '?campus=' + identifier),
    rooms: (identifier: string) => instance.get(endpoints.courseManagement.courseList.rooms + '?floor=' + identifier),
    items: (identifier: string) =>
        instance.get<ItemEntity[]>(endpoints.courseManagement.courseList.items + '?room=' + identifier),
    floors: (identifier: string) =>
        instance.get(endpoints.courseManagement.courseList.floors + '?building=' + identifier),
    semesters: () => instance.get(endpoints.courseManagement.courseList.semesters),
    semestersDetailed: () => instance.get(endpoints.courseManagement.courseList.semestersDetailed),
    roomCapacity: (id: number) =>
        instance.get(endpoints.courseManagement.courseList.roomCapacity.replace('{id}', id?.toString())),
    courseTypes: () => instance.get(endpoints.courseManagement.courseList.courseTypes),
    courseManagers: () => instance.get(endpoints.courseManagement.courseList.courseManagers),
    coursePresences: (form: IForm, courseId: number, id: number | string) =>
        instance.post(
            endpoints.courseManagement.courseList.coursePresences
                .replace('{courseId}', courseId?.toString())
                .replace('{eventId}', id?.toString()),
            form,
        ),
    getCoursePresences: (courseId: number, id: number | string, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseList.coursePresences
            .replace('{courseId}', courseId?.toString())
            .replace('{eventId}', id?.toString());

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
    validForEvaluating: (courseId: number | string, tenant: number | string) =>
        instance.get(
            endpoints.courseManagement.courseList.validForEvaluating
                .replace('{tenantId}', tenant?.toString())
                .replace('{courseId}', courseId?.toString()),
        ),
    evaluateView: (
        courseId: number | string,
        studentId: number | string,
        courseTenant: string | null,
        readOnly?: boolean,
    ) => {
        const endpoint = endpoints.courseManagement.courseList.evaluateView
            .replace('{courseId}', courseId?.toString())
            .replace('{studentId}', studentId?.toString());
        const addition =
            courseTenant && readOnly
                ? '?readOnly=true&courseTenant=' + courseTenant
                : courseTenant
                ? '?courseTenant=' + courseTenant
                : readOnly
                ? '?readonly=true'
                : '';
        return instance.get(endpoint + addition);
    },
    evaluate: (courseId: number | string, studentId: number | string, courseTenant: string | null, form: IForm) => {
        const endpoint = endpoints.courseManagement.courseList.evaluate
            .replace('{courseId}', courseId?.toString())
            .replace('{studentId}', studentId?.toString());

        if (courseTenant) {
            return instance.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return instance.post(endpoint, form);
        }
    },
    evaluationOptions: (courseId: number | string, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseList.evaluationOptions.replace(
            '{courseId}',
            courseId?.toString(),
        );

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
    coursesClose: (courseId: number | string) =>
        instance.post(endpoints.courseManagement.courseList.coursesClose.replace('{id}', courseId?.toString())),
    coursesOpen: (courseId: number | string) =>
        instance.post(endpoints.courseManagement.courseList.coursesOpen.replace('{id}', courseId?.toString())),
    /** -- Kurzuslista export -- */
    initiateExport: (form: { format: string }, filter: IForm) =>
        instance.post(endpoints.courseManagement.courseList.export.initiate, form, {
            params: fixDateRangeFields(filter),
        }),
    checkExport: (id: number) =>
        instance.get(endpoints.courseManagement.courseList.export.check.replace('{id}', id.toString())),
    downloadExport: (id: number) =>
        instance.get(endpoints.courseManagement.courseList.export.download.replace('{id}', id.toString()), {
            responseType: 'arraybuffer',
        }),
    courseNameSearch: (term: string, courseType?: string) =>
        instance.get(
            endpoints.courseManagement.courseBaseData.searchName +
                '?term=' +
                term +
                '&' +
                (courseType ? 'course-type=' + courseType : ''),
        ),
    questionnaireTemplates: (courseId?: number) =>
        instance.get(endpoints.courseManagement.courseBaseData.questionnaireTemplates(courseId ? '' + courseId : '0')),
    tenants: (id: string | undefined) => instance.get(endpoints.courseManagement.courseList.listTenants(id ?? '0')),
    tenantsByCourse: (id: string | undefined) =>
        instance.get(endpoints.courseManagement.courseList.listTenantsByCourse(id ?? '0')),
};

export default courseListService;
