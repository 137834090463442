import Grid from '@mui/material/Grid';
import { Page } from '@silinfo/front-end-template';
import MCCLoading from '../../components/MCCLoading';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';
import EventCalendar from './EventCalendar';
import './EventCalendar.css';
import Header from './Header';
import { IEvent } from './types';
import { header } from './utils';
import { DateStateContextProvider } from './DateStateContext';
import { useTenant } from '../../components/TenantContext';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';

export default function Calendar() {
    const { data, loading } = useFetch<IEvent[]>(endpoints.students.calendar.main, '') as {
        data: IEvent[];
        loading: boolean;
    };
    const tenant = useTenant();

    if (loading) {
        return <MCCLoading />;
    }
    return (
        <Page header={header(tenant || 'TAR') as HeaderProps}>
            <Grid item container xs={12} spacing={2}>
                <DateStateContextProvider>
                    <Header data={data} />
                    <EventCalendar data={data} />
                </DateStateContextProvider>
            </Grid>
        </Page>
    );
}
