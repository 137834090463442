import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { createInitialValues, FieldGenerator, Input } from '@silinfo/front-end-template';
import { Children, useState } from 'react';
import { useDispatch } from 'react-redux';
import instance from '../../../../../api';
import Form from '../../../../../components/Form/Form';
import CertificateInstituteAutocomplete from '../../../../../pages/MasterData/General/Students/Form/CertificateInstituteAutocomplete';
import { refreshAction, setEdit } from '../../../../../store/studentProfile';
import useFetch from '../../../../../utils/useFetch';
import { endpoints } from '../../../../../utils/endPoints';
import DialogTitle from '@mui/material/DialogTitle';
import BasicDialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { FormikHelpers } from 'formik';
import { AxiosError } from 'axios';
import { transformApiErrors } from '../../../../../utils/AppConst';
import { create } from '../../../../../store/notification';
import { Link } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
0;

const EditContent = <
    T extends {
        id: number | string;
        institute?: string;
        competitionInstitution?: string;
        stayAbroadStart?: string;
        stayAbroadEnd?: string;
    },
>({
    setOpen,
    value,
    handleClose,
    fields,
    url,
}: {
    setOpen: (value: boolean) => void;
    value?: T;
    handleClose: () => void;
    fields: Input[];
    url: string;
}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const currentSemesterInfo = useFetch<{ start: string; end: string }>(
        endpoints.students.courseEnrollment.currentSemesterInfo,
        '',
    );
    const [warningOpen, setWarningOpen] = useState(false);
    const [savedForm, setSavedForm] = useState({});
    const [error, setError] = useState(false);

    const handleWarningClose = () => {
        if (!error) {
            dispatch(refreshAction());
            dispatch(setEdit(false));
            setWarningOpen(false);
            setOpen(false);
        }
    };

    const checkIfWarningShouldOpen = (form: T) => {
        if (error) return;
        if (
            fields[0].name == 'stayAbroadStart' &&
            form.stayAbroadStart &&
            form.stayAbroadEnd &&
            !currentSemesterInfo.loading
        ) {
            const foreignStart = form.stayAbroadStart;
            const foreignEnd = form.stayAbroadEnd;
            const semesterStart = currentSemesterInfo.data.start;
            const semesterEnd = currentSemesterInfo.data.end;
            if (foreignStart > semesterStart || foreignEnd < semesterEnd) {
                setWarningOpen(true);
            }
        } else {
            handleWarningClose();
        }
    };

    const handleDelete = () => {
        if (!value) return;
        setLoading(true);
        return instance
            .delete(url + '/' + value.id)
            .then(() => {
                dispatch(refreshAction());
                dispatch(setEdit(false));
                setOpen(false);
            })
            .finally(() => setLoading(false));
    };

    const handleSubmit = async (form: T, formikProps?: FormikHelpers<T>) => {
        setLoading(true);
        setSavedForm(form);
        await sendSubmit(formikProps, form);
        checkIfWarningShouldOpen(form);
    };

    const sendSubmit = (formikProps?: FormikHelpers<T>, form?: T) => {
        if (form) {
            return instance
                .post(url + (value ? '/' + value.id : ''), {
                    ...form,
                })
                .then(() => {
                    setError(false);
                })
                .finally(() => setLoading(false));
        } else {
            return instance
                .post(url + (value ? '/' + value.id : ''), {
                    ...savedForm,
                })
                .then(() => {
                    setError(false);
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 422 && formikProps) {
                        setError(true);
                        formikProps.setErrors(transformApiErrors<T>(error.response?.data.violations));
                    }
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a mentés során.',
                        }),
                    );
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <Form fields={[]} onSubmit={handleSubmit} hideButtons initialValues={value || createInitialValues<T>(fields)}>
            {(formikProps) => (
                <Grid item container>
                    <Grid item xs={12}>
                        <DialogContent>
                            {Children.toArray(
                                fields.map((field) => {
                                    if (field.name === 'institute' || field.name === 'competitionInstitution') {
                                        return (
                                            <CertificateInstituteAutocomplete
                                                key={field.name}
                                                value={formikProps.values[field.name] as string}
                                                onChange={(val) => formikProps.setFieldValue(field.name, val)}
                                                disabled={false}
                                                errorMessage={formikProps.errors[field.name] as string}
                                            />
                                        );
                                    }

                                    return FieldGenerator({
                                        ...field,
                                        ...formikProps,
                                        format: { xs: 12 },
                                        fieldType: 'base',
                                    });
                                }),
                            )}
                        </DialogContent>
                    </Grid>
                    <Grid item xs={12}>
                        <DialogActions>
                            <LoadingButton variant="outlined" onClick={handleClose}>
                                Bezár
                            </LoadingButton>
                            {value && (
                                <LoadingButton
                                    color="error"
                                    variant="contained"
                                    loading={loading}
                                    onClick={handleDelete}
                                >
                                    Törlés
                                </LoadingButton>
                            )}
                            <LoadingButton variant="contained" loading={loading} type="submit">
                                Mentés
                            </LoadingButton>
                        </DialogActions>
                    </Grid>
                    <BasicDialog open={warningOpen}>
                        <DialogTitle>Figyelem!</DialogTitle>
                        <Grid item container>
                            <Grid item xs={12}>
                                <DialogContent>
                                    <Typography>
                                        Az ösztöndíj rögzítésével a szemeszterhez kapcsolódó hallgatói státusz NEM
                                        változik. Amennyiben a szemeszterre vonatkozóan “Külföldi ösztöndíjas” státuszra
                                        szeretnél váltani, úgy a{' '}
                                        <Link to={clientEndPoints.student_profile_study_data} reloadDocument={true}>
                                            Profil\Tanulmányi adatok
                                        </Link>{' '}
                                        fülön, a Szemeszter státusz módosításánál adj le egy kérvényt!
                                    </Typography>
                                </DialogContent>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <LoadingButton
                                variant="contained"
                                onClick={() => {
                                    handleWarningClose();
                                }}
                            >
                                Bezár
                            </LoadingButton>
                        </DialogActions>
                    </BasicDialog>
                </Grid>
            )}
        </Form>
    );
};

export default EditContent;
