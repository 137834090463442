import Grid from '@mui/material/Grid';
import { DownloadButton, Input } from '@silinfo/front-end-template';
import moment from 'moment';
import { Children } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ObjectWithCertificateFileProperties, Option, transformFileProperties } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import useFetch from '../../../../utils/useFetch';
import { TabCardProps } from '../../TabCard';
import Row from '../Row';
import Card, { TCard } from './Card';
import {
    competitionFields,
    cvFields,
    educationFields,
    useForeignScholarshipFields,
    useInternshipFields,
    useWorkplaceFields,
} from './fields';
import { competitionIcons, educationIcons, foreingScholarshipIcons, internshipIcons, workplaceIcons } from './icons';

const useOtherExperiences = (): TabCardProps[] => {
    const { profile } = useSelector((state: RootState) => state.studentProfile);
    const { user } = useSelector((state: RootState) => state.auth);
    const { data } = useFetch<Option[]>(endpoints.masterData.students.countries.main, '');
    const internship = useInternshipFields(data);
    const foreignScholarship = useForeignScholarshipFields(data);
    const workplace = useWorkplaceFields(data);

    const internshipFields = () => internship;
    const foreignScholarshipFields = () => foreignScholarship;
    const workplaceFields = () => workplace;
    const foreignScholarShipValueGetter = (field: Input, value: Record<string, string | string[]>): string => {
        const fieldValue = value[field.name as keyof typeof value];
        switch (field.type) {
            case 'date':
                return moment('' + fieldValue).format('YYYY. MM. DD.');
            case 'select':
                return field.options.find((option) => option.value == fieldValue + '')?.label || '' + fieldValue || '-';
            case 'multiselect':
                return (fieldValue as string[])
                    .map((elem) => field.options.find((option) => option.value == elem)?.label || elem || '-')
                    .join(', ');
            default:
                return '' + fieldValue;
        }
    };

    return [
        {
            title: 'Önéletrajz',
            content: (
                <Card
                    initData={(profile?.curriculumVitae as TCard[]) || []}
                    fields={cvFields}
                    label={(value) => value.certificateFileName as string}
                    url={endpoints.students.profile.studies.curriculumVitae}
                    title={'Önéletrajz'}
                    readOnly={user.archive}
                >
                    {(value) => (
                        <DownloadButton
                            buttonText="Önéletrajz letöltése"
                            downloadName={value.certificateFileName as string}
                            url={`${endpoints.fileManagement.download}?id=${value.id}&type=student-document&subType=resume-document`}
                        />
                    )}
                </Card>
            ),
        },
        {
            title: 'Végzettség',
            content: (
                <Card
                    initData={
                        ((profile?.education || []) as ({ id: string } & ObjectWithCertificateFileProperties)[]).map(
                            transformFileProperties,
                        ) as unknown as TCard[]
                    }
                    fields={educationFields}
                    label={(value) => value.institute as string}
                    url={endpoints.students.profile.studies.certification}
                    title={'Végzettség'}
                    readOnly={user.archive}
                >
                    {(value) => (
                        <Grid item container xs={12} spacing={1}>
                            {Children.toArray(
                                educationFields(value.id).map((field) =>
                                    field.type === 'file' ? (
                                        <Grid item xs={12}>
                                            <DownloadButton
                                                buttonText="Végzettség letöltése"
                                                downloadName={value.certificateFileName as string}
                                                url={`${endpoints.fileManagement.download}?id=${value.id}&type=student-document&subType=education-document`}
                                            />
                                        </Grid>
                                    ) : (
                                        <Row
                                            label={field.label}
                                            value={
                                                field.type === 'select'
                                                    ? field.options.find(
                                                          (option) =>
                                                              option.value == value[field.name as keyof typeof value],
                                                      )?.label ?? '-'
                                                    : (value[field.name as keyof typeof value] as string)
                                            }
                                            Icon={educationIcons[field.name]}
                                        />
                                    ),
                                ),
                            )}
                        </Grid>
                    )}
                </Card>
            ),
        },
        {
            title: 'Szakmai gyakorlat',
            content: (
                <Card
                    initData={(
                        (profile?.internship as {
                            id: string;
                            employer: string;
                            activity: string;
                            internshipCountry: { id: string };
                        }[]) || []
                    ).map((e) => ({
                        ...e,
                        internshipCountry: e.internshipCountry.id,
                    }))}
                    fields={internshipFields}
                    label={(value) => value.employer + ' - ' + value.activity}
                    url={endpoints.students.profile.studies.internship}
                    title={'Szakmai gyakorlat'}
                    readOnly={user.archive}
                >
                    {(value) => (
                        <Grid item container xs={12}>
                            {Children.toArray(
                                internship.map((field) => (
                                    <Row
                                        key={field.name}
                                        label={field.label}
                                        value={
                                            (field.type === 'date'
                                                ? value[field.name as keyof typeof value]
                                                    ? moment(value[field.name as keyof typeof value]).format(
                                                          'YYYY. MM. DD.',
                                                      )
                                                    : '-'
                                                : field.type === 'select'
                                                ? field.options.find(
                                                      (option) =>
                                                          option.value == value[field.name as keyof typeof value],
                                                  )?.label
                                                : value[field.name as keyof typeof value]) as string
                                        }
                                        Icon={internshipIcons[field.name]}
                                    />
                                )),
                            )}
                        </Grid>
                    )}
                </Card>
            ),
        },
        {
            title: 'Külföldi ösztöndíj',
            content: (
                <Card
                    initData={(profile?.foreignScholarship || []).map((e) => ({
                        ...e,
                        id: '' + e.id,
                        hostCountry: e.hostCountry.id,
                    }))}
                    fields={foreignScholarshipFields}
                    label={(value) => value.hostName}
                    url={endpoints.students.profile.studies.foreignScholarship}
                    title={'Külföldi ösztöndíj'}
                    readOnly={user.archive}
                >
                    {(value) => (
                        <Grid item container xs={12}>
                            {Children.toArray(
                                foreignScholarship.map((field) => (
                                    <Row
                                        key={field.name}
                                        label={field.label}
                                        value={foreignScholarShipValueGetter(field, value)}
                                        Icon={foreingScholarshipIcons[field.name]}
                                    />
                                )),
                            )}
                        </Grid>
                    )}
                </Card>
            ),
        },
        {
            title: 'Munkahely',
            content: (
                <Card
                    initData={(
                        (profile?.workplace as { id: string; jobCountry: { id: string }; jobName: string }[]) || []
                    ).map((e) => ({
                        ...e,
                        jobCountry: e.jobCountry.id,
                    }))}
                    fields={workplaceFields}
                    label={(value) => value.jobName as string}
                    url={endpoints.students.profile.studies.workplace}
                    title={'Munkahely'}
                    readOnly={user.archive}
                >
                    {(value) => (
                        <Grid item container xs={12}>
                            {Children.toArray(
                                workplace.map((field) => (
                                    <Row
                                        key={field.name}
                                        label={field.label}
                                        value={
                                            (field.type === 'date'
                                                ? value[field.name as keyof typeof value]
                                                    ? moment(value[field.name as keyof typeof value]).format(
                                                          'YYYY. MM. DD.',
                                                      )
                                                    : '-'
                                                : field.type === 'select'
                                                ? field.options.find(
                                                      (option) =>
                                                          option.value == value[field.name as keyof typeof value],
                                                  )?.label
                                                : value[field.name as keyof typeof value]) as string
                                        }
                                        Icon={workplaceIcons[field.name]}
                                    />
                                )),
                            )}
                        </Grid>
                    )}
                </Card>
            ),
        },
        {
            title: 'Tanulmányi verseny',
            content: (
                <Card
                    initData={
                        ((profile?.competition || []) as ({ id: string } & ObjectWithCertificateFileProperties)[]).map(
                            transformFileProperties,
                        ) as unknown as TCard[]
                    }
                    fields={competitionFields}
                    label={(value) => value.competition as string}
                    url={endpoints.students.profile.studies.competition}
                    title={'Tanulmányi verseny'}
                    readOnly={user.archive}
                >
                    {(value) => (
                        <Grid item container xs={12} spacing={1}>
                            {Children.toArray(
                                competitionFields(value.id).map((field) =>
                                    field.type === 'file' ? (
                                        <Grid item xs={12}>
                                            <DownloadButton
                                                buttonText="Igazolás letöltése"
                                                downloadName={value.certificateFileName as string}
                                                url={`${endpoints.fileManagement.download}?id=${value.id}&type=student-document&subType=competition-certification`}
                                            />
                                        </Grid>
                                    ) : (
                                        <Row
                                            label={field.label}
                                            value={value[field.name as keyof typeof value] as string}
                                            Icon={competitionIcons[field.name]}
                                        />
                                    ),
                                ),
                            )}
                        </Grid>
                    )}
                </Card>
            ),
        },
    ];
};

export default useOtherExperiences;
