import { certificatesSubMenus } from '../../components/Layout';
import Certificates from '../../pages/Administration/Certificates';
import { confirmation } from './Certificates/Confirmation';
import { membership } from './Certificates/Membership';
import { transcript } from './Certificates/Transcript';

export const certificates = {
    path: 'certificates',
    children: [
        {
            path: '',
            element: <Certificates name="Igazolások" submenus={certificatesSubMenus} />,
            name: 'admin_certificates',
        },
        transcript,
        membership,
        confirmation,
    ],
};
