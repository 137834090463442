import Grid from '@mui/material/Grid';
import { Field, FormikProps } from 'formik';
import { TextField } from '@silinfo/front-end-template';
import { Address as IAddress } from '../../utils/Interfaces/interfaces';

export default function Address<T extends { address: IAddress }>(
    props: Pick<FormikProps<T>, 'values' | 'errors' | 'touched' | 'setFieldValue'> & { disabled?: boolean },
) {
    const { values, setFieldValue, disabled } = props;
    const touched = props.touched as { address: Record<string, boolean> };
    const errors = props.errors as { address: Record<string, string> };

    return (
        <>
            <Grid item xs={12} md={3} lg={3}>
                <Field as="div" field="address.postCode">
                    <TextField
                        value={values.address.postCode}
                        label="Irányítószám"
                        name="address.postCode"
                        error={!!(touched?.address?.postCode && errors?.address?.postCode)}
                        helperText={touched?.address?.postCode && errors?.address?.postCode}
                        onChange={(e) => setFieldValue('address.postCode', e.target.value)}
                        disabled={disabled}
                    />
                </Field>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
                <Field as="div" field="address.city">
                    <TextField
                        value={values.address.city}
                        label="Település"
                        name="address.city"
                        error={!!(touched?.address?.city && errors?.address?.city)}
                        helperText={touched?.address?.city && errors?.address?.city}
                        onChange={(e) => setFieldValue('address.city', e.target.value)}
                        disabled={disabled}
                    />
                </Field>
            </Grid>
            <Grid item xs={12} md={4}>
                <Field as="div" field="address.streetName">
                    <TextField
                        value={values.address.streetName}
                        label="Közterület neve"
                        name="address.streetName"
                        error={!!(touched?.address?.streetName && errors?.address?.streetName)}
                        helperText={touched?.address?.streetName && errors?.address?.streetName}
                        onChange={(e) => setFieldValue('address.streetName', e.target.value)}
                        disabled={disabled}
                    />
                </Field>
            </Grid>
            <Grid item xs={12} md={4}>
                <Field as="div" field="address.streetType">
                    <TextField
                        value={values.address.streetType}
                        label="Közterület jellege"
                        name="address.streetType"
                        error={!!(touched?.address?.streetName && errors?.address?.streetName)}
                        helperText={touched?.address?.streetName && errors?.address?.streetName}
                        onChange={(e) => setFieldValue('address.streetType', e.target.value)}
                        disabled={disabled}
                    />
                </Field>
            </Grid>
            <Grid item xs={12} md={4}>
                <Field as="div" field="address.houseNumber">
                    <TextField
                        value={values.address.houseNumber}
                        label="Házszám"
                        name="address.houseNumber"
                        error={!!(touched?.address?.houseNumber && errors?.address?.houseNumber)}
                        helperText={touched?.address?.houseNumber && errors?.address?.houseNumber}
                        onChange={(e) => setFieldValue('address.houseNumber', e.target.value)}
                        disabled={disabled}
                    />
                </Field>
            </Grid>
        </>
    );
}
