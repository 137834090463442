import Error from '@mui/icons-material/Error';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
// eslint-disable-next-line regex/invalid-warn
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AutoloadTable } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { FieldGenerator } from '../../../../../components/Form/FieldGenerator';
import studentsService from '../../../../../services/masterData/students';
import { IForm } from '../../../../../utils/Interfaces/interfaces';
import { theme } from '../../../../../utils/theme';
import ViewDataButton from '../ViewDataButton';
import { boolOptions } from './options';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

type TObject = { [key: string]: string };

export interface AdmittedSemester extends TObject {
    semester: string;
    trainingLevel: string;
    trainingProgram: string;
    scholarshipStatus: string;
}

export interface CompletedTrainingProgram extends TObject {
    trainingProgram: string;
    year: string;
}

const admittedSemesterColumns = (archive?: boolean): GridColDef[] =>
    [
        {
            headerName: 'Szemeszter',
            sortable: false,
            field: 'semester',
        },
        {
            headerName: 'Képzési szint',
            field: 'trainingLevel',
            sortable: false,
        },
        {
            headerName: 'Képzési program',
            field: 'trainingProgram',
            sortable: false,
        },
        {
            headerName: 'Szemeszter státusz',
            field: 'semesterStatus',
            sortable: false,
        },
        ...(!archive
            ? [
                  {
                      headerName: 'Műveletek',
                      field: 'operations',
                      sortable: false,
                      renderCell: ViewDataButton,
                  },
              ]
            : []),
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));

const completedTrainingProgramColumns = (): GridColDef[] =>
    [
        {
            headerName: 'Képzési szint',
            sortable: false,
            field: 'flevel',
            renderCell: (params: GridRenderCellParams) => params.row.level,
        },
        {
            headerName: 'Képzési program',
            sortable: false,
            field: 'fprogram',
            renderCell: (params: GridRenderCellParams) => params.row.program,
        },
        {
            headerName: 'Év',
            field: 'fyear',
            sortable: false,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams) => params.row.year,
        },
        {
            headerName: 'Státusz',
            field: 'status',
            sortable: false,
            minWidth: 50,
            renderCell: (params: GridRenderCellParams) => {
                return params.row.status === 'completed' ? (
                    <Tooltip title={'Teljesített'} placement="top">
                        <Done color={'success'} />
                    </Tooltip>
                ) : (
                    <Tooltip title={'Nem teljesített'} placement="top">
                        <Close color={'error'} />
                    </Tooltip>
                );
            },
        },
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));

interface MCCStudiesProps {
    formikProps: FormikProps<IForm>;
    loading: boolean;
    activeSection: number;
    setActiveSection: (section: number) => void;
    number: number;
    readOnly: boolean;
}

const names = ['entryYear', 'responsibilities', 'isSecondarySchoolProgram', 'isTalentCaretakerProgram'];

function MCCStudies({ formikProps, loading, activeSection, setActiveSection, number, readOnly }: MCCStudiesProps) {
    const { id } = useParams();
    const { user } = useSelector((state: RootState) => state.auth);
    const hasError = () =>
        names.some((field) =>
            Object.keys({ ...formikProps.errors, ...(formikProps.status?.errors || {}) }).includes(field),
        );
    if (loading) return <Skeleton height={35} />;
    return (
        <Accordion expanded={activeSection === number} onChange={() => setActiveSection(number)}>
            <AccordionSummary
                expandIcon={
                    <ExpandMore
                        style={{
                            fontSize: 'xx-large',
                            color: theme.palette.secondary.contrastText,
                        }}
                    />
                }
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                }}
            >
                MCC-s tanulmányok {hasError() && <Error style={{ marginLeft: '2px' }} color="error" />}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={4}>
                    {FieldGenerator({
                        name: 'entryYear',
                        label: 'Belépés éve',
                        format: { xs: 12, sm: 6 },
                        props: {
                            disabled: true,
                        },
                        ...formikProps,
                    })}
                    {FieldGenerator({
                        name: 'responsibilities',
                        label: 'MCC-s feladatkör',
                        type: 'multiselect',
                        options: [
                            {
                                value: 'educator',
                                label: 'Nevelőtanár',
                            },
                            {
                                value: 'campaignManager',
                                label: 'Kampányfelelős',
                            },
                        ],
                        format: { xs: 12, sm: 6 },
                        props: {
                            disabled: readOnly,
                        },
                        ...formikProps,
                    })}
                    {FieldGenerator({
                        name: 'isSecondarySchoolProgram',
                        label: 'Középiskolás program',
                        type: 'select',
                        options: boolOptions,
                        format: { xs: 12, sm: 6 },
                        props: {
                            disabled: readOnly,
                        },
                        ...formikProps,
                    })}
                    {FieldGenerator({
                        name: 'isTalentCaretakerProgram',
                        label: 'Fiatal tehetség program',
                        type: 'select',
                        options: boolOptions,
                        format: { xs: 12, sm: 6 },
                        props: {
                            disabled: readOnly,
                        },
                        ...formikProps,
                    })}
                    <AutoloadTable
                        title="Felvett szemeszterek"
                        columns={admittedSemesterColumns(user.archive)}
                        filter={() => studentsService.enrolledSemestersByStudent(id as string)}
                        responseKey="data"
                        pagination={false}
                        hideFooter={true}
                    />
                    <AutoloadTable
                        title="Képzési programok"
                        columns={completedTrainingProgramColumns()}
                        filter={() => studentsService.completedTrainingProgramByStudent(id as string)}
                        responseKey="data"
                        pagination={false}
                        hideFooter={true}
                    />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

const propsAreEquals = (prev: MCCStudiesProps, next: MCCStudiesProps): boolean => {
    const equalizationArray = names.map(
        (name) =>
            JSON.stringify(prev.formikProps?.values[name]) === JSON.stringify(next.formikProps?.values[name]) &&
            JSON.stringify(prev.formikProps?.errors[name]) === JSON.stringify(next.formikProps?.errors[name]) &&
            JSON.stringify(prev.formikProps?.touched[name]) === JSON.stringify(next.formikProps?.touched[name]) &&
            prev.loading === next.loading &&
            prev.activeSection === next.activeSection,
    );

    return equalizationArray.every((e) => e);
};

const MemoizedMCCStudies = memo(MCCStudies, propsAreEquals);

export default MemoizedMCCStudies;
