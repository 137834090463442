import LangExams from '../../pages/Administration/LangExamReport/LangExams';

export const langExamReport = {
    path: 'lang-exam-report',
    children: [
        {
            path: '',
            element: <LangExams />,
            name: 'admin_lang_exam_report_list',
        },
    ],
};
