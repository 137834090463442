import { FieldGenerator, Loading } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { Children, useMemo } from 'react';
import { foreignScholarship } from '../../../pages/MasterData/General/Students/Form/fields';
import { Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';
import { ForeignScholarshipSemesterStatusForm } from './SemesterStatusDialog';

interface ForeignScholarshipFieldsProps extends FormikProps<ForeignScholarshipSemesterStatusForm> {
    readOnly: boolean;
}

const ForeignScholarshipFields = (props: ForeignScholarshipFieldsProps) => {
    const { data, loading } = useFetch<Option[]>(endpoints.masterData.students.countries.main, '');
    const foreignScholarshipFields = useMemo(
        () => foreignScholarship(data).map((field) => ({ ...field, format: { xs: 12 } })),
        [data],
    );
    return (
        <>
            {loading ? (
                <Loading noHeight />
            ) : (
                Children.toArray(
                    foreignScholarshipFields.map((field) =>
                        FieldGenerator({ ...props, ...field, props: { disabled: props.readOnly } }),
                    ),
                )
            )}
        </>
    );
};

export default ForeignScholarshipFields;
