import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch } from 'react-redux';
import { setEdit } from '../../../../../store/studentProfile';

const InfoContent = ({
    children,
    handleClose,
    readOnly = false,
}: {
    children: JSX.Element | JSX.Element[] | string;
    handleClose: () => void;
    readOnly: boolean | undefined;
}) => {
    const dispatch = useDispatch();

    return (
        <>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <LoadingButton variant="outlined" onClick={handleClose}>
                    Bezár
                </LoadingButton>
                {!readOnly ? (
                    <LoadingButton variant="contained" onClick={() => dispatch(setEdit())}>
                        Szerkesztés
                    </LoadingButton>
                ) : (
                    <></>
                )}
            </DialogActions>
        </>
    );
};

export default InfoContent;
