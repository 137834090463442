import Grid from '@mui/material/Grid';
import { FieldGenerator, Input, Loading } from '@silinfo/front-end-template';
import moment from 'moment';
import { Children, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import studentsService from '../../../services/masterData/students';
import studentProfileService from '../../../services/studentServices/studentProfileService';
import { RootState } from '../../../store';
import { IStudentProfile, refreshAction } from '../../../store/studentProfile';
import { bankAccountNumberFormatter, Option } from '../../../utils/AppConst';
import { filterUnusedKeys } from '../../../utils/form';
import EditForm from '../EditForm';
import Title from '../Title';
import Data from './Data';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { snakeCaseToCamelCase } from '../../../utils/caseConverter';
import { FieldRule } from '../../../pages/MasterData/General/Students/Form/types';

const fieldsToDisplay = (options: Option[]): (Input & { getValue?: (value: string | string[]) => string })[] =>
    [
        { name: 'birthPlace', label: 'Születési hely' },
        {
            name: 'birthDate',
            label: 'Születési idő',
            type: 'date',
            getValue: (value: string) => (value ? moment(value).format('YYYY. MM. DD.') : '-'),
        },
        {
            name: 'nationality',
            label: 'Állampolgárság',
            type: 'multiselect',
            options,
            getValue: (value: string[]) => (value?.length > 0 ? value.join(', ') : '-'),
        },
        { name: 'kepIdCardNumber', label: 'Személyi igazolvány szám' },
        { name: 'idCardNumber', label: 'Személyi igazolvány szám' },
        { name: 'taxId', label: 'Adóazonosító jel' },
        { name: 'identificationNumberCnp', label: 'Személyi szám (CNP)' },
        { name: 'taj', label: 'TAJ szám' },
        { name: 'bankName', label: 'Pénzintézet neve' },
        { name: 'omId', label: 'Oktatási azonosító' },
        { name: 'bankAccountNumber', label: 'Bankszámlaszám' },
        { name: 'motherLastName', label: 'Anyja vezetékneve' },
        { name: 'motherFirstName', label: 'Anyja keresztneve' },
    ].map((field) => ({ getValue: (value: string | string[]) => value || '-', ...field })) as (Input & {
        getValue?: (value: string | string[]) => string;
    })[];

export default function BaseData() {
    const { user } = useSelector((state: RootState) => state.auth);
    const { profile } = useSelector((state: RootState) => state.studentProfile);
    const [nationalityOptions, setNationalityOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [fieldRules, setFieldRules] = useState<FieldRule[]>([]);

    useEffect(() => {
        setLoading(true);
        studentsService.nationalities().then((res) => {
            setNationalityOptions(res.data);

            studentsService
                .listFieldRules()
                .then((res) => setFieldRules(res.data))
                .finally(() => setLoading(false));
        });
    }, []);

    let fields = fieldsToDisplay(nationalityOptions);

    if (fieldRules.length > 0) {
        fields = fields.filter((field) => fieldRules.some((rule) => snakeCaseToCamelCase(rule.key) === field.name));
    } else {
        fields = [];
    }

    const Normal = useCallback(
        () => (
            <Grid item container spacing={2} xs={12}>
                {Children.toArray(
                    fields.map((field) => (
                        <Data label={field.label} format={field.format} key={field.name}>
                            {field.getValue
                                ? field.getValue(profile[field.name as keyof IStudentProfile] as string)
                                : (profile[field.name as keyof IStudentProfile] as string)}
                        </Data>
                    )),
                )}
            </Grid>
        ),
        [profile, fields],
    );

    let allFields = fieldsToDisplay(nationalityOptions);

    if (fieldRules.length > 0) {
        allFields = allFields.filter((field) =>
            fieldRules.some(
                (rule) => snakeCaseToCamelCase(rule.key) === field.name && field.name !== 'bankAccountNumber',
            ),
        );
    }

    const keysToHave = fields.map((field) => field.name);

    const initialValues: IForm = {
        ...filterUnusedKeys(profile, keysToHave),
        nationality:
            profile?.nationality?.map((nation) => nationalityOptions.find((elem) => elem.label === nation)?.value) ??
            [],
    };

    keysToHave.forEach((key) => {
        if (initialValues[key] === undefined) {
            initialValues[key] = key === 'nationality' ? [] : '';
        }
    });

    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item container xs={12} justifyContent="space-between">
                <Grid item>
                    <Title>Személyes adatok</Title>
                </Grid>
                <Grid item>
                    {!user.archive && !loading && fieldRules.length > 0 && (
                        <EditForm
                            fields={allFields}
                            onSubmit={(form: IForm) =>
                                studentProfileService
                                    .saveBaseDataPrimaryForm(form)
                                    .then(() => dispatch(refreshAction()))
                            }
                            initialValues={loading ? {} : initialValues}
                        >
                            {(formikProps) => (
                                <>
                                    {loading ? (
                                        <Loading noHeight />
                                    ) : (
                                        FieldGenerator({
                                            ...formikProps,
                                            ...{
                                                name: 'bankAccountNumber',
                                                label: 'Bankszámlaszám',
                                                format: { xs: 12, md: 8 },
                                                props: {
                                                    onChange: bankAccountNumberFormatter(formikProps.setFieldValue),
                                                },
                                            },
                                        })
                                    )}
                                </>
                            )}
                        </EditForm>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Normal />
            </Grid>
        </Grid>
    );
}
