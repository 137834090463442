import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Accordion, Input, Page } from '@silinfo/front-end-template';
import { AxiosError, AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import React, { Dispatch, createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../../../../../components/Form/Form';
import { useGeneralMasterDataSubmenus } from '../../../../../components/Layout';
import MCCLoading from '../../../../../components/MCCLoading';
import studentsService, { IStudentResponse, IStudentsForm } from '../../../../../services/masterData/students';
import usersService from '../../../../../services/masterData/users';
import { create } from '../../../../../store/notification';
import { transformApiErrors } from '../../../../../utils/AppConst';
import { View } from '../../../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import Yup from '../../../../../utils/yup';
import DetailedForm from './DetailedForm';
import ExtraStudentForm from './ExtraStudentForm';
import ProfilePicture from './ProfilePicture';
import { baseFormFieldsName, useBaseFormFields } from './fields';
import { RootState } from '../../../../../store';
import { useTenant } from '../../../../../components/TenantContext';

export const ReadOnlyContext = createContext(false);
export const StudentNameContext = createContext('');
export default function StudentsForm({ view, readOnly }: { view: View; readOnly?: boolean }) {
    const { id } = useParams();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [initialValues, setInitialValues] = useState<IStudentsForm>({
        ...init,
        building: '',
        floor: '',
        room: '',
        campus: '',
        trainingLevel: '',
        trainingProgram: '',
        collegeYear: '',
        userId: '',
    });
    const tenant = useTenant();

    const generalMasterDataSubmenus = useGeneralMasterDataSubmenus();

    useEffect(() => {
        if (id) {
            studentsService.get(String(id)).then((response: AxiosResponse) => {
                setFirstName(response.data.data.firstName);
                setLastName(response.data.data.lastName);
            });
        }
    }, [id]);

    const formTitle = id ? lastName + ' ' + firstName + ' szerkesztése' : 'Hallgató hozzáadása';
    const title = readOnly ? 'Hallgató megtekintése' : formTitle;

    const header = {
        project: tenant || 'TAR',
        title: 'Hallgatók',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [generalMasterDataSubmenus.students.to]: 'Hallgatók',
            form: title,
        },
    };

    return (
        <Page header={header}>
            {view === 'base' ? (
                <BaseForm
                    title={title}
                    id={id}
                    readOnly={readOnly}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                />
            ) : (
                <ReadOnlyContext.Provider value={!!readOnly}>
                    <StudentNameContext.Provider value={lastName + ' ' + firstName}>
                        <DetailedForm readOnly={readOnly} />
                    </StudentNameContext.Provider>
                </ReadOnlyContext.Provider>
            )}
        </Page>
    );
}

const init = baseFormFieldsName.reduce((acc, field) => {
    acc[field] = '';
    return acc;
}, {} as IStudentsForm);

function BaseForm({
    title,
    id,
    readOnly,
    initialValues,
    setInitialValues,
}: {
    title: string;
    id?: string | number;
    readOnly?: boolean;
    initialValues: IStudentsForm;
    setInitialValues: Dispatch<React.SetStateAction<IStudentsForm>>;
}) {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldsLoading, setFieldsLoading] = useState<boolean>(false);
    const [disableCheckBox, setDisableCheckBox] = useState<boolean>(false);
    const isEdit: boolean = id ? true : false;
    const baseFormFields = useBaseFormFields(setFieldsLoading, readOnly, isEdit);
    const [foundUser, setFoundUser] = useState<boolean>(false);
    const backUrl = clientEndPoints.md_general_students_list + (readOnly ? 'read-only' : '') + window.location.search;
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(backUrl);
    };
    let fields: Input[] = baseFormFields;

    useEffect(() => {
        setLoading(true);
        if (id) {
            studentsService
                .get(id)
                .then((response: AxiosResponse<{ data: IStudentResponse }>) => {
                    setInitialValues(
                        Object.entries({ ...init, ...response.data.data }).reduce((prev, [key, value]) => {
                            return { ...prev, [key]: (typeof value === 'object' ? value?.id : value) ?? '' };
                        }, {}) as IStudentsForm,
                    );

                    if (response.data?.data?.functionalUser) {
                        setFoundUser(true);
                    }
                })
                .finally(() => setLoading(false));
        } else {
            usersService
                .mccId()
                .then((response) => {
                    setInitialValues((prev) => ({ ...prev, mccId: response.data }));
                })
                .finally(() => setLoading(false));
        }
    }, [setInitialValues, id]);

    const handleSubmit = async (form: IStudentsForm, { setErrors, setSubmitting }: FormikHelpers<IStudentsForm>) => {
        setSubmitting(true);
        await (id ? studentsService.updatePost(id, form) : studentsService.create(form))
            .then(() => {
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                        redirect: backUrl,
                    }),
                );
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
            })
            .finally(() => setSubmitting(false));
    };

    const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            await Yup.string().email().required().validate(e.target.value);

            studentsService.findByEmail(e.target.value).then((response: AxiosResponse<{ data: IStudentsForm }>) => {
                if (response.data.data) {
                    setFoundUser(true);
                    setLoading(true);
                    setInitialValues((prev) => ({ ...prev, ...response.data.data }));
                    if (response.data.data.activated) {
                        setDisableCheckBox(response.data.data.activated);
                    }
                    setLoading(false);
                } else {
                    setFoundUser(false);
                }
            });
        } catch {
            setFoundUser(false);
            return;
        }
    };

    if (!readOnly) {
        fields[0] = { ...fields[0], props: { onChange: handleEmailChange } };

        fields = fields.map((elem: Input) => {
            if (['firstName', 'lastName', 'campus'].includes(elem.name)) {
                return {
                    ...elem,
                    props: {
                        ...elem.props,
                        disabled: foundUser && !!initialValues[elem.name],
                    },
                };
            }
            return elem;
        }, fields);
    }

    if (loading || fieldsLoading) return <MCCLoading />;
    return (
        <Accordion title={title}>
            <>
                {initialValues.userId ? <ProfilePicture userId={initialValues.userId} /> : <></>}
                <Form
                    fields={fields}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hideButtons
                    readonly={readOnly || user.archive}
                >
                    {(props) => (
                        <>
                            <ExtraStudentForm
                                {...props}
                                setFoundUser={setFoundUser}
                                readOnly={readOnly || user.archive}
                                disableCheckbox={disableCheckBox}
                                baseLoading={loading}
                                detailed={false}
                            />
                            <Grid item xs={12} container spacing={2}>
                                {!readOnly && !user.archive && (
                                    <Grid item>
                                        <LoadingButton variant="contained" type="submit" loading={props.isSubmitting}>
                                            Mentés
                                        </LoadingButton>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button variant="outlined" onClick={handleBack}>
                                        Vissza
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Form>
            </>
        </Accordion>
    );
}
