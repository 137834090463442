import { LanguageExamType } from '../../../../pages/Administration/LangExamReport/types';
import { LanguageExamBase, LanguageExamCategory } from './types';

export const getCategory = (category: LanguageExamCategory): 'Komplex' | 'Szóbeli' | 'Írásbeli' =>
    category === 'complex' ? 'Komplex' : category === 'oral' ? 'Szóbeli' : 'Írásbeli';

export const createLanguageExamLabel = ({ language, category, level }: LanguageExamBase): string =>
    [language, getCategory(category), level].join(' - ');

export const getType = (type: LanguageExamType): 'Általános' | 'Szakmai' | '-' => {
    if (type === 'general') {
        return 'Általános';
    } else if (type === 'professional') {
        return 'Szakmai';
    }

    return '-';
};
