import Close from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Dispatch, SetStateAction } from 'react';
import { theme } from '../../../utils/theme';

export default function CloseButton({ setOpen }: { setOpen: Dispatch<SetStateAction<boolean>> }) {
    return (
        <Grid item container xs={12} justifyContent="flex-end">
            <Grid item>
                <Tooltip title="Bezárás">
                    <IconButton
                        onClick={() => setOpen(false)}
                        color="secondary"
                        style={{ backgroundColor: theme.palette.primary.main }}
                    >
                        <Close />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}
