import Tooltip from '@mui/material/Tooltip';
import { theme } from '../../../../../../../utils/theme';
import { ICourseCompletion } from '../../types';
import { INFO_COLOR } from '../../utils';

export default function CompletionCell({ completed, minToBeCompleted, enrolled, status, tooltip }: ICourseCompletion) {
    const getColor = () =>
        status === 'in_progress' ? INFO_COLOR : theme.palette[status === 'success' ? 'success' : 'error'].main;

    const Core = () => (
        <>
            <span
                style={{
                    color: getColor(),
                }}
            >
                {completed}
            </span>
            /{enrolled}/{minToBeCompleted} db
        </>
    );

    return (
        <>
            {tooltip ? (
                <Tooltip
                    title={
                        tooltip.includes(';') ? (
                            <>
                                {tooltip.split(';').map((val) => (
                                    <div key={val}>{val}</div>
                                ))}
                            </>
                        ) : (
                            tooltip
                        )
                    }
                >
                    <div>
                        <Core />
                    </div>
                </Tooltip>
            ) : (
                <Core />
            )}
        </>
    );
}
