import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useCallback, useContext } from 'react';
import UploadButton from '../../../../../components/UploadButton';
import RefreshContext from '../../../../../studentPages/SelfAssessment/RefreshContextProvider';
import MenuContext from '../List/MenuContextProvider';
import { ImportDialogProps } from './types';
import { errorMessage, importContext, unknownError } from './utils';
import GridRenderCellParamsContext from '../List/GridRenderCellParamsContextProvider';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { create } from '../../../../../store/notification';

export default function ImportDialog({ openState }: ImportDialogProps) {
    const [file, setFile] = React.useState<File | null>(null);
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = openState;
    const setter = useContext(MenuContext);
    const refresh = useContext(RefreshContext);
    const params = useContext(GridRenderCellParamsContext);
    const dispatch = useDispatch();

    const onClose = React.useCallback(() => {
        setOpen(false);
        setFile(null);
        setError(null);
        setter(null);
    }, [setOpen, setter]);

    const handleFileChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const fileTmp = event.target.files?.[0];

        if (!fileTmp) {
            setError('A fájl nem található');
            return;
        }

        setFile(fileTmp);
    }, []);

    const checking = useCallback(
        (id: string) => {
            setTimeout(() => {
                importContext
                    .check(id)
                    .then(({ data }) => {
                        if (
                            data.status === 'error_validation' ||
                            data.status === 'success' ||
                            data.status === 'error_unknown'
                        ) {
                            if (data.status === 'success') {
                                dispatch(create({ message: 'Sikeres importálás', type: 'success' }));
                                onClose();
                                refresh();
                            } else {
                                setError(errorMessage);
                            }
                        } else {
                            checking(id);
                        }
                    })
                    .catch(() => {
                        setError(unknownError);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }, 1000);
        },
        [dispatch, onClose, refresh],
    );

    const handleUpload = React.useCallback(() => {
        if (file && params) {
            dispatch(create({ message: 'Importálás folyamatban...', type: 'info' }));
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('scholarshipGroup', '' + params.id);
            importContext
                .upload(formData)
                .then(({ data }) => {
                    const { id } = data;
                    checking(id);
                })
                .catch(({ response }: AxiosError) => {
                    setLoading(false);
                    setError(response?.data?.err || unknownError);
                });
        }
    }, [checking, dispatch, file, params]);

    const handleDelete = React.useCallback(() => setFile(null), []);

    return (
        <Grid item>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Kitöltött lista importálása</DialogTitle>
                <DialogContent style={{ marginTop: '16px' }}>
                    <DialogContentText>
                        A Kitöltött lista importálásához válaszd ki az importálandó .xlsx fájlt, majd kattints az
                        Importálás gombra.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                value={file?.name || ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <InsertDriveFileIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <UploadButton accept=".xlsx" handleFileChange={handleFileChange} loading={loading} />
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<DeleteIcon />}
                                    disabled={loading || !file}
                                    onClick={handleDelete}
                                    color="error"
                                >
                                    Törlés
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {error && (
                        <Grid item xs={12} style={{ marginTop: '8px' }}>
                            <Alert severity="error">{error}</Alert>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} variant="outlined">
                        Mégse
                    </Button>
                    <LoadingButton onClick={handleUpload} disabled={!file} loading={loading} variant="contained">
                        Importálás
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
