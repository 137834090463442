import SemesterFees from '../../../pages/MasterData/Finance/AdministrationFees/SemesterFees';
import SemesterFeeForm from '../../../pages/MasterData/Finance/AdministrationFees/SemesterFees/Form';

export const administrationFeesSemesters = {
    path: ':feeId/semesters',
    children: [
        {
            path: '',
            element: <SemesterFees />,
            name: 'md_finance_administration_fees_semesters_list',
        },
        {
            path: ':feeValId/edit',
            element: <SemesterFeeForm />,
            name: 'md_finance_administration_fees_semesters_edit',
        },
        {
            path: ':new',
            element: <SemesterFeeForm />,
            name: 'md_finance_administration_fees_semesters_new',
        },
    ],
};
