import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../utils/theme';

const useStyle = makeStyles(() => ({
    title: {
        fontSize: 'large',
        borderBottom: '1px solid ' + theme.palette.secondary.main,
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
    },
    error: {
        color: '#f44336',
        fontSize: '0.75rem',
    },
}));

//TODO: ezt kivinni template-be esetleg
export interface TitleProps {
    title: string;
    error?: string;
    children?: React.ReactNode;
}

export default function Title(props: TitleProps) {
    const classes = useStyle();
    const { title } = props;

    return (
        <>
            <Typography className={classes.title}>{title}</Typography>
            {props.children}
            {props.error && <Typography className={classes.error}>{props.error}</Typography>}
        </>
    );
}
