import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ListPage, MultiselectField, setInfo as setInfo2, TextField } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MCCLoading from '../../../../components/MCCLoading';
import i18n from '../../../../i18n';
import { create } from '../../../../store/notification';
import { findLabelByValue, Option, paginatorInfoBuild } from '../../../../utils/AppConst';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { IInfo } from '../../../../utils/Interfaces/IInfo';
import { EventLog } from '../../../../utils/Interfaces/interfaces';
import ActionCellRenderer from '../../LogCellRenderers/ActionCellRenderer';
import ActorCellRenderer from '../../LogCellRenderers/ActorCellRenderer';
import BtnCellRenderer from '../../LogCellRenderers/BtnCellRenderer';
import TypeCellRenderer from '../../LogCellRenderers/TypeCellRenderer';
import eventLogsService from '../../../../services/administration/eventLogs';
import StudentCellRenderer from '../../LogCellRenderers/StudentCellRenderer';
import CourseCellRenderer from '../../LogCellRenderers/CourseCellRenderer';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { columns } from './utils';
import { useTenant } from '../../../../components/TenantContext';

export const useStyles = makeStyles((theme: Theme) => ({
    eyeIcon: {
        color: 'white',
        backgroundColor: '#0288d1',
        '&:hover': {
            backgroundColor: 'white',
            color: '#0288d1',
        },
    },
    title: {
        fontSize: 'large',
        borderBottom: '1px solid ' + theme.palette.secondary.main,
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
    },
    error: {
        color: '#f44336',
        fontSize: '0.75rem',
    },
    tableHeaderCell: {
        fontWeight: 'bold',
    },
}));

interface EventLogFilter {
    insertDate: string;
    'createdAt[after]': string;
    'createdAt[before]': string;
    user: string;
    type: string;
    action: string;
    affectedStudent: string;
    affectedCourse: string;
}

const initialState: IInfo<EventLogFilter> = {
    filter: {},
    sort: {
        insertDate: 'desc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

export default function EventLogs() {
    const [data, setData] = useState<EventLog[]>([]);
    const [actions, setActions] = useState<string[]>([]);
    const [types, setTypes] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [info, setInfo] = useState(initialState);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const tenant = useTenant();

    const search = useCallback(
        (form: IInfo<EventLogFilter>) => {
            axios
                .all([
                    eventLogsService.getActions().then((res) => {
                        setActions(res.data['hydra:member']);
                    }),
                    eventLogsService.getTypes().then((res) => {
                        setTypes(res.data['hydra:member']);
                    }),
                    eventLogsService
                        .filter({ ...form.filter, ...paginatorInfoBuild(form) })
                        .then((d: AxiosResponse) => {
                            const totalItems = d.data.metadata.allCount;
                            const filteredItems = d.data.metadata?.filteredCount;
                            setData(d.data['data']);
                            setInfo((prev) => ({
                                ...prev,
                                metadata: {
                                    'allCount': totalItems,
                                    'filteredCount': filteredItems,
                                    'lastPage': totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                                    'page': info.page,
                                    'perpage': info.perpage,
                                },
                            }));
                        }),
                ])
                .catch(() =>
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    ),
                )
                .finally(() => {
                    setTableLoading(false);
                    setLoading(false);
                });
        },
        [dispatch, info.page, info.perpage],
    );

    const fetchData = useCallback(
        (form: IInfo<EventLogFilter>) => {
            setTableLoading(true);
            search(form);
        },
        [search],
    );

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            fetchData(initialState);
        }
    }, [fetchData, firstLoad]);

    const header = {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_sys_log]: 'Napló',
            [clientEndPoints.admin_event_logs_list]: 'Eseménynapló',
        },
        title: 'Eseménynapló',
        project: tenant || 'TAR',
    };

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInfo((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [event.target.name]: event.target.value,
            },
        }));
    };

    const propsSearch = {
        info: info,
        setInfo: (name: string, data: IInfo<EventLogFilter>) =>
            setInfo((prevState) => ({
                ...prevState,
                [name]: data,
            })),
        search: (form: IInfo<EventLogFilter>) => {
            search(form);
        },
    };
    const handleMultiChange = (fieldName: string) => {
        return (_: unknown, v: { value: string }[]) => {
            setInfo((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    [fieldName]: v?.map((obj) => obj.value || obj),
                },
            }));
        };
    };
    const resetForm = () => {
        setInfo(initialState);
    };

    const filterParam = {
        form: info.filter,
        setForm: () => {
            resetForm();
            setInfo2(propsSearch, 'filter', {});
        },
        onSubmit: () => {
            setInfo2(propsSearch, 'filter', info.filter);
        },
    };
    if (loading) {
        return <MCCLoading />;
    }

    return (
        <ListPage
            header={header}
            filter={filterParam}
            table={{
                columnDefs: columns,
                columnBuffer: columns.length,
                rowData: data,
                loading: tableLoading,
                searchfunc: fetchData,
                info: info,
                server: true,
                initialState: {
                    sorting: {
                        sortModel: [{ field: 'insertDate', sort: 'desc' }],
                    },
                },
                setInfo: setValueByKey,
                frameworkComponents: {
                    btnCellRenderer: BtnCellRenderer,
                    actorCellRenderer: ActorCellRenderer,
                    actionCellRenderer: (params: GridRenderCellParams) => (
                        <ActionCellRenderer {...params} page="eventlog" />
                    ),
                    typeCellRenderer: (params: GridRenderCellParams) => (
                        <TypeCellRenderer {...params} page="eventlog" />
                    ),
                    affectedStudentRenderer: StudentCellRenderer,
                    affectedCourseRenderer: CourseCellRenderer,
                },
            }}
        >
            <Grid item container spacing={2} xs={12} wrap={'wrap'} style={{ marginTop: 3 }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Felhasználó"
                        name="user"
                        value={info?.filter['user'] || ''}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MultiselectField
                        onChange={handleMultiChange('action')}
                        label="Esemény"
                        options={actions
                            .filter((element) => element != 'delete')
                            .map((action: string) => ({
                                value: action,
                                label: i18n.t('administration.eventlog.action.' + action) as string,
                            }))}
                        value={Array.isArray(info.filter.action) ? info.filter.action : []}
                        getOptionLabel={(option: Option | string) =>
                            i18n.t(
                                typeof option === 'string'
                                    ? findLabelByValue(
                                          option,
                                          actions.map((action: string) => ({
                                              value: action,
                                              label: i18n.t('administration.eventlog.action.' + action) as string,
                                          })),
                                      ) || ''
                                    : option.label,
                            )
                        }
                        isOptionEqualToValue={(option: Option, value: Option | string) => {
                            return typeof value === 'string' ? value === option.value : option.value === value.value;
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Érintett hallgató"
                        name="affectedStudent"
                        value={info?.filter['affectedStudent'] || ''}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Érintett kurzus"
                        name="affectedCourse"
                        value={info?.filter['affectedCourse'] || ''}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MultiselectField
                        onChange={handleMultiChange('objectType')}
                        label="Típus"
                        options={types.map((action: string) => ({
                            value: action,
                            label: i18n.t('administration.log.type.' + action) as string,
                        }))}
                        value={Array.isArray(info.filter.objectType) ? info.filter.objectType : []}
                        getOptionLabel={(option: Option | string) =>
                            i18n.t(
                                typeof option === 'string'
                                    ? findLabelByValue(
                                          option,
                                          types.map((action: string) => ({
                                              value: action,
                                              label: i18n.t('administration.log.type.' + action) as string,
                                          })),
                                      ) || ''
                                    : option.label,
                            )
                        }
                        isOptionEqualToValue={(option: Option, value: Option | string) =>
                            typeof value === 'string' ? value === option.value : option.value === value.value
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.title}>{'Időpont'}</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Időpont eleje"
                        name="createdAt[after]"
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={info.filter['createdAt[after]'] || ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Időpont vége"
                        name="createdAt[before]"
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={info.filter['createdAt[before]'] || ''}
                    />
                </Grid>
            </Grid>
        </ListPage>
    );
}
