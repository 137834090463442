import API from '../../api';
import { endpoints } from '../../utils/endPoints';
import { IForm } from '../../utils/Interfaces/interfaces';
import { crudTemplate } from '../templates';

const documentService = {
    ...crudTemplate<IForm>(endpoints.administration.document.main),
    filter: (form: IForm) => API.get(endpoints.administration.document.filter, { params: form }),
    create: (form: IForm) => API.post(endpoints.administration.document.create, form),
    update: (id: string | number, form: IForm) => API.post(`${endpoints.administration.document.main}/${id}`, form),
    getTrainingPrograms: (documentId: string | number, trainingLevelId: string | number) =>
        API.get(
            endpoints.administration.document.getTrainingPrograms
                .replace('{documentId}', documentId as string)
                .replace('{trainingLevelId}', trainingLevelId as string),
        ),
    studentFilter: () => API.get(endpoints.students.document.list),
};

export default documentService;
