import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CancelButton } from '@silinfo/front-end-template';
import { useNavigate } from 'react-router-dom';

export default function TokenNotFoundComponent() {
    const navigate = useNavigate();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography textAlign="center" variant="h5">
                    Nem létezik az azonosító!
                </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center" textAlign="center">
                <CancelButton onClick={() => navigate('/felveteli')} fullWidth variant="outlined" size="large">
                    Vissza a felvételi felületre
                </CancelButton>
            </Grid>
        </Grid>
    );
}
