import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Loading, NewButton, Select } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ISemester } from '../../../pages/CourseManagement/Courses/types';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';
import Performance from './Performance';
import RightPanel from '../CourseTypeRequirements';
import Table from './Table';
import useMediaQuery from '@mui/material/useMediaQuery';

type RequirementSemesterOption = ISemester & { startTime: number };

export default function SemesterLevel() {
    const [searchParams] = useSearchParams();
    const id = searchParams.get('semester') || '0';
    const [semester, setSemester] = useState<string>(id);
    const { loading, data } = useFetch<RequirementSemesterOption[]>(endpoints.students.requirements.semesters);
    const matches = useMediaQuery('(min-width: 960px)', { noSsr: true });

    useEffect(() => {
        if (!searchParams.get('semester') && !loading && data.length > 0) {
            const activeSemester = data.find((e) => e.active);
            setSemester(activeSemester ? '' + activeSemester.id : '' + data.slice(1)[0].id);
        }
    }, [data, loading, searchParams]);

    const handleSelectChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSemester(e.target.value), []);
    if (loading) return <Loading noHeight />;
    if (data.length === 0) return <Typography>Még nem volt aktív szemesztered.</Typography>;

    const semesterOptions: Option[] =
        data
            .slice(1)
            .sort((a, b) => {
                return a.startTime < b.startTime ? 1 : -1;
            })
            .map((e) => ({
                value: '' + e.id,
                label: e.name,
            })) || [];

    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item container justifyContent="flex-end" xs={12}>
                <Grid item>
                    <Link to="/student/course-enrollment">
                        <NewButton>Kurzus felvétele</NewButton>
                    </Link>
                </Grid>
            </Grid>
            <Grid item container xs={12} md={8} spacing={2} alignSelf="flex-start">
                <Grid item container xs={12} spacing={2}>
                    <Grid item container alignItems="center" justifyContent="center" xs={12} md={6}>
                        <Select options={semesterOptions} value={semester} onChange={handleSelectChange} />
                    </Grid>
                    <Performance semester={semester} />
                </Grid>
                <Grid item xs={12}>
                    <Divider>Pontszámos kurzusok</Divider>
                </Grid>
                <Grid item xs={12}>
                    {semester !== '0' ? (
                        <div style={matches ? { overflow: 'auto', paddingBottom: '5px' } : {}}>
                            <Table semester={semester} />
                        </div>
                    ) : (
                        <Typography>{loading ? 'Betöltés...' : 'Még nem volt aktív szemesztered.'}</Typography>
                    )}
                </Grid>
            </Grid>
            <RightPanel url={endpoints.students.requirements.courseTypes(semester)} selectedProgram={null} />
        </Grid>
    );
}
