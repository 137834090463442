import SystemMessages from '../../pages/SystemMessages';

export const systemMessages = {
    path: 'system-messages',
    children: [
        {
            path: '',
            element: <SystemMessages />,
            name: 'system_messages',
        },
    ],
};
