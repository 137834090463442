import Grid from '@mui/material/Grid';
import { ICourseInfoDialogForm } from '../../../studentPages/Processes/InfoDialogs/CourseInfoDialogForm';

export interface ICourseInfoProps {
    data?: ICourseInfoDialogForm;
}

export function CourseInfo(props: ICourseInfoProps) {
    const { data } = props;

    if (!data) {
        return <></>;
    }

    return (
        <>
            <Grid item xs={12}>
                <b>Hallgató neve:</b> {data.student}
            </Grid>
            {data.tenant && (
                <Grid item xs={12}>
                    <b>Tenant:</b> {data.tenant}
                </Grid>
            )}
            <Grid item xs={12}>
                <b>Kurzus neve:</b> {data.name}
            </Grid>
            <Grid item xs={12}>
                <b>Kurzus kódja:</b> {data.code}
            </Grid>
            <Grid item xs={12}>
                <b>Szemeszter azonosító:</b> {data.semesterCode}
            </Grid>
            <Grid item xs={12}>
                <b>Kurzus aktuális és maximális létszáma:</b> {data.normalEnrollments} / {data.maxHeadCount}
            </Grid>
            <Grid item xs={12}>
                <b>Hallgató által beírt indoklás:</b> {data.reason}
            </Grid>
        </>
    );
}
