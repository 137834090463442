import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import Grid from '@mui/material/Grid';
import { Paper } from '../Paper';
import { ILanguageExam } from './LanguageExamRequirements';

const LanguageExamRow = (languageExam: ILanguageExam) => (
    <Paper elevation={3} style={{ width: '100%', padding: '8px 0' }}>
        <Grid item container xs={12}>
            <Grid item xs={5}>
                {languageExam.language}
            </Grid>
            <Grid item xs={5}>
                {languageExam.category} {languageExam.level}
            </Grid>
            <Grid item xs={2}>
                <PowerSettingsNew color={languageExam.status === 'completed' ? 'success' : 'error'} />
            </Grid>
        </Grid>
    </Paper>
);

export default LanguageExamRow;
