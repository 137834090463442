import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from '@mui/styles';
import { CancelButton, Loading, TableButton } from '@silinfo/front-end-template';
import axios from 'axios';
import { useEffect, useState } from 'react';
import approvalsService from '../../../../services/administration/approvals';
import IStudentRequestApproval, {
    initialStudentRequestApproval,
    IStudentRequestCourse,
} from '../../../../utils/Interfaces/IStudentRequestApproval';
import { CourseInfo } from '../CourseInfo';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    container: {
        background: 'rgba(0,0,0,0.1)',
        margin: '8px',
    },
    textContainer: {
        padding: '8px 16px',
        margin: '0px 0px 16px 0px',
    },
    eyeIcon: {
        backgroundColor: 'white',
        color: '#0288d1',
    },
}));

interface ApprovalCourseInfoStatusProps {
    objectId: number;
}

export default function ApprovalCourseInfoStatusDialog(props: ApprovalCourseInfoStatusProps) {
    return <DialogElement {...props} />;
}

const Content = (props: ApprovalCourseInfoStatusProps & { setOpen: (val: boolean) => void }) => {
    const { objectId, setOpen } = props;
    const classes = useStyles();
    const [initialLoading, setInitialLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(initialStudentRequestApproval);
    useEffect(() => {
        axios
            .all([approvalsService.filterDataStatuses(), approvalsService.get(objectId)])
            .then((res) => {
                setInitialValues(res[1].data.data);
            })
            .catch(() => alert('hiba'))
            .finally(() => setInitialLoading(false));
    }, [objectId]);

    if (initialLoading) return <Loading noHeight />;

    return (
        <>
            <DialogTitle className={classes.title}>Hallgatói kérvény: {initialValues.type.toLowerCase()}</DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    <Paper className={classes.container} elevation={4}>
                        <Grid container className={classes.textContainer}>
                            {showRequestInfo(initialValues)}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <CancelButton onClick={() => setOpen(false)}>Bezár</CancelButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};

// Dialog element létrehozás
function DialogElement(props: ApprovalCourseInfoStatusProps) {
    const { objectId } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableButton
                onClick={() => setOpen(true)}
                tooltip={`Információ`}
                color={'info'}
                className={classes.eyeIcon}
            >
                <InfoIcon />
            </TableButton>
            <DialogBasic open={open} onClose={() => setOpen(false)} fullWidth>
                {open && <Content objectId={objectId} setOpen={setOpen} />}
            </DialogBasic>
        </>
    );
}

function showRequestInfo(data: IStudentRequestApproval) {
    return (
        <CourseInfo
            data={{
                student: data.student,
                tenant: data.tenant,
                reason: data.reason,
                ...(data.course as Required<IStudentRequestCourse>),
            }}
        />
    );
}
