import { GridColDef } from '@mui/x-data-grid-pro';
import instance from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { IStudentCompletionData } from '../../Administration/CourseCompletions/types';
import IconButton from '@mui/material/IconButton';
import QuizIcon from '@mui/icons-material/Quiz';
import Tooltip from '@mui/material/Tooltip';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { GridRenderCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';

export const header = (tenant: string | null) => {
    return {
        title: 'Jelentkezések riport',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

export const breadcrumbs = {
    admission: 'Felvételi',
    riport: 'Jelentkezések riport',
};

const autoload = true as const;

export const columns = (archive?: boolean): GridColDef<IStudentCompletionData>[] =>
    [
        {
            field: 'semester',
            headerName: 'Szemeszter',
        },
        {
            field: 'serial',
            headerName: 'Sorrend',
            minWidth: 75,
        },
        {
            field: 'campus',
            headerName: 'Campus',
            minWidth: 200,
        },
        {
            field: 'trainingLevel',
            headerName: 'Képzési szint',
        },
        {
            field: 'trainingProgram',
            headerName: 'Képzési program',
        },
        {
            field: 'point',
            headerName: 'Pontszám',
            minWidth: 100,
        },
        {
            field: 'status',
            headerName: 'Státusz',
        },
        ...(!archive
            ? [
                  {
                      field: 'actions',
                      headerName: 'Műveletek',
                      renderCell: (params: GridRenderCellParams) => {
                          const handlePreview = () => {
                              window.open(
                                  clientEndPoints.admission_applicant_report_templates_preview.replace(
                                      ':id',
                                      `${params.row.id}`,
                                  ),
                                  '_blank',
                              );
                          };
                          return (
                              <Tooltip
                                  title={
                                      params.row.filled
                                          ? 'Kitöltött felvételi űrlap megtekintése'
                                          : 'A jelentkező nem töltötte ki a felvételi űrlapot!'
                                  }
                              >
                                  <Grid item>
                                      <IconButton
                                          size="small"
                                          sx={{
                                              borderWidth: '1px',
                                              borderStyle: 'solid',
                                              color: '#364354',
                                          }}
                                          disableRipple
                                          disabled={!params.row.filled}
                                      >
                                          <QuizIcon onClick={handlePreview} sx={{ cursor: 'pointer' }} />
                                      </IconButton>
                                  </Grid>
                              </Tooltip>
                          );
                      },
                  },
              ]
            : []),
    ].map((column) => ({
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        ...column,
    }));

export const service = (email: string) => ({
    filter: () => {
        return instance.get(endpoints.admission.applicantProgramList(email));
    },
});

export const tableProps = (email: string, archive?: boolean, tenant?: string) => ({
    autoload,
    header: header(tenant || 'TAR'),
    service: service(email),
    columns: columns(archive),
    refresh: email,
    responseKey: 'data',
});
