import { createTheme } from '@mui/material/styles';

const myTheme = {
    palette: {
        type: 'light',
        primary: {
            main: '#f3ca12',
            contrastText: '#fff',
        },
        secondary: {
            main: '#364354',
            contrastText: '#f3ca12',
        },
        info: {
            main: '#f3ca12',
            contrastText: '#fff',
        },
        success: {
            main: '#35A899',
            contrastText: '#fff',
        },
        error: {
            main: '#ff6961',
            contrastText: '#fff',
        },
    },
};

export const theme = createTheme(myTheme);
