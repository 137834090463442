import AccountBalance from '@mui/icons-material/AccountBalance';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Apartment from '@mui/icons-material/Apartment';
import Approval from '@mui/icons-material/Approval';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import AttachMoney from '@mui/icons-material/AttachMoney';
import AutoStories from '@mui/icons-material/AutoStories';
import DateRange from '@mui/icons-material/DateRange';
import Email from '@mui/icons-material/Email';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import Foundation from '@mui/icons-material/Foundation';
import Groups from '@mui/icons-material/Groups';
import HistoryEdu from '@mui/icons-material/HistoryEdu';
import MenuBook from '@mui/icons-material/MenuBook';
import Money from '@mui/icons-material/Money';
import Notifications from '@mui/icons-material/Notifications';
import People from '@mui/icons-material/People';
import PointOfSale from '@mui/icons-material/PointOfSale';
import RuleIcon from '@mui/icons-material/Rule';
import School from '@mui/icons-material/School';
import Settings from '@mui/icons-material/Settings';
import Storage from '@mui/icons-material/Storage';
import SummarizeIcon from '@mui/icons-material/Summarize';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Receipt from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/system';
import { Layout as BasicLayout } from '@silinfo/front-end-template';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import Logo from '../../images/mcc_logo.svg';
import userService from '../../services/masterData/users';
import { RootState, history } from '../../store';
import { AdminNotification } from '../../studentPages/components/Layout/Notification';
import { removeLayoutTyper, setJWTToken } from '../../utils/AuthHelper';
import ROLES from '../../utils/Roles';
import { clientEndPoints } from '../../utils/clientEndPoints';
import useSwitchCheck from '../../utils/useSwitchCheck';
import ChangePassword from './ChangePassword';
import LoginInAs from './LoginInAs';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import QuizIcon from '@mui/icons-material/Quiz';
import SavingsIcon from '@mui/icons-material/Savings';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import LockIcon from '@mui/icons-material/Lock';
import Key from '@mui/icons-material/Key';
import EditNoteOutlined from '@mui/icons-material/EditNoteOutlined';
import API from '../../api';
import { endpoints } from '../../utils/endPoints';
import { useTenant } from '../TenantContext';
import Cookies from 'universal-cookie';

interface SidebarMenuItem {
    to: string;
    icon: JSX.Element;
    text: string;
}

type SidebarMenuGroup = { [key: string]: SidebarMenuItem };

interface SidebarMenu {
    name: string;
    subMenus: SidebarMenuGroup;
}

type Sidebar = { [key: string]: SidebarMenu };

export const useGeneralMasterDataSubmenus = (): SidebarMenuGroup => {
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const { user } = useSelector((state: RootState) => state.auth);

    const getStudentTo = useCallback((): string => {
        if (accessTree?.md_general_students_list?.some((role: string) => user?.roles.includes(role)) || user?.admin) {
            return clientEndPoints.md_general_students_list;
        }
        if (
            accessTree?.md_general_students_list_base?.some((role: string) => user?.roles.includes(role)) ||
            user?.admin
        ) {
            return clientEndPoints.md_general_students_list_base;
        }
        if (accessTree?.md_general_students_list_read_only?.some((role: string) => user?.roles.includes(role))) {
            return clientEndPoints.md_general_students_list_read_only;
        }
        return clientEndPoints.md_general_students_list_base_read_only;
    }, [
        accessTree?.md_general_students_list,
        accessTree?.md_general_students_list_base,
        accessTree?.md_general_students_list_read_only,
        user?.admin,
        user?.roles,
    ]);

    return {
        users: {
            to: clientEndPoints.md_general_users_list,
            icon: <People />,
            text: 'Felhasználókezelés',
        },
        students: {
            to: getStudentTo(),
            icon: <School />,
            text: 'Hallgatók',
        },
        campuses: {
            to: clientEndPoints.md_general_campuses_list,
            icon: <Apartment />,
            text: 'Campusok',
        },
        universities: {
            to: clientEndPoints.md_general_universities_list,
            icon: <AccountBalance />,
            text: 'Egyetemek',
        },
        trainingLevels: {
            to: clientEndPoints.md_training_levels_list,
            icon: <MenuBook />,
            text: 'Képzési szintek',
        },
        semesters: {
            to: clientEndPoints.md_general_semesters_list,
            icon: <DateRange />,
            text: 'Szemeszterek',
        },
    };
};

export const financesSubmenus: SidebarMenuGroup = {
    administrationFees: {
        to: clientEndPoints.finance_administration_fees_list,
        icon: <Money />,
        text: 'Adminisztrációs díj',
    },
    financialApprovals: {
        to: clientEndPoints.finance_financial_approvals_list,
        icon: <Money />,
        text: 'Pénzügyi jóváhagyások',
    },
};

export const financesMasterDataSubmenus: SidebarMenuGroup = {
    administrationFees: {
        to: clientEndPoints.md_finance_administration_fees_list,
        icon: <PointOfSale />,
        text: 'Adminisztrációs díjak',
    },
};

export const coursesMasterDataSubmenus: SidebarMenuGroup = {
    courseTypes: {
        to: clientEndPoints.md_courses_course_types_list,
        icon: <MenuBook />,
        text: 'Kurzustípusok',
    },
};

export const systemLogSubMenus: SidebarMenuGroup = {
    systemLogs: {
        to: clientEndPoints.admin_sys_logs_list,
        icon: <Storage />,
        text: 'Rendszernapló',
    },
    emailLogs: {
        to: clientEndPoints.admin_email_logs_list,
        icon: <Email />,
        text: 'E-mail napló',
    },
    eventLogs: {
        to: clientEndPoints.admin_event_logs_list,
        icon: <AssessmentRoundedIcon />,
        text: 'Eseménynapló',
    },
    charmLogs: {
        to: clientEndPoints.admin_charm_logs_list,
        icon: <ReceiptLongIcon />,
        text: 'ChaRM napló',
    },
};

export const certificatesSubMenus: SidebarMenuGroup = {
    transcript: {
        to: clientEndPoints.admin_certificates_transcript,
        icon: <ReceiptLongIcon />,
        text: 'Transcript',
    },
    membership: {
        to: clientEndPoints.admin_certificates_membership,
        icon: <CardMembershipIcon />,
        text: 'Tagságigazolás',
    },
    confirmation_semester: {
        to: clientEndPoints.admin_certificates_confirmation_semester,
        icon: <FactCheckIcon />,
        text: 'Igazolás teljesítésről (szemeszter)',
    },
    confirmation_training_programs: {
        to: clientEndPoints.admin_certificates_confirmation_training_program,
        icon: <FactCheckIcon />,
        text: 'Igazolás teljesítésről (képzési program)',
    },
};

const masterDataSubmenus: SidebarMenuGroup = {
    general: {
        to: clientEndPoints.md_general,
        icon: <Foundation />,
        text: 'Általános',
    },
    courses: {
        to: clientEndPoints.md_courses,
        icon: <HistoryEdu />,
        text: 'Kurzusok',
    },
    finance: {
        to: clientEndPoints.md_finance,
        icon: <AttachMoney />,
        text: 'Pénzügy',
    },
};

const administrationSubMenus: SidebarMenuGroup = {
    settings: {
        to: clientEndPoints.admin_settings_list,
        icon: <Settings />,
        text: 'Beállítások',
    },
    api: {
        to: clientEndPoints.admin_api_keys_list,
        icon: <Key />,
        text: 'API kulcsok',
    },
    systemLogs: {
        to: clientEndPoints.admin_sys_log,
        icon: <Storage />,
        text: 'Napló',
    },
    pages: {
        to: clientEndPoints.admin_pages_list,
        icon: <AutoStories />,
        text: 'Oldalak',
    },
    documents: {
        to: clientEndPoints.admin_documents,
        icon: <DescriptionRoundedIcon />,
        text: 'Dokumentumok',
    },
    courseCompletions: {
        to: clientEndPoints.admin_course_completions,
        icon: <AssessmentRoundedIcon />,
        text: 'Képzésteljesítések',
    },
    programClosing: {
        to: clientEndPoints.admin_program_closing,
        icon: <LockIcon />,
        text: 'Képzészárás',
    },
    templates: {
        to: clientEndPoints.admin_questionnaire_templates,
        icon: <QuizIcon />,
        text: 'Kérdőívsablonok',
    },
    certificates: {
        to: clientEndPoints.admin_certificates,
        icon: <Receipt />,
        text: 'Igazolások',
    },
    langExamReport: {
        to: clientEndPoints.admin_lang_exam_report_list,
        icon: <HistoryEdu />,
        text: 'Nyelvvizsga riport',
    },
    approvals: {
        to: clientEndPoints.admin_approvals_list,
        icon: <Approval />,
        text: 'Jóváhagyások',
    },
    accessManagement: {
        to: clientEndPoints.admin_access_management_list,
        icon: <Groups />,
        text: 'Jogosultságok',
    },
    notifications: {
        to: clientEndPoints.admin_notifications_list,
        icon: <Notifications />,
        text: 'Értesítések',
    },
};

const selfAssessMentSubMenus: SidebarMenuGroup = {
    administration: {
        to: clientEndPoints.self_assessment_administration,
        icon: <AccessibilityNewIcon />,
        text: 'Adminisztráció',
    },
    report: {
        to: clientEndPoints.self_assessment_report,
        icon: <AccessibilityNewIcon />,
        text: 'Önértékelési riport',
    },
};

const courseManagementSubMenus: SidebarMenuGroup = {
    settings: {
        to: clientEndPoints.course_manamagement_course_list,
        icon: <FormatListBulleted />,
        text: 'Kurzuslista',
    },
    courseEvaluationReport: {
        to: clientEndPoints.course_evaluation_report,
        icon: <QuestionAnswerIcon />,
        text: 'Kurzusértékelési riport',
    },
};

const scholarshipSubMenus: SidebarMenuGroup = {
    administration: {
        to: clientEndPoints.scholarship_administration,
        icon: <SavingsIcon />,
        text: 'Adminisztráció',
    },
    groupManagement: {
        to: clientEndPoints.scholarship_group_management,
        icon: <SavingsIcon />,
        text: 'Csoportok kezelése',
    },
    report: {
        to: clientEndPoints.scholarship_report,
        icon: <SavingsIcon />,
        text: 'Ösztöndíj riport',
    },
};

const admissionSubMenus: SidebarMenuGroup = {
    administration: {
        to: clientEndPoints.admission_administration,
        icon: <EditNoteOutlined />,
        text: 'Adminisztráció',
    },
    programAdmissions: {
        to: clientEndPoints.admission_applicant_report,
        icon: <SummarizeIcon />,
        text: 'Jelentkezések riport',
    },
    riport: {
        to: clientEndPoints.admission_riport,
        icon: <RuleIcon />,
        text: 'Jelentkezések elbírálása',
    },
};

const mainMenus = {
    masterData: {
        name: 'Törzsadatok',
        subMenus: Object.values(masterDataSubmenus),
    },
    administration: {
        name: 'Adminisztráció',
        subMenus: Object.values(administrationSubMenus),
    },
    selfAssessment: {
        name: 'Önértékelések',
        subMenus: Object.values(selfAssessMentSubMenus),
    },
    courseManagement: {
        name: 'Kurzuskezelés',
        subMenus: Object.values(courseManagementSubMenus),
    },
    finance: {
        name: 'Pénzügy',
        subMenus: Object.values(financesSubmenus),
    },
    scholarshipSubMenus: {
        name: 'Ösztöndíjak',
        subMenus: Object.values(scholarshipSubMenus),
    },
    admission: {
        name: 'Felvételi',
        subMenus: Object.values(admissionSubMenus),
    },
};

const studentCoursesSubmenus = {
    courseEnrollment: {
        to: 'student/course-enrollment',
        icon: <AddCircleOutline />,
        text: 'Kurzusfelvétel',
    },
};

const studentMainMenus = {
    courses: {
        name: 'Kurzusok',
        subMenus: Object.values(studentCoursesSubmenus),
    },
};

export default function Layout() {
    const th = useTheme<Theme>();
    const matches = useMediaQuery(th.breakpoints.up('md'), { noSsr: true });
    const { user } = useSelector((state: RootState) => state.auth);
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [loginInAsOpen, setLoginInAsOpen] = useState(false);
    const tenant = useTenant();
    const canSwitch = useSwitchCheck();
    const checkRole = (role: string) => user.roles.indexOf(role) !== -1;
    const location = useLocation();
    const [isEpregImpersonation, setIsEpregImpersonation] = useState(false);
    const cookies = new Cookies();

    useEffect(() => {
        document.title = tenant || 'TAR';
        const params = new URLSearchParams(location.search);
        if (params.has('epregImpersonation')) {
            setLoginInAsOpen(true);
            setIsEpregImpersonation(true);
        }
    }, [tenant, location.search]);

    const headerAccountMenu = [
        {
            name: 'Rendszerválasztó',
            props: {
                onClick: () => (window.location.href = process.env.REACT_APP_AUTH_URL as string),
            },
        },
        ...(canSwitch.layoutTypes.length === 2
            ? [
                  {
                      name: 'Nézetváltás',
                      props: {
                          onClick: () => {
                              history.push('/switch-layout');
                          },
                      },
                  },
              ]
            : []),
        ...(checkRole(ROLES.ADMIN) && !user.archive && user.realUser === null
            ? [
                  {
                      name: 'Megszemélyesítés',
                      props: {
                          onClick: () => {
                              setLoginInAsOpen(true);
                          },
                      },
                  },
              ]
            : []),
        ...(user.realUser !== null
            ? [
                  {
                      name: 'Kilépés a megszemélyesítésből',
                      props: {
                          onClick: () => {
                              userService
                                  .userLoginInAs()
                                  .then((response: AxiosResponse) => {
                                      setJWTToken(response.data.token);
                                      removeLayoutTyper();
                                      cookies.remove('skip_sentry_errors');
                                      window.location.href = `${
                                          process.env.REACT_APP_AUTH_URL || 'https://auth01.mcc.hu/'
                                      }`;
                                  })
                                  .catch((error: AxiosError) => {
                                      console.error(error.response?.data?.error);
                                  });
                          },
                      },
                  },
              ]
            : []),
        {
            name: 'Jelszóváltoztatás',
            props: {
                onClick: () => {
                    setChangePasswordOpen(true);
                },
            },
        },
        {
            name: 'Kijelentkezés',
            props: {
                onClick: async () => {
                    await API.post(endpoints.logoutLog);
                    removeLayoutTyper();
                    window.location.href = process.env.REACT_APP_AUTH_URL + '/logout';
                },
            },
        },
    ];

    const createMenu = useCallback((): Sidebar => {
        let copiedMainMenus = {};
        if (user?.student && (!user?.roles || user?.roles.indexOf(ROLES.USER) === -1)) {
            copiedMainMenus = { ...studentMainMenus };
            return copiedMainMenus;
        } else {
            copiedMainMenus = Object.entries(mainMenus).reduce((acc, [key, value]) => {
                const result = value.subMenus.filter((menu) => {
                    // Find the key of the menu in the cliend endpoints
                    const endpoints: Record<string, string> = clientEndPoints;
                    const menuKey = Object.keys(endpoints).find((endpointKey) => endpoints[endpointKey] === menu.to);
                    if (!accessTree || !menuKey || !accessTree[menuKey]) {
                        return user?.admin;
                    } else {
                        return user?.admin || accessTree[menuKey].some((e: string) => user?.roles.includes(e));
                    }
                });

                if (result.length > 0) {
                    return { ...acc, [key]: { ...value, subMenus: result } };
                }

                return acc;
            }, {});

            return copiedMainMenus;
        }
    }, [accessTree, user?.admin, user?.roles, user?.student]);

    return (
        <BasicLayout
            logo={Logo}
            roles={createMenu()}
            headerAccountMenu={headerAccountMenu}
            headerAccountUsername={matches ? user?.lastName + ' ' + user?.firstName : ' '}
            drawerType={matches ? 'permanent' : 'temporary'}
            defaultExpanded={matches}
            notifications={<AdminNotification />}
            headerText={tenant || 'TAR'}
            toolbarProps={{
                variant: matches ? 'regular' : 'dense',
            }}
        >
            <Outlet />
            <ChangePassword open={changePasswordOpen} setOpen={setChangePasswordOpen} />
            <LoginInAs open={loginInAsOpen} setOpen={setLoginInAsOpen} epregFlag={isEpregImpersonation} />
        </BasicLayout>
    );
}
