import { IHeaderData } from './interfaces';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const IconMapper: Record<string, OverridableComponent<SvgIconTypeMap>> = {
    1: CloseIcon,
    notSufficient: CloseIcon,
    sufficient: CheckIcon,
    notEvaluable: QuestionMarkIcon,
    inProgress: HourglassEmptyIcon,
};

export const translations: Record<string, string> = {
    notSufficient: 'Nem teljesített',
    sufficient: 'Teljesített',
    notEvaluable: 'Nem értékelhető',
    inProgress: 'Folyamatban',
};

export const headerData = (isPoint?: boolean): IHeaderData[] => [
    { width: 7, label: 'Kurzuskód' },
    { width: 17, label: 'Kurzus neve' },
    { width: 3, label: isPoint ? 'Pontszám' : 'Garas' },
    { width: 5, label: 'Értékelés' },
];

export const notCountedText = 'A követelményrendszer alapján nem pontot érő kurzus.';

const success = '53 168 153';
const error = '211 47 47';
const info = '52 100 201';
const activeColorByRating = (rating: string) =>
    rating === '1' || rating === 'notSufficient' || rating === 'notEvaluable'
        ? error
        : rating === null || rating === ''
        ? info
        : success;
const ratingTextByRating = (rating: string) =>
    rating in translations
        ? translations[rating as keyof typeof translations]
        : rating === null || rating === ''
        ? translations.inProgress
        : rating;

const getIconByRating = (rating: string) =>
    rating in IconMapper
        ? IconMapper[rating as keyof typeof IconMapper]
        : rating === null || rating === ''
        ? IconMapper.inProgress
        : IconMapper.sufficient;

export { activeColorByRating, ratingTextByRating, getIconByRating };
