import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { CancelButton } from '@silinfo/front-end-template';
import { IAddress, IEventForm } from './utils';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { ILocation } from '../types';

const errorMsg = 'Az eseményhez egy helyszín csak egyszer vehető fel!';

interface FormErrors {
    [field: string]: string;
}

export default function FormButtons({
    readOnly,
    count,
    submitBtnTitle,
    handleClose,
}: {
    count: number;
    readOnly: boolean | undefined;
    submitBtnTitle: string;
    handleClose: () => void;
}) {
    const formikProps = useFormikContext<IEventForm>();

    const hasDuplicateRoomsOrLocations = formikProps.values.addresses.reduce(
        (acc: Record<string, number>, address: IAddress | ILocation) => {
            let key: string;
            if ('room' in address) {
                if (address.room) {
                    key = String(address.room);
                    if (!acc[key]) {
                        acc[key] = 0;
                    }
                    acc[key]++;
                }
            } else {
                key = '';
            }
            return acc;
        },
        {} as Record<string, number>,
    );

    const hasAtLeastTwoDuplicates = Object.values(hasDuplicateRoomsOrLocations).some((count) => count >= 2);

    const duplicateIndexes = useMemo(() => {
        const indexes: number[] = [];
        for (let i = 0; i < formikProps.values.addresses.length - 1; i++) {
            const currentAddress = formikProps.values.addresses[i] as IAddress | ILocation;
            for (let j = i + 1; j < formikProps.values.addresses.length; j++) {
                const nextAddress = formikProps.values.addresses[j];
                const isDuplicate: boolean =
                    'room' in currentAddress &&
                    'room' in nextAddress &&
                    !!(currentAddress.room && nextAddress.room) &&
                    currentAddress.room === nextAddress.room;
                if (isDuplicate) {
                    indexes.push(i, j);
                }
            }
        }
        return indexes;
    }, [formikProps.values.addresses]);

    useEffect(() => {
        const type = formikProps.values.external ? 'location' : 'room';
        if (duplicateIndexes.length > 0) {
            const errors: FormErrors = {};
            duplicateIndexes.forEach((e: number) => {
                errors[`addresses[${e}].${type}`] = errorMsg;
            });
            formikProps.setErrors(errors);
        } else {
            const errors = { ...formikProps.errors };
            Object.keys(errors).forEach((e: string) => {
                if (e.includes(type)) {
                    errors[e] = formikProps.errors[e]?.includes(errorMsg) ? '' : formikProps.errors[e];
                }
            });
            formikProps.setErrors(errors);
        }
    }, [duplicateIndexes, formikProps]);

    return (
        <Grid item container spacing={2} justifyContent="flex-end">
            {!readOnly && (
                <Grid item>
                    <LoadingButton
                        loading={count > 0}
                        variant="contained"
                        type="submit"
                        disabled={hasAtLeastTwoDuplicates}
                    >
                        {submitBtnTitle}
                    </LoadingButton>
                </Grid>
            )}
            <Grid item>
                <CancelButton onClick={handleClose}>Bezár</CancelButton>
            </Grid>
        </Grid>
    );
}
