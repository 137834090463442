import { useParams } from 'react-router-dom';
import EventForm from '../EventForm';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import { IEventForm } from '../utils';

export default function BasicData({
    objectId,
    handleClose,
    readOnly,
}: {
    objectId?: number;
    handleClose: () => void;
    readOnly: boolean | undefined;
}) {
    const { courseId } = useParams() as { courseId: string };

    const formikProps = useFormikContext<IEventForm>();

    return (
        <Grid item container spacing={1}>
            <EventForm
                handleClose={handleClose}
                objectId={objectId}
                courseId={+courseId}
                readOnly={readOnly}
                {...formikProps}
            />
        </Grid>
    );
}
