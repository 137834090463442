import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
export interface IRoomsForm {
    name: string;
    campus: string;
    building: string;
    floor: string;
    floorName: string;
    space: number;
    type: string;
    assignable: boolean;
}

const roomsService = {
    ...crudTemplate<IRoomsForm>(endpoints.masterData.rooms.main),
    filter: (form: IForm, campusId: string, buildingId: string, floorId: string) =>
        API.get(
            endpoints.masterData.rooms.filter
                .replace('{campusId}', campusId.toString())
                .replace('{buildingId}', buildingId.toString())
                .replace('{floorId}', floorId.toString()),
            { params: form },
        ),
    fetch: (floorId: string) => API.get(endpoints.masterData.rooms.fetch.replace('{floorId}', floorId + '')),
    fetchClassRooms: (floorId: string) =>
        API.get(endpoints.masterData.rooms.fetchClassRooms.replace('{floorId}', floorId + '')),
    fetchDormRooms: (floorId: string) =>
        API.get(endpoints.masterData.rooms.fetchDormRooms.replace('{floorId}', floorId + '')),
};

export default roomsService;
