import Items from '../../../pages/MasterData/General/Campuses/Buildings/Floors/Rooms/Items/Items';
import ItemsForm from '../../../pages/MasterData/General/Campuses/Buildings/Floors/Rooms/Items/ItemsForm';

export const campusesBuildingsFloorsRoomsItems = {
    path: ':roomId/items',
    children: [
        {
            path: '',
            element: <Items />,
            name: 'md_general_campuses_buildings_floors_rooms_items_list',
        },
        {
            path: ':itemId/show',
            element: <ItemsForm readOnly />,
            name: 'md_general_campuses_buildings_floors_rooms_items_show',
        },
    ],
};
