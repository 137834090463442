import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { ConfirmDialogProps } from '../types';

export default function ConfirmDialog({ openState, handleSave, loadingState }: ConfirmDialogProps) {
    const [open, setOpen] = openState;
    const [_, setLoading] = loadingState;
    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Megerősítés</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>
                <DialogContentText>
                    Figyelem! Az új képzési program kiválasztása a korábbi jelentkezés törlésével jár. Biztosan
                    folytatod?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Nem
                </Button>
                <Button onClick={handleSave} variant="contained">
                    Igen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
