import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { theme } from '../../utils/theme';
import Cell from './Cell';
import InfoDialog from './InfoDialog';
import { IEvent } from './types';
import { CSSProperties } from 'react';

const typeColorMap = {
    lecture: theme.palette.secondary.main,
    exam: theme.palette.primary.main,
    deadline: theme.palette.error.main,
    other: '#0288d1',
};

export default function Event(event: IEvent) {
    const { time, label, type, course, location } = event;
    const typeColor = typeColorMap[type];
    const style: CSSProperties = {
        backgroundColor: typeColor + 'F0',
        color: 'white',
        margin: '0.5rem',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    };

    const locations = location.map((loc, index) => <div key={index}>{loc}</div>);

    return (
        <Grid item container xs={12} style={style} justifyContent="space-between">
            <Cell>
                <Typography variant="h6">{time}</Typography>
            </Cell>
            <Cell>
                <Typography variant="h6">
                    <strong>{label}</strong>
                </Typography>
                <div>{course}</div>
                <div
                    style={{
                        visibility: locations ? 'visible' : 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginRight: 10,
                    }}
                >
                    {locations || '-'}
                </div>
            </Cell>
            <InfoDialog {...event} />
        </Grid>
    );
}
