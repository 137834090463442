import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ICoursePrerequisite } from '../../../../services/masterData/courses/coursePrerequisites/coursePrerequisites';

export const tableColumns = (
    renderCell: (row: GridRenderCellParams<ICoursePrerequisite, ICoursePrerequisite>) => JSX.Element,
    courseNameCellRenderer: (row: GridRenderCellParams<ICoursePrerequisite, ICoursePrerequisite>) => JSX.Element,
    hasAccess: boolean,
): GridColDef[] =>
    [
        {
            headerName: 'Tenant',
            field: 'tenant',
            maxWidth: 150,
        },
        {
            headerName: 'Kurzus törzsszáma',
            field: 'courseReferenceCode',
        },
        {
            headerName: 'Kurzus megnevezése',
            field: 'courseName',
            renderCell: courseNameCellRenderer,
        },
        ...(!hasAccess ? [] : [{ headerName: 'Műveletek', field: 'operations', renderCell, sortable: false }]),
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));
