import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';

export interface Form {
    label: string;
}

const universitiesAllService = {
    ...crudTemplate(endpoints.masterData.students.universities),
};

export default universitiesAllService;
