import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import { ICompletedTrainingProgram } from '../types';
import Table from './Table';

export default function CompletedTrainingProgramDialog(props: ICompletedTrainingProgram) {
    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} md={5}>
                <TextField disabled value={props.trainingLevel} label="Képzés szint" />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField disabled value={props.campus} label="Campus" />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField disabled value={props.semesters.length} label="Szemeszterek száma" />
            </Grid>
            <Table semesters={props.semesters} />
        </Grid>
    );
}
