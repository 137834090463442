import Grid from '@mui/material/Grid';
import { IAddress, IEventForm, IExternalAddress, iTRequestFields } from '../utils';
import EventDynamicFields from '../../../../../components/EventDynamicFields';
import { endpoints } from '../../../../../utils/endPoints';
import { Option } from '@silinfo/front-end-template/src/components/Form/types';
import { Loading } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import API from '../../../../../api';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SeparateLocationsCheckBox from '../SeparateLocationsCheckBox';
import Tooltip from '@mui/material/Tooltip';
import { useTabState } from '../TabStateContext';

export default function ItNeed({ readOnly }: { readOnly: boolean | undefined }) {
    const formikProps = useFormikContext<IEventForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Option[]>([]);
    const [separateLocations, setSeparateLocations] = useState(formikProps.values.itNeedPerLocation);
    const [value, setValue] = useState('0');
    const addresses = formikProps.values.addresses;
    const { tabState, setTabState } = useTabState();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        API.get(endpoints.courseManagement.courseList.listItNeed)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch(() => setLoading(false))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (firstLoad) {
            tabState.forEach((address, index) => {
                if (address.itRequests && index < addresses.length) {
                    formikProps.setFieldValue('addresses.' + index + '.itRequests', address.itRequests);
                }
            });
            setFirstLoad(false);
        }
    }, [addresses.length, firstLoad, formikProps, tabState]);

    useEffect(() => {
        return () => {
            setTabState((prevTabState) => {
                const updatedTabState = addresses.map((address, index) => {
                    const newITneeds = separateLocations
                        ? addresses[index]?.itRequests ?? []
                        : addresses[0]?.itRequests ?? [];

                    return {
                        ...address,
                        ...prevTabState[index],
                        itRequests: newITneeds,
                    };
                });

                return updatedTabState;
            });
        };
    }, [addresses, separateLocations, setTabState]);

    const handleChange = (newValue: boolean) => {
        formikProps.setFieldValue('itNeedPerLocation', newValue);
        if (separateLocations) {
            addresses?.forEach((_, index) => {
                if (index !== 0) {
                    formikProps.setFieldValue('addresses.' + index + '.itRequests', addresses[0].itRequests);
                }
            });
        }
    };

    return (
        <Grid item xs={12} container spacing={4} sx={{ paddingLeft: '2rem' }}>
            {loading ? (
                <Grid item xs={12} container spacing={4}>
                    <Loading noHeight />
                </Grid>
            ) : (
                <>
                    {separateLocations ? (
                        <>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '0.5rem' }} width="100%">
                                    <TabList
                                        variant="fullWidth"
                                        onChange={(_, val) => setValue(val)}
                                        aria-label="lab API tabs example"
                                        sx={{
                                            '& .MuiButtonBase-root': {
                                                backgroundColor: '#fff !important',
                                                color: '#22344c !important',
                                            },
                                        }}
                                    >
                                        {addresses?.map((element: IAddress | IExternalAddress, index) =>
                                            'roomName' in element ? (
                                                <Tab
                                                    key={index}
                                                    label={
                                                        <Tooltip title={element.roomName ?? 'Nincs megadva'}>
                                                            <span>
                                                                {element.roomName && element.roomName.length > 32
                                                                    ? element.roomName.substring(0, 32) + '...'
                                                                    : element.roomName ?? 'Nincs megadva'}
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                    value={'' + index}
                                                />
                                            ) : (
                                                <Tab
                                                    key={index}
                                                    label={
                                                        <Tooltip title={element.location ?? 'Nincs megadva'}>
                                                            <span>
                                                                {element.location && element.location.length > 32
                                                                    ? element.location.substring(0, 32) + '...'
                                                                    : element.location ?? 'Nincs megadva'}
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                    value={'' + index}
                                                />
                                            ),
                                        )}
                                    </TabList>
                                    {addresses?.map((_, index) => (
                                        <TabPanel key={index} value={'' + index}>
                                            <EventDynamicFields
                                                label="IT igény"
                                                keyName={'addresses.' + index + '.itRequests'}
                                                inputFields={(innerIndex) =>
                                                    iTRequestFields(data).slice(
                                                        0,
                                                        formikProps.values.addresses &&
                                                            formikProps.values.addresses[index].itRequests?.[
                                                                innerIndex
                                                            ]?.itRequest?.includes?.('other')
                                                            ? 3
                                                            : -1,
                                                    )
                                                }
                                                readOnly={readOnly}
                                            />
                                        </TabPanel>
                                    ))}
                                </Box>
                            </TabContext>
                            <SeparateLocationsCheckBox
                                checkedState={[separateLocations, setSeparateLocations]}
                                label="Helyszínenként külön szeretném beállítani az IT igényeket"
                                handleChange={handleChange}
                                readOnly={readOnly}
                            />
                        </>
                    ) : (
                        <>
                            <SeparateLocationsCheckBox
                                checkedState={[separateLocations, setSeparateLocations]}
                                label="Helyszínenként külön szeretném beállítani az IT igényeket"
                                handleChange={handleChange}
                                readOnly={readOnly}
                            />
                            <EventDynamicFields
                                label="IT igény"
                                keyName={'addresses.' + 0 + '.itRequests'}
                                inputFields={(index) =>
                                    iTRequestFields(data).slice(
                                        0,
                                        formikProps.values.addresses &&
                                            formikProps.values.addresses[0].itRequests?.[index]?.itRequest?.includes?.(
                                                'other',
                                            )
                                            ? 3
                                            : -1,
                                    )
                                }
                                readOnly={readOnly}
                            />
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
