import { Input } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import studentsService from '../../../../../services/masterData/students';
import { isKep, Option } from '../../../../../utils/AppConst';
import { endpoints } from '../../../../../utils/endPoints';
import useFetch from '../../../../../utils/useFetch';
import { Options } from '../utils';
import {
    categoryOptions,
    genderOptions,
    languageExamTypeOptions,
    languageOptions,
    legalRelationshipOptions,
    levelOptions,
    semesterStatusOptions,
    stayAbroadTypeOptions,
    trainingFormOptions,
    validatedOptions,
} from './options';
import axios from 'axios';
import { snakeCaseToCamelCase } from '../../../../../utils/caseConverter';
import { FieldRule } from './types';

export const baseFields = (options?: Options): Input[] => {
    return [
        { label: 'E-mail cím', name: 'user.email' },
        { label: 'Vezetéknév', name: 'user.lastName' },
        { label: 'Keresztnév', name: 'user.firstName' },
        {
            label: 'Nem',
            name: 'gender',
            type: 'multiselect',
            options: genderOptions,
        },
        {
            label: 'Campus',
            name: 'user.campus',
            type: 'multiselect',
            options: options?.campuses || [],
        },
        {
            label: 'Képzési szint',
            name: 'currentTrainingLevel',
            type: 'multiselect',
            options: options?.trainingLevels || [],
        },
        {
            label: 'Képzési program',
            name: 'currentTrainingProgram',
            type: 'multiselect',
            options: options?.trainingPrograms || [],
            props: {
                renderOption: (props: object, option: Option) => {
                    const { label } = option;
                    if (label.includes('(archív)')) {
                        return (
                            <li {...props} style={{ color: '#999' }}>
                                {option.label}
                            </li>
                        );
                    } else {
                        return <li {...props}>{option.label}</li>;
                    }
                },
            },
        },
        {
            label: 'Évfolyam',
            name: 'currentCollegeYear',
            type: 'multiselect',
            options: options?.collegeYears || [],
        },
        {
            label: 'Szemeszter státusz',
            name: 'semesterStatus',
            type: 'multiselect',
            options: [
                { label: 'Aktív', value: 'active' },
                { label: 'Külföldi ösztöndíjas', value: 'foreign_scholarship' },
                { label: 'Passzív', value: 'passive' },
            ],
        },
        {
            label: 'Aktuális státusz',
            name: 'currentMembershipStatus',
            type: 'multiselect',
            options: [
                { label: 'Bentlakó', value: 'internal' },
                { label: 'Külsős', value: 'external' },
            ],
        },
        {
            label: 'Letiltva',
            name: 'user.disabled',
            type: 'multiselect',
            options: [
                { value: '1', label: 'Igen' },
                { value: '0', label: 'Nem' },
            ],
        },
        {
            label: 'Kiiratkozva',
            name: 'terminating',
            type: 'multiselect',
            options: [
                { value: '1', label: 'Igen' },
                { value: '0', label: 'Nem' },
            ],
        },
    ];
};

export const useBaseFormFields = (
    setFieldsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
    readOnly = false,
    isEdit = false,
): Input[] => {
    const [statusOptions, setStatusOptions] = useState<Option[]>([]);
    useEffect(() => {
        if (setFieldsLoading) {
            // console.log('render1');
            setFieldsLoading(true);
        }
        studentsService
            .actualStatuses()
            .then((res) => {
                // console.log('render2');
                setStatusOptions(res.data);
            })
            .finally(() => {
                if (setFieldsLoading) {
                    //console.log('render3');
                    setFieldsLoading(false);
                }
            });
    }, [setFieldsLoading]);

    return [
        {
            label: 'E-mail cím',
            name: 'email',
            props: {
                disabled: readOnly,
            },
        },
        {
            label: 'Vezetéknév',
            name: 'lastName',
            fieldType: 'base',
            props: {
                disabled: readOnly,
                inputProps: { maxLength: 128 },
            },
        },
        {
            label: 'Keresztnév',
            name: 'firstName',
            fieldType: 'base',
            props: {
                disabled: readOnly,
                inputProps: { maxLength: 128 },
            },
        },
        {
            label: 'Nem',
            name: 'gender',
            type: 'select',
            options: genderOptions,
            props: {
                disabled: readOnly,
            },
        },
        {
            label: 'Telefonszám',
            name: 'phone',
            type: 'phone',
            props: {
                country: isKep() ? 'ro' : 'hu',
            },
        },
        {
            label: 'Státusz',
            name: 'status',
            type: 'select',
            options: statusOptions,
            props: {
                disabled: isEdit || readOnly,
            },
        },
        {
            label: 'Aktuális státusz',
            name: 'actualStatus',
            type: 'select',
            options: statusOptions,
            props: {
                disabled: isEdit || readOnly,
            },
        },
        {
            label: 'Szemeszter státusz',
            name: 'semesterStatus',
            type: 'select',
            options: semesterStatusOptions,
            props: {
                disabled: isEdit || readOnly,
            },
        },
        {
            label: 'MCC azonosító',
            name: 'mccId',
            fieldType: 'base',
            props: {
                disabled: true,
            },
        },
    ].map((field) => ({ ...field })) as Input[];
};

export const baseFormFieldsName = [
    'email',
    'lastName',
    'firstName',
    'gender',
    'phone',
    'status',
    'actualStatus',
    'semesterStatus',
    'mccId',
];

export const usePersonalData = (): Input[] => {
    const [nationalityOptions, setNationalityOptions] = useState<Option[]>([]);
    const [fieldRules, setFieldRules] = useState<FieldRule[]>([]);

    useEffect(() => {
        studentsService.nationalities().then((res) => setNationalityOptions(res.data));
        studentsService.listFieldRules().then((res) => setFieldRules(res.data));
    }, []);

    let fields = [
        { name: 'omId', label: 'Oktatási azonosító', props: { helperText: 'pl.: 71234567891' } },
        { name: 'taxId', label: 'Adóazonosító jel', props: { helperText: 'pl.: 8412731018' } },
        { name: 'identificationNumberCnp', label: 'Személyi szám (CNP)', props: { helperText: 'pl.: 8203114106118' } },
        { name: 'taj', label: 'TAJ szám', props: { helperText: 'pl.: 115652436' } },
        { name: 'idCardNumber', label: 'SZIG szám', props: { helperText: 'pl.: 346243XX' } },
        { name: 'kepIdCardNumber', label: 'SZIG szám - KEP', props: { helperText: 'pl.: XX522386' } },
        { name: 'bankName', label: 'Pénzintézet neve' },
        { name: 'bankAccountNumber', label: 'Bankszámlaszám' },
        { name: 'birthPlace', label: 'Születési hely' },
        { name: 'birthDate', label: 'Születési idő', type: 'date' },
        {
            name: 'nationality',
            label: 'Állampolgárság',
            type: 'multiselect',
            fieldType: 'base',
            options: nationalityOptions,
        },
        { name: 'motherLastName', label: 'Anyja vezetékneve' },
        { name: 'motherFirstName', label: 'Anyja keresztneve' },
    ];

    if (fieldRules.length > 0) {
        fields = fields.filter((field) => fieldRules.some((rule) => snakeCaseToCamelCase(rule.key) === field.name));
    } else {
        fields = [];
    }

    return fields as Input[];
};

export const residence: Input[] = [
    { name: 'postCode', label: 'Irányítószám', format: { xs: 4, md: 2 } },
    { name: 'city', label: 'Település', format: { xs: 8, md: 4 } },
    { name: 'streetName', label: 'Közterület neve', format: { xs: 6, md: 3 } },
    { name: 'streetType', label: 'Közterület jellege', format: { xs: 6, md: 3 } },
    { name: 'houseNumber', label: 'Házszám', format: { xs: 4, md: 2 } },
    { name: 'addressFloor', label: 'Emelet', format: { xs: 4, md: 2 } },
    { name: 'door', label: 'Ajtó', format: { xs: 4, md: 2 } },
];

export const useStudies = (): Input[] => {
    const [financeForms, setFinanceForms] = useState<Option[]>([]);
    useEffect(() => {
        axios.all([studentsService.financeForms()]).then((res) => {
            setFinanceForms(res[0].data);
        });
    }, []);

    let fields = [
        { name: 'university', label: 'Egyetem', type: 'select', options: [] },
        { name: 'foreignUniversity', label: 'Külföldi egyetem neve' },
        { name: 'major', label: 'Szak' },
        {
            name: 'trainingForm',
            type: 'select',
            label: 'Képzés formája',
            fieldType: 'base',
            options: trainingFormOptions,
        },
        {
            name: 'financeForm',
            type: 'select',
            label: 'Finanszírozás formája',
            fieldType: 'base',
            options: financeForms,
        },
        {
            name: 'semesterStatus',
            type: 'select',
            label: 'Hallgatói jogviszony',
            fieldType: 'base',
            options: legalRelationshipOptions,
        },
        { name: 'neptun', label: 'Neptun kód', props: { helperText: 'pl.: A1B2C3' } },
        { name: 'pedigreeNumber', label: 'Törzskönyvi szám (Număr matricol)' },
        {
            name: 'admission',
            label: 'Egyetemi felvételi határozat',
            type: 'file',
            origFileName: 'fileName',
            uuidFileName: 'filePath',
            tempType: 'student-document',
            downloadUrl: `${endpoints.fileManagement.download}?id={documentId}&type=student-document&subType=actual-university-studies`,
            format: { xs: 12 },
        },
    ];

    if (isKep()) {
        fields = fields.filter((field) => field.name !== 'neptun');
    } else {
        fields = fields.filter((field) => field.name !== 'pedigreeNumber');
    }

    return fields as Input[];
};

/*  További tanulmányok mezői  */

export const useEducation = (): Input[] => {
    //  const {data, loading} = useFetch(endpoints.masterData.students.universities, '');
    //todo: freesolo intézmény
    return [
        {
            name: 'institute',
            label: 'Intézmény',
        },
        { name: 'major', label: 'Szak' },
        { name: 'trainingType', type: 'select', label: 'Képzés formája', options: trainingFormOptions },
        {
            name: 'educationCertificate',
            type: 'file',
            label: 'Igazoló dokumentum',
            origFileName: 'origFileName',
            uuidFileName: 'uuidFileName',
            tempType: 'student-document',
            downloadUrl: `${endpoints.fileManagement.download}?id={documentId}&type=student-document&subType=education-document`,
        },
    ];
};

export const useLanguageCertification = (): Input[] => {
    return [
        { name: 'language', label: 'Nyelv', type: 'select', options: languageOptions },
        { name: 'type', label: 'Típus', type: 'select', options: languageExamTypeOptions },
        { name: 'level', label: 'Szint', options: levelOptions, type: 'select' },
        { name: 'category', label: 'Kategória', options: categoryOptions, type: 'select' },
        { name: 'examLocation', label: 'Szerzés helye' },
        { name: 'examDate', label: 'Szerzés dátuma', type: 'date' },
        {
            name: 'certification',
            label: 'Bizonyítvány',
            type: 'file',
            origFileName: 'origFileName',
            uuidFileName: 'uuidFileName',
            tempType: 'student-document',
            downloadUrl: `${endpoints.fileManagement.download}?id={documentId}&type=student-document&subType=student-language-exam`,
        },
        { name: 'validated', label: 'Validált', type: 'select', options: validatedOptions },
        { name: 'createdAt', label: 'Feltöltés ideje', props: { style: { display: 'none' } } },
    ];
};

export const foreignScholarship = (data: Option[]): Input[] => [
    { name: 'stayAbroadStart', label: 'Külföldi tartózkodás kezdete', type: 'date' },
    { name: 'stayAbroadEnd', label: 'Külföldi tartózkodás vége', type: 'date' },
    {
        name: 'stayAbroadType',
        label: 'Külföldi tartózkodás típusa',
        type: 'select',
        options: stayAbroadTypeOptions,
    },
    { name: 'hostName', label: 'Fogadó intézmény teljes neve' },
    { name: 'hostCountry', label: 'Fogadó intézmény országa', type: 'select', options: data },
    { name: 'hostCity', label: 'Fogadó intézmény városa' },
    { name: 'hostMajor', label: 'Fogadó intézménynél végzett szak/tevékenység megnevezése' },
];

export const useForeignScholarship = (): Input[] => {
    const { data } = useFetch<Option[]>(endpoints.masterData.students.countries.main, '');
    return foreignScholarship(data);
};

export const useInternship = (): Input[] => {
    const { data } = useFetch<Option[]>(endpoints.masterData.students.countries.main, '');

    return [
        { name: 'internshipStart', label: 'Szakmai gyakorlat kezdete', type: 'date' },
        { name: 'internshipEnd', label: 'Szakmai gyakorlat vége', type: 'date' },
        { name: 'employer', label: 'Munkáltató megnevezése' },
        { name: 'internshipCountry', label: 'Szakmai gyakorlat országa', type: 'select', options: data },
        { name: 'internshipCity', label: 'Szakmai gyakorlat városa' },
        { name: 'activity', label: 'Pozíció' },
    ];
};

export const resume = (): Input[] => {
    return [
        {
            name: 'resume',
            type: 'file',
            label: 'Önéletrajz',
            origFileName: 'certificateFileName',
            uuidFileName: 'certificateFilePath',
            tempType: 'student-document',
            downloadUrl: `${endpoints.fileManagement.download}?id={documentId}&type=student-document&subType=resume-document`,
        },
        { name: 'createdAt', label: 'Feltöltés dátuma', props: { style: { display: 'none' } } },
    ];
};

export const competitions = (): Input[] => {
    return [
        { name: 'competitionInstitution', label: 'Intézmény' },
        { name: 'competition', label: 'Verseny' },
        { name: 'competitionResult', label: 'Eredmény' },
        {
            name: 'competitionCertification',
            label: 'Igazoló dokumentum',
            type: 'file',
            origFileName: 'origFileName',
            uuidFileName: 'uuidFileName',
            tempType: 'student-document',
            downloadUrl: `${endpoints.fileManagement.download}?id={documentId}&type=student-document&subType=competition-certification`,
        },
    ];
};

export const useJobs = (): Input[] => {
    const { data } = useFetch<Option[]>(endpoints.masterData.students.countries.main, '');

    return [
        { name: 'jobStart', label: 'Munkahely idejének kezdete', type: 'date' },
        { name: 'jobStartEnd', label: 'Munkahely idejének vége', type: 'date' },
        { name: 'jobName', label: 'Munkahely megnevezése' },
        { name: 'jobCountry', label: 'Munkahely országa', options: data, type: 'select' },
        { name: 'jobCity', label: 'Munkahely városa' },
        { name: 'jobActivity', label: 'Pozíció' },
    ];
};

export const contacts = (): Input[] =>
    [
        { name: 'primaryContactPersonLastName', label: 'Kapcsolattartó vezetékneve' },
        { name: 'primaryContactPersonFirstName', label: 'Kapcsolattartó keresztneve' },
        { name: 'primaryContactPersonEmail', label: 'Kapcsolattartó e-mail címe' },
        {
            name: 'primaryContactPersonPhone',
            label: 'Kapcsolattartó telefonszáma',
            type: 'phone',
        },
        { name: 'secondaryContactPersonLastName', label: 'Kapcsolattartó vezetékneve' },
        { name: 'secondaryContactPersonFirstName', label: 'Kapcsolattartó keresztneve' },
        { name: 'secondaryContactPersonEmail', label: 'Kapcsolattartó e-mail címe' },
        {
            name: 'secondaryContactPersonPhone',
            label: 'Kapcsolattartó telefonszáma',
            type: 'phone',
        },
    ].map((input) => ({ ...input, format: { xs: 12, md: 6, xl: 3 } })) as Input[];
