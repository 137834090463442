import Visibility from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { ICourseView } from '../../../pages/CourseManagement/Courses/types';
import BaseData from './BaseData';
import CloseButton from './CloseButton';
import CourseName from './CourseName';
import Documents from './Documents';
import Events from './Events';
import InstructorData from './InstructorData';
import Rating from './Rating';

export default function CourseDialog(props: ICourseView) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableButton
                color="info"
                onClick={() => setOpen(true)}
                className="borderRadius"
                style={{
                    boxShadow:
                        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
                    borderRadius: '50%',
                }}
            >
                <Visibility />
            </TableButton>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                fullWidth
                maxWidth="xl"
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <CloseButton setOpen={setOpen} />
                        <CourseName {...props} />
                        <BaseData {...props} />
                        <InstructorData {...props} />
                        <Documents {...props} />
                        <Rating {...props} />
                        <Events {...props} />
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
