import Grid from '@mui/material/Grid';
import { Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { useEffect, useMemo, useState } from 'react';
import MCCLoading from '../../components/MCCLoading';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';
import FinanceTable, { IFinanceRow } from './FinanceTable';
import Statistics from './Statistics';
import UnpaidAmount from './UnpaidAmount';
import UnpaidCount from './UnpaidCount';
import Scholarships from './Scholarships';
import api from '../../api';
import { useTenant } from '../../components/TenantContext';

export default function Finances() {
    const { data, loading } = useFetch<IFinanceRow[]>(endpoints.students.finances.main) as {
        data: IFinanceRow[];
        loading: boolean;
    };
    const tenant = useTenant();
    const header: HeaderProps = {
        title: 'Pénzügyeim',
        project: tenant || 'TAR',
        breadcrumbs: {
            finances: 'Pénzügyeim',
        },
    };
    const [currency, setCurrency] = useState<string>('');

    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setCurrency(response.data.currencyTrans);
            })
            .catch((error) => {
                console.error('Error fetching current tenant currency:', error);
            });
    }, []);

    const paidFees = useMemo(() => data.filter((row) => row.status === 'paid'), [data]);
    const unpaidFees = useMemo(() => data.filter((row) => row.status === 'waiting_for_payment'), [data]);
    const unpaidFeesAmount = useMemo(() => unpaidFees.reduce((acc, row) => acc + row.amount, 0), [unpaidFees]);

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Grid item container xs={12} spacing={1}>
                <Scholarships currency={currency} />
                <Grid item container xs={12} md={data.length > 0 ? 8 : 12} spacing={2}>
                    <Grid item container xs={12} spacing={2}>
                        <UnpaidCount count={unpaidFees.length} />
                        <UnpaidAmount amount={unpaidFeesAmount} currency={currency} />
                    </Grid>
                    <FinanceTable title="Befizetésre váró tételek" rows={unpaidFees} currency={currency} />
                    <FinanceTable title="Korábbi befizetések" rows={paidFees} currency={currency} />
                </Grid>
                {data.length > 0 && <Statistics fees={data} currency={currency} />}
            </Grid>
        </Page>
    );
}
