import { Input } from '@silinfo/front-end-template';
import { Option } from '../../../../utils/AppConst';

export const fields = (options: { country: Option[] }): Input[] => [
    {
        name: 'name',
        label: 'Név',
    },
    {
        name: 'shortName',
        label: 'Rövid név',
    },
    {
        name: 'country',
        label: 'Ország',
        type: 'select',
        options: options.country,
    },
];
