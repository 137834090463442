import React, { createContext } from 'react';

type RefreshContextType = () => void;

const defaultContext: RefreshContextType = console.log;

const RefreshContext = createContext<RefreshContextType>(defaultContext);

interface RefreshContextProviderProps {
    children: React.ReactNode;
    refresh: RefreshContextType;
}

export function RefreshContextProvider(props: RefreshContextProviderProps) {
    return <RefreshContext.Provider value={props.refresh}>{props.children}</RefreshContext.Provider>;
}

export default RefreshContext;
