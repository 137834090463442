import Grid from '@mui/material/Grid';
// eslint-disable-next-line regex/invalid-warn
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import DeleteButton from './DeleteButton';
import ViewMessageButton from './ViewMessageButton';

interface OperationCellRendererProps extends GridRenderCellParams {
    refresh: () => void;
}

export default function OperationCellRenderer(props: OperationCellRendererProps) {
    const { refresh, row } = props;

    return (
        <Grid container spacing={1}>
            <ViewMessageButton refresh={refresh} id={'' + row.id} />
            <DeleteButton refresh={refresh} id={'' + row.id} />
        </Grid>
    );
}
