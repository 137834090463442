import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { addressFieldTypes, basicDataTypes, formFields, initialBaseData, otherDataTypesFirstRow } from '../utils';
import { Field, UserData } from '../types';
import { CancelButton, SubmitButton, Form } from '@silinfo/front-end-template';
import { endpoints } from '../../../../utils/endPoints';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './PhoneInput.css';
import MCCLoading from '../../../../components/MCCLoading';
import { useDispatch } from 'react-redux';
import { create } from '../../../../store/notification';
import { ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { defaultTheme } from '../../../../utils/AppConst';
import AdmissionsStyle from '../Admissions.module.scss';
import BasicDataFields from './BasicDataFields';
import BasicDataOtherFields from './BasicDataOtherFields';
import axios from 'axios';

export default function BasicData({
    fields,
    isRegistrated,
    token,
    handleNavigateToAdmissions,
    refreshFields,
    isForeignStudent,
    matches,
}: {
    fields: Field[];
    isRegistrated: boolean;
    token: string;
    handleNavigateToAdmissions: () => void;
    refreshFields: () => void;
    isForeignStudent: boolean;
    matches: boolean;
}) {
    const [editMode, _setEditMode] = useState<boolean>(!isRegistrated);
    const formData = formFields();

    const FormFields = () => {
        const [baseDataFields, setBaseDataFields] = useState<Field[]>([]);
        const [otherDataFields, setOtherDataFields] = useState<Field[]>([]);
        const [otherDataFieldsFirstRow, setOtherDataFieldsFirstRow] = useState<Field[]>([]);
        const [addressDataFields, setAddressDataFields] = useState<Field[]>([]);
        const [basicData, setBasicData] = useState<UserData>(initialBaseData);
        const [errors, setErrors] = useState<{ [key: string]: string }>({});
        const [nationalities, setNationalities] = useState<{ value: string; label: string }[]>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const dispatch = useDispatch();

        const handleSave = (navigate: boolean) => {
            setLoading(true);
            const saveData = {
                ...basicData,
                bankAccountNumber: basicData.bankAccountNumber?.replace(/-/g, ''),
                taj: basicData.socialSecurityNumber,
                foreignStudent: checked,
            };
            axios
                .post(endpoints.admission.saveBaseData.replace('{token}', token), saveData)
                .then(() => {
                    navigate ? handleNavigateToAdmissions() : refreshFields();
                    dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
                })
                .catch((error) => {
                    dispatch(create({ type: 'error', message: 'Sikertelen mentés!' }));
                    if (error.response && error.response.status === 422) {
                        let errorObj = {};
                        error.response.data.violations.forEach((e: { propertyPath: string; message: string }) => {
                            errorObj = { ...errorObj, [e.propertyPath]: e.message };
                        });
                        setLoading(false);
                        setErrors(errorObj);
                    }
                });
        };

        const [checked, setChecked] = useState(isForeignStudent);

        const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = event.target.checked;
            setChecked(isChecked);
            setBasicData((prevData) => ({
                ...prevData,
                foreignStudent: isChecked,
            }));
        };

        useEffect(() => {
            if (fields.length > 0) {
                setLoading(true);
                fields.forEach((e) => {
                    setBasicData((prev) => ({ ...prev, [e.type]: e.value }));
                });
                const baseFields = fields.filter((e) => basicDataTypes.includes(e.type));
                const firstOtherFieldsRow = fields.filter((e) => otherDataTypesFirstRow.includes(e.type));
                const otherFields = fields.filter(
                    (e) =>
                        !basicDataTypes.includes(e.type) &&
                        !otherDataTypesFirstRow.includes(e.type) &&
                        !addressFieldTypes.includes(e.type),
                );
                const addressFields = fields.filter((e) => addressFieldTypes.includes(e.type));
                setBaseDataFields(baseFields);
                setOtherDataFields(otherFields);
                setOtherDataFieldsFirstRow(firstOtherFieldsRow);
                setAddressDataFields(addressFields);
            }
            axios.get(endpoints.masterData.students.nationalities).then((res) => {
                setNationalities(res.data);
                setLoading(false);
            });
        }, []);

        if (loading) {
            return <MCCLoading />;
        }

        return (
            <>
                <Form fields={[]} onSubmit={() => console.log('/TODO')} hideButtons>
                    {(props) => (
                        <Grid sx={{ marginLeft: '1rem' }}>
                            {matches && (
                                <Grid container sx={{ marginBottom: '1rem', marginTop: '1.5rem' }}>
                                    <Typography className={`${AdmissionsStyle.basicDataTitle}`}>Alapadatok</Typography>
                                </Grid>
                            )}
                            <Grid
                                container
                                xs={12}
                                spacing={1}
                                sx={{
                                    ...(!matches && {}),
                                }}
                                className={`borderRadius ${AdmissionsStyle.bolder} ${
                                    !matches ? AdmissionsStyle.basicDataTitleContainerMobile : ''
                                }`}
                            >
                                {!matches && (
                                    <Typography
                                        className={`${AdmissionsStyle.bolder} ${AdmissionsStyle.basicDataTitleMobile}`}
                                    >
                                        Alapadatok
                                    </Typography>
                                )}
                                <BasicDataFields
                                    fields={baseDataFields}
                                    setBasicData={setBasicData}
                                    basicData={basicData}
                                    formData={formData}
                                    formProps={props}
                                    errors={errors}
                                    matches={matches}
                                    editMode={editMode}
                                />
                            </Grid>
                            <BasicDataOtherFields
                                setBasicData={setBasicData}
                                basicData={basicData}
                                otherDataFields={otherDataFields}
                                addressDataFields={addressDataFields}
                                otherDataFieldsFirstRow={otherDataFieldsFirstRow}
                                nationalities={nationalities}
                                formData={formData}
                                formProps={props}
                                handleCheckboxChange={handleCheckboxChange}
                                checked={checked}
                                matches={matches}
                                editMode={editMode}
                                errors={errors}
                            />
                        </Grid>
                    )}
                </Form>
                <div
                    className={`${AdmissionsStyle.wide} ${AdmissionsStyle.basicdataSubmitButtonContainter}`}
                    style={{
                        flexDirection: matches ? 'row' : 'column',
                        ...(!matches && {
                            marginRight: '0.5rem',
                        }),
                    }}
                >
                    {!isRegistrated && (
                        <SubmitButton
                            onClick={() => handleSave(false)}
                            sx={{
                                marginRight: '0.5rem',
                                borderRadius: '0px',
                                ...(!matches && { marginBottom: '0.5rem' }),
                            }}
                            fullWidth={!matches}
                        >
                            Mentés
                        </SubmitButton>
                    )}
                    {isRegistrated ? (
                        <CancelButton
                            onClick={handleNavigateToAdmissions}
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            fullWidth={!matches}
                            className={`${AdmissionsStyle.basicDataCancelButton}`}
                            sx={{
                                ...(!matches && { marginBottom: '0.5rem' }),
                            }}
                        >
                            Tovább a képzés kiválasztásához
                        </CancelButton>
                    ) : (
                        <CancelButton
                            onClick={() => handleSave(true)}
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            fullWidth={!matches}
                            className={`${AdmissionsStyle.basicDataCancelButton}`}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#364354',
                                },
                                ...(!matches && { marginBottom: '0.5rem' }),
                            }}
                        >
                            {matches ? 'Mentés és tovább a képzés kiválasztásához' : 'Mentés & Képzés kiválasztása'}
                        </CancelButton>
                    )}
                </div>
            </>
        );
    };

    return (
        <Grid container sx={{ marginBottom: '1rem' }}>
            {isRegistrated && (
                <Grid container className={AdmissionsStyle.contentCenter}>
                    <Grid item>
                        <ThemeProvider theme={defaultTheme}>
                            <Alert
                                severity="info"
                                sx={{
                                    marginBottom: '0.5rem',
                                    marginX: '0.5rem',
                                }}
                            >
                                Amennyiben valamelyik személyes adatod megváltozott, úgy a tanulmányi rendszeren belül a{' '}
                                <i>Profilom</i> menüpontban van lehetőséged módosításra.{' '}
                            </Alert>
                        </ThemeProvider>
                    </Grid>
                </Grid>
            )}

            <Grid
                container
                sx={{
                    marginLeft: '1rem',
                }}
            >
                <FormFields />
            </Grid>
        </Grid>
    );
}
