import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FieldGenerator } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { semesterStatusOptions } from '../../../pages/MasterData/General/Students/Form/options';
import ForeignScholarshipFields from './ForeignScholarshipFields';
import { ForeignScholarshipSemesterStatusForm } from './SemesterStatusDialog';
import Alert from '@mui/material/Alert';

const SemesterStatusDialogForm = (
    formikProps: FormikProps<ForeignScholarshipSemesterStatusForm> & {
        actualStatus?: string;
        handleClose: () => void;
        buttonLoading: boolean;
        readOnly: boolean;
    },
) => {
    const { actualStatus, handleClose, buttonLoading, readOnly } = formikProps;

    return (
        <Grid item container>
            <Grid item xs={12}>
                <DialogTitle>Szemeszter státusz módosítása</DialogTitle>
            </Grid>
            <Grid item container xs={12}>
                <DialogContent>
                    {FieldGenerator({
                        ...formikProps,
                        label: 'Aktuális státusz',
                        name: 'actualStatus',
                        type: 'select',
                        options: semesterStatusOptions,
                        props: {
                            disabled: true,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                    {FieldGenerator({
                        ...formikProps,
                        label: 'Igényelt státusz',
                        name: 'requestedStatus',
                        type: 'select',
                        options: semesterStatusOptions.filter(({ value }) => value !== actualStatus),
                        props: {
                            disabled: readOnly,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                    {formikProps.values.requestedStatus === 'foreign_scholarship' && (
                        <ForeignScholarshipFields {...formikProps} />
                    )}
                    {FieldGenerator({
                        ...formikProps,
                        label: 'Indoklás',
                        name: 'reason',
                        props: {
                            multiline: true,
                            rows: 4,
                            disabled: readOnly,
                        },
                        format: {
                            xs: 12,
                        },
                    })}

                    <Alert severity="warning">
                        Amennyiben megváltoztatod a státuszod, úgy a beiratkozási adatlapod is frissülni fog, amely
                        hatására a képzési követelményeid is módosulhatnak az adott szemeszterre.
                    </Alert>
                </DialogContent>
            </Grid>
            <Grid item xs={12}>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Bezár
                    </Button>
                    {!readOnly && (
                        <LoadingButton loading={buttonLoading} type="submit" variant="contained">
                            Elküldés
                        </LoadingButton>
                    )}
                </DialogActions>
            </Grid>
        </Grid>
    );
};

export default SemesterStatusDialogForm;
