import General from '../../pages/MasterData/General';
import { users } from './General/Users';
import { students } from './General/Students';
import { campuses } from './General/Campuses';
import { universites } from './General/Universites';
import { semesters } from './General/Semesters';
import { trainingLevels } from './General/TrainingLevels';

export const general = {
    path: 'general',
    children: [
        {
            path: '',
            element: <General />,
            name: 'md_general',
        },
        users,
        students,
        campuses,
        universites,
        trainingLevels,
        semesters,
    ],
};
