import instance from '../../api';
import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const courseTargetAudienceService = {
    filter: (courseId: number, courseTenant: string | null, form: IForm) => {
        const endpoint = endpoints.courseManagement.courseTargetAudience.list.replace(
            '{courseId}',
            courseId.toString(),
        );

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant, {
                params: form,
            });
        } else {
            return instance.get(endpoint, {
                params: form,
            });
        }
    },
    create: (form: IForm, courseId: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseTargetAudience.create.replace(
            '{courseId}',
            courseId.toString(),
        );
        if (courseTenant) {
            return API.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return API.post(endpoint, form);
        }
    },
    update: (form: IForm, courseId: number, id: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseTargetAudience.put
            .replace('{courseId}', courseId.toString())
            .replace('{id}', id.toString());
        if (courseTenant) {
            return API.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return API.post(endpoint, form);
        }
    },
    get: (courseId: number, id: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseTargetAudience.get
            .replace('{courseId}', courseId.toString())
            .replace('{id}', id.toString());

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
    delete: (courseId: number, id: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseTargetAudience.delete
            .replace('{courseId}', courseId?.toString())
            .replace('{id}', id.toString());
        if (courseTenant) {
            return API.delete(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return API.delete(endpoint);
        }
    },
    getTenants: (courseId: number | string) =>
        instance.get(
            endpoints.courseManagement.courseTargetAudience.getTenants.replace('{courseId}', courseId.toString()),
        ),
    getLevels: (courseId: number | string, tenantId: number | string) =>
        instance.get(
            endpoints.courseManagement.courseTargetAudience.getLevels
                .replace('{courseId}', courseId.toString())
                .replace('{tenantId}', tenantId.toString()),
        ),
    getPrograms: (courseId: number | string, tenantId: number | string) =>
        instance.get(
            endpoints.courseManagement.courseTargetAudience.getPrograms
                .replace('{courseId}', courseId.toString())
                .replace('{tenantId}', tenantId.toString()),
        ),
    getYears: (courseId: number | string, tenantId: number | string) =>
        instance.get(
            endpoints.courseManagement.courseTargetAudience.getYears
                .replace('{courseId}', courseId.toString())
                .replace('{tenantId}', tenantId.toString()),
        ),
    getCampuses: (tenantId: number | string, courseId: number | string) =>
        instance.get(
            endpoints.courseManagement.courseTargetAudience.getCampus
                .replace('{tenantId}', tenantId.toString())
                .replace('{courseId}', courseId.toString()),
        ),
};

export default courseTargetAudienceService;
