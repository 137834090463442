import { useEffect, useState } from 'react';
import MCCLoading from '../../../../../../components/MCCLoading';
import { Link, Page } from '@silinfo/front-end-template';
import { useParams } from 'react-router-dom';
import trainingProgramRequirementsService from '../../../../../../services/masterData/trainingProgramRequirements';
import trainingLevelService from '../../../../../../services/masterData/trainingLevels';
import trainingProgramsService from '../../../../../../services/masterData/trainingPrograms';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { theme } from '../../../../../../utils/theme';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Apartment from '@mui/icons-material/Apartment';
import { clientEndPoints } from '../../../../../../utils/clientEndPoints';
import { Option } from '../../../../../../utils/AppConst';
import { useTenant } from '../../../../../../components/TenantContext';

export default function RequirementsCampuses() {
    const [loading, setLoading] = useState<boolean>(true);
    const [campuses, setCampuses] = useState<{ value: string; label: string }[]>([]);
    const { id, programId } = useParams();
    const [levelName, setLevelName] = useState('');
    const [programName, setProgramName] = useState('');
    const tenant = useTenant();

    const header = {
        project: tenant || 'TAR',
        title: 'Képzési programok',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: levelName + ' szint',
            [clientEndPoints.md_training_levels_programs_list.replace(':id', id?.toString() || '')]:
                programName + ' program',
            requirements: 'Követelmények',
        },
    };

    useEffect(() => {
        trainingProgramRequirementsService.campuses(id, programId).then((response) => {
            setCampuses(response.data);
            setLoading(false);
        });

        trainingLevelService.trainingLevels().then((response) => {
            setLevelName(response.data.find((element: Option) => element.value == id).label);
        });

        trainingProgramsService.trainingPrograms().then((response) => {
            setProgramName(response.data.find((element: Option) => element.value == programId).label);
        });
    }, [id, programId]);

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Page header={header}>
            <List>
                {campuses.map((v: { value: string; label: string }) => {
                    return (
                        <ListItem disablePadding key={v.value}>
                            <Link
                                to={clientEndPoints.md_training_levels_programs_requirements
                                    .replace(':id', id ? id : '')
                                    .replace(':programId', programId ? programId : '')
                                    .replace(':campusId', v.value)}
                            >
                                <ListItemButton>
                                    <ListItemIcon style={{ color: theme.palette.secondary.main }}>
                                        <Apartment />
                                    </ListItemIcon>
                                    <ListItemText primary={v.label} style={{ color: theme.palette.secondary.main }} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    );
                })}
            </List>
        </Page>
    );
}
