import { Input } from '@silinfo/front-end-template';
import { trainingFormOptions } from '../../../../pages/MasterData/General/Students/Form/options';
import { Option } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';

export const cvFields = (id?: string | number, readOnly?: boolean | undefined): Input[] => [
    {
        name: 'document',
        label: 'Önéletrajz',
        type: 'file',
        origFileName: 'certificateFileName',
        uuidFileName: 'certificateFilePath',
        tempType: 'student-document',
        downloadUrl: `${endpoints.fileManagement.download}?id=${id}&type=student-document&subType=resume-document`,
        props: {
            disabled: readOnly,
        },
    },
];

export const educationFields = (id?: string | number): Input[] => [
    { name: 'institute', label: 'Intézmény' },
    { name: 'major', label: 'Szak' },
    {
        name: 'trainingType',
        label: 'Képzés formája',
        type: 'select',
        options: trainingFormOptions,
    },
    {
        name: 'certification',
        label: 'Igazoló dokumentum',
        type: 'file',
        origFileName: 'origFileName',
        uuidFileName: 'uuidFileName',
        tempType: 'student-document',
        downloadUrl: `${endpoints.fileManagement.download}?id=${id}&type=student-document&subType=education-document`,
    },
];

export const useInternshipFields = (countries: Option[]): Input[] => {
    return [
        { name: 'internshipStart', label: 'Szakmai gyakorlat kezdete', type: 'date' },
        { name: 'internshipEnd', label: 'Szakmai gyakorlat vége', type: 'date' },
        { name: 'employer', label: 'Munkáltató megnevezése' },
        { name: 'internshipCountry', label: 'Szakmai gyakorlat országa', type: 'select', options: countries },
        { name: 'internshipCity', label: 'Szakmai gyakorlat városa' },
        { name: 'activity', label: 'Pozíció' },
    ];
};

export const useForeignScholarshipFields = (countries: Option[]): Input[] => {
    return [
        { name: 'stayAbroadStart', label: 'Külföldi tartózkodás kezdete', type: 'date' },
        { name: 'stayAbroadEnd', label: 'Külföldi tartózkodás vége', type: 'date' },
        {
            name: 'stayAbroadType',
            label: 'Külföldi tartózkodás típusa',
            type: 'select',
            options: [
                { label: 'Erasmus', value: 'erasmus' },
                { label: 'Fellowship', value: 'fellowship' },
            ],
        },
        { name: 'hostName', label: 'Fogadó intézmény teljes neve' },
        { name: 'hostCountry', label: 'Fogadó intézmény országa', type: 'select', options: countries },
        { name: 'hostCity', label: 'Fogadó intézmény városa' },
        { name: 'hostMajor', label: 'Fogadó intézménynél végzett szak/tevékenység megnevezése' },
    ];
};

export const useWorkplaceFields = (countries: Option[]): Input[] => {
    return [
        { name: 'jobStart', label: 'Munkahely idejének kezdete', type: 'date' },
        { name: 'jobStartEnd', label: 'Munkahely idejének vége', type: 'date' },
        { name: 'jobName', label: 'Munkahely megnevezése' },
        { name: 'jobCountry', label: 'Munkahely országa', type: 'select', options: countries },
        { name: 'jobCity', label: 'Munkahely városa' },
        { name: 'jobActivity', label: 'Pozíció' },
    ];
};

export const competitionFields = (id?: string | number): Input[] => {
    return [
        { name: 'competitionInstitution', label: 'Intézmény' },
        { name: 'competition', label: 'Verseny' },
        { name: 'competitionResult', label: 'Eredmény' },
        {
            name: 'competitionCertification',
            label: 'Igazoló dokumentum',
            type: 'file',
            origFileName: 'origFileName',
            uuidFileName: 'uuidFileName',
            tempType: 'student-document',
            downloadUrl: `${endpoints.fileManagement.download}?id=${id}&type=student-document&subType=competition-certification`,
        },
    ];
};
