import Grid from '@mui/material/Grid';
import { FormikListPage } from '@silinfo/front-end-template';
import { tableProps } from './GroupFormUtils';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { endpoints } from '../../../utils/endPoints';
import API from '../../../api';
import AddModal from './AddGroupElementModal';
import { useTenant } from '../../../components/TenantContext';

export default function GroupElements({ groupName, readonly }: { groupName: string; readonly: boolean }) {
    const [refresh, setRefresh] = useState(false);
    const { groupId } = useParams();
    const validGroupId = groupId ?? '';
    const tenant = useTenant();
    const filter = useCallback(
        (form: Record<string, unknown>) => {
            return API.get(
                endpoints.admission.admissionGroupElement.list.replace('{groupId}', validGroupId as unknown as string),
                {
                    params: form,
                },
            );
        },
        [validGroupId],
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setRefresh(true);
    };

    return (
        <Grid item xs={12}>
            <FormikListPage
                {...tableProps(refresh, setRefresh, validGroupId, groupName, handleModalOpen, readonly, tenant)}
                service={{ filter }}
            />
            <AddModal
                open={isModalOpen}
                onClose={handleModalClose}
                groupId={validGroupId}
                onRefresh={() => setRefresh((prev) => !prev)}
            />
        </Grid>
    );
}
