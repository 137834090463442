import VisibilityIcon from '@mui/icons-material/Visibility';
import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import OnClickDownloadButton from '../../pages/Administration/Documents/OnClickDownloadButton';
import { endpoints } from '../../utils/endPoints';
import instance from '../../api';
import { useState } from 'react';
import CircularProgess from '@mui/material/CircularProgress';

export interface Document {
    id: number;
    name: string;
    semester: string;
    campus: string;
    trainingPrograms: string;
    isEnrollmentDocument: boolean;
    acceptText: string | null;
    consentText: string | null;
    required: boolean | null;
    filePath: string;
    fileName: string;
    updatedAt: string;
}

export interface OperationsMobileProps {
    row: Document;
}

export default function OperationsMobile({ row }: OperationsMobileProps) {
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        instance
            .get(`${endpoints.students.document.download.replace('{id}', row.id.toString())}`, {
                responseType: 'blob',
            })
            .then((response) => {
                window.open(URL.createObjectURL(response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item>
                <TableButton color="primary" tooltip="Dokumentum előnézete" onClick={handleClick}>
                    {loading ? <CircularProgess size={24} /> : <VisibilityIcon />}
                </TableButton>
            </Grid>
            <Grid item>
                <OnClickDownloadButton
                    filename={row.fileName || 'dokumentum.pdf'}
                    url={`${endpoints.students.document.download.replace('{id}', row.id.toString())}`}
                />
            </Grid>
        </Grid>
    );
}
