import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useCallback, useMemo } from 'react';
import { ParamKeyValuePair } from 'react-router-dom';
import { DesktopView } from './DesktopView';
import { RowProps } from './types';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileView from './MobileView';

const Row = (props: RowProps) => {
    const { setSearchParams, id } = props;
    const matches = useMediaQuery('(min-width:960px)');
    const semesterToNavigateTo: ParamKeyValuePair[] = useMemo(
        () => [
            ['semester', '' + id],
            ['tab', '1'],
        ],
        [id],
    );

    const handleTodayClick = useCallback(
        () => setSearchParams(semesterToNavigateTo),
        [semesterToNavigateTo, setSearchParams],
    );

    const View = matches ? DesktopView : MobileView;

    return (
        <Grid item container xs={12} alignItems="center" spacing={2}>
            <View {...props} handleTodayClick={handleTodayClick} />
            {!props.id && (
                <Grid item xs={12}>
                    <Divider> Szemeszterre bontva </Divider>
                </Grid>
            )}
        </Grid>
    );
};

export default Row;
