import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import { Dialog, TableButton } from '@silinfo/front-end-template';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaymentTable from './PaymentTable';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useParams } from 'react-router-dom';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { useContext } from 'react';
import SemesterContext from '../../../components/SemesterSelector/SemesterContextProvider';
import { StudentNameContext } from '../../MasterData/General/Students/Form/StudentsForm';

export default function OperationCellRenderer(params: GridRenderCellParams) {
    const semester = useContext(SemesterContext);
    const studentName = useContext(StudentNameContext);
    const { id } = useParams() as { id: string };

    if (params.id === -1) {
        return null;
    }

    return (
        <>
            {params.row.groups && (
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item>
                        <Dialog
                            opener={
                                <TableButton tooltip="Ösztöndíjak ütemezése, kifizetési naptár" color="secondary">
                                    <CalendarMonthIcon />
                                </TableButton>
                            }
                            title={'Kifizetési naptár: ' + studentName || params.row.student || ''}
                        >
                            <PaymentTable
                                studentId={params.row.student ? '' + params.row.id : id}
                                semesterId={params.row.semester ? '' + params.row.id : undefined}
                            />
                        </Dialog>
                    </Grid>
                    {semester ? (
                        <Grid item>
                            <Link
                                to={
                                    clientEndPoints.md_general_students_edit_detailed.replace(':id', '' + params.id) +
                                    '?tab=' +
                                    2
                                }
                            >
                                <TableButton tooltip="Hallgató szintű nézet megtekintése" color="primary">
                                    <VisibilityIcon />
                                </TableButton>
                            </Link>
                        </Grid>
                    ) : null}
                </Grid>
            )}
        </>
    );
}
