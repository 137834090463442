import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { initialValues } from './GroupFormUtils';
import { FieldGenerator, Form, Option } from '@silinfo/front-end-template';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { useDispatch } from 'react-redux';
import { create } from '../../../store/notification';

interface AddModalProps {
    open: boolean;
    onClose: () => void;
    groupId: string;
    onRefresh: () => void;
}

const AddModal: React.FC<AddModalProps> = ({ open, onClose, groupId, onRefresh }) => {
    const dispatch = useDispatch();
    const [trainingProgramOptions, setTrainingProgramOptions] = useState([]);
    const handleSave = (form: Record<string, unknown>) => {
        const endpointUrl = endpoints.admission.admissionGroupElement.create.replace('{groupId}', groupId);
        API.post(endpointUrl, form)
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
                onClose();
                onRefresh();
            })
            .catch((error) => {
                console.error('Error while saving data:', error);
            });
    };

    const handleTrainingProgramOptions = (trainingLevel: string | number) => {
        setTrainingProgramOptions([]);
        API.get(
            endpoints.admission.admissionGroupElement.trainingProgramOptions.replace('{groupId}', groupId) +
                '?trainingLevel=' +
                trainingLevel,
        ).then((response) => setTrainingProgramOptions(response.data));
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{'Csoport elem létrehozása'}</DialogTitle>
            <DialogContent style={{ paddingTop: '12px', paddingBottom: 0 }}></DialogContent>
            <Grid style={{ padding: '0 12px 12px 12px' }}>
                <Form fields={[]} initialValues={initialValues} onSubmit={handleSave} hideButtons={true}>
                    {(props) => (
                        <Grid item xs={12}>
                            {FieldGenerator({
                                name: 'campus',
                                label: 'Campus',
                                type: 'backendSelect',
                                format: { xs: 12 },
                                fieldType: 'base',
                                url: endpoints.admission.admissionGroupElement.campusOptions,
                                justValue: true,
                                props: {
                                    sx: { marginBottom: '10px' },
                                    onChange: (_: unknown, values: Option) => {
                                        props.setFieldValue('campus', values?.value ?? '');
                                        props.setFieldValue('trainingLevel', '');
                                        props.setFieldValue('trainingProgram', '');
                                    },
                                },
                                ...props,
                            })}
                            {FieldGenerator({
                                name: 'trainingLevel',
                                label: 'Képzési szint',
                                type: 'backendSelect',
                                format: { xs: 12 },
                                fieldType: 'base',
                                url: endpoints.admission.admissionGroupElement.trainingLevelOptions,
                                justValue: true,
                                props: {
                                    sx: { marginBottom: '10px' },
                                    disabled: !props.values.campus,
                                    onChange: (_: unknown, values: Option) => {
                                        props.setFieldValue('trainingLevel', values?.value ?? '');
                                        props.setFieldValue('trainingProgram', '');
                                        handleTrainingProgramOptions(values?.value);
                                    },
                                },
                                ...props,
                            })}
                            {FieldGenerator({
                                name: 'trainingProgram',
                                label: 'Képzési program',
                                type: 'multiselect',
                                format: { xs: 12 },
                                fieldType: 'base',
                                options: trainingProgramOptions,
                                props: {
                                    disabled: !props.values?.trainingLevel || trainingProgramOptions.length == 0,
                                    disablePortal: false,
                                },
                                ...props,
                            })}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    style={{ marginRight: '10px', marginTop: '10px' }}
                                >
                                    Mentés
                                </Button>
                                <Button
                                    onClick={onClose}
                                    color="primary"
                                    variant="outlined"
                                    style={{ marginRight: '10px', marginTop: '10px' }}
                                >
                                    Mégse
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Form>
            </Grid>
        </Dialog>
    );
};

export default AddModal;
