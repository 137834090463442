import ConfirmationSemester from '../../../pages/Administration/Certificates/ConfirmationSemester';
import ConfirmationTrainingProgram from '../../../pages/Administration/Certificates/ConfirmationTrainingProgram';

export const confirmation = {
    path: 'confirmation',
    children: [
        {
            path: 'semester',
            element: <ConfirmationSemester />,
            name: 'admin_certificates_confirmation_semester',
        },
        {
            path: 'training-program',
            element: <ConfirmationTrainingProgram />,
            name: 'admin_certificates_confirmation_training_program',
        },
    ],
};
