import { ICourseView } from '../../../../../pages/CourseManagement/Courses/types';
import { DesktopView } from './DesktopView';
import MobileView from './MobileView';
import { ViewProps } from './types';

export const Row = (props: ICourseView) => {
    const { notCounted } = props;
    const { matches } = window.matchMedia('(min-width:960px)');
    const pointsBeforeEvaluation =
        props.point?.customCoursePoint || props.point?.customCoursePointPerEvent
            ? `${props.point?.minimumPointToBeAchieved}-${props.point?.maximumAchievablePoint}`
            : props.point?.maximumAchievablePoint;

    const pointsAfterEvaluation = props.totalPointsOnCourse?.totalPoints;

    const viewProps: ViewProps = {
        ...props,
        notCounted,
        pointsBeforeEvaluation,
        pointsAfterEvaluation,
    };

    const View = matches ? DesktopView : MobileView;

    return <View {...viewProps} />;
};
