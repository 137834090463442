import CommentIcon from '@mui/icons-material/Comment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ICourseView, IEvent } from '../../../pages/CourseManagement/Courses/types';
import { TScoringType } from '../../../services/masterData/courses/courseTypes';
import { scoringTypeLabel } from '../../../utils/AppConst';
import { Paper } from '../Paper';

const createPointLabel = (min: number, max: number, type: TScoringType) =>
    (min === max ? `${min} ` : `${min} - ${max} `) + scoringTypeLabel(type);

const EventCard = (props: IEvent & { scoringType: TScoringType }) => {
    return (
        <Card
            sx={{
                bgcolor: 'action.hover',
            }}
        >
            <CardHeader
                title={props.eventType}
                subheader={
                    <>
                        <div>{props.instructors}</div>
                        {props?.userEventDate && <Typography variant="body1">{props.userEventDate}</Typography>}
                        {props?.timezone && (
                            <Typography variant="body2">{`Helyi idő szerint ${props.timezone}`}</Typography>
                        )}
                        <Typography variant="body2">{props.eventDate}</Typography>
                    </>
                }
                sx={{
                    paddingBottom: 1,
                }}
            />
            <CardContent sx={{ paddingTop: 0 }}>
                <Grid item xs={12} container spacing={2}>
                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <LocationOnOutlinedIcon color="secondary" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{props.locations}</Typography>
                        </Grid>
                    </Grid>
                    {props.pointMin ? (
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <ListAltIcon color="secondary" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    {createPointLabel(
                                        props.pointMin,
                                        props.pointMax || props.pointMin,
                                        props.scoringType,
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : null}
                    {props.comment ? (
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <CommentIcon color="secondary" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">{props.comment}</Typography>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default function Events(props: ICourseView) {
    return props.events && props.events.length ? (
        <Grid item xs={12}>
            <Paper>
                <Grid item xs={12} container spacing={2}>
                    <Grid container item xs={12} onClick={(e) => e.stopPropagation()} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Események</Typography>
                        </Grid>
                        {props.events.map((e, i) => (
                            <Grid item xs={12} key={i}>
                                <EventCard {...e} scoringType={props.scoringType} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null;
}
