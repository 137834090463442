import CalendarToday from '@mui/icons-material/CalendarToday';
import Category from '@mui/icons-material/Category';
import Language from '@mui/icons-material/Language';
import LocationCity from '@mui/icons-material/LocationCity';
import Settings from '@mui/icons-material/Settings';
import StarBorder from '@mui/icons-material/StarBorder';
import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { setEdit } from '../../../../../store/studentProfile';
import { LanguageExam } from '../types';
import Row from '../../Row';
import Validation from './Validation';
import { getCategory, getType } from '../utils';
import { languageOptions } from '../../../../../pages/MasterData/General/Students/Form/options';
import { DownloadButton } from '@silinfo/front-end-template';
import { endpoints } from '../../../../../utils/endPoints';
import moment from 'moment';

const InfoContent = ({ languageExam, handleClose }: { languageExam?: LanguageExam; handleClose: () => void }) => {
    const dispatch = useDispatch();

    return (
        <>
            <DialogContent>
                {languageExam && (
                    <>
                        <Row
                            Icon={Language}
                            label="Nyelv"
                            value={
                                languageOptions.find((elem) => elem.value === languageExam.language)?.label ||
                                languageExam.language
                            }
                        />
                        <Row Icon={Settings} label="Típus" value={getType(languageExam.type)} />
                        <Row Icon={StarBorder} label="Szint" value={languageExam.level} />
                        <Row Icon={Category} label="Kategória" value={getCategory(languageExam.category)} />
                        <Row
                            Icon={CalendarToday}
                            label="Szerzés dátuma"
                            value={moment(languageExam.examDate).format('YYYY. MM. DD.')}
                        />
                        <Row Icon={LocationCity} label="Szerzés helye" value={languageExam.examLocation} />
                        <Grid item xs={12} style={{ padding: '8px 4px' }}>
                            <DownloadButton
                                buttonText="Bizonyítvány letöltése"
                                downloadName={languageExam.certificateFileName}
                                url={`${endpoints.fileManagement.download}?id=${languageExam.id}&type=student-document&subType=student-language-exam`}
                            />
                        </Grid>
                        <Validation languageExam={languageExam} />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <LoadingButton variant="outlined" onClick={handleClose}>
                    Bezár
                </LoadingButton>
                {!languageExam ||
                    (languageExam.validated !== 'yes' && (
                        <LoadingButton variant="contained" onClick={() => dispatch(setEdit())}>
                            Szerkesztés
                        </LoadingButton>
                    ))}
            </DialogActions>
        </>
    );
};

export default InfoContent;
