import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import { useDispatch } from 'react-redux';
import questionnaireTemplateService from '../../../services/selfEvalution/questionnaireTemplate';
import { create } from '../../../store/notification';
import ContentCopy from '@mui/icons-material/ContentCopy';

export default function DuplicateButton({ refresh, id }: { refresh: () => void; id: string }) {
    const dispatch = useDispatch();
    const handleCopy = () => {
        questionnaireTemplateService
            .duplicate(id)
            .then(() => {
                dispatch(create({ message: 'Sikeres másolás', type: 'success' }));
                refresh();
            })
            .catch(() => dispatch(create({ message: 'Hiba a másolás során.', type: 'error' })));
    };

    return (
        <Grid item>
            <TableButton tooltip="Duplikálás" color="info" onClick={handleCopy} sx={{ marginRight: '5px' }}>
                <ContentCopy />
            </TableButton>
        </Grid>
    );
}
