import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

interface DeleteGroupConfirmModalProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    groupName: string;
    handleDelete: () => void;
}
export default function DeleteGroupConfirmModal({ openState, groupName, handleDelete }: DeleteGroupConfirmModalProps) {
    const [open, setOpen] = openState;
    const [currentGroupName, setCurrentGroupName] = useState<string | null>(groupName);
    const handleClose = () => {
        setCurrentGroupName(null);
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Felvételi csoport törlése</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>
                <DialogContentText>
                    Biztosan törölni szeretnéd a(z) {currentGroupName} felvételi csoportot? A művelet nem vonható
                    vissza!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleDelete}>
                    Igen
                </Button>
                <Button onClick={handleClose} variant="outlined">
                    Nem
                </Button>
            </DialogActions>
        </Dialog>
    );
}
