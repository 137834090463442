import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ICourseView } from '../../pages/CourseManagement/Courses/types';
import { Paper } from '../Requirements/Paper';
import { useState, useCallback } from 'react';

const Header = () => (
    <TableHead>
        <TableRow>
            <TableCell>Kurzuskód</TableCell>
            <TableCell>Kurzus neve</TableCell>
            {/* <TableCell style={{ textAlign: 'center' }}>Teljesítve</TableCell> */}
        </TableRow>
    </TableHead>
);

const DesktopView = (props: ICourseView) => (
    <Grid item xs={12} style={{ minHeight: '200px', maxHeight: '400px' }}>
        <Table>
            <Header />
            <TableBody>
                {props.coursePrerequisites?.length > 0 ? (
                    props.coursePrerequisites.map((row) => (
                        <TableRow key={row.courseReferenceCode}>
                            <TableCell>{row.courseReferenceCode}</TableCell>
                            <TableCell>{row.courseName}</TableCell>
                            {/* <TableCell style={{ textAlign: 'center' }}>
                                {Math.random() > 0.5 ? <Check color="success" /> : <Close color="error" />}
                            </TableCell> */}
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={3} align="center">
                            <Typography variant="body2">
                                <i>Nincsenek előfeltételek.</i>
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </Grid>
);

const MobileView = (props: ICourseView) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <Grid item xs={12}>
            {'coursePrerequisites' in props && props.coursePrerequisites?.length > 0 ? (
                props.coursePrerequisites.map((row) => (
                    <Grid item xs={12} key={row.courseReferenceCode}>
                        <Chip label={row.courseCode} color="secondary" onClick={handleOpen} />
                        <Menu
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={handleClose}
                            PaperProps={{ style: { padding: '0 8px' } }}
                        >
                            <Typography variant="body2">{row.courseName}</Typography>
                        </Menu>
                    </Grid>
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography variant="body2">
                        <i>Ehhez a kurzushoz nem tartoznak előfeltételek.</i>
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default function RightPanel(props: ICourseView) {
    const isMobile = window.innerWidth < 960;

    return (
        <Grid item container xs={12} md={4} spacing={2} alignItems="flex-start">
            <Grid item xs={12} style={{ height: '100%' }}>
                <Paper>
                    <Grid item xs={12}>
                        <Typography variant="h6">Előfeltételek</Typography>
                    </Grid>
                    {isMobile ? <MobileView {...props} /> : <DesktopView {...props} />}
                </Paper>
            </Grid>
        </Grid>
    );
}
