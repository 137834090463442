import Grid from '@mui/material/Grid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Input, Link, NewButton } from '@silinfo/front-end-template';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ExportButton from '../../../components/Buttons/ExportButton';
import EndpointProvider from '../../../components/EndpointProvider';
import MCCLoading from '../../../components/MCCLoading';
import courseListService from '../../../services/courseManagement/courseList';
import { RootState } from '../../../store';
import { TooltipRenderer } from '../../../utils/AppConstElements';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import { IUser } from '../../../utils/Interfaces/interfaces';
import { BtnCellRenderer } from './BtnCellRenderer';
import { CapacityCellRenderer } from './CapacityCellRenderer';
import CopyButton from './Copy/CopyButton';
import { CourseFilter, initialCourseFilterState } from './types';
import { useCourseFields } from './useCourseFields';
import { SESSION_KEY, header } from './utils';
import { scoringTypeLabel } from '../../../utils/AppConst';
import Tooltip from '@mui/material/Tooltip';
import { useTenant } from '../../../components/TenantContext';
import { formatDateWithoutConvert } from '../../../utils/timezoneConverter';

export function InstructorCellRenderer(params: GridRenderCellParams) {
    const instructors = params.row.instructors;
    const result = instructors?.join(', ');
    return TooltipRenderer(result);
}

export function InstructorCellRendererCourseList(params: GridRenderCellParams) {
    const instructors = params.row.instructors;
    const result = instructors.map((instructor: IUser) => instructor.lastName + ' ' + instructor.firstName).join(', ');
    return TooltipRenderer(result);
}

function CourseListTable({ fields, defaultSemester }: { fields: Input[]; defaultSemester: string }) {
    const [refresh, setRefresh] = useState<boolean>(false);
    const [info, setInfo] = useState<IInfo<CourseFilter>>(initialCourseFilterState(defaultSemester));
    const { user } = useSelector((state: RootState) => state.auth);
    const tenant = useTenant();

    useEffect(() => {
        setInfo((prev) => ({ ...prev, semester: defaultSemester ? [defaultSemester] : [] }));
    }, [defaultSemester]);
    const columns: GridColDef[] = [
        {
            headerName: 'Kurzus neve',
            field: 'courseName',
            renderHeader: () => {
                return (
                    <>
                        <div
                            style={{
                                background: 'transparent',
                                minWidth: '10px',
                                height: '100%',
                                marginLeft: '-10px',
                                marginRight: '8px',
                            }}
                        />
                        {'Kurzus neve'}
                    </>
                );
            },
            renderCell: (params) => {
                if (params.row?.courseType !== 'Megosztott') {
                    return (
                        <>
                            <div
                                style={{
                                    background: 'transparent',
                                    minWidth: '10px',
                                    height: '100%',
                                    marginLeft: '-10px',
                                    marginRight: '8px',
                                }}
                            />
                            {TooltipRenderer(params.row?.courseName)}
                        </>
                    );
                }

                return (
                    <>
                        <Tooltip title={'Megosztott kurzus'}>
                            <div
                                style={{
                                    background: '#35A899',
                                    minWidth: '10px',
                                    height: '100%',
                                    marginLeft: '-10px',
                                    marginRight: '8px',
                                }}
                            />
                        </Tooltip>
                        {TooltipRenderer(params.row?.courseName)}
                    </>
                );
            },
        },
        {
            headerName: 'Kurzuskód',
            field: 'courseCode',
            renderCell: (params) => TooltipRenderer(params.row?.courseCode),
            minWidth: 130,
        },
        {
            headerName: 'Kurzustípus',
            field: 'courseTypeReal',
            renderCell: (params) => TooltipRenderer(params.row?.courseTypeReal),
        },
        { headerName: 'Campus', field: 'campusName', minWidth: 125 },
        { headerName: 'Kurzuskezelő', field: 'courseManager' },
        { headerName: 'Oktató', field: 'instructors', renderCell: InstructorCellRenderer },
        {
            headerName: 'Érték',
            field: 'point',
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) =>
                params.value ? params.value + ' ' + scoringTypeLabel(params.row.scoringType) : '-',
        },
        {
            headerName: 'Aktuális létszám',
            field: 'capacity',
            renderCell: CapacityCellRenderer,
            minWidth: 130,
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerName: 'Státusz',
            field: 'status',
            renderCell: (params) => TooltipRenderer(params.row.status),
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerName: 'Kurzus kezdete',
            field: 'courseStartDate',
            renderCell: (params) => {
                const date = formatDateWithoutConvert(params.row.courseStartDate?.date);
                return TooltipRenderer(date);
            },
            minWidth: 130,
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerName: 'Kurzus vége',
            field: 'courseEndDate',
            renderCell: (params) => {
                const date = formatDateWithoutConvert(params.row.courseEndDate?.date);
                return TooltipRenderer(date);
            },
            minWidth: 130,
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params) => BtnCellRenderer(params, setRefresh),
            sortable: false,
        },
    ];

    const exportContext = {
        initiateExport: courseListService.initiateExport,
        checkExport: courseListService.checkExport,
        downloadExport: courseListService.downloadExport,
        info,
        filter: info.filter,
    };

    return (
        <FormikListPage
            autoload
            service={{
                filter: (form) => {
                    setInfo((prevState) => ({ ...prevState, filter: form })); // Export miatt van itt
                    return courseListService.filter(form);
                },
            }}
            sessionKey={SESSION_KEY}
            header={header(tenant || 'TAR')}
            refresh={refresh}
            filter={{ fields, initialValues: { semester: defaultSemester ? [defaultSemester] : [] } }}
            columns={columns.map((column) => ({ flex: 1, minWidth: 200, ...column }))}
            defaultSort={{ courseName: 'asc' }}
            tableProps={{
                columnBuffer: columns.length,
                initialState: {
                    pinnedColumns: {
                        right: ['operations'],
                    },
                },
            }}
            responseKey={'data'}
            customButtons={
                !user.archive ? (
                    <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <ExportButton exportContext={exportContext} />
                        </Grid>
                        <EndpointProvider endpoints={['course_manamagement_course_create']}>
                            <Grid item>
                                <Link to="new">
                                    <NewButton>Kurzus hozzáadása</NewButton>
                                </Link>
                            </Grid>
                        </EndpointProvider>
                        <EndpointProvider endpoints={['course_manamagement_course_copy']}>
                            <CopyButton />
                        </EndpointProvider>
                    </Grid>
                ) : (
                    <></>
                )
            }
        />
    );
}

export default function CourseList() {
    const { fields, options } = useCourseFields(true);
    const fieldsLoading = useCourseFields().loading;
    const defaultSemester = useMemo(
        () => options.semesters.find((element) => !!element.default)?.value,
        [options.semesters],
    );

    return fieldsLoading ? <MCCLoading /> : <CourseListTable fields={fields} defaultSemester={defaultSemester || ''} />;
}
