import { Loading } from '@silinfo/front-end-template';
import { useMemo } from 'react';
import IEntityBase from '../../interface/IEntityBase';
import { createOptions, Option } from '../../utils/AppConst';
import { TDependentSelect } from '../../utils/Interfaces/interfaces';
import useFetch from '../../utils/useFetch';
import DependentSelect from './DependentSelect';

export type TParentInput = Omit<TDependentSelect, 'options' | 'optionsGetter'>;

interface DependentSelectInputGeneratorProps<T> {
    parentInput: TParentInput;
    endpoint: string;
    keyOfResponse?: string;
    campus?: string;
    optionCreator?: (data: T[], campus?: string) => Option[]; //FIXME: ezt meg a campus-t ki kellene venni, mert csak a kurzus/eseményeknél van használva
    disabled?: boolean;
}

export default function DependentSelectInputGenerator<T>(props: DependentSelectInputGeneratorProps<T>) {
    const { parentInput, endpoint, keyOfResponse, optionCreator, disabled } = props;
    const { data, loading } = useFetch<T[]>(endpoint, keyOfResponse);

    const options: Option[] = useMemo(
        () => (optionCreator ? optionCreator(data, props.campus) : createOptions(data as IEntityBase[])),
        [data, optionCreator, props.campus],
    );

    const inputProps: TDependentSelect = {
        ...parentInput,
        options,
        disabled,
    };
    return loading ? <Loading noHeight /> : <DependentSelect {...inputProps} />;
}
