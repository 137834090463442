import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import courseListService from '../../../services/courseManagement/courseList';
import { theme } from '../../../utils/theme';

export default function OtherOrganiserAutocomplete({
    value,
    onChange,
    errorMessage,
    disabled,
}: {
    value: string[];
    onChange: (values: string[]) => void;
    errorMessage?: string;
    disabled?: boolean;
}) {
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const n = 3;

    const handleInputChange = (_: React.SyntheticEvent<Element, Event>, value: string) => {
        setInputValue(value);
        if (value.length === n) {
            setLoading(true);
            courseListService
                .otherOrganiserSearch(value)
                .then((response: AxiosResponse<{ data: string[] }>) => setOptions(response.data.data))
                .finally(() => setLoading(false));
        }
    };

    return (
        <Grid item xs={12} sm={3}>
            <Autocomplete
                onInputChange={handleInputChange}
                multiple
                size="small"
                disabled={disabled}
                onClose={() => setOptions([])}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Egyéb szervező"
                        placeholder={'Írjon be ' + n + ' karaktert a kereséshez'}
                        size="small"
                        helperText={errorMessage}
                        error={!!errorMessage}
                    />
                )}
                options={options}
                noOptionsText={
                    loading
                        ? 'Betöltés...'
                        : inputValue.length >= n
                        ? 'Nincs találat'
                        : 'Írjon be ' + n + ' karaktert a kereséshez'
                }
                ChipProps={{
                    sx: {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        '& .MuiChip-deleteIcon': {
                            color: theme.palette.primary.main,
                        },
                    },
                }}
                value={value || []}
                filterSelectedOptions
                freeSolo
                openOnFocus
                onChange={(_, values) => {
                    if (values.includes(inputValue)) courseListService.otherOrganiserSave(inputValue);
                    onChange(values);
                }}
                clearOnBlur={true}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    if (e.target.value != '') {
                        if (value.includes(inputValue)) courseListService.guestPerformerSave(inputValue);
                        onChange([...value, e.target.value]);
                    }
                }}
            />
        </Grid>
    );
}
