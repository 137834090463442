import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';

const systemLogsService = {
    ...crudTemplate<never>(endpoints.administration.systemLogs.main),
    getActions: () => API.get(endpoints.administration.systemLogs.actions),
    getTypes: () => API.get(endpoints.administration.systemLogs.types),
};

export default systemLogsService;
