import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import API from '../../../../api';
import { useCallback, useEffect, useState } from 'react';
import { endpoints } from '../../../../utils/endPoints';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../../../../utils/AppConst';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { ProgramSelectDialogProps } from '../types';
import AdmissionsStyle from '../Admissions.module.scss';
import AdmissionsDialogsStyle from './AdmissionsDialogs.module.scss';
import axios from 'axios';

export default function ProgramSelectDialog({
    openState,
    serialState,
    tempSerialState,
    token,
    handleSaveBody,
    refresh,
    checkIsProgramAlreadyAdded,
    loadingState,
}: ProgramSelectDialogProps) {
    const [open, setOpen] = openState;
    const [campuses, setCampuses] = useState<{ value: number; label: string }[]>([]);
    const [programs, setPrograms] = useState<{ value: number; label: string }[]>([]);
    const [campus, setCampus] = useState<string | null>();
    const [program, setProgram] = useState<string | null>();
    const [campusValue, setCampusValue] = useState<string | number | null>();
    const [programValue, setProgramValue] = useState<string | number | null>();
    const [editable, setEditable] = useState<boolean>(false);
    const [removable, setRemovable] = useState<boolean>(false);
    const [programError, setProgramError] = useState<boolean>(false);
    const [currentSerial, setCurrentSerial] = serialState;
    const [tempSerial, setTempSerial] = tempSerialState;
    const [loading, setLoading] = loadingState;

    const handleClose = useCallback(() => {
        setOpen(false);
        setCurrentSerial(null);
        setCampusValue(null);
        setProgramValue(null);
        setTempSerial(null);
        setProgramError(false);
    }, [setOpen, setCurrentSerial, setTempSerial]);

    const handleSave = () => {
        if (campusValue && programValue) {
            setLoading(true);
            const serial = currentSerial || tempSerial;
            const isNew = currentSerial ? false : true;
            handleSaveBody(serial, campusValue, programValue, isNew);
        }
    };

    const handleDelete = () => {
        handleClose();
        const endpoint = window.location.pathname.includes('/mcc')
            ? endpoints.admission.removeProgramMcc.replace('{serial}', `${currentSerial}`)
            : endpoints.admission.removeProgramToken
                  .replace('{serial}', `${currentSerial}`)
                  .replace('{token}', `${token}`);

        const deleteRequest = window.location.pathname.includes('/mcc') ? API.delete(endpoint) : axios.delete(endpoint);
        deleteRequest.then(() => {
            handleClose();
            refresh();
        });
    };

    const handleChange = (e: SelectChangeEvent<string | number>) => {
        setProgramError(false);
        setProgramValue(e.target.value);
        if (campusValue && e.target.value) {
            const isAlreadyAdded = checkIsProgramAlreadyAdded(campusValue as number, e.target.value as number);
            if (isAlreadyAdded) {
                setProgramError(true);
            }
        }
    };

    useEffect(() => {
        (() => {
            if (currentSerial) {
                const endpoint = window.location.pathname.includes('/mcc')
                    ? endpoints.admission.getProgramMcc.replace('{serial}', `${currentSerial}`)
                    : endpoints.admission.getProgramToken
                          .replace('{serial}', `${currentSerial}`)
                          .replace('{token}', `${token}`);
                const fetchData = async () => {
                    setLoading(true);
                    try {
                        const request = window.location.pathname.includes('/mcc')
                            ? API.get(endpoint)
                            : axios.get(endpoint);
                        const response1 = await request;
                        const response2 = await axios.get(endpoints.admission.listCampuses);
                        if (response1.status === 200 && response2.status === 200) {
                            setEditable(response1.data.editable);
                            setRemovable(response1.data.removable);
                            setCampusValue(response1.data.campus.value);
                            setProgramValue(response1.data.program.value);
                            setCampus(response1.data.campus.label);
                            setProgram(response1.data.program.label);
                            setCampuses(response2.data);
                        }
                    } finally {
                        setLoading(false);
                    }
                };
                fetchData();
            } else {
                setEditable(true);
                setRemovable(false);
                setCampusValue(null);
                setProgramValue(null);
                setCampus(null);
                setProgram(null);
                axios.get(endpoints.admission.listCampuses).then((response) => setCampuses(response.data));
            }
        })();
    }, [currentSerial, token, setLoading]);

    useEffect(() => {
        (() => {
            if (campusValue) {
                axios
                    .get(endpoints.admission.listPrograms.replace('{campus}', `${campusValue}`))
                    .then((response) => setPrograms(response.data));
            }
        })();
    }, [campusValue]);

    return (
        <Modal open={open} onClose={handleClose}>
            {loading ? (
                <Grid
                    container
                    className={`borderRadius ${AdmissionsDialogsStyle.programsModal}`}
                    sx={{
                        padding: '1rem',
                        borderRadius: '20px !important',
                    }}
                >
                    <CircularProgress size={100} />
                </Grid>
            ) : (
                <Grid
                    container
                    className={`${AdmissionsDialogsStyle.programsModal}`}
                    sx={{
                        padding: '2rem',
                    }}
                >
                    <Typography className={`${AdmissionsDialogsStyle.title}`}>Képzés kiválasztása</Typography>
                    {editable && (
                        <ThemeProvider theme={defaultTheme}>
                            <Alert severity="info">
                                Az alábbiakban kiválaszthatod, melyik képzési központba és melyik képzési programra
                                szeretnél jelentkezni. A kiválasztáshoz kattints a mezőkbe!
                            </Alert>
                        </ThemeProvider>
                    )}
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        {editable ? (
                            <>
                                <Typography sx={{ fontSize: '14px' }}>Képzési központ</Typography>
                                <Select
                                    id="campus"
                                    value={campusValue ? campusValue : ''}
                                    onChange={(e) => {
                                        setCampusValue(e.target.value);
                                        setProgramError(false);
                                        setProgramValue(null);
                                    }}
                                    disabled={!editable}
                                    fullWidth
                                    size="small"
                                    className={`${AdmissionsDialogsStyle.programsSelector}`}
                                >
                                    {campuses.map((e, index) => (
                                        <MenuItem key={index} value={e.value}>
                                            {e.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <TextField label="Campus" value={campus} disabled size="small" sx={{ mb: '20px' }} />
                        )}
                    </FormControl>
                    <FormControl fullWidth>
                        {editable ? (
                            <>
                                <Typography sx={{ fontSize: '14px' }}>Képzési program</Typography>
                                <Select
                                    id="program"
                                    value={programValue ? programValue : ''}
                                    onChange={handleChange}
                                    disabled={!campusValue || !editable}
                                    size="small"
                                    fullWidth
                                    className={`${AdmissionsDialogsStyle.programsSelector}`}
                                    error={programError}
                                >
                                    {programs.map((e, index) => (
                                        <MenuItem key={index} value={e.value}>
                                            {e.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <TextField
                                label="Képzési program"
                                value={program}
                                disabled
                                size="small"
                                sx={{ mb: '20px' }}
                            />
                        )}

                        {programError && (
                            <FormHelperText error={programError} sx={{ marginBottom: '0.5rem' }}>
                                Ezt a képzést már kiválasztottad, kérlek válassz másikat!
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Grid item container className={`${AdmissionsStyle.contentCenter}`}>
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                            className={`${AdmissionsDialogsStyle.programsModalButton}`}
                        >
                            Mégsem
                        </Button>
                        {removable && (
                            <Button
                                variant="contained"
                                className={`${AdmissionsDialogsStyle.programsModalButton}`}
                                sx={{
                                    backgroundColor: '#D27070',
                                }}
                                onClick={handleDelete}
                            >
                                Jelentkezés törlése
                            </Button>
                        )}
                        {editable && (
                            <Button
                                onClick={handleSave}
                                disabled={!(campusValue && programValue) || programError}
                                variant="contained"
                                className={`${AdmissionsDialogsStyle.programsModalButton}`}
                            >
                                Mentés
                            </Button>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
}
