import { endpoints } from '../../../../../utils/endPoints';
import { crudTemplate } from '../../../../templates';
import { IAdministrationFeeType } from '../administrationFees';
import API from '../../../../../api';
import { fixDateRangeFields } from '../../../../../utils/AppConst';
import instance from '../../../../../api';
import { IForm } from '../../../../../utils/Interfaces/interfaces';
import { ISemesterFee } from '../../../../../pages/MasterData/Finance/AdministrationFees/SemesterFees/Form';

const administrationFeeValuesService = {
    ...crudTemplate<IAdministrationFeeType>(endpoints.masterData.finance.administrationFeeVal.main),
    create: crudTemplate<ISemesterFee>(endpoints.masterData.finance.administrationFeeVal.main).create,
    list: (form: IForm, feeId: number | string) =>
        API.get(endpoints.masterData.finance.administrationFeeVal.list.replace('{feeId}', feeId.toString()), {
            params: fixDateRangeFields(form),
        }),
    semesters: (feeId: string) =>
        instance.get(endpoints.masterData.finance.administrationFeeVal.semesters.replace('{feeId}', feeId.toString())),
    semestersEdit: (feeId: string, semesterId: string | number) =>
        instance.get(
            endpoints.masterData.finance.administrationFeeVal.semesters.replace('{feeId}', feeId.toString()) +
                '?semesterId=' +
                semesterId.toString(),
        ),
    updateValue: (id: string | number, form: ISemesterFee) =>
        API.post(endpoints.masterData.finance.administrationFeeVal.main + '/' + id, form),
    administrationFeeValBySemesterAndAdministrationFee: (id: string | number, semesterId: string | number) =>
        instance.get(
            endpoints.masterData.finance.administrationFeeVal.administrationFeeValBySemesterAndAdministrationFee
                .replace('{id}', id.toString())
                .replace('{semesterId}', semesterId.toString()),
        ),
    listPossibleStudentsForAdministrationFeeValue: (feeId: string | number, term: string) =>
        instance.get(
            endpoints.masterData.finance.administrationFeeVal.listPossibleStudentsForAdministrationFeeValue.replace(
                '{feeId}',
                feeId.toString(),
            ) +
                '?term=' +
                term,
        ),
};

export default administrationFeeValuesService;
