import buildingsService from '../../../../../../services/masterData/buildings';
import floorsService from '../../../../../../services/masterData/floors';
import roomsService from '../../../../../../services/masterData/rooms';
import {
    dependentSelectOptionsGetter,
    dependentSelectOptionsGetterValueLabel,
    Option,
} from '../../../../../../utils/AppConst';
import { TDependentSelect } from '../../../../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../../../../utils/clientEndPoints';
import ICampus from '../../../../../../interface/ICampus';
import { TParentInput } from '../../../../../../components/Form/DependentSelectInputGenerator';

const roomInputProps = (disabled: boolean, location: string): TDependentSelect => {
    const isCourse = location.includes(clientEndPoints.course_manamagement_course_list);

    return {
        name: 'room',
        type: 'multiselect',
        multiple: false,
        label: isCourse ? 'Tanterem' : 'Szoba',
        optionsGetter: isCourse
            ? dependentSelectOptionsGetterValueLabel({ fetch: roomsService.fetchClassRooms })
            : location.includes(clientEndPoints.md_general_students_list)
            ? dependentSelectOptionsGetterValueLabel({ fetch: roomsService.fetchDormRooms })
            : dependentSelectOptionsGetter(roomsService),
        disabled,
        ...(isCourse ? { format: { xs: 12, sm: 3, md: 4 } } : {}),
    };
};

const floorInputProps = (disabled: boolean, location: string): TDependentSelect => {
    const isCourse = location.includes(clientEndPoints.course_manamagement_course_list);

    return {
        name: 'floor',
        type: 'multiselect',
        label: 'Emelet',
        multiple: false,
        optionsGetter: dependentSelectOptionsGetter(floorsService),
        children: [roomInputProps(disabled, location)],
        disabled,
        ...(isCourse ? { format: { xs: 12, sm: 3, md: 4 } } : {}),
    };
};

const buildingInputProps = (disabled: boolean, location: string): TDependentSelect => ({
    name: 'building',
    type: 'multiselect',
    label: 'Épület',
    multiple: false,
    optionsGetter: dependentSelectOptionsGetter(buildingsService),
    children: [floorInputProps(disabled, location)],
    disabled,
});

export const inputProps = (disabled: boolean, location: string): TParentInput => ({
    type: 'multiselect',
    name: 'campus',
    label: 'Campus',
    multiple: false,
    children: [buildingInputProps(disabled, location)],
    disabled,
});

const createOption = (row: ICampus): Option => {
    const option: Option = {
        value: '' + row.value,
        label: row.label,
    };
    return option;
};

export const createOptions = (data: ICampus[], campus?: string): Option[] => {
    /**
     * We need to sort the options based on alphabet (and numbers should be first)
     */
    data.sort((a, b) => {
        const aLabel = a.label.toLowerCase();
        const bLabel = b.label.toLowerCase();
        if (aLabel < bLabel) {
            return -1;
        }
        if (aLabel > bLabel) {
            return 1;
        }
        return 0;
    });

    if (window.location.pathname.includes('new')) {
        return data.map(createOption).filter((value) => !value.label.includes('inaktív'));
    } else {
        return data.map(createOption).filter((value) => {
            return !value.label.includes('inaktív') || Number(value.value) === Number(campus);
        });
    }
};
