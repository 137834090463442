import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import Title from '../../../../../../components/Title';
import { IStudentAllocationPreference } from './types';

export interface StudentAllocationPreferenceDialogContentProps {
    data: IStudentAllocationPreference;
}

export default function StudentAllocationPreferenceDialogContent(props: StudentAllocationPreferenceDialogContentProps) {
    const { data } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Title title="Típus" />
                <TextField value={'Szobatárspreferencia'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Név" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Vezetéknév" value={data.lastName || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Keresztnév" value={data.firstName || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Előző szoba" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Campus" value={data.campus || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Épület" value={data.building || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Szint" value={data.floor || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Szoba" value={data.room || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Szobatárs" />
                <TextField value={data.roomMates || '-'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Megjegyzés" />
                <TextField value={data.comment || '-'} disabled />
            </Grid>
        </Grid>
    );
}
