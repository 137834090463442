import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { endpoints } from '../../../utils/endPoints';
import CourseTypeRequirements from '../CourseTypeRequirements';
import ColorExplanations from './ColorExplanations';
import LanguageExamRequirements from './LanguageExamRequirements';
import MandatoryCourses from './MandatoryCourses';
import ProgressBar from './ProgressBar';

export default function RightPanel({ selectedProgram }: { selectedProgram: null | number }) {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Grid item xs={12} md={4} container spacing={2} alignSelf="flex-start">
                {matches ? (
                    <>
                        <ProgressBar />
                        <ColorExplanations />
                    </>
                ) : null}
                <MandatoryCourses selectedProgram={selectedProgram} />
                <LanguageExamRequirements selectedProgram={selectedProgram} />
                <CourseTypeRequirements
                    fullWidth
                    url={endpoints.students.requirements.courseTypesByProgram}
                    selectedProgram={selectedProgram}
                />
            </Grid>
        </>
    );
}
