import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Checkbox, TextField } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { IEvent } from './types';
import { useCallback } from 'react';

export default function EventPresenceRow({
    row,
    eventRow,
    formikProps,
    scoringTypeText,
    readOnly,
    hasAccessToEditRoster,
    customPointPerEvent,
    errorMessage,
    checkValid,
}: {
    row: Record<string, string>;
    eventRow: IEvent;
    formikProps: FormikProps<object>;
    scoringTypeText: string;
    readOnly: boolean;
    hasAccessToEditRoster: boolean;
    customPointPerEvent: boolean;
    errorMessage: string;
    checkValid: (row: IEvent, targetValue: number) => boolean;
}) {
    const style = { padding: '4px' };

    const handleStudentChange = useCallback(
        (elem: Record<string, string>, setFieldValue: FormikProps<Record<string, string>>['setFieldValue']) =>
            (newValue: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('student_' + elem.id + '.point', parseFloat(newValue.target.value));
            },
        [],
    );

    return (
        <>
            <TableRow>
                <TableCell align={'center'} sx={style}>
                    {row.name}
                </TableCell>
                <TableCell align={'center'} sx={style}>
                    {row.email}
                </TableCell>
                <TableCell align={'center'} sx={style}>
                    <Checkbox
                        label=""
                        checkbox={{
                            checked: !!(
                                formikProps.values[('student_' + row.id) as keyof typeof formikProps.values][
                                    'present'
                                ] || ''
                            ),
                            name: 'student_' + 1,
                            disabled: !hasAccessToEditRoster || closed || readOnly,
                            onChange: () => {
                                formikProps.setFieldValue(
                                    'student_' + row.id + '.present',
                                    !formikProps.values[('student_' + row.id) as keyof typeof formikProps.values][
                                        'present'
                                    ],
                                );
                                formikProps.setFieldValue('student_' + row.id + '[point]', 0);
                            },
                        }}
                    />
                </TableCell>

                {customPointPerEvent && !readOnly && (
                    <TableCell style={{ width: '150px' }} align={'center'} sx={style}>
                        <TextField
                            error={
                                !checkValid(
                                    eventRow,
                                    formikProps.getFieldProps('student_' + row.id + '.point').value,
                                ) && formikProps.getFieldProps('student_' + row.id + '.present').value
                            }
                            value={parseFloat(formikProps.getFieldProps('student_' + row.id + '.point').value ?? 0)}
                            helperText={
                                !checkValid(
                                    eventRow,
                                    formikProps.getFieldProps('student_' + row.id + '.point').value,
                                ) && formikProps.getFieldProps('student_' + row.id + '.present').value
                                    ? errorMessage
                                    : ''
                            }
                            id="outlined-basic"
                            label={scoringTypeText}
                            type="number"
                            size="small"
                            disabled={
                                !hasAccessToEditRoster ||
                                !formikProps.values[('student_' + row.id) as keyof typeof formikProps.values]['present']
                            }
                            inputProps={{
                                step: 0.1,
                            }}
                            onChange={handleStudentChange(row, formikProps.setFieldValue)}
                        />
                    </TableCell>
                )}
            </TableRow>
        </>
    );
}
