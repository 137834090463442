import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { AutoloadTable, Page } from '@silinfo/front-end-template';
import useOpen from '../../utils/useOpen';
import { RefreshContextProvider } from '../SelfAssessment/RefreshContextProvider';
import { header, columns, filter } from './utils';
import useCheckRefresh from '../../utils/useCheckRefresh';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../utils/theme';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import OperationsMobile, { Questionnaire } from './OperationsMobile';
import Tooltip from '@mui/material/Tooltip';
import { useTenant } from '../../components/TenantContext';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    headingMain: {
        color: theme.palette.info.main,
        fontSize: '18px',
    },
    headingPaper: {
        backgroundColor: '#D9D9D9',
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(2.5),
        fontSize: '18px',
    },
    q: {
        padding: theme.spacing(1.5),
    },
    qContainer: {
        display: 'flex',
        border: '1px solid #647C9B',
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(1.2),
        paddingRight: theme.spacing(1.5),
        boxSizing: 'border-box',
    },
    qBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '8px',
    },
    courseName: {
        fontSize: '12px',
    },
    courseCode: {
        fontSize: '11px',
    },
    deadline: {
        marginTop: theme.spacing(0.5),
        fontSize: '11px',
    },
    operations: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
}));

export default function Questionnaires() {
    const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
    const { user } = useSelector((state: RootState) => state.auth);
    const { isOpen: refresh, toggleOpen: toggleRefresh } = useOpen();
    const refreshCheck = useCheckRefresh();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    const tenant = useTenant();

    useEffect(() => {
        const fetchData = async () => {
            const response = await filter();
            setQuestionnaires(response.data.data);
        };
        fetchData();
    }, []);

    return matches ? (
        <Page header={header(tenant || 'TAR')}>
            <Grid item xs={12}>
                <Typography variant="h5">Kitöltendő kérdőívek</Typography>
            </Grid>
            <Grid item xs={12}>
                <RefreshContextProvider refresh={toggleRefresh}>
                    <Paper>
                        <AutoloadTable
                            columns={user.archive ? columns.slice(0, -1) : columns}
                            filter={filter}
                            noAccordionHeader
                            responseKey="data"
                            hideFooter
                            refresh={JSON.stringify([refresh, refreshCheck])}
                            getRowId={(row) => row.courseId}
                        />
                    </Paper>
                </RefreshContextProvider>
            </Grid>
        </Page>
    ) : (
        <Grid item xs={12}>
            <Typography className={classes.headingMain}>Kérdőívek</Typography>
            <Paper className={classes.paper} elevation={3}>
                <Typography className={classes.headingPaper}>Kitöltendő kérdőívek</Typography>
                {questionnaires.map((item) => (
                    <div key={item.courseId} className={classes.qContainer}>
                        <div>
                            {item.sharedType && (
                                <Tooltip title="Megosztott kurzus">
                                    <div
                                        style={{
                                            background: '#35A899',
                                            width: '10px',
                                            height: '100%',
                                            marginLeft: '-10px',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <div className={classes.qBox}>
                            <Typography className={classes.courseName}>{item.courseName}</Typography>
                            <Typography className={classes.courseCode}>{item.courseCode}</Typography>
                            <Typography className={classes.deadline}>
                                {`Kitöltési határidő: ${item.deadline ?? 'nincs'}`}
                            </Typography>
                        </div>
                        <div className={classes.operations}>
                            <OperationsMobile row={item} />
                        </div>
                    </div>
                ))}
            </Paper>
        </Grid>
    );
}
