import React, { useState } from 'react';
import ApprovalDialogForm from './ApprovalDialogForm';

export default function ApprovalTableEditButton({
    opener,
    objectId,
    setTableLoading,
}: {
    opener: JSX.Element;
    objectId: number;
    setTableLoading: () => void;
}) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            {open && (
                <ApprovalDialogForm
                    opener={opener}
                    handleClose={handleClose}
                    objectId={objectId}
                    setTableLoading={setTableLoading}
                />
            )}
        </>
    );
}
