import { GridColDef, GridColumns } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import instance from '../../../../api';
import trainingProgramsService from '../../../../services/masterData/trainingPrograms';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { endpoints } from '../../../../utils/endPoints';
import OperationCellRenderer from './OperationsCellRenderer';
import {
    CheckResponse,
    IMemberForm,
    IMemberFormToSubmit,
    IMemberUpdateForm,
    IMemberUpdateFormToSubmit,
    Translation,
} from './types';

const names = ['campus', 'trainingLevel', 'trainingProgram'];

const header = (isEdit: boolean, tenant: string | null) => {
    const title = 'Csoport ' + (isEdit ? 'szerkesztése' : 'létrehozása');
    return {
        title,
        project: tenant || 'TAR',
        breadcrumbs: {
            scholarships: 'Ösztöndíjak',
            [clientEndPoints.scholarship_group_management]: 'Csoportok kezelése',
            edit: title,
        },
    };
};

const translations: Translation = {
    campus: 'Campus',
    trainingLevel: 'Képzési szint',
    trainingProgram: 'Képzési program',
};

const operations: GridColDef = {
    field: 'operations',
    headerName: 'Műveletek',
    headerAlign: 'center',
    width: 150,
    align: 'center',
    renderCell: OperationCellRenderer,
};

const columns = (view?: boolean): GridColumns =>
    !view
        ? names
              .map(
                  (name) =>
                      ({
                          field: name,
                          headerName: translations[name] || name,
                          headerAlign: 'center',
                          valueGetter: (params) => params.row[name]?.label || params.row[name] || '-',
                          align: 'left',
                      } as GridColDef),
              )
              .concat(operations)
        : names.map(
              (name) =>
                  ({
                      field: name,
                      headerName: translations[name] || name,
                      headerAlign: 'center',
                      valueGetter: (params) => params.row[name]?.label || params.row[name] || '-',
                      align: 'left',
                  } as GridColDef),
          );

const service = (groupId: string) => ({
    filter: async (form: IForm): Promise<AxiosResponse> =>
        instance.get(endpoints.scholarships.groupList(groupId), { params: form }),
});

const collegeYearService = {
    filter: trainingProgramsService.collegeYears,
};

const initialValue: IMemberForm = {
    campus: {
        value: '',
        label: '',
    },
    trainingLevel: {
        value: '',
        label: '',
    },
    trainingProgram: [],
};

const noOptionText = 'Amennyiben üresen küldöd el, úgy az összes elemet érinti a mentés';
const transformer = (values: IMemberForm): IMemberFormToSubmit => ({
    campus: +values.campus.value,
    trainingLevel: +values.trainingLevel.value,
    trainingProgram: values.trainingProgram.map((item) => +item.value),
});

const updateTransformer = (values: IMemberUpdateForm): IMemberUpdateFormToSubmit => ({
    id: +values.id,
    campus: +values.campus.value,
    trainingLevel: +values.trainingLevel.value,
    trainingProgram: +values.trainingProgram.value,
});

const check = (
    id: string,
    form: IMemberFormToSubmit | IMemberUpdateFormToSubmit,
): Promise<AxiosResponse<CheckResponse[][]>> => instance.post(endpoints.scholarships.check(id), form);

export {
    header,
    names,
    translations,
    columns,
    service,
    collegeYearService,
    initialValue,
    noOptionText,
    transformer,
    updateTransformer,
    check,
};
