import CourseList from '../../pages/CourseManagement/Courses';
import CourseForm from '../../pages/CourseManagement/Courses/CourseForm';

export const courseListRoute = {
    path: 'course-list',
    children: [
        {
            path: '',
            element: <CourseList />,
            name: 'course_manamagement_course_list',
        },
        {
            path: ':courseId/edit',
            element: <CourseForm />,
            name: 'course_manamagement_course_edit',
        },
        {
            path: ':courseId/show',
            element: <CourseForm readOnly={true} />,
            name: 'course_manamagement_course_show',
        },
        {
            path: 'new',
            element: <CourseForm />,
            name: 'course_manamagement_course_create',
        },
    ],
};
