import Grid from '@mui/material/Grid';
import { Accordion, CancelButton, Link, Page, Select, SubmitButton, TextField } from '@silinfo/front-end-template';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Field, Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import MCCLoading from '../../../../../../../../components/MCCLoading';
import { create } from '../../../../../../../../store/notification';
import roomsService from '../../../../../../../../services/masterData/rooms';
import itemsService, { IItemsForm } from '../../../../../../../../services/masterData/items';
import { transformApiErrors } from '../../../../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../../../../utils/clientEndPoints';
import { initialForm, itemCategoryOptions } from './utils';
import IconMenuItem from './IconMenuItem';
import { useTenant } from '../../../../../../../../components/TenantContext';

const FormSchema = Yup.object().shape({
    name: Yup.string()
        .max(128, 'Túl hosszú! A maximumálisan megadható karakterek száma 128.')
        .required('Kötelező megadni'),
    amount: Yup.number()
        .max(10000000, 'Túl nagy szám. A megadható maximális mennyiség 10 000 000.')
        .typeError('Az értéknek számnak kell lennie')
        .required('Kötelező megadni'),
    status: Yup.string().max(128, 'Túl hosszú! A maximumálisan megadható karakterek száma 128.'),
    inventoryNumber: Yup.string().max(128, 'Túl hosszú! A maximumálisan megadható karakterek száma 128.'),
});

export default function ItemsForm({ readOnly }: { readOnly?: boolean }) {
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [form, setForm] = useState<IItemsForm>(initialForm);
    const [type, setType] = useState<string>('room');
    const tenant = useTenant();
    const { campusId, buildingId, floorId, roomId, itemId } = useParams();
    const dispatch = useDispatch();
    const URL = clientEndPoints.md_general_campuses_buildings_floors_rooms_items_list
        .replace(':campusId', campusId?.toString() ?? '')
        .replace(':buildingId', buildingId?.toString() ?? '')
        .replace(':floorId', floorId?.toString() ?? '')
        .replace(':roomId', roomId?.toString() ?? '');

    const itemType = type === 'room' ? 'Tárgy' : 'Eszköz';

    const title = itemId ? form.name + ' ' + `${itemType} megtekintése` : `${itemType} megtekintése`;

    const header = {
        project: tenant || 'TAR',
        title: title,
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
            [clientEndPoints.md_general_campuses_buildings_list.replace(':campusId', campusId?.toString() || '')]:
                'Épületek',
            [clientEndPoints.md_general_campuses_buildings_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')]: 'Szintek',
            [clientEndPoints.md_general_campuses_buildings_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')
                .replace(':floorId', floorId?.toString() || '')]: 'Szobák',
            [clientEndPoints.md_general_campuses_buildings_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')
                .replace(':floorId', floorId?.toString() || '')
                .replace(':roomId', roomId?.toString() || '')]: type === 'room' ? 'Tárgyak' : 'Eszközök',
            form: title,
        },
    };

    useEffect(() => {
        setPageLoading(true);
        axios
            .all([
                ...(itemId
                    ? [
                          itemsService.get(itemId).then((response: AxiosResponse) => {
                              const data = response.data;
                              setForm(() => ({
                                  campus: data?.room?.floor?.building?.campus?.name,
                                  building: data?.room?.floor?.building?.name,
                                  floor: data?.room?.floor?.name,
                                  room: data?.room['@id'],
                                  roomName: data?.room?.name,
                                  name: data.name,
                                  amount: data.amount,
                                  status: data.status,
                                  category: data.category,
                                  inventoryNumber: data.inventoryNumber,
                              }));
                          }),
                      ]
                    : []),
                roomsService
                    .get(roomId as string)
                    .then((response: AxiosResponse) => {
                        const data = response.data;
                        setForm((prevState) => ({
                            ...prevState,
                            campus: data?.floor?.building?.campus?.name,
                            building: data?.floor?.building?.name,
                            floor: data?.floor?.name,
                            room: data['@id'],
                            roomName: data.name,
                        }));
                        setType(data.type);
                    })
                    .catch(console.error),
            ])
            .catch(() =>
                dispatch(
                    create({
                        'type': 'error',
                        'message': 'Sikertelen betöltés',
                    }),
                ),
            )
            .finally(() => setPageLoading(false));
    }, [dispatch, itemId, roomId]);

    const handleSubmit = (form: IItemsForm, { setErrors }: FormikHelpers<IItemsForm>) => {
        setFormLoading(true);
        (itemId
            ? itemsService.update(itemId, {
                  ...form,
                  amount: parseInt(String(form.amount)),
              })
            : itemsService.create({ ...form, amount: parseInt(String(form.amount)) })
        )
            .then(() => dispatch(create({ type: 'success', message: 'Sikeres mentés!', redirect: URL })))
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => setFormLoading(false));
    };

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title} defaultExpanded disableClosing>
                <Formik initialValues={form} onSubmit={handleSubmit} validationSchema={FormSchema}>
                    {({ touched, errors, values, setFieldValue }) => {
                        return (
                            <FormikForm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Field as="div" field="campus">
                                            <TextField label="Campus" name="campus" disabled value={values?.campus} />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field as="div" field="building">
                                            <TextField
                                                label="Épület"
                                                name="building"
                                                value={values?.building}
                                                disabled
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field as="div" field="floor">
                                            <TextField label="Szint" name="floor" value={values.floor} disabled />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field as="div" field="roomName">
                                            <TextField
                                                label={type === 'room' ? 'Lakószoba' : 'Tanterem'}
                                                name="roomName"
                                                value={values.roomName}
                                                disabled
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field as="div" field="name">
                                            <TextField
                                                label="Név"
                                                name="name"
                                                error={!!(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                                value={values.name}
                                                onChange={(e) => setFieldValue('name', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field as="div" field="amount">
                                            <TextField
                                                label="Mennyiség"
                                                name="amount"
                                                error={!!(touched.amount && errors.amount)}
                                                helperText={touched.amount && errors.amount}
                                                value={values.amount}
                                                onChange={(e) => setFieldValue('amount', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field as="div" field="inventoryNumber">
                                            <TextField
                                                label="Leltári szám"
                                                name="inventoryNumber"
                                                error={!!(touched.inventoryNumber && errors.inventoryNumber)}
                                                helperText={touched.inventoryNumber && errors.inventoryNumber}
                                                value={values.inventoryNumber}
                                                onChange={(e) => setFieldValue('inventoryNumber', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field as="div" field="status">
                                            <Select
                                                label="Állapot"
                                                name="status"
                                                error={!!(touched.status && errors.status)}
                                                helperText={touched.status && errors.status}
                                                options={[
                                                    { value: 'new', label: 'Új' },
                                                    { value: 'excellent', label: 'Kitűnő' },
                                                    { value: 'normal', label: 'Normál' },
                                                    { value: 'deficient', label: 'Hiányos' },
                                                    { value: 'injured', label: 'Sérült' },
                                                    { value: 'unknown', label: 'Ismeretlen' },
                                                ]}
                                                onChange={(e) => setFieldValue('status', e.target.value)}
                                                value={values.status}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field as="div" field="category">
                                            <Select
                                                label="Eszköz kategória"
                                                name="category"
                                                error={!!(touched.status && errors.status)}
                                                helperText={touched.status && errors.status}
                                                options={itemCategoryOptions.map((item) => ({
                                                    value: item.value,
                                                    label: <IconMenuItem icon={item.icon} label={item.label} />,
                                                }))}
                                                onChange={(e) => setFieldValue('category', e.target.value)}
                                                value={values.category}
                                                disabled={readOnly}
                                            ></Select>
                                        </Field>
                                    </Grid>

                                    <Grid item container xs={12} spacing={2}>
                                        {!readOnly && (
                                            <Grid item>
                                                <SubmitButton loading={formLoading} type="submit">
                                                    Mentés
                                                </SubmitButton>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Link to={URL}>
                                                <CancelButton>Mégsem</CancelButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}
