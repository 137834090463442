import { AxiosResponse } from 'axios';
import API from '../api';
import { fixDateRangeFields } from '../utils/AppConst';
import { IForm } from '../utils/Interfaces/interfaces';

export interface ICrudTemplate<T> {
    filter: (form: IForm) => Promise<AxiosResponse>;
    get: (id: string | number) => Promise<AxiosResponse>;
    create: (form: T) => Promise<AxiosResponse>;
    update: (id: string | number, form: T) => Promise<AxiosResponse>;
    delete: (id: string | number) => Promise<AxiosResponse>;
    replace: (id: string | number, form: T) => Promise<AxiosResponse>;
}

export const crudTemplate = <T>(url: string): ICrudTemplate<T> => ({
    filter: (form) => API.get(url, { params: fixDateRangeFields(form) }),
    get: (id) => API.get(url + '/' + id),
    create: (form) => API.post(url, form),
    update: (id, form) => API.patch(url + '/' + id, form),
    replace: (id, form) => API.put(url + '/' + id, form),
    delete: (id) => API.delete(url + '/' + id),
});
