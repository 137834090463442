import FinancialApprovals from '../../pages/Finance/FinancialApprovals';
import TimelineShow from '../../pages/Finance/FinancialApprovals/TimelineShow';

export const financeApprovals = {
    path: 'financial-approvals',
    children: [
        {
            path: '',
            element: <FinancialApprovals />,
            name: 'finance_financial_approvals_list',
        },
        {
            path: 'timeline/:id/show',
            element: <TimelineShow />,
            name: 'finance_financial_approvals_timeline_show',
        },
    ],
};
