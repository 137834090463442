import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Children, useEffect, useState } from 'react';
import { Row } from './Row';
import { CourseTypeRequirementsProps, ICourseType } from './types';
import instance from '../../../api';

export default function CourseTypeRequirements({
    url,
    fullWidth,
    selectedProgram,
}: CourseTypeRequirementsProps & { selectedProgram: null | number }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ICourseType[]>([]);

    useEffect(() => {
        setLoading(true);
        setData([]);
        instance.get(url + (selectedProgram ? '?program=' + selectedProgram : '')).then((res) => {
            setData(res.data);
            setLoading(false);
        });
    }, [selectedProgram, setData, setLoading, url]);

    return (
        <Grid item container xs={12} md={fullWidth ? 12 : 4} spacing={1} alignSelf="flex-start">
            {!loading && data.length === 0 ? (
                <Grid item xs={12}>
                    <Typography>Nincs megjeleníthető kurzustípus</Typography>
                </Grid>
            ) : (
                <>
                    <Grid item xs={8} textAlign="center">
                        <strong>Kurzustípus</strong>
                    </Grid>
                    <Grid item xs={4} textAlign="center">
                        <strong>Telj. / Felv. / Min.</strong>
                    </Grid>
                    <Grid item container xs={12}>
                        {Children.toArray(
                            (loading ? Array(10).fill(0) : data).map((elem) => <Row key={elem.name} {...elem} />),
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
}
