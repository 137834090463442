import { createTheme } from '@mui/material/styles';
import { Input } from '@silinfo/front-end-template';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { FormikErrors } from 'formik';
import React, { Dispatch } from 'react';
import Api from '../api';
import IEntityBase from '../interface/IEntityBase';
import { IViolations } from '../services/ResponseInterfaces';
import { TScoringType } from '../services/masterData/courses/courseTypes';
import systemMessagesService from '../services/systemMessages';
import { ICrudTemplate } from '../services/templates';
import { store } from '../store';
import { create } from '../store/notification';
import { Language } from '../store/sharedInterfaces';
import { getLastBackendRequestTime } from './AuthHelper';
import { IInfo } from './Interfaces/IInfo';
import { IForm, IUser } from './Interfaces/interfaces';
import { clientEndPoints } from './clientEndPoints';
import { endpoints } from './endPoints';

export const createStringFromUser = (user: IUser): string =>
    user ? user.lastName + ' ' + user.firstName + ' (' + user.email + ')' : '';

export const findLabelByValue = (value: Option | string, options: Option[]) => {
    if (typeof value === 'string') return options.find((opt) => opt.value === value)?.label || value || '';
    return value?.label || '';
};

export function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });

    return blob;
}
export async function getBase64(url: string): Promise<string> {
    const { data } = await Api.get(url, { responseType: 'blob' });
    return new Promise((resolve) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
            resolve(reader.result as string);
        };
    });
}

export const fixDateRangeFields = <T>(form: Record<string, T>) => {
    const fixedForm: Record<string, T> = {};
    Object.entries(form).forEach(([key, value]) => {
        const newKey = key.replace(/(.*)From/i, '$1[after]').replace(/(.*)Until/i, '$1[before]');
        fixedForm[newKey] = value;
    });
    return fixedForm;
};

export const destructArrayFields = <T>(form: Record<string, T>) => {
    const destructedForm: Record<string, T> = {};
    Object.entries(form).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
            destructedForm[key] = value.map((item) => {
                if (typeof item === 'object') {
                    return item.value;
                } else {
                    return item;
                }
            }) as unknown as T;
        } else {
            destructedForm[key] = value;
        }
    });
    return destructedForm;
};

export function deleteUnnecessaryFields(form: Record<string, unknown>, fields: string[]) {
    fields.forEach((field) => {
        if (field in form) {
            if (form[field] !== '') {
                delete form[field];
            }
        }
    });
    return form;
}

export function deleteFieldIfEmpty(form: IInfo<IForm>, fields: string[]) {
    fields.forEach((field) => {
        if (field in form.filter) {
            if (form.filter[field] === '') {
                delete form.filter[field];
            }
        }
    });
    return form;
}

export function onlySpaces(str: string) {
    return str.trim().match(/^-$/);
}

export function transformApiErrors<T extends Record<string, unknown>>(violations: Array<IViolations>): FormikErrors<T> {
    const errors: Record<string, unknown> = {};
    for (const violation of violations) {
        /*   if (violation['propertyPath'].includes('.')) {
            errors = { ...errors, ...deepen({ [violation.propertyPath]: violation.message }) };
        } else {*/
        errors[violation['propertyPath']] = violation['message'];
    }
    /*  }*/
    return errors as FormikErrors<T>;
}

export const createOption = <T extends IEntityBase>(row: T): Option => ({
    value: '' + row.id,
    label: row.name,
});
export const createOptionArchive = <T extends IEntityBase>(row: T): Option => ({
    value: '' + row.id,
    label: row.name + (row.archive ? ' (archív)' : ''),
});

export const createOptions = <T extends IEntityBase>(data: T[]): Option[] => data.map(createOption);
export const createOptionsWithArchive = <T extends IEntityBase>(data: T[]): Option[] =>
    data
        .sort((a, b) => {
            if (a.archive || b.archive) {
                if (a.archive && b.archive) return a.name.localeCompare(b.name);
                return b.archive ? -1 : 1;
            }
            return a.name.localeCompare(b.name);
        })
        .map(createOptionArchive);

export const createOptionsFromResponse = (response: AxiosResponse) => response.data['hydra:member'].map(createOption);

export const createOptionsFromDataResponse = (response: AxiosResponse) => response.data.map(createOption);

export const createOptionsFromResponseArchive = (response: AxiosResponse) =>
    response.data['hydra:member']
        .sort((a: Option & { archive: boolean; name: string }, b: Option & { archive: boolean; name: string }) => {
            if (a.archive || b.archive) {
                if (a.archive && b.archive) return a.name.localeCompare(b.name);
                return b.archive ? -1 : 1;
            }
            return a.name.localeCompare(b.name);
        })
        .map(createOptionArchive);

export const paginatorInfoBuild = <T>(info: IInfo<T>) => {
    const ret: IForm = {
        itemsPerPage: info.perpage,
        page: info.page,
    };

    if (info.sort && Object.keys(info.sort).length) {
        const orderKey = 'order[' + Object.keys(info.sort)[0].toString() + ']';
        const orderDescription = Object.values(info.sort)[0];
        ret[orderKey as keyof typeof ret] = orderDescription;
    }

    return ret;
};

export interface OrganiserOption<T = number> {
    duration: string;
    label: string;
    value: T;
}

export interface Option<T = string> {
    label: string;
    value: T;
}

export const languages: Language[] = ['hu', 'en'];

export const createInitialValues = <T extends object>(fields: Input[]): T =>
    fields.reduce((o, key) => ({ ...o, [key.name]: key.type === 'multiselect' ? [] : '' }), {}) as T;
export function fetchData<T extends IForm, D>(
    form: IForm,
    service: ICrudTemplate<T>,
    setLoading: (value: boolean) => void,
    setData: (data: D[]) => void,
    info: IInfo<D>,
    setInfo: React.Dispatch<React.SetStateAction<IInfo<D>>>,
) {
    setLoading(true);

    service
        .filter({ ...form, ...paginatorInfoBuild(info) })
        .then((d: AxiosResponse) => {
            const totalItems = d.data['hydra:totalItems'];
            setData(d.data['hydra:member']);
            setInfo((prev: IInfo<D>) => ({
                ...prev,
                metadata: {
                    'allCount': totalItems,
                    'filteredCount': totalItems,
                    'lastPage': totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                    'page': info.page,
                    'perpage': info.perpage,
                },
            }));
        })
        .catch(() =>
            store.dispatch(
                create({
                    type: 'error',
                    message: 'Hiba a betöltés során!',
                }),
            ),
        )
        .finally(() => {
            setLoading(false);
        });
}

export const initialInfo = <T>(defaultSort?: { [K in keyof T]: 'asc' | 'desc' }): IInfo<T> => ({
    filter: {},
    sort: defaultSort || {},
    page: 1,
    perpage: 25,
    metadata: {},
});

export const makeOptionsFromHydra = <T extends { id: string | number; name: string }>(arr: {
    'hydra:member': T[];
}): Option[] => arr['hydra:member'].map((elem) => ({ value: '' + elem.id, label: elem.name }));

export const financeFinancialApprovalStatuses = {
    'wait_to_un_admin_approval': 'KP admin jóváhagyásra vár',
    'wait_to_leader_approval': 'Vezetői jóváhagyásra vár',
    'invoice_during_issue': 'Számla kiállítás alatt',
    'waiting_for_payment': 'Befizetésre vár',
    'waiting_for_payment_out': 'Kifizetésre vár',
    'paid': 'Befizetve',
    'paid_for': 'Kifizetve',
    'deleted': 'Törölt',
};

export type ObjectWithCertificateFileProperties = {
    certificateFileName: string;
    certificateFilePath: string;
};
export type ObjectWithBaseFileProperties = {
    uuidFileName: string;
    origFileName: string;
};

interface TransformState extends ObjectWithCertificateFileProperties, ObjectWithBaseFileProperties {}
// Ez sajnos azért kell,
// mert nincsenek összhangban az uuidFileName/origFileName és certificateFilePath/certificateFileName elnevezések
// TODO: backenden összehangolni, hogy egységes legyen
//Converts the object's keys named certificateFilePath/certificateFileName to uuidFileName/origFileName
export const transformFileProperties = <T extends ObjectWithCertificateFileProperties>(
    obj: T,
): ObjectWithBaseFileProperties => {
    const newObj: Partial<TransformState> = { ...obj, uuidFileName: '', origFileName: '' };
    for (const key in newObj) {
        if (key === 'certificateFilePath') {
            newObj.uuidFileName = newObj[key];
            delete newObj[key];
        }
        if (key === 'certificateFileName') {
            newObj.origFileName = newObj[key];
            delete newObj[key];
        }
    }
    return newObj as ObjectWithBaseFileProperties;
};

export function dependentSelectOptionsGetter(service: { fetch: (id: string) => Promise<AxiosResponse> }) {
    return async (value: string | number) => {
        const response = await service.fetch(value as string);
        return createOptionsFromResponse(response);
    };
}

export function dependentSelectOptionsGetterValueLabel(service: { fetch: (id: string) => Promise<AxiosResponse> }) {
    return async (value: string | number) => {
        const response = await service.fetch(value as string);
        return createOptionsFromDataResponse(response);
    };
}

export const UNSEEN_MESSAGES_CHECK_INTERVAL = 30 * 1000; // 30 mp

export function dependentSelectOptionsGetterArchive(
    service: { fetch: (id: string) => Promise<AxiosResponse> },
    keyOfResponse?: string,
) {
    return async (value: string | number) => {
        const response = await service.fetch(value as string);
        return keyOfResponse === 'data'
            ? createOptionsWithArchive(response.data)
            : createOptionsFromResponseArchive(response);
    };
}

export const bankAccountNumberFormatter =
    (onChange: (key: string, value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        let value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
        const key = value.slice(-1);
        const characterRegex = /^[a-zA-Z0-9]+$/;
        const cursorPos = event.target.selectionStart;

        if ((value.length === 25 && !isNaN(parseInt(value.slice(0, 2)))) || cursorPos === null) {
            return;
        }

        let newCursorPos = cursorPos;

        if (value.length > 25 && !isNaN(parseInt(value.slice(0, 2)))) {
            value = value.slice(0, 24);
        }
        if (!key || (characterRegex.test(key) && value.length < 35)) {
            let transformedValue = value;
            if (value.length >= 2 && isNaN(parseInt(value.slice(0, 2)))) {
                transformedValue =
                    value
                        .toUpperCase()
                        .match(/.{1,4}/g)
                        ?.join(' ') || '';
                newCursorPos += Math.floor((cursorPos - 1) / 4);
            } else if (value.length >= 2 && !isNaN(parseInt(value.slice(0, 2)))) {
                transformedValue = value.match(/.{1,8}/g)?.join('-') || '';
                newCursorPos += Math.floor((cursorPos - 1) / 8);
            }
            onChange('bankAccountNumber', transformedValue);
            event.target.value = transformedValue;
            event.target.selectionStart = newCursorPos;
            event.target.selectionEnd = newCursorPos;
        }
    };

export const createQueryParams = (params: Record<string, string>) =>
    Object.keys(params)
        .map((k) => `${k}=${encodeURI(params[k])}`)
        .join('&');

export const axiosErrorRedirect = (error: AxiosError) => {
    const pathname = window.location.pathname;
    const regex = /\/felveteli\/(?=.*[a-zA-Z0-9]).{10,}$/;
    const exclusionPaths = [/\/felveteli\/.*\/elonezet$/, /\/felveteli\/.*\/preview$/];
    if (pathname === '/felveteli') return;

    let shouldReturn = false;

    if (regex.test(pathname)) {
        shouldReturn = true;

        for (const pattern of exclusionPaths) {
            if (pattern.test(pathname)) {
                shouldReturn = false;
                break;
            }
        }
    }

    if (shouldReturn) return;
    const params: Record<string, string> = {
        'error-status': error.response?.data?.messageData?.status || error.response?.status || 'unknown',
    };

    // Csak 401 esetén küldjön vissza
    if (error.response?.status === 401) {
        if (pathname.includes('/felveteli/')) {
            params.target = 'tar_admission';
        } else {
            params.target = 'tenant_' + (process.env.REACT_APP_TENANT_ID || 1) + '_tar';
        }
    }

    window.location.href = `${process.env.REACT_APP_AUTH_URL || 'https://auth01.mcc.hu/'}?${createQueryParams(params)}`;
};

//Ezresekre bontja a számot (1000 -> 1 000)
export const formatNumberToThousands = (number: number) =>
    [...('' + number)]
        .reverse()
        .join('')
        .match(/.{1,3}/g)
        ?.reverse()
        .map((elem) => elem.split('').reverse().join(''))
        .join(' ');

export const setUnseenMessagesInterval = (lastTime: number) => {
    systemMessagesService.setUnseenNotifications();
    const lastRequestTime = +getLastBackendRequestTime();
    const now = Date.now();
    const timeFromLastRequest = now - (lastRequestTime || now);
    if (lastTime > timeFromLastRequest) {
        //Amennyiben előzőleg többet kellett várni, az azt jelenti, hogy történt kérés, így ez a szál megáll
        return;
    }

    setTimeout(
        () => setUnseenMessagesInterval(timeFromLastRequest),
        timeFromLastRequest > UNSEEN_MESSAGES_CHECK_INTERVAL ? timeFromLastRequest : UNSEEN_MESSAGES_CHECK_INTERVAL,
    );
};

export const defaultTheme = createTheme();

export const scoringTypeLabel = (type: TScoringType) => (type === 'point' ? 'pont' : 'garas');

export function downloadFile(data: BlobPart, filename: string) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

export function downloadPdfFile(data: BlobPart, filename: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

export const formFilledSuccessfullyMessage = 'Köszönjük, hogy kitöltötted a kérdőívet!';
export const handleAdminPreviewOpen = (id: string | number) => () => {
    window.open(clientEndPoints.admin_questionnaire_templates_preview.replace(':id', '' + id), '_blank');
};
export const handleAdminPreviewTemplateGroupOpen = (id: string | number) => () => {
    window.open(clientEndPoints.admin_questionnaire_template_groups_preview.replace(':id', '' + id), '_blank');
};
export const isOptionEqualToValue = (option: Option, value: Option) => option.value === value.value;
export const getOptionLabel = (option: Option) => option.label;

export const createFileNameString = (value: string) =>
    value
        .replaceAll(' ', '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
export const initOption: Option = { value: '', label: '' };

export const ADMIN_FEE_CATEGORY_PAYMENT = 'payment';
export const ADMIN_FEE_CATEGORY_INPAYMENT = 'inpayment';

export const ADMIN_FEE_CATEGORIES = [
    { label: 'Befizetés', value: ADMIN_FEE_CATEGORY_INPAYMENT },
    { label: 'Kifizetés', value: ADMIN_FEE_CATEGORY_PAYMENT },
];

export const createObjectFromParams = (keyValues: [string, string][], fields: Input[]) => {
    const result: Record<string, unknown> = {};
    keyValues.forEach(([key, value]) => {
        const field = fields.find((f) => f.name === key);
        if (!field) return;
        if (field.type === 'multiselect') {
            result[key] = [...((result[key] as unknown[]) || []), value];
        } else {
            result[key] = value;
        }
    });

    return result;
};

const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await axios.post(endpoints.publicFileUpload, formData);

        return data.data;
    }
};

const handleFileDownload = async ({ origFileName, uuidFileName }: { origFileName: string; uuidFileName: string }) => {
    const { data } = await axios.get(endpoints.publicFileDownload, {
        params: {
            fileName: uuidFileName,
            type: 'temp',
        },
        responseType: 'blob',
    });
    downloadFile(data, origFileName);
};

const handleQuestionnaireFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, dispatch: Dispatch<unknown>) => {
    const file = e.target.files?.[0];
    const accept = e.target.accept;
    if (file && accept) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('accept', accept);
        try {
            const { data } = await axios.post(endpoints.publicFileQuestionnaireUpload, formData);
            return data.data;
        } catch (err) {
            dispatch(create({ type: 'error', message: 'Fájlfeltöltési hiba!' }));
        }
    }
};
const fileProps = {
    upload: handleFileUpload,
    download: handleFileDownload,
    getFileName(value: { origFileName: string }) {
        return value.origFileName;
    },
};

const questionnaireFileProps = (dispatch: Dispatch<unknown>) => ({
    upload: (e: React.ChangeEvent<HTMLInputElement>) => handleQuestionnaireFileUpload(e, dispatch),
    download: handleFileDownload,
    getFileName(value: { origFileName: string }) {
        return value.origFileName;
    },
});

export { fileProps, questionnaireFileProps, handleFileDownload, handleFileUpload, handleQuestionnaireFileUpload };

export function isKep() {
    return process.env.REACT_APP_TENANT_ID === '2';
}
