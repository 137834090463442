import { useParams } from 'react-router-dom';
import EndpointProvider from '../../../../../../../../components/EndpointProvider';
import { clientEndPoints } from '../../../../../../../../utils/clientEndPoints';
import { BtnCellRendererProps } from '../../../../../../../../utils/Interfaces/interfaces';
import Visibility from '@mui/icons-material/Visibility';
import { Link, TableButton } from '@silinfo/front-end-template';

export function BtnCellRenderer(params: BtnCellRendererProps) {
    const urlParams = useParams();
    const { campusId, buildingId, floorId, roomId } = urlParams as {
        campusId: string;
        buildingId: string;
        floorId: string;
        roomId: string;
    };

    return (
        <EndpointProvider
            endpoints={[
                'md_general_campuses_buildings_floors_rooms_items_list',
                'md_general_campuses_buildings_floors_rooms_items_show',
            ]}
        >
            <Link
                to={clientEndPoints.md_general_campuses_buildings_floors_rooms_items_show
                    .replace(':campusId', campusId?.toString() ?? '')
                    .replace(':buildingId', buildingId?.toString() ?? '')
                    .replace(':floorId', floorId?.toString() ?? '')
                    .replace(':roomId', roomId?.toString() ?? '')
                    .replace(':itemId', params.row.id?.toString() ?? '')}
            >
                <TableButton tooltip={'Megtekintés'} sx={{ marginRight: '5px' }}>
                    <Visibility />
                </TableButton>
            </Link>
        </EndpointProvider>
    );
}
