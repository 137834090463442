import SemesterForm from '../../../pages/MasterData/General/Semesters/SemesterForm';
import Semesters from '../../../pages/MasterData/General/Semesters/Semesters';

export const semesters = {
    path: 'semesters',
    children: [
        {
            path: '',
            element: <Semesters />,
            name: 'md_general_semesters_list',
        },
        {
            path: 'new',
            element: <SemesterForm />,
            name: 'md_general_semesters_create',
        },
        {
            path: ':semesterId/edit',
            element: <SemesterForm />,
            name: 'md_general_semesters_edit',
        },
        {
            path: ':semesterId/show',
            element: <SemesterForm readOnly={true} />,
            name: 'md_general_semesters_show',
        },
    ],
};
