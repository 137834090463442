import ApplicantReport from '../../pages/Admission/ApplicantReport';
import Riport from '../../pages/Admission/Riport';

const riport = [
    {
        path: 'riport',
        element: <Riport />,
        name: 'admission_riport',
    },
    {
        path: 'applicant-report',
        element: <ApplicantReport />,
        name: 'admission_applicant_report',
    },
];

export default riport;
