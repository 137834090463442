import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history, RootState } from '../../store';
import { remove } from '../../store/notification';

export default function Notification() {
    const dispatch = useDispatch();
    const { isOpen, type, message, redirect, autoHideDuration } = useSelector((state: RootState) => state.notification);

    useEffect(() => {
        if (redirect) history.push(redirect);
    }, [redirect]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isOpen}
            autoHideDuration={autoHideDuration ?? 4000}
            onClose={() => dispatch(remove())}
        >
            <Alert severity={type}>{message}</Alert>
        </Snackbar>
    );
}
