import Grid from '@mui/material/Grid';
import TimelineIcon from '@mui/icons-material/Timeline';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Input, TableButton } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MCCLoading from '../../../components/MCCLoading';
import financeFinancialApprovalsService from '../../../services/masterData/finances/financialApprovals/financialApprovals';
import { RootState } from '../../../store';
import { Option, initialInfo } from '../../../utils/AppConst';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { userHasAccess } from '../../../utils/useAccessCheck';
import { StatusRenderer } from '../AdministrationFees/StatusRenderer';
import UniqueFeeDialog from '../AdministrationFees/UniqueFeeDialog';
import ActionButtons from './ActionButtons';
import { filterFields } from './fields';
import { SESSION_KEY, TabContentProps, categoryColumn, getStatusEndpoint, header } from './utils';
import ExportButton, { ExportContext } from './ExportButton';
import RefreshCharmDataButton from './RefreshCharmDataButton';
import DownloadInvoiceButton from './DownloadInvoiceButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Check from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import Delete from '@mui/icons-material/Delete';
import { create } from '../../../store/notification';
import { makeStyles } from '@mui/styles';
import { useTenant } from '../../../components/TenantContext';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import instance from '../../../api';

export default function TabContent({ view }: TabContentProps) {
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const { user } = useSelector((state: RootState) => state.auth);
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const [defaultSemester, setDefaultSemester] = useState<null | string>(null);
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [exportInfo, setExportInfo] = useState<IForm>(initialInfo());
    const [currency, setCurrency] = useState<string>('');
    const navigate = useNavigate();
    const [fields, setFields] = useState<Input[]>(filterFields(view));
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    useEffect(() => {
        API.get(endpoints.currentTenantAll)
            .then((response) => {
                setCurrency(response.data.currencyTrans);
            })
            .catch((error) => {
                console.error('Error fetching current tenant currency:', error);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        financeFinancialApprovalsService.semesters().then((response: AxiosResponse) => {
            const field = fields.find((f) => f.name === 'semester');
            if (field?.type === 'multiselect') {
                field.options = response.data.map((value: Option & { default: boolean }) => {
                    if (value.default) {
                        setDefaultSemester(value.value);
                    }

                    return {
                        value: value.value,
                        label: value.label,
                    };
                });
                setFields(fields.map((f) => (f.name === 'semester' ? field : f)));
            }
        });

        instance
            .get(endpoints.tenantList)
            .then((response: AxiosResponse) => {
                const field = fields.find((f) => f.name === 'tenant');
                if (field?.type === 'multiselect') {
                    field.options = response.data.map((value: Option) => ({
                        value: value.value,
                        label: value.label,
                    }));

                    setFields(fields.map((f) => (f.name === 'tenant' ? field : f)));
                }
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useStyles = makeStyles(() => ({
        eyeIcon: {
            color: 'white',
            backgroundColor: '#ffc83d;',
            '&:hover': {
                backgroundColor: 'white',
                color: '#ffc83d',
            },
        },
    }));

    const classes = useStyles();

    const columns: GridColDef[] = [
        categoryColumn,
        { headerName: 'Azonosító', field: 'identifier', maxWidth: 130 },
        { headerName: 'Hallgató', field: 'student' },
        ...(view === 'guest' ? [{ headerName: 'Tenant', field: 'tenant', maxWidth: 130 }] : []),
        { headerName: 'Adóazonosító jel', field: 'taxNumber', maxWidth: 130 },
        { headerName: 'Lakcím', field: 'address' },
        { headerName: 'Bankszámlaszám', field: 'bankNumber' },
        { headerName: 'Díj típusa', field: 'feeName' },
        { headerName: 'Összeg', field: 'amount', maxWidth: 75 },
        { headerName: 'Szemeszter', field: 'semester' },
        { headerName: 'Létrehozás dátuma', field: 'createdAt' },
        {
            headerName: 'Státusz',
            field: 'status',
            sortable: false,
            renderCell: (params: GridRenderCellParams<string>) => StatusRenderer(params.row.status),
            minWidth: 100,
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams<string>) => (
                <>
                    <Grid container>
                        <ActionButtons
                            id={parseInt(params.row.id)}
                            status={params.row.status}
                            setRefresh={setRefresh}
                        />
                        <TableButton
                            style={{ marginRight: '5px' }}
                            tooltip="Idővonal"
                            className={classes.eyeIcon}
                            onClick={() =>
                                navigate(
                                    clientEndPoints.finance_financial_approvals_timeline_show.replace(
                                        ':id',
                                        params.row.id?.toString(),
                                    ),
                                )
                            }
                        >
                            <TimelineIcon />
                        </TableButton>
                        {params.row.invoiceFile && params.row.updatedFileName !== null && (
                            <DownloadInvoiceButton
                                invoiceFile={params.row.invoiceFile}
                                updatedFileName={params.row.updatedFileName}
                            />
                        )}
                        {params.row.label === 'EGY' && (
                            <Grid item>
                                <UniqueFeeDialog
                                    setReFresh={setRefresh}
                                    uniqueFeeId={params.row.id}
                                    currency={currency}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>
            ),
            sortable: false,
            minWidth: 175,
        },
    ];

    const filter = {
        fields,
        initialValues: {
            semester: defaultSemester ? [defaultSemester] : [],
            status: [
                'wait_to_un_admin_approval',
                'wait_to_leader_approval',
                'invoice_during_issue',
                'waiting_for_payment',
                'paid',
                'waiting_for_payment_out',
                'paid_for',
            ],
        },
    };

    const getData = async (form: IForm) => {
        const modifiedForm = Object.entries(form).reduce((acc, [key, value]) => {
            if (key.endsWith('Until')) {
                return {
                    ...acc,
                    [key.replace('Until', '[before]')]: value,
                };
            }

            if (key.endsWith('From')) {
                return {
                    ...acc,
                    [key.replace('From', '[after]')]: value,
                };
            }

            return {
                ...acc,
                [key]: value,
            };
        }, {});
        setExportInfo({ ...modifiedForm });
        const result = await financeFinancialApprovalsService[view === 'base' ? 'filter' : 'filterGuest'](form);
        return {
            ...result,
            data: {
                ...result.data,
                'hydra:member': result.data.data,
            },
        };
    };

    if (loading) return <MCCLoading />;

    const exportContext: ExportContext = {
        initiateExport: financeFinancialApprovalsService[view === 'base' ? 'initiateExport' : 'initiateExportGuest'],
        checkExport: financeFinancialApprovalsService[view === 'base' ? 'checkExport' : 'checkExportGuest'],
        downloadExport: financeFinancialApprovalsService[view === 'base' ? 'downloadExport' : 'downloadExportGuest'],
        info: initialInfo(),
        filter: exportInfo,
    };

    const DeleteButton = () => {
        const [openDelete, setOpenDelete] = useState(false);
        const dispatch = useDispatch();

        const handleDelete = () => {
            API.post(endpoints.finance.financialApprovals.updateOrDeleteMany, {
                action: 'delete',
                method: 'stop',
                selected: selected,
            })
                .then(() => {
                    setRefresh(true);
                    setRefresh(false);
                    setOpenDelete(false);
                    dispatch(create({ type: 'success', message: 'Sikeres törlés' }));
                })
                .catch(() => {
                    setOpenDelete(false);
                });
        };

        return (
            <Grid item>
                <IconButton onClick={() => setOpenDelete(true)}>
                    <Delete color="error" />
                </IconButton>
                <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                    <DialogTitle>Kijelölés törlése</DialogTitle>
                    <DialogContent sx={{ marginTop: '8px' }}>
                        <DialogContentText>
                            Biztosan törli a kijelölt elemeket? ({selected.length} darab)
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setOpenDelete(false)}>
                            Nem
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleDelete();
                                setRefresh(true);
                                setRefresh(false);
                                setOpenDelete(false);
                            }}
                        >
                            Igen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    const ApproveButton = () => {
        const [openApproval, setOpenApproval] = useState(false);
        const dispatch = useDispatch();

        const handleApprove = () => {
            API.post(endpoints.finance.financialApprovals.updateOrDeleteMany, {
                action: 'approve',
                method: 'next',
                selected: selected,
            })
                .then(() => {
                    setRefresh(true);
                    setRefresh(false);
                    setOpenApproval(false);
                    dispatch(create({ type: 'success', message: 'Sikeres mentés' }));
                })
                .catch(() => {
                    setOpenApproval(false);
                });
            setSelected([]);
        };

        return (
            <Grid item>
                <IconButton onClick={() => setOpenApproval(true)}>
                    <Check color="success" />
                </IconButton>
                <Dialog open={openApproval} onClose={() => setOpenApproval(false)}>
                    <DialogTitle>Kijelölés jóváhagyása</DialogTitle>
                    <DialogContent sx={{ marginTop: '8px' }}>
                        <DialogContentText>
                            Biztosan jóváhagyja a kijelölt elemeket? ({selected.length} darab)
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setOpenApproval(false)}>
                            Nem
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleApprove();
                                setRefresh(true);
                                setRefresh(false);
                                setOpenApproval(false);
                            }}
                        >
                            Igen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    const Toolbar = () => (
        <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ padding: '4px 0' }}
            justifyContent="flex-start"
            alignItems="center"
        >
            <Grid item>
                <Typography marginLeft={'1rem'} sx={{ flex: '1 1 100%', fontSize: '1rem' }}>
                    {selected.length > 0 ? `${selected.length} kiválasztva` : ''}
                </Typography>
            </Grid>
            <Grid item style={{ marginRight: '1rem' }}>
                <Grid container>
                    <Grid item style={{ visibility: selected.length > 0 ? 'visible' : 'hidden' }}>
                        <ApproveButton />
                    </Grid>
                    <Grid item style={{ visibility: selected.length > 0 ? 'visible' : 'hidden' }}>
                        <DeleteButton />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <FormikListPage
            autoload
            header={{ ...headerWithTenant, breadcrumbs: {} } as HeaderProps}
            service={{ filter: getData }}
            sessionKey={SESSION_KEY}
            columns={columns.map((col) => ({ flex: 1, ...col }))}
            refresh={refresh}
            defaultSort={{ createdAt: 'desc' }}
            customButtons={
                <>
                    {!user.archive && (
                        <>
                            <Grid item>
                                <ExportButton exportContext={exportContext} outerLoading={setLoading} />
                            </Grid>
                            {view === 'base' && (
                                <Grid item>
                                    <RefreshCharmDataButton outerLoading={setLoading} />
                                </Grid>
                            )}
                        </>
                    )}
                </>
            }
            tableProps={{
                checkboxSelection: !user.archive,
                onSelectionModelChange: setSelected,
                components: {
                    Toolbar: Toolbar,
                },
                selectionModel: selected,
                isRowSelectable: (row: GridRowParams) => {
                    const endpoint = getStatusEndpoint(row.row.status);
                    return endpoint !== 'error' && userHasAccess([endpoint], user, accessTree) && !user.archive;
                },
                initialState: {
                    pinnedColumns: {
                        right: ['status', 'operations'],
                    },
                },
                disableSelectionOnClick: true,
            }}
            filter={filter}
        />
    );
}
