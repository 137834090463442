import { Input } from '@silinfo/front-end-template';
import { scholarshipOptions } from '../GroupManagement/utils';
import { Option } from '../../../utils/AppConst';
import { service } from './utils';
import { AxiosResponse } from 'axios';

const searchGroup = async (term: string, semesterId: string): Promise<AxiosResponse> => {
    const response = await service(semesterId).groupSearch(term);
    const { data } = response;
    return {
        ...response,
        data: data,
    };
};

const searchStudent = async (term: string, semesterId: string): Promise<AxiosResponse> => {
    const response = await service(semesterId).studentSearch(term);
    const { data } = response;
    return {
        ...response,
        data: data,
    };
};

const filterFields = (options: Record<string, Option[]>, semesterId: string): Input[] => [
    {
        name: 'student',
        label: 'Hallgató',
        type: 'autocomplete',
        multiple: true,
        getter: (term: string) => searchStudent(term, semesterId),
    },
    {
        name: 'campus',
        label: 'Campus',
        type: 'multiselect',
        options: options.campus,
    },
    {
        name: 'trainingLevel',
        label: 'Képzési szint',
        type: 'multiselect',
        options: options.trainingLevel,
    },
    {
        name: 'trainingProgram',
        label: 'Képzési program',
        type: 'multiselect',
        options: options.trainingProgram,
        props: {
            renderOption: (props: object, option: Option) => {
                const { label } = option;
                if (label.includes('(archív)')) {
                    return (
                        <li {...props} style={{ color: '#999' }}>
                            {option.label}
                        </li>
                    );
                } else {
                    return <li {...props}>{option.label}</li>;
                }
            },
        },
    },
    {
        name: 'groups',
        label: 'Csoportok',
        type: 'autocomplete',
        multiple: true,
        getter: (term: string) => searchGroup(term, semesterId),
    },
    {
        name: 'scholarshipType',
        label: 'Ösztöndíj típusa',
        type: 'select',
        options: scholarshipOptions,
    },
];

export { filterFields };
