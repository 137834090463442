import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Document, DocumentsDialogProps } from '../types';
import AdmissionDateExpiredIcon from '../Icons/AdmissionDateExpiredIcon.svg';
import AdmissionCompletedIcon from '../Icons/AdmissionCompletedIcon.svg';
import AdmissionWarningIcon from '../Icons/AdmissionWarningIcon.svg';
import NotCompleted from '../Icons/NotCompleted.svg';
import { useEffect, useState } from 'react';
import { endpoints } from '../../../../utils/endPoints';
import API from '../../../../api';
import { create } from '../../../../store/notification';
import InputAdornment from '@mui/material/InputAdornment';
import { downloadFile } from '../../Administration/utils';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { convertDate } from '../utils';
import FileUpload from './FileUpload';
import AdmissionsStyle from '../Admissions.module.scss';
import AdmissionsDialogsStyle from './AdmissionsDialogs.module.scss';
import Alert from '@mui/material/Alert';
import { defaultTheme } from '../../../../utils/AppConst';
import { ThemeProvider } from '@mui/styles';
import axios from 'axios';

export default function DocumentsDialog({
    openState,
    isRegistrated,
    token,
    serialState,
    disabledState,
    refresh,
    admission,
}: DocumentsDialogProps) {
    const [open, setOpen] = openState;
    const [uploadableDocuments, setUploadableDocuments] = useState<Document[]>([]);
    const [informationDocuments, setInformationDocuments] = useState<Document[]>([]);
    const [currentSerial, setCurrentSerial] = serialState;
    const [admissionDisabled, setAdmissionDisabled] = disabledState;
    const [types, setTypes] = useState<string>('');
    const [maxSize, setMaxSize] = useState<string>('');
    const handleClose = (force = true) => {
        if (force) {
            refresh();
        }
        setOpen(false);
        setCurrentSerial(null);
        setUploadableDocuments([]);
        setInformationDocuments([]);
        setAdmissionDisabled(false);
    };
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const getStatusIcon = (document: Document) => {
        if (document.fileName) {
            return <img src={AdmissionCompletedIcon} />;
        }
        if (document.rule === 'optional' && new Date(convertDate(document.deadline)) < new Date()) {
            return <img src={AdmissionWarningIcon} />;
        }
        if (new Date(convertDate(document.deadline)) < new Date()) {
            return <img src={AdmissionDateExpiredIcon} />;
        }
        return <img src={NotCompleted} />;
    };

    const handleChangeDocuments = (documents: Document[]) => {
        setUploadableDocuments(documents);
    };

    const handleDownload = (document: Document) => {
        const endpoint = isRegistrated
            ? endpoints.admission.downloadDocumentMcc(currentSerial, document.id)
            : endpoints.admission.downloadDocument(token, currentSerial, document.id);

        const request = isRegistrated
            ? API.get(endpoint, {
                  responseType: 'blob',
              })
            : axios.get(endpoint, {
                  responseType: 'blob',
              });
        request.then((response) => downloadFile(response.data, document.fileName));
    };

    const handleDeleteDocument = (serial: number | null, documentId: number) => {
        const endpoint = isRegistrated
            ? endpoints.admission.deleteDocumentMcc(serial, documentId)
            : endpoints.admission.deleteDocument(token, serial, documentId);

        const deleteRequest = isRegistrated ? API.delete(endpoint) : axios.delete(endpoint);
        deleteRequest
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres dokumentum törlés' }));
                const endpoint = isRegistrated
                    ? endpoints.admission.getDocumentsMcc(serial)
                    : endpoints.admission.getDocuments(token, serial?.toString());

                const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
                request.then((response) => handleChangeDocuments(response.data.uploadableDocuments));
            })
            .catch((err) => {
                dispatch(
                    create({ type: 'error', message: err.response.data.err ?? 'Sikertelen dokumentum feltöltés' }),
                );
            });
    };

    const checkAllDocumentExpired = () => {
        const expiredDocument = uploadableDocuments.filter(
            (document) => new Date(convertDate(document.deadline)) < new Date(),
        ).length;
        if (expiredDocument === uploadableDocuments.length) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            axios.get(endpoints.admission.settings).then((response) => {
                if (response.data.types) {
                    setTypes(response.data.types);
                }
                if (response.data.maxSize) {
                    setMaxSize(response.data.maxSize);
                }
            });
            const endpoint = isRegistrated
                ? endpoints.admission.getDocumentsMcc(currentSerial)
                : endpoints.admission.getDocuments(token, currentSerial?.toString());

            const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
            request
                .then((response) => {
                    if (response.data.uploadableDocuments) {
                        setUploadableDocuments(response.data.uploadableDocuments);
                    }
                    if (response.data.informationDocuments) {
                        setInformationDocuments(response.data.informationDocuments);
                    }
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [currentSerial, token, isRegistrated, open]);

    return (
        <Modal open={open} onClose={() => handleClose(true)}>
            <Grid container className={`${AdmissionsDialogsStyle.documentsModal}`}>
                {loading ? (
                    <Grid item container xs={12} sx={{ justifyContent: 'center' }}>
                        <CircularProgress size={100} />
                    </Grid>
                ) : (
                    <Grid container className={`${AdmissionsDialogsStyle.modalContent}`}>
                        <Grid item container xs={12} sx={{ justifyContent: 'center' }}>
                            <Typography className={`${AdmissionsDialogsStyle.title}`}>Dokumentumok</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                            <ThemeProvider theme={defaultTheme}>
                                <Alert
                                    severity="info"
                                    sx={{
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Elfogadott formátum: {types}
                                    <br></br>
                                    Maximum fájlméret: {maxSize} MB
                                </Alert>
                            </ThemeProvider>
                        </Grid>
                        <Grid item container>
                            <Typography className={`${AdmissionsDialogsStyle.subtTitle}`}>
                                Feltöltendő dokumentumok
                            </Typography>
                            {uploadableDocuments.map((document, index) => (
                                <Grid
                                    key={index}
                                    item
                                    container
                                    xs={12}
                                    className={`${AdmissionsDialogsStyle.documentContainer}`}
                                >
                                    <Grid item container xs={7} sx={{ flexDirection: 'column' }}>
                                        <Grid item>
                                            <Typography className={`${AdmissionsDialogsStyle.subtTitle}`}>
                                                {document.name}
                                                {document.rule === 'optional' && (
                                                    <span
                                                        style={{
                                                            fontSize: '14px',
                                                            marginLeft: '0.5rem', // Adjust the spacing as needed
                                                            color: 'grey',
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        (opcionális)
                                                    </span>
                                                )}
                                            </Typography>
                                        </Grid>
                                        {document.fileName ? (
                                            <Grid item container sx={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    lg={8}
                                                    onClick={() => handleDownload(document)}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        value={document.fileName}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <DownloadIcon
                                                                        className={
                                                                            AdmissionsDialogsStyle.documentDownloadIcon
                                                                        }
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        inputProps={{
                                                            'aria-disabled': 'true',
                                                        }}
                                                        focused={false}
                                                        sx={{
                                                            input: {
                                                                cursor: 'pointer',
                                                                caretColor: 'transparent',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '&:hover fieldset': {
                                                                    borderColor: '#c4c4c4',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                {new Date(convertDate(document.deadline)) > new Date() &&
                                                    !admission?.isFinalized && (
                                                        <Grid item xs={1} lg={2} sx={{ marginLeft: '0.5rem' }}>
                                                            <Button
                                                                variant="outlined"
                                                                component="label"
                                                                startIcon={<DeleteIcon />}
                                                                className={AdmissionsDialogsStyle.documentDeleteButton}
                                                                onClick={() =>
                                                                    handleDeleteDocument(currentSerial, document.id)
                                                                }
                                                            >
                                                                Törlés
                                                            </Button>
                                                        </Grid>
                                                    )}
                                            </Grid>
                                        ) : (
                                            <FileUpload
                                                handleChangeDocuments={handleChangeDocuments}
                                                isRegistrated={isRegistrated}
                                                document={document}
                                                serial={currentSerial}
                                                token={token}
                                                admissionDisabled={admissionDisabled}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item container xs={5} wrap="nowrap" sx={{ justifyContent: 'center' }}>
                                        <Grid
                                            item
                                            container
                                            xs={8}
                                            className={AdmissionsStyle.contentCenter}
                                            sx={{ marginRight: '2rem' }}
                                        >
                                            <Typography className={AdmissionsDialogsStyle.documentUploadDeadline}>
                                                Feltöltési határidő:
                                            </Typography>
                                            <Typography
                                                className={AdmissionsDialogsStyle.documentUploadDeadline}
                                                sx={{
                                                    ...(new Date(convertDate(document.deadline)) < new Date() && {
                                                        color: '#D27070',
                                                    }),
                                                }}
                                            >
                                                {convertDate(document.deadline)}
                                                {new Date(convertDate(document.deadline)) < new Date() && ' (Lejárt)'}
                                            </Typography>
                                        </Grid>
                                        <Grid item container xs={2}>
                                            {getStatusIcon(document)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            {informationDocuments.length > 0 && (
                                <>
                                    <Typography className={`${AdmissionsDialogsStyle.subTitle}`}>
                                        Tájékoztatók
                                    </Typography>
                                    {informationDocuments.map((document, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            container
                                            xs={12}
                                            className={`${AdmissionsDialogsStyle.documentInformational}`}
                                        >
                                            <Typography sx={{ fontSize: '16px' }}>{document.name}</Typography>
                                            <Button
                                                onClick={() => handleDownload(document)}
                                                variant="contained"
                                                sx={{ textDecoration: 'none' }}
                                                startIcon={<DownloadIcon />}
                                            >
                                                Fájl letöltése
                                            </Button>
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </Grid>
                        <Grid item container className={AdmissionsStyle.contentCenter} sx={{ marginTop: '1rem' }}>
                            <Button
                                onClick={() => handleClose(false)}
                                variant="outlined"
                                sx={{ marginRight: '0.75rem' }}
                            >
                                Vissza
                            </Button>
                            {!admission?.isFinalized && (
                                <Button
                                    onClick={() => handleClose(true)}
                                    variant="contained"
                                    sx={{ marginLeft: '0.75rem' }}
                                    disabled={checkAllDocumentExpired() ? true : false}
                                >
                                    Mentés
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
}
