import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGridPro, DataGridProProps, getGridStringOperators } from '@mui/x-data-grid-pro';
import { Page } from '@silinfo/front-end-template';
import { UIEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { IInfo } from '../../utils/Interfaces/IInfo';
import { endpoints } from '../../utils/endPoints';
import { theme } from '../../utils/theme';
import { RefreshContextProvider } from '../SelfAssessment/RefreshContextProvider';
import useFetch from './../../utils/useFetch';
import './CourseEnrollment.scss';
import CustomToolbar from './Table/CustomToolbar';
import MobilView from './Table/MobilView';
import { ICourseEnrollmentRow } from './types';
import { columns, header, initialInfo, makeQuery, makeUrl, partialTableProps } from './utils';
import { useTenant } from '../../components/TenantContext';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';

interface IResponse {
    data: ICourseEnrollmentRow[];
    metadata: IInfo<ICourseEnrollmentRow>['metadata'];
}

const isResponse = (data: IResponse | []): data is IResponse => (data as IResponse).data !== undefined;

export default function CourseEnrollment() {
    const [refresh, setRefresh] = useState(false);
    const isEnrolledFetch = useFetch<{ enrolled: boolean }>(endpoints.students.courseEnrollment.isEnrolled, '');
    const [info, setInfo] = useState(initialInfo);
    const [url, setUrl] = useState(makeUrl(endpoints.students.courseEnrollment.coursesList, info));
    const { data, loading } = useFetch<IResponse>(url, '', refresh);
    const matches = useMediaQuery('(min-width:960px)');
    const done = isResponse(data) && data.metadata.lastPage === info.page;
    const [visible, setVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [mobileData, setMobileData] = useState<ICourseEnrollmentRow[]>([]);
    const tenant = useTenant();

    const toggleRefresh = useCallback(() => setRefresh((prev) => !prev), []);

    const Toolbar = useCallback(() => <CustomToolbar setInfo={setInfo} info={info} />, [info]);

    useEffect(() => {
        setUrl(
            makeUrl(endpoints.students.courseEnrollment.coursesList, info) +
                (makeQuery(info.filter || {}) ? '&' + makeQuery(info.filter || {}) : ''),
        );
    }, [info]);

    const tableProps: DataGridProProps = {
        ...partialTableProps,
        loading: loading || isEnrolledFetch.loading,
        onSortModelChange: (model) =>
            setInfo((prev) => ({
                ...prev,
                sort: model[0]?.sort ? { [model[0].field]: model[0].sort } : {},
            })),
        onPageChange: (page) => setInfo((prev) => ({ ...prev, page: page + 1 })),
        onPageSizeChange: (size) => setInfo((prev) => ({ ...prev, perpage: size })),
        columns: columns(setRefresh, !isEnrolledFetch.loading && isEnrolledFetch.data.enrolled).map((e) => ({
            flex: 1,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
            ...e,
        })),
        disableColumnSelector: true,
        rows: loading || !data.data ? [] : data.data,
        getRowClassName: (params) => {
            if (params.row.isEnrolled == 'yes') {
                return 'enrolled';
            } else if (params.row.isEnrolled == 'waiting_list' || params.row.hasOpenCourseEnrollmentRequest) {
                return 'waiting';
            }
            return '';
        },
        pageSize: info.perpage ? info.perpage : 25,
        rowCount: loading || !data.metadata?.filteredCount ? 0 : +data.metadata?.filteredCount,
        page: info.page ? info.page - 1 : 0,
        components: {
            Toolbar,
        },
        density: 'compact',
        columnBuffer: ((!Array.isArray(data) && data?.data) || []).length,
    };

    useEffect(() => {
        if (!loading && data?.data?.length > 0) {
            setMobileData((prev) => [...prev, ...data.data]);
        }
    }, [loading, data]);

    useEffect(() => {
        if (!matches) {
            setInfo((prev) => ({ ...prev, page: 1 }));
            setMobileData([]);
        }
    }, [refresh, matches, info.filter]);

    const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            const MIN_SCROLL = 100;
            const target = e.target as HTMLDivElement;
            setVisible(target.scrollTop > MIN_SCROLL);

            if (matches || loading || done) return;
            if (
                target.scrollHeight - Math.ceil(target.scrollTop) === target.clientHeight &&
                info.page < +data.metadata.lastPage
            ) {
                setMobileData((prev) =>
                    [...prev, ...(data as IResponse).data].filter(
                        // unique by id
                        (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
                    ),
                );
                setInfo((prev) => ({ ...prev, page: prev.page + 1 }));
            }
        },
        [matches, loading, done, info.page, data],
    );

    return (
        <>
            <div onScroll={handleScroll} ref={ref} style={{ height: 'calc(100vh - 88px)', overflow: 'auto' }}>
                <Page header={header(tenant || 'TAR') as HeaderProps}>
                    <Grid item xs={12}>
                        {matches ? (
                            <Paper elevation={3}>
                                <DataGridPro {...tableProps} disableColumnMenu disableSelectionOnClick autoHeight />
                            </Paper>
                        ) : (
                            <RefreshContextProvider refresh={toggleRefresh}>
                                <MobilView
                                    info={info}
                                    setInfo={setInfo}
                                    data={mobileData}
                                    loading={loading}
                                    done={done}
                                />
                            </RefreshContextProvider>
                        )}
                    </Grid>
                </Page>
            </div>

            <div
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    visibility: visible ? 'visible' : 'hidden',
                }}
            >
                <Fab
                    className="borderRadius"
                    size="small"
                    onClick={() => {
                        if (ref.current) ref.current.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                    style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.primary.main }}
                >
                    <ArrowUpwardOutlinedIcon />
                </Fab>
            </div>
        </>
    );
}
