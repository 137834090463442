import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Tooltip } from '@silinfo/front-end-template';
import { theme } from '../../../../utils/theme';
import { semesterStatus } from '../utils';

export const useStylesTooltip = makeStyles(() => ({
    truncate: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    grey: {
        color: theme.palette.grey[500],
    },
}));

export type DefaultCellRendererProps = {
    params: GridRenderCellParams;
};

export default function SemesterStatusCellRenderer({ params }: DefaultCellRendererProps) {
    const classes = useStylesTooltip();
    const status = semesterStatus.find((s) => s.value === params?.value);
    const statusLabel = status?.label || '-';
    const style = params.value === 'active' ? { color: theme.palette.success.main } : undefined;

    return (
        <Tooltip title={statusLabel}>
            <div className={classes.truncate} style={style}>
                {statusLabel}
            </div>
        </Tooltip>
    );
}
