import { LoadingButton } from '@mui/lab';
import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { CancelButton, CKEditor, Loading } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import EndpointProvider from '../../../../components/EndpointProvider';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import courseListService from '../../../../services/courseManagement/courseList';
import { create } from '../../../../store/notification';
import { Option, transformApiErrors } from '../../../../utils/AppConst';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import useAccessCheck from '../../../../utils/useAccessCheck';
import RateButton from '../RateButton';
import { useSearchParams } from 'react-router-dom';

interface IStudentRating extends IForm {
    name: string;
    trainingProgram: string;
    numberOfMissing: string;
    evaluation: string;
    evaluationText: string;
    grade: string;
    gradeInfo: {
        needGrade: boolean;
        min: number;
        max: number;
    };
}

const initState: IStudentRating = {
    name: '',
    trainingProgram: '',
    numberOfMissing: '',
    evaluation: '',
    evaluationText: '',
    grade: '',
    gradeInfo: {
        needGrade: false,
        min: 0,
        max: 0,
    },
};

export default function StudentRatingDialog(props: {
    studentId: number | string;
    evaluationStatus: string;
    courseId: number | string;
    evaluationOptions: Option[];
    isCourseEvaluable: boolean;
    isClosed: boolean;
    readOnly?: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const {
        studentId,
        evaluationStatus,
        courseId,
        evaluationOptions,
        isCourseEvaluable,
        isClosed,
        readOnly,
        setLoading,
    } = props;
    const [open, setOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const hasAccessToEdit = useAccessCheck(['course_manamagement_course_student_rating']);
    const [form, setForm] = useState(initState);
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');

    const dispatch = useDispatch();
    const useStyles = makeStyles(() => ({
        richTextEditor: {
            '& .ck-editor__main > .ck-editor__editable': {
                height: '30vh',
            },
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        if (open) {
            setFormLoading(false);
            setFormLoading(true);
            courseListService
                .evaluateView(courseId, studentId, courseTenant, readOnly)
                .then((res) =>
                    setForm({
                        ...res.data.data,
                        numberOfMissing: res.data.data.numberOfMissing === 0 ? '0' : res.data.data.numberOfMissing,
                    }),
                )
                .finally(() => setFormLoading(false));
        }
    }, [readOnly, courseId, open, studentId, courseTenant]);

    const handleSubmit = (form: IStudentRating, { setErrors }: FormikHelpers<IStudentRating>) => {
        setBtnLoading(true);
        return courseListService
            .evaluate(courseId, studentId, courseTenant, {
                evaluation: form.evaluation,
                evaluationText: form.evaluationText,
                grade: form.grade,
            })
            .then(() => {
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                    }),
                );
                setOpen(false);
                setBtnLoading(false);
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                } else {
                    setOpen(false);
                }

                if (error.response?.data.error) {
                    dispatch(create({ type: 'error', message: error.response?.data.error }));
                } else {
                    dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
                }
                setBtnLoading(false);
            })
            .finally(() => setLoading((prev) => !prev));
    };
    return (
        <Grid item>
            <RateButton
                disabled={readOnly ? evaluationStatus !== 'evaluated' : !isCourseEvaluable}
                tooltip="Hallgató értékelése"
                onClick={() => setOpen(true)}
            />
            <DialogBasic scroll="paper" open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'lg'}>
                <DialogTitle>{'Hallgató értékelése'}</DialogTitle>
                <DialogContent dividers>
                    {!formLoading ? (
                        <Formik initialValues={form} onSubmit={handleSubmit}>
                            {(formikProps) => {
                                return (
                                    <FormikForm>
                                        {FieldGenerator({
                                            name: 'name',
                                            label: 'Név',
                                            format: { xs: 12, sm: 12 },
                                            props: {
                                                disabled: true,
                                            },
                                            ...formikProps,
                                        })}
                                        {FieldGenerator({
                                            name: 'trainingProgram',
                                            label: 'Képzési program',
                                            props: {
                                                disabled: true,
                                            },
                                            format: { xs: 12, sm: 12 },
                                            ...formikProps,
                                        })}
                                        {FieldGenerator({
                                            name: 'numberOfMissing',
                                            label: 'Hiányzások száma',
                                            props: {
                                                disabled: true,
                                            },
                                            format: { xs: 12, sm: 12 },
                                            ...formikProps,
                                        })}
                                        {FieldGenerator({
                                            name: 'evaluation',
                                            label: 'Értékelés',
                                            type: 'select',
                                            fieldType: 'base',
                                            options: evaluationOptions,
                                            format: { xs: 12, sm: 12 },
                                            props: {
                                                disabled:
                                                    !hasAccessToEdit ||
                                                    isClosed ||
                                                    readOnly ||
                                                    formikProps.values.isGuest,
                                            },
                                            ...formikProps,
                                        })}
                                        {form.gradeInfo.needGrade &&
                                            FieldGenerator({
                                                name: 'grade',
                                                label: 'Pontszám',
                                                format: { xs: 12, sm: 12 },
                                                props: {
                                                    disabled:
                                                        !hasAccessToEdit ||
                                                        isClosed ||
                                                        readOnly ||
                                                        formikProps.values.isGuest,
                                                    helperText: formikProps?.errors['grade'] || (
                                                        <>
                                                            {form.gradeInfo.min} és {form.gradeInfo.max} közötti érték
                                                        </>
                                                    ),
                                                },
                                                ...formikProps,
                                            })}
                                        <div
                                            className={classes.richTextEditor}
                                            style={
                                                formikProps?.errors['evaluationText']
                                                    ? {
                                                          border: '0.5px solid red',
                                                          borderStyle: 'solid',
                                                      }
                                                    : {}
                                            }
                                        >
                                            {hasAccessToEdit &&
                                            !isClosed &&
                                            !readOnly &&
                                            !formikProps.values.isGuest ? (
                                                <CKEditor
                                                    name={`evaluationText`}
                                                    format={{ xs: 12 }}
                                                    style={{ maxHeight: '10vh' }}
                                                    data={formikProps.values?.evaluationText || ''}
                                                    onChange={(_: unknown, editor: { getData: () => string }) =>
                                                        formikProps.setFieldValue(`evaluationText`, editor.getData())
                                                    }
                                                />
                                            ) : (
                                                <Grid item xs={12}>
                                                    <fieldset
                                                        style={{
                                                            margin: 0,
                                                            border: '1px solid rgba(0, 0, 0, 0.38)',
                                                            color: 'rgba(0, 0, 0, 0.38)',
                                                        }}
                                                    >
                                                        <legend>Megjegyzés</legend>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: formikProps.values?.evaluationText || '',
                                                            }}
                                                            style={{
                                                                overflowWrap: 'anywhere',
                                                            }}
                                                        />
                                                    </fieldset>
                                                </Grid>
                                            )}
                                        </div>

                                        {!!formikProps?.errors['evaluationText'] && (
                                            <span style={{ color: 'red', marginLeft: '9px', fontSize: '12.5px' }}>
                                                Maximum 1024 karakter adható meg!
                                            </span>
                                        )}

                                        <Grid item mt={0.5} container spacing={2} justifyContent="flex-end">
                                            {!isClosed && !readOnly && !formikProps.values.isGuest && (
                                                <EndpointProvider
                                                    endpoints={['course_manamagement_course_student_rating']}
                                                >
                                                    <Grid item>
                                                        <LoadingButton
                                                            disabled={false}
                                                            variant="contained"
                                                            type="submit"
                                                            loading={btnLoading}
                                                        >
                                                            Mentés
                                                        </LoadingButton>
                                                    </Grid>
                                                </EndpointProvider>
                                            )}
                                            <Grid item>
                                                <CancelButton onClick={() => setOpen(false)}>
                                                    {hasAccessToEdit && !isClosed && !readOnly ? 'Mégse' : 'Vissza'}
                                                </CancelButton>
                                            </Grid>
                                        </Grid>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    ) : (
                        <Loading noHeight />
                    )}
                </DialogContent>
            </DialogBasic>
        </Grid>
    );
}
