import { Input } from '@silinfo/front-end-template';
import {
    archivedOptions,
    calculationTypeOptions,
    completionOptions,
    scholarshipOptions,
    statusOptions,
    service,
} from './utils';
import { AxiosResponse } from 'axios';

const dummyGetter = async (): Promise<AxiosResponse> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                config: {},
                headers: {},
                status: 200,
                statusText: 'OK',
                data: {
                    data: [
                        { value: 1, label: 'Teszt 1' },
                        { value: 2, label: 'Teszt 2' },
                        { value: 3, label: 'Teszt 3' },
                        { value: 4, label: 'Teszt 4' },
                    ],
                },
            });
        }, 1000);
    });
};

const search = async (term: string, semesterId: string): Promise<AxiosResponse> => {
    const response = await service(semesterId).nameSearch(term);
    const { data } = response;
    return {
        ...response,
        data: data,
    };
};

const filterFields = (semesterId: string): Input[] => [
    {
        name: 'groupNameIds',
        label: 'Csoport neve',
        type: 'autocomplete',
        multiple: true,
        getter: (term: string) => search(term, semesterId),
    },
    {
        name: 'scholarshipType',
        label: 'Ösztöndíj típusa',
        type: 'multiselect',
        options: scholarshipOptions,
    },
    {
        name: 'calculationType',
        label: 'Kalkuláció típusa',
        type: 'multiselect',
        options: calculationTypeOptions,
    },
    {
        name: 'completionType',
        label: 'Teljesítés típusa',
        type: 'multiselect',
        options: completionOptions,
    },
    {
        name: 'used',
        label: 'Státusz',
        type: 'multiselect',
        options: statusOptions,
    },
    {
        name: 'archived',
        label: 'Archiválva?',
        type: 'select',
        options: archivedOptions,
    },
];

export { filterFields, dummyGetter };
