import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../../../../../../../components/Form/Form';
import MCCLoading from '../../../../../../../components/MCCLoading';
import trainingProgramRequirementsService from '../../../../../../../services/masterData/trainingProgramRequirements';
import { IForm } from '../../../../../../../utils/Interfaces/interfaces';
import BaseForm from './BaseForm';

export interface IBaseRequirements extends IForm {
    id: number;
    numberOfSemesters: number;
    mandatoryPointToFullCourse: number;
    minPointPerSemester: number;
    minPointCoTrainingCourses: number;
    redirect: string;
}

export default function BaseRequirements(params: { readonly?: boolean }) {
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState<IBaseRequirements>({
        id: 0,
        numberOfSemesters: 0,
        mandatoryPointToFullCourse: 0,
        minPointPerSemester: 0,
        minPointCoTrainingCourses: 0,
        redirect: '',
    });
    const navigate = useNavigate();
    const { id, programId, campusId } = useParams();

    useEffect(() => {
        trainingProgramRequirementsService.getBase(id, programId, campusId).then((response) => {
            setInitialValues(response.data.data);
            setLoading(false);
        });
    }, [campusId, id, programId]);

    if (loading) {
        return <MCCLoading />;
    }

    const onSubmit = (form: IBaseRequirements) =>
        trainingProgramRequirementsService.saveBase(id, programId, campusId, form).then(() => {
            const redirect = form.redirect;
            if (redirect !== 'edit') {
                navigate(redirect);
            }
        });

    return (
        <Form hideButtons fields={[]} onSubmit={onSubmit} initialValues={initialValues} urlParam="courseId">
            {(props) => <BaseForm {...props} readonly={params.readonly} />}
        </Form>
    );
}
