import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridColDef, GridRowModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { translation } from '@silinfo/front-end-template';
import { financeFinancialApprovalStatuses, formatNumberToThousands } from '../../utils/AppConst';
import { Paper } from '../Requirements/Paper';
import FinanceInfoComponent from './FinanceInfoComponent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../utils/theme';
import { GridRenderCellParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

export type TFinanceStatus = keyof typeof financeFinancialApprovalStatuses;

export interface IFinanceRow {
    id: number;
    identifier: string;
    name: string;
    amount: number;
    date: string;
    status: TFinanceStatus;
    comment: string;
    invoiceFile?: string;
    updatedFileName?: string;
}

interface FinanceTableProps {
    title: string;
    rows: GridRowModel[];
    columns?: GridColDef[];
    loading?: boolean;
    currency?: string;
}

const FinanceTable = (props: FinanceTableProps) => {
    const { rows, title, loading, currency } = props;

    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const columns: GridColDef[] =
        props.columns?.map((col) => {
            if (!matches) {
                return {
                    ...col,
                    minWidth: 60,
                    maxWidth: 300,
                    renderCell: (params: GridRenderCellParams) => (
                        <Tooltip title={params.value || '-'}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</div>
                        </Tooltip>
                    ),
                };
            }
            return { ...col };
        }) ||
        [
            {
                headerName: 'Azonosító',
                field: 'identifier',
            },
            {
                headerName: 'Megnevezés',
                field: 'name',
                minWidth: 300,
            },
            {
                headerName: 'Összeg',
                field: 'amount',
                valueGetter: (params: GridValueGetterParams) =>
                    `${formatNumberToThousands(params.row.amount || 0)} ${currency}`,
                minWidth: 100,
            },
            {
                headerName: 'Létrehozás dátuma',
                field: 'date',
            },
            {
                headerName: 'Státusz',
                field: 'status',
                valueGetter: (rowData: GridValueGetterParams<TFinanceStatus>) =>
                    rowData.value ? financeFinancialApprovalStatuses[rowData.value] : '-',
            },
            {
                headerName: '',
                renderCell: FinanceInfoComponent,
                field: 'info',
                minWidth: 100,
                flex: 0.5,
            },
        ].map((col) => {
            if (!matches) {
                if (col.headerName === '') {
                    return {
                        headerName: '',
                        renderCell: (params: GridRenderCellParams) => (
                            <Tooltip title={params.value || '-'}>
                                <FinanceInfoComponent {...params} />
                            </Tooltip>
                        ),
                        field: 'info',
                        minWidth: 80,
                        maxWidth: 300,
                        flex: 0.5,
                    };
                }
                return {
                    ...col,
                    minWidth: 60,
                    maxWidth: 300,
                    renderCell: (params: GridRenderCellParams) => (
                        <Tooltip title={params.value || '-'}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</div>
                        </Tooltip>
                    ),
                };
            }
            return { flex: 1, minWidth: 150, ...col };
        });

    return (
        <Grid item xs={12}>
            <Paper>
                <Grid item container spacing={3} xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{title}</Typography>
                    </Grid>
                    <Grid item xs={12} className="noTransition">
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            autoHeight
                            density="compact"
                            localeText={translation}
                            hideFooter
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default FinanceTable;
