import RequirementsCampuses from '../../../pages/MasterData/General/TrainingLevels/TrainingPrograms/Requirements/RequirementsCampuses';
import Requirements from '../../../pages/MasterData/General/TrainingLevels/TrainingPrograms/Requirements/Requirements';

export const trainingLevelsProgramsRequirements = {
    path: ':programId',
    children: [
        {
            path: 'requirements',
            element: <RequirementsCampuses />,
            name: 'md_training_levels_programs_requirements_campuses',
        },
        {
            path: 'requirements/:campusId',
            children: [
                {
                    path: '',
                    element: <Requirements />,
                    name: 'md_training_levels_programs_requirements',
                },
            ],
        },
    ],
};
