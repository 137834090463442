import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';
import { ILanguageExamRequirement } from '../../pages/MasterData/General/TrainingLevels/LanguageExamRequirements/fields';
import { fixDateRangeFields } from '../../utils/AppConst';
import { IForm } from '../../utils/Interfaces/interfaces';

const languageExamRequirements = {
    ...crudTemplate(endpoints.masterData.languageExamRequirements.main),
    updateLanguageExamRequirement: (id: string | number, form: ILanguageExamRequirement) =>
        API.post(endpoints.masterData.languageExamRequirements.main + '/' + id, form),
    languages: () => API.get(endpoints.masterData.languageExamRequirements.languages),
    categories: () => API.get(endpoints.masterData.languageExamRequirements.categories),
    levels: () => API.get(endpoints.masterData.languageExamRequirements.levels),
    check: () => API.get(endpoints.masterData.languageExamRequirements.check),
    checkFine: () => API.get(endpoints.masterData.languageExamRequirements.checkFine),
    filterByTrainingLevel: (form: IForm, trainingLevel: string | number) =>
        API.get(endpoints.masterData.languageExamRequirements.main + '?trainingLevel=' + trainingLevel, {
            params: fixDateRangeFields(form),
        }),
};

export default languageExamRequirements;
