import Grid from '@mui/material/Grid';
import { AutoloadTable, Loading, NewButton } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import EndpointProvider from '../../../../components/EndpointProvider';
import courseBaseDataService from '../../../../services/courseManagement/courseBaseData';
import courseEventService from '../../../../services/courseManagement/courseEvent';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import AddEditEventButton from './AddEditEventButton';
import { tableColumns } from './fields';
import { OperationCellRenderer } from './OperationCellRenderer';
import { IEvent } from './types';
import { GridRenderCellParams } from '@mui/x-data-grid';
import AddFromEventButton from './AddFromEventButton';
import api from '../../../../api';
import { endpoints } from '../../../../utils/endPoints';

export default function Events(props: {
    closed: boolean;
    course: IForm;
    readOnly?: boolean;
    eventAssignable: boolean;
}) {
    const { courseId } = useParams() as { courseId: string };
    const id: number = parseInt(courseId);
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');
    const [tableLoading, setTableLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const { closed, readOnly } = props;
    const [course, setCourse] = useState(props.course);
    const [eventAccess, setEventAccess] = useState<boolean>(false);

    const Operations = useCallback(
        (params: GridRenderCellParams<IEvent, IEvent, IEvent>) => (
            <OperationCellRenderer
                {...params}
                setTableLoading={setTableLoading}
                id={id}
                readOnly={readOnly}
                scoringType={'' + course.scoringType || 'point'}
                courseTenant={courseTenant}
            />
        ),
        [course.scoringType, id, readOnly, courseTenant],
    );

    const filter = useCallback((form: IForm) => courseEventService.filter(id, courseTenant, form), [id, courseTenant]);

    useEffect(() => {
        setLoading(true);
        courseBaseDataService
            .get(id, courseTenant)
            .then((response) => {
                setCourse({
                    ...response.data.data,
                    campus: response.data.data.campus?.value || '',
                    building: response.data.data.building?.value || '',
                    floor: response.data.data.floor?.value || '',
                    room: response.data.data.room?.value || '',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, courseTenant]);

    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setEventAccess(response.data.eventAccess);
            })
            .catch((error) => {
                console.error('Error fetching tenant event access:', error);
            });
    }, []);

    if (loading) {
        return <Loading noHeight />;
    }

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
                {!closed && !readOnly && (
                    <EndpointProvider endpoints={['course_manamagement_course_event_edit']}>
                        <Grid item>
                            <AddEditEventButton
                                title="Esemény hozzáadása"
                                submitBtnTitle="Hozzáadás"
                                course={course}
                                setTableLoading={setTableLoading}
                                opener={<NewButton>Esemény hozzáadása</NewButton>}
                                courseTenant={courseTenant}
                            />
                        </Grid>
                        {props.eventAssignable && eventAccess && (
                            <Grid item>
                                <AddFromEventButton setTableLoading={setTableLoading} />
                            </Grid>
                        )}
                    </EndpointProvider>
                )}
            </Grid>
            <Grid item xs={12}>
                {tableLoading ? (
                    <Loading noHeight />
                ) : (
                    <AutoloadTable
                        columns={tableColumns(Operations)}
                        filter={filter}
                        responseKey="data"
                        refresh={tableLoading}
                        defaultSort={{ eventDate: 'asc' }}
                    />
                )}
            </Grid>
        </Grid>
    );
}
