import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableButton } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

export default function OperationsCellRenderer(params: GridRenderCellParams) {
    return (
        <Link to={clientEndPoints.md_general_students_edit_detailed.replace(':id', params.row.id)} target="_blank">
            <TableButton color={'info'} tooltip={`Hallgatói részletes oldal megnyitása`}>
                <VisibilityIcon />
            </TableButton>
        </Link>
    );
}
