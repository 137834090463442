import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FieldGenerator } from '@silinfo/front-end-template';
import { isKep } from '../../../../utils/AppConst';
import { ErrorText } from './ErrorText';
import { Field, FormField, UserData } from '../types';
import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';

export default function BasicDataFields({
    fields,
    setBasicData,
    basicData,
    formData,
    formProps,
    errors,
    matches,
    editMode,
}: {
    fields: Field[];
    setBasicData: Dispatch<SetStateAction<UserData>>;
    basicData: UserData;
    formData: FormField[];
    formProps: FormikProps<Record<string, unknown>>;
    errors: { [key: string]: string };
    matches: boolean;
    editMode: boolean;
}) {
    return (
        <>
            {fields.map((e, index) => {
                const label = formData.find((el) => el.name === e.type)?.label;
                const name = formData.find((el) => el.name === e.type)?.name;
                if (e.type === 'gender') {
                    return (
                        <Grid key={index} item container xs={matches ? 2 : 12}>
                            <Typography
                                sx={{
                                    ...(!matches && {
                                        fontSize: '14px',
                                    }),
                                }}
                            >
                                Nem
                            </Typography>
                            {FieldGenerator({
                                name: 'gender',
                                label: '',
                                type: 'select',
                                fieldType: 'base',
                                format: { xs: 12, md: 12, lg: 12 },
                                options: [
                                    {
                                        value: 'male',
                                        label: 'Férfi',
                                    },
                                    {
                                        value: 'female',
                                        label: 'Nő',
                                    },
                                    {
                                        value: 'unknown',
                                        label: 'Egyéb',
                                    },
                                ],
                                props: {
                                    disabled: !editMode,
                                    sx: {
                                        '& .MuiOutlinedInput-root fieldset ': {
                                            border: '1px solid #C1CBD7 !important',
                                            borderRadius: '0px',
                                        },
                                    },
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                        setBasicData((prevData) => ({
                                            ...prevData,
                                            gender: e.target.value,
                                        }));
                                    },
                                    value: basicData.gender,
                                },
                                ...formProps,
                            })}
                            <ErrorText message={errors[name as string]} matches={matches} />
                        </Grid>
                    );
                }
                if (e.type === 'phone') {
                    return (
                        <Grid key={index} item container xs={matches ? 4 : 12}>
                            <Typography
                                sx={{
                                    ...(!matches && {
                                        fontSize: '14px',
                                    }),
                                }}
                            >
                                Telefonszám
                            </Typography>
                            {FieldGenerator({
                                name: 'phone',
                                label: '',
                                type: 'phone',
                                fieldType: 'base',
                                format: { xs: 12, md: 12, lg: 12 },
                                props: {
                                    disabled: !editMode,
                                    sx: {},
                                    onChange: (value: string) => {
                                        setBasicData((prevData) => ({
                                            ...prevData,
                                            phone: value,
                                        }));
                                    },
                                    value: basicData.phone || '',
                                    inputProps: {
                                        style: {
                                            height: 40,
                                            width: '100%',
                                            border: '1px solid #C1CBD7 !important',
                                            borderRadius: '0px',
                                        },
                                    },
                                    className: editMode ? 'phone-input' : 'phone-input-disabled',
                                    country: isKep() ? 'ro' : 'hu',
                                },
                                ...formProps,
                            })}
                            <ErrorText message={errors[name as string]} matches={matches} />
                        </Grid>
                    );
                }
                return (
                    <Grid key={index} item container xs={matches ? 6 : 12}>
                        <Typography
                            sx={{
                                ...(!matches && {
                                    fontSize: '14px',
                                }),
                            }}
                        >
                            {label}
                        </Typography>
                        {FieldGenerator({
                            name: name || '',
                            label: '',
                            type: 'text',
                            fieldType: 'base',
                            format: { xs: 12, md: 12, lg: 12 },
                            props: {
                                disabled: !editMode || name === 'email',
                                sx: {
                                    '& .MuiOutlinedInput-root fieldset ': {
                                        border: '1px solid #C1CBD7 !important',
                                        borderRadius: '0px',
                                    },
                                },

                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    setBasicData((prevData) => ({
                                        ...prevData,
                                        [name as string]: e.target.value,
                                    }));
                                },
                                value: basicData[e.type],
                                inputProps: {
                                    maxLength: 128,
                                },
                            },
                            ...formProps,
                        })}
                        <ErrorText message={errors[name as string]} matches={matches} />
                    </Grid>
                );
            })}
        </>
    );
}
