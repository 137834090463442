import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import Tooltip from '@mui/material/Tooltip';
import { useFormikContext } from 'formik';
import { IAddress, IEventForm, IExternalAddress } from '../utils';

const tabsMap = {
    'Alapadatok': '1',
    'Ajándékok': '2',
    'Kommunikáció': '3',
    'Catering': '4',
    'IT igény': '5',
    'Terembeosztás': '6',
};

const renderTabs = () => Object.entries(tabsMap).map(([key, value]) => <Tab key={key} label={key} value={value} />);
const renderDisabledTabs = () =>
    Object.entries(tabsMap).map(([key, value], index) =>
        index === 0 ? (
            <Tab key={key} label={key} value={value} />
        ) : (
            <Tab
                disabled
                style={{ pointerEvents: 'auto' }}
                key={key}
                label={
                    <Tooltip title="A folyamati paraméterek szerkesztéséhez meg kell adni az esemény helyszínét!">
                        <span style={{ color: 'grey' }}>{key}</span>
                    </Tooltip>
                }
                value={value}
            />
        ),
    );

export default function TabsContainer({ setValue }: { setValue: React.Dispatch<React.SetStateAction<string>> }) {
    const {
        values: { addresses, external },
    } = useFormikContext<IEventForm>();

    const hasAddress = () => {
        if (!addresses || addresses.length === 0) {
            return false;
        }

        const allHaveRoom = addresses.every((e: IAddress | IExternalAddress) => {
            return 'room' in e && e.room;
        });

        if (allHaveRoom) {
            return true;
        } else {
            if (external) {
                const hasLocation = addresses.every((e: IAddress | IExternalAddress) => {
                    return 'location' in e && e.location;
                });
                return hasLocation;
            }
            return false;
        }
    };

    return (
        <TabList
            variant="scrollable"
            onChange={(_, val) => setValue(val)}
            sx={{
                '& .MuiButtonBase-root': {
                    backgroundColor: '#fff !important',
                    color: '#22344c !important',
                },
            }}
        >
            {hasAddress() ? renderTabs() : renderDisabledTabs()}
        </TabList>
    );
}
