import Filler from '../../pages/Admission/Admissions/Filler';
import QuestionnairePreview from '../../pages/Admission/Admissions/QuestionnairePreview';

export const admissionFormPage = {
    path: '/',
    children: [
        {
            path: 'felveteli/:tokenId/:serial/kerdoiv',
            element: <Filler />,
            name: 'admission_token_questionnaire',
        },
        {
            path: 'felveteli/mcc/:serial/kerdoiv',
            element: <Filler />,
            name: 'admission_mcc_questionnaire',
        },
        {
            path: 'felveteli/mcc/:serial/elonezet',
            element: <QuestionnairePreview />,
            name: 'admission_mcc_questionnaire',
        },
        {
            path: 'felveteli/:tokenId/:serial/elonezet',
            element: <QuestionnairePreview />,
            name: 'admission_mcc_questionnaire',
        },
    ],
};
