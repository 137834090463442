import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { IViolations, TextField, useFetch } from '@silinfo/front-end-template';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { formatNumberToThousands, initialInfo } from '../../../../../utils/AppConst';
import { endpoints } from '../../../../../utils/endPoints';
import { scholarshipOptions } from '../../../GroupManagement/utils';
import ExcelExport from './Excel';
import GridRenderCellParamsContext from './GridRenderCellParamsContextProvider';
import NoDataRow from './NoDataRow';
import RelevantEditableCell from './RelevantEditableCell';
import { IResponse, IScholarshipRow, ScholarshipTableProps } from './types';
import { columns, isRelevant, isResponse } from './utils';
import api from '../../../../../api';

export const ScholarshipTable = ({ editable, amountsState, errors, relevanceState }: ScholarshipTableProps) => {
    const [currency, setCurrency] = useState<string>('');

    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setCurrency(response.data.currencyTrans);
            })
            .catch((error) => {
                console.error('Error fetching current tenant currency:', error);
            });
    }, []);

    const [amounts, setAmounts] = amountsState;
    const [relevances, setRelevances] = relevanceState;
    const difference = amounts.reduce((acc, curr) => acc + curr.new - curr.old, 0);
    const params = useContext(GridRenderCellParamsContext);
    const [page, setPage] = useState(1);
    const { data, loading } = useFetch<IResponse>(
        endpoints.scholarships.admin.groupList('' + (params?.id || '')) + '?page=' + page,
        '',
    );
    const { data: sum, loading: sumLoading } = useFetch<{ sum: number }>(
        endpoints.scholarships.admin.sumValues('' + (params?.id || '')),
        '',
    );
    const rows: IScholarshipRow[] = useMemo(() => (isResponse(data) ? data.data : []), [data]);
    const { metadata } = isResponse(data) ? data : initialInfo();
    const isMonthly = params?.row.completionType === 'monthly';

    const type =
        scholarshipOptions.find((option) => option.value === params?.row.scholarshipType)?.label ||
        params?.row.scholarshipType ||
        'Ismeretlen';
    const sumText = sumLoading ? 'Betöltés...' : formatNumberToThousands(sum.sum + difference) + ' ' + currency;

    useEffect(() => {
        setAmounts((prev) => {
            const newRows = rows.filter((row) => !prev.find((elem) => elem.id === row.studentId));
            return [
                ...prev,
                ...newRows.map((row) => ({ id: row.studentId, old: row.amount || 0, new: row.amount || 0 })),
            ];
        });

        setRelevances((prev) => {
            const newRows = rows.filter((row) => !prev.find((elem) => elem.id === row.studentId));
            return [...prev, ...newRows.map((row) => ({ id: row.studentId, old: row.relevant, new: row.relevant }))];
        });
    }, [rows, setAmounts, setRelevances]);

    const handleChange = useCallback(
        (id: string) => (e: React.FocusEvent<HTMLInputElement>) => {
            if (isNaN(+e.target.value) || +e.target.value < 0) {
                e.target.value = '0';
            }

            setAmounts((prev) =>
                prev.map((elem) =>
                    elem.id === +id
                        ? {
                              id: elem.id,
                              old: elem.old,
                              new: +e.target.value,
                          }
                        : elem,
                ),
            );
        },
        [setAmounts],
    );

    const handleRelevanceChange = useCallback(
        (id: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setRelevances((prev) =>
                prev.map((elem) =>
                    elem.id === +id
                        ? {
                              id: elem.id,
                              old: elem.old || false,
                              new: e.target.value === 'true',
                          }
                        : elem,
                ),
            );
        },
        [setRelevances],
    );

    const EditableCell = useCallback(
        (row: IScholarshipRow) => {
            const errorIds = errors?.map((element: IViolations) => {
                return Number(element.propertyPath.split('.')[1]);
            });
            return (
                <TextField
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                    }}
                    size="small"
                    type="number"
                    defaultValue={amounts.find((elem) => elem.id === row.studentId)?.new || 0}
                    onBlur={handleChange('' + row.studentId)}
                    error={errorIds?.includes(row.studentId)}
                />
            );
        },
        [errors, currency, amounts, handleChange],
    );

    const TablePagination = useCallback(
        () => (
            <TableRow>
                <TableCell colSpan={5}>
                    <Pagination
                        sx={{
                            '& ul': {
                                justifyContent: 'flex-end',
                            },
                        }}
                        count={+(metadata.lastPage || 0)}
                        page={page}
                        onChange={(_, value) => setPage(value)}
                    />
                </TableCell>
            </TableRow>
        ),
        [metadata.lastPage, page],
    );

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TablePagination />
                    <TableRow sx={{ borderBottom: '2px solid #ddd' }}>
                        <TableCell colSpan={2}>
                            <Typography variant="h6" component="div">
                                {type} ösztöndíj összege
                            </Typography>
                        </TableCell>
                        <TableCell colSpan={1} align="center">
                            <Typography variant="h6" component="div">
                                {sumText}
                            </Typography>
                        </TableCell>
                        <ExcelExport />
                    </TableRow>

                    <TableRow>
                        {columns.slice(0, columns.length - (isMonthly ? 0 : 1)).map((column) => (
                            <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length < 1 || loading ? (
                        <NoDataRow loading={loading} />
                    ) : (
                        rows.map((row) => (
                            <TableRow key={row.studentId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {row.student}
                                </TableCell>
                                <TableCell>{row.campus}</TableCell>
                                <TableCell>{row.trainingProgram}</TableCell>
                                <TableCell>
                                    {editable ? (
                                        <EditableCell {...row} />
                                    ) : (
                                        (row.amount || 0).toLocaleString() + ' ' + currency
                                    )}
                                </TableCell>
                                {isMonthly ? (
                                    <TableCell>
                                        {editable ? (
                                            <RelevantEditableCell
                                                errors={errors}
                                                relevances={relevances}
                                                handleChange={handleRelevanceChange}
                                                studentId={row.studentId}
                                            />
                                        ) : (
                                            isRelevant(row.relevant)
                                        )}
                                    </TableCell>
                                ) : null}
                            </TableRow>
                        ))
                    )}
                    <TablePagination />
                </TableBody>
            </Table>
        </TableContainer>
    );
};
