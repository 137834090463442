import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useCallback } from 'react';
import Form from '../../../components/Form/Form';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { FilterProps, IFilterOptions } from '../types';
import { filterFields, initialFilter } from '../utils';

const FilterForm = ({
    info,
    setInfo,
    options,
    handleClose,
}: FilterProps & { options: IFilterOptions; handleClose: () => void }) => {
    const handleSubmit = useCallback(
        (form: IForm) => {
            setInfo((prev) => ({ ...prev, filter: form }));
            handleClose();
        },
        [setInfo, handleClose],
    );

    const handleDefaultClick = useCallback(() => {
        setInfo((prev) => ({ ...prev, filter: initialFilter }));
        handleClose();
    }, [setInfo, handleClose]);

    return (
        <Form fields={filterFields(options)} onSubmit={handleSubmit} hideButtons initialValues={info.filter}>
            {() => (
                <Grid item container xs={12} spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={handleDefaultClick}>
                            Alapértelmezett
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                            Szűrés
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Form>
    );
};

export default FilterForm;
