import Grid from '@mui/material/Grid';
import { Field, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import Autocomplete from '../../../components/Form/Autocomplete';
import { FieldGenerator } from '../../../components/Form/FieldGenerator';
import administrationFeesService from '../../../services/masterData/finances/administrationFees/administrationFees';
import administrationFeeValuesService from '../../../services/masterData/finances/administrationFees/administrationFeeValues/administrationFeeValues';
import { Option } from '../../../utils/AppConst';
import { IAdministrationForm } from './Form';

interface IAdministrationFeeVal {
    id: string | number;
}

export default function AdministrationFormFields({ ...props }: FormikProps<IAdministrationForm>) {
    const [semesterOptions, setSemesterOptions] = useState<Option[]>([]);
    const [administrationFeeOptions, setAdministrationFeeOptions] = useState<Option[]>([]);
    const [feeVal, setFeeVal] = useState<IAdministrationFeeVal>({ id: '' });
    const { values, setFieldValue } = props;

    useEffect(() => {
        administrationFeesService.listForMultiselect().then((response) => {
            setAdministrationFeeOptions(response.data);
        });
    }, []);

    useEffect(() => {
        if (values.administrationFee) {
            administrationFeesService.semestersWithGivenAdministrationFee(values.administrationFee).then((response) => {
                setSemesterOptions(response.data);
            });
        }
    }, [values.administrationFee]);

    useEffect(() => {
        if (values.semester) {
            administrationFeeValuesService
                .administrationFeeValBySemesterAndAdministrationFee(values.administrationFee, values.semester)
                .then((response) => {
                    if (response && response.data) {
                        setFeeVal(response.data);
                        setFieldValue('administrationFeeVal', String(response.data.id));
                        setFieldValue('amount', String(response.data.amount));
                    } else {
                        setFieldValue('amount', '');
                        setFeeVal({ id: '' });
                        setFieldValue('students', []);
                    }
                });
        }
    }, [setFieldValue, values.administrationFee, values.semester]);
    return (
        <>
            <>
                {FieldGenerator({
                    name: 'administrationFee',
                    label: 'Adminisztrációs díj',
                    type: 'select',
                    fieldType: 'base',
                    options: administrationFeeOptions,
                    format: { xs: 12 },
                    ...props,
                })}

                {FieldGenerator({
                    name: 'semester',
                    label: 'Szemeszter',
                    type: 'select',
                    options: semesterOptions,
                    props: {
                        disabled: semesterOptions.length < 1,
                        helperText:
                            semesterOptions.length < 1 && values.administrationFee != ''
                                ? 'A kiválasztott adminisztrációs díjhoz nem tartozik érték!'
                                : '',
                    },
                    fieldType: 'base',
                    format: { xs: 12 },
                    ...props,
                })}

                {props.values.semester &&
                    semesterOptions.length > 0 &&
                    FieldGenerator({
                        name: 'amount',
                        label: 'Összeg',
                        format: { xs: 12 },
                        props: {
                            disabled: true,
                        },
                        ...props,
                    })}
                {feeVal.id && (
                    <Grid item xs={12} sm={12}>
                        <Field as="div" name="students">
                            <Autocomplete
                                getter={(term: string) =>
                                    administrationFeeValuesService.listPossibleStudentsForAdministrationFeeValue(
                                        feeVal.id,
                                        term,
                                    )
                                }
                                textFieldProps={{ label: 'Hallgatók' }}
                                errorMessage={props.errors.students as string}
                                autocompleteProps={{
                                    value: props.values.students || [],
                                    onChange: (_, v) => {
                                        props.setFieldValue('students', v);
                                    },
                                    multiple: true,
                                }}
                                createOptions={(students: { value: number; label: string }[]) =>
                                    students.map((student) => ({
                                        value: student.value.toString(),
                                        label: student.label,
                                    }))
                                }
                            />
                        </Field>
                    </Grid>
                )}
            </>
        </>
    );
}
