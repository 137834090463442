import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import API from '../../../api';
import { Document, SelectedRow } from './types';
import { endpoints } from '../../../utils/endPoints';
import { create } from '../../../store/notification';
import TextField from '@mui/material/TextField';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { downloadFile } from '../Administration/utils';
import axios from 'axios';

const FileInput = ({
    selectedRow,
    isRegistrated,
    document,
    setUploadableDocuments,
    reload,
}: {
    selectedRow: SelectedRow;
    isRegistrated: boolean;
    document: Document;
    setUploadableDocuments: Dispatch<SetStateAction<Document[]>>;
    reload: () => void;
}) => {
    const dispatch = useDispatch();
    const fileInput = useRef<HTMLInputElement | null>(null);
    const handleDownload = () => {
        const endpoint = isRegistrated
            ? endpoints.admission.downloadDocumentMcc(selectedRow.serial, document.id)
            : endpoints.admission.downloadDocument(selectedRow.token, selectedRow.serial, document.id);

        const request = isRegistrated
            ? API.get(endpoint, {
                  responseType: 'blob',
              })
            : axios.get(endpoint, {
                  responseType: 'blob',
              });
        request.then((response) => downloadFile(response.data, document.fileName));
    };

    const handleUpload = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const files = (e.target as HTMLInputElement).files;
        if (files) {
            const formData = new FormData();
            formData.append('file', files[0]);
            formData.append('type', 'admission_applicant_program');
            formData.append('fileName', files[0].name);
            const endpoint = isRegistrated
                ? endpoints.admission.uploadDocumentMcc(selectedRow.serial, document.id)
                : endpoints.admission.uploadDocument(selectedRow.token, selectedRow.serial, document.id);

            const request = isRegistrated
                ? API.post(endpoint, formData, {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                      },
                  })
                : axios.post(endpoint, formData, {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                      },
                  });
            request
                .then(() => {
                    dispatch(create({ type: 'success', message: 'Sikeres feltöltés' }));
                    const endpoint = isRegistrated
                        ? endpoints.admission.getDocumentsMcc(selectedRow.serial)
                        : endpoints.admission.getDocuments(selectedRow.token, selectedRow.serial?.toString());

                    const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
                    request.then((response) => setUploadableDocuments(response.data.uploadableDocuments));
                })
                .catch(() => {
                    dispatch(create({ type: 'error', message: 'Sikertelen felöltés' }));
                })
                .finally(() => reload());
        }
    };

    return (
        <Grid container item sx={{ alignItems: 'center', marginBottom: '0.5rem' }}>
            <TextField
                inputRef={fileInput}
                type="file"
                sx={{
                    display: 'none',
                }}
                onChange={handleUpload}
            />
            {document.filePath ? (
                <CheckBoxIcon sx={{ color: '#61953f' }} />
            ) : (
                <IndeterminateCheckBoxIcon sx={{ color: '#c66728' }} />
            )}
            <TextField
                size="small"
                placeholder={document.name}
                value={document.filePath ? document.name : ''}
                onClick={(e) => {
                    if (new Date(document.deadline) > new Date() && fileInput.current) {
                        fileInput.current.click();
                        e.stopPropagation();
                    }
                }}
                disabled={new Date(document.deadline) < new Date()}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <FileUploadIcon
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={(e) => {
                                if (new Date(document.deadline) > new Date() && fileInput.current) {
                                    fileInput.current.click();
                                    e.stopPropagation();
                                }
                            }}
                        />
                    ),
                }}
                sx={{
                    marginRight: '0.5rem',
                    marginLeft: '1rem',
                    boxShadow: '0px 1px 6px -3px rgba(0,0,0,0.4)',
                }}
            />
            {document.filePath && (
                <DownloadIcon
                    onClick={handleDownload}
                    sx={{
                        cursor: 'pointer',
                        marginRight: '1rem',
                    }}
                />
            )}
            <Typography sx={{ color: '#414141' }}>Feltöltés határideje: {document.deadline}</Typography>
        </Grid>
    );
};

export default function UploadDocuments({
    selectedRow,
    isRegistrated,
    matches,
    admissionReload,
}: {
    selectedRow: SelectedRow;
    isRegistrated: boolean;
    matches: boolean;
    admissionReload: () => void;
}) {
    const [uploadableDocuments, setUploadableDocuments] = useState<Document[]>([]);
    const [informationDocuments, setInformationDocuments] = useState<Document[]>([]);

    const handleDownload = (document: Document) => {
        const endpoint = isRegistrated
            ? endpoints.admission.downloadDocumentMcc(selectedRow.serial, document.id)
            : endpoints.admission.downloadDocument(selectedRow.token, selectedRow.serial, document.id);
        const request = isRegistrated
            ? API.get(endpoint, {
                  responseType: 'blob',
              })
            : axios.get(endpoint, {
                  responseType: 'blob',
              });
        request.then((response) => downloadFile(response.data, document.fileName));
    };

    useEffect(() => {
        (() => {
            const endpoint = isRegistrated
                ? endpoints.admission.getDocumentsMcc(selectedRow.serial)
                : endpoints.admission.getDocuments(selectedRow.token, selectedRow.serial?.toString());
            const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
            request.then((response) => {
                if (response.data.uploadableDocuments) {
                    setUploadableDocuments(response.data.uploadableDocuments);
                }
                if (response.data.informationDocuments) {
                    setInformationDocuments(response.data.informationDocuments);
                }
            });
        })();
    }, [selectedRow, isRegistrated]);

    return (
        <Grid container style={{ paddingTop: '0.5rem', marginBottom: '0.5rem' }}>
            <Grid xs={matches ? 6 : 12} container item>
                <Grid container item>
                    <PostAddIcon sx={{ color: '#f3ca12', marginRight: '0.5rem' }} />
                    <Typography sx={{ fontWeight: 700 }}>Feltöltendő dokumentumok</Typography>
                </Grid>
                <Grid container item sx={{ flexDirection: 'column', marginTop: '0.5rem' }}>
                    {uploadableDocuments.map((document, index) => (
                        <FileInput
                            key={index}
                            isRegistrated={isRegistrated}
                            document={document}
                            selectedRow={selectedRow}
                            setUploadableDocuments={setUploadableDocuments}
                            reload={admissionReload}
                        />
                    ))}
                </Grid>
            </Grid>
            <Grid xs={matches ? 6 : 12} container item>
                <Grid container item sx={{ flexDirection: 'column' }}>
                    <Grid
                        container
                        item
                        sx={{
                            marginBottom: '1rem',
                        }}
                    >
                        <ErrorOutlineIcon sx={{ color: '#f3ca12', marginRight: '0.5rem' }} />
                        <Typography sx={{ fontWeight: 700 }}>Tájékoztatók</Typography>
                    </Grid>
                    {informationDocuments.map((document, index) => {
                        return (
                            <Grid
                                key={index}
                                container
                                item
                                sx={{ justifyContent: 'space-between', paddingLeft: '2rem' }}
                            >
                                <Typography sx={{ color: '#414141' }}>{document.name}</Typography>
                                <div>
                                    <DownloadIcon onClick={() => handleDownload(document)} sx={{ cursor: 'pointer' }} />
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}
