import TuneIcon from '@mui/icons-material/Tune';
import CalculateIcon from '@mui/icons-material/Calculate';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const options = [
    {
        Icon: TuneIcon,
        label: 'Paraméterek beállítása',
    },
    {
        Icon: CalculateIcon,
        label: 'Ösztöndíjak újraszámítása',
    },
];

//Ideiglenes megoldás, míg nincs meg a specifikáció
export default function UnavailableOptions({ disabled }: { disabled?: boolean }) {
    return (
        <>
            {options.map(
                (
                    { Icon, label }, // FIXME: itt a || true-t majd ki kell szedni, de egyelőre fejlesztés alatt van a funkció
                ) => (
                    <MenuItem key={label} disabled={disabled || true}>
                        <ListItemIcon>
                            <Icon color="disabled" />
                        </ListItemIcon>
                        <ListItemText>{label} - fejlesztés alatt</ListItemText>
                    </MenuItem>
                ),
            )}
        </>
    );
}
