import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Dispatch, SetStateAction } from 'react';
import { endpoints } from '../../../utils/endPoints';
import ElementOperationsCellRenderer from './ElementOperationsCellRenderer';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import { IForm, TrainingProgramOption } from '../../../utils/Interfaces/interfaces';

export const breadcrumbs = {
    admission: 'Felvételi',
    administration: 'Adminisztráció',
};

export const header = (tenant: string | null) => {
    return {
        title: 'Felvételi',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

export const columns = (
    setRefresh: Dispatch<SetStateAction<boolean>>,
    groupId: string,
    groupName: string,
    readonly: boolean,
): GridColDef[] => {
    const cols: GridColDef[] = [
        {
            field: 'campus',
            headerName: 'Campus',
            sortable: true,
        },
        {
            field: 'trainingLevel',
            headerName: 'Képzési szint',
            sortable: true,
        },
        {
            field: 'trainingProgram',
            headerName: 'Képzési program',
            sortable: true,
        },
    ];

    if (!readonly) {
        cols.push({
            field: 'operations',
            headerName: 'Műveletek',
            renderCell: (params: GridRenderCellParams) => (
                <ElementOperationsCellRenderer
                    setRefresh={setRefresh}
                    groupId={groupId}
                    groupName={groupName}
                    {...params}
                />
            ),
            align: 'center',
            sortable: false,
            minWidth: 100,
        });
    }

    return cols.map((column) => ({
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        sortable: false,
        ...column,
    }));
};

export const filterFields = (): Input[] => {
    return [
        {
            name: 'campus',
            label: 'Campus',
            type: 'backendSelect',
            url: endpoints.admission.admissionGroupElement.campusOptions,
            justValue: true,
            multiple: true,
        },
        {
            name: 'trainingLevel',
            label: 'Képzési szint',
            type: 'backendSelect',
            url: endpoints.admission.admissionGroupElement.trainingLevelOptions,
            justValue: true,
            multiple: true,
        },
        {
            name: 'trainingProgram',
            label: 'Képzési program',
            type: 'backendSelect',
            url: endpoints.admission.admissionGroupElement.trainingProgramOptions,
            justValue: true,
            multiple: true,
            props: {
                groupBy: (option: TrainingProgramOption) => option.trainingLevel,
            },
        },
    ];
};

export interface IGroupElement extends IForm {
    id: number;
    cammpus: string;
    trainingLevel: string;
    trainingProgram: string;
}

export const initialValues: Record<string, unknown> = {
    id: 0,
    campus: '',
    trainingLevel: '',
    trainingProgram: '',
};

export const formFields: Input[] = [
    {
        name: 'campus',
        label: 'Campus',
        type: 'backendSelect',
        url: endpoints.admission.admissionGroupElement.campusOptions,
        justValue: true,
        format: {
            xs: 12,
        },
    },
    {
        name: 'trainingLevel',
        label: 'Képzési szint',
        type: 'backendSelect',
        url: endpoints.admission.admissionGroupElement.trainingLevelOptions,
        justValue: true,
        format: {
            xs: 12,
        },
    },
    {
        name: 'trainingProgram',
        label: 'Képzési program',
        type: 'backendSelect',
        url: endpoints.admission.admissionGroupElement.trainingProgramOptions,
        justValue: true,
        format: {
            xs: 12,
        },
    },
];

const autoload = true as const;

export const tableProps = (
    refresh: boolean,
    setRefresh: Dispatch<SetStateAction<boolean>>,
    groupId: string,
    groupName: string,
    handleModalOpen: () => void,
    readonly: boolean,
    tenant?: string,
) => ({
    autoload,
    header: header(tenant || 'TAR'),
    columns: columns(setRefresh, groupId, groupName, readonly),
    filter: { fields: filterFields() },
    refresh: refresh,
    defaultSort: { trainingProgram: 'asc' as const },
    responseKey: 'data',
    customButtons: !readonly ? (
        <Grid item xs={12} textAlign="right">
            <Button onClick={handleModalOpen} variant="outlined" color="success" startIcon={<AddIcon />}>
                Hozzáadás
            </Button>
        </Grid>
    ) : (
        <></>
    ),
});
