import IEntityBase from '../../../../../interface/IEntityBase';
import { Option } from '../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { IInfo } from '../../../../../utils/Interfaces/IInfo';
import { IForm } from '../../../../../utils/Interfaces/interfaces';

export const YEAR_OPTIONS = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
];

export const makeOptionsFromHydra = (data: IEntityBase[], url: string): Option[] => {
    return data.map((elem: { id: number | string; name: string }) => {
        return {
            value: `/${url}/${elem.id}`,
            label: elem.name,
        };
    });
};

export const makeOptionsStartFromHydra = (data: [], url: string): string[] => {
    return data.map((elem: { id: string }) => {
        return `/${url}/${elem.id}`;
    });
};

export const initialInfo: IInfo<IForm> = {
    filter: { archive: ['0'] },
    sort: {
        name: 'asc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

export const header = (tenant: string | null) => {
    return {
        project: tenant || 'TAR',
        title: 'Képzési programok',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: 'Képzési szintek',
            trainingPrograms: 'Képzési programok',
        },
    };
};
