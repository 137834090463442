import TabContext from '@mui/lab/TabContext';
import Grid from '@mui/material/Grid';
import { Page } from '@silinfo/front-end-template';
import { Children, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MCCLoading from '../../components/MCCLoading';
import { RootState } from '../../store';
import { RawIStudentProfile, setProfile } from '../../store/studentProfile';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';
import ProfileHeader from './ProfileHeader';
import TabContent from './TabContent';
import useTabs from './Tabs';
import { useTenant } from '../../components/TenantContext';

export default function Profile({ tabValue }: { tabValue: number }) {
    const { refresh } = useSelector((state: RootState) => state.studentProfile);
    const [value, setValue] = useState(tabValue);
    const { loading, data, error } = useFetch<RawIStudentProfile>(endpoints.students.profile.main, 'data', refresh);
    const dispatch = useDispatch();
    const tabs = useTabs();
    const tenant = useTenant();

    const header = {
        project: tenant || 'TAR',
        title: 'Profil',
        breadcrumbs: {
            profile: 'Profil',
        },
    };

    useEffect(() => {
        if (!loading && !error) {
            const modifiedForeignScholarship =
                data?.foreignScholarship?.map((element) => ({
                    ...element,
                    stayAbroadType: element.stayAbroadType.split(', '),
                })) ?? [];
            dispatch(setProfile({ ...data, foreignScholarship: modifiedForeignScholarship }));
        }
    }, [data, dispatch, error, loading]);

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Grid item container spacing={3}>
                <TabContext value={'' + value}>
                    <ProfileHeader value={value} setValue={setValue} />
                    {Children.toArray(tabs.map((tab) => <TabContent key={tab.value} {...tab} />))}
                </TabContext>
            </Grid>
        </Page>
    );
}
