import { Address } from '../types';

export interface ProcessDialogProps {
    id: number;
    open: boolean;
    handleClose: () => void;
    isMoveOut?: boolean;
}

export interface IEnrollmentDocument {
    id: number;
    name: string;
    required: string;
}

export interface ActualUniversityStudy {
    id: string;
    university: {
        id: number;
        name: string;
        externalId: number;
    };
    enrollmentActualUniversityStudyId: number;
    neptun: string;
    pedigreeNumber: string;
    semesterStatus: string;
    trainingForm: string;
    financeForm: string;
    major: string;
    foreignUniversity: string;
    fileName: string;
    filePath: string;
}

export interface IEnrollment {
    lastName: string;
    firstName: string;
    trainingLevel: string;
    trainingProgram: string;
    collegeYear: string;
    membershipStatus: string;
    currentMembershipStatus: string;
    semesterStatus: string;
    neptun: string;
    omId: string;
    taxId: string;
    identityNumber: string;
    campus: string;
    building?: string;
    floor?: string;
    room?: string;
    address: Address;
    motherLastName: string;
    motherFirstName: string;
    birthPlace: string;
    birthDate: string;
    nationality: string;
    identificationNumberCnp: string;
    idCardNumber: string;
    kepIdCardNumber: string;
    bankName: string;
    bankAccountNumber: string;
    phoneNumber: string;
    email: string;
    actualUniversityStudies: ActualUniversityStudy[];
    scholarshipStatus: string;
    universityAdmissionDecisionFileName: string;
    acceptedDocuments: IEnrollmentDocument[];
    consentedDocuments: IEnrollmentDocument[];
    primaryContactPersonFirstName?: string;
    primaryContactPersonLastName?: string;
    primaryContactPersonEmail?: string;
    primaryContactPersonPhone?: string;
    secondaryContactPersonFirstName?: string;
    secondaryContactPersonLastName?: string;
    secondaryContactPersonEmail?: string;
    secondaryContactPersonPhone?: string;
    hasModification?: boolean;
}

export const initialEnrollment: IEnrollment = {
    lastName: '',
    firstName: '',
    trainingLevel: '',
    trainingProgram: '',
    collegeYear: '',
    membershipStatus: '',
    currentMembershipStatus: '',
    semesterStatus: '',
    neptun: '',
    omId: '',
    taxId: '',
    identityNumber: '',
    campus: '',
    building: '',
    floor: '',
    room: '',
    address: {
        postCode: '',
        city: '',
        streetType: '',
        streetName: '',
        houseNumber: '',
        floor: '',
        door: '',
    },
    motherLastName: '',
    motherFirstName: '',
    birthPlace: '',
    birthDate: '',
    nationality: '',
    identificationNumberCnp: '',
    idCardNumber: '',
    kepIdCardNumber: '',
    bankName: '',
    bankAccountNumber: '',
    phoneNumber: '',
    email: '',
    actualUniversityStudies: [],
    scholarshipStatus: '',
    universityAdmissionDecisionFileName: '',
    acceptedDocuments: [],
    consentedDocuments: [],
    primaryContactPersonFirstName: '',
    primaryContactPersonLastName: '',
    primaryContactPersonEmail: '',
    primaryContactPersonPhone: '',
    secondaryContactPersonFirstName: '',
    secondaryContactPersonLastName: '',
    secondaryContactPersonEmail: '',
    secondaryContactPersonPhone: '',
};

export interface IStudentAllocationInfo {
    type: string;
    trainingLevel: string;
    trainingProgram: string;
    collegeYear: string;
    currentMembershipStatus: string;
    room: string;
    floor: string;
    building: string;
    campus: string;
    semester: string;
}

export interface IRelocation {
    '@type': string;
    lastName: string;
    firstName: string;
    address: Address;
    email: string;
    phoneNumber: string;
    oldStudentAllocation: IStudentAllocationInfo;
    newStudentAllocation: IStudentAllocationInfo;
    moveOutDate: string;
    financialComment: string;
    operatorComment: string;
}

export const initialRelocation: IRelocation = {
    '@type': '',
    lastName: '',
    firstName: '',
    address: {
        postCode: '',
        city: '',
        streetType: '',
        streetName: '',
        houseNumber: '',
        floor: '',
        door: '',
    },
    email: '',
    phoneNumber: '',
    oldStudentAllocation: {
        type: '',
        trainingLevel: '',
        trainingProgram: '',
        collegeYear: '',
        currentMembershipStatus: '',
        room: '',
        building: '',
        floor: '',
        campus: '',
        semester: '',
    },
    newStudentAllocation: {
        type: '',
        trainingLevel: '',
        trainingProgram: '',
        collegeYear: '',
        currentMembershipStatus: '',
        room: '',
        building: '',
        floor: '',
        campus: '',
        semester: '',
    },
    moveOutDate: '',
    financialComment: '',
    operatorComment: '',
};

export interface IStudentAllocationPreference {
    firstName: string;
    lastName: string;
    room: string;
    floor: string;
    building: string;
    campus: string;
    roomMates?: string; // TODO: ez egy szobatársra van kezelve
    comment?: string;
}

export const initialStudentAllocationPreference: IStudentAllocationPreference = {
    firstName: '',
    lastName: '',
    room: '',
    floor: '',
    building: '',
    campus: '',
    roomMates: '',
    comment: '',
};

export interface ITermination {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    trainingLevel: string;
    trainingProgram: string;
    collegeYear: string;
    membershipStatus: string;
    currentMembershipStatus: string;
    semesterStatus: string;
    campus: string;
    building?: string;
    floor?: string;
    room?: string;
    address: Address;
    isMoveOut: boolean;
}

export const initialTermination: ITermination = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    trainingLevel: '',
    trainingProgram: '',
    collegeYear: '',
    membershipStatus: '',
    currentMembershipStatus: '',
    semesterStatus: '',
    campus: '',
    building: '',
    floor: '',
    room: '',
    address: {
        postCode: '',
        city: '',
        streetType: '',
        streetName: '',
        houseNumber: '',
        floor: '',
        door: '',
    },
    isMoveOut: false,
};

export interface IEntity {
    id: number;
    name: string;
}

export interface IBuilding extends IEntity {
    campus: IEntity;
}
