import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const EventLabel = () => (
    <Grid item xs={12} md={6} alignSelf="center">
        <Typography variant="h4">
            <strong>Események a héten</strong>
        </Typography>
    </Grid>
);

export default EventLabel;
