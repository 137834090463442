import { Input } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { QUESTIONNAIRE_TEMPLATE_TYPE_OPTIONS } from '../useTemplateFields';
import { ITemplatesForm } from './types';

const header = (title: string, tenant: string | null) => ({
    title,
    project: tenant || 'TAR',
    breadcrumbs: {
        selfEvaluation: 'Adminisztráció',
        [clientEndPoints.admin_questionnaire_templates + '?tab=0']: 'Kérdőívsablonok',
        form: title,
    },
});

const initialValue: ITemplatesForm = {
    name: '',
    notificationSuccess: '',
    type: '',
    notificationDone: '',
    notificationOutOfDate: '',
    content: { pages: {} },
    relatedCourseType: '',
    anonymous: true,
};

const fields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12 },
        props: {},
    },
    {
        name: 'type',
        label: 'Kérdőív típusa',
        format: { xs: 12 },
        type: 'select',
        options: QUESTIONNAIRE_TEMPLATE_TYPE_OPTIONS,
        props: {},
    },
];

export { header, initialValue, fields };
