import { ITranslation } from '../../Administration/Pages/types';

export interface AddressFieldSizes {
    postCode: number;
    city: number;
    streetName: number;
    streetType: number;
    houseNumber: number;
    addressFloor: number;
    door: number;
    [key: string]: number;
}

export interface Admission {
    campus: string | null;
    campusId: number | null;
    trainingProgram: string | null;
    trainingProgramId: number | null;
    serial: number | null;
    questionnaireFilledDate: string | null;
    applicationDeadline: string | null;
    editable: boolean;
    deletable: boolean;
    canFinalize: boolean;
    isFinalized: boolean;
    status: string | null;
    finalizedPrivacyAcceptedVersion: number | null;
}

export interface Field {
    id: number;
    type: FieldName;
    optional: boolean;
    value: string | null;
}

export interface SelectedRow {
    serial: number | null;
    token: string;
}

export interface Document {
    deadline: string;
    fileName: string;
    filePath: string;
    id: number;
    name: string;
    rule: string;
}

export interface IPageVersion {
    page: IPage;
    translations: ITranslation;
}

export interface IPage {
    translations: ITranslation;
}

export enum FieldName {
    Email = 'email',
    LastName = 'lastName',
    FirstName = 'firstName',
    ForeignStudent = 'foreignStudent',
    IdCardNumber = 'idCardNumber',
    IdentificationNumberCnp = 'identificationNumberCnp',
    KepIdCardNumber = 'kepIdCardNumber',
    BirthPlace = 'birthPlace',
    BirthDate = 'birthDate',
    Nationality = 'nationality',
    SocialSecurityNumber = 'socialSecurityNumber',
    OmId = 'omId',
    MotherFirstName = 'motherFirstName',
    MotherLastName = 'motherLastName',
    Gender = 'gender',
    Phone = 'phone',
    TaxId = 'taxId',
    BankName = 'bankName',
    BankAccountNumber = 'bankAccountNumber',
    PostCode = 'postCode',
    City = 'city',
    StreetName = 'streetName',
    StreetType = 'streetType',
    HouseNumber = 'houseNumber',
    Floor = 'addressFloor',
    Door = 'door',
}

export interface FormField {
    name: FieldName;
    label: string;
    optional: boolean;
    maxLength?: number;
}

export interface UserData {
    email: string | null;
    lastName: string | null;
    firstName: string | null;
    foreignStudent: boolean | null;
    idCardNumber: string | null;
    identificationNumberCnp: string | null;
    kepIdCardNumber: string | null;
    birthPlace: string | null;
    birthDate: string | null;
    nationality: string[] | null;
    socialSecurityNumber: string | null;
    omId: string | null;
    motherLastName: string | null;
    motherFirstName: string | null;
    gender: string | null;
    phone: string | null;
    taxId: string | null;
    bankName: string | null;
    bankAccountNumber: string | null;
    postCode: string | null;
    city: string | null;
    streetName: string | null;
    streetType: string | null;
    houseNumber: string | null;
    addressFloor: string | null;
    door: string | null;
}

export interface Option<T = string> {
    label: string;
    value: T;
}

export interface DocumentsDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    serialState: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
    disabledState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    isRegistrated: boolean;
    token: string;
    refresh: () => void;
    admission?: Admission;
}

export interface ConfirmDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    loadingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    handleSave: () => void;
}

export interface FinalizeDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    serialState: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
    disabledState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    token: string;
    admission: Admission | null;
    refresh: () => void;
}

export interface VersionInfo {
    versionId: number | null;
    versionText: string | null;
    acceptProgram: string | null;
}

export interface MobileDocumentDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    serialState: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
    disabledState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    isRegistrated: boolean;
    token: string;
    refresh: () => void;
}

export interface MobileProgramSelectDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    serialState: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
    tempSerialState: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
    loadingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    token: string;
    handleSaveBody: (
        serial: number | null,
        campusId: string | number,
        programId: string | number,
        isNewAdmission: boolean,
    ) => void;
    refresh: () => void;
    checkIsProgramAlreadyAdded: (campus: number, program: number) => boolean;
}

export interface ProgramSelectDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    serialState: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
    tempSerialState: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
    loadingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    token: string;
    handleSaveBody: (
        serial: number | null,
        campusId: string | number,
        programId: string | number,
        isNewAdmission: boolean,
    ) => void;
    refresh: () => void;
    checkIsProgramAlreadyAdded: (campus: number, program: number) => boolean;
}

export interface UserProfileActionsProps {
    handleGuide: () => void;
    openModal: boolean;
    handleCloseModal: () => void;
    logout: () => void;
}
