import { FormikProps } from 'formik';
import { FieldGenerator } from '../../../../../components/Form/FieldGenerator';
import { Option } from '../../../../../utils/AppConst';
import { ISemesterFee } from './Form';

export default function EditFields(props: FormikProps<ISemesterFee>, semesterOptions: Option[]) {
    return (
        <>
            {FieldGenerator({
                name: 'semester',
                label: 'Szemeszter',
                type: 'select',
                fieldType: 'base',
                options: semesterOptions,
                props: {
                    disabled: true,
                },
                format: { md: 6, xs: 12 },
                ...props,
            })}
            {FieldGenerator({
                name: 'amount',
                label: 'Összeg',
                props: {
                    inputProps: { maxLength: 8 },
                },
                format: { md: 6, xs: 12 },
                ...props,
            })}
        </>
    );
}
