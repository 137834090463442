import { FormikListPage, Loading, useFetch } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RefreshContextProvider } from '../../../../studentPages/SelfAssessment/RefreshContextProvider';
import { Option } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import AddMember from './AddMember';
import { filterFields } from './fields';
import { columns, header, service } from './utils';
import { useTenant } from '../../../../components/TenantContext';

export default function Elements({ view }: { view?: boolean }) {
    const [refresh, setRefresh] = useState(false);
    const { id } = useParams();
    const { data: campusOptions } = useFetch<Option[]>(endpoints.scholarships.campus(id || ''), '');
    const { data: trainingLevelOptions } = useFetch<Option[]>(endpoints.scholarships.trainingLevel(id || ''), '');
    const { data: trainingProgramOptions } = useFetch<Option[]>(endpoints.scholarships.trainingProgram(id || ''), '');
    const toggleRefresh = useCallback(() => setRefresh((prev) => !prev), []);
    const tenant = useTenant();
    const options = {
        campus: campusOptions,
        trainingLevel: trainingLevelOptions,
        trainingProgram: trainingProgramOptions,
    };

    const loading = Object.values(options).some((option) => option.length === 0);

    if (loading) return <Loading noHeight />;

    return (
        <RefreshContextProvider refresh={toggleRefresh}>
            <FormikListPage
                header={{ ...header(true, tenant || 'TAR'), breadcrumbs: {} }}
                autoload
                service={service(id || '')}
                columns={columns(view)}
                filter={{ fields: filterFields(options) }}
                responseKey="data"
                refresh={refresh}
                customButtons={<AddMember view={view} />}
            />
        </RefreshContextProvider>
    );
}
