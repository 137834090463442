import { default as API } from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
import { endpoints } from '../../utils/endPoints';

const questionnaireGroupService = {
    filter: (form: IForm) => API.get(endpoints.selfEvaluation.questionnaireGroup.list, { params: form }),
    search: (term: string) => API.get(endpoints.selfEvaluation.questionnaireGroup.search + '?term=' + term),
    update: (id: string, form: IForm) =>
        API.post(endpoints.selfEvaluation.questionnaireGroup.update.replace('{id}', id), form),
    create: (form: IForm) => API.post(endpoints.selfEvaluation.questionnaireGroup.create, form),
    get: (id: string) => API.get(endpoints.selfEvaluation.questionnaireGroup.show.replace('{id}', id)),
};

export default questionnaireGroupService;
