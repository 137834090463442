import { Outlet } from 'react-router-dom';
import OffLayout from '../../components/OffLayout';
import ExpiredTokenComponent from '../../pages/Admission/Admissions/ExpiredTokenComponent';

export const admissionTokenExpiredPage = {
    path: '/',
    element: (
        <OffLayout>
            <Outlet />
        </OffLayout>
    ),
    children: [
        {
            path: 'felveteli/expired-token',
            element: <ExpiredTokenComponent />,
            name: 'admissin_expired_token',
        },
    ],
};
