import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Snackbar from '@mui/material/Snackbar';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import instance from '../../../../api';
import useOpen from '../../../../utils/useOpen';
import { endpoints } from './../../../../utils/endPoints';
import { Content } from './Content';
import { ClosingDialogProps, ICourseResponse, IStudentResponse } from './types';
import { PAGE_SIZE } from './utils';

export default function ClosingDialog({ checkResponse }: Omit<ClosingDialogProps, 'isClosing'>) {
    const { semesterId } = useParams() as { semesterId: string };
    const [response, setResponse] = useState<(ICourseResponse | IStudentResponse)[] | null>(null);
    const [loading, setLoading] = useState(false);
    const { isOpen, open, close } = useOpen();
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (checkResponse && ['check_error_course', 'check_error_student'].includes(checkResponse.status)) {
            setLoading(true);
            setPage(1);
            instance
                .get<(ICourseResponse | IStudentResponse)[]>(
                    endpoints.masterData.semesters[
                        checkResponse.status.includes('course') ? 'getCourseList' : 'getStudentList'
                    ](semesterId, '' + checkResponse.log),
                )
                .then((res) => {
                    setResponse(res.data);
                    open();
                })
                .finally(() => setLoading(false));
        }
    }, [checkResponse, open, semesterId]);

    if (loading) {
        return (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="info">
                    {checkResponse?.status === 'check_error_student' &&
                        'Követelményeket nem teljesítő hallgatók lekérése...'}
                    {checkResponse?.status === 'check_error_course' && 'Nyitott kurzusok lekérdezése...'}
                </Alert>
            </Snackbar>
        );
    }

    if (!checkResponse) return null;

    if (checkResponse.status === 'check_ok') {
        return (
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000}>
                <Alert severity="success">Sikeres ellenőrzés!</Alert>
            </Snackbar>
        );
    }

    if (['init', 'processing'].includes(checkResponse.status)) {
        return (
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000}>
                <Alert severity="success">Sikeres lezárás!</Alert>
            </Snackbar>
        );
    }

    if (response) {
        return (
            <Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
                <DialogTitle>
                    {checkResponse?.status === 'check_error_student' && 'Követelményeket nem teljesítő hallgatók'}
                    {checkResponse?.status === 'check_error_course' && 'Nyitott kurzusok'}
                </DialogTitle>
                <DialogContent dividers>
                    {checkResponse?.status === 'check_error_course' && (
                        <Alert severity="warning">A szemeszter zárásához minden kurzusnak zártnak kell lennie!</Alert>
                    )}
                    <Content response={response} page={page - 1} />
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Pagination
                                count={Math.floor(response.length / PAGE_SIZE)}
                                page={page}
                                onChange={(_, value) => setPage(value)}
                            />
                        </Grid>
                        <Grid item>
                            <Button onClick={close} variant="contained">
                                Bezárás
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
    return null;
}
