import Grid from '@mui/material/Grid';
import { Checkbox, FieldGenerator } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store';
import BackendSelect from '../../../../studentPages/components/BackendSelect';
import { Option } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import { deadlineOptions, TBaseData, timingOptions, TYPE_SCHEDULED } from './types';
import Typography from '@mui/material/Typography';

export default function Questionnaire({ isTenant = false }: { isTenant?: boolean }) {
    const { user } = useSelector((state: RootState) => state.auth);
    const formikProps = useFormikContext<TBaseData>();
    const { values, setFieldValue } = formikProps;
    const templateSetter = useCallback(
        (value: Option | null) => setFieldValue('questionnaireTemplate', value),
        [setFieldValue],
    );
    const disabled = user.archive || values.readonly;
    const { courseId } = useParams();

    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" style={{ marginTop: '8px' }}>
                    Kérdőív adatok
                </Typography>
            </Grid>
            <Grid item>
                <Checkbox
                    label="Elérhető kérdőív"
                    checkbox={{
                        disabled,
                        checked: values.questionnaire,
                        name: 'questionnaire',
                        onChange: (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            setFieldValue('questionnaire', !values.questionnaire);

                            if (checked) {
                                setFieldValue('questionnaireAnonymous', true);
                                setFieldValue('questionnaireTemplate', null);
                                setFieldValue('questionnaireTiming', timingOptions[0].value);
                                setFieldValue('questionnaireDeadline', deadlineOptions[0].value);
                                setFieldValue('questionnaireActivationStart', new Date().toISOString().split('T')[0]);
                                setFieldValue('questionnaireDeadlineDate', new Date().toISOString().split('T')[0]);
                                setFieldValue('questionnaireActive', false);
                            }
                        },
                    }}
                />
            </Grid>
            {values.questionnaire ? (
                <>
                    <Grid item>
                        <Checkbox
                            label="Anonim"
                            checkbox={{
                                disabled,
                                checked: values.questionnaireAnonymous,
                                name: 'questionnaireAnonymous',
                                onChange: () => {
                                    setFieldValue('questionnaireAnonymous', !values.questionnaireAnonymous);
                                    setFieldValue('questionnaireTemplate', null);
                                },
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={12} md={6}>
                            {!isTenant ? (
                                <BackendSelect
                                    url={
                                        endpoints.courseManagement.courseBaseData.questionnaireTemplates(
                                            courseId || '0',
                                        ) +
                                        '?questionnaireAnonymous=' +
                                        (values.questionnaireAnonymous ? '1' : '0') +
                                        '&courseType=' +
                                        values.courseType
                                    }
                                    label="Kérdőívsablon"
                                    value={values.questionnaireTemplate}
                                    setter={templateSetter}
                                    responseKey=""
                                    disabled={disabled}
                                    helperText={formikProps.errors.questionnaireTemplate as string}
                                    error={!!formikProps.errors.questionnaireTemplate}
                                />
                            ) : (
                                FieldGenerator({
                                    label: 'Kérdőívsablon',
                                    name: 'questionnaireTemplate',
                                    props: {
                                        disabled,
                                    },
                                    format: { xs: 12, md: 6 },
                                    ...formikProps,
                                })
                            )}
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        {FieldGenerator({
                            label: 'Időzítés',
                            name: 'questionnaireTiming',
                            type: 'select',
                            options: timingOptions,
                            props: {
                                disabled,
                            },
                            format: { xs: 12, md: 6 },
                            ...formikProps,
                        })}
                        {values.questionnaireTiming === TYPE_SCHEDULED
                            ? FieldGenerator({
                                  label: 'Aktiválás kezdete',
                                  name: 'questionnaireActivationStart',
                                  type: 'date',
                                  format: { xs: 12, md: 6 },
                                  props: {
                                      disabled,
                                  },
                                  ...formikProps,
                              })
                            : null}
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        {FieldGenerator({
                            label: 'Határidő',
                            name: 'questionnaireDeadline',
                            type: 'select',
                            options: deadlineOptions,
                            props: {
                                disabled,
                            },
                            format: { xs: 12, md: 6 },
                            ...formikProps,
                        })}
                        {values.questionnaireDeadline === '1'
                            ? FieldGenerator({
                                  label: 'Kitöltési határidő',
                                  name: 'questionnaireDeadlineDate',
                                  type: 'date',
                                  format: { xs: 12, md: 6 },
                                  props: {
                                      disabled,
                                  },
                                  ...formikProps,
                              })
                            : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox
                            label="Kérdőív aktiválása"
                            checkbox={{
                                disabled,
                                checked: values.questionnaireActive,
                                name: 'questionnaireActive',
                                onChange: () => {
                                    setFieldValue('questionnaireActive', !values.questionnaireActive);
                                },
                            }}
                        />
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
}
