import TrainingPrograms from '../../../pages/MasterData/General/TrainingLevels/TrainingPrograms';
import TrainingProgramForm from '../../../pages/MasterData/General/TrainingLevels/TrainingPrograms/TrainingProgramForm';
import { trainingLevelsProgramsRequirements } from './TrainingLevelsProgramsRequirements';

export const trainingLevelsPrograms = {
    path: ':id/training-programs',
    children: [
        {
            path: '',
            element: <TrainingPrograms />,
            name: 'md_training_levels_programs_list',
        },
        {
            path: 'new',
            element: <TrainingProgramForm />,
            name: 'md_training_levels_programs_create',
        },
        {
            path: ':programId/edit',
            element: <TrainingProgramForm />,
            name: 'md_training_levels_programs_edit',
        },
        {
            path: ':programId/show',
            element: <TrainingProgramForm readOnly={true} />,
            name: 'md_training_levels_programs_show',
        },
        trainingLevelsProgramsRequirements,
    ],
};
