import CourseData from './CourseDataRow';
import Grid from '@mui/material/Grid';
import { endpoints } from '../../../utils/endPoints';
import { Option } from '../../../utils/AppConst';
import { useEffect, useState } from 'react';
import instance from '../../../api';

interface IMandatouryCourseInfo {
    toBeCompleted: number;
    completed: number;
    coTrainingToBeCompleted: number;
    coTrainingCompleted: number;
    coTrainingToBeCompletedPoint: number;
    coTrainingCompletedPoint: number;
}

const makeOptions = (data: IMandatouryCourseInfo | null, loading: boolean): Option[] =>
    [
        {
            label: 'Minimum teljesítendő',
            value: `${data?.toBeCompleted} db`,
        },
        {
            label: 'Teljesített',
            value: `${data?.completed} db`,
        },
        {
            label: 'Társképzésből teljesítendő',
            value: `${data?.coTrainingToBeCompletedPoint} pont`,
        },
        {
            label: 'Társképzésből teljesített',
            value: `${data?.coTrainingCompletedPoint} pont`,
        },
    ].map((o) => (loading ? { ...o, value: 'Betöltés...' } : o));

export default function MandatoryCourses({ selectedProgram }: { selectedProgram: null | number }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IMandatouryCourseInfo | null>(null);

    useEffect(() => {
        setLoading(true);
        setData(null);
        instance
            .get(
                endpoints.students.requirements.mandatoryCourses +
                    (selectedProgram ? '?program=' + selectedProgram : ''),
            )
            .then((res) => {
                setData(res.data);
                setLoading(false);
            });
    }, [selectedProgram, setData, setLoading]);

    return (
        <Grid item container xs={12}>
            <fieldset style={{ border: '1px solid #ddd', textAlign: 'center' }}>
                <legend>Kötelezően választható kurzusok a képzés során</legend>
                <Grid item container xs={12} spacing={4}>
                    {makeOptions(data, loading).map((elem) => (
                        <CourseData key={elem.label} {...elem} />
                    ))}
                </Grid>
            </fieldset>
        </Grid>
    );
}
