import { endpoints } from '../../utils/endPoints';
import API from '../../api';

const scholarshipService = (studentId: number | string) => ({
    /** Admin hallgató részletes ösztöndíj export */
    initiateExport: (form: { format: string }) =>
        API.post(
            endpoints.masterData.students.scholarship.export.initiate.replace('{studentId}', studentId.toString()),
            form,
        ),
    checkExport: (id: number) =>
        API.get(
            endpoints.masterData.students.scholarship.export.check
                .replace('{studentId}', studentId.toString())
                .replace('{id}', id.toString()),
        ),
    downloadExport: (id: number) =>
        API.get(
            endpoints.masterData.students.scholarship.export.download
                .replace('{studentId}', studentId.toString())
                .replace('{id}', id.toString()),
            {
                responseType: 'arraybuffer',
            },
        ),
});

export default scholarshipService;
