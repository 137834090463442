import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { endpoints } from '../../../utils/endPoints';
import Autocomplete from '../../../components/Form/Autocomplete';
import instance from '../../../api';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { Option } from '../Admissions/types';
import { FormikListPage, Header } from '@silinfo/front-end-template';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../../../utils/AppConst';
import { tableProps, header, breadcrumbs } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

export default function ApplicantReport() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [selected, setSelected] = useState<Option<string> | string | null>();
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const service = () => ({
        applicantSearch: (term: string) => instance.get(endpoints.admission.applicantSearch + '?term=' + term),
    });

    const search = async (term: string): Promise<AxiosResponse> => {
        const response = await service().applicantSearch(term);
        const { data } = response;
        return {
            ...response,
            data: data,
        };
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...headerWithTenant, breadcrumbs }} />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    getter={(term: string) => search(term)}
                    createOptions={(data) => {
                        return data;
                    }}
                    autocompleteProps={{
                        onChange: (_, value) => {
                            if (value) {
                                setSelected(value);
                            }
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                {selected ? (
                    <FormikListPage {...tableProps((selected as Option<string>).value, user.archive, tenant)} />
                ) : (
                    <ThemeProvider theme={defaultTheme}>
                        <Alert severity="info">Kérjük, válassz egy felvételizőt az adatok megtekintéséhez</Alert>
                    </ThemeProvider>
                )}
            </Grid>
        </Grid>
    );
}
