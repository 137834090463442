import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import { createAddressStringFromObject } from '../../utils';
import SemesterAllocationInfo from './SemesterAllocationInfo';
import Title from '../../../../../../components/Title';
import { IRelocation } from './types';

export interface RelocationDialogContentProps {
    data: IRelocation;
}

export default function RelocationDialogContent(props: RelocationDialogContentProps) {
    const { data } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Title title="Típus" />
                <TextField value={'Átköltözés'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Név" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Vezetéknév" value={data.lastName || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Keresztnév" value={data.firstName || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Állandó lakcím" />
                <TextField value={createAddressStringFromObject(data.address) || '-'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="E-mail cím" />
                <TextField value={data.email || '-'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Telefonszám" />
                <TextField value={data.phoneNumber || '-'} disabled />
            </Grid>

            {data.oldStudentAllocation && (
                <Grid item xs={12}>
                    <SemesterAllocationInfo data={data.oldStudentAllocation} type="old" />
                </Grid>
            )}
            {data.newStudentAllocation && (
                <Grid item xs={12}>
                    <SemesterAllocationInfo data={data.newStudentAllocation} type="new" />
                </Grid>
            )}

            <Grid item xs={12}>
                <Title title="Kiköltözés várható dátuma" />
                <TextField value={data.moveOutDate || '-'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Pénzügyes megjegyzése" />
                <TextField value={data.financialComment || '-'} multiline rows={4} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Üzemeltető megjegyzése" />
                <TextField value={data.operatorComment || '-'} multiline rows={4} disabled />
            </Grid>
        </Grid>
    );
}
