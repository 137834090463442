import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import DialogContentText from '@mui/material/DialogContentText';
import { LoadingButton } from '@mui/lab';
import RefreshContext from '../../../../studentPages/SelfAssessment/RefreshContextProvider';
import { EditActionProps } from './types';

export const EditActions = ({ handleClose, handleSubmit }: EditActionProps) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => setOpen(true), []);
    const handleCloseDialog = useCallback(() => setOpen(false), []);
    const refresh = useContext(RefreshContext);

    const handleClick = useCallback(async () => {
        handleCloseDialog();
        setLoading(true);
        try {
            await handleSubmit();
            handleClose();
            refresh();
        } finally {
            setLoading(false);
        }
    }, [handleCloseDialog, handleSubmit, handleClose, refresh]);

    return (
        <Grid item xs={12} justifyContent="flex-end" container spacing={1}>
            <Grid item>
                <Button onClick={handleClose} variant="outlined">
                    Mégse
                </Button>
            </Grid>
            <Grid item>
                <LoadingButton onClick={handleOpen} loading={loading} variant="contained">
                    Mentés
                </LoadingButton>
                <Dialog open={open} onClose={handleCloseDialog} fullWidth>
                    <DialogTitle>Megerősítés</DialogTitle>
                    <DialogContent style={{ paddingTop: '16px' }}>
                        <DialogContentText>Biztosan mented a változtatásokat?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} variant="outlined">
                            Nem
                        </Button>
                        <Button onClick={handleClick} variant="contained">
                            Igen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
};
