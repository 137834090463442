import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useFormikContext } from 'formik';
import { Children, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { create } from '../../store/notification';
import { Option } from '../../utils/AppConst';
import { TDependentSelect } from '../../utils/Interfaces/interfaces';
import { FieldGenerator } from './FieldGenerator';

//példabemenet
export const exampleDependentSelects: TDependentSelect = {
    type: 'select',
    name: 'select',
    label: 'Select',
    options: [
        { value: 'dog', label: 'Kutya' },
        { value: 'cat', label: 'Macska' },
    ],
    children: [
        {
            name: 'childSelect',
            type: 'select',
            label: 'Child Select',
            optionsGetter(value) {
                return new Promise((res) =>
                    setTimeout(
                        () =>
                            res(
                                value === 'dog'
                                    ? [{ value: 'littleDog', label: 'Kiskutya' }]
                                    : [{ value: 'littleCat', label: 'Kismacska' }],
                            ),
                        500,
                    ),
                );
            },
            children: [
                {
                    name: 'grandChildSelect',
                    type: 'select',
                    label: 'Grandchild Select',
                    optionsGetter(value) {
                        return new Promise((res) =>
                            setTimeout(
                                () =>
                                    res(
                                        value === 'littleDog'
                                            ? [{ value: 'miniDog', label: 'Picikutya' }]
                                            : [{ value: 'miniCat', label: 'Picimacska' }],
                                    ),
                                500,
                            ),
                        );
                    },
                },
            ],
        },
    ],
};

type DependentSelectProps = TDependentSelect &
    (
        | {
              optionsGetter: (id: string | number) => Option[] | Promise<Option[]>;
              parentValue?: string | number;
              parentLoading?: boolean;
          }
        | {
              optionsGetter?: never;
              parentValue?: never;
              parentLoading?: never;
          }
    );

export default function DependentSelect<T extends Record<string, string>>(props: DependentSelectProps) {
    const formikProps = useFormikContext<T>();
    const { setFieldValue } = formikProps;
    const { name, label, type, optionsGetter, parentValue, children, parentLoading, format, disabled, multiple } =
        props;
    const [options, setOptions] = useState<Option[]>(props.options || []);
    const [loading, setLoading] = useState(false);
    const [parentValueState, setParentValueState] = useState(parentValue);
    const dispatch = useDispatch();

    useEffect(() => {
        let isActive = true;
        if (parentValue === '' || (parentValue && parentValueState !== parentValue)) {
            setFieldValue(name, '');
        }
        if (parentValue !== parentValueState) {
            setParentValueState(parentValue);
        }
        if (optionsGetter && parentValue && !parentLoading && parentValue === parentValueState) {
            setLoading(true);
            Promise.resolve(optionsGetter(parentValue))
                .then((response) => {
                    if (isActive) {
                        response.sort((a, b) => (a.label > b.label ? 1 : -1));
                        setOptions(response);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(create({ type: 'error', message: 'Hiba a ' + label + ' opcióinak betöltése során' }));
                })
                .finally(() => setLoading(false));
        }
        return () => {
            isActive = false;
        };
    }, [parentValue, parentLoading, setFieldValue, name, dispatch, label, optionsGetter, parentValueState]);

    return (
        <>
            {loading || parentLoading ? (
                <Grid item container {...(format || { xs: 12, sm: 6, md: 4 })}>
                    <Skeleton style={{ width: '100%' }} />
                </Grid>
            ) : (
                FieldGenerator({
                    ...{
                        name,
                        label,
                        type,
                        options,
                        fieldType: 'base',
                        props: {
                            disabled: disabled || !(parentValue || props.options),
                            ...(multiple !== undefined && {
                                multiple,
                                onChange: (_: unknown, value: Option) => setFieldValue(name, value?.value || ''),
                            }),
                        },
                        format,
                    },
                    ...formikProps,
                })
            )}
            {children &&
                Children.toArray(
                    children.map((child) =>
                        child.optionsGetter ? (
                            <DependentSelect
                                {...child}
                                parentValue={formikProps.values[name as keyof T]}
                                parentLoading={parentLoading || loading}
                                disabled={disabled}
                            />
                        ) : (
                            <DependentSelect {...child} disabled={disabled} />
                        ),
                    ),
                )}
        </>
    );
}
