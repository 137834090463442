import Grid from '@mui/material/Grid';
import { FormikListPage, Link, TableButton, TableEditButton, Tooltip } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MCCLoading from '../../../../components/MCCLoading';
import usersService from '../../../../services/masterData/users';
import { RootState } from '../../../../store';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import ROLES from '../../../../utils/Roles';
import Chip from '@mui/material/Chip';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Option } from '../../../../utils/AppConst';
import { ActivationEmailButton, PasswordReminderButton } from '../Students/Operations';
import useUserFields from './useUserFields';
import TableButtonArchive from '../../../../components/Buttons/TableButtonArchive';
import Visibility from '@mui/icons-material/Visibility';
import { formatDate } from '../../../../utils/timezoneConverter';
import { useTenant } from '../../../../components/TenantContext';

export function DisabledCellRenderer(params: GridRenderCellParams) {
    const disabled = params.row?.disabled;

    return <Chip color={disabled ? 'error' : 'success'} label={disabled ? 'Igen' : 'Nem'} />;
}

export function ArchivedCellRenderer(params: GridRenderCellParams) {
    const archive = params.row?.archive;

    return <Chip color={archive ? 'error' : 'success'} label={archive ? 'Igen' : 'Nem'} />;
}

export function TerminatingCellRenderer(params: GridRenderCellParams) {
    const terminating = params.row?.terminating;

    return <Chip color={terminating ? 'error' : 'success'} label={terminating ? 'Igen' : 'Nem'} />;
}

function ActivatedCellRenderer(params: GridRenderCellParams) {
    const activated = params.row.activated;

    return <Chip color={!activated ? 'error' : 'success'} label={activated ? 'Igen' : 'Nem'} />;
}

export default function Users() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [refresh, setRefresh] = useState<boolean>(false);
    const { fields, loading, setLoading, rolesOptions } = useUserFields(refresh);
    const tenant = useTenant();

    const doRefresh = () => setRefresh((prev) => !prev);

    const header = {
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_users_list]: 'Felhasználókezelés',
        },
        project: tenant || 'TAR',
        title: 'Felhasználókezelés',
    };

    function RolesCellRenderer(params: GridRenderCellParams) {
        let roles = params.row?.roles;

        if (typeof roles !== typeof []) {
            return '-';
        }

        roles = roles.filter(function (e: string) {
            return e !== ROLES.USER && e !== ROLES.STUDENT;
        });

        if (roles.length === 0) {
            return '-';
        }

        roles = roles.map((role: string) => {
            const arrE = rolesOptions.find((e: Option) => {
                return e.value === role;
            });
            return arrE ? arrE['label'] : role;
        });

        return (
            <Tooltip title={roles.join(', ')}>
                <div>{roles.join(', ')}</div>
            </Tooltip>
        );
    }

    function BtnCellRenderer(params: GridRenderCellParams) {
        if (user.archive) {
            return (
                <Link to={clientEndPoints.md_general_users_show.replace(':id', params.row.id.toString())}>
                    <TableButton tooltip={'Adatok megtekintése'}>
                        <Visibility />
                    </TableButton>
                </Link>
            );
        } else {
            return (
                <Grid container spacing={1}>
                    <Grid item>
                        <Link to={clientEndPoints.md_general_users_edit.replace(':id', params.row.id.toString())}>
                            <TableEditButton tooltip={'Szerkesztés'} />
                        </Link>
                    </Grid>
                    {params.row.id !== user.id && !params.row.student && (
                        <Grid item>
                            <TableButtonArchive
                                archive={params.row.archive}
                                id={params.row.id}
                                setLoading={setLoading}
                                setRefresh={setRefresh}
                            />
                        </Grid>
                    )}
                    {!params.row.disabled && (
                        <>
                            {!params.row.activated && (
                                <Grid item>
                                    <ActivationEmailButton
                                        id={parseInt(params.row.id.toString())}
                                        isDisabled={params.row.disabled}
                                        activationEmailSent={params.row.activationEmailSent}
                                        setRefresh={doRefresh}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <PasswordReminderButton
                                    isDisabled={params.row.disabled}
                                    id={parseInt(params.row.id.toString())}
                                    setRefresh={doRefresh}
                                    activated={params.row.activated}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            );
        }
    }

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            service={usersService}
            header={header}
            refresh={refresh}
            autoload
            filter={{
                fields,
            }}
            columns={
                [
                    { headerName: 'E-mail cím', field: 'email', minWidth: 200 },
                    { headerName: 'Vezetéknév', field: 'lastName', minWidth: 120 },
                    { headerName: 'Keresztnév', field: 'firstName', minWidth: 120 },
                    {
                        headerName: 'Campus',
                        field: 'campus',
                        valueGetter: (params: GridValueGetterParams) => params.row?.campus?.name || '-',
                        minWidth: 200,
                    },
                    { headerName: 'MCC azonosító', field: 'mccId', align: 'center', minWidth: 120, flex: 0.5 },
                    { headerName: 'Jogosultság', field: 'roles', renderCell: RolesCellRenderer, minWidth: 200 },
                    {
                        headerName: 'Letiltva',
                        field: 'disabled',
                        renderCell: DisabledCellRenderer,
                        align: 'center',
                        minWidth: 80,
                        flex: 0.5,
                    },
                    {
                        headerName: 'Aktiválva',
                        field: 'activated',
                        renderCell: ActivatedCellRenderer,
                        align: 'center',
                        minWidth: 80,
                        flex: 0.5,
                    },
                    {
                        headerName: 'Archív',
                        field: 'archive',
                        renderCell: ArchivedCellRenderer,
                        align: 'center',
                        minWidth: 80,
                        flex: 0.5,
                    },
                    {
                        headerName: 'Utolsó belépés',
                        field: 'lastLoggedInAt',
                        minWidth: 160,
                        flex: 0.5,
                        align: 'center',
                        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.lastLoggedInAt),
                    },
                    {
                        headerName: 'Műveletek',
                        field: 'operations',
                        renderCell: BtnCellRenderer,
                        sortable: false,
                        minWidth: 150,
                        flex: 0.5,
                    },
                ].map((e) => ({ headerAlign: 'center', flex: 1, ...e })) as GridColDef[]
            }
            newLabel={!user.archive ? 'Felhasználó hozzáadása' : ''}
        />
    );
}
