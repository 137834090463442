import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Loading } from '@silinfo/front-end-template';
import { Children, useMemo } from 'react';
import { ICourseView } from '../../../../pages/CourseManagement/Courses/types';
import { endpoints } from '../../../../utils/endPoints';
import useFetch from '../../../../utils/useFetch';
import { HeaderRow } from './HeaderRow';
import { Row } from './Row';
import { headerData } from './utils';
import useCheckRefresh from '../../../../utils/useCheckRefresh';
import useOpen from '../../../../utils/useOpen';
import { RefreshContextProvider } from '../../../SelfAssessment/RefreshContextProvider';
import useMediaQuery from '@mui/material/useMediaQuery';

const NoData = () => (
    <Grid item xs={12}>
        <Typography textAlign="center">
            <i>Nincs megjeleníthető kurzus</i>
        </Typography>
    </Grid>
);

export default function Table({ semester }: { semester: string }) {
    const refreshCheck = useCheckRefresh();
    const { isOpen: refresh, toggleOpen: toggleRefresh } = useOpen();
    const { data, loading } = useFetch<ICourseView[]>(
        endpoints.students.requirements.courses.replace('{semesterId}', semester),
        'data',
        JSON.stringify([refreshCheck, refresh]),
    );
    const matches = useMediaQuery('(min-width: 960px)', { noSsr: true });

    const pointCourses = useMemo(() => data.filter((e) => e.scoringType === 'point'), [data]);
    const copperCourses = useMemo(() => data.filter((e) => e.scoringType === 'copper'), [data]);

    if (loading) {
        return <Loading noHeight />;
    }

    return data.length > 0 ? (
        <RefreshContextProvider refresh={toggleRefresh}>
            <Grid item container xs={12} spacing={2} style={matches ? { minWidth: '800px' } : {}}>
                <Grid item container xs={12} spacing={1}>
                    {matches ? (
                        <Grid item container columns={36} xs={36} spacing={1}>
                            {headerData(true).map((e) => (
                                <HeaderRow key={e.label} {...e} />
                            ))}
                        </Grid>
                    ) : null}
                    {pointCourses.length > 0 ? Children.toArray(pointCourses.map(Row)) : <NoData />}
                </Grid>
                <Grid item xs={12}>
                    <Divider>Garasos kurzusok</Divider>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                    {matches ? (
                        <Grid item container columns={36} xs={36} spacing={1}>
                            {headerData(false).map((e) => (
                                <HeaderRow key={e.label} {...e} />
                            ))}
                        </Grid>
                    ) : null}
                    {copperCourses.length > 0 ? Children.toArray(copperCourses.map(Row)) : <NoData />}
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </RefreshContextProvider>
    ) : (
        <Typography textAlign="center">Nincs megjeleníthető kurzus</Typography>
    );
}
