import { Accordion, Page } from '@silinfo/front-end-template';
import Form from '../../../../components/Form/Form';
import Yup from '../../../../utils/yup';
import { fields } from './fields';
import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import administrationFeesService from '../../../../services/masterData/finances/administrationFees/administrationFees';
import { IAdministrationFeeForm } from '../../../../services/masterData/finances/administrationFees/administrationFees';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { AxiosResponse } from 'axios';
import { ADMIN_FEE_CATEGORY_INPAYMENT } from '../../../../utils/AppConst';
import { useTenant } from '../../../../components/TenantContext';

const Schema = Yup.object().shape({
    type: Yup.string().max(128).required(),
});

export default function AdministrationFeeForm() {
    const { id } = useParams();
    const [data, setData] = useState<IAdministrationFeeForm>();
    const title = data?.type ? data.type + ' szerkesztése' : 'Adminisztrációs díj hozzáadása';
    const tenant = useTenant();

    const getFee = useCallback(async (): Promise<AxiosResponse<IAdministrationFeeForm>> => {
        const { data: info, ...response } = await administrationFeesService.get(id || 0);
        setData(info);
        return {
            ...response,
            data: info,
        };
    }, [id]);

    const header = {
        project: tenant || 'TAR',
        title: 'Adminisztrációs díjak',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_finance]: 'Pénzügy',
            [clientEndPoints.md_finance_administration_fees_list]: 'Adminisztrációs díjak',
            form: title,
        },
    };

    return (
        <Page header={header}>
            <Accordion title={title}>
                <Form
                    validationSchema={Schema}
                    onSubmit={(values: IAdministrationFeeForm) =>
                        id ? administrationFeesService.update(id, values) : administrationFeesService.create(values)
                    }
                    initialValues={
                        { type: '', typeShort: '', category: ADMIN_FEE_CATEGORY_INPAYMENT } as IAdministrationFeeForm
                    }
                    fields={fields(!!data?.flag, !!data?.used)}
                    back={clientEndPoints.md_finance_administration_fees_list}
                    getterFunction={getFee}
                />
            </Accordion>
        </Page>
    );
}
