import Courses from '../../pages/MasterData/Courses';
import { courseTypes } from './Courses/CourseTypes';

export const courses = {
    path: 'courses',
    children: [
        {
            path: '',
            element: <Courses />,
            name: 'md_courses',
        },
        courseTypes,
    ],
};
