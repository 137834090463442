import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { theme } from '../../../../../../utils/theme';
import { completionDataTranslations } from '../utils';

export default function Header({ print }: { print?: boolean }) {
    return (
        <TableHead>
            <TableRow style={{ backgroundColor: theme.palette.secondary.main }}>
                {!print ? <TableCell /> : null}
                {Object.entries(completionDataTranslations).map(([key, value], i) => (
                    <TableCell
                        align={i > 0 ? 'center' : 'left'}
                        key={key}
                        style={{ color: theme.palette.primary.main }}
                    >
                        {print ? <strong>{value}</strong> : value}
                    </TableCell>
                ))}
                {!print ? <TableCell /> : null}
            </TableRow>
        </TableHead>
    );
}
