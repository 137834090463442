import { Input, Option } from '@silinfo/front-end-template';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { endpoints } from '../../../../utils/endPoints';
import { useCallback, useMemo } from 'react';
import { FormikContextType, useFormikContext } from 'formik';

export interface Gift {
    gifted: string[];
    count: number;
    type: string;
}

export interface IITRequest {
    itRequest: string;
    itRequestCount: number;
    description: string;
}
export interface IEventForm extends IForm {
    eventType: string;
    comment: string;
    instructors: never[];
    eventStart: string;
    eventEnd: string;
    campus: string;
    building: string;
    floor: string;
    room: string;
    item: string;
    isRepeated: string;
    numberOfRepetitions: number;
    pointMin: null;
    pointMax: null;
    guestInstructors: string[];
    external: boolean;
    category: string;
    organisingTrainingCenter: string;
    organisers: string[];
    addresses: IAddress[];
    presentsPerLocation: boolean;
    communicationPerLocation: boolean;
    cateringPerLocation: boolean;
    itNeedPerLocation: boolean;
    roomLayoutPerLocation: boolean;
}

export interface IAddress extends IMultiLocationPorcessParameters {
    campus: string | Option;
    building: string | Option;
    floor: string | Option;
    room: string | Option;
    roomName: string;
    spaces: number;
    queue?: number;
    id?: number;
}

export interface IExternalAddress extends IMultiLocationPorcessParameters {
    country: string;
    location: string;
    id?: number;
}

export interface IMultiLocationPorcessParameters {
    gifts?: IGift[];
    communicationNeed?: number[];
    communicationItemNeeds?: number[];
    waterForPresenter?: boolean;
    receptionType?: string;
    waterFoodNeeded?: boolean;
    cateringFileName?: string;
    cateringFilePath?: string;
    visualAppearances?: string[];
    itRequests?: IITRequest[];
    roomLayout?: string;
    communicationNeedsDescription?: string;
    communicationItemNeedsDescription?: string;
    visualAppearancesDescription?: string;
    customRoomLayoutDescription?: string;
    customRoomLayoutFileName?: string;
    customRoomLayoutFilePath?: string;
}

export interface IGift {
    count: number;
    gifted: string[];
    type: string;
    description: string;
}

export interface ICatering {
    waterForPresenter: boolean;
    receptionType: string;
    waterFoodNeeded: boolean;
    cateringFileName: string;
    cateringFilePath: string;
}

const giftFields = (gifts: Option[]): Input[] => [
    {
        name: 'type',
        label: 'Ajándék típusa',
        type: 'select',
        options: gifts,
        format: {
            xs: 12,
        },
        props: {
            clearable: true,
        },
    },
    {
        name: 'count',
        label: 'Darabszám',
        format: {
            xs: 12,
        },
        fieldType: 'base',
        props: {
            InputLabelProps: {
                shrink: true,
            },
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
                if (!event.key.match(regex)) {
                    event.preventDefault();
                }
            },
        },
    },
    {
        name: 'gifted',
        label: 'Ajándékozottak',
        type: 'multiselect',
        fieldType: 'base',
        options: [],
        props: {
            freeSolo: true,
            noOptionsText: 'Nincs találat',
            autoSelect: true,
            isOptionEqualToValue: undefined,
        },
    },
    {
        name: 'description',
        label: 'Leírás',
        format: {
            xs: 12,
        },
    },
];

const communicationFields = (): Input[] => [
    {
        name: 'communicationNeed',
        label: '',
        type: 'backendSelect',
        url: endpoints.courseManagement.courseList.listCommunicationNeed,
        format: {
            xs: 12,
        },
        justValue: true,
        multiple: true,
    },
    {
        name: 'communicationItemNeeds',
        label: '',
        type: 'backendSelect',
        url: endpoints.courseManagement.courseList.listCommunicationItemNeed,
        format: {
            xs: 12,
        },

        multiple: true,
        justValue: true,
    },
    {
        name: 'communicationItemNeedsDescription',
        label: 'Leírás',
        format: {
            xs: 12,
        },
    },
    {
        name: 'communicationNeedsDescription',
        label: 'Leírás',
        format: {
            xs: 12,
        },
    },
];

const iTRequestFields = (itRequest: Option[]): Input[] => [
    {
        name: 'itRequest',
        label: 'IT igény',
        type: 'select',
        options: [...itRequest],
        format: {
            xs: 12,
        },
        props: {
            clearable: true,
        },
    },
    {
        name: 'itRequestCount',
        label: 'Mennyiség',
        format: {
            xs: 12,
        },
        fieldType: 'base',
        props: {
            InputLabelProps: {
                shrink: true,
            },
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
                if (!event.key.match(regex)) {
                    event.preventDefault();
                }
            },
        },
    },
    {
        name: 'description',
        label: 'Leírás',
        format: {
            xs: 12,
        },
    },
];

const REVIEW_VALIDATION_PHASES = [
    'base',
    'presentEvent',
    'communicationEvent',
    'cateringEvent',
    'itNeedsEvent',
    'roomLayoutEventForm',
];

const getBuildingDisabled = (values: IEventForm, i: number) => {
    if (values.external) return true;
    return !values.addresses?.[i]?.campus || (values.addresses?.[i]?.campus as Option)?.value === '';
};

const getFloorDisabled = (values: IEventForm, i: number) => {
    if (values.external) return true;
    return (
        !values.addresses?.[i]?.building ||
        (values.addresses?.[i]?.building as Option)?.value === '' ||
        getBuildingDisabled(values, i)
    );
};

const getRoomDisabled = (values: IEventForm, i: number) => {
    if (values.external) return true;
    return (
        !values.addresses?.[i]?.floor ||
        (values.addresses?.[i]?.floor as Option)?.value === '' ||
        getFloorDisabled(values, i)
    );
};

const MakeDynamicFields = (
    i: number,
    _trainingCenters: Option[],
    setFieldValue: (field: string, value: string | number | unknown, shouldValidate?: boolean | undefined) => void,
    values: IEventForm,
    context: FormikContextType<IEventForm>,
) => {
    const createTransformFunction = useCallback(
        (fieldType: 'campus' | 'building' | 'floor' | 'room') => (data: (string | number | Option)[]) => {
            if (data.length === 1) {
                const value = data[0];
                if (typeof value === 'object' && fieldType === 'room') {
                    setFieldValue(
                        'addresses.' + i + '.roomName',
                        (value as unknown as { label: string; value: number })?.label || null,
                    );
                }
                setFieldValue('addresses.' + i + '.' + fieldType, typeof value === 'object' ? value.value : value);
            }
            return data as unknown as Option[];
        },
        [setFieldValue, i],
    );
    const trainingCenterTransform = useMemo(() => createTransformFunction('campus'), [createTransformFunction]);
    const buildingTransform = useMemo(() => createTransformFunction('building'), [createTransformFunction]);
    const floorTransform = useMemo(() => createTransformFunction('floor'), [createTransformFunction]);
    const roomTransform = useMemo(() => createTransformFunction('room'), [createTransformFunction]);

    const dynamicFields: Input[] = [
        {
            name: 'campus',
            label: 'Képzési központ',
            type: 'backendSelect',
            url: endpoints.courseManagement.courseList.activeCampuses,
            format: { xs: 12 },
            fieldType: 'base',
            props: {
                multiple: false,
                onChange: (_: unknown, value: Option) => {
                    setFieldValue('addresses.' + i + '.campus', value?.value ?? value);
                    setFieldValue('addresses.' + i + '.building', '');
                    setFieldValue('addresses.' + i + '.floor', '');
                    setFieldValue('addresses.' + i + '.room', '');
                    setFieldValue('addresses.' + i + '.roomName', '');
                    setFieldValue('addresses.' + i + '.spaces', 0);
                    setFieldValue('addresses.' + i + '.gifts', []);
                    setFieldValue('addresses.' + i + '.itRequests', []);
                },
                transform: trainingCenterTransform,
            },
        },
    ];

    if (!getBuildingDisabled(values, i)) {
        dynamicFields.push({
            name: 'building',
            label: 'Épület',
            type: 'backendSelect',
            url: endpoints.courseManagement.courseList.buildings + `?campus=${values.addresses?.[i].campus || ''}`,
            justValue: true,
            fieldType: 'base',
            transform: buildingTransform,
            props: {
                onChange: (_: unknown, value: Option | null) => {
                    context.setFieldValue('addresses.' + i + '.building', value?.value || value);
                    context.setFieldValue('addresses.' + i + '.floor', '');
                    context.setFieldValue('addresses.' + i + '.room', '');
                    context.setFieldValue('addresses.' + i + '.gifts', []);
                    context.setFieldValue('addresses.' + i + '.itRequests', []);
                },
            },
        });

        if (!getFloorDisabled(values, i)) {
            dynamicFields.push({
                name: 'floor',
                label: 'Szint',
                type: 'backendSelect',
                url: endpoints.courseManagement.courseList.floors + `?building=${values.addresses?.[i].building || ''}`,
                justValue: true,
                fieldType: 'base',
                transform: floorTransform,
                props: {
                    onChange: (_: undefined, value: Option | null) => {
                        context.setFieldValue('addresses.' + i + '.floor', value?.value || null);
                        context.setFieldValue('addresses.' + i + '.room', '');
                        context.setFieldValue('addresses.' + i + '.gifts', []);
                        context.setFieldValue('addresses.' + i + '.itRequests', []);
                    },
                },
            });

            if (!getRoomDisabled(values, i)) {
                dynamicFields.push({
                    name: 'room',
                    label: 'Terem',
                    type: 'backendSelect',
                    url:
                        endpoints.courseManagement.courseList.classRooms +
                        `?floor=${values.addresses?.[i].floor || ''}`,
                    justValue: true,
                    fieldType: 'base',
                    transform: roomTransform,
                    props: {
                        onChange: (_: undefined, value: { label: string; value: number; space: number } | null) => {
                            context.setFieldValue('addresses.' + i + '.room', value?.value || null);
                            context.setFieldValue('addresses.' + i + '.roomName', value?.label || null);
                            context.setFieldValue('addresses.' + i + '.spaces', value?.space || null);
                            context.setFieldValue('addresses.' + i + '.gifts', []);
                            context.setFieldValue('addresses.' + i + '.itRequests', []);
                        },
                    },
                });
            }
        }
    }
    return dynamicFields;
};

const useDynamicFields = (i: number, trainingCenters: Option[], loading: boolean): Input[] => {
    const context = useFormikContext<IEventForm>();
    const { setFieldValue } = context;
    const values = context.values;
    if (values.external || loading) return [];

    return MakeDynamicFields(i, trainingCenters, setFieldValue, values, context);
};

const rawInternalFields: Input[] = [
    {
        name: 'trainingCenter',
        label: 'Képzési központ',
        type: 'select',
        options: [],
    },
    {
        name: 'building',
        label: 'Épület',
        type: 'backendSelect',
        url: '',
        justValue: true,
    },
    {
        name: 'floor',
        label: 'Szint',
        type: 'backendSelect',
        url: '',
        justValue: true,
    },
    {
        name: 'room',
        label: 'Terem',
        type: 'backendSelect',
        url: '',
        justValue: true,
    },
];

const removeHtmlTags = (input: string): string => {
    return input.replace(/<[^>]*>/g, '');
};

export {
    giftFields,
    communicationFields,
    iTRequestFields,
    REVIEW_VALIDATION_PHASES,
    useDynamicFields,
    rawInternalFields,
    removeHtmlTags,
};
