import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { IUser } from './Interfaces/interfaces';

export const userHasAccess = (endpoints: string[], user: IUser, accessTree: { [key: string]: string[] }): boolean =>
    endpoints.some((endpoint) => accessTree[endpoint]?.some((elem: string) => user.roles.includes(elem))) ||
    !!user.admin;

export default function useAccessCheck(endpoints: string[]): boolean {
    const { user } = useSelector((state: RootState) => state.auth);
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);

    return userHasAccess(endpoints, user, accessTree);
}
