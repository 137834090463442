import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import useProfilePictureAdmin from './useProfilePictureAdmin';
import Skeleton from '@mui/material/Skeleton';

const AVATAR_SIZE = 75;

const useStyles = makeStyles(() => ({
    avatar: {
        '&:hover': {
            filter: 'contrast(0.5)',
        },
    },
}));

export default function ProfilePicture({ userId }: { userId: string }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { loading, profilePicture } = useProfilePictureAdmin(userId);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <IconButton sx={{ padding: 0 }} onClick={() => setOpen(true)}>
                    {loading ? (
                        <Skeleton
                            width={AVATAR_SIZE}
                            height={AVATAR_SIZE}
                            variant="circular"
                            className="borderRadius"
                        />
                    ) : (
                        <Avatar
                            alt="profile picture"
                            src={profilePicture}
                            sx={{
                                width: AVATAR_SIZE,
                                height: AVATAR_SIZE,
                            }}
                            className={classes.avatar}
                        />
                    )}
                </IconButton>
            </div>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Profilkép</DialogTitle>
                <img style={{ margin: '4px' }} alt="profile picture" src={profilePicture} />
            </Dialog>
        </>
    );
}
