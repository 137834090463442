export const columns = [
    { headerName: 'Időpont', field: 'insertDate', minWidth: 200 },
    { headerName: 'Felhasználó', field: 'user', cellRenderer: 'actorCellRenderer', minWidth: 250 },
    { headerName: 'Esemény', field: 'action', sortable: false, cellRenderer: 'actionCellRenderer' },
    { headerName: 'Típus', field: 'objectType', sortable: false, cellRenderer: 'typeCellRenderer' },
    {
        headerName: 'Érintett hallgató',
        field: 'affectedStudent',
        cellRenderer: 'affectedStudentRenderer',
        sortable: false,
    },
    {
        headerName: 'Érintett kurzus',
        field: 'affectedCourse',
        cellRenderer: 'affectedCourseRenderer',
        sortable: false,
    },
    {
        headerName: 'Változások',
        field: 'operations',
        cellRenderer: 'btnCellRenderer',
        sortable: false,
    },
].map((col) => ({ minWidth: 150, flex: 1, ...col }));
