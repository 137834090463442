import AddBox from '@mui/icons-material/AddBox';
import Info from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Loading, TableButton } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import administrationFeesService from '../../../services/masterData/finances/administrationFees/administrationFees';
import studentsService from '../../../services/masterData/students';
import { IViolations } from '../../../services/ResponseInterfaces';
import { create } from '../../../store/notification';
import { ADMIN_FEE_CATEGORY_INPAYMENT, transformApiErrors } from '../../../utils/AppConst';
import { useStyles } from '../../Administration/SystemLog/EmailLogs/utils';
import UniqueFeeDialogForm, { IAdministrationFee } from './UniqueFeeDialogForm';

export default function UniqueFeeDialog(props: {
    uniqueFeeId?: number;
    setReFresh: React.Dispatch<React.SetStateAction<boolean>>;
    currency?: string;
}) {
    const { setReFresh, uniqueFeeId, currency } = props;
    const [formLoading, setFormLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [form, setForm] = useState<IAdministrationFee>({
        category: ADMIN_FEE_CATEGORY_INPAYMENT,
        students: [],
        uniqueFeeName: '',
        uniqueFeeComment: '',
        amount: '',
        student: '',
    });
    const classes = useStyles();

    useEffect(() => {
        if (uniqueFeeId && open) {
            setFirstLoading(true);
            administrationFeesService
                .getUniqueAdministrationFee(uniqueFeeId)
                .then((res) => setForm(res.data))
                .finally(() => setFirstLoading(false));
        }
    }, [open, uniqueFeeId]);

    const handleSubmit = (form: IAdministrationFee, { setErrors }: FormikHelpers<IAdministrationFee>) => {
        setFormLoading(true);

        const list = Object.entries(form).reduce(
            (prev, [key, value]) => ({
                ...prev,
                [key]:
                    key === 'amount'
                        ? parseInt(value.toString().replace(/\s/g, ''))
                        : Array.isArray(value)
                        ? value.map((elem) => elem?.value || elem)
                        : value?.value || value,
            }),
            {},
        );
        studentsService
            .uniqueFee(list)
            .then(() => {
                setReFresh((prevState) => !prevState);
                dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
                setOpen(false);
            })
            .catch((error: AxiosError) => {
                let message = '';
                if (error.response?.status === 422) {
                    error.response.data.violations?.forEach((elem: IViolations) => {
                        if (elem.propertyPath === 'semester') {
                            message = elem.message;
                        }
                    });
                    dispatch(create({ type: 'error', message: message }));
                    setErrors(transformApiErrors(error.response?.data.violations));
                } else {
                    setOpen(false);
                }
                if (!message) dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => setFormLoading(false));
    };

    return (
        <>
            {!uniqueFeeId ? (
                <Button startIcon={<AddBox />} onClick={() => setOpen(true)} variant="outlined" color="success">
                    Egyedi díj kiszabása
                </Button>
            ) : (
                <TableButton
                    color="info"
                    className={classes.eyeIcon}
                    tooltip={'Egyedi díj részletei'}
                    onClick={() => setOpen(true)}
                >
                    <Info />
                </TableButton>
            )}
            <DialogBasic open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>{uniqueFeeId ? 'Egyedi díj részletei' : 'Egyedi díj kiszabása'}</DialogTitle>
                {!firstLoading ? (
                    <Formik initialValues={form} onSubmit={handleSubmit}>
                        {(formikProps) => {
                            return (
                                <FormikForm>
                                    <DialogContent>
                                        <UniqueFeeDialogForm
                                            setOpen={setOpen}
                                            formLoading={formLoading}
                                            uniqueFeeId={uniqueFeeId}
                                            currency={currency}
                                            {...formikProps}
                                        />
                                    </DialogContent>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                ) : (
                    <Loading noHeight />
                )}
            </DialogBasic>
        </>
    );
}
