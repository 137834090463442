import Download from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DownloadButton } from '@silinfo/front-end-template';
import React from 'react';
import { ICourseView } from '../../../pages/CourseManagement/Courses/types';
import { endpoints } from './../../../utils/endPoints';

export default function Documents({ documents }: ICourseView) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid item xs={12} md={2}>
            <Tooltip title="Syllabus és dokumentumok megtekintése">
                <Button fullWidth sx={{ height: '100%' }} variant="contained" onClick={handleClick}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Typography>Syllabus és dokumentumok</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Download />
                        </Grid>
                    </Grid>
                </Button>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {documents && documents.length > 0 ? (
                    documents.map((document) => (
                        <MenuItem key={document.id}>
                            <DownloadButton
                                downloadName={document.fileName}
                                buttonText={document.name}
                                url={`${endpoints.fileManagement.download}?id=${document.id}&type=${
                                    document.type === 'syllabus' ? 'course_document_syllabus' : 'course_document'
                                }`}
                            />
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem onClick={handleClose}>
                        <i>Nincs megjeleníthető dokumentum</i>
                    </MenuItem>
                )}
            </Menu>
        </Grid>
    );
}
