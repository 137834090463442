import Grid from '@mui/material/Grid';
import { Accordion, CancelButton, Link, Page, SubmitButton, TextField } from '@silinfo/front-end-template';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Field, Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import MCCLoading from '../../../../../../components/MCCLoading';
import { create } from '../../../../../../store/notification';
import floorsService, { IFloorsForm } from '../../../../../../services/masterData/floors';
import buildingsService from '../../../../../../services/masterData/buildings';
import { transformApiErrors } from '../../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../../utils/clientEndPoints';
import { useTenant } from '../../../../../../components/TenantContext';

const FormSchema = Yup.object().shape({
    name: Yup.string()
        .max(16, 'Túl hosszú! A maximumálisan megadható karakterek száma 16.')
        .required('Kötelező megadni'),
});

//If the form is readOnly, the inputs are disabled and the submit button is hidden.

export default function FloorsForm({ readOnly }: { readOnly?: boolean }) {
    const initialForm = {
        building: '',
        name: '',
        buildingName: '',
        campusName: '',
    };

    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [form, setForm] = useState<IFloorsForm>(initialForm);
    const { campusId, buildingId, floorId } = useParams();
    const dispatch = useDispatch();
    const tenant = useTenant();

    const URL = clientEndPoints.md_general_campuses_buildings_floors_list
        .replace(':campusId', campusId?.toString() ?? '')
        .replace(':buildingId', buildingId?.toString() ?? '');

    useEffect(() => {
        if (!buildingId) {
            // Should never happen.
            dispatch(create({ type: 'error', message: 'Hiba hiányzó building id' }));
        } else {
            setPageLoading(true);
            axios
                .all([
                    ...(floorId
                        ? [
                              floorsService
                                  .get(floorId)
                                  .then((response: AxiosResponse) => {
                                      const data = response.data;
                                      setForm({
                                          building: data.building['@id'],
                                          name: data.name,
                                          buildingName: data.building.name,
                                          campusName: data.building.campus.name,
                                      });
                                  })
                                  .catch(console.error),
                          ]
                        : [
                              buildingsService
                                  .get(buildingId)
                                  .then((response: AxiosResponse) => {
                                      const data = response.data;
                                      setForm({
                                          building: data['@id'],
                                          name: '',
                                          buildingName: data.name,
                                          campusName: data.campus.name,
                                      });
                                  })
                                  .catch(console.error),
                          ]),
                ])
                .finally(() => setPageLoading(false));
        }
    }, [buildingId, dispatch, floorId]);

    const handleSubmit = (form: IFloorsForm, { setErrors }: FormikHelpers<IFloorsForm>) => {
        setFormLoading(true);
        (floorId ? floorsService.update(floorId, form) : floorsService.create(form))
            .then(() => dispatch(create({ type: 'success', message: 'Sikeres mentés!', redirect: URL })))
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => setFormLoading(false));
    };

    const title = floorId
        ? isNaN(parseInt(form.name))
            ? form.name + ' megtekintése'
            : form.name + '. szint megtekintése'
        : 'Szint megtekintése';

    const header = {
        project: tenant || 'TAR',
        title: title,
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
            [clientEndPoints.md_general_campuses_buildings_list.replace(':campusId', campusId?.toString() || '')]:
                'Épületek',
            [clientEndPoints.md_general_campuses_buildings_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')]: 'Szintek',
            form: title,
        },
    };

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title} defaultExpanded disableClosing>
                <Formik initialValues={form} onSubmit={handleSubmit} validationSchema={FormSchema}>
                    {({ touched, errors, values, setFieldValue }) => {
                        return (
                            <FormikForm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="campus">
                                            <TextField
                                                label="Campus"
                                                name="campusName"
                                                disabled
                                                value={values?.campusName}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="buildingName">
                                            <TextField
                                                label="Épület"
                                                name="buildingName"
                                                value={values?.buildingName}
                                                disabled
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="name">
                                            <TextField
                                                label="Szint"
                                                name="name"
                                                error={!!(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                                value={values.name}
                                                onChange={(e) => setFieldValue('name', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item container xs={12} spacing={2}>
                                        {!readOnly && (
                                            <Grid item>
                                                <SubmitButton loading={formLoading} type="submit">
                                                    Mentés
                                                </SubmitButton>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Link to={URL}>
                                                <CancelButton>Mégsem</CancelButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}
