import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';

const countriesService = {
    ...crudTemplate(endpoints.countries.main),
    countriesWithUniversities: () => API.get(endpoints.countries.countriesWithUniversities, {}),
    countriesWithCampuses: () => API.get(endpoints.countries.countriesWithCampuses, {}),
};

export default countriesService;
