import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import Form from '../../../../components/Form/Form';
import { actualStatusOptions } from '../../../../pages/MasterData/General/Students/Form/options';
import studentRequestService from '../../../../services/studentServices/studentRequestService';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import ActualStatusDialogForm from './ActualStatusDialogForm';

export interface ActualStatusForm extends IForm {
    actualStatus?: string;
    requestedStatus?: string;
    reason?: string;
}

export const greySettingIcon = (): ReactJSXElement => {
    return (
        <IconButton style={{ color: 'grey' }}>
            <SettingsIcon />
        </IconButton>
    );
};

const ActualStatusDialog = ({
    actualStatus,
    enabled,
    readOnly = false,
}: {
    actualStatus: string;
    enabled: boolean;
    readOnly?: boolean;
}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [localEnabled, setLocalEnabled] = useState(enabled);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const initialValues: ActualStatusForm = {
        actualStatus,
        requestedStatus: actualStatusOptions.find(({ value }) => value !== actualStatus)?.value || actualStatus,
        reason: '',
    };

    const handleSubmit = (form: ActualStatusForm) => {
        setLoading(true);
        return studentRequestService
            .requestChangeActualStatus(form)
            .then(() => {
                setLocalEnabled(false);
                setOpen(false);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            {!readOnly ? (
                <>
                    {localEnabled ? (
                        <Tooltip title="Státusz változtatás">
                            <IconButton onClick={handleOpen} color="primary">
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Már van azonos típusú nyitott kérvényed">{greySettingIcon()}</Tooltip>
                    )}
                </>
            ) : (
                <></>
            )}

            <Dialog open={open} onClose={handleClose}>
                <Form fields={[]} onSubmit={handleSubmit} initialValues={initialValues} hideButtons>
                    {(props) => (
                        <ActualStatusDialogForm
                            actualStatus={actualStatus}
                            handleClose={handleClose}
                            loading={loading}
                            readOnly={readOnly}
                            {...props}
                        />
                    )}
                </Form>
            </Dialog>
        </>
    );
};

export default ActualStatusDialog;
