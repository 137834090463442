import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../../components/MCCLoading';
import IEntityBase from '../../../../../interface/IEntityBase';
import trainingLevelsService from '../../../../../services/masterData/trainingLevels';
import trainingProgramsService from '../../../../../services/masterData/trainingPrograms';
import { create } from '../../../../../store/notification';
import { Option, paginatorInfoBuild } from '../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { IInfo } from '../../../../../utils/Interfaces/IInfo';
import { initialTrainingLevel } from '../../../../../utils/Interfaces/initialObjects';
import { IForm, TrainingLevel } from '../../../../../utils/Interfaces/interfaces';
import { ArchiveCellRenderer } from './CellRenderers/ArchiveCellRenderer';
import { BtnCellRenderer } from './CellRenderers/BtnCellRenderer';
import { CollegeYearsCellRenderer } from './CellRenderers/CollegeYearsCellRenderer';
import fields from './fields';
import { RootState } from '../../../../../store';
import trainingLevelService from '../../../../../services/masterData/trainingLevels';
import { TrainingProgramLeaderCellRenderer } from './CellRenderers/TrainingProgramLeaderCellRenderer';
import { useTenant } from '../../../../../components/TenantContext';

export const YEAR_OPTIONS = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
];

export const makeOptionsFromHydra = (data: [], url: string): Option[] => {
    return data.map((elem: { id: number | string; name: string }) => {
        return {
            value: `/${url}/${elem.id}`,
            label: elem.name,
        };
    });
};

export const makeOptionsStartFromHydra = (data: [], url: string): string[] => {
    return data.map((elem: { id: string }) => {
        return `/${url}/${elem.id}`;
    });
};

export const makeOptionsStart = (data: []): number[] => {
    return data.map((elem: { id: number }) => {
        return elem.id;
    });
};

const initialInfo: IInfo<IForm> = {
    filter: { archive: ['0'] },
    sort: {
        name: 'asc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

export default function TrainingPrograms() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [trainingLevel, setTrainingLevel] = useState<TrainingLevel>(initialTrainingLevel);
    const [collegeYears, setCollegeYears] = useState<IEntityBase[]>([]);
    const dispatch = useDispatch();
    const [info, setInfo] = useState(initialInfo);
    const { id } = useParams();
    const [levelName, setLevelName] = useState('');
    const tenant = useTenant();

    const fetchData = useCallback(
        (form: IInfo<IForm>) => {
            setTableLoading(true);
            if (id)
                axios
                    .all([
                        trainingLevelsService.get(id).then((response) => setTrainingLevel(response.data)),
                        trainingProgramsService
                            .collegeYears()
                            .then((response: AxiosResponse) => setCollegeYears(response.data['hydra:member'] || [])),
                        trainingProgramsService
                            .filterByTrainingProgram({ ...form.filter, ...paginatorInfoBuild(form) }, id)
                            .then((d: AxiosResponse) => {
                                const totalItems = d.data['hydra:totalItems'];
                                setData(d.data['hydra:member']);
                                setInfo((prev) => ({
                                    ...prev,
                                    metadata: {
                                        'allCount': totalItems,
                                        'filteredCount': totalItems,
                                        'lastPage': totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                                        'page': info.page,
                                        'perpage': info.perpage,
                                    },
                                }));
                            }),
                    ])
                    .catch(() =>
                        dispatch(
                            create({
                                type: 'error',
                                message: 'Hiba a betöltés során!',
                            }),
                        ),
                    )
                    .finally(() => {
                        setTableLoading(false);
                        setLoading(false);
                    });
            trainingLevelService.trainingLevels().then((response) => {
                setLevelName(response.data.find((element: Option) => element.value == id).label);
            });
        },
        [dispatch, id, info.page, info.perpage],
    );

    useEffect(() => {
        fetchData(initialInfo);
    }, [fetchData]);

    const header = {
        project: tenant || 'TAR',
        title: 'Képzési programok',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: levelName + ' szint',
            [clientEndPoints.md_training_levels_programs_list.replace(':id', id?.toString() || '')]:
                'Képzési programok',
        },
    };

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    const onSubmit = (form: IForm) => {
        setTableLoading(true);

        setInfo((prevState) => ({
            ...prevState,
            filter: form,
        }));

        const filter = { ...form, ...paginatorInfoBuild(info) };
        return trainingProgramsService
            .filterByTrainingProgram(filter, id)
            .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
            .finally(() => {
                setTableLoading(false);
                setLoading(false);
            });
    };

    const loadTable = () => {
        setLoading(true);

        axios
            .all([
                trainingProgramsService
                    .collegeYears()
                    .then((response: AxiosResponse) => setCollegeYears(response.data['hydra:member'] || [])),
                trainingProgramsService
                    .filterByTrainingProgram({}, id)
                    .then((response: AxiosResponse) => setData(response.data['hydra:member'] || [])),
            ])
            .finally(() => setLoading(false));
    };

    if (loading) {
        return <MCCLoading />;
    }

    const newButton =
        trainingLevel.archive || user.archive
            ? undefined
            : {
                  target: clientEndPoints.md_training_levels_programs_create.replace(':id', id ? id : ''),
                  text: 'Képzési program hozzáadása',
              };

    return (
        <FormikListPage
            header={header}
            filter={{
                initialValues: {
                    trainingLevelName: trainingLevel.name,
                    archive: ['0'],
                },
                onSubmit,
                fields: fields(collegeYears),
            }}
            table={{
                columnDefs: [
                    { headerName: 'Képzési program', field: 'name', minWidth: 200 },
                    { headerName: 'Év', field: 'duration' },
                    {
                        headerName: 'Évfolyam',
                        field: 'collegeYears',
                        cellRenderer: 'collegeYearCellRenderer',
                        sortable: false,
                    },
                    {
                        headerName: 'Archiválva?',
                        field: 'archive',
                        cellRenderer: 'archiveCellRenderer',
                        sortable: false,
                    },
                    {
                        headerName: 'Képzési program vezető',
                        field: 'trainingProgramLeader',
                        cellRenderer: 'trainingProgramLeaderCellRenderer',
                        minWidth: 200,
                    },
                    {
                        headerName: 'Műveletek',
                        field: 'operations',
                        cellRenderer: 'btnCellRenderer',
                        sortable: false,
                        minWidth: 150,
                    },
                ].map((col) => ({ minWidth: 100, flex: 1, ...col })),
                frameworkComponents: {
                    btnCellRenderer: (params: GridRenderCellParams) => (
                        <BtnCellRenderer loadTable={loadTable} readonly={!!user.archive} {...params} />
                    ),
                    collegeYearCellRenderer: CollegeYearsCellRenderer,
                    archiveCellRenderer: ArchiveCellRenderer,
                    trainingProgramLeaderCellRenderer: TrainingProgramLeaderCellRenderer,
                },
                newButton: newButton,
                rowData: data,
                loading: tableLoading,
                searchfunc: fetchData,
                info: info,
                server: true,
                initialState: {
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                },
                setInfo: setValueByKey,
            }}
        />
    );
}
