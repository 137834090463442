import PreviewTemplate from '../../../components/PreviewTemplate';
import { endpoints } from '../../../utils/endPoints';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { setRefreshLocalStorage } from '../../../components/PreviewTemplate/utils';
import selfEvaluationReportService from '../../../services/selfEvalution/report';

const keyPrefix = 'selfEvaluationReportPreview';

export default function ReportPreview() {
    const { id } = useParams<{ id: string }>();
    const handleSubmit = useCallback(
        async (values: IForm, formIndex: number, finalization?: boolean) => {
            await selfEvaluationReportService.writeFill(id ?? '0', values, formIndex, !!finalization);
            return setRefreshLocalStorage();
        },
        [id],
    );

    if (!id) return null;

    return (
        <PreviewTemplate
            type="selfEvaluation"
            url={endpoints.selfEvaluation.report.preview(id)}
            keyPrefix={keyPrefix}
            onSubmit={handleSubmit}
        />
    );
}
