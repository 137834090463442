/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { endpoints } from '../../../../utils/endPoints';
import { Admission, SelectedRow } from '../types';
import API from '../../../../api';
import Typography from '@mui/material/Typography';
import ProgramSelectDialog from '../Dialogs/ProgramSelectDialog';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import DocumentsDialog from '../Dialogs/DocumentsDialog';
import MCCLoading from '../../../../components/MCCLoading';
import { useDispatch } from 'react-redux';
import { create } from '../../../../store/notification';
import AdmissionMobileListElement from './AdmissionMobileListElement';
import MobileDocumentDialog from '../Dialogs/MobileDocumentDialog';
import MobileProgramSelectDialog from '../Dialogs/MobileProgramSelectDialog';
import axios, { AxiosError } from 'axios';
import { IViolations } from '@silinfo/front-end-template';
import FinalizeDialog from '../Dialogs/FinalizeDialog';
import AdmissionsStyle from '../Admissions.module.scss';
import AdmissionsListStyle from './AdmissionsList.module.scss';
import AdmissionListElement from './AdmissionListElement';

export default function AdmissionListMobile({
    isRegistrated,
    token,
    selectState,
    matches,
}: {
    isRegistrated: boolean;
    token: string;
    selectState: [SelectedRow | null, React.Dispatch<React.SetStateAction<SelectedRow | null>>];
    matches: boolean;
}) {
    const dispatch = useDispatch();
    const [admissions, setAdmissions] = useState<Admission[]>();
    const [refresh, setRefresh] = useState(false);
    const [openProgramModal, setOpenProgramModal] = useState<boolean>(false);
    const [currentSerial, setCurrentSerial] = useState<number | null>(null);
    const [currentAdmission, setCurrentAdmission] = useState<Admission | null>(null);
    const [tempSerial, setTempSerial] = useState<number | null>(null);
    const [saveBody, setSaveBody] = useState<{
        serial: number | null;
        campusId: string | number;
        programId: string | number;
        isNewAdmission: boolean;
    }>();
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [admissionMap, setAdmissionMap] = useState<({ campus: number; program: number } | undefined)[]>([]);
    const [openDocumentsModal, setOpenDocumentsModal] = useState<boolean>(false);
    const [openFinalizeModal, setOpenFinalizeModal] = useState<boolean>(false);
    const [currentAdmissionDisabled, setCurrentAdmissionDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [programSelectLoading, setProgramSelectLoading] = useState<boolean>(false);

    const handleLoading = (loading: boolean) => setLoading(loading);

    const handleRefresh = useCallback(() => {
        setLoading(true);
        setRefresh((prevRefresh) => !prevRefresh);
        setLoading(false);
    }, [setRefresh]);

    const handleOpenDialog = (serial: number | null, index: number) => {
        setCurrentSerial(serial);
        setTempSerial(index);
        setOpenProgramModal(true);
    };

    const handleOpenDocumentDialog = (serial: number | null, isAdmissionDisabled: boolean) => {
        setCurrentSerial(serial);
        setOpenDocumentsModal(true);
        setCurrentAdmissionDisabled(isAdmissionDisabled);
    };

    const handleOpenFinalizeDialog = (admission: Admission) => {
        setCurrentAdmission(admission);
        setOpenFinalizeModal(true);
    };

    const refreshPage = () => {
        const endpoint = isRegistrated
            ? endpoints.admission.admissionListMcc
            : endpoints.admission.admissionListToken.replace('{token}', token);
        const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
        request.then((response) => {
            const mappedAdmissions = Object.keys(response.data).map((e) => response.data[e]);
            setAdmissions(mappedAdmissions);
            const addedAdmissions = mappedAdmissions
                .map((e) => {
                    if (e.campus) {
                        return { campus: e.campusId, program: e.trainingProgramId };
                    }
                })
                .filter((el) => el);
            setAdmissionMap(addedAdmissions);
            setLoading(false);
        });
    };

    const handleSave = useCallback(() => {
        const endpoint = isRegistrated
            ? endpoints.admission.saveProgramMcc.replace('{serial}', `${saveBody?.serial}`)
            : endpoints.admission.saveProgramToken
                  .replace('{token}', `${token}`)
                  .replace('{serial}', `${saveBody?.serial}`);
        const request = isRegistrated
            ? API.post(endpoint, {
                  campus: saveBody?.campusId,
                  trainingProgram: saveBody?.programId,
              })
            : axios.post(endpoint, {
                  campus: saveBody?.campusId,
                  trainingProgram: saveBody?.programId,
              });
        request
            .then(() => {
                setOpenConfirm(false);
                handleRefresh();

                setProgramSelectLoading(false);
                setOpenProgramModal(false);
            })
            .catch((error: AxiosError) => {
                let message = '';
                error?.response?.data.violations?.forEach((elem: IViolations) => {
                    if (elem.propertyPath === 'expired') {
                        message = elem.message;
                    }
                });
                dispatch(create({ message: message, type: 'error' }));
            })
            .finally(() => setProgramSelectLoading(false));
    }, [isRegistrated, saveBody?.serial, saveBody?.campusId, saveBody?.programId, token, handleRefresh, dispatch]);

    useEffect(() => {
        if (saveBody && saveBody.isNewAdmission) {
            handleSave();
        }
    }, [saveBody, handleSave]);

    const handleSaveBody = async (
        serial: null | number,
        campusId: string | number,
        programId: string | number,
        isNewAdmission: boolean,
    ) => {
        setSaveBody({ serial, campusId, programId, isNewAdmission });
        if (!isNewAdmission) {
            setOpenProgramModal(false);
            setOpenConfirm(true);
        } else {
            setProgramSelectLoading(true);
        }
    };

    const checkIsProgramAlreadyAdded = (campusId: number, programId: number) => {
        const admission = admissionMap.find((e) => e?.campus === campusId && e.program === programId);
        if (admission) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        (() => {
            setLoading(true);
            const endpoint = isRegistrated
                ? endpoints.admission.admissionListMcc
                : endpoints.admission.admissionListToken.replace('{token}', token);
            const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
            request.then((response) => {
                const mappedAdmissions = Object.keys(response.data).map((e) => response.data[e]);
                setAdmissions(mappedAdmissions);
                const addedAdmissions = mappedAdmissions
                    .map((e) => {
                        if (e.campus) {
                            return { campus: e.campusId, program: e.trainingProgramId };
                        }
                    })
                    .filter((el) => el);
                setAdmissionMap(addedAdmissions);
                setLoading(false);
            });
        })();
    }, [isRegistrated, token, refresh]);

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Grid item container className={`${AdmissionsListStyle.listContainer}`} xs={12}>
            {matches ? (
                <ProgramSelectDialog
                    openState={[openProgramModal, setOpenProgramModal]}
                    serialState={[currentSerial, setCurrentSerial]}
                    tempSerialState={[tempSerial, setTempSerial]}
                    loadingState={[programSelectLoading, setProgramSelectLoading]}
                    token={token}
                    handleSaveBody={handleSaveBody}
                    refresh={handleRefresh}
                    checkIsProgramAlreadyAdded={checkIsProgramAlreadyAdded}
                />
            ) : (
                <MobileProgramSelectDialog
                    openState={[openProgramModal, setOpenProgramModal]}
                    serialState={[currentSerial, setCurrentSerial]}
                    tempSerialState={[tempSerial, setTempSerial]}
                    loadingState={[programSelectLoading, setProgramSelectLoading]}
                    token={token}
                    handleSaveBody={handleSaveBody}
                    refresh={handleRefresh}
                    checkIsProgramAlreadyAdded={checkIsProgramAlreadyAdded}
                />
            )}
            {matches ? (
                <DocumentsDialog
                    openState={[openDocumentsModal, setOpenDocumentsModal]}
                    serialState={[currentSerial, setCurrentSerial]}
                    disabledState={[currentAdmissionDisabled, setCurrentAdmissionDisabled]}
                    isRegistrated={isRegistrated}
                    token={token}
                    refresh={handleRefresh}
                    admission={admissions?.find((e) => e.serial === currentSerial)}
                />
            ) : (
                <MobileDocumentDialog
                    openState={[openDocumentsModal, setOpenDocumentsModal]}
                    serialState={[currentSerial, setCurrentSerial]}
                    disabledState={[currentAdmissionDisabled, setCurrentAdmissionDisabled]}
                    isRegistrated={isRegistrated}
                    token={token}
                    refresh={handleRefresh}
                />
            )}
            {openFinalizeModal && (
                <FinalizeDialog
                    openState={[openFinalizeModal, setOpenFinalizeModal]}
                    serialState={[currentSerial, setCurrentSerial]}
                    disabledState={[currentAdmissionDisabled, setCurrentAdmissionDisabled]}
                    token={token}
                    admission={currentAdmission}
                    refresh={handleRefresh}
                />
            )}
            <ConfirmDialog
                openState={[openConfirm, setOpenConfirm]}
                loadingState={[programSelectLoading, setProgramSelectLoading]}
                handleSave={handleSave}
            />
            {matches && (
                <Grid item container sx={{ marginBottom: '1rem' }}>
                    <Typography className={`${AdmissionsStyle.selectedProgramsTitle} ${AdmissionsStyle.bolder}`}>
                        Kiválasztott képzések
                    </Typography>
                </Grid>
            )}
            {admissions?.map((admission, index) => {
                const firstItem = index === 0 && admission.trainingProgramId !== null;
                const lastItem =
                    admission.trainingProgramId !== null &&
                    (admissions[index + 1]?.trainingProgram === null || index + 1 === admissions.length);
                const isPreviousProgramNull = index !== 0 && admissions[index - 1].trainingProgram === null;
                const isProgramSelected = admission.trainingProgramId !== null;

                return matches ? (
                    <AdmissionListElement
                        key={index}
                        admission={admission}
                        selectState={selectState}
                        isRegistrated={isRegistrated}
                        token={token}
                        index={index}
                        firstItem={firstItem}
                        lastItem={lastItem}
                        isProgramSelected={isProgramSelected}
                        isPreviousProgramNull={isPreviousProgramNull}
                        handleOpenDialog={() => handleOpenDialog(admission.serial, index + 1)}
                        handleOpenDocumentDialog={handleOpenDocumentDialog}
                        handleOpenFinalizeDialog={handleOpenFinalizeDialog}
                        handleRefresh={refreshPage}
                        handleLoading={handleLoading}
                    />
                ) : (
                    <AdmissionMobileListElement
                        key={index}
                        admission={admission}
                        selectState={selectState}
                        isRegistrated={isRegistrated}
                        token={token}
                        index={index}
                        firstItem={firstItem}
                        lastItem={lastItem}
                        isProgramSelected={isProgramSelected}
                        isPreviousProgramNull={isPreviousProgramNull}
                        handleOpenDialog={() => handleOpenDialog(admission.serial, index + 1)}
                        handleOpenDocumentDialog={handleOpenDocumentDialog}
                        handleOpenFinalizeDialog={handleOpenFinalizeDialog}
                        handleRefresh={refreshPage}
                        handleLoading={handleLoading}
                    />
                );
            })}
        </Grid>
    );
}
