import { Option } from './AppConst';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { create } from '../store/notification';
import { AxiosResponse } from 'axios';
import { IForm } from './Interfaces/interfaces';

type ReturnType = Option & { extraVal?: string; extraVal2?: string } & Record<string, string>;

const sortOptions = (labelField: string) => (a: Record<string, string>, b: Record<string, string>) => {
    if (a.archive || b.archive) {
        if (a.archive && b.archive) return a[labelField].localeCompare(b[labelField]);
        return b.archive ? -1 : 1;
    }
    return a[labelField].localeCompare(b[labelField]);
};

export default function useEntityOptions(
    service: { filter: (form: IForm) => Promise<AxiosResponse> },
    labelField = 'name',
    valueField = '@id' || 'id',
    plusField?: string,
    plusField2?: string,
    ...params: string[]
): ReturnType[] {
    const [state, setState] = useState<ReturnType[]>([]);
    const dispatch = useDispatch();
    const stringifiedParams = JSON.stringify(params); // https://github.com/facebook/react/issues/14476 - dependency array miatt
    useEffect(() => {
        service
            .filter({})
            .then((response) => {
                const data = response.data['hydra:member'] || response.data['data'];
                const options = data.map((row: Record<string, string>) => {
                    const ret: ReturnType = {
                        value: row[valueField],
                        label: row[labelField],
                    };
                    if (plusField) {
                        ret.extraVal = row[plusField];
                    }
                    if (plusField2) {
                        ret.extraVal2 = row[plusField2];
                    }

                    const reconvertedParams = JSON.parse(stringifiedParams);
                    for (const param of reconvertedParams) {
                        ret[param] = row[param];
                    }

                    return ret;
                });
                setState(options);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Hiba az opciók betöltése során' })));
    }, [dispatch, labelField, stringifiedParams, plusField, plusField2, service, valueField]);

    return state;
}
export function useEntityOptionsArchive(
    service: { filter: () => Promise<AxiosResponse> },
    labelField = 'name',
    valueField = '@id' || 'id',
    plusField?: string,
): (Option & { extraVal?: string })[] {
    const [state, setState] = useState<(Option & { extraVal?: string })[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        service
            .filter()
            .then((response) => {
                const data = response.data['hydra:member'] || response.data['data'];
                const options = data.sort(sortOptions(labelField)).map((row: Record<string, string>) => {
                    const ret: Option & { extraVal?: string } = {
                        value: row[valueField],
                        label: row[labelField] + (row.archive ? ' (archive)' : ''),
                    };
                    if (plusField) {
                        ret.extraVal = row[plusField];
                    }
                    return ret;
                });
                setState(options);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Hiba az opciók betöltése során' })));
    }, [dispatch, service, labelField, valueField, plusField]);

    return state;
}
