import Grid from '@mui/material/Grid';
import Form from '../../../../components/Form/Form';
import { Input, Link } from '@silinfo/front-end-template';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { calculationTypeOptions, completionOptions, scholarshipOptions } from '../utils';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { Option } from '../../../../utils/AppConst';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { FormikProps } from 'formik';
import instance from '../../../../api';
import { endpoints } from '../../../../utils/endPoints';

const fields: Input[] = [
    {
        name: 'name',
        label: 'Csoport neve',
        format: {
            xs: 12,
        },
    },
];

interface IGroupForm extends IForm {
    name: string;
    calculationType: 'automatic' | 'manual';
    completionType: 'automatic' | 'monthly';
    scholarshipType: 'professional' | 'project';
}

interface RadioButtonProps {
    name: string;
    label: string;
    options: Option[];
}

const radioButtons: RadioButtonProps[] = [
    {
        name: 'scholarshipType',
        label: 'Ösztöndíj típusa',
        options: scholarshipOptions,
    },
    {
        name: 'calculationType',
        label: 'Kalkuláció típusa',
        options: calculationTypeOptions,
    },
    {
        name: 'completionType',
        label: 'Teljesítés típusa',
        options: completionOptions,
    },
];

const handleChange =
    (formProps: FormikProps<IGroupForm>, radioButton: RadioButtonProps) => (e: React.ChangeEvent<HTMLInputElement>) =>
        formProps.setFieldValue(radioButton.name, e.target.value);

const initialValue: IGroupForm = {
    name: '',
    calculationType: 'automatic',
    completionType: 'automatic',
    scholarshipType: 'professional',
};

interface CreateProps {
    setId: (id: string) => void;
}

export default function Create({ setId }: CreateProps) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { semesterId, id } = useParams();
    const [used, setUsed] = useState(true);
    const usedOrNotNew = id ? used : false;

    const handleSubmit = useCallback(
        async (values: IGroupForm) => {
            if (!semesterId) return;
            setLoading(true);
            const url = id
                ? endpoints.scholarships.group(semesterId, id)
                : endpoints.scholarships.createGroup(semesterId);
            return instance
                .post(url, values)
                .then(({ data }) => {
                    setId(data.data.id);
                })
                .finally(() => setLoading(false));
        },
        [id, semesterId, setId],
    );

    const handleSubmitAndGoBack = useCallback(
        (values: IGroupForm) => async () => {
            if (!usedOrNotNew) {
                handleSubmit(values).then(() => {
                    navigate(clientEndPoints.scholarship_group_management);
                });
            } else {
                navigate(clientEndPoints.scholarship_group_management);
            }
        },
        [handleSubmit, navigate, usedOrNotNew],
    );

    const getter = useCallback(
        async (id?: string | number) => {
            const { data } = await instance.get(endpoints.scholarships.group(semesterId || '', id as string));
            setUsed(data.data.used);
            return data;
        },
        [semesterId],
    );

    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
                <Form
                    fields={fields}
                    onSubmit={handleSubmit}
                    hideButtons
                    initialValues={initialValue}
                    getterFunction={getter}
                    readonly={usedOrNotNew}
                >
                    {(formProps) => (
                        <>
                            {radioButtons.map((radioButton) => (
                                <Grid item xs={12} key={radioButton.name}>
                                    <FormLabel>{radioButton.label}</FormLabel>
                                    <RadioGroup
                                        row
                                        value={formProps.values[radioButton.name]}
                                        onChange={handleChange(formProps, radioButton)}
                                    >
                                        {radioButton.options.map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                control={<Radio />}
                                                labelPlacement="end"
                                                disabled={usedOrNotNew}
                                                {...option}
                                            />
                                        ))}
                                    </RadioGroup>
                                </Grid>
                            ))}
                            <Grid item container xs={12} spacing={2}>
                                {!usedOrNotNew && (
                                    <Grid item>
                                        <LoadingButton variant="contained" type="submit" loading={loading}>
                                            Mentés
                                        </LoadingButton>
                                    </Grid>
                                )}
                                <Grid item>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={handleSubmitAndGoBack(formProps.values)}
                                        loading={loading}
                                    >
                                        {!usedOrNotNew ? 'Mentés és vissza a listaoldalra' : 'Vissza a listaoldalra'}
                                    </LoadingButton>
                                </Grid>
                                {!usedOrNotNew && (
                                    <Grid item>
                                        <Link to={clientEndPoints.scholarship_group_management}>
                                            <Button variant="outlined">Mégsem</Button>
                                        </Link>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                </Form>
            </Grid>
        </Grid>
    );
}
