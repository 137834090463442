import VisibilityIcon from '@mui/icons-material/Visibility';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { getJWTToken } from '../../../../utils/AuthHelper';
import { endpoints } from '../../../../utils/endPoints';
import CircularProgress from '@mui/material/CircularProgress';
import { DataFile } from '../..';

interface PreviewUniversityIndexProps {
    file: DataFile;
}

export default function PreviewUniversityIndex({ file }: PreviewUniversityIndexProps) {
    const [loading, setLoading] = useState(false);
    const [documentUrl, setDocumentUrl] = useState('');

    useEffect(() => {
        if (file?.id && file?.filename) {
            const AUTH_TOKEN = getJWTToken();
            setLoading(true);
            axios
                .get(`${endpoints.fileManagement.download}?id=${file.id.toString()}&type=university-index`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${AUTH_TOKEN}`,
                    },
                })
                .then((response) => {
                    const blob = response.data;
                    setDocumentUrl(URL.createObjectURL(blob));
                })
                .finally(() => setLoading(false));
        }
    }, [file]);

    const handleOpen = useCallback(() => window.open(documentUrl, '_blank'), [documentUrl]);

    return (
        <Grid item>
            <IconButton
                color="primary"
                sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                size="small"
                onClick={handleOpen}
                disabled={loading}
            >
                <Tooltip title={loading ? 'Betöltés...' : 'Megtekintés'}>
                    {loading ? <CircularProgress size={24} /> : <VisibilityIcon />}
                </Tooltip>
            </IconButton>
        </Grid>
    );
}
