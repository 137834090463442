import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import { LoadingButton } from '@mui/lab';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Tooltip from '@mui/material/Tooltip';
import { TableButton } from '@silinfo/front-end-template';
import { useCallback, useContext, useState } from 'react';
import instance from '../../../api';
import RefreshContext from '../../../studentPages/SelfAssessment/RefreshContextProvider';

interface DeleteButtonProps {
    archived: boolean;
    deletable: boolean;
    url: (id: string) => string;
    groupName: string;
    id: number;
}

interface IState {
    tooltip: string;
    Icon: OverridableComponent<SvgIconTypeMap>;
    color: 'error' | 'success' | 'info';
    confirmationText: string;
}

const state = (deletable: boolean, archived: boolean, groupName: string): IState =>
    deletable
        ? {
              tooltip: 'Törlés',
              Icon: DeleteIcon,
              color: 'error',
              confirmationText: 'Biztosan törlöd a(z) ' + groupName + ' csoportot?',
          }
        : archived
        ? {
              tooltip: 'Archiválás visszavonása',
              color: 'success',
              Icon: UnarchiveRoundedIcon,
              confirmationText: 'Biztosan visszavonja a(z) ' + groupName + ' archiválását?',
          }
        : {
              tooltip: 'Archiválás',
              Icon: ArchiveIcon,
              color: 'error',
              confirmationText: 'Biztosan archiválni szeretnéd a(z) ' + groupName + ' csoportot?',
          };

export default function DeleteButton({ id, deletable, archived, groupName, url }: DeleteButtonProps) {
    const [open, setOpen] = useState(false);
    const { Icon, tooltip, confirmationText, color } = state(deletable, archived, groupName);
    const [loading, setLoading] = useState(false);
    const refresh = useContext(RefreshContext);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleSubmit = useCallback(async () => {
        setLoading(true);
        try {
            await (deletable ? instance.delete(url('' + id)) : instance.post(url('' + id)));
            refresh();
            setOpen(false);
        } finally {
            setLoading(false);
        }
    }, [deletable, id, refresh, url]);

    return (
        <Grid item>
            <Tooltip title={tooltip}>
                <TableButton color={color} onClick={handleOpen}>
                    <Icon />
                </TableButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{tooltip}</DialogTitle>
                <DialogContent style={{ marginTop: '16px' }}>
                    <DialogContentText>{confirmationText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Nem
                    </Button>
                    <LoadingButton variant="contained" onClick={handleSubmit} loading={loading}>
                        Igen
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
