import { Input } from '@silinfo/front-end-template';
import { Option } from '../../../utils/AppConst';
import { Language } from '../../../utils/Interfaces/interfaces';
import { NotificationKey, NotificationState } from './types';

export const createFields = (value: string): Input[] => [
    {
        name: 'types.' + value + '.message.identification',
        label: 'Esemény azonosítója',
    },
    {
        name: 'types.' + value + '.message.name',
        label: 'Esemény megnevezése',
    },
];

export const languages: { value: Language; label: 'Magyar' | 'Angol' }[] = [
    { value: 'hu', label: 'Magyar' },
    { value: 'en', label: 'Angol' },
];

export const notificationTypesArray: NotificationKey[] = [
    'TYPE_POPUP',
    'TYPE_POPUP_ONCE',
    'TYPE_SYSTEM_MESSAGE',
    'TYPE_EMAIL',
];

export const notificationTypes: Option<NotificationKey>[] = [
    {
        value: 'TYPE_POPUP',
        label: 'Pop-up',
    },
    {
        value: 'TYPE_POPUP_ONCE',
        label: 'Egyszeri pop-up',
    },
    {
        value: 'TYPE_SYSTEM_MESSAGE',
        label: 'Rendszerüzenet',
    },
    {
        value: 'TYPE_EMAIL',
        label: 'E-mail',
    },
];

export const initialNotificationState: NotificationState = {
    value: false,
    message: {
        identification: '',
        name: '',
        translations: {
            hu: {
                title: '',
                content: '',
            },
            en: {
                title: '',
                content: '',
            },
        },
    },
};

export const generateRandomOptions = (): Option[] => {
    const options = [];
    for (let i = 0; i < Math.floor(Math.random() * 10); i++) {
        const text = (Math.random() + 1).toString(36).substring(7);
        options.push({
            value: '%' + text.toUpperCase() + '%',
            label: text,
        });
    }
    return options;
};
