import useAccessCheck, { userHasAccess } from '../../utils/useAccessCheck';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

// This component is used to check if the user has access to the given role.

/**
 * @param children
 * @param endpoints Funkciójogok, amelyek ha vannak a usernél , akkor true-t ad vissza
 * @param endpointsInverse Funkciójogok, amelyek ha vannak a usernél, akkor false-t ad vissza
 * @param componentToRenderIfAccessDenied
 * @constructor
 */
export default function EndpointProvider({
    children,
    endpoints,
    forceShow,
    endpointsInverse,
    componentToRenderIfAccessDenied,
}: {
    children: React.ReactNode;
    endpoints: string[];
    forceShow?: boolean;
    endpointsInverse?: string[];
    componentToRenderIfAccessDenied?: JSX.Element;
}): JSX.Element {
    const hasAccess = useAccessCheck(endpoints);
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const { user } = useSelector((state: RootState) => state.auth);
    if (!(user && accessTree)) return <></>;
    const hasAcceessInverse =
        endpointsInverse && endpointsInverse.length > 0 ? userHasAccess(endpointsInverse, user, accessTree) : false;

    if (hasAcceessInverse) {
        return <></>;
    }

    return hasAccess || !!forceShow ? <>{children}</> : componentToRenderIfAccessDenied || <></>;
}
