import { useParams } from 'react-router-dom';
import PreviewTemplate from '../../../components/PreviewTemplate';
import { endpoints } from '../../../utils/endPoints';

export default function QuestionnairePreview() {
    const { tokenId, serial } = useParams() as { tokenId: string; serial: string };
    const isRegistrated = window.location.pathname.includes('/mcc');
    const endpoint = isRegistrated
        ? endpoints.admission.previewMccQuestionnaire(serial)
        : endpoints.admission.previewTokenQuestionnaire(serial, tokenId);

    return <PreviewTemplate url={endpoint} keyPrefix="student_preview" type="admission" />;
}
