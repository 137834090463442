import { GridRenderCellParams } from '@mui/x-data-grid';
import DeleteButton from '../DeleteButton';
import { endpoints } from '../../../../utils/endPoints';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import EditMember from './EditMember';

export default function OperationCellRenderer(params: GridRenderCellParams) {
    const { id } = useParams();
    const deleteUrl = useCallback((rowId: string) => endpoints.scholarships.deleteGroup(id || '', '' + rowId), [id]);
    const groupName = `${params.row.campus.label} - ${params.row.trainingLevel.label} - ${params.row.trainingProgram.label}`;

    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid item>
                <EditMember row={params.row} />
            </Grid>
            <Grid item>
                <DeleteButton
                    url={deleteUrl}
                    deletable
                    archived={params.row.archived}
                    id={params.row.id}
                    groupName={groupName}
                />
            </Grid>
        </Grid>
    );
}
