import instance from '../../api';
import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const courseDocumentService = {
    filter: (courseId: number | string, courseTenant: string | null, form: IForm) => {
        const endpoint = endpoints.courseManagement.documents.list.replace('{courseId}', courseId.toString());

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant, {
                params: form,
            });
        } else {
            return instance.get(endpoint, {
                params: form,
            });
        }
    },
    create: (courseId: number | string, form: IForm, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.documents.create.replace('{courseId}', courseId.toString());

        if (courseTenant) {
            return API.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return API.post(endpoint, form);
        }
    },
    update: (courseId: number | string, documentId: number | string, form: IForm, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.documents.update
            .replace('{courseId}', courseId.toString())
            .replace('{documentId}', documentId.toString());

        if (courseTenant) {
            return API.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return API.post(endpoint, form);
        }
    },
    get: (courseId: number, id: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.documents.get
            .replace('{courseId}', courseId.toString())
            .replace('{documentId}', id.toString());

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
    delete: (courseId: number, documentId: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.documents.delete
            .replace('{courseId}', courseId.toString())
            .replace('{documentId}', documentId.toString());

        if (courseTenant) {
            return API.delete(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return API.delete(endpoint);
        }
    },
};

export default courseDocumentService;
