import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DifferentCellDifferentTooltipCellRenderer } from '../../utils/AppConstElements';

interface InstructorAutoCompleteViewWithEmail {
    value: string;
    label: string;
    email: string;
}

type InstructorCellRendererProps = {
    params: GridRenderCellParams;
};

export default function InstructorCellRenderer({ params }: InstructorCellRendererProps) {
    const instructors: InstructorAutoCompleteViewWithEmail[] = params.row.instructors;

    let namesString = '';
    let namesAndEmailsString = '';

    if (instructors && instructors.length !== 0) {
        namesString = instructors.map((instructor) => instructor.label).join(', ');
        namesAndEmailsString = instructors.map((instructor) => `${instructor.label} (${instructor.email})`).join(', ');
    } else {
        namesString = '-';
        namesAndEmailsString = '-';
    }

    return DifferentCellDifferentTooltipCellRenderer(namesString, namesAndEmailsString);
}
