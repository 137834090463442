import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import {
    getDocumentField,
    getFinancingFormLabelByValue,
    getMembershipStatus,
    getSemesterStatus,
    getTrainingFormLabelByValueOld,
} from '../../utils';
import Title from '../../../../../../components/Title';
import { IEnrollment } from './types';
import Alert from '@mui/material/Alert';

export interface EnrollmentDialogContentProps {
    data: IEnrollment;
}

export default function EnrollmentDialogContent(props: EnrollmentDialogContentProps) {
    const { data } = props;

    return (
        <Grid container spacing={1}>
            {data.hasModification && (
                <Grid item xs={12}>
                    <Alert severity="warning">
                        A beiratkozást követően hallgatói kérvény(ek) általi változás(ok) történtek a hallgató képzési
                        adataiban. A kérvény(ek)nek megfelelően frissültek a hallgató adatai és a szemeszterre vonatkozó
                        beiratkozási lapja is.
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <Title title="Típus" />
                <TextField value={'Beiratkozás'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Név" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Vezetéknév" value={data.lastName || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Keresztnév" value={data.firstName || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Képzés" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Képzési szint" value={data.trainingLevel || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Képzési program" value={data.trainingProgram || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Évfolyam" value={data.collegeYear || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Státusz" value={getMembershipStatus(data.membershipStatus || '-')} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Aktuális státusz"
                            value={getMembershipStatus(data.currentMembershipStatus || '-')}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Szemeszter státusz"
                            value={getSemesterStatus(data.semesterStatus) || data.semesterStatus || '-'}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            {'omId' in data && (
                <Grid item xs={12}>
                    <Title title="OM azonosító" />
                    <TextField value={data.omId || '-'} disabled />
                </Grid>
            )}
            {'taxId' in data && (
                <Grid item xs={12}>
                    <Title title="Adóazonosító jel" />
                    <TextField value={data.taxId || '-'} disabled />
                </Grid>
            )}
            {'taj' in data && (
                <Grid item xs={12}>
                    <Title title="TAJ szám" />
                    <TextField value={data.taj || '-'} disabled />
                </Grid>
            )}
            <Grid item xs={12}>
                <Title title="Campus" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Campus" value={data.campus || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Épület" value={data.building || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Szint" value={data.floor || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Szoba" value={data.room || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Állandó lakcím" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={3}>
                        <TextField label="Irányítószám" value={data.address?.postCode || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField label="Település" value={data.address?.city || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={4}>
                        <TextField label="Közterület neve" value={data.address?.streetName || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="Közterület jellege" value={data.address?.streetType || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="Házszám" value={data.address?.houseNumber || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Emelet" value={data.address?.floor || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Ajtó" value={data.address?.door || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            {('motherLastName' in data || 'motherFirstName' in data) && (
                <Grid item xs={12}>
                    <Title title="Anyja neve" />
                    <Grid item xs={12} container spacing={1} direction="row">
                        {'motherLastName' in data && (
                            <Grid item xs={12} md={6}>
                                <TextField label="Vezetéknév" value={data.motherLastName || '-'} disabled />
                            </Grid>
                        )}
                        {'motherFirstName' in data && (
                            <Grid item xs={12} md={6}>
                                <TextField label="Keresztnév" value={data.motherFirstName || '-'} disabled />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {'nationality' in data && (
                <Grid item xs={12}>
                    <Title title="Állampolgárság" />
                    <TextField value={data.nationality || '-'} disabled />
                </Grid>
            )}
            {('birthPlace' in data || 'birthDate' in data) && (
                <Grid item xs={12}>
                    <Title title="Születési hely/idő" />
                    <Grid item xs={12} container spacing={1} direction="row">
                        {'birthPlace' in data && (
                            <Grid item xs={12} md={6}>
                                <TextField label="Születési hely" value={data.birthPlace || '-'} disabled />
                            </Grid>
                        )}
                        {'birthDate' in data && (
                            <Grid item xs={12} md={6}>
                                <TextField label="Születési idő" value={data.birthDate || '-'} disabled />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {'identificationNumberCnp' in data && (
                <Grid item xs={12}>
                    <Title title="Személyi szám (CNP)" />
                    <TextField value={data.identificationNumberCnp || '-'} disabled />
                </Grid>
            )}
            {'idCardNumber' in data && (
                <Grid item xs={12}>
                    <Title title="SZIG szám" />
                    <TextField value={data.idCardNumber || '-'} disabled />
                </Grid>
            )}
            {'kepIdCardNumber' in data && (
                <Grid item xs={12}>
                    <Title title="SZIG szám - KEP" />
                    <TextField value={data.kepIdCardNumber || '-'} disabled />
                </Grid>
            )}
            {'bankName' in data && (
                <Grid item xs={12}>
                    <Title title="Pénzintézet neve" />
                    <TextField value={data.bankName || '-'} disabled />
                </Grid>
            )}
            {'bankAccountNumber' in data && (
                <Grid item xs={12}>
                    <Title title="Bankszámlaszám" />
                    <TextField value={data.bankAccountNumber || '-'} disabled />
                </Grid>
            )}
            <Grid item xs={12}>
                <Title title="Telefonszám" />
                <TextField value={data.phoneNumber || '-'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="E-mail cím" />
                <TextField value={data.email || '-'} disabled />
            </Grid>
            {'actualUniversityStudies' in data && (
                <>
                    <Grid item xs={12}>
                        <Title title="Egyetem" />
                        <TextField value={data?.actualUniversityStudies[0]?.university?.name || '-'} disabled />
                        {data?.actualUniversityStudies[0]?.foreignUniversity && (
                            <TextField
                                label={'Külföldi egyetem neve'}
                                value={data?.actualUniversityStudies[0]?.foreignUniversity}
                                disabled
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Title title="Neptun kód" />
                        <TextField value={data?.actualUniversityStudies[0]?.neptun || '-'} disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <Title title="Szak" />
                        <TextField value={data?.actualUniversityStudies[0]?.major || '-'} disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <Title title="Képzés formája" />
                        <TextField
                            value={
                                getTrainingFormLabelByValueOld(data?.actualUniversityStudies[0]?.trainingForm) ||
                                data?.actualUniversityStudies[0]?.trainingForm ||
                                '-'
                            }
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Title title="Finanszírozás formája" />
                        <TextField
                            value={getFinancingFormLabelByValue(data?.actualUniversityStudies[0]?.financeForm) || '-'}
                            disabled
                        />
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <Title title="Hallgatói jogviszony" />
                <TextField value={getSemesterStatus(data.scholarshipStatus) || '-'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Egyetemi felvételi határozat" />
                <TextField value={data.universityAdmissionDecisionFileName || 'Nem került feltöltésre fájl'} disabled />
            </Grid>
            {data.acceptedDocuments.length > 0 && (
                <Grid item xs={12}>
                    <Title title="Elfogadott dokumentumok" />
                    {data.acceptedDocuments?.map((f) => {
                        return <TextField key={f.id} value={getDocumentField(f, data)} disabled />;
                    })}
                </Grid>
            )}
        </Grid>
    );
}
