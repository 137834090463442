import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import studentsService from '../../../../../../services/masterData/students';
import { theme } from '../../../../../../utils/theme';

export default function ForeignUniversityAutomcomplete({
    value,
    onChange,
    errorMessage,
    disabled,
}: {
    value: string | null;
    onChange: (value: string | null) => void;
    errorMessage?: string;
    disabled?: boolean;
}) {
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const n = 3;

    const handleInputChange = useCallback(
        (_: React.SyntheticEvent<Element, Event>, value: string) => {
            setInputValue(value);
            if (value.length >= n) {
                setLoading(true);
                studentsService
                    .foreignUniversySearch(value)
                    .then((response: AxiosResponse<{ data: string[] }>) => setOptions(response.data.data))
                    .finally(() => setLoading(false));
            }
            onChange(value);
        },
        [onChange],
    );

    const handleClose = useCallback(() => setOptions([]), []);

    const renderTextField = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                label="Külföldi egyetem neve"
                placeholder={'Írjon be ' + n + ' karaktert a kereséshez'}
                size="small"
                helperText={errorMessage}
                error={!!errorMessage}
            />
        ),
        [errorMessage],
    );

    const getNoOptionsText = () => {
        if (loading) {
            return 'Betöltés...';
        } else if (inputValue.length >= n) {
            return 'Nincs találat';
        } else {
            return 'Írjon be ' + n + ' karaktert a kereséshez';
        }
    };

    const handleOnChange = useCallback(
        (_: React.SyntheticEvent<Element, Event>, value: string | null) => onChange(value),
        [onChange],
    );

    return (
        <Grid item xs={12}>
            <Autocomplete
                onInputChange={handleInputChange}
                disabled={disabled}
                onClose={handleClose}
                renderInput={renderTextField}
                options={options}
                noOptionsText={getNoOptionsText()}
                ChipProps={{
                    sx: {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        '& .MuiChip-deleteIcon': {
                            color: theme.palette.primary.main,
                        },
                    },
                }}
                value={value}
                filterSelectedOptions
                freeSolo
                openOnFocus
                onChange={handleOnChange}
            />
        </Grid>
    );
}
