import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TooltipRenderer } from '../../../utils/AppConstElements';

export default function ActorCellRenderer(params: GridRenderCellParams) {
    const data = params.row.actor;
    if (data?.lastName === null || data?.firstName === null) {
        return '';
    }

    if (data?.fullName) {
        return TooltipRenderer(data.fullName);
    } else {
        return TooltipRenderer(`${data.lastName} ${data.firstName} (${data.email})`);
    }
}
