import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { FieldGenerator } from '@silinfo/front-end-template';
import { Children, useState } from 'react';
import { useDispatch } from 'react-redux';
import instance from '../../../../../api';
import Form from '../../../../../components/Form/Form';
import { create } from '../../../../../store/notification';
import { refreshAction } from '../../../../../store/studentProfile';
import { endpoints } from '../../../../../utils/endPoints';
import { fields } from '../fields';
import { LanguageExam } from '../types';
import Validation from './Validation';

const initialValues: LanguageExam = {
    id: '',
    language: '',
    level: '',
    type: null,
    category: 'complex',
    examDate: '',
    examLocation: '',
    certificateFileName: '',
    certificateFilePath: '',
    validated: '',
};

const EditContent = ({
    setOpen,
    languageExam,
    handleClose,
}: {
    setOpen: (value: boolean) => void;
    languageExam?: LanguageExam;
    handleClose: () => void;
}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (form: LanguageExam) => {
        setLoading(true);
        return instance
            .post(endpoints.students.profile.studies.languageExams + (languageExam ? '/' + languageExam.id : ''), {
                ...form,
                validated: languageExam ? languageExam.validated : '',
            })
            .then(() => {
                dispatch(refreshAction());
                setOpen(false);
            })
            .finally(() => setLoading(false));
    };

    const handleDelete = (id: string | number) => {
        instance
            .delete(endpoints.students.profile.studies.languageExams + '/' + id)
            .then(() => {
                dispatch(refreshAction());
                dispatch(
                    create({
                        message: 'Nyelvvizsga sikeresen törölve!',
                        type: 'success',
                    }),
                );
                setOpen(false);
            })
            .catch(() => {
                dispatch(
                    create({
                        message: 'Hiba történt a nyelvvizsga törlése közben!',
                        type: 'error',
                    }),
                );
            });
    };

    return (
        <Form fields={[]} onSubmit={handleSubmit} hideButtons initialValues={languageExam || initialValues}>
            {(props) => (
                <Grid item container>
                    <Grid item xs={12}>
                        <DialogContent>
                            {Children.toArray(
                                fields(languageExam?.id as number).map((field) =>
                                    FieldGenerator({ ...field, ...props, format: { xs: 12 } }),
                                ),
                            )}
                            {languageExam && <Validation languageExam={languageExam} />}
                        </DialogContent>
                    </Grid>
                    <Grid item xs={12}>
                        <DialogActions>
                            <LoadingButton variant="outlined" onClick={handleClose}>
                                Bezár
                            </LoadingButton>
                            {languageExam && (
                                <LoadingButton
                                    color="error"
                                    variant="contained"
                                    onClick={() => handleDelete(languageExam.id)}
                                >
                                    Törlés
                                </LoadingButton>
                            )}
                            <LoadingButton type="submit" variant="contained" loading={loading}>
                                Mentés
                            </LoadingButton>
                        </DialogActions>
                    </Grid>
                </Grid>
            )}
        </Form>
    );
};

export default EditContent;
