import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import i18n from '../../../../i18n';
import { TooltipRenderer } from '../../../../utils/AppConstElements';

export default function TypeCellRenderer(params: GridRenderCellParams) {
    const data = params.row.type;
    if (!data) {
        return '-';
    }
    const typeCell = i18n.t('administration.email_log.type.' + data);
    return TooltipRenderer(typeCell);
}
