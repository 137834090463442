import { IPage, IVersion } from './types';
import pagesService from '../../../services/administration/pages';
import { Language } from '../../../store/sharedInterfaces';
import { clientEndPoints } from '../../../utils/clientEndPoints';

const initialTranslation = {
    id: 0,
    content: '',
    locale: '',
    title: '',
};

const initialTranslations = {
    hu: initialTranslation,
    en: initialTranslation,
};
export const initialPage = {
    id: 0,
    key: '',
    translations: initialTranslations,
};

export const initialVersion = {
    id: 0,
    page: initialPage,
    startDate: new Date(),
    endDate: '',
    translations: initialTranslations,
};

export interface LanguageObject {
    value: Language;
    label: string;
}
export const languagesObjects: LanguageObject[] = [
    { value: 'hu', label: 'Magyar' },
    { value: 'en', label: 'Angol' },
];

export const fetchVersion = (
    pageId: number | string,
    versionId: number | string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setVersion: React.Dispatch<React.SetStateAction<IVersion[]>>,
) => {
    setLoading(true);
    pagesService
        .getVersion(pageId, versionId)
        .then((response) => {
            setVersion(response.data);
        })
        .finally(() => setLoading(false));
};

export const fetchVersions = (
    pageId: number | string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setVersions: React.Dispatch<React.SetStateAction<IVersion[]>>,
) => {
    setLoading(true);
    pagesService
        .filterVersions(pageId, {})
        .then((response) => {
            setVersions(response.data['hydra:member']);
        })
        .finally(() => setLoading(false));
};

export const fetchPages = (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setData: React.Dispatch<React.SetStateAction<IPage>>,
) => {
    setLoading(true);
    pagesService
        .filterPages({})
        .then((response) => {
            setData(response.data['hydra:member']);
        })
        .finally(() => setLoading(false));
};

export const header = (tenant: string | null) => {
    return {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_pages_list]: 'Oldalak',
        },
        title: 'Oldalak',
        project: tenant || 'TAR',
    };
};
