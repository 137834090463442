import { LoadingButton } from '@mui/lab';
import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CancelButton, Loading } from '@silinfo/front-end-template';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FieldGenerator } from '../../../components/Form/FieldGenerator';
import Form from '../../../components/Form/Form';
import approvalsService from '../../../services/administration/approvals';
import { create } from '../../../store/notification';
import { Option } from '../../../utils/AppConst';
import { IForm } from '../../../utils/Interfaces/interfaces';
import IStudentRequestApproval, {
    initialStudentRequestApproval,
    IStudentRequestCourse,
} from '../../../utils/Interfaces/IStudentRequestApproval';
import { CourseInfo } from './CourseInfo';
import { StatusChangeInfo } from './StatusChangeInfo';
import Alert from '@mui/material/Alert';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    warningMessage: {
        color: theme.palette.warning.main,
        fontWeight: 'bold',
    },
    container: {
        background: 'rgba(0,0,0,0.1)',
        margin: '8px',
    },
    textContainer: {
        padding: '8px 16px',
        margin: '0px 0px 16px 0px',
    },
}));

interface ApprovalDialogFormProps {
    opener: JSX.Element;
    handleClose: () => void;
    objectId: number;
    setTableLoading: () => void;
}

export default function ApprovalDialogForm(props: ApprovalDialogFormProps) {
    const classes = useStyles();

    return (
        <DialogBasic open={true} onClose={props.handleClose} fullWidth>
            <DialogTitle className={classes.title}>Jóváhagyás</DialogTitle>
            <DialogContent>
                <LangForm {...props} />
            </DialogContent>
        </DialogBasic>
    );
}

// Űrlap generálás
function LangForm(props: ApprovalDialogFormProps) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { handleClose, objectId, setTableLoading } = props;
    const [initialValues, setInitialValues] = useState(initialStudentRequestApproval);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [options, setOptions] = useState<{ statuses: Option[] }>({
        statuses: [],
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        axios
            .all([approvalsService.filterDataStatuses(), approvalsService.get(objectId)])
            .then((res) => {
                setOptions((prev) => {
                    return {
                        ...prev,
                        statuses: res[0].data.filter((e: Option) => e.value !== 'denied_semester_close'),
                    };
                });
                setInitialValues(res[1].data.data);
            })
            .catch(() => alert('hiba'))
            .finally(() => setInitialLoading(false));
    }, [objectId]);

    const handleSubmit = (form: IForm) => {
        if (initialValues.course?.closed && form.status === 'accepted' && !open) {
            setOpen(true);
            return;
        }
        setOpen(false);
        setLoadingForm(true);
        approvalsService
            .save(objectId, form)
            .then(() => {
                handleClose();
                setTableLoading();
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                    }),
                );
            })
            .catch((e) => {
                const msg = e?.response?.data?.message;
                if (msg) {
                    dispatch(
                        create({
                            type: 'error',
                            message: msg,
                        }),
                    );
                }
            })
            .finally(() => {
                setLoadingForm(false);
            });
    };

    if (initialLoading) return <Loading noHeight />;

    const text = `FIGYELEM! A kérvényhez tartozó '${
        initialValues.course?.name || 'ismeretlen'
    }' kurzus már lezárásra került. A kérvény elfogadásával a kurzus újranyitásra kerül. Biztosan elfogadod a kérvényt?`;

    return (
        <>
            <Form
                hideButtons
                fields={[]}
                onSubmit={handleSubmit}
                submitTransformData={(form) =>
                    Object.entries(form).reduce(
                        (prev, [key, value]: [string, unknown]) => ({
                            ...prev,
                            [key]: Array.isArray(value)
                                ? value.map((elem) => elem?.value || elem)
                                : (value as Option)?.value || value,
                        }),
                        {},
                    ) as IForm
                }
                initialValues={{ ...initialValues }}
            >
                {(formikProps) => {
                    return loadingForm ? (
                        <Loading noHeight />
                    ) : (
                        <>
                            <ConfirmationDialog
                                title="Kérvény elfogadása"
                                text={text}
                                openState={[open, setOpen]}
                                onConfirm={() => handleSubmit(formikProps.values)}
                            />
                            <Grid item xs={12}>
                                <Paper className={classes.container} elevation={4}>
                                    <Grid container className={classes.textContainer}>
                                        {showRequestInfo(initialValues)}
                                    </Grid>
                                </Paper>
                            </Grid>
                            {FieldGenerator({
                                name: 'status',
                                label: 'Státusz',
                                type: 'select',
                                options: options?.statuses,
                                format: { xs: 12, sm: 12, md: 12 },
                                ...formikProps,
                            })}
                            <WarningMessage data={initialValues} values={formikProps.values} />
                            <Grid item container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    {initialValues.typeOrig === 'change_semester_status' && (
                                        <Alert severity="warning">
                                            Amennyiben jóváhagyásra kerül a szemeszter státusz módosítás úgy a hallgató
                                            beiratkozási adatlapja is frissül, amely hatására a képzési követelményei is
                                            módosulthatnak az adott szemeszterre.
                                        </Alert>
                                    )}
                                </Grid>
                                <Grid item>
                                    <CancelButton onClick={handleClose}>Bezár</CancelButton>
                                </Grid>
                                <Grid item>
                                    <LoadingButton loading={loadingForm} variant="contained" type="submit">
                                        Mentés
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </>
                    );
                }}
            </Form>
        </>
    );
}

function showRequestInfo(data: IStudentRequestApproval) {
    switch (data.typeOrig) {
        case 'course_dismissal':
        case 'course_enrollment':
            return (
                <CourseInfo
                    data={{
                        student: data.student,
                        tenant: data.tenant,
                        reason: data.reason,
                        ...(data.course as Required<IStudentRequestCourse>),
                    }}
                />
            );
        case 'change_semester_status':
        case 'change_actual_status':
            return <StatusChangeInfo data={data} />;
        default:
            return <></>;
    }
}

interface WarningMessageProps {
    data: IStudentRequestApproval;
    values: IStudentRequestApproval;
}

function WarningMessage(props: WarningMessageProps) {
    const { data, values } = props;
    const { type, course } = data;
    const classes = useStyles();

    let msg = '';

    if (values.status === 'accepted' && course) {
        if (type === 'course_enrollment') {
            if (course.normalEnrollments + 1 > course.maxHeadCount) {
                msg =
                    'A művelet elvégzése magával vonja a kurzus maximális létszámának növelését. A mentés után a kurzus maximális létszáma: ' +
                    (course.maxHeadCount + 1);
            }
        } else if (type === 'course_dismissal') {
            if (course.normalEnrollments - 1 < course.minHeadCount) {
                msg =
                    'A művelet elvégzése magával vonja a kurzus minimális létszámának csökkentését. A mentés után a kurzus minimális létszáma: ' +
                    (course.minHeadCount - 1);
            }
        }
    }

    if (!msg) {
        return <></>;
    }

    return (
        <Grid item xs={12} className={classes.warningMessage}>
            Figyelem! {msg}
        </Grid>
    );
}
