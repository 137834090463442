// eslint-disable-next-line regex/invalid-warn
import AddBox from '@mui/icons-material/AddBox';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Input } from '@silinfo/front-end-template';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExportButton from '../../../components/Buttons/ExportButton';
import MCCLoading from '../../../components/MCCLoading';
import langExamCompletionReportService from '../../../services/administration/langExamCompletionReport';
import courseListService from '../../../services/courseManagement/courseList';
import languageExamRequirements from '../../../services/masterData/languageExamRequirements';
import trainingLevelsService from '../../../services/masterData/trainingLevels';
import trainingProgramsService from '../../../services/masterData/trainingPrograms';
import { RootState } from '../../../store';
import { create } from '../../../store/notification';
import { Option } from '../../../utils/AppConst';
import { TooltipRenderer } from '../../../utils/AppConstElements';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { semesterStatusAll } from '../../SelfEvaluation/Administration/utils';
import StudentLanguageExamsDialog from './StudentLanguageExamsDialog';
import { LangExamReportFilter, initialLangExamReportFilterState } from './types';
import { SESSION_KEY } from './utils';
import { useTenant } from '../../../components/TenantContext';

const initialOptions = {
    campuses: [],
    trainingLevels: [],
    trainingPrograms: [],
    semesterStatus: semesterStatusAll,
    billing: [
        { value: 'yes', label: 'Igen' },
        { value: 'no', label: 'Nem' },
    ],
};
export type OptionKeys = 'campuses' | 'trainingLevels' | 'trainingPrograms' | 'semesterStatus' | 'billing';

export default function LangExamRequirementCompletion() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [options, setOptions] = useState<Record<OptionKeys, Option[]>>(initialOptions);
    const dispatch = useDispatch();
    const [info, setInfo] = useState<IInfo<LangExamReportFilter>>(initialLangExamReportFilterState);
    const tenant = useTenant();
    const header = {
        project: tenant || 'TAR',
        title: 'Nyelvvizsga riport',
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_lang_exam_report_list]: 'Nyelvvizsga riport',
            'languageExams': 'Hallgatók',
        },
    };

    useEffect(() => {
        setLoading(true);
        axios
            .all([
                trainingLevelsService.trainingLevels(),
                trainingProgramsService.trainingPrograms(),
                courseListService.campuses(),
            ])
            .then((res) => {
                setOptions((prev) => ({
                    ...prev,
                    campuses: res[2].data,
                    trainingLevels: res[0].data,
                    trainingPrograms: res[1].data,
                }));
            })
            .finally(() => setLoading(false));
    }, []);

    const exportContext = {
        initiateExport: langExamCompletionReportService.initiateExport,
        checkExport: langExamCompletionReportService.checkExport,
        downloadExport: langExamCompletionReportService.downloadExport,
        info: info,
        filter: info.filter,
    };

    const filter = {
        onSubmit: () => {
            //TODO: Why is this needed
        },
        fields: [
            {
                name: 'student',
                label: 'Hallgató',
                format: {
                    xs: 4,
                },
            },
            {
                name: 'status',
                label: 'Státusz',
                type: 'multiselect',
                options: [
                    { value: 'full_completion', label: 'Teljesítve' },
                    { value: 'no_completion_but_have_chance', label: 'Nem teljesítve - Türelmi idővel' },
                    { value: 'no_completion', label: 'Nem teljesítve' },
                ],
                format: {
                    xs: 4,
                },
            },
            {
                name: 'semesterStatus',
                label: 'Szemeszter státusz',
                fieldType: 'base',
                type: 'multiselect',
                options: options.semesterStatus,
            },
            {
                name: 'campus',
                label: 'Campus',
                format: {
                    xs: 4,
                },
                type: 'multiselect',
                options: options.campuses,
            },
            {
                name: 'trainingLevel',
                label: 'Képzési szint',
                format: {
                    xs: 4,
                },
                type: 'multiselect',
                options: options.trainingLevels,
            },
            {
                name: 'trainingProgram',
                label: 'Képzési program',
                format: {
                    xs: 4,
                },
                type: 'multiselect',
                options: options.trainingPrograms,
            },
            {
                type: 'date',
                label: 'Teljesítés időpontja (-tól)',
                name: 'completionDateFrom',
                format: { xs: 12, md: 4, lg: 4 },
            },
            {
                type: 'date',
                label: 'Teljesítés időpontja (-ig)',
                name: 'completionDateUntil',
                format: { xs: 12, md: 4, lg: 4 },
            },
            {
                name: 'billing',
                label: 'Számlázás',
                fieldType: 'base',
                type: 'multiselect',
                options: options.billing,
            },
        ] as Input[],
    };

    const columns = [
        {
            headerName: 'Hallgató',
            field: 'student',
        },
        {
            headerName: 'Státusz',
            field: 'status',
        },
        {
            headerName: 'Szemeszter státusz',
            field: 'semesterStatus',
        },
        {
            headerName: 'Képzési szint',
            field: 'trainingLevel',
        },
        {
            headerName: 'Képzési program',
            field: 'trainingProgram',
        },
        {
            headerName: 'Campus',
            field: 'campus',
        },
        {
            headerName: 'Követelmény',
            field: 'languageExamRequirements',
            sortable: false,
        },
        {
            headerName: 'Teljesítés időpontja',
            field: 'completionDate',
            renderCell: (params: GridRenderCellParams<Record<string, unknown>>) =>
                TooltipRenderer(params.row.completionDate),
        },
        {
            headerName: 'Számlázás',
            field: 'billing',
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams<Record<string, unknown>>) => {
                return <StudentLanguageExamsDialog studentId={params.row.studentId} />;
            },
            sortable: false,
        },
    ];

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            autoload
            header={header}
            sessionKey={SESSION_KEY}
            responseKey="data"
            service={{
                filter: (form) => {
                    setInfo((prevState) => ({ ...prevState, filter: form })); // Export miatt van itt
                    return langExamCompletionReportService.filter(form);
                },
            }}
            columns={columns}
            defaultSort={{ student: 'asc' }}
            filter={filter}
            customButtons={
                <>
                    {user.admin && !user.archive && (
                        <Grid item>
                            <LoadingButton
                                startIcon={<AddBox />}
                                loading={buttonLoading}
                                loadingPosition="start"
                                onClick={() => {
                                    setButtonLoading(true);
                                    languageExamRequirements
                                        .checkFine()
                                        .then(() =>
                                            dispatch(
                                                create({
                                                    type: 'info',
                                                    message: 'A folyamat elindult, néhány percig eltarthat...',
                                                }),
                                            ),
                                        )
                                        .finally(() => setButtonLoading(false));
                                }}
                                variant="outlined"
                                color="success"
                            >
                                Nyelvvizsga követelmények ellenőrzése és díjak kiszabása
                            </LoadingButton>
                        </Grid>
                    )}
                    {!user.archive && (
                        <Grid item>
                            <ExportButton exportContext={exportContext} />
                        </Grid>
                    )}
                </>
            }
        />
    );
}
