import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useContext, useMemo } from 'react';
import { Paper } from '../Requirements/Paper';
import Counter from './Counter';
import EventLabel from './EventLabel';
import { IEvent } from './types';
import { eventTypes, getCounters } from './utils';
import DateStateContext from './DateStateContext';

const Header = ({ data }: { data: IEvent[] }) => {
    const [_, setDate] = useContext(DateStateContext);

    const counters = useMemo(() => eventTypes.map((eventType) => getCounters(eventType, data)), [data]);

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                <Paper>
                    <Grid item container xs={12} justifyContent="space-between">
                        <EventLabel />
                        <Grid item container xs={12} md={6} spacing={2}>
                            {counters.map((counter) => (
                                <Counter key={counter.label} {...counter} />
                            ))}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12} md={2} lg={1} textAlign="end">
                <Button variant="contained" fullWidth style={{ height: '100%' }} onClick={() => setDate(new Date())}>
                    <Typography variant="h4">
                        <strong>MA</strong>
                    </Typography>
                </Button>
            </Grid>
        </>
    );
};

export default Header;
