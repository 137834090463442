import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { useCallback, useContext, useState } from 'react';
import { ScholarshipTable } from './ScholarshipTable';
import { GroupListProps, IAmount, IRelevance } from './types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Edit from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import MenuContext from './MenuContextProvider';
import { EditActions } from '../EditActions';
import { NonEditActions } from './NonEditActions';
import instance from '../../../../../api';
import { endpoints } from '../../../../../utils/endPoints';
import GridRenderCellParamsContext from './GridRenderCellParamsContextProvider';
import { useDispatch } from 'react-redux';
import { create } from '../../../../../store/notification';

export function GroupList({ editable }: GroupListProps) {
    const [open, setOpen] = useState(false);
    const [amounts, setAmounts] = useState<IAmount[]>([]);
    const [relevances, setRelevances] = useState<IRelevance[]>([]);
    const [errors, setErrors] = useState();
    const dispatch = useDispatch();

    const handleOpen = useCallback(() => setOpen(true), []);
    const setter = useContext(MenuContext);

    const handleClose = useCallback(() => {
        setter(null);
        setOpen(false);
    }, [setter]);
    const text = editable ? 'szerkesztése' : 'megtekintése';
    const Icon = editable ? Edit : Visibility;
    const color = editable ? 'warning' : 'primary';
    const title = `Csoportlista ${text}`;
    const params = useContext(GridRenderCellParamsContext);

    const handleSubmit = useCallback(async () => {
        return instance
            .post(endpoints.scholarships.admin.saveValues('' + params?.id || ''), {
                amounts: amounts.filter((a) => a.old !== a.new),
                relevances: relevances.filter((r) => r.old !== r.new),
            })
            .then(() => handleClose())
            .catch((error) => {
                if (error.response.data.violations) {
                    setErrors(error.response.data.violations);
                    dispatch(create({ type: 'error', message: error.response.data.violations[0].message }));
                } else {
                    dispatch(create({ type: 'error', message: 'Hiba lépett fel a mentés során' }));
                }
            });
    }, [params?.id, amounts, relevances, handleClose, dispatch]);

    return (
        <>
            <MenuItem onClick={handleOpen}>
                <ListItemIcon>
                    <Icon color={color} />
                </ListItemIcon>
                <ListItemText>{title}</ListItemText>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent style={{ paddingTop: '16px' }} dividers>
                    <ScholarshipTable
                        relevanceState={[relevances, setRelevances]}
                        editable={!!editable}
                        amountsState={[amounts, setAmounts]}
                        errors={errors}
                    />
                </DialogContent>
                <DialogActions>
                    {editable ? (
                        <EditActions handleClose={handleClose} handleSubmit={handleSubmit} />
                    ) : (
                        <NonEditActions handleClose={handleClose} />
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}
