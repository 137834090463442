import instance from '../../api';
import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const courseBaseDataService = {
    create: (form: IForm) => API.post(endpoints.courseManagement.courseBaseData.create, form),
    copy: (id: number, form: IForm) =>
        API.post(endpoints.courseManagement.courseBaseData.copy.replace('{id}', id.toString()), form),
    get: (id: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseBaseData.get.replace('{id}', id.toString());
        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
    copyFrom: (id: number) =>
        instance.get(endpoints.courseManagement.courseBaseData.copyFrom.replace('{id}', id.toString())),
    update: (id: number, form: IForm) =>
        API.post(endpoints.courseManagement.courseBaseData.update.replace('{id}', id.toString()), form),
    getCourseCodeVersion: (params: IForm) =>
        API.get(endpoints.courseManagement.courseBaseData.getCodeVersion, { params }),
    getEvents: (id: number, onlyLecture?: boolean) =>
        instance.get(
            endpoints.courseManagement.courseBaseData.getEvents.replace('{id}', id.toString()) +
                '?onlyLectures=' +
                (onlyLecture ? '1' : '0'),
        ),
};

export default courseBaseDataService;
