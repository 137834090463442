import { Accordion, createInitialValues, Page } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import courseTypesService, { ICourseType } from '../../../../services/masterData/courses/courseTypes';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { formFields } from './fields';
import { useTenant } from '../../../../components/TenantContext';

export default function CourseTypesForm({ readOnly = false }: { readOnly?: boolean }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const tenant = useTenant();

    const title = (id: string | undefined, readOnly: boolean) => {
        if (readOnly) {
            return 'Kurzustípus megtekintése';
        } else if (id) {
            return 'Kurzustípus szerkesztése';
        } else {
            return 'Kurzustípus hozzáadása';
        }
    };

    const header = {
        project: tenant || 'TAR',
        title: 'Kurzustípusok',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_courses]: 'Kurzusok',
            [clientEndPoints.md_courses_course_types_list]: 'Kurzustípusok',
            form: title(id, readOnly),
        },
    };
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitalValues] = useState({
        ...createInitialValues(formFields),
        scoringType: 'point',
        eventAssignable: 'false',
        countAsOwnSchoolCompletion: 'false',
    } as ICourseType);

    useEffect(() => {
        if (id) {
            setLoading(true);
            courseTypesService
                .get(id)
                .then((response: AxiosResponse<ICourseType>) => {
                    if (!readOnly && response.data.data.name === 'Megosztott') {
                        navigate('/403');
                        return;
                    }

                    setInitalValues((prev) => ({
                        ...prev,
                        ...response.data.data,
                        active: response.data.data.active.toString(),
                        eventAssignable: response.data.data.eventAssignable.toString(),
                        countAsOwnSchoolCompletion: response.data.data.countAsOwnSchoolCompletion.toString(),
                    }));
                })
                .finally(() => setLoading(false));
        }
    }, [id, navigate, readOnly]);

    const handleSubmit = (form: ICourseType) =>
        id
            ? courseTypesService.update(id, {
                  ...form,
                  active: form.active === 'true',
                  eventAssignable: form.eventAssignable === 'true',
                  countAsOwnSchoolCompletion: form.countAsOwnSchoolCompletion === 'true',
              })
            : courseTypesService.create({
                  ...form,
                  active: form.active === 'true',
                  eventAssignable: form.eventAssignable === 'true',
                  countAsOwnSchoolCompletion: form.countAsOwnSchoolCompletion === 'true',
              });

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title(id, readOnly)}>
                <Form
                    fields={formFields}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    back={clientEndPoints.md_courses_course_types_list}
                    readonly={readOnly}
                />
            </Accordion>
        </Page>
    );
}
