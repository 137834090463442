import API from '../../../../api';
import { useEffect, useState } from 'react';
import { Admission, Document, SelectedRow } from '../types';
import { useDispatch } from 'react-redux';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import AdmissionCompletedIcon from '../Icons/AdmissionCompletedIcon.svg';
import NotCompleted from '../Icons/NotCompleted.svg';
import AdmissionDateExpiredIcon from '../Icons/AdmissionDateExpiredIcon.svg';
import Serial from '../Serial';
import UploadIcon from '../Icons/UploadIcon.svg';
import DisabledUploadIcon from '../Icons/DisabledUploadIcon.svg';
import FillQuestionnaireIcon from '../Icons/FillQuestionnaireIcon.svg';
import AddTrainingProgram from '../Icons/AddTrainingProgram.svg';
import AddTrainingProgramDisabled from '../Icons/AddTrainingProgramDisabled.svg';
import ExpiredIcon from '../Icons/ExpiredIcon.svg';
import ModeIcon from '@mui/icons-material/Mode';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { endpoints } from '../../../../utils/endPoints';
import { create } from '../../../../store/notification';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AdmissionsStyle from '../Admissions.module.scss';
import AdmissionsListStyle from './AdmissionsList.module.scss';
import axios from 'axios';

const convertDate = (dateString: string) => {
    return `${dateString} 23:59`;
};

export default function AdmissionListElement({
    admission,
    index,
    token,
    isRegistrated,
    firstItem,
    lastItem,
    isProgramSelected,
    isPreviousProgramNull,
    handleOpenDialog,
    selectState,
    handleOpenDocumentDialog,
    handleOpenFinalizeDialog,
    handleRefresh,
    handleLoading,
}: {
    admission: Admission;
    index: number;
    token: string;
    isRegistrated: boolean;
    firstItem: boolean;
    lastItem: boolean;
    isProgramSelected: boolean;
    isPreviousProgramNull: boolean;
    handleOpenDialog: () => void;
    selectState: [SelectedRow | null, React.Dispatch<React.SetStateAction<SelectedRow | null>>];
    handleOpenDocumentDialog: (serial: number | null, isAdmissionDisabled: boolean) => void;
    handleOpenFinalizeDialog: (admission: Admission) => void;
    handleRefresh: () => void;
    handleLoading: (loading: boolean) => void;
}) {
    const [_selectedRow, _setSelectedRow] = selectState;
    const [maxDocumentCount, setMaxDocumentCount] = useState<number | undefined>(undefined);
    const [uploadedDocumentCount, setUploadedDocumentCount] = useState<number | undefined>(undefined);
    const [isAdmissionDisabled, setIsAdmissionDisabled] = useState<boolean>(false);
    const [isEveryDocumentExpired, setIsEveryDocumentExpired] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [inactive, setInactive] = useState<boolean>(false);

    const handleNavigate = () => {
        if (isRegistrated) {
            window.open(
                clientEndPoints.admission_mcc_questionnaire.replace(':serial', `${admission.serial}`),
                '_blank',
            );
        } else {
            window.open(
                clientEndPoints.admission_token_questionnaire
                    .replace(':serial', `${admission.serial}`)
                    .replace(':tokenId', `${token}`),
                '_blank',
            );
        }
    };

    const handlePreview = () => {
        if (isRegistrated) {
            window.open(
                clientEndPoints.admission_mcc_questionnaire_preview.replace(':serial', `${admission.serial}`),
                '_blank',
            );
        } else {
            window.open(
                clientEndPoints.admission_token_questionnaire_preview
                    .replace(':serial', `${admission.serial}`)
                    .replace(':tokenId', `${token}`),
                '_blank',
            );
        }
    };

    const getAdmissionIcon = () => {
        if (admission.isFinalized) {
            return <img src={AdmissionCompletedIcon} />;
        }

        if (admission.applicationDeadline) {
            if (isAdmissionDisabled || new Date(admission.applicationDeadline) < new Date()) {
                return <img src={AdmissionDateExpiredIcon} />;
            }
        }

        return <img src={NotCompleted} />;
    };

    const handleMoveDown = () => {
        const endpoint = isRegistrated
            ? endpoints.admission.moveAdmissionDownMcc(admission.serial)
            : endpoints.admission.moveAdmissionDown(token, admission.serial);
        const request = isRegistrated ? API.post(endpoint) : axios.post(endpoint);
        handleLoading(true);
        request
            .then(() => {
                handleRefresh();
                dispatch(create({ type: 'success', message: 'Sikeres sorrendmódosítás!' }));
            })
            .catch((error) => {
                console.error(error);
                handleLoading(false);
                dispatch(create({ type: 'error', message: 'Sikertelen sorrendmódosítás!' }));
            });
    };

    const handleMoveUp = () => {
        const endpoint = isRegistrated
            ? endpoints.admission.moveAdmissionUpMcc(admission.serial)
            : endpoints.admission.moveAdmissionUp(token, admission.serial);
        const request = isRegistrated ? API.post(endpoint) : axios.post(endpoint);
        handleLoading(true);
        request
            .then(() => {
                handleRefresh();
                dispatch(create({ type: 'success', message: 'Sikeres sorrendmódosítás!' }));
            })
            .catch((error) => {
                console.error(error);
                handleLoading(false);
                dispatch(create({ type: 'error', message: 'Sikertelen sorrendmódosítás!' }));
            });
    };

    const getDocumentIcon = () => {
        if (maxDocumentCount === undefined && uploadedDocumentCount === undefined) {
            return <CircularProgress size={20} />;
        }
        if (maxDocumentCount === uploadedDocumentCount) {
            return <img src={UploadIcon} />;
        }
        if (isEveryDocumentExpired || (isAdmissionDisabled && uploadedDocumentCount === 0)) {
            return <img src={ExpiredIcon} />;
        }
        return <img src={DisabledUploadIcon} />;
    };

    const getQuestionnaireIcon = () => {
        return <img src={isAdmissionDisabled ? ExpiredIcon : FillQuestionnaireIcon} />;
    };

    useEffect(() => {
        if (admission.serial) {
            const endpoint = isRegistrated
                ? endpoints.admission.getDocumentsMcc(admission.serial)
                : endpoints.admission.getDocuments(token, admission.serial?.toString());
            const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
            request.then((response) => {
                if (response.data.uploadableDocuments) {
                    setMaxDocumentCount(
                        response.data.uploadableDocuments.filter((e: Document) => e.rule !== 'optional').length,
                    );
                    setUploadedDocumentCount(
                        response.data.uploadableDocuments.filter((e: Document) => e.filePath && e.rule === 'required')
                            .length,
                    );
                    const expiredDocuments = response.data.uploadableDocuments.filter(
                        (e: Document) => e.deadline && new Date(convertDate(e.deadline)) < new Date(),
                    );
                    if (expiredDocuments.length === response.data.uploadableDocuments.length) {
                        setIsEveryDocumentExpired(true);
                    }
                    if (admission.applicationDeadline && new Date(admission.applicationDeadline) < new Date()) {
                        setIsAdmissionDisabled(true);
                    }
                } else {
                    setMaxDocumentCount(0);
                    setUploadedDocumentCount(0);
                }
            });

            if (admission.status === 'inactive') setInactive(true);
        }
    }, [admission, inactive, isRegistrated, token]);

    return (
        <Grid item container xs={12}>
            <Grid
                container
                item
                xs={1.75}
                className={`${AdmissionsStyle.contentCenter}`}
                sx={{ flexDirection: 'column' }}
            >
                <Serial serial={admission.serial || index + 1} />
                {!firstItem && isProgramSelected && (
                    <IconButton size="small" onClick={handleMoveUp}>
                        <ExpandLessIcon className={`${AdmissionsListStyle.expandIcon}`} />
                    </IconButton>
                )}
                {!lastItem && isProgramSelected && (
                    <IconButton size="small" onClick={handleMoveDown}>
                        <ExpandMoreIcon className={`${AdmissionsListStyle.expandIcon}`} />
                    </IconButton>
                )}
            </Grid>
            <Grid item container xs={10.25} className={`${AdmissionsListStyle.applicantProgramContainer}`}>
                <Grid item container xs={9}>
                    <Grid item container style={{ marginBottom: '0.5rem' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: admission.trainingProgram ? 700 : 400 }}>
                            {admission.trainingProgram || 'Még nem választottál ki képzést.'}
                        </Typography>
                        {(((!isPreviousProgramNull || firstItem) &&
                            !admission.isFinalized &&
                            admission.editable &&
                            !isAdmissionDisabled) ||
                            admission.deletable) && (
                            <ModeIcon onClick={handleOpenDialog} className={`${AdmissionsListStyle.modeIcon}`} />
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{admission.campus}</Typography>
                    </Grid>
                    <Grid container gap={2} wrap="nowrap">
                        {admission.trainingProgram ? (
                            inactive ? (
                                <Typography
                                    className={`${AdmissionsStyle.bolder} ${AdmissionsListStyle.applicantProgramButtonText}`}
                                >
                                    A képzést inaktiválták. Töröld a jelentkezésed vagy válassz másik képzést!
                                </Typography>
                            ) : (
                                <Grid
                                    item
                                    container
                                    xs={4}
                                    sm={6}
                                    className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.applicantProgramButtons} `}
                                    onClick={() =>
                                        handleOpenDocumentDialog(
                                            admission.serial,
                                            isAdmissionDisabled || admission.isFinalized,
                                        )
                                    }
                                >
                                    <Typography
                                        className={`${AdmissionsStyle.bolder} ${AdmissionsListStyle.applicantProgramButtonText}`}
                                    >
                                        Dokumentumok feltöltése
                                    </Typography>
                                    {getDocumentIcon()}
                                </Grid>
                            )
                        ) : (
                            <Grid
                                item
                                container
                                xs={4}
                                sm={6}
                                className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.applicantProgramButtons} `}
                                sx={{
                                    cursor: isPreviousProgramNull ? 'default' : 'pointer',
                                    ...(isPreviousProgramNull && { color: '#899BB3' }),
                                }}
                                onClick={() => {
                                    if (!isPreviousProgramNull) {
                                        handleOpenDialog();
                                    }
                                }}
                            >
                                <Typography
                                    className={`${AdmissionsStyle.contentCenter} ${AdmissionsStyle.applicantProgramButtonText}`}
                                    sx={{ marginRight: '0.5rem' }}
                                >
                                    Képzés hozzáadása
                                </Typography>
                                <img src={isPreviousProgramNull ? AddTrainingProgramDisabled : AddTrainingProgram} />
                            </Grid>
                        )}
                        {!inactive && admission.trainingProgram && admission.questionnaireFilledDate === null && (
                            <Grid
                                item
                                container
                                xs={4}
                                sm={6}
                                className={`${AdmissionsListStyle.applicantProgramButtons} ${AdmissionsStyle.contentCenter}`}
                                sx={{
                                    cursor: isAdmissionDisabled ? 'default' : 'pointer',
                                }}
                                onClick={() => {
                                    if (!isAdmissionDisabled) {
                                        handleNavigate();
                                    }
                                }}
                            >
                                <Typography
                                    className={`${AdmissionsStyle.bolder} ${AdmissionsListStyle.applicantProgramButtonText}`}
                                >
                                    Felvételi űrlap kitöltése
                                </Typography>
                                {getQuestionnaireIcon()}
                            </Grid>
                        )}
                        {!inactive && admission.trainingProgram && admission.questionnaireFilledDate && (
                            <Grid
                                item
                                container
                                xs={4}
                                sm={6}
                                className={`${AdmissionsListStyle.applicantProgramButtons} ${AdmissionsStyle.contentCenter}`}
                                onClick={handlePreview}
                            >
                                <Typography
                                    className={`${AdmissionsStyle.bolder} ${AdmissionsListStyle.applicantProgramButtonText}`}
                                >
                                    Felvételi űrlap megtekintése
                                </Typography>
                                <RemoveRedEyeIcon className={AdmissionsStyle.checkGreen} />
                            </Grid>
                        )}
                        {admission.canFinalize && (
                            <Grid
                                item
                                container
                                xs={4}
                                sm={6}
                                className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.finalizeButtonContainer}`}
                                onClick={() => handleOpenFinalizeDialog(admission)}
                            >
                                <Typography
                                    className={`${AdmissionsStyle.bolder} ${AdmissionsListStyle.finalizeButton}`}
                                >
                                    Jelentkezés véglegesítése
                                </Typography>
                                <CheckIcon className={AdmissionsStyle.checkGreen} />
                            </Grid>
                        )}
                    </Grid>
                    {!inactive && isProgramSelected && (
                        <Grid item>
                            <Typography className={AdmissionsListStyle.uploadedText}>
                                Feltöltendő: {uploadedDocumentCount}/{maxDocumentCount} kész
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                {!inactive && isProgramSelected && (
                    <Grid item container xs={3} spacing={1} className={AdmissionsListStyle.rightPanelContainer}>
                        <Grid item>{getAdmissionIcon()}</Grid>
                        <Grid item>
                            <Typography className={AdmissionsListStyle.rightPanel}>Jelentkezési határidő:</Typography>
                            <Typography className={AdmissionsListStyle.rightPanel}>
                                {admission.applicationDeadline?.replaceAll('-', '.') || '-'}
                                {admission.applicationDeadline &&
                                    new Date(admission.applicationDeadline) < new Date() &&
                                    ' (Lejárt)'}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
