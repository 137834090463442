import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import { TableButton } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import Form from '../../../components/Form/Form';
import { useStyles } from '../../../pages/Administration/Approvals/RequestFormDialog/ApprovalActualStatusDialog';
import { ForeignScholarshipSemesterStatusForm } from '../../Profile/Tabs/SemesterStatusDialog';
import SemesterStatusDialogForm from '../../Profile/Tabs/SemesterStatusDialogForm';

const SemesterStatusInfoDialog = (row: ForeignScholarshipSemesterStatusForm) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const initialValues: ForeignScholarshipSemesterStatusForm = row;

    return (
        <>
            <TableButton onClick={handleOpen} tooltip={`Változtatások`} color={'info'} className={classes.eyeIcon}>
                <InfoIcon />
            </TableButton>
            <Dialog open={open} onClose={handleClose}>
                <Form fields={[]} onSubmit={() => console.log('TODO')} initialValues={initialValues} hideButtons>
                    {(formikProps) => (
                        <SemesterStatusDialogForm
                            actualStatus={row.actualStatus}
                            handleClose={handleClose}
                            readOnly={true}
                            buttonLoading={false}
                            {...formikProps}
                        />
                    )}
                </Form>
            </Dialog>
        </>
    );
};

export default SemesterStatusInfoDialog;
