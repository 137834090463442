import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TSelfAssessmentDocumentStatus } from '../types';
import { Link } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import useAccessCheck from '../../../../utils/useAccessCheck';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const OperationsCellRenderer = (params: GridRenderCellParams<TSelfAssessmentDocumentStatus>) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const editAccess = useAccessCheck(['md_general_students_edit_detailed']);
    return (
        <>
            {!user.archive && (
                <Link
                    to={
                        editAccess
                            ? clientEndPoints.md_general_students_edit_detailed.replace(
                                  ':id',
                                  '' + params.row.studentId,
                              ) + '?tab=3'
                            : clientEndPoints.md_general_students_list_read_only_show.replace(
                                  ':id',
                                  '' + params.row.studentId,
                              ) + '?tab=3'
                    }
                >
                    <Tooltip title="Hallgató önértékeléseinek megtekintése">
                        <IconButton color="primary" sx={{ borderWidth: '1px', borderStyle: 'solid' }}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </Link>
            )}
        </>
    );
};

export default OperationsCellRenderer;
