export const columns = [
    { headerName: 'Időpont', field: 'createdAt', minWidth: 200 },
    {
        headerName: 'Felhasználó',
        field: 'user',
        cellRenderer: 'actorCellRenderer',
        minWidth: 250,
        sortComparator: () => {
            return 0;
        },
    },
    { headerName: 'Esemény', field: 'action', sortable: false, cellRenderer: 'actionCellRenderer' },
    { headerName: 'Típus', field: 'type', sortable: false, cellRenderer: 'typeCellRenderer' },
    { headerName: 'Változások', field: 'operations', cellRenderer: 'btnCellRenderer', sortable: false },
].map((col) => ({ minWidth: 150, flex: 1, ...col }));
