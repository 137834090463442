import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { ICourseType } from './types';
import { theme } from '../../../utils/theme';
import { Paper } from '../Paper';

const CoCourseInfo = ({
    coCourseCompleted,
    coCourseEnrolled,
    minCoCourseToBeComplete,
}: Omit<ICourseType, 'courseType' | 'id' | 'enrolled' | 'completed' | 'minToBeCompleted'>) => (
    <Grid item container xs={12} justifyContent="flex-end">
        <Grid item xs={6}>
            <Typography textAlign="left">
                <i>Ebből társképzés:</i>
            </Typography>
        </Grid>
        <Grid item xs={4}>
            <Typography textAlign="center">
                <span
                    style={{
                        color:
                            coCourseCompleted < minCoCourseToBeComplete
                                ? theme.palette.warning.main
                                : theme.palette.success.main,
                    }}
                >
                    {coCourseCompleted}
                </span>
                /
                <span style={{ color: coCourseEnrolled === 0 ? theme.palette.error.main : 'black' }}>
                    {coCourseEnrolled}
                </span>
                /<span>{minCoCourseToBeComplete}</span> db
            </Typography>
        </Grid>
    </Grid>
);

export const Row = ({
    courseType,
    completed,
    enrolled,
    minToBeCompleted,
    minCoCourseToBeComplete,
    coCourseCompleted,
    coCourseEnrolled,
}: ICourseType) => {
    if (!courseType)
        return (
            <Grid item xs={12}>
                <Skeleton height={50} />
            </Grid>
        );
    return (
        <Grid item xs={12}>
            <Paper style={{ width: '100%' }}>
                <Grid item container xs={12}>
                    <Grid item xs={8}>
                        <Typography textAlign="left">{courseType}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography textAlign="center">
                            <span
                                style={{
                                    color:
                                        completed < minToBeCompleted
                                            ? theme.palette.warning.main
                                            : theme.palette.success.main,
                                }}
                            >
                                {completed}
                            </span>
                            /
                            <span style={{ color: enrolled === 0 ? theme.palette.error.main : 'black' }}>
                                {enrolled}
                            </span>
                            /<span>{minToBeCompleted}</span> db
                        </Typography>
                    </Grid>
                    {minCoCourseToBeComplete > 0 && (
                        <CoCourseInfo
                            coCourseCompleted={coCourseCompleted}
                            coCourseEnrolled={coCourseEnrolled}
                            minCoCourseToBeComplete={minCoCourseToBeComplete}
                        />
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
};
