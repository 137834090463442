import { FormikListPage } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import semestersService from '../../../../services/masterData/semesters';
import { create } from '../../../../store/notification';
import { paginatorInfoBuild } from '../../../../utils/AppConst';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import fields from './fields';
import { columns, header, getRowData } from './utils';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { BtnCellRenderer } from './BtnCellRenderer';
import { RootState } from '../../../../store';
import { IInfo } from '../../../../utils/Interfaces/IInfo';
import { ISemesterEntity } from './types';
import { useTenant } from '../../../../components/TenantContext';

export default function Semesters() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [data, setData] = useState<ISemesterEntity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const tenant = useTenant();

    useEffect(() => {
        setLoading(true);
        semestersService
            .filter({ 'order[name]': 'desc' })
            .then((response: AxiosResponse) => {
                setData(response.data['hydra:member'] || []);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen betöltés' })))
            .finally(() => setLoading(false));
    }, [dispatch]);

    const loadTable = () => {
        setLoading(true);
        semestersService
            .filter({})
            .then((response: AxiosResponse) => {
                setData(response.data['hydra:member'] || []);
            })
            .finally(() => setLoading(false));
    };

    const onSubmit = (form: IForm) => {
        setLoading(true);
        const info: IInfo<IForm> = {
            filter: form,
            sort: {
                name: 'asc',
            },
            page: 1,
            perpage: 25,
            metadata: {},
        };
        return semestersService
            .filter({ ...form, ...paginatorInfoBuild(info) })
            .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
            .finally(() => setLoading(false));
    };

    return (
        <FormikListPage
            header={header(tenant || 'TAR')}
            filter={{
                onSubmit,
                fields,
            }}
            table={{
                columnDefs: columns,
                rowData: getRowData(data),
                loading: loading,
                frameworkComponents: {
                    btnCellRenderer: (params: GridRenderCellParams) => (
                        <BtnCellRenderer loadTable={loadTable} readonly={!!user.archive} {...params} />
                    ),
                },
                newButton: !user.archive
                    ? {
                          target: clientEndPoints.md_general_semesters_create,
                          text: 'Szemeszter hozzáadása',
                      }
                    : null,
                columnBuffer: columns.length,
                initialState: {
                    pinnedColumns: {
                        right: ['operations'],
                    },
                },
            }}
        />
    );
}
