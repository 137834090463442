import Commute from '@mui/icons-material/Commute';
import Cottage from '@mui/icons-material/Cottage';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PublicIcon from '@mui/icons-material/Public';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TranslationKey } from './types';
import { translation } from './utils';

const StatusBadge = ({
    label,
    value,
    dialog,
}: {
    label: string;
    value: TranslationKey | 'none';
    dialog?: JSX.Element;
}) => {
    const getIcon = () => {
        if (label === 'Szemeszter státusz') {
            if (value === 'active') return <PlayCircleOutlineIcon />;
            if (value === 'passive') return <PauseCircleOutlineIcon />;
            if (value === 'foreign_scholarship') return <PublicIcon />;
        }
        return value === 'external' ? <Commute /> : <Cottage />;
    };

    return (
        <Grid item container>
            <Grid item xs={12}>
                <Typography color="white">{label}</Typography>
            </Grid>
            <Grid item xs={12}>
                {value != 'none' ? (
                    <Chip color="primary" icon={getIcon()} label={translation[value]} />
                ) : (
                    <Chip label={'Nincs aktív szemeszter'} />
                )}
                {dialog}
            </Grid>
        </Grid>
    );
};

export default StatusBadge;
