import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { TabContentProps } from '../TabContent';
import ActualStudies from './ActualStudies/ActualStudies';
import BaseData from './BaseData';
import Contacts from './Contacts';
import LanguageExams from './LanguageExams';
import MCCStudies from './MCCStudies';
import useOtherExperiences from './OtherExperiences';

const useTabs = (): TabContentProps[] => {
    const { user } = useSelector((state: RootState) => state.auth);
    const OtherExperiences = useOtherExperiences();

    return [
        {
            cards: [
                {
                    title: '',
                    content: <BaseData />,
                    cardProps: {
                        style: { height: '100%' },
                    },
                },
                {
                    title: '',
                    content: <Contacts />,
                },
            ],
        },
        {
            cards: [
                {
                    title: '',
                    content: <MCCStudies />,
                    format: { xs: 12 },
                    cardProps: {
                        style: {
                            backgroundColor: '#a1b1c4',
                        },
                    },
                },
                {
                    title: 'Nyelvvizsgák',
                    content: <LanguageExams readOnly={user.archive} />,
                },
                {
                    title: 'Aktuális egyetemi tanulmányok',
                    content: <ActualStudies />,
                },
            ],
        },
        {
            cards: OtherExperiences,
        },
    ].map((e, i) => ({ ...e, value: i }));
};

export default useTabs;
