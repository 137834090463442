import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../utils/theme';

export default function Serial({ serial }: { serial: number }) {
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const useStyle = makeStyles(() => ({
        avatar: {
            backgroundColor: matches ? '#FFFFFF' : '#C1CBD7',
            border: '1px solid #C1CBD7',
            color: '#364354',
            width: '75px',
            height: '50px',
            borderRadius: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            fontWeight: 700,
            ...(!matches && { fontWeight: 700, height: '24px', width: '24px', fontSize: '17px' }),
        },
    }));

    const classes = useStyle();
    return <Avatar className={`${classes.avatar}`}>{serial + (matches ? '. hely' : '')}</Avatar>;
}
