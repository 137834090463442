import Close from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { Dialog, SubmitButton, TableButton } from '@silinfo/front-end-template';
import { useDispatch } from 'react-redux';
import courseEventService from '../../../../services/courseManagement/courseEvent';
import { create } from '../../../../store/notification';
import { IEvent } from './types';

export default function RemoveEvent({
    courseId,
    eventToDelete,
    setLoading,
}: {
    courseId: number;
    eventToDelete: IEvent;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const dispatch = useDispatch();

    const handleDelete = () => {
        setLoading(true);
        courseEventService
            .remove(courseId, eventToDelete.id)
            .then(() => {
                setLoading(false);
                dispatch(create({ type: 'success', message: 'Sikeres törlés!' }));
            })
            .catch(() => {
                console.error('sikertelen törlés');
            });
    };

    return (
        <Dialog
            title="Esemény eltávolítása"
            opener={
                <TableButton color="error" tooltip="Esemény eltávolítása">
                    <Close />
                </TableButton>
            }
            action={<SubmitButton onClick={handleDelete}>Igen</SubmitButton>}
            cancelText="Mégsem"
        >
            <DialogContent>
                Biztosan eltávolítja a(z) {eventToDelete.eventDate} között zajló eseményt a kurzusból?
            </DialogContent>
        </Dialog>
    );
}
