import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FieldGenerator } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { actualStatusOptions } from '../../../../pages/MasterData/General/Students/Form/options';
import { ActualStatusForm } from './ActualStatusDialog';

const ActualStatusDialogForm = (
    props: FormikProps<ActualStatusForm> & {
        actualStatus?: string;
        handleClose: () => void;
        loading: boolean;
        readOnly: boolean;
    },
) => {
    const { actualStatus, handleClose, loading, readOnly } = props;

    return (
        <Grid item container>
            <Grid item xs={12}>
                <DialogTitle>Aktuális státusz módosítása</DialogTitle>
            </Grid>
            <Grid item container xs={12}>
                <DialogContent>
                    {FieldGenerator({
                        ...props,
                        label: 'Aktuális státusz',
                        name: 'actualStatus',
                        type: 'select',
                        options: actualStatusOptions,
                        props: {
                            disabled: true,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                    {FieldGenerator({
                        ...props,
                        label: 'Igényelt státusz',
                        name: 'requestedStatus',
                        type: 'select',
                        options: actualStatusOptions.filter(({ value }) => value !== actualStatus),
                        props: {
                            disabled: readOnly,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                    {FieldGenerator({
                        ...props,
                        label: 'Indoklás',
                        name: 'reason',
                        props: {
                            multiline: true,
                            rows: 4,
                            disabled: readOnly,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                </DialogContent>
            </Grid>
            <Grid item xs={12}>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Bezár
                    </Button>
                    {!readOnly && (
                        <LoadingButton loading={loading} type="submit" variant="contained">
                            Elküldés
                        </LoadingButton>
                    )}
                </DialogActions>
            </Grid>
        </Grid>
    );
};

export default ActualStatusDialogForm;
