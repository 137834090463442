import AccessManagement from '../../pages/Administration/AccessManagement/AccessManagement';

export const accessManagement = {
    path: 'access-management',
    children: [
        {
            path: '',
            element: <AccessManagement />,
            name: 'admin_access_management_list',
        },
    ],
};
