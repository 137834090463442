import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Grid from '@mui/material/Grid';
import { useState, useCallback } from 'react';
import instance from '../../api';
import Tooltip from '@mui/material/Tooltip';
import styles from './RefreshButton.module.scss';
import clsx from 'clsx';

interface RefreshButtonProps {
    toggleRefresh: () => void;
    endpoint: string;
    tooltip: string;
    method?: 'post' | 'get';
    iconColor?: string | null;
}

export default function RefreshButton({ endpoint, toggleRefresh, tooltip, method, iconColor }: RefreshButtonProps) {
    const [loading, setLoading] = useState(false);

    const handleClick = useCallback(() => {
        setLoading(true);
        instance[method || 'post'](endpoint, {})
            .then(toggleRefresh)
            .finally(() => setLoading(false));
    }, [endpoint, toggleRefresh, method]);

    return (
        <Grid item xs={1} display="flex" justifyContent="flex-end">
            <Tooltip title={tooltip}>
                <IconButton
                    color="primary"
                    disabled={loading}
                    onClick={handleClick}
                    className={clsx(loading && styles.spinning)}
                >
                    <RefreshIcon style={{ color: iconColor || 'primary' }} />
                </IconButton>
            </Tooltip>
        </Grid>
    );
}
