import { GridAlignment, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Dispatch, SetStateAction } from 'react';
import { StatusCellRenderer } from './CellRenderers';
import OperationsCellRenderer from './CellRenderers/Operations';

export const header = (tenant: string | null) => {
    return {
        title: 'Önértékelés',
        project: tenant || 'TAR',
        breadcrumbs: {
            selfAssesment: 'Önértékelés',
        },
    };
};

const order = [
    'previousSummary',
    'selfAssessmentQuestionnaire',
    'selfAssessmentConversation',
    'summaryDocument',
    'universityIndex',
    'careerGuidanceSheet',
    'completionSheet',
];

const translations = [
    'Korábbi célkitűzés',
    'Önértékelés és célkitűzés',
    'Önértékelési beszélgetés',
    'Összefoglaló dokumentum',
    'Egyetemi index',
    'Pályaorientációs lap',
    'MCC teljesítési lap',
];

export const names: Record<string, string> = order.reduce(
    (acc, curr, index) => ({
        ...acc,
        [curr]: translations[index],
    }),
    {},
);

const columns = (setRefresh: Dispatch<SetStateAction<boolean>>): GridColDef[] =>
    [
        {
            field: 'name',
            headerName: 'Megnevezés',
            valueGetter: (params: GridValueGetterParams) => names[params.value],
        },
        {
            field: 'status',
            headerName: 'Státusz',
            renderCell: StatusCellRenderer,
        },
        {
            field: 'operations',
            headerName: 'Műveletek',
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
                <OperationsCellRenderer setRefresh={setRefresh} params={params} />
            ),
            align: 'center' as GridAlignment,
            headerAlign: 'center' as GridAlignment,
        },
    ].map((column) => ({
        flex: 1,
        minWidth: 200,
        sortable: false,
        ...column,
    }));

export default {
    header,
    columns,
    order,
};
