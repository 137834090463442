import DialogContent from '@mui/material/DialogContent';
import { Children } from 'react';
import { FieldGenerator } from '../../../../../../components/Form/FieldGenerator';
import { ActualUniversityStudiesDialogFormProps } from './ActualUniversityStudiesDialogForm';
import ForeignUniversityAutomcomplete from './ForeignUniversityAutocomplete';

export default function ActualUniversityStudiesDialogFormContent(props: ActualUniversityStudiesDialogFormProps) {
    const { formikProps, actualUniversityStudiesFields, optionData, row } = props;

    return (
        <DialogContent>
            {Children.toArray(
                actualUniversityStudiesFields.map((field) => {
                    if (field.name === 'university') {
                        return FieldGenerator({
                            ...field,
                            type: 'select',
                            options: optionData,
                            format: { xs: 12 },
                            ...formikProps,
                            props: { ...field.props },
                        });
                    }
                    /**
                     * Here we need to check if the 'university' field === 64, and only then show the 'foreignUniversity' field
                     */
                    if (field.name === 'foreignUniversity') {
                        if (formikProps.values.university == 64) {
                            return (
                                <ForeignUniversityAutomcomplete
                                    key={field.name}
                                    value={formikProps.values[field.name] as string}
                                    onChange={(value) => formikProps.setFieldValue(field.name, value)}
                                    //   disabled={formikProps.values.readonly as boolean}
                                    disabled={false}
                                    errorMessage={formikProps.errors[field.name] as string}
                                />
                            );
                        }
                    } else {
                        return FieldGenerator({
                            ...field,
                            format: { xs: 12 },
                            ...formikProps,
                            ...(field.type === 'file'
                                ? {
                                      downloadUrl: row
                                          ? field.downloadUrl.replace('{documentId}', row.id?.toString())
                                          : field.downloadUrl,
                                  }
                                : {}),
                            props: { ...field.props },
                        });
                    }
                }),
            )}
        </DialogContent>
    );
}
