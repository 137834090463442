import Visibility from '@mui/icons-material/Visibility';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableButton } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { useStyles } from './utils';
import { Link } from 'react-router-dom';

export default function InfoCellRenderer(params: GridRenderCellParams) {
    const classes = useStyles();

    return (
        <Link to={clientEndPoints.admin_email_logs_show.replace(':id', params.row.id.toString())}>
            <TableButton
                color={'info'}
                style={{ marginRight: '5px' }}
                className={classes.eyeIcon}
                tooltip={'Megtekintés'}
            >
                <Visibility />
            </TableButton>
        </Link>
    );
}
