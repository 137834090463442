import { IInfo } from '../../../../utils/Interfaces/IInfo';

export interface IEmailLog {
    id: number;
    emailText: string;
    generatedAt: string;
    mailingAddress: string;
    sentAt: string;
    status: string;
    subject: string;
    type: string;
    user?: {
        firstName: string;
        lastName: string;
        email: string;
    };
}

export const initialEmailLog = {
    id: 0,
    emailText: '',
    generatedAt: '',
    mailingAddress: '',
    sentAt: '',
    status: '',
    subject: '',
    type: '',
    user: {
        firstName: '',
        lastName: '',
        email: '',
    },
};

export interface EmailLogFilter extends Record<string, string> {
    generatedAt: string;
    sentAt: string;
    'sentAt[after]': string;
    'sentAt[before]': string;
    'generatedAt[before]': string;
    'generatedAt[after]': string;
    type: string;
    subject: string;
    status: string;
    mailingAddress: string;
}

export const initialEmailLogFilterState: IInfo<EmailLogFilter> = {
    filter: {},
    sort: {
        generatedAt: 'desc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};
