import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Children, useContext, useState } from 'react';
import Form from '../../../../../components/Form/Form';
import instance from '../../../../../api';
import { LoadingButton } from '@mui/lab';
import { FieldGenerator } from '@silinfo/front-end-template';
import { getFileName, getFilePath, newRow, SectionRow } from './SectionGenerator';
import { createInitialValues } from '@silinfo/front-end-template';
import { useLanguageCertification } from './fields';
import { useSelector } from 'react-redux';
import { IForm } from '../../../../../utils/Interfaces/interfaces';
import DataTable from './DataTable';
import { RootState } from '../../../../../store';
import { useParams } from 'react-router-dom';
import { endpoints } from '../../../../../utils/endPoints';
import ConfirmationDialog from '../../../../Administration/LangExamReport/ConfirmationDialog';
import { DynamicSection } from './interfaces';
import { ReadOnlyContext } from './StudentsForm';
import { Option } from '../../../../../utils/AppConst';
import EndpointProvider from '../../../../../components/EndpointProvider';

export default function LangExamField() {
    const [row, setRow] = useState<SectionRow | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const id = useParams().id as string;
    const { count } = useSelector((state: RootState) => state.loading);
    const { user } = useSelector((state: RootState) => state.auth);
    const section: DynamicSection = {
        name: 'Nyelvvizsga',
        fields: useLanguageCertification(),
        dynamic: true,
        nameInForm: 'languageCertification',
        url: endpoints.masterData.students.languageExams.main.replace('{student}', id),
    };
    const readOnly = useContext(ReadOnlyContext);

    const initialValues = row
        ? {
              ...row,
              ...newRow(row),
              uuidFileName: getFilePath(row),
              origFileName: getFileName(row),
          }
        : (createInitialValues(section.fields) as IForm);

    const handleSubmit = async (form: IForm) => {
        await instance.post(section.url.split('/').slice(0, -1).join('/') + (row ? '/' + row.id : ''), form);
        setOpen(false);
        setConfirmOpen(false);
        setRow(undefined);
        setRefresh((prev) => !prev);
    };

    return (
        <>
            <Grid item xs={12} justifyContent="flex-end">
                {!user.archive && (
                    <EndpointProvider endpoints={['md_general_students_edit_detailed']}>
                        <Button color="success" variant="contained" onClick={() => setOpen(!open)}>
                            Nyelvvizsga hozzáadása
                        </Button>
                    </EndpointProvider>
                )}

                <Dialog open={open || !!row}>
                    <DialogTitle>{'Nyelvvizsga' + (row ? ' szerkesztése' : ' hozzáadása')}</DialogTitle>
                    <Form
                        fields={[]}
                        onSubmit={(form: IForm) => {
                            const validated =
                                form.validated === 'yes' || form.validated === 'no' || form.validated == 'waiting';
                            const initialValidated =
                                initialValues.validated === 'yes' || initialValues.validated === 'no';
                            if (
                                JSON.stringify(form) !== JSON.stringify(initialValues) &&
                                validated &&
                                initialValidated &&
                                form.validatedConfirmation !== 1
                            ) {
                                setConfirmOpen(true);
                            } else {
                                return handleSubmit(form);
                            }
                        }}
                        submitTransformData={(form) =>
                            Object.entries(form).reduce(
                                (prev, [key, value]: [string, unknown]) => ({
                                    ...prev,
                                    [key]: Array.isArray(value)
                                        ? value.map((elem) => elem?.value || elem)
                                        : (value as Option)?.value || value,
                                }),
                                { student: id },
                            ) as IForm
                        }
                        hideButtons
                        initialValues={initialValues}
                    >
                        {(formikProps) => (
                            <Grid item container xs={12}>
                                <Grid item xs={12}>
                                    <DialogContent>
                                        {Children.toArray(
                                            section.fields.map((field) => {
                                                return FieldGenerator({
                                                    ...field,
                                                    format: {
                                                        xs: 12,
                                                    },
                                                    ...formikProps,
                                                    ...(field.type === 'file'
                                                        ? {
                                                              downloadUrl: row
                                                                  ? field.downloadUrl.replace(
                                                                        '{documentId}',
                                                                        row.id?.toString(),
                                                                    )
                                                                  : field.downloadUrl,
                                                          }
                                                        : {}),
                                                    props: {
                                                        ...field.props,
                                                        ...(readOnly ? { disabled: true } : {}),
                                                    },
                                                });
                                            }),
                                        )}
                                    </DialogContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <DialogActions>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                setOpen(false);
                                                setRow(undefined);
                                            }}
                                        >
                                            Mégsem
                                        </Button>
                                        <LoadingButton loading={count > 0} variant="contained" type="submit">
                                            Mentés
                                        </LoadingButton>
                                    </DialogActions>
                                </Grid>
                                <ConfirmationDialog
                                    open={confirmOpen}
                                    setOpen={setConfirmOpen}
                                    handleSubmit={handleSubmit}
                                />
                            </Grid>
                        )}
                    </Form>
                </Dialog>
            </Grid>
            <Grid item xs={12} style={{ overflow: 'auto' }}>
                <DataTable
                    section={section}
                    setRow={setRow}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    readOnly={readOnly}
                    responseKey={'data'}
                />
            </Grid>
        </>
    );
}
