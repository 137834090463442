import Administration from '../../pages/SelfEvaluation/Administration';

export const administration = {
    path: 'administration',
    children: [
        {
            path: '',
            element: <Administration />,
            name: 'administration',
        },
    ],
};
