import Pages from '../../pages/Administration/Pages';
import Versions from '../../pages/Administration/Pages/Versions';
import VersionForm from '../../pages/Administration/Pages/VersionForm';
import PageForm from '../../pages/Administration/Pages/pageForm';

export const pages = {
    path: 'pages',
    children: [
        {
            path: '',
            element: <Pages />,
            name: 'admin_pages_list',
        },
        {
            path: 'new',
            element: <PageForm />,
            name: 'admin_pages_create',
        },
        {
            path: ':pageId/edit',
            element: <PageForm />,
            name: 'admin_pages_edit',
        },
        {
            path: ':pageId/versions',
            element: <Versions />,
            name: 'admin_pages_versions_list',
        },
        {
            path: ':pageId/versions/:versionId/edit',
            element: <VersionForm />,
            name: 'admin_pages_versions_edit',
        },
        {
            path: ':pageId/versions/:versionId/show',
            element: <VersionForm readOnly />,
            name: 'admin_pages_versions_show',
        },
        {
            path: ':pageId/versions/new',
            element: <VersionForm />,
            name: 'admin_pages_versions_create',
        },
    ],
};
