import Grid from '@mui/material/Grid';
import UserIcon from './Icons/UserIcon.svg';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import UserProfileActions from './UserProfileActions';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import AdmissionsStyle from './Admissions.module.scss';

const Divider = () => <div className={`${AdmissionsStyle.divider}`} />;

export default function Header({
    email,
    semester,
    matches,
    status,
}: {
    email: string;
    semester: string;
    matches: boolean;
    status: string;
}) {
    const [openModal, setOpenModal] = useState(false);

    const handleGuide = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const logout = () => {
        window.location.href = clientEndPoints.admission_landing_page;
    };
    const HeaderText = (
        <Typography className={`${AdmissionsStyle.bold} ${AdmissionsStyle.headerTitle}`}>
            {matches ? `MCC ${semester}i szemeszter - Felvételi adatlap` : semester}
        </Typography>
    );
    return (
        <div className={`${AdmissionsStyle.flex} ${AdmissionsStyle.wide}`}>
            <>
                {matches && (
                    <Grid className={`borderRadius ${AdmissionsStyle.headerUserModule}`}>
                        <Grid item container className={`${AdmissionsStyle.contentCenter} ${AdmissionsStyle.flex}`}>
                            <Grid
                                item
                                container
                                className={`${AdmissionsStyle.contentCenter} ${AdmissionsStyle.headerUserModulePadding}`}
                            >
                                <img src={UserIcon} className={`${AdmissionsStyle.headerUserProfileIcon}`} />
                                <Grid item>
                                    <Typography>
                                        {email}
                                        <Divider />
                                        <UserProfileActions
                                            handleGuide={handleGuide}
                                            openModal={openModal}
                                            handleCloseModal={handleCloseModal}
                                            logout={logout}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </>
            {status === 'open' ? (
                HeaderText
            ) : (
                <Typography className={`${AdmissionsStyle.bold} ${AdmissionsStyle.headerClosed}`}>
                    Felvételi adatlap
                </Typography>
            )}
        </div>
    );
}
