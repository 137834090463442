import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { ICourseResponse, IStudentResponse } from './types';
import { PAGE_SIZE, isCourseResponse } from './utils';

export const Content = ({ response, page }: { response: (ICourseResponse | IStudentResponse)[]; page: number }) => {
    const { semesterId } = useParams() as { semesterId: string };
    if (isCourseResponse(response[0])) {
        const courses = (response as ICourseResponse[]).slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
        return (
            <ul>
                {courses.map((course) => (
                    <li key={course.id}>{course.course}</li>
                ))}
            </ul>
        );
    }

    const students = (response as IStudentResponse[]).slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Hallgató</TableCell>
                    <TableCell>Felvett kurzusok</TableCell>
                    <TableCell>Minimum teljesítendő</TableCell>
                    <TableCell>Minimum teljesítendő kötelező kurzusok</TableCell>
                    <TableCell>Minimum teljesítendő kurzusok társképzésből</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {students.map((student) => (
                    <TableRow key={student.studentId}>
                        <TableCell>{student.student}</TableCell>
                        <TableCell>
                            {student.enrolled[0] === student.enrolled[1]
                                ? student.enrolled[0]
                                : student.enrolled.join(' - ')}
                        </TableCell>
                        <TableCell>{student.minToBeCompleted}</TableCell>
                        <TableCell>{student.mandatoryCoursesCountRequirement}</TableCell>
                        <TableCell>{student.coTrainingCoursesCountRequirement}</TableCell>
                        <TableCell>
                            <Link
                                to={
                                    clientEndPoints.md_general_students_edit_detailed.replace(
                                        ':id',
                                        '' + student.studentId,
                                    ) +
                                    '?tab=1&trainingProgram=' +
                                    student.trainingProgram +
                                    '&semester=' +
                                    semesterId
                                }
                            >
                                <IconButton color="primary">
                                    <VisibilityIcon />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
