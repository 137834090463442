import { DataGridProProps, getGridSingleSelectOperators, GridColDef } from '@mui/x-data-grid-pro';
import { Input, translation } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction } from 'react';
import { scoringTypeOptions } from '../../pages/MasterData/Courses/CourseTypes/fields';
import { Option, paginatorInfoBuild } from '../../utils/AppConst';
import { TooltipCellRenderer } from '../../utils/AppConstElements';
import { IInfo } from '../../utils/Interfaces/IInfo';
import { IForm } from '../../utils/Interfaces/interfaces';
import './CourseEnrollment.scss';
import EnrolledUserRenderer from './EnrolledUserRenderer';
import HeadCountCellRenderer from './HeadCountCellRenderer';
import InstructorCellRenderer from './InstructorCellRenderer';
import OperationsCellRenderer from './OperationsCellRenderer';
import PointCellRenderer from './PointCellRenderer';
import { TypeCellRenderer } from './TypeCellRenderer';
import { IFilterOptions } from './types';
import Tooltip from '@mui/material/Tooltip';

export const makeQuery = (obj: IForm, prefix?: string): string => {
    const str = [];
    for (const p in obj) {
        if (Object.getOwnPropertyDescriptor(obj, p)) {
            const k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p] as IForm;
            str.push(typeof v === 'object' ? makeQuery(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
        }
    }

    return str.filter((e) => e.split('=')[1]).join('&');
};

export const makeUrl = (url: string, params: IInfo<IForm>) =>
    url + (makeQuery(params) ? '?' + makeQuery(paginatorInfoBuild(params)) : '');

export const initialFilter: IForm = {
    courseName: '',
    courseCode: '',
    courseType: [],
    campus: [],
    instructor: '',
    deadline: {
        after: '',
        before: '',
    },
    headcount: {
        gte: '',
        lte: '',
    },
    enrolled: [],
};

export const initialInfo: IInfo<IForm> = {
    perpage: 25,
    page: 1,
    count: 0,
    sort: { isEnrolled: 'desc' },
    metadata: {
        'allCount': 0,
        'filteredCount': 0,
        'lastPage': 1,
        'page': 1,
        'perpage': 25,
        'rowCount': 0,
    },
    filter: initialFilter,
};

export const header = (tenant: string | null) => {
    return {
        title: 'Kurzusfelvétel',
        project: tenant || 'TAR',
        breadcrumbs: {
            courseDescription: 'Kurzusfelvétel',
        },
    };
};

const singSelectFilterOperators = getGridSingleSelectOperators().filter((operator) => operator.value === 'is');

export const columns = (setRefresh: Dispatch<SetStateAction<boolean>>, isEnrolled: boolean): GridColDef[] => [
    {
        headerName: '',
        field: 'indicator',
        sortable: false,
        resizable: false,
        align: 'left' as const,
        flex: 0,
        minWidth: 3,
        width: 3,
        renderCell: (params) => {
            if (params.row?.courseType !== 'Megosztott') {
                return <></>;
            }

            return (
                <Tooltip title={'Megosztott kurzus'}>
                    <div style={{ background: '#35A899', width: '10px', height: '100%', marginLeft: '-10px' }} />
                </Tooltip>
            );
        },
    },
    {
        headerName: '',
        field: 'Kategória',
        renderCell: (params) => <TypeCellRenderer type={params.row.type} />,
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        minWidth: 50,
        maxWidth: 50,
    },
    {
        headerName: 'Kurzus neve',
        field: 'courseName',
        renderCell: (params) => <TooltipCellRenderer params={params} />,
        minWidth: 250,
        headerAlign: 'center',
    },
    {
        headerName: 'Kurzustípus',
        field: 'courseTypeReal',
        renderCell: (params) => <TooltipCellRenderer params={params} />,
        minWidth: 200,
        headerAlign: 'center',
    },
    {
        headerName: 'Kurzuskód',
        field: 'courseCode',
        renderCell: (params) => <TooltipCellRenderer params={params} />,
        minWidth: 130,
        maxWidth: 130,
        headerAlign: 'center',
    },
    {
        headerName: 'Campus',
        field: 'campus',
        renderCell: (params) => <TooltipCellRenderer params={params} />,
        minWidth: 150,
        headerAlign: 'center',
    },
    {
        headerName: 'Oktató',
        field: 'instructor',
        renderCell: (params) => <InstructorCellRenderer params={params} />,
        minWidth: 180,
        sortable: false,
        headerAlign: 'center',
    },
    {
        headerName: 'Érték',
        field: 'maximumAchievablePoint',
        renderCell: (params) => <PointCellRenderer params={params} />,
        minWidth: 90,
        maxWidth: 90,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
    },
    {
        headerName: 'Létszám',
        field: 'headCount',
        renderCell: (params) => <HeadCountCellRenderer params={params} />,
        minWidth: 100,
        maxWidth: 100,
        align: 'center',
        filterable: false,
        headerAlign: 'center',
    },
    {
        headerName: 'Határidő',
        field: 'enrollmentEndDate',
        renderCell: (params) => <TooltipCellRenderer params={params} />,
        minWidth: 100,
        maxWidth: 100,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
    },
    {
        headerName: 'Felvett',
        field: 'isEnrolled',
        renderCell: (params) => <EnrolledUserRenderer params={params} />,
        type: 'singleSelect',
        filterOperators: singSelectFilterOperators,
        valueOptions: [
            { value: 'yes', label: 'Igen' },
            { value: 'no', label: 'Nem' },
            { value: 'waiting_list', label: 'Várólistán' },
        ],
        minWidth: 50,
        maxWidth: 80,
        align: 'center',
        headerAlign: 'center',
    },
    {
        headerName: '',
        field: 'Műveletek',
        renderCell: (params) => (
            <OperationsCellRenderer params={params} setRefresh={setRefresh} isEnrolled={isEnrolled} />
        ),
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        minWidth: 50,
        maxWidth: 50,
    },
];

export const partialTableProps: Partial<DataGridProProps> = {
    density: 'comfortable',
    classes: {
        cell: 'noBorder',
    },
    localeText: translation,
    columnBuffer: 7,
    paginationMode: 'server',
    pagination: true,
    rowsPerPageOptions: [25, 50, 100],
    sx: {
        '& .enrolled': {
            color: '#000',
            backgroundColor: '#ffed8a66',
            ':hover': {
                backgroundColor: '#ffed8a',
            },
        },
        '& .waiting': {
            color: '#000',
            backgroundColor: '#ccd0e366',
            ':hover': {
                backgroundColor: '#ccd0e3',
            },
        },
    },
    filterMode: 'server',
};

const fullOptions: Option[] = [
    { value: '0', label: 'Szabad' },
    { value: '1', label: 'Betelt' },
];

const closedOptions: Option[] = [
    { value: '0', label: 'Aktív' },
    { value: '1', label: 'Lejárt' },
];

const isEnrolledOptions: Option[] = [
    { value: 'yes', label: 'Igen' },
    { value: 'no', label: 'Nem' },
    { value: 'waiting_list', label: 'Várólistán' },
    { value: 'course_enrollment', label: 'Felvételi kérelem jóváhagyás alatt' },
    { value: 'course_dismissal', label: 'Kurzusleadási kérelem jóváhagyás alatt' },
];

const filterFields = (options: IFilterOptions): Input[] => [
    { name: 'courseName', label: 'Kurzus neve', format: { xs: 12 } },
    { name: 'courseType', label: 'Kurzustípus', format: { xs: 12 }, type: 'multiselect', options: options.courseTypes },
    { name: 'courseCode', label: 'Kurzuskód', format: { xs: 12 } },
    { name: 'campus', label: 'Campus', format: { xs: 12 }, type: 'multiselect', options: options.campuses },
    { name: 'instructor', label: 'Oktató', format: { xs: 12 } },
    { name: 'pointMin', label: 'Érték (-tól)', format: { xs: 12, md: 6 } },
    { name: 'pointMax', label: 'Érték (-ig)', format: { xs: 12, md: 6 } },
    {
        name: 'isFull',
        label: 'Létszám',
        type: 'multiselect',
        options: fullOptions,
        format: { xs: 12 },
    },
    {
        name: 'isClosed',
        label: 'Kurzusfelvételi időszak',
        type: 'multiselect',
        format: { xs: 12 },
        options: closedOptions,
    },
    { name: 'enrollmentEndDate', label: 'Kurzusfelvétel elérhető (-ig)', type: 'date', format: { xs: 12 } },
    {
        name: 'isEnrolled',
        label: 'Felvett',
        type: 'multiselect',
        options: isEnrolledOptions,
        format: { xs: 12 },
    },
    {
        name: 'scoringType',
        label: 'Pontozás típusa',
        type: 'multiselect',
        options: scoringTypeOptions,
        format: {
            xs: 12,
        },
    },
];

export {
    filterFields,
    fullOptions,
    closedOptions,
    isEnrolledOptions,
    translation,
    singSelectFilterOperators,
    scoringTypeOptions,
};
