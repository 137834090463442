import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useCallback, useContext } from 'react';
import instance from '../../../../api';
import PDFDownloader from '../../../../components/PDFDownloader';
import { STATUS } from '../../../../studentPages/SelfAssessment/CellRenderers/Status/utils';
import SemesterContext from '../../../../components/SemesterSelector/SemesterContextProvider';
import { endpoints } from '../../../../utils/endPoints';
import ComponentToPrint from './../../../MasterData/General/Students/StudentView/CollapsibleTable/ComponentToPrint';
import ConfirmationDialog from './ConfirmationDialog';
import SelfAssessmentQuestionnaireCellRenderer from './SelfAssessmentQuestionnaire';
//import SummaryDocumentCellRenderer from './SummaryDocumentCellRenderer';
import UniversityIndexCellRenderer from './UniversityIndexCellRenderer';
import { componentMap, confirmationTextsByKey, conversationMapper } from './utils';
import RefreshContext from '../../../../studentPages/SelfAssessment/RefreshContextProvider';
import PreviousSummaryDocumentCellRenderer from './PreviousSummaryDocumentCellRenderer';
import CareerGuidanceSheetCellRenderer from './CareerGuidanceSheetCellRenderer';

const DocumentCellRenderer = (params: GridRenderCellParams) => {
    const { value } = params;
    const semesterFromContext = useContext(SemesterContext);
    const semester = params.row.semesterId || semesterFromContext;
    const key = params.colDef.field;
    const refresh = useContext(RefreshContext);

    const Opener = useCallback(
        (props: ButtonProps) => (
            <IconButton {...props}>{componentMap[conversationMapper.get(value) || 'none']}</IconButton>
        ),
        [value],
    );
    const update = useCallback(
        (yes?: boolean) =>
            instance
                .post(endpoints.selfEvaluation.report.update(semester, '' + params.id), {
                    selfEvaluationConversation: !!yes,
                })
                .then(refresh),
        [params.id, semester, refresh],
    );
    const handleYes = useCallback(() => update(true), [update]);
    const handleNo = useCallback(() => update(false), [update]);

    if (value === 'none') {
        return componentMap.none;
    }

    if (key === 'completionSheet') {
        return (
            <PDFDownloader
                url={endpoints.selfEvaluation.report.pdf(semester, '' + params.id)}
                ComponentToPrint={ComponentToPrint}
            />
        );
    }

    if (key === 'previousSummary') {
        return <PreviousSummaryDocumentCellRenderer {...params} />;
    }

    if (key === 'selfAssessmentQuestionnaire') {
        return <SelfAssessmentQuestionnaireCellRenderer {...params} />;
    }

    /*
    if (key === 'summaryDocument') {
        return <SummaryDocumentCellRenderer {...params} />;
    }
     */

    if (key === 'universityIndex') {
        return <UniversityIndexCellRenderer {...params} />;
    }

    if (key === 'careerGuidanceSheet') {
        return <CareerGuidanceSheetCellRenderer {...params} />;
    }

    if (key === 'selfAssessmentConversation' && params.row.selfAssessmentQuestionnaire === STATUS.COMPLETED) {
        return (
            <ConfirmationDialog
                Opener={Opener}
                confirmationText={confirmationTextsByKey[key] || 'Biztosan szeretné visszaállítani a dokumentumot?'}
                handleNo={handleNo}
                handleYes={handleYes}
            />
        );
    }

    return componentMap.none;
};

export default DocumentCellRenderer;
