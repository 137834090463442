import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { TableButton } from '@silinfo/front-end-template';
import useOpen from '../../utils/useOpen';
import Dialog from '@mui/material/Dialog';
import ActionDialogContent from './ActionDialogContent';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { clientEndPoints } from '../../utils/clientEndPoints';

export default function Operations(params: GridRenderCellParams) {
    const { isOpen, open, close } = useOpen();

    const openQuestionnaire = useCallback(() => {
        window.open(clientEndPoints.student_questionnaires_fill.replace(':id', '' + params.row.courseId), '_blank');
    }, [params.row.courseId]);

    const handleOpen = () => {
        if (params.row.anonym) {
            openQuestionnaire();
        } else {
            open();
        }
    };

    return (
        <>
            <TableButton color="primary" onClick={handleOpen}>
                <DriveFileRenameOutlineIcon />
            </TableButton>
            <Dialog open={isOpen} onClose={close} fullWidth>
                <ActionDialogContent close={close} isAnonymous={!!params.row.anonym} id={+params.row.courseId} />
            </Dialog>
        </>
    );
}
