import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Input } from '@silinfo/front-end-template';
import { useDispatch } from 'react-redux';
import API from '../../api';
import Form from '../../components/Form/Form';
import { create } from '../../store/notification';
import { endpoints } from '../../utils/endPoints';

const fields: Input[] = [
    { name: 'oldPassword', label: 'Jelenlegi jelszó', type: 'password', format: { xs: 12 } },
    { name: 'password', label: 'Új jelszó', type: 'password', format: { xs: 12 } },
    { name: 'confirmPassword', label: 'Új jelszó megint', type: 'password', format: { xs: 12 } },
];

function ChangePassword(props: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
    const dispatch = useDispatch();
    const { open, setOpen } = props;

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Jelszóváltoztatás</DialogTitle>
            <DialogContent>
                <Form
                    fields={fields}
                    onSubmit={(data, helpers) => {
                        API.post(endpoints.changePassword, data)
                            .then((response) => {
                                setOpen(false);
                                dispatch(create({ type: 'success', message: response.data.message }));
                            })
                            .catch((error) => {
                                console.error(error.response.data);
                                helpers.setErrors(error.response.data);
                                if (error.response.status === 422 && error.response.data.errorMessage) {
                                    dispatch(create({ type: 'error', message: error.response.data.errorMessage }));
                                } else {
                                    dispatch(
                                        create({
                                            type: 'error',
                                            message: 'Hiba lépett fel a jelszóváltoztatás közben.',
                                        }),
                                    );
                                }
                            });
                    }}
                    initialValues={{
                        oldPassword: '',
                        password: '',
                        confirmPassword: '',
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}

export default ChangePassword;
