import { ChangeEvent, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from './Divider';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { useDispatch } from 'react-redux';
import { create } from '../../../store/notification';
import { PartialPoint, ScoringDialogProps, UploadableDocument } from './types';

export default function ScoringDialog({
    openState,
    semester,
    applicantState,
    programState,
    disabledState,
    toggleRefresh,
}: ScoringDialogProps) {
    const [open, setOpen] = openState;
    const [currentApplicantId, setCurrentApplicantId] = applicantState;
    const [currentProgramId, setCurrentProgramId] = programState;
    const [maxPoints, setMaxPoints] = useState<number>(0);
    const [achievedPoints, setAchievedPoints] = useState<number>(0);
    const [partialPoints, setPartialPoints] = useState<PartialPoint[]>([]);
    const [uploadableDocuments, setUploadableDocuments] = useState<UploadableDocument[]>([]);
    const [isCurrentRowDisabled, setIsCurrentRowDisabled] = disabledState;

    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        setCurrentApplicantId(null);
        setCurrentProgramId(null);
        setPartialPoints([]);
        setUploadableDocuments([]);
        setIsCurrentRowDisabled(false);
        setAchievedPoints(0);
    };

    const handleChangePartialPoints = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        partialPointId: number,
        total: number,
    ) => {
        const partialPointsMap = [...partialPoints];
        partialPointsMap.forEach((el) => {
            if (el.partialPointId === partialPointId) {
                if (!e.target.value) {
                    el.achieved = 0;
                } else {
                    const newValue = parseInt(e.target.value, 10);
                    if (typeof newValue === 'number' && newValue <= total) {
                        el.achieved = newValue;
                    }
                }
                setPartialPoints(partialPointsMap);
                countPoints();
            }
        });
    };

    const handleChangeUploadableDocuments = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        documentId: number,
        total: number,
    ) => {
        const documentsMap = [...uploadableDocuments];
        documentsMap.forEach((el) => {
            if (el.admissionDocumentId === documentId) {
                if (!e.target.value) {
                    el.achieved = 0;
                } else {
                    const newValue = parseInt(e.target.value, 10);
                    if (typeof newValue === 'number' && newValue <= total) {
                        el.achieved = newValue;
                    }
                }
                setUploadableDocuments(documentsMap);
                countPoints();
            }
        });
    };

    const countPoints = () => {
        let partialPointCount = 0;
        partialPoints.forEach((e) => {
            partialPointCount += e.achieved;
        });
        uploadableDocuments.forEach((e) => {
            partialPointCount += e.achieved;
        });
        setAchievedPoints(partialPointCount);
    };

    const handleSave = () => {
        if (currentApplicantId && currentProgramId) {
            const partialBody = partialPoints.map((e) => ({
                partialPointId: e.partialPointId,
                achieved: e.achieved,
                ...(e.programPointId && { programPointId: e.programPointId }),
            }));
            const documentBody = uploadableDocuments.map((e) => ({
                admissionDocumentId: e.admissionDocumentId,
                achieved: e.achieved,
                ...(e.programPointId && { programPointId: e.programPointId }),
            }));
            const body = {
                partialPoints: partialBody,
                uploadableDocuments: documentBody,
            };
            API.post(endpoints.admission.savePoints(semester.value, currentApplicantId, currentProgramId), body)
                .then(() => {
                    dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
                    toggleRefresh();
                    handleClose();
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(create({ type: 'error', message: 'Sikertelen mentés!' }));
                });
        }
    };

    useEffect(() => {
        if (currentApplicantId && currentProgramId && open) {
            API.get(endpoints.admission.getPoints(semester.value, currentApplicantId, currentProgramId)).then(
                (response) => {
                    setMaxPoints(response.data.maxPoints);
                    setAchievedPoints(response.data.achievedPoints);
                    setPartialPoints(response.data.partialPoints);
                    setUploadableDocuments(response.data.uploadableDocuments);
                },
            );
        }
    }, [semester.value, currentApplicantId, currentProgramId, open]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Jelentkező által elért pontok</DialogTitle>
            <DialogContent>
                <Grid container sx={{ marginTop: '1rem', flexDirection: 'column' }}>
                    <Grid container item sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ color: '#595959' }}>Elért pontszám összesen:</Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                disabled
                                value={achievedPoints}
                                sx={{ width: 60, marginRight: '0.5rem' }}
                                inputProps={{ style: { textAlign: 'center' } }}
                            />
                            / {maxPoints}
                        </div>
                    </Grid>
                    <Divider />
                    <Grid container item sx={{ flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: 700, marginBottom: '1rem' }}>Részpontok</Typography>
                        {partialPoints.map((e, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '1rem',
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    disabled
                                    value={e.name}
                                    sx={{ width: '80%', marginRight: '2rem' }}
                                />
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={e.achieved}
                                    sx={{ width: 40, marginRight: '0.5rem' }}
                                    inputProps={{
                                        max: e.total,
                                        style: { textAlign: 'center', paddingLeft: 0, paddingRight: 0 },
                                    }}
                                    onChange={(event) => handleChangePartialPoints(event, e.partialPointId, e.total)}
                                    disabled={isCurrentRowDisabled}
                                />
                                / {e.total}
                            </div>
                        ))}
                    </Grid>
                    <Divider />
                    <Grid container item sx={{ flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: 700, marginBottom: '1rem' }}>Feltöltendő dokumentumok</Typography>
                        {uploadableDocuments.map((e, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '1rem',
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={e.name}
                                    sx={{ width: '80%', marginRight: '2rem' }}
                                    disabled
                                />
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={e.achieved}
                                    sx={{ width: 40, marginRight: '0.5rem' }}
                                    inputProps={{
                                        max: e.total,
                                        style: { textAlign: 'center', paddingLeft: 0, paddingRight: 0 },
                                    }}
                                    onChange={(event) =>
                                        handleChangeUploadableDocuments(event, e.admissionDocumentId, e.total)
                                    }
                                    disabled={isCurrentRowDisabled}
                                />
                                / {e.total}
                            </div>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    disabled={(!currentApplicantId && !currentProgramId) || isCurrentRowDisabled}
                >
                    Mentés
                </Button>
                <Button onClick={handleClose} variant="outlined">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
}
