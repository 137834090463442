import Download from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import GridRenderCellParamsContext from './GridRenderCellParamsContextProvider';
import { useCallback, useContext, useState } from 'react';
import { handleExportClick } from '../Export/utils';

export default function ExcelExport() {
    const [loading, setLoading] = useState(false);
    const params = useContext(GridRenderCellParamsContext);
    const handleClick = useCallback(() => {
        if (!params) return;
        handleExportClick({ id: params.row.id, setLoading });
    }, [params]);

    return (
        <TableCell colSpan={1} align="right">
            <Tooltip title="Excel letöltése">
                <IconButton size="large" color="primary" aria-label="download" component="span" onClick={handleClick}>
                    {loading ? <CircularProgress size={24} /> : <Download />}
                </IconButton>
            </Tooltip>
        </TableCell>
    );
}
