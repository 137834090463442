import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { theme } from '../../../../../utils/theme';
import { LanguageExam } from '../types';

const Validation = ({ languageExam }: { languageExam: LanguageExam }) => (
    <Grid item container alignItems="center" xs={12} style={{ padding: '8px' }}>
        {languageExam.validated === 'yes' ? (
            <Check color="success" style={{ marginRight: '4px' }} />
        ) : (
            <Close color="error" style={{ marginRight: '4px' }} />
        )}
        <Typography
            style={{
                color: theme.palette[languageExam.validated === 'yes' ? 'success' : 'error'].main,
            }}
            variant="h6"
        >
            {languageExam.validated === 'yes' ? 'Validált' : 'Nem validált'}
        </Typography>
    </Grid>
);

export default Validation;
