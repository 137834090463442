import Documents from '../../pages/Administration/Documents';
import DocumentForm from '../../pages/Administration/Documents/DocumentForm';

export const documents = {
    path: 'documents',
    children: [
        {
            path: '',
            element: <Documents />,
            name: 'admin_documents',
        },
        {
            path: 'new',
            element: <DocumentForm />,
            name: 'admin_documents_create',
        },
        {
            path: ':id/edit',
            element: <DocumentForm />,
            name: 'admin_documents_edit',
        },
        {
            path: ':id/show',
            element: <DocumentForm readOnly={true} />,
            name: 'admin_documents_show',
        },
    ],
};
