import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelButton, SubmitButton } from '@silinfo/front-end-template';
import pagesService from '../../../services/administration/pages';
import { useState } from 'react';
import { RootState } from '../../../store';
import i18n from '../../../i18n';
import { IPrivacyStatement, IUser } from '../../../utils/Interfaces/interfaces';
import { setUser } from '../../../store/auth';
import { endpoints } from '../../../utils/endPoints';

export default function PrivacyStatementDialog() {
    const [loading, setLoading] = useState(false);
    const { count } = useSelector((state: RootState) => state.loading);
    const { user, activePrivacyStatement } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const getContent = (user: IUser, activePrivacyStatement?: IPrivacyStatement) =>
        activePrivacyStatement?.translations?.[user?.lang || 'hu']?.content || '';

    const handleSubmit = () => {
        if (!activePrivacyStatement?.id) return;
        setLoading(true);
        pagesService
            .setPrivacyStatement(activePrivacyStatement?.id || 0)
            .then(() => {
                if (user?.realUser) {
                    dispatch(
                        setUser({
                            ...user,
                            realUser: { ...user.realUser, acceptedPrivacyStatement: activePrivacyStatement.id },
                        }),
                    );
                } else {
                    dispatch(setUser({ ...user, acceptedPrivacyStatement: activePrivacyStatement.id }));
                }
            })
            .finally(() => setLoading(false));
    };

    const handleLogout = () => {
        window.location.href = endpoints.logout;
    };

    return (
        <Dialog
            maxWidth={'xl'}
            open={
                !!(
                    (!count || (count && loading)) &&
                    Boolean(
                        activePrivacyStatement?.id &&
                            activePrivacyStatement?.id !==
                                (user?.realUser
                                    ? user?.realUser?.acceptedPrivacyStatement
                                    : user?.acceptedPrivacyStatement),
                    )
                )
            }
        >
            <DialogTitle>{i18n.t('common.privacy_statement') as string}</DialogTitle>
            <DialogContent>
                <DialogContentText dangerouslySetInnerHTML={{ __html: getContent(user, activePrivacyStatement) }} />
            </DialogContent>
            <DialogActions>
                <SubmitButton onClick={handleSubmit} loading={loading}>
                    Elfogadom
                </SubmitButton>
                <CancelButton onClick={handleLogout} disabled={loading}>
                    Kijelentkezés
                </CancelButton>
            </DialogActions>
        </Dialog>
    );
}
