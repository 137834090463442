import { settings } from './Settings';
import { accessManagement } from './AccessManagement';
import { pages } from './Pages';
import { langExamReport } from './LangExamReport';
import { approvals } from './Approvals';
import { notifications } from './Notifications';
import { eventLogs } from './SystemLog/EventLogs';
import { systemLogs } from './SystemLog/SystemLogs';
import { emailLogs } from './SystemLog/EmailLogs';
import { courseCompletions } from './CourseCompletions';
import { documents } from './Documents';
import { questionnaireTemplates } from './QuestionnaireTemplates';
import { log } from './Log';
import { courseClosing } from './CourseClosing';
import { apiKeys } from './ApiKeys';
import { certificates } from './Certificates';

export const administration = {
    path: 'administration',
    children: [
        settings,
        apiKeys,
        systemLogs,
        emailLogs,
        eventLogs,
        log,
        pages,
        certificates,
        langExamReport,
        approvals,
        accessManagement,
        notifications,
        courseCompletions,
        documents,
        questionnaireTemplates,
        courseClosing,
    ],
};
