import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getBase64 } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import LayoutContext from './LayoutContext';

interface ProfilePictureState {
    loading: boolean;
    profilePicture: string;
    profilePictureUrl: string;
}

export default function useProfilePicture(): ProfilePictureState {
    const [loading, setLoading] = useState(false);
    const [profilePicture, setProfilePicture] = useContext(LayoutContext).profilePictureState;
    const { user } = useSelector((state: RootState) => state.auth);
    const profilePictureUrl = `${endpoints.fileManagement.download}?id=${user.id}&type=profile-image`;

    useEffect(() => {
        setLoading(true);
        getBase64(profilePictureUrl)
            .then(setProfilePicture)
            .finally(() => setLoading(false));
    }, [profilePictureUrl, setProfilePicture]);
    return { loading, profilePicture, profilePictureUrl };
}
