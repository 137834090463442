import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { CancelButton, Loading } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FieldGenerator } from '../../../../../../../components/Form/FieldGenerator';
import { RootState } from '../../../../../../../store';
import trainingProgramRequirementCourseTypesService from '../../../../../../../services/masterData/trainingProgramRequirementCourseTypes';
import { useParams } from 'react-router-dom';
import { ICourseTypeEditForm } from './EditButton';

export default function UpdateCourseTypeRequirementForm({
    submitBtnTitle,
    handleClose,
    courseTypeId,
    ...props
}: FormikProps<ICourseTypeEditForm> & { submitBtnTitle: string; handleClose: () => void; courseTypeId?: string }) {
    const { count } = useSelector((state: RootState) => state.loading);
    const { id, programId, campusId } = useParams();
    const { values, setValues } = props;

    useEffect(() => {
        trainingProgramRequirementCourseTypesService.show(id, programId, campusId, courseTypeId).then((response) => {
            response.data.data.numberOfCoursesNotCounting = response.data.data.numberOfCoursesNotCounting
                ? response.data.data.numberOfCoursesNotCounting
                : 0;
            setValues(response.data.data);
        });
    }, [campusId, courseTypeId, id, programId, setValues]);

    if (count) {
        return <Loading />;
    }

    return (
        <>
            {FieldGenerator({
                ...{
                    name: 'type',
                    label: 'Követelmény',
                    format: { xs: 12, sm: 12, md: 12 },
                    type: 'select',
                    options: [
                        { value: 'optional', label: 'Szabadon választható' },
                        { value: 'mandatory', label: 'Kötelezően választható' },
                    ],
                },
                ...props,
            })}
            {values.type === 'mandatory' &&
                FieldGenerator({
                    ...{
                        name: 'numberOfCoursesToBeCompleted',
                        label: 'Képzés alatt teljesítendő kurzusok száma',
                        format: { xs: 12, sm: 12, md: 12 },
                    },
                    ...props,
                })}
            {values.type === 'mandatory' &&
                FieldGenerator({
                    ...{
                        name: 'numberOfCoursesToBeCompletedPerSemester',
                        label: '1 szemeszter alatt teljesítendő kurzusok száma',
                        format: { xs: 12, sm: 12, md: 12 },
                    },
                    ...props,
                })}
            {values.type === 'mandatory' &&
                FieldGenerator({
                    ...{
                        name: 'minAmountToBeCompletedFromOtherTrainingPrograms',
                        label: 'Minimum kurzusszám szemeszterenként társképzésből',
                        format: { xs: 12, sm: 12, md: 12 },
                    },
                    ...props,
                })}
            {FieldGenerator({
                ...{
                    name: 'visibleOnCompletionPage',
                    label: 'Teljesítési igazoláson megjelenik',
                    type: 'checkbox',
                    format: { xs: 12, sm: 12, md: 12 },
                },
                ...props,
            })}
            {FieldGenerator({
                ...{
                    name: 'worthPoint',
                    label: 'Pontot ér',
                    type: 'checkbox',
                    format: { xs: 12, sm: 12, md: 12 },
                },
                ...props,
            })}
            {FieldGenerator({
                ...{
                    name: 'includedInTheScholarship',
                    label: 'Ösztöndíjba beleszámít',
                    type: 'checkbox',
                    format: { xs: 12, sm: 12, md: 12 },
                },
                ...props,
            })}
            {FieldGenerator({
                ...{
                    name: 'numberOfCoursesNotCounting',
                    label: 'Pontozásba nem számító kurzusok száma (szemeszterenként)',
                    format: { xs: 12, sm: 12, md: 12 },
                    props: {
                        inputProps: { maxLength: 8 },
                    },
                },
                ...props,
            })}
            <Grid item container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <LoadingButton loading={count > 0} variant="contained" type="submit">
                        {submitBtnTitle}
                    </LoadingButton>
                </Grid>
                <Grid item>
                    <CancelButton onClick={handleClose}>Bezár</CancelButton>
                </Grid>
            </Grid>
        </>
    );
}
