import Delete from '@mui/icons-material/Delete';
import DialogContent from '@mui/material/DialogContent';
import { Dialog, SubmitButton, TableButton } from '@silinfo/front-end-template';
import { useDispatch } from 'react-redux';
import coursePrerequisitesService, {
    ICoursePrerequisite,
} from '../../../../services/masterData/courses/coursePrerequisites/coursePrerequisites';
import { create } from '../../../../store/notification';

export default function DeletePrerequisite({
    courseId,
    prerequisiteToDelete,
    setLoading,
    courseTenant,
}: {
    courseId: number;
    prerequisiteToDelete: ICoursePrerequisite;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    courseTenant: string | null;
}) {
    const dispatch = useDispatch();

    const handleDelete = () => {
        setLoading(true);
        coursePrerequisitesService
            .delete(courseId, prerequisiteToDelete.id, courseTenant)
            .then(() => {
                setLoading(false);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen művelet' })));
    };

    // FIXME: ez a dialog a base-ből kellene, hogy jöjjön
    return (
        <Dialog
            title="Előfeltétel törlése"
            opener={
                <TableButton color="error" tooltip="Előfeltétel törlése">
                    <Delete />
                </TableButton>
            }
            action={<SubmitButton onClick={handleDelete}>Igen</SubmitButton>}
            cancelText="Mégsem"
        >
            <DialogContent>
                Biztosan törli a(z) {prerequisiteToDelete.courseReferenceCode} törzsszámú előfeltételt?
            </DialogContent>
        </Dialog>
    );
}
