import Edit from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const LanguageChip = <T,>({
    value,
    setOpen,
    setValue,
    label,
}: {
    value: T;
    label: string;
    setOpen: (value: boolean) => void;
    setValue: (value: T) => void;
}) => {
    const { isEdit } = useSelector((state: RootState) => state.studentProfile);

    return (
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Chip
                color="secondary"
                label={label}
                deleteIcon={isEdit ? <Edit /> : <InfoIcon />}
                onClick={() => {
                    setValue(value);
                    setOpen(true);
                }}
                onDelete={() => {
                    setValue(value);
                    setOpen(true);
                }}
            />
        </Grid>
    );
};

export default LanguageChip;
