import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
// import { createAddressStringFromObject } from '../../utils';
// import SemesterAllocationInfo from './SemesterAllocationInfo';
import Title from '../../../../../../components/Title';
import { ITermination } from './types';
import { getMembershipStatus, getSemesterStatus } from '../../utils';

export interface TerminationDialogContentProps {
    data: ITermination;
}

export default function TerminationDialogContent(props: TerminationDialogContentProps) {
    const { data } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Title title="Típus" />
                <TextField value={data.isMoveOut ? 'Kiköltözés' : 'Kiiratkozás'} disabled />
            </Grid>
            <Grid item xs={12}>
                <Title title="Név" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Vezetéknév" value={data.lastName || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Keresztnév" value={data.firstName || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Elérhetőségek" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={6} md={6}>
                        <TextField label="Email cím" value={data.email || '-'} disabled />
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <TextField label="Telefonszám" value={data.phoneNumber || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Képzés" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Képzési szint" value={data.trainingLevel || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Képzési program" value={data.trainingProgram || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Évfolyam" value={data.collegeYear || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Státusz" value={getMembershipStatus(data.membershipStatus || '-')} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Aktuális státusz"
                            value={getMembershipStatus(data.currentMembershipStatus || '-')}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Szemeszter státusz"
                            value={getSemesterStatus(data.semesterStatus) || data.semesterStatus || '-'}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Campus" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Campus" value={data.campus || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Épület" value={data.building || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Szint" value={data.floor || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Szoba" value={data.room || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Title title="Állandó lakcím" />
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={3}>
                        <TextField label="Irányítószám" value={data.address?.postCode || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField label="Település" value={data.address?.city || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={4}>
                        <TextField label="Közterület neve" value={data.address?.streetName || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="Közterület jellege" value={data.address?.streetType || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="Házszám" value={data.address?.houseNumber || '-'} disabled />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                        <TextField label="Emelet" value={data.address?.floor || '-'} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Ajtó" value={data.address?.door || '-'} disabled />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
