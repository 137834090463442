import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonProps } from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useState, useContext, useCallback } from 'react';
import RefreshContext from '../../../../studentPages/SelfAssessment/RefreshContextProvider';
import EndpointProvider from '../../../../components/EndpointProvider';

interface ConfirmationDialogProps {
    Opener: (props: ButtonProps) => JSX.Element;
    confirmationText: string;
    handleNo: () => Promise<unknown>;
    handleYes: () => Promise<unknown>;
}

export default function ConfirmationDialog({ Opener, confirmationText, handleNo, handleYes }: ConfirmationDialogProps) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = useContext(RefreshContext);

    const handleClick = useCallback(
        async (yes: boolean) => {
            setLoading(true);
            try {
                if (yes) {
                    await handleYes();
                } else {
                    await handleNo();
                }
                refresh();
            } finally {
                setLoading(false);
                setOpen(false);
            }
        },
        [handleNo, handleYes, refresh],
    );

    const handleYesClick = useCallback(async () => {
        await handleClick(true);
    }, [handleClick]);

    const handleNoClick = useCallback(async () => {
        await handleClick(false);
    }, [handleClick]);

    const handleOpen = useCallback(() => setOpen(true), []);

    const handleClose = useCallback(() => setOpen(false), []);

    return (
        <>
            <Opener onClick={handleOpen} />
            <EndpointProvider endpoints={['self_evaluation_edit']}>
                <Dialog open={open} onClose={handleClose} fullWidth>
                    <DialogTitle>Megerősítés</DialogTitle>
                    <DialogContent style={{ marginTop: '16px' }}>
                        <DialogContentText>{confirmationText}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton loading={loading} onClick={handleNoClick} variant="outlined">
                            Nem
                        </LoadingButton>
                        <LoadingButton loading={loading} onClick={handleYesClick} autoFocus variant="contained">
                            Igen
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </EndpointProvider>
        </>
    );
}
