import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { GroupList } from './List';
import { GridRenderCellParamsContextProvider } from './List/GridRenderCellParamsContextProvider';
import { MenuContextProvider } from './List/MenuContextProvider';
import Scheduler from './Scheduler';
import UnavailableOptions from './UnavailableOptions';
import GroupListExport from './Export';
import ListImport from './Import';
import StatusChanger from './StatusChanger';

export default function Operations(params: GridRenderCellParams) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const isFinalized = params.row.status === 'finalized';

    return (
        <GridRenderCellParamsContextProvider {...{ params }}>
            <Tooltip title="Műveletek">
                <IconButton onClick={handleClick}>
                    <MoreVert />
                </IconButton>
            </Tooltip>
            <MenuContextProvider setter={setAnchorEl}>
                <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <GroupList />
                    <GroupList editable />
                    <Scheduler />
                    {params.row.calculationType === 'automatic' ? <UnavailableOptions disabled={isFinalized} /> : null}
                    <GroupListExport disabled={isFinalized} />
                    <ListImport disabled={isFinalized} />
                    <StatusChanger id={params.row.id} finalized={isFinalized} setAnchorEl={setAnchorEl} />
                </Menu>
            </MenuContextProvider>
        </GridRenderCellParamsContextProvider>
    );
}
