export const snakeCaseToCamelCase = (input: string) => {
    return input
        .split('_')
        .reduce(
            (res, word, i) =>
                i === 0
                    ? word.toLowerCase()
                    : `${res}${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`,
            '',
        );
};

export const camelToSnake = (s1: string) => {
    return s1
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();
};
