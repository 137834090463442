import { ICompletionData, ITrainingCompletionData } from '../../types';
import { getColorByCompletiondAndStatus } from '../../utils';
import Grid from '@mui/material/Grid';

export default function CompletedCellRenderer(row: ICompletionData | ITrainingCompletionData) {
    return (
        <Grid container spacing={1}>
            <Grid
                item
                xs={12}
                style={{
                    color: getColorByCompletiondAndStatus(row.completed, row.minToBeCompleted, row.status),
                    textAlign: 'center',
                }}
            >
                {row.completed} pont
            </Grid>
            <Grid item xs={12} style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {row.completedCopper} garas
            </Grid>
        </Grid>
    );
}
