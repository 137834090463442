import axios, { AxiosResponse } from 'axios';
import campusesService from '../../../../services/masterData/campuses';
import semestersService from '../../../../services/masterData/semesters';
import trainingLevelsService from '../../../../services/masterData/trainingLevels';
import trainingProgramsService from '../../../../services/masterData/trainingPrograms';
import { Option } from '../../../../utils/AppConst';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { IStudentProcessIds } from './Form/DetailedForm';
import { financingFormOptions, semesterStatusOptions, statusOptions, trainingFormOptions } from './Form/options';
import { IEnrollment, IEnrollmentDocument } from './Form/ProcessDialogs/types';
import { Address, Base, IProcess } from './Form/types';

export const masterDataKeys = ['campuses', 'trainingLevels', 'trainingPrograms', 'collegeYears', 'semesters'] as const;
export type GeneralMasterData = typeof masterDataKeys;
export type MasterData = GeneralMasterData[number];
export type Options = { [key in MasterData]: Option[] };

export const createOption = (array: Base[]): Option[] => {
    return array
        ? array.map((elem) => ({
              label: elem.name,
              value: '' + elem.id,
          }))
        : [];
};

export const initOptions = masterDataKeys.reduce((o, key) => ({ ...o, [key]: [] }), {}) as Options;

export const services: { filter: (form: IForm) => Promise<AxiosResponse> }[] = [
    campusesService,
    trainingLevelsService,
    { filter: trainingProgramsService.optionsForFilterStudent },
    { filter: trainingProgramsService.collegeYears },
    semestersService,
];

export const fetchData = (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setOptions: React.Dispatch<React.SetStateAction<Options>>,
) => {
    setLoading(true);
    axios
        .all(services.map((service) => service.filter({})))
        .then((response) =>
            masterDataKeys.map((key, i) => {
                let d = response[i].data['hydra:member'] ? response[i].data['hydra:member'] : response[i].data;

                if (!response[i].data['hydra:member']) {
                    d = d.map((v: Option) => {
                        return {
                            id: v.value,
                            name: v.label,
                        };
                    });
                }

                return setOptions((prev) => ({ ...prev, [key]: createOption(d) }));
            }),
        )
        .finally(() => setLoading(false));
};

// MCCENROLLMENT-ből érkező folyamatok és státuszaik nevei angolul és magyarul
export const PROCESS_STATUS_OPTIONS_DETAILED = [
    // Beiratkozás
    { value: 'student_filling', label: 'Beiratkozás', type_hu: 'Beiratkozás', type_en: 'enrollment' },
    {
        value: 'financial_check',
        label: 'Pénzügyi ellenőrzés',
        type_hu: 'Beiratkozás',
        type_en: 'enrollment',
    },
    { value: 'filling_approval', label: 'Jóváhagyás', type_hu: 'Beiratkozás', type_en: 'enrollment' },
    { value: 'room_approval', label: 'Szobajóváhagyás', type_hu: 'Beiratkozás', type_en: 'enrollment' },
    { value: 'room_take_over', label: 'Szoba átvételi jegyzőkönyv', type_hu: 'Beiratkozás', type_en: 'enrollment' },
    { value: 'operator_check', label: 'Üzemeltetési jegyzőkönyv', type_hu: 'Beiratkozás', type_en: 'enrollment' },
    { value: 'done', label: 'Lezárult', type_hu: 'Beiratkozás', type_en: 'enrollment' },
    // Szobatárspreferencia
    {
        value: 'student_filling',
        label: 'Kitöltés alatt',
        type_hu: 'Szobatárspreferencia',
        type_en: 'student_allocation_preference',
    },
    {
        value: 'done',
        label: 'Lezárult',
        type_hu: 'Szobatárspreferencia',
        type_en: 'student_allocation_preference',
    },
    // Átköltözés
    { value: 'student_filling', label: 'Hallgató tölti ki', type_hu: 'Átköltözés', type_en: 'relocation' },
    { value: 'financial_check', label: 'Pénzügyi ellenőrzés', type_hu: 'Átköltözés', type_en: 'relocation' },
    { value: 'move_out_form', label: 'Kiköltözési nyilatkozat', type_hu: 'Átköltözés', type_en: 'relocation' },
    {
        value: 'operator_room_take_over',
        label: 'Üzemeltetési szobaátvétel',
        type_hu: 'Átköltözés',
        type_en: 'relocation',
    },
    {
        value: 'student_room_take_over',
        label: 'Szobaátvételi nyilatkozat kitöltése',
        type_hu: 'Átköltözés',
        type_en: 'relocation',
    },
    { value: 'approve_room_take_over', label: 'Üzemeltetői ellenőrzés', type_hu: 'Átköltözés', type_en: 'relocation' },
    { value: 'done', label: 'Lezárult', type_hu: 'Átköltözés', type_en: 'relocation' },
    // Kiiratkozás
    {
        value: 'student_declaration',
        label: 'Hallgatói nyilatkozat',
        type_hu: 'Kiiratkozás',
        type_en: 'termination',
    },
    {
        value: 'operator_check',
        label: 'Üzemeltetői ellenőrzés',
        type_hu: 'Kiiratkozás',
        type_en: 'termination',
    },
    {
        value: 'financial_check',
        label: 'Pénzügyi ellenőrzés',
        type_hu: 'Kiiratkozás',
        type_en: 'termination',
    },
    {
        value: 'done',
        label: 'Lezárult',
        type_hu: 'Kiiratkozás',
        type_en: 'termination',
    },
    // Kiköltözés
    {
        value: 'student_declaration',
        label: 'Hallgatói nyilatkozat',
        type_hu: 'Kiköltözés',
        type_en: 'move_out',
    },
    {
        value: 'operator_check',
        label: 'Üzemeltetői ellenőrzés',
        type_hu: 'Kiköltözés',
        type_en: 'move_out',
    },
    {
        value: 'financial_check',
        label: 'Pénzügyi ellenőrzés',
        type_hu: 'Kiköltözés',
        type_en: 'move_out',
    },
    {
        value: 'done',
        label: 'Lezárult',
        type_hu: 'Kiköltözés',
        type_en: 'move_out',
    },
];

export function getMembershipStatus(status: string) {
    return statusOptions.filter((s) => s.value === status)?.[0]?.label || '';
}

export function getSemesterStatus(status: string) {
    return semesterStatusOptions.filter((s) => s.value === status)?.[0]?.label || '';
}

export const getTrainingFormLabelByValue = (value: string) =>
    trainingFormOptions.find((option) => option.value === value)?.label || '';

export const getFinancingFormLabelByValue = (value: string) =>
    financingFormOptions.find((option) => option.value === value)?.label || '';

export const getDocumentField = (field: IEnrollmentDocument, data: IEnrollment) => {
    let val = field.name;

    if (field.required === '1') {
        return val;
    }

    if (data.consentedDocuments.find((x: IEnrollmentDocument) => x.id === field.id)) {
        val = val + ' (Kép-, hang- és videófelvételhez hozzájárult)';
    } else {
        val = val + ' (Kép-, hang- és videófelvételhez nem járult hozzá)';
    }

    return val;
};

const getAddressDetail = (value: string, firstWord = false) => {
    if (value && value !== 'null') {
        return firstWord ? value : ' ' + value;
    }

    return '';
};

export const createAddressStringFromObject = (object: Address) => {
    let addressString = '';
    if (object) {
        addressString += getAddressDetail(object.postCode, true);
        addressString += object.city ? ' ' + object.city + ',' : '';
        addressString += getAddressDetail(object.streetName);
        addressString += getAddressDetail(object.streetType);
        addressString += getAddressDetail(object.houseNumber);
        if (object.floor) addressString += getAddressDetail(object.floor);
        if (object.door) addressString += getAddressDetail(object.door);
    }

    return addressString;
};

export function mergeStudentProcessIds(
    processesFromResponse: IProcess[],
    studentProcessIds: IStudentProcessIds,
): IStudentProcessIds {
    processesFromResponse.forEach((process: IProcess) => {
        switch (process.processType) {
            case 'enrollment':
                if (!studentProcessIds.enrollments.includes(process.id)) studentProcessIds.enrollments.push(process.id);
                break;
            case 'student_allocation_preference':
                if (!studentProcessIds.studentAllocationPreferences.includes(process.id))
                    studentProcessIds.studentAllocationPreferences.push(process.id);
                break;
            case 'relocation':
                if (!studentProcessIds.relocations.includes(process.id)) studentProcessIds.relocations.push(process.id);
                break;
            default:
                break;
        }
    });

    return studentProcessIds;
}

//MCCENROLLMENT-ből:
const trainingFormOptionsOld = [
    { value: 'ba', label: 'BA' },
    { value: 'bsc', label: 'BSc' },
    { value: 'ma', label: 'MA' },
    { value: 'msc', label: 'MSc' },
    { value: 'undivided', label: 'Osztatlan' },
    { value: 'other', label: 'Egyéb' },
];

export const getTrainingFormLabelByValueOld = (value: string) =>
    trainingFormOptionsOld.find((option) => option.value === value)?.label || '';
