import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import instance from '../../../../api';
import Form from '../../../../components/Form/Form';
import RefreshContext from '../../../../studentPages/SelfAssessment/RefreshContextProvider';
import BackendSelect from '../../../../studentPages/components/BackendSelect';
import { Option, transformApiErrors } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import ConfirmationDialog from './ConfirmationDialog';
import { CheckResponse, IMemberUpdateForm } from './types';
import { check, updateTransformer } from './utils';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { create } from '../../../../store/notification';

export default function EditMember({ row }: { row: IMemberUpdateForm }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = useContext(RefreshContext);
    const id = useParams().id || '';
    const [checked, setChecked] = useState(false);
    const ref = useRef<HTMLButtonElement>(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const dispatch = useDispatch();
    const [groups, setGroups] = useState<CheckResponse[][]>([]);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleSubmit = useCallback(
        (form: IMemberUpdateForm) => {
            const newForm = updateTransformer(form);
            setLoading(true);
            return instance
                .post(endpoints.scholarships.update(id, '' + row.id), newForm)
                .then(() => {
                    refresh();
                    setOpen(false);
                    setOpenConfirmation(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [id, refresh, row.id],
    );

    const handleChange = useCallback(
        (setFieldValue: (field: string, value: unknown) => void, name: string) =>
            (values: Option[] | Option | null) => {
                if (name === 'trainingLevel') {
                    setFieldValue('trainingProgram', { value: '', label: '' });
                }
                setFieldValue(name, values ? values : { value: '', label: '' });
            },
        [],
    );

    const handleCheck = useCallback(
        (values: IMemberUpdateForm, setErrors: FormikHelpers<IMemberUpdateForm>['setErrors']) => () => {
            const newForm = updateTransformer(values);
            check(id, newForm)
                .then(({ data }) => {
                    if (data.length > 0) {
                        setGroups(data);
                        setOpenConfirmation(true);
                    } else {
                        setChecked(true);
                    }
                })
                .catch((error: AxiosError) => {
                    setLoading(false);
                    if (error.response?.status === 422)
                        setErrors(transformApiErrors<IMemberUpdateForm>(error.response?.data.violations));
                    dispatch(
                        create({
                            type: 'error',
                            message: error.response?.data.message || 'Hiba a mentés során.',
                        }),
                    );
                });
        },
        [dispatch, id],
    );

    useEffect(() => {
        if (checked && ref.current) {
            ref.current.click();
            setChecked(false);
        }
    }, [checked]);

    return (
        <Grid item>
            <Tooltip title="Szerkesztés">
                <IconButton
                    size="small"
                    color="warning"
                    onClick={handleOpen}
                    style={{ borderWidth: '1px', borderStyle: 'solid' }}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                PaperProps={{ style: { overflow: 'visible' } }}
            >
                <DialogTitle>Szerkesztés</DialogTitle>
                <DialogContent>
                    <Form fields={[]} onSubmit={handleSubmit} initialValues={row} hideButtons>
                        {({ values, setFieldValue, errors, setErrors }) => {
                            return (
                                <>
                                    <Grid item xs={12}>
                                        <BackendSelect
                                            value={values.campus}
                                            url={endpoints.scholarships.campus(id)}
                                            setter={handleChange(setFieldValue, 'campus')}
                                            responseKey=""
                                            label="Campus"
                                            error={!!(errors as Record<string, string>).campus}
                                            helperText={(errors as Record<string, string>).campus}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BackendSelect
                                            value={values.trainingLevel}
                                            url={endpoints.scholarships.trainingLevel(id)}
                                            setter={handleChange(setFieldValue, 'trainingLevel')}
                                            responseKey=""
                                            label="Képzési szint"
                                            error={!!(errors as Record<string, string>).trainingLevel}
                                            helperText={(errors as Record<string, string>).trainingLevel}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BackendSelect
                                            value={values.trainingProgram}
                                            url={endpoints.scholarships.trainingProgram(id, values.trainingLevel.value)}
                                            setter={handleChange(setFieldValue, 'trainingProgram')}
                                            responseKey=""
                                            label="Képzési program"
                                            error={!!(errors as Record<string, string>).trainingProgram}
                                            helperText={(errors as Record<string, string>).trainingProgram}
                                        />
                                    </Grid>
                                    <Grid item container xs={12} spacing={1} justifyContent="flex-end">
                                        <Grid item>
                                            <Button onClick={handleClose} variant="outlined">
                                                Mégse
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <LoadingButton
                                                type={checked ? 'submit' : 'button'}
                                                variant="contained"
                                                loading={loading}
                                                onClick={!checked ? handleCheck(values, setErrors) : undefined}
                                                ref={ref}
                                            >
                                                Mentés
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                    <ConfirmationDialog
                                        groups={groups}
                                        openState={[openConfirmation, setOpenConfirmation]}
                                    />
                                </>
                            );
                        }}
                    </Form>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}
