import { endpoints } from '../../utils/endPoints';
import API from '../../api';

export interface ITrainingLevelsForm {
    name: string;
}

const fileManagementServices = {
    fileUpload: (form: { file: File; type: string }) => API.post(endpoints.fileManagement.fileUpload, form),
    downloadTemp: (type: string, fileName: string) =>
        API.get(endpoints.fileManagement.downloadTemp.replace('{type}', type) + '?fileName=' + fileName, {
            responseType: 'blob',
        }),
};

export default fileManagementServices;
