import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { AutoloadTable, Page } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import systemMessagesService from '../../services/systemMessages';
import { RootState } from '../../store';
import { create } from '../../store/notification';
import './SystemMessages.css';
import { columns, header } from './utilts';
import { useTenant } from '../../components/TenantContext';

type SystemMessageServiceKey = keyof typeof systemMessagesService;

export default function SystemMessages() {
    const [refresh, setRefresh] = useState(false);
    const [checked, setChecked] = useState<number[]>([]);
    const [allChecked, setAllChecked] = useState(false);
    const [visibleChecked, setVisibleChecked] = useState(false);
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const tenant = useTenant();
    const doRefresh = () => setRefresh(!refresh);

    const allCheckedHandler = () => {
        setAllChecked(!allChecked);
    };

    const checkVisibleHandler = () => {
        setVisibleChecked(!visibleChecked);
    };

    const followUp = (promise: Promise<unknown>) => {
        promise
            .then(() => {
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                    }),
                );
                doRefresh();
                systemMessagesService.setUnseenNotifications();
            })
            .catch(() => {
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => {
                setAllChecked(false);
            });
    };

    const markAll = (seen: boolean): SystemMessageServiceKey => (seen ? 'markAllAsSeen' : 'markAllAsUnSeen');
    const markSome = (seen: boolean): SystemMessageServiceKey => (seen ? 'markAsSeen' : 'markAsUnSeen');

    const mark = (all: boolean, seen: boolean): SystemMessageServiceKey => (all ? markAll : markSome)(seen);

    const markFunction = (seen: boolean) => {
        followUp(
            (systemMessagesService[mark(!!allChecked, seen)] as (checked?: number[]) => Promise<unknown>)(checked),
        );
    };

    return (
        <Page header={header(tenant || 'TAR')}>
            <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                <Grid item>
                    <Tooltip title="Összes kijelölése">
                        <Button onClick={allCheckedHandler} variant="contained" disabled={user.realUser ? true : false}>
                            <DoneAllIcon />
                            Minden elem kijelölése
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Összes kijelölése">
                        <Button
                            onClick={checkVisibleHandler}
                            variant="contained"
                            disabled={user.realUser ? true : false}
                        >
                            <DoneIcon />
                            Láthatóak kijelölése
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Megjelölés olvasottként">
                        <Button onClick={() => markFunction(true)} disabled={user.realUser ? true : false}>
                            <MarkAsUnreadIcon />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Megjelölés olvasatlanként">
                        <Button onClick={() => markFunction(false)} disabled={user.realUser ? true : false}>
                            <MarkunreadIcon />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <AutoloadTable
                filter={systemMessagesService.filter}
                columns={columns(doRefresh, setChecked, allChecked, visibleChecked)}
                responseKey="data"
                getRowClassName={({ row }: GridRowClassNameParams) => (row.lastSeen ? '' : 'unseen')}
                noAccordionHeader
                defaultSort={{
                    createdAt: 'desc',
                }}
                refresh={refresh}
            />
        </Page>
    );
}
