import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { Link } from '@silinfo/front-end-template';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { clientEndPoints } from '../../../utils/clientEndPoints';

const NotificationBadge = () => {
    const { unseenNotifications } = useSelector((state: RootState) => state.auth);
    return (
        <Badge badgeContent={unseenNotifications} color="error">
            <NotificationsIcon />
        </Badge>
    );
};

export const AdminNotification = () => {
    return (
        <Link to={clientEndPoints.system_messages}>
            <IconButton color="primary" style={{ paddingTop: '10px' }}>
                <NotificationBadge />
            </IconButton>
        </Link>
    );
};

export default function Notification() {
    return (
        <div style={{ padding: '8px' }}>
            <Link to={clientEndPoints.system_messages}>
                <IconButton color="secondary">
                    <NotificationBadge />
                </IconButton>
            </Link>
        </div>
    );
}
