import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuizIcon from '@mui/icons-material/Quiz';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link, TableButton } from '@silinfo/front-end-template';
import { ExportContext } from '../../../components/Buttons/ExportButton';
import TableExportButton from '../../../components/Buttons/TableExportButton';
import courseEvaluationReportService from '../../../services/courseManagement/courseEvaluationReport';
import { initialInfo } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import ViewEvaluations from './ViewEvaluations';
import EndpointProvider from '../../../components/EndpointProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ROLES, ROLES_TO_CHECK } from './utils';

export default function Operations(params: GridRenderCellParams) {
    const { user } = useSelector((state: RootState) => state.auth);
    const exportContext: ExportContext = {
        initiateExport: courseEvaluationReportService.initiateExportWithTenant(params.id, params.row.tenantId),
        checkExport: courseEvaluationReportService.checkExport(params.id),
        downloadExport: courseEvaluationReportService.downloadExport(params.id),
        info: initialInfo(),
        filter: {},
    };

    const isAllowedRoles = user.roles.includes(
        ROLES.ADMIN ||
            ROLES.TRAINING_PROGRAM_ADMIN ||
            ROLES.TRAINING_PROGRAM_LEADER ||
            ROLES.EP_COORDINATOR ||
            ROLES.FINANCIAL_ADMIN ||
            ROLES.APPROVER_DECISION_MAKER ||
            ROLES.OPERATOR ||
            ROLES.BOARD,
    );

    const isCourseManager =
        !params.row.student &&
        user.roles.includes(ROLES_TO_CHECK.COURSE_MANAGER) &&
        user.authId === params?.row?.manager?.id;

    const isTeacher =
        !params.row.student &&
        user.roles.includes(ROLES_TO_CHECK.TEACHER) &&
        params?.row?.teacher.some((t: { id: number }) => t.id === user.authId);

    const CourseView = (
        <>
            {params.row.questionnairesFilled > 0 && (
                <ViewEvaluations view="course" id={'' + params.id} tenantId={'' + params.row.tenantId} />
            )}
            <Grid item>
                {(isAllowedRoles || isCourseManager || isTeacher) && (
                    <EndpointProvider endpoints={['course_manamagement_course_show']}>
                        <Link
                            to={
                                clientEndPoints.course_manamagement_course_show.replace(':courseId', '' + params.id) +
                                (params.row.sharedType ? `?courseTenant=${params.row.tenantId}` : '')
                            }
                        >
                            <TableButton color="secondary" tooltip="Kurzusadatlap megtekintése">
                                <DescriptionIcon />
                            </TableButton>
                        </Link>
                    </EndpointProvider>
                )}
            </Grid>
            <Grid item>
                <TableExportButton title="Kérdőívek exportálása" exportContext={exportContext} />
            </Grid>
        </>
    );

    const StudentView = (
        <>
            {params.row.courseId ? (
                <>
                    {params.row.anonymous !== true && params.row.questionnaireStatus === 'filled' ? (
                        <Grid item>
                            <EndpointProvider endpoints={['md_general_students_list_read_only_show']}>
                                <TableButton color="primary" tooltip="Kurzusértékelés megtekintése">
                                    <QuizIcon
                                        onClick={() =>
                                            window.open(
                                                clientEndPoints.course_evaluation_report_preview
                                                    .replace(':courseId', '' + params.row.courseId)
                                                    .replace(':studentId', '' + params.row.studentId),
                                            )
                                        }
                                    />
                                </TableButton>
                            </EndpointProvider>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    {params.row.notAnonimFillsCount > 0 && (
                        // TODO: Hallgatói listaoldal kezelésekor a tenantId-t megfelelően kell kezelni.
                        <Grid item>
                            <ViewEvaluations view="student" id={'' + params.id} tenantId="1" />
                        </Grid>
                    )}
                    <Grid item>
                        <EndpointProvider endpoints={['md_general_students_list_read_only_show']}>
                            <Link
                                to={clientEndPoints.md_general_students_list_read_only_show.replace(
                                    ':id',
                                    '' + params.row.tarStudentId,
                                )}
                            >
                                <TableButton color="primary" tooltip="Hallgató adatlapjának megtekintése">
                                    <VisibilityIcon />
                                </TableButton>
                            </Link>
                        </EndpointProvider>
                    </Grid>
                </>
            )}
        </>
    );

    return (
        <Grid item xs={12} container spacing={1} justifyContent="center">
            {params.row.student ? StudentView : CourseView}
        </Grid>
    );
}
