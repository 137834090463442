import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Feed';
import RemoveIcon from '@mui/icons-material/NotificationsActive';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Item from './Item';
import { Points } from './Points';
import { RowProps } from './types';

export default function MobileView(props: RowProps & { handleTodayClick: () => void }) {
    return (
        <Grid item xs={12}>
            <Card elevation={6}>
                <CardHeader
                    title={
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h6" component="div">
                                    {props.name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {props.id ? (
                                    <IconButton color="primary" onClick={props.handleTodayClick}>
                                        <CalendarTodayIcon />
                                    </IconButton>
                                ) : null}
                            </Grid>
                        </Grid>
                    }
                    subheader={
                        props.listProgram ? (
                            <Typography variant="body2" component="div">
                                {props.listProgram}
                            </Typography>
                        ) : null
                    }
                    style={{
                        paddingBottom: 0,
                    }}
                />
                <CardContent
                    style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                >
                    <List>
                        <Item label="Teljesített" Icon={<DoneIcon />} color="success">
                            <div>{props.completed.point} pont</div>
                            <div>{props.completed.copper} garas</div>
                        </Item>
                        <Divider variant="inset" component="li" style={{ marginLeft: 0 }} />
                        <Item label="Felvett kurzusok" Icon={<AddIcon />} color="primary">
                            <Points {...props} />
                        </Item>
                        <Divider variant="inset" component="li" style={{ marginLeft: 0 }} />
                        <Item label="Minimum teljesítendő" Icon={<RemoveIcon />} color="error">
                            <div>{props.minToBeCompleted} pont</div>
                        </Item>
                    </List>
                </CardContent>
            </Card>
        </Grid>
    );
}
