import Grid from '@mui/material/Grid';
import { Paper } from '../Paper';
import Label from './Label';
import { ICourseView } from '../../../pages/CourseManagement/Courses/types';

export default function CourseName(props: ICourseView) {
    return (
        <Grid item xs={12} container>
            <Paper style={{ width: '100%' }}>
                <Label>Kurzus neve</Label>
                <Grid item xs={12}>
                    {props.courseName}
                </Grid>
            </Paper>
        </Grid>
    );
}
