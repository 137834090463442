import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Add from '@mui/icons-material/Add';
import AttachMoney from '@mui/icons-material/AttachMoney';
import List from '@mui/icons-material/List';
import Hub from '@mui/icons-material/Hub';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ListItemProps } from '../ListItem';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import QuizIcon from '@mui/icons-material/Quiz';
import DescriptionIcon from '@mui/icons-material/Description';

export const mainListItems: ListItemProps[] = [
    {
        text: 'Naptár',
        icon: <CalendarMonth />,
        to: '/student/calendar',
    },
    {
        text: 'Profilom',
        icon: <AccountCircleIcon />,
        to: '/student/profile',
    },
    {
        text: 'Kurzusfelvétel',
        icon: <Add />,
        to: '/student/course-enrollment',
    },
    {
        text: 'Követelmények',
        icon: <List />,
        to: '/student/requirements',
    },
    {
        text: 'Pénzügyeim',
        icon: <AttachMoney />,
        to: '/student/finances',
    },
    {
        text: 'Ügyeim, folyamataim',
        icon: <Hub />,
        to: '/student/processes',
    },
    {
        text: 'Önértékelés',
        icon: <AccessibilityNewIcon />,
        to: clientEndPoints.student_self_assessment,
    },
    {
        text: 'Kérdőívek',
        icon: <QuizIcon />,
        to: clientEndPoints.student_questionnaires,
    },
    {
        text: 'Dokumentumok',
        icon: <DescriptionIcon />,
        to: clientEndPoints.student_documents,
    },
];
