import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FieldGenerator } from '../../../../../components/Form/FieldGenerator';
import { IStudentsForm } from '../../../../../services/masterData/students';
import AddressInput from './AddressInput';
import TrainingInput from './TrainingInput';

export interface ExtraStudentFormProps extends FormikProps<IStudentsForm> {
    setFoundUser?: React.Dispatch<React.SetStateAction<boolean>>;
    readOnly?: boolean;
    detailed?: boolean;
    baseLoading: boolean;
    disableCheckbox?: boolean;
}
export default function ExtraStudentForm(props: ExtraStudentFormProps) {
    const { id } = useParams() as { id: string };
    const { readOnly, setFieldValue } = props;

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, { setFieldValue }: FormikProps<IStudentsForm>) => {
        if (props.setFoundUser) {
            props.setFoundUser(false);
            if (e.target.checked) {
                if (props.values.functionalUser === '1') {
                    props.setFoundUser(e.target.checked);
                    setFieldValue('firstName', props.values.firstName);
                    setFieldValue('lastName', props.values.lastName);
                    setFieldValue('campus', props.values.campus);
                    setFieldValue('mccId', props.values.mccId);
                }
            }
        }
    };

    useEffect(() => {
        if (props.values.actualStatus === 'external') {
            setFieldValue('room', null);
            setFieldValue('floor', null);
            setFieldValue('building', null);
        }
    }, [props.values.actualStatus, setFieldValue]);

    const myProps = {
        disabled: readOnly !== undefined && !!readOnly,
        campus: props.values.campus,
        detailed: props.detailed || false,
    };

    return (
        <>
            <TrainingInput disabled={readOnly !== undefined && !!readOnly} detailed={props.detailed || false} />
            <AddressInput props={myProps} />
            {(props.setFoundUser || id) &&
                FieldGenerator({
                    type: 'checkbox',
                    label: 'Funkcionális felhasználó',
                    name: 'functionalUser',
                    props: {
                        disabled: readOnly || !id,
                        ...(id ? {} : { onChange: handleCheck }),
                    },
                    ...props,
                })}

            {props.setFoundUser &&
                !id &&
                !readOnly &&
                FieldGenerator({
                    type: 'checkbox',
                    label: 'Aktivációs e-mail küldése',
                    name: 'sendEmail',
                    props: {
                        disabled: readOnly || props.disableCheckbox,
                    },
                    ...props,
                })}
            {FieldGenerator({
                type: 'checkbox',
                label: 'Letiltva',
                name: 'disabled',
                props: {
                    disabled: readOnly,
                },
                ...props,
            })}
            {/* 
                Ez azért disabled már, mert csak a kiiratkozási folyamat tudja beállítani,
                illetve a beiratkozási folyamat visszaállítani.
            */}
            {FieldGenerator({
                type: 'checkbox',
                label: 'Kiiratkozva',
                name: 'terminating',
                props: {
                    disabled: true,
                },
                ...props,
            })}
        </>
    );
}
