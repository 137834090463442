import Grid from '@mui/material/Grid';
import RightPanel from '../RightPanel';
import Semesters from './Semesters';
import Table from './Table';
import ProgressBar from '../RightPanel/ProgressBar';
import ColorExplanations from '../RightPanel/ColorExplanations';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Loading, Select } from '@silinfo/front-end-template';
import useFetch from '../../../utils/useFetch';
import { endpoints } from '../../../utils/endPoints';
import { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

type RequirementProgramOption = { value: number; label: string };

export default function ProgramLevel() {
    const matches = useMediaQuery('(min-width:960px)');

    const [selectedProgram, setSelectedProgram] = useState<number | null>(null);
    const { loading, data: programs } = useFetch<RequirementProgramOption[]>(endpoints.students.requirements.programs);
    const handleSelectChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setSelectedProgram(parseInt(e.target.value)),
        [],
    );

    useEffect(() => {
        if (programs.length > 0 && !loading) {
            setSelectedProgram(programs[0].value);
        }
    }, [programs, loading, setSelectedProgram]);

    if (loading) return <Loading noHeight />;

    if (programs.length === 0) return <Typography>Még nem volt aktív képzés beállítva a profilodhoz.</Typography>;

    return (
        <Grid item container xs={12} spacing={2} justifyContent="flex-end">
            <Grid item xs={12} md={8} container spacing={1} alignSelf="flex-start">
                <Select options={programs} value={selectedProgram} onChange={handleSelectChange} />
                {matches ? null : (
                    <>
                        <ProgressBar />
                        <ColorExplanations />
                    </>
                )}
                <Semesters selectedProgram={selectedProgram} />
                <Table selectedProgram={selectedProgram} />
            </Grid>
            <RightPanel selectedProgram={selectedProgram} />
        </Grid>
    );
}
