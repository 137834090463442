import AutocompleteBasic, { AutocompleteProps as AutocompleteBasicProps } from '@mui/material/Autocomplete';
import { TextFieldProps } from '@mui/material/TextField';
import { TextField } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { makeOptionsFromHydra, Option } from '../../utils/AppConst';
import { theme } from '../../utils/theme';

interface AutocompleteProps<T, D extends boolean = false, E extends boolean = false> {
    numberOfCharactersToSearch?: number;
    getter: (term: string) => Promise<AxiosResponse<T>> | Promise<AxiosResponse<T>>;
    createOptions?: (arr: T[]) => Option[];
    autocompleteProps?: Omit<AutocompleteBasicProps<Option | string, D, false, E>, 'renderInput' | 'options'>;
    textFieldProps?: TextFieldProps;
    errorMessage?: string;
}

export default function Autocomplete<T, D extends boolean = false, E extends boolean = false>(
    props: AutocompleteProps<T, D, E>,
) {
    const { numberOfCharactersToSearch, getter, textFieldProps, autocompleteProps, createOptions, errorMessage } =
        props;
    const [options, setOptions] = useState<Option[]>([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState(
        autocompleteProps?.value && !autocompleteProps?.multiple
            ? (((!Array.isArray(autocompleteProps.value) &&
                  typeof autocompleteProps.value !== 'string' &&
                  autocompleteProps.value.label) ||
                  autocompleteProps.value) as string)
            : '',
    );
    const n = numberOfCharactersToSearch || 3;
    const handleInputChange = (e: React.SyntheticEvent<Element, Event>, value: string) => {
        if (!e) return setInputValue(inputValue);
        setInputValue(value);
        if (value.length >= n) {
            setLoading(true);
            getter(value)
                .then((response: AxiosResponse) =>
                    setOptions(createOptions ? createOptions(response.data) : makeOptionsFromHydra(response.data)),
                )
                .finally(() => setLoading(false));
        }
    };

    return (
        <AutocompleteBasic
            onInputChange={handleInputChange}
            onOpen={() => setOpen(true)}
            onClose={() => {
                setOptions([]);
                setOpen(false);
            }}
            inputValue={inputValue}
            open={open}
            renderInput={(params) => (
                <TextField
                    {...textFieldProps}
                    {...params}
                    placeholder={'Írjon be ' + n + ' karaktert a kereséshez'}
                    size="small"
                    helperText={errorMessage}
                    error={!!errorMessage}
                />
            )}
            options={options}
            noOptionsText={
                loading
                    ? 'Betöltés...'
                    : inputValue.length >= n
                    ? 'Nincs találat'
                    : 'Írjon be ' + n + ' karaktert a kereséshez'
            }
            isOptionEqualToValue={(option, value) =>
                (typeof option !== 'string' && typeof value !== 'string' && option.value == value.value) ||
                option == value
            }
            ChipProps={{
                sx: {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    '& .MuiChip-deleteIcon': {
                        color: theme.palette.primary.main,
                    },
                },
            }}
            filterSelectedOptions
            {...autocompleteProps}
        />
    );
}
