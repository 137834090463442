import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import MicIcon from '@mui/icons-material/Mic';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import SpeakerIcon from '@mui/icons-material/Speaker';
import VideocamIcon from '@mui/icons-material/Videocam';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import { GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Input } from '@silinfo/front-end-template';
import { useParams } from 'react-router-dom';
import { clientEndPoints } from '../../../../../../../../utils/clientEndPoints';
import { IInfo } from '../../../../../../../../utils/Interfaces/IInfo';
import { IForm } from '../../../../../../../../utils/Interfaces/interfaces';
import { useTenant } from '../../../../../../../../components/TenantContext';
export const itemCategoryOptions = [
    {
        value: 'projector',
        label: 'Projektor',
        icon: <VideocamIcon />,
    },
    {
        value: 'speaker',
        label: 'Hangfal',
        icon: <SpeakerIcon />,
    },
    {
        value: 'microphone',
        label: 'Mikrofon',
        icon: <MicIcon />,
    },
    {
        value: 'tv',
        label: 'TV',
        icon: <ConnectedTvIcon />,
    },
    {
        value: 'eboard',
        label: 'E-tábla',
        icon: <AspectRatioIcon />,
    },
    {
        value: 'webcam',
        label: 'Webkamera',
        icon: <VoiceChatIcon />,
    },
    {
        value: 'other',
        label: 'Egyéb',
        icon: <MiscellaneousServicesIcon />,
    },
];

export const initialForm = {
    campus: '',
    building: '',
    floor: '',
    room: '',
    roomName: '',
    name: '',
    amount: 1,
    status: '',
    category: '',
    inventoryNumber: '',
};

export const fields = (type: string): Input[] => [
    {
        name: 'campusName',
        label: 'Campus',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            disabled: true,
        },
    },
    {
        name: 'buildingName',
        label: 'Épület',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            disabled: true,
        },
    },
    {
        name: 'floorName',
        label: 'Szint',
        format: { xs: 12, md: 3, lg: 3 },
        props: {
            disabled: true,
        },
    },
    {
        name: 'roomName',
        label: type === 'room' ? 'Lakószoba' : 'Tanterem',
        format: { xs: 12, md: 3, lg: 3 },
        props: {
            disabled: true,
        },
    },
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12, md: 3, lg: 3 },
    },
    {
        name: 'amount',
        label: 'Mennyiség',
        format: { xs: 12, md: 3, lg: 3 },
    },
    {
        name: 'status',
        label: 'Állapot',
        type: 'select',
        options: [
            { value: 'new', label: 'Új' },
            { value: 'excellent', label: 'Kitűnő' },
            { value: 'normal', label: 'Normál' },
            { value: 'deficient', label: 'Hiányos' },
            { value: 'injured', label: 'Sérült' },
            { value: 'unknown', label: 'Ismeretlen' },
        ],
        format: { xs: 12, md: 6, lg: 3 },
    },
];

const translations: Record<string, string> = {
    new: 'Új',
    excellent: 'Kitűnő',
    normal: 'Normál',
    deficient: 'Hiányos',
    injured: 'Sérült',
    unknown: 'Ismeretlen',
};

export const columns = [
    { headerName: 'Név', field: 'name', align: 'left' },
    { headerName: 'Mennyiség', field: 'amount' },
    {
        headerName: 'Állapot',
        field: 'status',
        valueGetter: (params: GridValueGetterParams<string>) => translations[params.value || ''] || params.value || '-',
        sortable: false,
    },
    { headerName: 'Műveletek', field: 'operations', cellRenderer: 'btnCellRenderer', sortable: false, align: 'left' },
].map((column) => ({ flex: 1, minWidth: 150, headerAlign: 'center', align: 'center', ...column }));

export const initialInfo: IInfo<IForm> = {
    filter: {},
    sort: {
        name: 'asc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

export const useHeader = (type: string) => {
    const params = useParams();
    const tenant = useTenant();
    const { campusId, buildingId, floorId } = params as {
        campusId: string;
        buildingId: string;
        floorId: string;
    };
    return {
        project: tenant || 'TAR',
        title: type === 'room' ? 'Tárgyak' : 'Eszközök',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
            [clientEndPoints.md_general_campuses_buildings_list.replace(':campusId', campusId?.toString() || '')]:
                'Épületek',
            [clientEndPoints.md_general_campuses_buildings_floors_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')]: 'Szintek',
            [clientEndPoints.md_general_campuses_buildings_floors_rooms_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')
                .replace(':floorId', floorId?.toString() || '')]: 'Szobák',
            'items': type === 'room' ? 'Tárgyak' : 'Eszközök',
        },
    };
};
