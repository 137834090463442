import CounterBadge from './CounterBadge';
import { IEvent } from './types';
import moment from 'moment';

const Tile = ({ date, events }: { date: Date; events: IEvent[] }) => (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
        {
            <CounterBadge
                color="primary"
                size="small"
                value={
                    events.filter(
                        (event) => moment(event.date).format('YYYY. MM. DD.') === moment(date).format('YYYY. MM. DD.'),
                    ).length
                }
            />
        }
    </div>
);

export default Tile;
