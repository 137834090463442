import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link, TableButton, Tooltip } from '@silinfo/front-end-template';
import DriveFileRenameOutline from '@mui/icons-material/DriveFileRenameOutline';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import Grid from '@mui/material/Grid';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';
import IconButton from '@mui/material/IconButton';
import { theme } from '../../../utils/theme';
import { endpoints } from '../../../utils/endPoints';
import instance from '../../../api';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { create } from '../../../store/notification';
import { useDispatch } from 'react-redux';
import ConflictDialog from './ConflictDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteGroupConfirmModal from './DeleteGroupConfirmModal';

export default function OperationsCellRenderer(
    params: GridRenderCellParams & { semester: string | number; refreshing: () => void },
) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [conflicts, setConflicts] = useState([]);
    const disabled =
        !params.row.questionnaireTemplate ||
        !params.row.applicationDeadline ||
        !params.row.closingDeadline ||
        params.row.closed;
    const activated = params.row.active;
    const getLink = () => {
        const link = window.location.href;
        const splitted = link.split('/');
        return '' + splitted[0] + '//' + splitted[2] + clientEndPoints.admission_landing_page;
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenDeleteModal = () => setDeleteModalOpen(true);

    const handleSwitch = () => {
        instance
            .post(endpoints.admission.administration.statusChange.replace('{admissionId}', params.row.id), {
                active: !activated,
            })
            .then((response) => {
                setConflicts(response.data);
                params.refreshing();
            })
            .finally(() => handleClose());
    };

    const handleOpenPreview = () => {
        window.open(
            clientEndPoints.admission_questionnaire_preview.replace(':id', '' + params.row.questionnaireTemplateId),
            '_blank',
        );
    };

    const handleDelete = () => {
        instance
            .delete(endpoints.admission.administration.deleteGroup(params.row.id, params.row.admissionGroupId))
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres csoport törlés!' }));
                handleClose();
                params.refreshing();
            })
            .catch((error) => {
                console.error(error);
                dispatch(create({ type: 'error', message: 'Sikertelen csoport törlés!' }));
            });
    };

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item>
                <Link
                    to={clientEndPoints.admission_administration_modify_group
                        .replace(':semesterId', params.semester.toString())
                        .replace(':groupId', params.row.admissionGroupId)}
                >
                    <TableButton color={'info'} tooltip={`Felvételi csoport beállításai`}>
                        <DriveFileRenameOutline />
                    </TableButton>
                </Link>
            </Grid>
            {activated && !disabled && (
                <Grid item>
                    <TableButton
                        color={'info'}
                        tooltip={`Link másolása`}
                        onClick={() => {
                            navigator.clipboard.writeText(getLink());
                            dispatch(create({ type: 'success', message: 'A link sikeresen vágólapra helyezve' }));
                        }}
                    >
                        <LinkIcon />
                    </TableButton>
                </Grid>
            )}
            <Tooltip
                title={
                    disabled
                        ? params.row.closed
                            ? 'A felvételi csoport lezárt státuszban van'
                            : 'A felvételi csoport aktiváláshoz további adatok megadására van szükség!'
                        : activated
                        ? 'Felvételi csoport inaktiválása'
                        : 'Felvételi csoport aktiválása'
                }
            >
                <Grid item>
                    {disabled ? (
                        <IconButton size="small" sx={{ borderWidth: '1px', borderStyle: 'solid' }} disabled>
                            <ToggleOffIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            size="small"
                            sx={{
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: activated ? theme.palette.success.main : theme.palette.error.main,
                            }}
                            onClick={handleOpen}
                        >
                            {activated ? <ToggleOnIcon color="success" /> : <ToggleOffIcon color="error" />}
                        </IconButton>
                    )}
                </Grid>
            </Tooltip>
            {params.row.questionnaireTemplate && (
                <Grid item>
                    <TableButton color={'info'} tooltip={`Felvételi előnézete`} onClick={handleOpenPreview}>
                        <QuizIcon />
                    </TableButton>
                </Grid>
            )}
            {(params.row.status === 'Nincs aktiválva' || params.row.status === 'Hiányzó adatok') &&
                !params.row.hasApplicants && (
                    <Tooltip title="Csoport törlése">
                        <Grid item>
                            <IconButton
                                size="small"
                                sx={{ borderWidth: '1px', borderStyle: 'solid', borderColor: theme.palette.error.main }}
                                onClick={handleOpenDeleteModal}
                            >
                                <DeleteIcon color="error" />
                            </IconButton>
                        </Grid>
                    </Tooltip>
                )}
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{'Megerősítés'}</DialogTitle>
                <DialogContent style={{ paddingTop: '16px' }}>
                    <DialogContentText>
                        {activated
                            ? 'A felvételi deaktiválásával a felvételi űrlap a továbbiakban nem lesz elérhető a hallgatónak, valamint ha a kérdőívsablon kicserélésre kerül, az eddigi összes kitöltés törlésre kerül. Biztosan inaktívvá akarod tenni a kérdőívet?'
                            : 'A felvételi aktiválásával a felvételi űrlap elérhetővé válik minden érintett jelentkezőnek. Biztosan aktiválod a felvételit?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSwitch} variant="contained">
                        Igen
                    </Button>
                    <Button onClick={handleClose} variant="outlined">
                        Nem
                    </Button>
                </DialogActions>
            </Dialog>
            <ConflictDialog conflicts={conflicts} />
            <DeleteGroupConfirmModal
                openState={[deleteModalOpen, setDeleteModalOpen]}
                groupName={params.row.admissionGroup}
                handleDelete={handleDelete}
            />
        </Grid>
    );
}
