import VisibilityIcon from '@mui/icons-material/Visibility';
import { StudentOperationsCellRendererMobileProps, StudentOperationsCellRendererProps } from '.';
import FillQuestionnaire, { FillQuestionnaireMobile } from './FillQuestionnaire';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { STATUS } from '../Status/utils';
import { endpoints } from '../../../../utils/endPoints';

export default function PreviousSummaryDocument({ params }: StudentOperationsCellRendererProps) {
    return (
        <FillQuestionnaire
            url={clientEndPoints.student_self_assessment_previous_summary}
            filename="korabbi_osszefoglalo.jpg"
            Icon={<VisibilityIcon />}
            status={STATUS.AVAILABLE}
            errorStatus={STATUS.UNAVAILABLE}
            endpoint={endpoints.students.selfEvaluation.previousSummary}
            type="previousSummary"
            {...params}
        />
    );
}

export function PreviousSummaryDocumentMobile({ params }: StudentOperationsCellRendererMobileProps) {
    return (
        <FillQuestionnaireMobile
            url={clientEndPoints.student_self_assessment_previous_summary}
            filename="korabbi_osszefoglalo.jpg"
            Icon={<VisibilityIcon />}
            status={STATUS.AVAILABLE}
            errorStatus={STATUS.UNAVAILABLE}
            endpoint={endpoints.students.selfEvaluation.previousSummary}
            type="previousSummary"
            {...params}
        />
    );
}
