import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ProfileTabProps } from './types';

export const ProfileTabs = ({ value, handleChange }: ProfileTabProps) => (
    <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab label="Alapadatok" className="studentProfile" />
                <Tab label="Tanulmányi adatok" className="studentProfile" />
                <Tab label="További tapasztalatok" className="studentProfile" />
            </Tabs>
        </Box>
    </Grid>
);
