import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IconMap, colors, status } from './utils';
import { TStatusKey } from './types';
import { ComponentMapKeys } from '../Operations/utils';

export default function StatusCellRenderer(params: GridRenderCellParams) {
    const values = status[params.row.name as ComponentMapKeys];
    const entry = Object.entries(values).find(([_, value]) => value.value === params.value);
    if (!entry) return '-';
    const [key, value] = entry;
    const colorKey = key === 'none' ? 'disabled' : key;
    if (key === 'empty') return <></>;

    return (
        <Typography component="div" color={colors[colorKey as Exclude<TStatusKey, 'empty'>]} display="flex" gap="4px">
            {IconMap[colorKey]} {value.label}
        </Typography>
    );
}

export function StatusCellRendererMobile({ name, st }: { name: string; st: string }) {
    const values = status[name as ComponentMapKeys];
    const entry = Object.entries(values).find(([_, value]) => value.value === st);
    if (!entry) return <>{'-'}</>;
    const [key, value] = entry;
    const colorKey = key === 'none' ? 'disabled' : key;

    if (key === 'empty') return <></>;

    return (
        <Typography component="div" color={colors[colorKey as Exclude<TStatusKey, 'empty'>]} display="flex" gap="4px">
            {IconMap[colorKey]} {value.label}
        </Typography>
    );
}
