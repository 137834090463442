import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { IPrerequisites } from './types';

export default function PrerequisiteModal({
    open,
    courseName,
    courseCode,
    prerequisites,
    handleClose,
}: {
    open: boolean;
    courseName: string;
    courseCode: string;
    prerequisites: IPrerequisites[];
    handleClose: () => void;
}) {
    return (
        <Dialog open={open}>
            <DialogTitle>Előfeltétel nem teljesül</DialogTitle>
            <DialogContent sx={{ marginBottom: 0, marginTop: '1rem' }}>
                <Grid container>
                    <Grid item>
                        <Typography>
                            A(z) &quot;{courseName}&quot; - {courseCode} nevű kurzus felvételéhez teljesítened kell az
                            előfeltételként megadott kurzusokat:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ul>
                            {prerequisites.map((e, i) => (
                                <li key={e.course + i}>
                                    {e.reference} - {e.course}
                                </li>
                            ))}
                        </ul>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                    style={{
                        display: 'flex',
                    }}
                >
                    Vissza
                </Button>
            </DialogActions>
        </Dialog>
    );
}
