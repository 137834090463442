import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Skeleton from '@mui/material/Skeleton';
import { useCallback, useEffect } from 'react';
import { Option } from '../../../utils/AppConst';
import useFetch from '../../../utils/useFetch';
import { BackendSelectProps } from './types';
import { getOptionLabel, isOptionEqualToValue } from './utils';
import Input from './Input';

export default function BackendSelect<T>({
    url,
    transform,
    label,
    value,
    setter,
    responseKey,
    defaultValueCondition,
    setRefresh,
    multiple,
    helperText,
    error,
    disabled,
}: BackendSelectProps<T>) {
    const { data, loading } = useFetch<T[]>(url, responseKey === undefined ? 'data' : responseKey, url);
    const options = transform ? transform(data) : (data as Option[]);
    const RenderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <Input {...params} error={error} helperText={helperText} label={label} />
        ),
        [helperText, label, error],
    );

    const handleChange = useCallback(
        (_: unknown, value: Option | Option[] | null) => {
            setter(value as Option<string>[] & Option<string>);
            setRefresh?.((prev) => !prev);
        },
        [setter, setRefresh],
    );

    useEffect(() => {
        if (defaultValueCondition && data.length > 0) {
            const result = defaultValueCondition(data);
            if (result) {
                setter(result as Option<string>[] & Option<string>);
                setRefresh?.((prev) => !prev);
            }
        }
    }, [defaultValueCondition, data, setter, setRefresh]);

    return loading ? (
        <Skeleton height="50px" />
    ) : (
        <Autocomplete
            options={options}
            value={value}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleChange}
            getOptionLabel={getOptionLabel}
            size="small"
            renderInput={RenderInput}
            multiple={multiple}
            filterSelectedOptions
            ChipProps={{ color: 'secondary' }}
            noOptionsText="Nincs találat"
            disabled={disabled}
        />
    );
}
