import Grid from '@mui/material/Grid';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Page, Tooltip } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MCCLoading from '../../../../components/MCCLoading';
import RefreshButtonWithText from '../../../../components/RefreshButtonWithText';
import campusesService from '../../../../services/masterData/campuses';
import countriesService from '../../../../services/masterData/countriesService';
import { RootState } from '../../../../store';
import { create } from '../../../../store/notification';
import { paginatorInfoBuild } from '../../../../utils/AppConst';
import { ActiveCellRenderer, AddressCellRenderer, TooltipRenderer } from '../../../../utils/AppConstElements';
import { IInfo } from '../../../../utils/Interfaces/IInfo';
import { Campus, IForm } from '../../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { endpoints } from '../../../../utils/endPoints';
import { ICountry } from '../Universities/useUniversityFields';
import BtnCellRenderer from './CellRenderers/BtnCellRenderer';
import InfoIcon from '@mui/icons-material/Info';
import { tooltipHTML } from './utils';
import { useTenant } from '../../../../components/TenantContext';

const initialInfo: IInfo<IForm> = {
    filter: {},
    sort: {
        name: 'asc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

export default function Campuses() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [data, setData] = useState<Campus[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState([]);
    const [info, setInfo] = useState(initialInfo);
    const dispatch = useDispatch();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const tenant = useTenant();

    const fetchData = useCallback(
        (form: IInfo<IForm>) => {
            setTableLoading(true);

            axios
                .all([
                    campusesService.filter({ ...form.filter, ...paginatorInfoBuild(form) }).then((d: AxiosResponse) => {
                        const totalItems = d.data['hydra:totalItems'];
                        setData(d.data['hydra:member']);
                        setInfo((prev) => ({
                            ...prev,
                            metadata: {
                                'allCount': totalItems,
                                'filteredCount': totalItems,
                                'lastPage': totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                                'page': info.page,
                                'perpage': info.perpage,
                            },
                        }));
                    }),

                    countriesService.countriesWithCampuses().then((response) => {
                        const countryOptions = response.data['hydra:member'].map((row: ICountry) => {
                            return { value: row['@id'], label: row['countryName'] };
                        });
                        setCountries(countryOptions);
                    }),
                ])
                .catch(() =>
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    ),
                )
                .finally(() => {
                    setTableLoading(false);
                    setLoading(false);
                });
        },
        [dispatch, info.page, info.perpage],
    );

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            fetchData(initialInfo);
        }
    }, [fetchData, firstLoad]);

    const loadTable = () => {
        setTableLoading(true);

        axios
            .all([
                campusesService
                    .filter({})
                    .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
                    .finally(() => setTableLoading(false)),
                countriesService.countriesWithCampuses().then((response) => {
                    const countryOptions = response.data['hydra:member'].map((row: ICountry) => {
                        return { value: row['@id'], label: row['countryName'] };
                    });
                    setCountries(countryOptions);
                }),
            ])
            .catch(() =>
                dispatch(
                    create({
                        type: 'error',
                        message: 'Hiba a betöltés során!',
                    }),
                ),
            )
            .finally(() => {
                setTableLoading(false);
                setLoading(false);
            });
    };

    const header = {
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
        },
        title: 'Campusok',
        project: tenant || 'TAR',
    };

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    function transformForm(form: IForm) {
        const ret = form;
        const fields = ['postCode', 'city', 'streetType', 'streetName', 'houseNumber'];

        fields.forEach((field) => {
            if (field in form) {
                if (form[field] !== '') {
                    ret[`address.${field}`] = form[field];
                }
                delete form[field];
            }
        });
        return ret;
    }

    const onSubmit = (form: IForm) => {
        setTableLoading(true);
        const newForm = transformForm(form);
        setInfo((prevState) => ({
            ...prevState,
            filter: newForm,
        }));

        const filter = { ...newForm, ...paginatorInfoBuild(info) };
        return campusesService
            .filter(filter)
            .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
            .finally(() => {
                setTableLoading(false);
                setLoading(false);
            });
    };

    function CountryRenderer(params: GridRenderCellParams) {
        return TooltipRenderer(params.row.country?.countryName ? params.row.country?.countryName : '-');
    }

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Page header={header}>
            <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                {!user.archive && (
                    <Grid item display="flex" gap="8px" alignItems="center" justifyContent="flex-end">
                        <RefreshButtonWithText
                            method="get"
                            endpoint={endpoints.masterData.campuses.sync}
                            toggleRefresh={() => {
                                dispatch(
                                    create({
                                        autoHideDuration: 20000,
                                        type: 'info',
                                        message:
                                            'A Campusok Rendezvénynaptár rendszerből való szinkronizálása elindult. Kérjük, néhány perc múlva frissítsd az oldalt a találatok megtekintéséhez.',
                                    }),
                                );
                                return setRefresh((prev) => !prev);
                            }}
                            buttonString="Rendezvénynaptár adatainak szinkronizálása"
                            iconColor="#364354"
                        />
                        <Tooltip
                            title={<div dangerouslySetInnerHTML={{ __html: tooltipHTML }}></div>}
                            sx={{ color: '#364354' }}
                        >
                            <InfoIcon />
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            <FormikListPage
                header={{ title: '', breadcrumbs: {}, project: '' }}
                filter={{
                    onSubmit,
                    fields: [
                        {
                            name: 'name',
                            label: 'Név',
                            format: { xs: 12, md: 6, lg: 3 },
                        },
                        {
                            name: 'shortName',
                            label: 'Rövid név',
                            format: { xs: 12, md: 6, lg: 3 },
                        },
                        {
                            name: 'active',
                            label: 'Aktív',
                            type: 'select',
                            options: [
                                { value: '1', label: 'Aktív' },
                                { value: '0', label: 'Inaktív' },
                            ],
                            format: { xs: 12, md: 6, lg: 3 },
                        },
                        {
                            name: 'country',
                            label: 'Ország',
                            type: 'multiselect',
                            options: countries,
                            format: { xs: 12, md: 6, lg: 3 },
                        },
                        {
                            name: 'postCode',
                            label: 'Irányítószám',
                            format: { xs: 12, md: 3 },
                        },
                        {
                            name: 'city',
                            label: 'Település',
                            format: { xs: 12, md: 9 },
                        },
                        {
                            name: 'streetName',
                            label: 'Közterület neve',
                            format: { xs: 12, md: 4 },
                        },
                        {
                            name: 'streetType',
                            label: 'Közterület jellege',
                            format: { xs: 12, md: 4 },
                        },
                        {
                            name: 'houseNumber',
                            label: 'Házszám',
                            format: { xs: 12, md: 4 },
                        },
                    ],
                }}
                refresh={refresh}
                table={{
                    columnDefs: [
                        { headerName: 'Név', field: 'name', minWidth: 200 },
                        { headerName: 'Rövid név', field: 'shortName' },
                        { headerName: 'Ország', field: 'country', renderCell: CountryRenderer, minWidth: 150 },
                        {
                            headerName: 'Cím',
                            field: 'address',
                            cellRenderer: 'addressCellRenderer',
                            sortable: false,
                            minWidth: 280,
                            //   comparator: (a: { postCode: string; }, b: { postCode: string; }) => a?.postCode?.localeCompare(b?.postCode)
                        },
                        {
                            headerName: 'Aktív',
                            field: 'active',
                            valueGetter: (params: GridValueGetterParams) => (params.row.active ? 'Aktív' : 'Inaktív'),
                            align: 'center',
                        },
                        {
                            headerName: 'Műveletek',
                            field: 'operations',
                            cellRenderer: 'btnCellRenderer',
                            sortable: false,
                            minWidth: 200,
                        },
                    ].map((col) => ({ flex: 1, headerAlign: 'center', minWidth: 100, ...col })),
                    rowData: data,
                    loading: tableLoading,
                    searchfunc: fetchData,
                    info: info,
                    server: true,
                    initialState: {
                        sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                        },
                    },
                    setInfo: setValueByKey,
                    frameworkComponents: {
                        btnCellRenderer: (params: GridRenderCellParams) => (
                            <BtnCellRenderer {...params} loadTable={loadTable} readonly={!!user.archive} />
                        ),
                        addressCellRenderer: AddressCellRenderer,
                        activeCellRenderer: ActiveCellRenderer,
                    },
                }}
            />
        </Page>
    );
}
