import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import useOpen, { OpenState } from '../../../../utils/useOpen';
import { Checkbox } from '@silinfo/front-end-template';
import { useEffect } from 'react';

export default function ConfirmationDialog({ isOpen, close, onClick }: OpenState & { onClick: () => void }) {
    const { isOpen: value, toggleOpen: toggle, close: setFalse } = useOpen();

    const handleClick = () => {
        onClick();
        close();
    };

    useEffect(() => {
        if (!isOpen) {
            setFalse();
        }
    }, [isOpen, setFalse]);

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Szemeszter zárása</DialogTitle>
            <DialogContent style={{ paddingTop: '12px', paddingBottom: 0 }}>
                <DialogContentText>
                    A szemeszter zárását követően a szemeszterhez tartozó követelmények és hallgatói teljesítések
                    rögzítésre és eltárolásra kerülnek. Ezt követően nincs lehetőség a szemeszter követelményeinek
                    módosítására.
                </DialogContentText>
                <Checkbox
                    label="Megértettem, hogy a szemeszter zárása nem visszavonható művelet."
                    checkbox={{
                        checked: value,
                        onChange: toggle,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button onClick={close} variant="outlined">
                            Mégsem
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            disabled={!value}
                            onClick={handleClick}
                            variant="contained"
                            color="error"
                            loadingPosition="start"
                        >
                            Szemeszter zárása
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
