import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import BackgroundLogo from '../../../images/MCClogo_negacolor.svg';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100dvh',
    },
    image: {
        backgroundImage: `url(${BackgroundLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.secondary.main,
        backgroundSize: '80%',
        backgroundPosition: 'center',
        boxShadow: '20px',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mobilePaper: {
        margin: '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        margin: theme.spacing(2),
    },
}));

export default function AdmissionLayout({ children }: { children: JSX.Element[] | JSX.Element }) {
    const classes = useStyles();
    const theme = useTheme() as Theme;
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container component="main" className={classes.root} style={{ paddingTop: 0 }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={3}
                style={{ backgroundColor: theme.palette.secondary.main }}
                className={classes.image}
                component={Paper}
                elevation={20}
                square
            />
            <Grid item xs={12} sm={8} md={9} sx={{ backgroundColor: '#EFF2F5' }}>
                <div className={matches ? classes.paper : classes.mobilePaper}>{children}</div>
            </Grid>
        </Grid>
    );
}
