import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const Data = ({ children, label }: { label: string; children: JSX.Element | string }) => (
    <Grid item>
        <Typography color="white">{label}</Typography>
        <Typography>
            <strong>{children}</strong>
        </Typography>
    </Grid>
);

export default Data;
