import { useParams } from 'react-router-dom';
import PreviewTemplate from '../../../components/PreviewTemplate';
import { endpoints } from '../../../utils/endPoints';

export default function QuestionnairePreview() {
    const { id } = useParams() as { id: string };
    const endpoint = endpoints.admission.previewApplicantProgramQuestionnaire(id);

    return <PreviewTemplate url={endpoint} keyPrefix="student_preview" type="admission" />;
}
