import BasicRefreshButton from '../../../components/RefreshButton';
import { endpoints } from './../../../utils/endPoints';

interface RefreshButtonProps {
    semester: string;
    toggleRefresh: () => void;
}

export default function RefreshButtona({ semester, toggleRefresh }: RefreshButtonProps) {
    return (
        <BasicRefreshButton
            endpoint={endpoints.selfEvaluation.report.refresh(semester)}
            toggleRefresh={toggleRefresh}
            tooltip="Adatok frissítése"
        />
    );
}
