import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { CancelButton, FieldGenerator } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import Form from '../../../../components/Form/Form';
import ForeignUniversityAutomcomplete from '../../../../pages/MasterData/General/Students/Form/ActualUniversityStudies/ForeignUniversityAutocomplete';
import { financingFormOptions, trainingFormOptions } from '../../../../pages/MasterData/General/Students/Form/options';
import studentProfileService from '../../../../services/studentServices/studentProfileService';
import { setProfile } from '../../../../store/studentProfile';
import { isKep, Option } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { IActualStudies } from './utils';

export const EditMode = ({
    aus,
    universities,
    legalRelationships,
    trainingForms,
    initialValues,
    setIsOneEdit,
}: {
    aus?: IActualStudies;
    universities: Option<string>[];
    legalRelationships: Option<string>[];
    trainingForms: Option<string>[];
    initialValues: IActualStudies;
    setIsOneEdit?: Dispatch<SetStateAction<boolean>>;
}) => {
    const dispatch = useDispatch();

    const handleSubmitActualStudies = (form: IForm) => {
        return studentProfileService.saveActualUniversityStudies(form).then((res) => {
            if (setIsOneEdit) {
                setIsOneEdit(false);
            }
            dispatch(setProfile(res?.data?.data));
        });
    };

    return (
        <Grid container item xs={12}>
            <Form
                hideButtons
                fields={[]}
                onSubmit={handleSubmitActualStudies}
                initialValues={
                    aus
                        ? {
                              ...aus,
                              university: aus.university.split('/').slice(-1)[0],
                              trainingForm:
                                  trainingForms.find((option) => option.value == aus.trainingForm)?.value || '-',
                          }
                        : initialValues
                }
            >
                {(formikProps) => {
                    return (
                        <Grid container item xs={12}>
                            {FieldGenerator({
                                name: 'university',
                                label: 'Egyetem neve',
                                type: 'select',
                                options: universities,
                                format: { xs: 12 },
                                ...formikProps,
                            })}
                            {formikProps.values.university == '64' && (
                                <ForeignUniversityAutomcomplete
                                    key={'foreignUniversity'}
                                    value={formikProps.values.foreignUniversity as unknown as string | null}
                                    onChange={(value) => {
                                        formikProps.setFieldValue('foreignUniversity', value);
                                    }}
                                    disabled={false}
                                    errorMessage={formikProps.errors.foreignUniversity}
                                />
                            )}
                            {FieldGenerator({
                                name: 'major',
                                label: 'Szak',
                                format: { xs: 12 },
                                ...formikProps,
                            })}
                            {FieldGenerator({
                                name: 'trainingForm',
                                label: 'Képzés formája',
                                type: 'select',
                                options: trainingFormOptions,
                                format: { xs: 12 },
                                ...formikProps,
                            })}
                            {FieldGenerator({
                                name: 'financeForm',
                                label: 'Finanszírozás formája',
                                type: 'select',
                                options: financingFormOptions,
                                format: { xs: 12 },
                                ...formikProps,
                            })}
                            {FieldGenerator({
                                name: 'semesterStatus',
                                type: 'select',
                                label: 'Hallgatói jogviszony',
                                options: legalRelationships,
                                format: { xs: 12 },
                                ...formikProps,
                            })}
                            {!isKep() &&
                                FieldGenerator({
                                    name: 'neptun',
                                    label: 'Neptun kód',
                                    format: { xs: 12 },
                                    ...formikProps,
                                })}
                            {isKep() &&
                                FieldGenerator({
                                    name: 'pedigreeNumber',
                                    label: 'Törzskönyvi szám (Număr matricol)',
                                    format: { xs: 12 },
                                    ...formikProps,
                                })}
                            {FieldGenerator({
                                name: 'admission',
                                label: 'Egyetemi felvételi határozat',
                                type: 'file',
                                origFileName: 'fileName',
                                uuidFileName: 'filePath',
                                tempType: 'student-document',
                                downloadUrl: `${endpoints.fileManagement.download}?id=${
                                    aus ? aus.id : 0
                                }&type=student-document&subType=actual-university-studies`,
                                format: { xs: 12 },
                                ...formikProps,
                            })}
                            <Grid item container justifyContent="flex-end" style={{ marginTop: '8px' }} xs={12}>
                                <CancelButton
                                    style={{ marginRight: '8px' }}
                                    onClick={() => {
                                        if (setIsOneEdit) {
                                            setIsOneEdit(false);
                                        }
                                    }}
                                >
                                    Mégsem
                                </CancelButton>
                                <Button variant="contained" type="submit">
                                    Mentés
                                </Button>
                            </Grid>
                        </Grid>
                    );
                }}
            </Form>
        </Grid>
    );
};
