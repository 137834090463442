import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback } from 'react';

interface ConfirmationDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    onConfirm: () => void;
    onDecline?: () => void;
    text?: string;
    title?: string;
}

export default function ConfirmationDialog({ openState, onConfirm, onDecline, text, title }: ConfirmationDialogProps) {
    const [open, setOpen] = openState;

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>{title || 'Megerősítés'}</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>
                <DialogContentText>{text || 'Biztosan mented a változtatásokat?'}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDecline || handleClose} variant="outlined">
                    Nem
                </Button>
                <Button onClick={onConfirm} variant="contained">
                    Igen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
