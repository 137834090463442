import { documentColumns } from '../../../../Administration/SelfAssessment/utils';
import { GridColDef } from '@mui/x-data-grid-pro';

export const columns: GridColDef[] = [
    {
        field: 'semester',
        headerName: 'Szemeszter',
    },
    ...documentColumns,
];
