import Transcript from '../../../pages/Administration/Certificates/Transcript';

export const transcript = {
    path: 'transcript',
    children: [
        {
            path: '',
            element: <Transcript />,
            name: 'admin_certificates_transcript',
        },
    ],
};
