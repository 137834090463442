import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Yup from '../../../utils/yup';
import { RootState } from '../../../store';
import { Option } from '../../../utils/AppConst';
import Form from '../../../components/Form/Form';
import studentsService from '../../../services/masterData/students';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { Accordion, CancelButton, Page } from '@silinfo/front-end-template';
import AdministrationFormFields from './AdministrationFormFields';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { useTenant } from '../../../components/TenantContext';

const Schema = Yup.object().shape({
    administrationFee: Yup.string().required(),
    semester: Yup.string().required(),
    // students: Yup.string().required()
});

const initialValues = {
    administrationFee: '',
    semester: '',
    administrationFeeVal: '',
    amount: '',
    students: [],
};

interface IAdministrationFeeVal {
    administrationFeeVal: string;
}

interface IAddAdministrationFeeForm extends IAdministrationFeeVal {
    students: Option[];
}

export interface IAddAdministrationFeeFormToSubmit extends IAdministrationFeeVal {
    students: number[];
}

export interface IAdministrationForm {
    administrationFee: string;
    semester: string;
    administrationFeeVal: string;
    amount: string;
    students: never[];
}

export default function FinanceAdministrationFeeForm() {
    const { count } = useSelector((state: RootState) => state.loading);
    const navigate = useNavigate();
    const tenant = useTenant();

    const transformator = ({ administrationFeeVal, students }: IAddAdministrationFeeForm) => ({
        administrationFeeVal,
        students: students.map((elem) => parseInt(elem.value)),
    });

    const header = {
        project: tenant || 'TAR',
        title: 'Adminisztrációs díjak',
        breadcrumbs: {
            finance: 'Pénzügyek',
            [clientEndPoints.finance_administration_fees_list]: 'Adminisztrációs díjak',
            newAdministrationFee: 'Díj kiszabása',
        },
    };

    return (
        <Page header={header}>
            <Accordion title="Díj kiszabása" defaultExpanded disableClosing>
                <Form
                    hideButtons
                    back={clientEndPoints.finance_administration_fees_list}
                    fields={[]}
                    validationSchema={Schema}
                    onSubmit={(form: IAddAdministrationFeeFormToSubmit) => studentsService.addAdministrationFee(form)}
                    submitTransformData={(form: IAddAdministrationFeeForm) => transformator(form)}
                    initialValues={initialValues}
                >
                    {(props) => (
                        <>
                            <AdministrationFormFields {...props} />
                            <Grid item container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <LoadingButton loading={count > 0} variant="contained" type="submit">
                                        Mentés
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <CancelButton
                                        onClick={() =>
                                            navigate(
                                                sessionStorage.getItem('administrationFee')
                                                    ? -1
                                                    : (clientEndPoints.finance_administration_fees_list as unknown as number),
                                            )
                                        }
                                    >
                                        Bezár
                                    </CancelButton>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Form>
            </Accordion>
        </Page>
    );
}
