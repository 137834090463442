import Grid from '@mui/material/Grid';
import { ICourseView } from '../../../pages/CourseManagement/Courses/types';
import { Paper } from '../Paper';
import Label from './Label';
const BaseData = (props: ICourseView) => {
    return (
        <Grid item xs={12} md={6}>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Label>Kurzustípus</Label>
                        {props.courseTypeReal}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Label>Kurzuskód</Label>
                        {props.courseCode}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Label>Campus</Label>
                        {props.campus}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Label>Cím</Label>
                        {props.address}
                    </Grid>
                    {props.enrollmentStart && props.enrollmentEnd && (
                        <Grid item xs={12} md={12}>
                            <Label>Kurzusfelvételi időszak</Label>
                            {props.enrollmentStart} - {props.enrollmentEnd}
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default BaseData;
