import Tooltip from '@mui/material/Tooltip';
import { TooltipContent } from './TooltipContent';
import { RowProps } from './types';

export const Points = (props: RowProps) => (
    <>
        <Tooltip
            title={
                <TooltipContent isPoint min={props.enrolledCourses.point.min} max={props.enrolledCourses.point.max} />
            }
        >
            <div>
                {props.enrolledCourses.point.min === props.enrolledCourses.point.max
                    ? props.enrolledCourses.point.max
                    : props.enrolledCourses.point.min + ' - ' + props.enrolledCourses.point.max}{' '}
                pont
            </div>
        </Tooltip>
        <Tooltip
            title={
                <TooltipContent
                    isPoint={false}
                    min={props.enrolledCourses.copper.min}
                    max={props.enrolledCourses.copper.max}
                />
            }
        >
            <div>
                {props.enrolledCourses.copper.min === props.enrolledCourses.copper.max
                    ? props.enrolledCourses.copper.max
                    : props.enrolledCourses.copper.min + ' - ' + props.enrolledCourses.copper.max}{' '}
                garas
            </div>
        </Tooltip>
    </>
);
