import React, { createContext } from 'react';
import NoSemesterAlert from './NoSemesterAlert';

type SemesterContextType = string;

const defaultContext: SemesterContextType = '';

const SemesterContext = createContext<SemesterContextType>(defaultContext);

interface SemesterContextProviderProps {
    children: React.ReactNode;
    semester: string;
}

export function SemesterContextProvider({ semester, children }: SemesterContextProviderProps) {
    return semester ? (
        <SemesterContext.Provider value={semester}>{children}</SemesterContext.Provider>
    ) : (
        <NoSemesterAlert />
    );
}

export default SemesterContext;
