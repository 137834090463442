import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material/';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CancelButton, Loading, SubmitButton, TableButton, TableEditButton } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import { Formik, Form as FormikForm } from 'formik';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import EndpointProvider from '../../../../components/EndpointProvider';
import courseListService from '../../../../services/courseManagement/courseList';
import { create } from '../../../../store/notification';
import { useParams } from 'react-router-dom';
import useAccessCheck from '../../../../utils/useAccessCheck';
import StudentsListButton from '../StudentsListButton';
import AddEditEventButton from './AddEditEventButton';
import DeleteEvent from './DeleteEvent';
import { IEvent } from './types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveEvent from './RemoveEvent';
import EventPresenceTable from './EventPresenceTable';
import { IForm } from '../../../../utils/Interfaces/interfaces';

interface IEventForm {
    point: number | null;
    present: boolean;
}

export function OperationCellRenderer({
    row,
    id,
    setTableLoading,
    readOnly,
    scoringType = 'point',
    courseTenant,
    ...params
}: GridRenderCellParams<IEvent, IEvent> & {
    id: number;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    readOnly?: boolean;
    scoringType: string;
    courseTenant: string | null;
}) {
    const { courseId } = useParams() as { courseId: string };
    const [open, setOpen] = useState<boolean>(false);
    const [enrollments, setEnrollments] = useState<Record<string, Record<string, string>[]>>({});
    const [tenants, setTenants] = useState<Record<string, string>>({});
    const [form, setForm] = useState({});
    const dispatch = useDispatch();
    const hasAccessToEditRoster = useAccessCheck(['course_manamagement_course_attendance_edit']);
    const hasAccessToEditElement = useAccessCheck(['course_manamagement_course_event_edit']);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggle = useCallback(() => setOpen((prev) => !prev), []);

    const fetchData = useCallback(
        function () {
            if (open) {
                setLoading(true);
                courseListService
                    .getCoursePresences(+courseId, row.id, courseTenant)
                    .then((res) => {
                        setEnrollments(res.data.users);
                        setTenants(res.data.tenants);
                        const list: IForm = Object.keys(res.data.tenants).reduce((acc: IForm, tenant) => {
                            return {
                                ...acc,
                                ...res.data.users[tenant].reduce(
                                    (innerAcc: IForm, user: IForm) => ({
                                        ...innerAcc,
                                        ['student_' + user.id]: {
                                            'authId': user.id,
                                            'tenant': tenant,
                                            'present': user.presence,
                                            'point': user.grade,
                                        },
                                    }),
                                    {},
                                ),
                            };
                        }, {});
                        setForm(list);
                    })
                    .finally(() => setLoading(false));
            }
        },
        [open, row.id, courseId, courseTenant],
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSubmit = useCallback(
        (form: Record<string, IEventForm>) => {
            // const points =
            //     Object.entries(form).length > 0
            //         ? Object.entries(form).reduce((prev, [key, value]) => {
            //               return {
            //                   ...prev,
            //                   [key.split('_')[1]]: value.point,
            //               };
            //           }, {})
            //         : [];
            // const newForm =
            //     Object.entries(form).length > 0
            //         ? Object.entries(form).reduce(
            //               (prev: { students: number[] }, [key, value]) => {
            //                   return {
            //                       ...prev,
            //                       students: [...(prev.students || []), ...(value.present ? [+key.split('_')[1]] : [])],
            //                   };
            //               },
            //               { students: [] },
            //           )
            //         : { students: [] };
            setIsSubmitting(true);

            courseListService
                .coursePresences(form, +courseId, row.id)
                .then(() => {
                    fetchData();
                    dispatch(
                        create({
                            type: 'success',
                            message: 'Sikeres mentés!',
                        }),
                    );
                    setOpen(false);
                })
                .catch((error: AxiosError) => {
                    if (Array.isArray(error.response?.data?.violations)) {
                        error.response?.data?.violations.forEach((err: { message: string }) => {
                            dispatch(
                                create({
                                    type: 'error',
                                    message: err.message,
                                }),
                            );
                        });
                        setLoading(false);
                        setOpen(true);
                        return;
                    }
                    dispatch(
                        create({
                            type: 'error',
                            message: error.response?.data?.violations?.courseClosed || 'Hiba a mentés során.',
                        }),
                    );
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [dispatch, row.id, courseId, fetchData],
    );

    if (loading) {
        return <></>;
    }

    return (
        <>
            <EndpointProvider
                endpoints={['course_manamagement_course_attendance_show', 'course_manamagement_course_attendance_edit']}
            >
                <Dialog open={open} onClose={toggle} fullWidth maxWidth={'md'}>
                    <DialogTitle
                        className="eventPresence"
                        sx={{ backgroundColor: '#364354 !important', color: 'white' }}
                    >
                        Jelenléti ív ({row.eventDate})
                        <IconButton onClick={toggle} style={{ marginRight: '0px', float: 'right', color: 'white' }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    {!loading ? (
                        <Formik initialValues={form} onSubmit={handleSubmit}>
                            {(formikProps) => {
                                return (
                                    <FormikForm>
                                        {
                                            <EventPresenceTable
                                                {...params}
                                                row={row}
                                                id={id}
                                                formikProps={formikProps}
                                                scoringType={scoringType}
                                                readOnly={readOnly}
                                                enrollments={enrollments}
                                                tenants={tenants}
                                                hasAccessToEditRoster={hasAccessToEditRoster}
                                                courseTenant={courseTenant}
                                            />
                                        }
                                        <DialogActions>
                                            <Grid item container xs={12} spacing={2} flexDirection={'row-reverse'}>
                                                {hasAccessToEditRoster && !closed && !readOnly && (
                                                    <Grid item>
                                                        <SubmitButton loading={isSubmitting} type="submit">
                                                            Mentés
                                                        </SubmitButton>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <CancelButton onClick={toggle}>Vissza</CancelButton>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    ) : (
                        <Loading noHeight />
                    )}
                </Dialog>
                <StudentsListButton tooltip={'Jelenléti ív'} onClick={toggle} />
            </EndpointProvider>
            {row.external && (
                <AddEditEventButton
                    title="Esemény megtekintése"
                    setTableLoading={setTableLoading}
                    submitBtnTitle="Mentés"
                    opener={
                        <TableButton
                            style={{ marginRight: '1px', marginLeft: '2px', background: '#f3ca12' }}
                            tooltip="Esemény megtekintése"
                        >
                            <VisibilityIcon sx={{ color: 'white' }} />
                        </TableButton>
                    }
                    objectId={row.id}
                    readOnly={true}
                    courseTenant={courseTenant}
                />
            )}
            {!closed && !readOnly && hasAccessToEditElement && (
                <>
                    {!row.external ? (
                        <>
                            <AddEditEventButton
                                title="Esemény szerkesztése"
                                setTableLoading={setTableLoading}
                                submitBtnTitle="Mentés"
                                opener={
                                    <TableEditButton
                                        tooltip="Esemény szerkesztése"
                                        sx={{ marginRight: '2px', marginLeft: '1px' }}
                                    />
                                }
                                objectId={row.id}
                                courseTenant={courseTenant}
                            />
                            <DeleteEvent
                                courseId={id}
                                eventToDelete={row}
                                setLoading={setTableLoading}
                                courseTenant={courseTenant}
                            />
                        </>
                    ) : (
                        <RemoveEvent courseId={id} eventToDelete={row} setLoading={setTableLoading} />
                    )}
                </>
            )}
        </>
    );
}
