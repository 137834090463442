import { Input } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import {
    QUESTIONNAIRE_GROUP_READABLE_OPTIONS,
    QUESTIONNAIRE_GROUP_STUDENT_FINALIZE_OPTIONS,
    QUESTIONNAIRE_GROUP_TYPE_OPTIONS,
    QUESTIONNAIRE_GROUP_WRITEABLE_OPTIONS,
} from '../useTemplateFields';
import { ITemplateGroupForm } from './types';
import questionnaireTemplateService from '../../../../services/selfEvalution/questionnaireTemplate';

const header = (title: string, tenant: string | null) => ({
    title,
    project: tenant || 'TAR',
    breadcrumbs: {
        selfEvaluation: 'Adminisztráció',
        [clientEndPoints.admin_questionnaire_templates + '?tab=1']: 'Kérdőívsablonok',
        form: title,
    },
});

const initialValue: ITemplateGroupForm = {
    name: '',
    // Ez csak addig amég ez az 1 típus van.
    type: QUESTIONNAIRE_GROUP_TYPE_OPTIONS[0].value,
    questionnaireTemplate1: null,
    questionnaireTemplate2: null,
    ruleQuestionnaire2Read: '',
    ruleQuestionnaire2Write: '',
    ruleStudentCanFinalize: '',
};

const fetchOptions = async () => {
    try {
        const response = await questionnaireTemplateService.getOptions();
        return response.data;
    } catch (error) {
        console.error('Error fetching options:', error);
        return [];
    }
};

export const IF_FIRST_IS_FINALIZED = 'if_first_is_finalized';
export const WRITE_FIELD_NAME = 'ruleQuestionnaire2Write';

const getFields = async () => {
    const options = await fetchOptions();

    const fields: Input[] = [
        {
            name: 'name',
            label: 'Név',
            format: { xs: 12 },
            props: {},
        },
        {
            name: 'type',
            label: 'Csoport típusa',
            format: { xs: 12 },
            type: 'select',
            options: QUESTIONNAIRE_GROUP_TYPE_OPTIONS,
            props: {
                disabled: true,
            },
        },
        {
            name: 'questionnaireTemplate1',
            label: 'Első kérdőív',
            format: { xs: 12 },
            type: 'select',
            options: options,
            props: {
                sx: {
                    '& .MuiPaper-root': {
                        maxHeight: '300px !important',
                    },
                },
            },
        },
        {
            name: 'questionnaireTemplate2',
            label: 'Második kérdőív',
            format: { xs: 12 },
            type: 'select',
            options: options,
            props: {
                sx: {
                    '& .MuiPaper-root': {
                        maxHeight: '300px',
                    },
                },
            },
        },
        {
            name: 'ruleQuestionnaire2Read',
            label: 'Második kérdőív olvasható',
            format: { xs: 12 },
            type: 'select',
            options: QUESTIONNAIRE_GROUP_READABLE_OPTIONS,
            fieldType: 'base',
            props: {},
        },
        {
            name: WRITE_FIELD_NAME,
            label: 'Második kérdőív írható',
            format: { xs: 12 },
            type: 'select',
            options: QUESTIONNAIRE_GROUP_WRITEABLE_OPTIONS,
            fieldType: 'base',
            props: {},
        },
        {
            name: 'ruleStudentCanFinalize',
            label: 'A hallgató véglegesítheti a kérdőívcsoportot?',
            format: { xs: 12 },
            type: 'select',
            options: QUESTIONNAIRE_GROUP_STUDENT_FINALIZE_OPTIONS,
            props: {},
        },
    ];

    return fields;
};

export { header, initialValue, getFields };
