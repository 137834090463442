import { GridRenderCellParams } from '@mui/x-data-grid-pro';

type PointCellRendererProps = {
    params: GridRenderCellParams;
};

export default function PointCellRenderer({ params }: PointCellRendererProps) {
    return (
        <>
            {params?.row?.maximumAchievablePoint} {params.row.scoringType === 'copper' ? ' garas' : ' pont'}
        </>
    );
}
