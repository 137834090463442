import { LoadingButton } from '@mui/lab';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import {
    DataGridPro,
    getGridStringOperators,
    GridColDef,
    GridColumnMenuContainer,
    GridColumnMenuProps,
    GridFilterMenuItem,
    GridRenderCellParams,
    GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { Page, translation } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MCCLoading from '../../../components/MCCLoading';
import accessManagementService from '../../../services/administration/accessManagement';
import { create } from '../../../store/notification';
import { Option } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import ROLES from '../../../utils/Roles';
import SaveButton from './SaveButton';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

const CustomColumnMenu = (props: GridColumnMenuProps) => {
    const { hideMenu, currentColumn, open } = props;
    return (
        <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={open}>
            <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
        </GridColumnMenuContainer>
    );
};

export interface IModification {
    checked: boolean;
    accessRoleId: string;
    accessFunctionId: string;
}

function ValueCellRenderer(
    params: GridRenderCellParams & {
        modificationsState: [Record<string, IModification>, Dispatch<SetStateAction<Record<string, IModification>>>];
        readOnly?: boolean;
    },
) {
    const [modifications, setModifications] = params.modificationsState;
    const accessRoleId = params.row[params.field].accessRoleId;
    const accessFunctionId = params.row.accessFunctionId;
    const cb_id = accessRoleId + '_' + accessFunctionId;
    const checked =
        typeof modifications[cb_id] === typeof undefined
            ? params.row[params.field].value
            : modifications[cb_id].checked;
    const [currentChecked, setCurrentChecked] = useState(checked);

    return (
        <Checkbox
            checked={currentChecked}
            name={params.row.id}
            disabled={params.readOnly || params.field === ROLES.ADMIN || params.field === ROLES.STUDENT}
            onChange={(e) => {
                const m = modifications;
                m[cb_id] = {
                    checked: e.target.checked,
                    accessRoleId: accessRoleId,
                    accessFunctionId: accessFunctionId,
                };
                if (e.target.checked === params.row[params.field].value) {
                    delete m[cb_id];
                }
                setModifications(m);
                setCurrentChecked(e.target.checked);
            }}
        />
    );
}

const ValueCellRendererOptimized = React.memo(
    ValueCellRenderer,
    (prev, next) => prev.row[prev.field].value === next.row[next.field].value,
);

export default function AccessManagement() {
    const [data, setData] = useState<GridValidRowModel[]>([]);
    const [tableHeader, setTableHeader] = useState<{ headerName: string; field: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [modifications, setModifications] = useState<Record<string, IModification>>({});
    const { user } = useSelector((state: RootState) => state.auth);
    const tenant = useTenant();

    useEffect(() => {
        setLoading(true);
        accessManagementService
            .getTable()
            .then((response: AxiosResponse<{ header: Record<string, Option>; data: GridValidRowModel[] }>) => {
                setData(response.data['data'] || []);
                setTableHeader(
                    Object.entries(response.data['header']).map((data) => {
                        return {
                            headerName: data[1]['label'],
                            field: data[0],
                            renderCell: (params: GridRenderCellParams) => (
                                <ValueCellRendererOptimized
                                    modificationsState={[modifications, setModifications]}
                                    readOnly={user.archive}
                                    {...params}
                                />
                            ),
                            sortable: false,
                            align: 'center',
                            description: data[1]['label'],
                            headerAlign: 'center',
                            disableColumnMenu: true,
                            filterable: false,
                            cellClassName: (params: GridRenderCellParams) => {
                                const accessRoleId = params.row[params.field].accessRoleId;
                                const accessFunctionId = params.row.accessFunctionId;
                                const cb_id = accessRoleId + '_' + accessFunctionId;
                                const checked =
                                    typeof modifications[cb_id] === typeof undefined
                                        ? params.row[params.field].value
                                        : modifications[cb_id].checked;
                                if (!checked) {
                                    return '';
                                }

                                return clsx('accessManagement', {
                                    green: true,
                                });
                            },
                            minWidth: 100,
                        };
                    }),
                );
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen művelet' })))
            .finally(() => setLoading(false));
    }, [dispatch, modifications, refresh, user.archive]);

    const header = {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_access_management_list]: 'Jogosultságok',
        },
        title: 'Jogosultságok',
        project: tenant || 'TAR',
    };

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Page header={header}>
            <Grid item xs={12} style={{ height: '80vh' }} className="noTransition">
                <DataGridPro
                    columns={
                        [
                            {
                                headerName: 'Kategória',
                                field: 'category',
                                sortable: false,
                                filterable: true,
                                minWidth: 100,
                                description: 'Kategória',
                                renderCell: (params: GridRenderCellParams) => (
                                    <Tooltip title={params.value || '-'}>
                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {params.value}
                                        </div>
                                    </Tooltip>
                                ),
                            },
                            {
                                headerName: 'Funkció',
                                field: 'label',
                                sortable: false,
                                filterable: true,
                                minWidth: 250,
                                description: 'Funkció',
                                renderCell: (params: GridRenderCellParams) => (
                                    <Tooltip
                                        title={
                                            <div>
                                                <b style={{ textAlign: 'center' }}>{params.value}</b>
                                                <br />
                                                {params?.row?.description}
                                            </div>
                                        }
                                    >
                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'help' }}>
                                            {params.value}
                                        </div>
                                    </Tooltip>
                                ),
                            },
                            ...tableHeader,
                        ].map((col) => ({
                            flex: 1,
                            hideable: false,
                            filterOperators: getGridStringOperators().filter(
                                (operator) => operator.value === 'contains',
                            ),
                            ...col,
                        })) as GridColDef[]
                    }
                    rows={data}
                    localeText={translation}
                    loading={loading}
                    pageSize={data.length}
                    hideFooterPagination
                    disableSelectionOnClick
                    components={{
                        ColumnMenu: CustomColumnMenu,
                    }}
                    hideFooterSelectedRowCount
                />
            </Grid>
            {!user.archive ? (
                <Grid item container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <SaveButton
                            setRefresh={setRefresh}
                            setModifications={setModifications}
                            modifications={modifications}
                            headerData={tableHeader}
                            listData={data}
                            opener={
                                <LoadingButton loading={false} variant="contained">
                                    Mentés
                                </LoadingButton>
                            }
                        />
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
        </Page>
    );
}
