import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IHoliday, ISemesterInfo } from '../../../../interface/ISemesterInfo';
import { printDateTime } from '../utils';
import { useFormikContext } from 'formik';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import Typography from '@mui/material/Typography';

/**
 * Ellenőrzés, hogy a dátum szünetre esik-e
 * @param holidays
 * @param checkDate
 */
function checkHoliday(holidays: IHoliday[], checkDate: Date): boolean {
    let collision = false;

    if (holidays.length > 0) {
        for (const holiday of holidays) {
            const holidayStart = moment(holiday.start).toDate();
            holidayStart.setHours(0, 0, 0, 0);
            const holidayEnd = moment(holiday.end).toDate();
            holidayEnd.setHours(23, 59, 59, 999);

            if (checkDate >= holidayStart && checkDate <= holidayEnd) {
                collision = true;
                break;
            }
        }
    }

    return collision;
}

export interface IRepeatInfoProps {
    numberOfRepetitions: number;
    eventStart?: string;
    eventEnd?: string;
    semesterInfo?: ISemesterInfo;
}

/**
 * Ismétlődés információ kiírása
 */
export default function RepeatInfo(props: IRepeatInfoProps): JSX.Element {
    const [eventsToPrint, setEventsToPrint] = useState<string[]>([]);
    const { errors } = useFormikContext<IForm>();

    const condition = props.numberOfRepetitions < 2 || !props.eventStart || !props.eventEnd;

    useEffect(() => {
        if (!condition) {
            setEventsToPrint(repeatInfoFunction(props as Required<IRepeatInfoProps>));
        }
    }, [condition, props]);

    if (condition) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            Létrehozandó események:
            {eventsToPrint.map(function (event: string, index: number): JSX.Element {
                return (
                    <div key={event}>
                        {event}
                        {errors['repetition.' + (index + 1)] ? (
                            <Typography color="error" variant="body2" component="span" style={{ marginLeft: 8 }}>
                                {errors['repetition.' + (index + 1)]}
                            </Typography>
                        ) : null}
                    </div>
                );
            })}
        </Grid>
    );
}

function repeatInfoFunction(props: Required<IRepeatInfoProps>): string[] {
    const { numberOfRepetitions, eventStart, eventEnd, semesterInfo } = props;

    const printEvents: string[] = [];
    const startDate = moment(eventStart).toDate();
    const endDate = moment(eventEnd).toDate();
    const semesterStart = moment(semesterInfo.start).toDate();
    const semesterEnd = moment(semesterInfo.end).toDate();
    const holidays = semesterInfo.holidays ?? [];

    const startDateIterator = new Date(+startDate);
    const endDateIterator = new Date(+endDate);
    let addedRepetitions = 0;
    const NUMBER_OF_BASE_EVENT = 1; // alap esemény száma, olvashatóság miatt

    const MAX_ITERATION = 1000; // MAX iterációk száma
    let iteration = 0;

    while (
        startDateIterator > semesterStart &&
        startDateIterator < semesterEnd &&
        addedRepetitions < numberOfRepetitions + NUMBER_OF_BASE_EVENT &&
        iteration++ < MAX_ITERATION // + védelem, hogy ne legyen végtelen ciklus
    ) {
        if (!checkHoliday(holidays, startDateIterator) && !checkHoliday(holidays, endDateIterator)) {
            printEvents.push(printDateTime(startDateIterator) + ' - ' + printDateTime(endDateIterator));
            addedRepetitions++;
        }

        startDateIterator.setDate(startDateIterator.getDate() + 7);
        endDateIterator.setDate(endDateIterator.getDate() + 7);
    }

    return printEvents;
}
