import { GridRenderCellParams } from '@mui/x-data-grid';
import { theme } from '../../../utils/theme';

export default function AnonymousCellRenderer(params: GridRenderCellParams) {
    return (
        <div style={{ color: params.row.isAnonymous ? 'inherit' : theme.palette.error.main }}>
            {params.row.isAnonymous ? 'Anonim' : 'Nem anonim'}
        </div>
    );
}
