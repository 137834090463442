import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
import { fixDateRangeFields } from '../../utils/AppConst';

const langExamCompletionReportService = {
    filter: (form: IForm) => API.get(endpoints.administration.langExamReport.completions, { params: form }),
    /** Nyelvvizsga riport export */
    initiateExport: (form: { format: string }, filter: IForm) =>
        API.post(endpoints.administration.langExamReport.completionsExport.initiate, form, {
            params: fixDateRangeFields(filter),
        }),
    checkExport: (id: number) =>
        API.get(endpoints.administration.langExamReport.completionsExport.check.replace('{id}', id.toString())),
    downloadExport: (id: number) =>
        API.get(endpoints.administration.langExamReport.completionsExport.download.replace('{id}', id.toString()), {
            responseType: 'arraybuffer',
        }),
};

export default langExamCompletionReportService;
