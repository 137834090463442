import Chip from '@mui/material/Chip';
import BasicTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
    getColorByEvaluationStatus,
    statusTranslations,
} from '../../../pages/MasterData/General/Students/StudentView/utils';
import { Paper } from '../Paper';
import NoDataDisplay from './NoDataDisplay';
import { ViewProps } from './types';
import { columns } from './utils';

const DesktopView = ({ loading, data }: ViewProps) => (
    <TableContainer component={Paper}>
        <BasicTable>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={3} align="center">
                        <Typography variant="h6">Kötelező kurzusok</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell key={column.field} align="center">
                            {column.headerName}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {loading || !data.length ? (
                    <NoDataDisplay loading={loading} />
                ) : (
                    data.map((row) => (
                        <TableRow key={row.code}>
                            <TableCell align="center">{row.code || '-'}</TableCell>
                            <TableCell>{row.name || '-'}</TableCell>
                            <TableCell align="center">
                                {row.inConnectCourses && row.inConnectCourses.length > 0 ? (
                                    <Tooltip title={row.inConnectCourses.join(', ')}>
                                        <Chip
                                            label={statusTranslations[row.status] || '-'}
                                            sx={{
                                                backgroundColor: getColorByEvaluationStatus(row.status) || 'inherit',
                                                color: 'white',
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Chip
                                        label={statusTranslations[row.status] || '-'}
                                        sx={{
                                            backgroundColor: getColorByEvaluationStatus(row.status) || 'inherit',
                                            color: 'white',
                                        }}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    ))
                )}
            </TableBody>
        </BasicTable>
    </TableContainer>
);

export default DesktopView;
