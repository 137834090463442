import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// eslint-disable-next-line regex/invalid-warn
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import Form from '../../../../../../../components/Form/Form';
import RequiredCourseCodeForm from './RequiredCourseCodeForm';
import trainingProgramRequirementCourseReferenceCodeService from '../../../../../../../services/masterData/trainingProgramRequirementCourseReferenceCode';
import { IForm } from '../../../../../../../utils/Interfaces/interfaces';
import { theme } from '../../../../../../../utils/theme';
import { Option } from '../../../../../../../utils/AppConst';

export interface IAddRequiredCourseCodeButtonForm extends IForm {
    courseReferenceCode: string | Option;
    coursePrerequisite: string | Option;
    courseName: string | Option;
}

const initialValues: IAddRequiredCourseCodeButtonForm = {
    courseReferenceCode: '',
    coursePrerequisite: '',
    courseName: '',
};

const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export default function AddRequiredCourseCodeButton({
    title,
    submitBtnTitle,
    opener,
    setTableLoading,
    id,
    programId,
    campusId,
}: {
    title: string;
    submitBtnTitle: string;
    opener: JSX.Element;
    id?: string;
    programId?: string;
    campusId?: string;
    setTableLoading: (val: boolean) => void;
}) {
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <div>
                    <DialogContent>
                        <Form
                            hideButtons
                            fields={[]}
                            onSubmit={(form: IAddRequiredCourseCodeButtonForm) => {
                                trainingProgramRequirementCourseReferenceCodeService
                                    .create(id, programId, campusId, form)
                                    .then(() => {
                                        setTableLoading(true);
                                        setTableLoading(false);
                                        handleClose();
                                    });
                            }}
                            initialValues={initialValues}
                        >
                            {(props) => (
                                <>
                                    <RequiredCourseCodeForm
                                        submitBtnTitle={submitBtnTitle}
                                        handleClose={handleClose}
                                        id={id}
                                        programId={programId}
                                        campusId={campusId}
                                        {...props}
                                    />
                                </>
                            )}
                        </Form>
                    </DialogContent>
                </div>
            </DialogBasic>
        </>
    );
}
