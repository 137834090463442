import Delete from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import questionnaireTemplateService from '../../../services/selfEvalution/questionnaireTemplate';
import { create } from '../../../store/notification';

export default function DeleteButton({ refresh, id }: { refresh: () => void; id: string }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = () => {
        setLoading(true);
        questionnaireTemplateService
            .delete(id)
            .then(() => {
                dispatch(create({ message: 'Sikeres törlés', type: 'success' }));
                setOpen(false);
                refresh();
            })
            .catch(() => dispatch(create({ message: 'Hiba a törlés során.', type: 'error' })))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Grid item>
            <TableButton tooltip="Törlés" color="error" onClick={() => setOpen(true)} sx={{ marginRight: '5px' }}>
                <Delete />
            </TableButton>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Törlés</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ marginTop: '4px' }}>
                        Biztosan törlöd a kijelölt elemet?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="outlined">
                        Mégse
                    </Button>
                    <LoadingButton loading={loading} onClick={handleDelete} variant="contained">
                        Igen
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
