import ActualStatusInfoDialog from './InfoDialogs/ActualStatusInfoDialog';
import CourseInfoDialog from './InfoDialogs/CourseInfoDialog';
import LanguageExamInfoDialog from './InfoDialogs/LanguageExamInfoDialog';
import RedirectButton from './InfoDialogs/RedirectButton';
import SemesterStatusInfoDialog from './InfoDialogs/SemesterStatusInfoDialog';
import { IProcessesRow } from './ProcessesTable';

export interface IChangesViewProps {
    row: IProcessesRow;
}

export default function ChangesView(props: IChangesViewProps): JSX.Element {
    const { row } = props;

    switch (row.type) {
        case 'change_actual_status':
            return (
                <ActualStatusInfoDialog
                    type={row.type}
                    actualStatus={row.requestData?.actualStatus}
                    requestedStatus={row.requestData?.requestedStatus}
                    reason={row.requestData?.reason}
                />
            );
        case 'change_semester_status':
            return (
                <SemesterStatusInfoDialog
                    type={row.type}
                    actualStatus={row.requestData?.actualStatus}
                    requestedStatus={row.requestData?.requestedStatus}
                    reason={row.requestData?.reason}
                    stayAbroadStart={row.requestData?.stayAbroadStart}
                    stayAbroadEnd={row.requestData?.stayAbroadEnd}
                    stayAbroadType={row.requestData?.stayAbroadType?.split(', ')}
                    hostCity={row.requestData?.hostCity}
                    hostCountry={row.requestData?.hostCountry}
                    hostMajor={row.requestData?.hostMajor}
                    hostName={row.requestData?.hostName}
                />
            );
        case 'course_dismissal':
            return <CourseInfoDialog objectId={row.id} isCentralData={row.isCentralData} />;
        case 'course_enrollment':
            return <CourseInfoDialog objectId={row.id} isCentralData={row.isCentralData} />;
        case 'language_exam_validation':
            return <LanguageExamInfoDialog objectId={row.id} />;
        case 'enrollment':
        case 'relocation':
        case 'student_allocation_preference':
        case 'termination':
        case 'move_out':
            return <RedirectButton />;
        default:
            return <></>;
    }
}
