import { Link, Page, Table, TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Visibility from '@mui/icons-material/Visibility';
import { fetchVersions } from './utils';
import { IVersion } from './types';
import pagesService from '../../../services/administration/pages';
import { create } from '../../../store/notification';
import MCCLoading from '../../../components/MCCLoading';
import { ValidityCell } from './utilElements';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

export default function Versions() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(true);
    const [versions, setVersions] = useState<IVersion[]>([]);
    const dispatch = useDispatch();
    const { pageId } = useParams() as { pageId: string };
    const tenant = useTenant();
    const columns = [
        {
            headerName: 'Hatályosság kezdete',
            field: 'startDate',
            valueGetter: (params: GridValueGetterParams) =>
                isNaN(moment(params?.row?.startDate).toDate().getTime())
                    ? '-'
                    : moment(params?.row?.startDate?.split('+')?.[0] || '')
                          .toDate()
                          .toLocaleString('hu-HU') || '-',
        },
        {
            headerName: 'Hatályosság vége',
            field: 'endDate',
            valueGetter: (params: GridValueGetterParams) =>
                !params?.row?.endDate
                    ? '-'
                    : moment(params?.row?.endDate?.split('+')?.[0] || '')
                          .toDate()
                          .toLocaleString('hu-HU') || '-',
        },
        {
            headerName: 'Érvényesség',
            field: 'validity',
            renderCell: ValidityCell,
            sortable: false,
            minWidth: 100,
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            sortable: false,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    {moment(params.row.startDate).toDate().getTime() >= new Date().getTime() ? (
                        <Link
                            to={clientEndPoints.admin_pages_versions_edit
                                .replace(':pageId', pageId.toString())
                                .replace(':versionId', params.id.toString())}
                        >
                            <TableEditButton tooltip={'Szerkesztés'} />
                        </Link>
                    ) : (
                        <Link
                            to={clientEndPoints.admin_pages_versions_show
                                .replace(':pageId', pageId.toString())
                                .replace(':versionId', params.id.toString())}
                        >
                            <TableButton color="info" style={{ marginRight: '5px' }} tooltip="Megtekintés">
                                <Visibility />
                            </TableButton>
                        </Link>
                    )}
                    {moment(params.row.startDate).toDate().getTime() >= new Date().getTime() &&
                        !params.row.endDate &&
                        !params.row.deletedAt && (
                            <TableDeleteButton
                                tooltip="Törlés"
                                deleteAction={() => handleDelete('' + params.id)}
                                style={{ marginLeft: '2px' }}
                            />
                        )}
                    {params.row.deletedAt && (
                        <TableButton
                            color={'info'}
                            style={{ marginRight: '5px' }}
                            onClick={() => handleRestore('' + params.id)}
                        >
                            <RestartAltIcon />
                        </TableButton>
                    )}
                </>
            ),
        },
    ];

    const table = {
        columns: columns.map((col) => ({ minWidth: 200, flex: 1, ...col })),
        rows: versions,
        loading: loading,
    };

    const addTable = {
        columns: columns.map((col) => ({ minWidth: 200, flex: 1, ...col })),
        rows: versions,
        loading: loading,
        newButton: {
            target: clientEndPoints.admin_pages_versions_create.replace(':pageId', pageId.toString()),
            text: 'Verzió hozzáadása',
        },
    };

    const header = {
        project: tenant || 'TAR',
        title: 'Oldalak',
        breadcrumbs: {
            administration: 'Adminisztráció',
            [clientEndPoints.admin_pages_list]: 'Oldalak',
            ...{ version: versions?.length > 0 ? versions[0].page.translations[null || 'hu'].title : '...' }, // TODO: Felhasználó nyelve szerint
        },
    };

    const initTable = useCallback(() => {
        setLoading(true);
        fetchVersions(pageId, setLoading, setVersions);
    }, [pageId]);

    useEffect(() => initTable(), [initTable]);

    const handleDelete = (versionId: string) => {
        setLoading(true);
        pagesService
            .deleteVersion(pageId, versionId)
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres törlés' }));
                initTable();
            })
            .catch(() => {
                dispatch(create({ type: 'error', message: 'Sikertelen törlés' }));
            })
            .finally(() => setLoading(false));
    };

    const handleRestore = (versionId: string) => {
        setLoading(true);
        pagesService
            .restoreVersion(pageId, versionId)
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres visszaállítás' }));
                initTable();
            })
            .catch(() => {
                setLoading(false);
                dispatch(create({ type: 'error', message: 'Sikertelen visszaállítás' }));
            });
    };

    if (loading) {
        return <MCCLoading />;
    }
    return <Page header={header}>{user.archive ? <Table {...table} /> : <Table {...addTable} />}</Page>;
}
