import { routingArr } from '../../routing/Routing';

export default function TestRoutes() {
    // FIXME: ezt le kell védeni!
    let allPaths = {};
    const routingA = routingArr();
    for (let i = 0; i < routingA.length; i++) {
        allPaths = touchPath(allPaths, routingA[i]);
    }

    const allKeys = Object.keys(allPaths);

    return (
        <>
            export const clientEndPoints = &#123;
            {allKeys.map((key) => {
                const path = allPaths[key];

                return (
                    <div key={key}>
                        {key}: &apos;{path}&apos;,
                    </div>
                );
            })}
            &#125;
        </>
    );
}

function touchPath(allPaths, arr, path, name) {
    if (arr.children) {
        for (let c = 0; c < arr.children.length; c++) {
            touchPath(allPaths, arr.children[c], (path ? path : '') + '/' + arr.children[c].path, arr.children[c].name);
        }
    }

    if (name) {
        if (allPaths[name]) {
            console.error('!!!!!!!!!!!! NÉV ÜTKÖZÉS: ', name);
        }
        allPaths[name] = path ? path : '/';
    }

    return allPaths;
}
