import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from '@silinfo/front-end-template';
import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NotificationBar from '../../../components/Notification';
import MCCLogo from '../../../images/mcc_logo.svg';
import ListItem from '../ListItem';
import Account from './Account';
import { LayoutContextProvider } from './LayoutContext';
import { mainListItems } from './listItems';
import Notification from './Notification';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        backgroundColor: theme.palette.secondary.main,
        position: 'relative',
        whiteSpace: 'nowrap',
        zIndex: 1250,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        [theme.breakpoints.up('sm')]: {
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(9),
            }),
        },
    },
}));

function DashboardContent() {
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const [open, setOpen] = React.useState(matches);
    const location = useLocation();

    React.useEffect(() => {
        setOpen(matches);
    }, [matches, location]);

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar color="default">
                <Grid item container justifyContent="space-between" spacing={2}>
                    <Grid item container alignItems="center" xs={6}>
                        <IconButton aria-label="open drawer" onClick={toggleDrawer}>
                            <MenuIcon color="secondary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Grid item display="flex" alignItems="center">
                            <Notification />
                        </Grid>
                        <Grid item>
                            <Account />
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
            <Drawer variant={matches ? 'permanent' : 'temporary'} open={open} onClose={() => setOpen(false)}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        px: [1],
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                    }}
                >
                    <Link to="/" style={{ width: open || !matches ? '100%' : 0 }}>
                        <img alt="logo" src={MCCLogo} width="100%" />
                    </Link>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}>
                        {open || !matches ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                </Toolbar>

                <Divider />
                <List component="nav">{React.Children.toArray(mainListItems.map(ListItem))}</List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                    <Toolbar></Toolbar>
                    <Outlet />
                    <NotificationBar />
                </Container>
            </Box>
        </Box>
    );
}

export default function Dashboard() {
    return (
        <LayoutContextProvider>
            <DashboardContent />
        </LayoutContextProvider>
    );
}
