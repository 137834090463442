import ArchiveIcon from '@mui/icons-material/Archive';
import Home from '@mui/icons-material/Home';
import SpatialAudioOff from '@mui/icons-material/SpatialAudioOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CancelButton, Link, SubmitButton, TableButton, TableEditButton } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import { useState, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import EndpointProvider from '../../../../components/EndpointProvider';
import trainingLevelsService from '../../../../services/masterData/trainingLevels';
import { create } from '../../../../store/notification';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import Visibility from '@mui/icons-material/Visibility';
import { TrainingProgram } from '../../../../utils/Interfaces/interfaces';

/*
 * A trainingLevel is archivable, if it has no trainingPrograms.
 * Or if all of its trainingPrograms are archived.
 */
const isArchivable = (trainingPrograms: TrainingProgram[]) => {
    if (trainingPrograms.length === 0) {
        return true;
    }
    return trainingPrograms.every((trainingProgram) => trainingProgram.archive);
};

export function BtnCellRenderer(
    params: GridRenderCellParams & { setRefresh: Dispatch<SetStateAction<boolean>>; readonly?: boolean },
) {
    const [open, setOpen] = useState(false);
    const onClick = () => {
        setOpen(true);
    };
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const archivable = isArchivable(params.row.trainingPrograms);

    return (
        <>
            {!params.readonly && (
                <EndpointProvider endpoints={['md_training_levels_edit']}>
                    <Link to={clientEndPoints.md_training_levels_edit.replace(':id', params.row.id.toString())}>
                        <TableEditButton tooltip={'Szerkesztés'} sx={{ marginRight: '5px' }} />
                    </Link>
                </EndpointProvider>
            )}

            {!params.row.archive && !params.readonly && (
                <>
                    <TableButton
                        color={'error'}
                        sx={{ marginRight: '5px' }}
                        tooltip={
                            archivable
                                ? 'Archiválás'
                                : 'A képzési szint archiválásához az összes kapcsolódó képzési programot szükséges archiválni.'
                        }
                        onClick={onClick}
                        disabled={!archivable}
                    >
                        <ArchiveIcon />
                    </TableButton>

                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Archiválás</DialogTitle>
                        <DialogContent style={{ paddingTop: '24px' }}>
                            <DialogContentText>
                                Biztosan archiválja a következő elemet a rendszerből: &quot;{params.row.name}
                                &quot;?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton onClick={handleClose}>Nem</CancelButton>
                            <SubmitButton
                                onClick={() => {
                                    trainingLevelsService
                                        .archive(params.row.id)
                                        .then(() =>
                                            dispatch(create({ type: 'success', message: 'Sikeres archiválás' })),
                                        )
                                        .catch((error: AxiosError) => {
                                            dispatch(
                                                create({
                                                    type: 'error',
                                                    message: error.response?.data.message || 'Sikertelen archiválás',
                                                }),
                                            );
                                        })
                                        .finally(() => {
                                            handleClose();
                                            params.setRefresh((prev) => !prev);
                                        });
                                }}
                            >
                                Igen
                            </SubmitButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}

            {params.readonly && (
                <Link to={clientEndPoints.md_training_levels_show.replace(':id', params.row.id.toString())}>
                    <TableButton sx={{ marginRight: '5px' }} tooltip={'Adatok megtekintése'}>
                        <Visibility />
                    </TableButton>
                </Link>
            )}

            <EndpointProvider endpoints={['md_training_levels_programs_list']}>
                <Link to={clientEndPoints.md_training_levels_programs_list.replace(':id', params.row.id.toString())}>
                    <TableButton tooltip={'Képzési programok'} sx={{ marginRight: '5px' }}>
                        <Home />
                    </TableButton>
                </Link>
            </EndpointProvider>

            <Link
                to={clientEndPoints.md_training_levels_language_exam_requirements_list.replace(
                    ':trainingLevelId',
                    params.row.id.toString(),
                )}
            >
                <TableButton tooltip={'Nyelvvizsga követelmények'} sx={{ marginRight: '5px' }}>
                    <SpatialAudioOff />
                </TableButton>
            </Link>
        </>
    );
}
