import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const NoDataRow = ({ loading }: { loading: boolean }) => {
    const text = loading ? 'Betöltés...' : 'Nincs megjeleníthető adat';

    return (
        <TableRow>
            <TableCell align="center" colSpan={5}>
                <i>{text}</i>
            </TableCell>
        </TableRow>
    );
};

export default NoDataRow;
