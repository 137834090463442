import { GridColDef } from '@mui/x-data-grid';
import { Input, Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import OperationsCellRenderer from './OperationsCellRenderer';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IAdmissionDocument, IAdmissionPointTablePoint } from './types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { Link } from '@silinfo/front-end-template';

export const breadcrumbs = {
    admission: 'Felvételi',
    administration: 'Adminisztráció',
};

export const header = (tenant: string | null) => {
    return {
        title: 'Felvételi',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

const STATUS_OPTIONS: Option[] = [
    { value: 'inactive', label: 'Nincs aktiválva' },
    { value: 'active', label: 'Aktiválva' },
    { value: 'closed', label: 'Lezárva' },
];

export const columns = (semester: string | number, refreshing: () => void, archive?: boolean): GridColDef[] => {
    const cols: GridColDef[] = [
        {
            field: 'admissionGroup',
            headerName: 'Csoport neve',
            sortable: true,
        },
        {
            field: 'questionnaireTemplate',
            headerName: 'Felvételi űrlap',
            sortable: true,
        },
        {
            field: 'applicationDeadline',
            headerName: 'Jelentkezési határidő',
            sortable: true,
        },
        {
            field: 'closingDeadline',
            headerName: 'Zárási határidő',
            sortable: true,
        },
        {
            field: 'status',
            headerName: 'Státusz',
            sortable: true,
        },
        ...(!archive
            ? [
                  {
                      field: 'operations',
                      headerName: 'Műveletek',
                      renderCell: (params: GridRenderCellParams) => (
                          <OperationsCellRenderer semester={semester} refreshing={refreshing} {...params} />
                      ),
                      sortable: false,
                  },
              ]
            : []),
    ];

    return cols.map((column) => ({
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        sortable: false,
        ...column,
    }));
};

export const filterFields = (): Input[] => {
    return [
        {
            name: 'admissionGroup',
            label: 'Csoport neve',
            type: 'text',
            format: {
                xs: 12,
                md: 6,
            },
        },
        {
            name: 'status',
            label: 'Csoport státusza',
            type: 'multiselect',
            options: STATUS_OPTIONS,
            format: {
                xs: 12,
                md: 6,
            },
        },
    ];
};

const autoload = true as const;

export const tableProps = (
    refresh: boolean,
    semester: string | number,
    refreshing: () => void,
    archive?: boolean,
    tenant?: string,
) => ({
    autoload,
    header: header(tenant || 'TAR'),
    columns: columns(semester, refreshing, archive),
    filter: { fields: filterFields() },
    refresh: refresh,
    defaultSort: { trainingProgram: 'asc' as const },
    responseKey: 'data',
    customButtons: (
        <>
            {!archive && (
                <Grid item xs={12} textAlign="right">
                    <Link
                        to={clientEndPoints.admission_administration_new_group.replace(
                            ':semesterId',
                            semester.toString(),
                        )}
                    >
                        <Button variant="outlined" color="success" startIcon={<AddIcon />}>
                            Új csoport létrehozása
                        </Button>
                    </Link>
                </Grid>
            )}
        </>
    ),
});

export const initialValues: Record<string, unknown> = {
    id: 0,
    questionnaireTemplate: '',
    applicationDeadline: '',
    closingDeadline: '',
    informationDocuments: [],
    uploadableDocuments: [],
};

export const initialDocument = (id: number, admissionId: number): IAdmissionDocument => ({
    id: id,
    admissionId: admissionId,
    name: '',
    rule: 'required',
    origFileName: '',
    uuidFileName: '',
    filePath: '',
    deadline: '',
});

export const initialPartialPoint = (id: number, admissionId: number): IAdmissionPointTablePoint => ({
    id: id,
    admissionId: admissionId,
    name: '',
    point: '0',
});

export function downloadFile(data: Blob, filename = 'File') {
    const blobURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}
