import Grid from '@mui/material/Grid';
import { useState, useCallback } from 'react';
import instance from '../../api';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

interface RefreshButtonWithTextProps {
    toggleRefresh: () => void;
    endpoint: string;
    buttonString: string;
    method?: 'post' | 'get';
    iconColor?: string | null;
}

export default function RefreshButtonWithText({
    endpoint,
    toggleRefresh,
    method,
    iconColor,
    buttonString,
}: RefreshButtonWithTextProps) {
    const [loading, setLoading] = useState(false);

    const handleClick = useCallback(() => {
        setLoading(true);
        instance[method || 'post'](endpoint, {})
            .then(toggleRefresh)
            .finally(() => setLoading(false));
    }, [endpoint, toggleRefresh, method]);

    return (
        <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
                sx={{
                    color: iconColor,
                    border: '1px solid',
                    borderColor: iconColor,
                }}
                disabled={loading}
                onClick={handleClick}
            >
                <RefreshIcon sx={{ marginRight: '5px' }} />
                {buttonString}
            </Button>
        </Grid>
    );
}
