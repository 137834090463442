import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { clientEndPoints } from '../../../utils/clientEndPoints';

export const SESSION_KEY = 'financialApprovals';
const categoryTranslation = {
    inpayment: 'Befizetés',
    payment: 'Kifizetés',
};

export type FinancialApprovalsView = 'base' | 'guest';

export interface TabContentProps {
    view: FinancialApprovalsView;
}

export const header = (tenant: string | null) => {
    return {
        project: tenant || 'TAR',
        title: 'Pénzügyi jóváhagyások',
        breadcrumbs: {
            finance: 'Pénzügyek',
            [clientEndPoints.finance_financial_approvals_list]: 'Pénzügyi jóváhagyások',
        },
    };
};

export const getStatusEndpoint = (status: string): string => {
    switch (status) {
        case 'wait_to_un_admin_approval':
            return 'finance_financial_approvals_automatic_approval';
        case 'wait_to_leader_approval':
            return 'finance_financial_approvals_professional_approval';
        case 'invoice_during_issue':
            return 'finance_financial_approvals_financial_approval';
        case 'waiting_for_payment':
        case 'waiting_for_payment_out':
            return 'finance_financial_approvals_status_edit';
        default:
            // Mindig disabled lesz
            return 'error';
    }
};

const categoryColumn = {
    headerName: '',
    field: 'category',
    renderCell: (params: GridRenderCellParams<keyof typeof categoryTranslation>) => (
        <Tooltip title={categoryTranslation[params.value || 'payment']}>
            {params.value === 'inpayment' ? (
                <KeyboardArrowRight
                    sx={{ backgroundColor: 'success.main', color: 'white', borderRadius: '50%' }}
                    className="borderRadius"
                />
            ) : (
                <KeyboardArrowLeft
                    sx={{ backgroundColor: 'error.main', color: 'white', borderRadius: '50%' }}
                    className="borderRadius"
                />
            )}
        </Tooltip>
    ),
    minWidth: 50,
    width: 50,
    flex: 0,
};

export { categoryColumn };
