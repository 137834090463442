import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ICourseView } from '../../pages/CourseManagement/Courses/types';
import { scoringTypeLabel } from '../../utils/AppConst';
import { Paper } from '../Requirements/Paper';

export default function Criteria(props: ICourseView) {
    const minimumAchievablePoint = props.point?.minimumPointToBeAchieved;
    const maximumAchievablePoint = props.point?.maximumAchievablePoint;

    const customPointsCondition =
        (props.point?.customCoursePoint || props.point?.customCoursePointPerEvent) &&
        minimumAchievablePoint !== null &&
        minimumAchievablePoint !== maximumAchievablePoint;

    return (
        <Grid item xs={12} md={8}>
            <Paper>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Kritériumok, pontozás</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper>
                            <Typography>
                                Kurzus értéke:{' '}
                                <strong>
                                    {customPointsCondition
                                        ? `${minimumAchievablePoint}-${maximumAchievablePoint}`
                                        : maximumAchievablePoint}
                                </strong>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper>
                            <Typography>
                                Pontozás típusa: <strong>{scoringTypeLabel(props.scoringType)}</strong>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper>
                            <Typography>
                                Maximum hiányzás: <strong>{props.maxAbsence}</strong>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <Typography variant="h6">Értékelés módja</Typography>
                            {props.point?.fixCoursePoint && (
                                <Typography>
                                    Fix kurzuspont: <b>{props.point?.fixCoursePointVal}</b>
                                </Typography>
                            )}
                            {props.point?.customCoursePoint && (
                                <Typography>
                                    Egyéni elbírálású pont:{' '}
                                    <b>
                                        {props.point?.customCoursePointValMin}-{props.point?.customCoursePointValMax}
                                    </b>
                                </Typography>
                            )}
                            {props.point?.fixedCoursePointPerEvent && (
                                <>
                                    <Typography>
                                        Eseményenként járó pont{' '}
                                        <b>{props.point?.fixedCoursePointPerEventValPoint ?? 0}</b>
                                    </Typography>
                                    <Typography>
                                        Min. eseményen történő részvétel:{' '}
                                        <b>{props.point.fixedCoursePointPerEventValMin}</b>
                                    </Typography>
                                </>
                            )}
                            {props.point?.customCoursePointPerEvent && (
                                <Typography>
                                    Eseményenként egyéni elbírálású pont:{' '}
                                    <b>
                                        {(() => {
                                            let maxPoint = 0,
                                                minPoint = 0;
                                            for (let i = 0; i < props.events.length; i++) {
                                                minPoint += props.events[i]?.pointMin ?? 0;
                                                maxPoint += props.events[i]?.pointMax ?? 0;
                                            }
                                            return minPoint === maxPoint ? minPoint : minPoint + ' - ' + maxPoint;
                                        })()}{' '}
                                        pont összesen {props.events.length} eseménynél
                                    </b>
                                </Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
