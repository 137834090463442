import SchoolIcon from '@mui/icons-material/School';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Loading } from '@silinfo/front-end-template';
import { useState } from 'react';
import { Option } from '../../../../../utils/AppConst';
import { endpoints } from '../../../../../utils/endPoints';
import useFetch from '../../../../../utils/useFetch';
import { ICompletedTrainingProgram } from '../types';
import CompletedTrainingProgramDialog from './CompletedTrainingProgramDialog';

const Content = ({ item }: { item: Option }) => {
    const { loading, data } = useFetch(endpoints.students.profile.studies.previousStudies + '/' + item.value, '') as {
        loading: boolean;
        data: ICompletedTrainingProgram;
    };

    return (
        <DialogContent style={{ marginTop: '16px' }}>
            {loading ? <Loading noHeight /> : <CompletedTrainingProgramDialog {...data} />}
        </DialogContent>
    );
};

export default function PreviousStudiesBadge({ label, value }: Option) {
    const [open, setOpen] = useState(false);
    const Opener = () => (
        <Chip
            color="secondary"
            sx={{ padding: '12px' }}
            icon={<SchoolIcon />}
            label={label}
            onClick={() => setOpen(true)}
        />
    );

    return (
        <Grid item>
            <Opener />
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
                <DialogTitle>
                    <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h5" color="white">
                                {label}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <SchoolIcon />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Content item={{ label, value }} />
            </Dialog>
        </Grid>
    );
}
