import { useState, useEffect, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { ThemeProvider } from '@mui/material/styles';
import { GridColumns } from '@mui/x-data-grid';
import { FormikFilter, Header, Page, TextField, useBoolean, useFetch } from '@silinfo/front-end-template';
import { MuiTable } from '@silinfo/front-end-template/lib/esm/components/Table';
import { Option, defaultTheme } from '../../../utils/AppConst';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { endpoints } from '../../../utils/endPoints';
import SemesterCell from './SemesterCell';
import {
    ListPageContext,
    breadcrumbs,
    defaultFilter,
    filterFields,
    header,
    listPageProps,
    operationsColumn,
    requirementColumns,
    studentColumn,
} from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

interface ListPageProps {
    trainingProgram: Option;
    campus: Option;
}

const ListPage = ({ trainingProgram, campus }: ListPageProps) => {
    const [filter, setFilter] = useState<IForm>(defaultFilter);
    const { user } = useSelector((state: RootState) => state.auth);
    const tenant = useTenant();
    const listPagePropsWithTenant = listPageProps(tenant || 'TAR');
    const { value: refresh, toggle } = useBoolean();
    const { data: semesters, loading: semesterLoading } = useFetch<Option[]>(
        endpoints.administration.courseClosing.semesters(trainingProgram.value, campus.value),
        '',
    );
    const { data: students, loading: studentLoading } = useFetch<IForm[]>(
        endpoints.administration.courseClosing.students(trainingProgram.value, campus.value),
        'data',
        refresh,
    );

    const filteredData = useMemo(
        () =>
            students.filter((student) =>
                ('' + student.student).toLowerCase().includes(((filter.student || '') + '').toLowerCase()),
            ),
        [students, filter],
    );

    useEffect(() => {
        setFilter(defaultFilter); // Reset filterFields when trainingProgram or campus changes
    }, [trainingProgram, campus]);

    if (semesterLoading || studentLoading) {
        return <Skeleton height="50px" />;
    }

    const semesterColumns: GridColumns = semesters.map((semester) => {
        return {
            field: semester.value,
            headerName: semester.label,
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: SemesterCell,
            valueGetter: (params) => params.row.semesters[params.field],
            sortComparator: (v1: string, v2: string) => {
                const order = ['success', 'in_progress', 'failed', null];
                const v1Index = order.indexOf(v1);
                const v2Index = order.indexOf(v2);

                if (v1Index === -1) {
                    return 1; // Move null or unknown values to the end
                }
                if (v2Index === -1) {
                    return -1; // Move null or unknown values to the end
                }

                return v1Index - v2Index;
            },
        };
    });

    const columns = [
        studentColumn,
        ...semesterColumns,
        ...requirementColumns,
        ...(user.archive ? [] : [operationsColumn(trainingProgram.label)]),
    ];

    return (
        <Page header={header(tenant || 'TAR')}>
            <FormikFilter fields={filterFields} onSubmit={setFilter} />
            <Grid item xs={12}>
                <ListPageContext.Provider value={{ programId: trainingProgram.value, refresh: toggle }}>
                    <MuiTable
                        rows={filteredData}
                        columns={columns}
                        disableSelectionOnClick
                        pagination
                        {...listPagePropsWithTenant.table}
                    />
                </ListPageContext.Provider>
            </Grid>
        </Page>
    );
};

export default function CourseClosing() {
    const [trainingProgram, setTrainingProgram] = useState<Option | null>(null);
    const [campus, setCampus] = useState<Option | null>(null);
    const tenant = useTenant();
    const { data: trainingPrograms, loading: trainingProgramLoading } = useFetch<Option[]>(
        endpoints.administration.courseClosing.programs,
        '',
    );
    const { data: campuses, loading: campusLoading } = useFetch<Option[]>(
        endpoints.administration.courseClosing.campuses,
        '',
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...header(tenant || 'TAR'), breadcrumbs }} />
            </Grid>
            <Grid item xs={12}>
                {trainingProgramLoading ? (
                    <Skeleton height="50px" />
                ) : (
                    <Autocomplete
                        options={trainingPrograms}
                        value={trainingProgram}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => setTrainingProgram(value)}
                        getOptionLabel={(option) => option.label}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Képzési program" />}
                        renderOption={(props: object, option: Option) => {
                            const { label } = option;
                            if (label.includes('(archív)')) {
                                return (
                                    <li {...props} style={{ color: '#999' }}>
                                        {option.label}
                                    </li>
                                );
                            } else {
                                return <li {...props}>{option.label}</li>;
                            }
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {campusLoading ? (
                    <Skeleton height="50px" />
                ) : (
                    <Autocomplete
                        options={campuses}
                        value={campus}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => setCampus(value)}
                        getOptionLabel={(option) => option.label}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Campus" />}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {trainingProgram && campus ? (
                    <ListPage trainingProgram={trainingProgram} campus={campus} />
                ) : (
                    <ThemeProvider theme={defaultTheme}>
                        <Alert severity="info">
                            Kérjük, válassz képzési programot és campust az adatok megtekintéséhez
                        </Alert>
                    </ThemeProvider>
                )}
            </Grid>
        </Grid>
    );
}
