import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';

const emailLogsService = {
    ...crudTemplate<never>(endpoints.administration.emailLogs.main),
    getEmailLogTypes: () => API.get(endpoints.administration.emailLogs.types),
};

export default emailLogsService;
