import AdministrationFees from '../../../pages/MasterData/Finance/AdministrationFees';
import AdministrationFeeForm from '../../../pages/MasterData/Finance/AdministrationFees/Form';
import { administrationFeesSemesters } from './AdministrationFeesSemesters';

export const administrationFees = {
    path: 'administration-fees',
    children: [
        {
            path: '',
            element: <AdministrationFees />,
            name: 'md_finance_administration_fees_list',
        },
        {
            path: 'new',
            element: <AdministrationFeeForm />,
            name: 'md_finance_administration_fees_new',
        },
        {
            path: ':id/edit',
            element: <AdministrationFeeForm />,
            name: 'md_finance_administration_fees_edit',
        },
        administrationFeesSemesters,
    ],
};
