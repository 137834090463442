import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
const Row = ({
    Icon,
    label,
    value,
}: {
    Icon: OverridableComponent<SvgIconTypeMap<Record<string, string>, 'svg'>> & { muiName: string };
    label: string;
    value: string;
}) => (
    <Grid item container xs={12} style={{ borderBottom: '1px solid #ddd', padding: '8px 4px' }} alignItems="center">
        <Grid item container xs={6} spacing={1} alignItems="center">
            <Grid item display="flex" alignItems="center">
                <Icon color="secondary" />
            </Grid>
            <Grid item display="flex" alignItems="center">
                <Typography variant="h6" color="secondary" component="span">
                    {label}:
                </Typography>
            </Grid>
        </Grid>
        <Grid item>
            <Typography component="span" variant="h6" color="secondary">
                <strong>{value}</strong>
            </Typography>
        </Grid>
    </Grid>
);

export default Row;
