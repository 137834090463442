import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import Grid from '@mui/material/Grid';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import DropdownChip from '../../../components/DropdownChip';
import { TStatus } from '../../../pages/MasterData/General/Students/StudentView/types';
import { ViewProps } from './types';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

const IconMapper: Record<TStatus, OverridableComponent<SvgIconTypeMap>> = {
    success: CheckIcon,
    failed: ErrorIcon,
    in_progress: PendingIcon,
};

const StatusMapper: Record<TStatus, 'success' | 'error' | 'warning'> = {
    success: 'success',
    failed: 'error',
    in_progress: 'warning',
};

export default function MobileView({ loading, data }: ViewProps) {
    return (
        <Grid item container xs={12} spacing={2} alignSelf="flex-start">
            <Grid item xs={12}>
                <Typography variant="h6" textAlign="center">
                    Kötelező kurzusok
                </Typography>
            </Grid>
            <Grid item container xs={12} spacing={1} alignSelf="flex-start" justifyContent="center">
                {loading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : (
                    data.map((row) => {
                        const Icon = IconMapper[row.status];
                        return (
                            <Grid item key={row.code} textAlign="center">
                                <DropdownChip
                                    label={row.code}
                                    color={StatusMapper[row.status]}
                                    sx={{ color: '#fff' }}
                                    icon={<Icon style={{ color: 'white' }} />}
                                >
                                    <Typography variant="body2">{row.name || '-'}</Typography>
                                </DropdownChip>
                            </Grid>
                        );
                    })
                )}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
}
