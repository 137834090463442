import Edit from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { FormProps } from '../../components/Form/Form';
import { theme } from '../../utils/theme';
import EditDialog from './EditDialog';

export default function EditForm<T extends Record<string, unknown>, D extends object>(props: FormProps<T, D>) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Grid item xs={12} textAlign="right">
                <IconButton
                    edge="start"
                    sx={{
                        backgroundColor: theme.palette.secondary.main,
                        ':hover': { backgroundColor: theme.palette.secondary.dark },
                    }}
                    onClick={() => setOpen(true)}
                >
                    <Edit color="primary" />
                </IconButton>
                <EditDialog {...props} openState={[open, setOpen]} />
            </Grid>
        </>
    );
}
