import ActualStatusDialog from './RequestFormDialog/ApprovalActualStatusDialog';
import SemesterStatusDialog from './RequestFormDialog/ApprovalSemesterStatusDialog';
import ApprovalCourseInfoStatusDialog from './RequestFormDialog/ApprovalCourseInfoStatusDialog';
import { IStudentRequestData } from '../../../utils/Interfaces/IStudentRequestApproval';
import { ApprovalTableRow } from './Approvals';

export type ChangesViewRow = {
    id: number;
    student: string;
    type: string;
    typeOrig: string;
    requestData?: IStudentRequestData;
};

export interface IChangesViewProps {
    row: ApprovalTableRow;
}

export default function ChangesView(props: IChangesViewProps): JSX.Element {
    const { row } = props;

    switch (row.typeOrig) {
        case 'change_actual_status':
            return (
                <ActualStatusDialog
                    student={row.student}
                    type={row.type}
                    actualStatus={row.requestData?.actualStatus}
                    requestedStatus={row.requestData?.requestedStatus}
                    reason={row.requestData?.reason}
                />
            );
        case 'change_semester_status':
            return (
                <SemesterStatusDialog
                    student={row.student}
                    type={row.type}
                    actualStatus={row.requestData?.actualStatus}
                    requestedStatus={row.requestData?.requestedStatus}
                    reason={row.requestData?.reason}
                    stayAbroadStart={row.requestData?.stayAbroadStart}
                    stayAbroadEnd={row.requestData?.stayAbroadEnd}
                    stayAbroadType={row.requestData?.stayAbroadType}
                    hostCity={row.requestData?.hostCity}
                    hostCountry={row.requestData?.hostCountry}
                    hostMajor={row.requestData?.hostMajor}
                    hostName={row.requestData?.hostName}
                />
            );
        case 'course_dismissal':
            return <ApprovalCourseInfoStatusDialog objectId={row.id} />;
        case 'course_enrollment':
            return <ApprovalCourseInfoStatusDialog objectId={row.id} />;
        default:
            return <></>;
    }
}
