import { Input } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';

export const header = (tenant: string | null) => {
    return {
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            'trainingLevels': 'Képzési szintek',
        },
        title: 'Képzési szintek',
        project: tenant || 'TAR',
    };
};

export const fields: Input[] = [
    {
        name: 'name',
        label: 'Képzési szint',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'archive',
        label: 'Archiválva?',
        type: 'multiselect',
        options: [
            { value: '1', label: 'Igen' },
            { value: '0', label: 'Nem' },
        ],
        format: { xs: 12, md: 4, lg: 4 },
    },
];
