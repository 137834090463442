import instance from '../../api';
import { fixDateRangeFields } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import { IForm } from '../../utils/Interfaces/interfaces';

const scholarshipReportService = (semesterId: string | number) => ({
    /** -- Riport export -- */
    initiateExport: (form: { format: string }, filter: IForm) =>
        instance.post(
            endpoints.scholarships.report.export.initiate.replace('{semesterId}', semesterId.toString()),
            form,
            {
                params: fixDateRangeFields(filter),
            },
        ),
    checkExport: (id: number) =>
        instance.get(
            endpoints.scholarships.report.export.check
                .replace('{semesterId}', semesterId.toString())
                .replace('{id}', id.toString()),
        ),
    downloadExport: (id: number) =>
        instance.get(
            endpoints.scholarships.report.export.download
                .replace('{semesterId}', semesterId.toString())
                .replace('{id}', id.toString()),
            {
                responseType: 'arraybuffer',
            },
        ),
});

export default scholarshipReportService;
