import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Option } from '@silinfo/front-end-template';
import { endpoints } from '../../../utils/endPoints';
import API from '../../../api';
import { create } from '../../../store/notification';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../../../utils/AppConst';

type SemesterOption = Option<number> & { active?: boolean };

interface SettingsModalProps {
    open: boolean;
    onClose: () => void;
    semester: SemesterOption | null;
}

interface CheckboxData {
    id: number;
    type: 'base' | 'detailed';
    name: string;
    label: string;
    disabled?: boolean;
    selected?: boolean;
}

const CheckboxWithTypography: React.FC<
    CheckboxData & { setData: React.Dispatch<React.SetStateAction<CheckboxData[]>> }
> = ({ id, label, disabled = false, selected = false, setData }) => {
    const [checked, setChecked] = useState(selected);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
            setChecked(event.target.checked);

            setData((prevData) =>
                prevData.map((field) => (field.id === id ? { ...field, selected: event.target.checked } : field)),
            );
        }
    };

    const handleLabelClick = () => {
        if (!disabled) {
            setChecked(!checked);

            setData((prevData) =>
                prevData.map((field) => (field.id === id ? { ...field, selected: !checked } : field)),
            );
        }
    };

    return (
        <Grid container alignItems="center" style={{ margin: '10px' }}>
            <Grid item xs={9}>
                <Typography
                    variant="body2"
                    style={{ cursor: disabled ? 'not-allowed' : 'pointer', userSelect: 'none' }}
                    onClick={handleLabelClick}
                >
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Checkbox checked={checked} onChange={handleCheckboxChange} disabled={disabled} />
            </Grid>
        </Grid>
    );
};

const SettingsModal: React.FC<SettingsModalProps> = ({ open, onClose, semester }) => {
    const [initialData, setInitialData] = useState<CheckboxData[]>([]);
    const [data, setData] = useState<CheckboxData[]>([]);
    const dispatch = useDispatch();

    {
        data.map((field) =>
            field.type === 'base' ? (
                <CheckboxWithTypography
                    id={field.id}
                    key={field.name}
                    type={field.type}
                    name={field.name}
                    label={field.label}
                    disabled={field.disabled}
                    selected={field.selected}
                    setData={setData}
                />
            ) : null,
        );
    }

    useEffect(() => {
        if (semester?.value) {
            const endpointUrl = endpoints.admission.fields.replace('{semesterId}', semester?.value.toString());
            API.get<CheckboxData[]>(endpointUrl)
                .then((response) => {
                    setInitialData(response.data);
                    setData(response.data);
                })
                .catch((error) => {
                    console.error('Axios Error:', error);
                });
        }
    }, [semester]);

    useEffect(() => {
        if (!open) {
            setData(initialData);
        }
    }, [open, initialData]);

    if (!semester) {
        return null;
    }

    const handleSave = () => {
        const selectedFieldIds = data.filter((field) => field.selected).map((field) => field.id);

        const requestData = {
            selectedFieldIds: selectedFieldIds,
        };

        const endpointUrl = endpoints.admission.fields.replace('{semesterId}', semester?.value.toString());
        API.post(endpointUrl, requestData)
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
                API.get<CheckboxData[]>(endpointUrl)
                    .then((response) => {
                        setData(response.data);
                        setInitialData(response.data);
                    })
                    .catch((error) => {
                        console.error('Axios Error:', error);
                    });
                onClose();
            })
            .catch((error) => {
                console.error('Error while saving data:', error);
            });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{`Általános felvételi adatok beállítása - ${semester.label}`}</DialogTitle>
            <div style={{ marginLeft: '1.5rem', marginRight: '1.5rem', marginTop: '0.5rem' }}>
                <ThemeProvider theme={defaultTheme}>
                    <Alert severity="info">
                        A kiválasztott adatok jelennek meg a felvételi felületen a jelentkezőknél. A kiválasztott adatok
                        megadása kötelező.
                    </Alert>
                </ThemeProvider>
            </div>
            <DialogContent style={{ paddingTop: '12px', paddingBottom: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2} direction="column">
                            <Grid item style={{ marginBottom: '10px' }}>
                                <strong>Alapadatok</strong>
                            </Grid>
                            {data.map((field) =>
                                field.type === 'base' ? (
                                    <CheckboxWithTypography
                                        id={field.id}
                                        key={field.name}
                                        type={field.type}
                                        name={field.name}
                                        label={field.label}
                                        disabled={field.disabled}
                                        selected={field.selected}
                                        setData={setData}
                                    />
                                ) : null,
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2} direction="column">
                            <Grid item style={{ marginBottom: '10px' }}>
                                <strong>Bővített alapadatok</strong>
                            </Grid>
                            {data.map((field) =>
                                field.type === 'detailed' ? (
                                    <CheckboxWithTypography
                                        id={field.id}
                                        key={field.name}
                                        type={field.type}
                                        name={field.name}
                                        label={field.label}
                                        disabled={field.disabled}
                                        selected={field.selected}
                                        setData={setData}
                                    />
                                ) : null,
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <Grid container justifyContent="flex-end" style={{ padding: '12px' }}>
                <Button
                    onClick={handleSave} // Call the handleSave function on click
                    color="primary"
                    variant="contained"
                    style={{ marginRight: '10px', marginTop: '10px' }}
                >
                    Mentés
                </Button>
                <Button
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    style={{ marginRight: '10px', marginTop: '10px' }}
                >
                    Mégse
                </Button>
            </Grid>
        </Dialog>
    );
};

export default SettingsModal;
