import Delete from '@mui/icons-material/Delete';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Dialog, SubmitButton, TableButton } from '@silinfo/front-end-template';
import courseDocumentService from '../../../../services/courseManagement/courseDocument';
import { IDocument } from './types';
import { useParams } from 'react-router-dom';

export default function DeleteDocument({
    documentToDelete,
    setLoading,
    courseTenant,
}: {
    documentToDelete: IDocument;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    courseTenant: string | null;
}) {
    const { courseId } = useParams() as { courseId: string };
    const handleDelete = () => {
        courseDocumentService
            .delete(+courseId, documentToDelete.id, courseTenant)
            .then(() => {
                setLoading((prev) => !prev);
            })
            .catch(() => {
                // FIXME: típuskezelés
                alert('sikertelen törlés');
            });
    };

    // FIXME: ez a dialog a base-ből kellene, hogy jöjjön
    return (
        <Dialog
            title="Dokumentum törlése"
            opener={
                <TableButton color="error" tooltip="Dokumentum törlése">
                    <Delete />
                </TableButton>
            }
            action={<SubmitButton onClick={handleDelete}>Igen</SubmitButton>}
            cancelText="Mégsem"
        >
            <DialogContent>
                <DialogContentText>Biztosan törli a(z) {documentToDelete.name} nevű dokumentumot?</DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
