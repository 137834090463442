import { GridAlignment, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import BtnCellRenderer from './BtnCellRenderer';

export const tableFields = (refreshing: () => void, archive?: boolean) => {
    const commonFields = [
        { headerName: 'Token', field: 'token', minWidth: 150 },
        { headerName: 'Funkciók', field: 'functions', minWidth: 150 },
        { headerName: 'Megjegyzés', field: 'comment', minWidth: 150 },
    ].map((e) => ({ headerAlign: 'center', flex: 1, ...e })) as GridColDef[];

    if (!archive) {
        commonFields.push({
            field: 'operations',
            headerName: 'Műveletek',
            renderCell: (params: GridRenderCellParams) => <BtnCellRenderer refreshing={refreshing} {...params} />,
            align: 'center' as GridAlignment,
            headerAlign: 'center' as GridAlignment,
            flex: 1,
            minWidth: 110,
        });
    }

    return commonFields;
};
