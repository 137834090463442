import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { Document, MobileDocumentDialogProps } from '../types';
import AdmissionDateExpiredIcon from '../Icons/AdmissionDateExpiredIcon.svg';
import AdmissionCompletedIcon from '../Icons/AdmissionCompletedIcon.svg';
import AdmissionWarningIcon from '../Icons/AdmissionWarningIcon.svg';
import NotCompleted from '../Icons/NotCompleted.svg';
import { useEffect, useState } from 'react';
import { endpoints } from '../../../../utils/endPoints';
import API from '../../../../api';
import { create } from '../../../../store/notification';
import { downloadFile } from '../../Administration/utils';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { convertDate } from '../utils';
import FileUpload from './FileUpload';
import AdmissionsStyle from '../Admissions.module.scss';
import AdmissionsDialogStyle from './AdmissionsDialogs.module.scss';
import Alert from '@mui/material/Alert';
import { defaultTheme } from '../../../../utils/AppConst';
import { ThemeProvider } from '@mui/styles';
import axios from 'axios';

export default function MobileDocumentDialog({
    openState,
    isRegistrated,
    token,
    serialState,
    disabledState,
    refresh,
}: MobileDocumentDialogProps) {
    const [open, setOpen] = openState;
    const [uploadableDocuments, setUploadableDocuments] = useState<Document[]>([]);
    const [informationDocuments, setInformationDocuments] = useState<Document[]>([]);
    const [types, setTypes] = useState<string>('');
    const [maxSize, setMaxSize] = useState<string>('');
    const [currentSerial, setCurrentSerial] = serialState;
    const [admissionDisabled, setAdmissionDisabled] = disabledState;
    const handleClose = () => {
        refresh();
        setOpen(false);
        setCurrentSerial(null);
        setUploadableDocuments([]);
        setInformationDocuments([]);
        setAdmissionDisabled(false);
    };
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const getStatusIcon = (document: Document) => {
        if (document.fileName) {
            return <img src={AdmissionCompletedIcon} />;
        }
        if (document.rule === 'optional' && new Date(convertDate(document.deadline)) < new Date()) {
            return <img src={AdmissionWarningIcon} />;
        }
        if (new Date(convertDate(document.deadline)) < new Date()) {
            return <img src={AdmissionDateExpiredIcon} />;
        }
        return <img src={NotCompleted} />;
    };

    const handleChangeDocuments = (documents: Document[]) => {
        setUploadableDocuments(documents);
    };

    const handleDownload = (document: Document) => {
        const endpoint = isRegistrated
            ? endpoints.admission.downloadDocumentMcc(currentSerial, document.id)
            : endpoints.admission.downloadDocument(token, currentSerial, document.id);

        const request = isRegistrated
            ? API.get(endpoint, {
                  responseType: 'blob',
              })
            : axios.get(endpoint, {
                  responseType: 'blob',
              });
        request.then((response) => downloadFile(response.data, document.fileName));
    };

    const handleDeleteDocument = (serial: number | null, documentId: number) => {
        const endpoint = isRegistrated
            ? endpoints.admission.deleteDocumentMcc(serial, documentId)
            : endpoints.admission.deleteDocument(token, serial, documentId);

        const deleteRequest = isRegistrated ? API.delete(endpoint) : axios.delete(endpoint);
        deleteRequest
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres dokumentum törlés' }));
                const endpoint = isRegistrated
                    ? endpoints.admission.getDocumentsMcc(serial)
                    : endpoints.admission.getDocuments(token, serial?.toString());
                const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
                request.then((response) => handleChangeDocuments(response.data.uploadableDocuments));
            })
            .catch((err) => {
                dispatch(create({ type: 'error', message: err.response.data.err ?? 'Sikertelen dokumentum felöltés' }));
            });
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            axios.get(endpoints.admission.settings).then((response) => {
                if (response.data.types) {
                    setTypes(response.data.types);
                }
                if (response.data.maxSize) {
                    setMaxSize(response.data.maxSize);
                }
            });
            const endpoint = isRegistrated
                ? endpoints.admission.getDocumentsMcc(currentSerial)
                : endpoints.admission.getDocuments(token, currentSerial?.toString());

            const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
            request
                .then((response) => {
                    if (response.data.uploadableDocuments) {
                        setUploadableDocuments(response.data.uploadableDocuments);
                    }
                    if (response.data.informationDocuments) {
                        setInformationDocuments(response.data.informationDocuments);
                    }
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [currentSerial, token, isRegistrated, open]);

    return (
        <Dialog open={open} onClose={handleClose} fullScreen sx={{ marginTop: '3rem' }}>
            <Grid container xs={12} sx={{ background: '#EFF2F5' }}>
                <Grid
                    item
                    container
                    sx={{
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontWeight: 700,
                        }}
                    >
                        Dokumentumok feltöltése
                    </Typography>
                    <CloseIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={handleClose} />
                </Grid>
                <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                    {!loading && (
                        <ThemeProvider theme={defaultTheme}>
                            <Alert
                                severity="info"
                                sx={{
                                    marginBottom: '1rem',
                                    marginX: '1rem',
                                }}
                            >
                                Elfogadott formátum: {types}
                                <br></br>
                                Maximum fájlméret: {maxSize} MB
                            </Alert>
                        </ThemeProvider>
                    )}
                </Grid>
                {loading ? (
                    <Grid item container xs={12} sx={{ justifyContent: 'center', background: '#EFF2F5' }}>
                        <CircularProgress size={100} />
                    </Grid>
                ) : (
                    <Grid container className={AdmissionsDialogStyle.mobileModalContent}>
                        <Grid item container>
                            {uploadableDocuments.map((document, index) => (
                                <Grid
                                    key={index}
                                    item
                                    container
                                    xs={12}
                                    className={`${AdmissionsDialogStyle.mobileDocumentContainer}`}
                                >
                                    <Grid item container xs={12} sx={{ flexDirection: 'column' }}>
                                        <Grid item>
                                            <Typography className={`${AdmissionsDialogStyle.subTitle}`}>
                                                {document.name}
                                                {document.rule === 'optional' && (
                                                    <span
                                                        style={{
                                                            fontSize: '14px',
                                                            marginLeft: '0.5rem', // Adjust the spacing as needed
                                                            color: 'grey',
                                                        }}
                                                    >
                                                        (opcionális)
                                                    </span>
                                                )}
                                            </Typography>
                                            {document.rule === 'optional' && (
                                                <Typography
                                                    sx={{ fontSize: '14px', marginBottom: '1rem', color: 'grey' }}
                                                >
                                                    (opcionális)
                                                </Typography>
                                            )}
                                        </Grid>
                                        {document.fileName ? (
                                            <Grid
                                                item
                                                container
                                                sx={{ alignItems: 'center', justifyContent: 'space-between' }}
                                            >
                                                <Grid
                                                    item
                                                    xs={10}
                                                    onClick={() => handleDownload(document)}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        value={document.fileName}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <DownloadIcon
                                                                        className={AdmissionsStyle.checkGreen}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        inputProps={{
                                                            'aria-disabled': 'true',
                                                        }}
                                                        focused={false}
                                                        sx={{ input: { cursor: 'pointer', caretColor: 'transparent' } }}
                                                    />
                                                </Grid>
                                                {new Date(convertDate(document.deadline)) > new Date() && (
                                                    <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <DeleteIcon
                                                            className={`${AdmissionsDialogStyle.documentDeleteButton}`}
                                                            onClick={() =>
                                                                handleDeleteDocument(currentSerial, document.id)
                                                            }
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        ) : (
                                            <FileUpload
                                                handleChangeDocuments={handleChangeDocuments}
                                                isRegistrated={isRegistrated}
                                                document={document}
                                                serial={currentSerial}
                                                token={token}
                                                admissionDisabled={admissionDisabled}
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '1rem',
                                        }}
                                        xs={12}
                                    >
                                        <Grid xs={10} item container className={AdmissionsStyle.contentCenter}>
                                            <Typography sx={{ fontSize: '14px' }}>Feltöltési határidő: </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    ...(new Date(convertDate(document.deadline)) < new Date() && {
                                                        color: '#D27070',
                                                    }),
                                                }}
                                            >
                                                {convertDate(document.deadline)}
                                                {new Date(convertDate(document.deadline)) < new Date() && ' (Lejárt)'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ marginRight: '0.5rem' }}>
                                            {getStatusIcon(document)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            {informationDocuments.length > 0 && (
                                <>
                                    <Typography className={AdmissionsDialogStyle.subTitle}>Tájékoztatók</Typography>
                                    {informationDocuments.map((document, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            container
                                            xs={12}
                                            className={`${AdmissionsDialogStyle.mobileDocumentInformational}`}
                                        >
                                            <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                                                {document.name}
                                            </Typography>
                                            <Button
                                                onClick={() => handleDownload(document)}
                                                variant="contained"
                                                sx={{ textDecoration: 'none' }}
                                                startIcon={<DownloadIcon />}
                                            >
                                                Fájl letöltése
                                            </Button>
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </Grid>
                        <Grid item container className={`${AdmissionsStyle.contentCenter}`}>
                            <Button
                                onClick={handleClose}
                                className={`${AdmissionsDialogStyle.mobileDocumentsClose}`}
                                variant="outlined"
                                size="large"
                                fullWidth
                            >
                                Bezár
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
}
