import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { Loading } from '@silinfo/front-end-template';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { endpoints } from '../../../../../utils/endPoints';
import useFetch from '../../../../../utils/useFetch';
import { EditActions } from '../EditActions';
import GridRenderCellParamsContext from '../List/GridRenderCellParamsContextProvider';
import MenuContext from '../List/MenuContextProvider';
import { IMonth, SchedulerDialogProps } from './types';
import instance from '../../../../../api';

export default function SchedulerDialog({ openState }: SchedulerDialogProps) {
    const [open, setOpen] = openState;
    const [months, setMonths] = useState<IMonth[]>([]);
    const setter = useContext(MenuContext);

    const handleClose = useCallback(() => {
        setter(null);
        setOpen(false);
    }, [setOpen, setter]);
    const params = useContext(GridRenderCellParamsContext);
    const group = params?.row.name || 'ismeretlen';
    const groupId = params?.id || 0;
    const { loading, data } = useFetch<IMonth[]>(endpoints.scholarships.admin.schedule.main(groupId), '');

    useEffect(() => {
        if (!loading && data) {
            setMonths(data);
        }
    }, [data, loading]);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setMonths((prev) =>
            prev.map((month) => ({ ...month, checked: month.month === +event.target.name ? checked : month.checked })),
        );
    }, []);

    const handleSubmit = useCallback(
        () => instance.post(endpoints.scholarships.admin.schedule.main(groupId), { data: months }),
        [groupId, months],
    );

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>Ösztöndíjak ütemezése: {group}</DialogTitle>
            <DialogContent style={{ paddingTop: 16 }}>
                <Grid container spacing={2}>
                    {loading ? (
                        <Loading noHeight />
                    ) : (
                        months.map(({ month, label, checked }) => (
                            <Grid item xs={12} key={month}>
                                <FormControlLabel
                                    control={<Checkbox checked={checked} onChange={handleChange} name={'' + month} />}
                                    label={label}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <EditActions handleClose={handleClose} handleSubmit={handleSubmit} />
            </DialogActions>
        </Dialog>
    );
}
