import { useContext } from 'react';
import PDFDownloader from '../../../../components/PDFDownloader';
import ComponentToPrint from '../../../../pages/MasterData/General/Students/StudentView/CollapsibleTable/ComponentToPrint';
import { endpoints } from '../../../../utils/endPoints';
import SemesterContext from '../../../../components/SemesterSelector/SemesterContextProvider';

export default function CompletionSheet() {
    const semester = useContext(SemesterContext);
    return <PDFDownloader url={endpoints.students.selfEvaluation.pdf(semester)} ComponentToPrint={ComponentToPrint} />;
}
