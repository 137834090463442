import { Input } from '@silinfo/front-end-template';
import { useState } from 'react';
import questionnaireTemplateService from '../../../services/selfEvalution/questionnaireTemplate';
import { Option } from '@silinfo/front-end-template/src/components/Form/types';

export default function useNameFilterAutoComplete(): Input {
    const [options, setOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const n = 3;

    const handleInputChange = (_: React.SyntheticEvent<Element, Event>, value: string) => {
        setInputValue(value);
        if (value.length >= n) {
            setLoading(true);
            questionnaireTemplateService
                .search(value)
                .then((response) => {
                    setOptions(response.data);
                })
                .finally(() => setLoading(false));
        }
    };
    return {
        name: 'name',
        label: 'Név',
        fieldType: 'base',
        type: 'multiselect',
        format: { xs: 12, md: 6, lg: 3 },
        options: options,
        props: {
            onInputChange: handleInputChange,
            noOptionsText: loading
                ? 'Betöltés...'
                : inputValue.length >= n
                ? 'Nincs találat'
                : 'Írjon be ' + n + ' karaktert a kereséshez',
            onClose: () => setOptions([]),
            multiple: true,
            filterSelectedOptions: true,
            freeSolo: true,
        },
    };
}
