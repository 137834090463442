import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Option } from '../../utils/AppConst';

const Row = ({ value, label }: Option<React.ReactNode>) => (
    <Grid item container xs={12}>
        <Grid item xs={12}>
            <Typography variant="h6">{label}</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h5">
                <strong>{value}</strong>
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
    </Grid>
);

export default Row;
