import Add from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Children, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
    ObjectWithBaseFileProperties,
    ObjectWithCertificateFileProperties,
    transformFileProperties,
} from '../../../../utils/AppConst';
import LanguageChip from './LanguageChip';
import LanguageDialog from './LanguageDialog';
import { LanguageExam, LanguageExamBase, TransformedLanguageExam } from './types';

export default function LanguageExams({ readOnly = false }: { readOnly?: boolean }) {
    const [open, setOpen] = useState(false);
    const [languageExam, setLanguageExam] = useState<LanguageExam | TransformedLanguageExam | undefined>(undefined);
    const { profile } = useSelector((state: RootState) => state.studentProfile);

    return (
        <Grid item xs={12} container spacing={0.5}>
            {Children.toArray(
                (profile.languageExams as LanguageExam[]).map((elem) => (
                    <LanguageChip
                        key={elem.id}
                        languageExam={
                            transformFileProperties(
                                elem as LanguageExamBase & ObjectWithCertificateFileProperties,
                            ) as LanguageExamBase & ObjectWithBaseFileProperties
                        }
                        setOpen={setOpen}
                        setLanguageExam={setLanguageExam}
                    />
                )),
            )}
            {!readOnly ? (
                <Grid item>
                    <IconButton
                        color="success"
                        onClick={() => {
                            setLanguageExam(undefined);
                            setOpen(true);
                        }}
                    >
                        <Add />
                    </IconButton>
                </Grid>
            ) : (
                <></>
            )}
            <LanguageDialog
                open={open}
                setOpen={setOpen}
                languageExam={languageExam as LanguageExam}
                setLanguageExam={setLanguageExam}
            />
        </Grid>
    );
}
