import { makeStyles } from '@mui/styles';
import { IStudentProfile } from '../../../store/studentProfile';
import { Address } from '../../../utils/Interfaces/interfaces';
import ProfilePicture from '../../../images/mcc_profile_picture.png';
import { IEditForms, IEmailForm, IPhoneForm, TEditForm } from './types';
import { residence } from '../../../pages/MasterData/General/Students/Form/fields';
import { store } from '../../../store';
import { create } from '../../../store/notification';
import { endpoints } from '../../../utils/endPoints';
import instance from '../../../api';
import { isKep } from '../../../utils/AppConst';

export const AVATAR_SIZE = 75;
export const COVER_IMAGE =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BgQAAAADDoPlTX+AIVQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwDcaiAAFXD1ujAAAAAElFTkSuQmCC';
export const PROFILE_PICTURE = ProfilePicture;

const emailFormProps: TEditForm<IEmailForm> = (profile) => ({
    fields: [
        {
            name: 'email',
            label: 'E-mail cím',
            format: {
                xs: 12,
            },
        },
    ],
    initialValues: {
        email: profile.email,
    },
    onSubmit: (data, helpers) => {
        if (profile.email === data.email) {
            store.dispatch(create({ type: 'info', message: 'Email címed nem változott.' }));
            return;
        }
        instance
            .post(endpoints.changeEmailGenCode, data)
            .then((response) => {
                store.dispatch(create({ type: 'success', message: response.data.message }));
            })
            .catch((error) => {
                if (error.response.data.emailEq) {
                    store.dispatch(create({ type: 'info', message: error.response.data.message }));
                } else {
                    helpers.setErrors({ email: error.response.data.message });
                    store.dispatch(create({ type: 'error', message: error.response.data.message }));
                }
            });
    },
});

const phoneFormProps: TEditForm<IPhoneForm> = (profile) => ({
    fields: [
        {
            name: 'phone',
            label: 'Telefonszám',
            type: 'phone',
            format: {
                xs: 12,
            },
            props: {
                country: isKep() ? 'ro' : 'hu',
            },
        },
    ],
    initialValues: {
        phone: profile.phone,
    },
    onSubmit: (form) => instance.post(endpoints.students.profile.baseData.savePhone, form),
});

const addressFormProps: TEditForm<Address> = (profile) => ({
    fields: residence,
    initialValues: {
        postCode: profile.postCode,
        city: profile.city,
        streetType: profile.streetType,
        streetName: profile.streetName,
        houseNumber: profile.houseNumber,
        addressFloor: profile.addressFloor,
        addressDoor: profile.door,
        door: profile.door,
    },
    onSubmit: (form) => instance.post(endpoints.students.profile.baseData.saveAddress, form),
});

export const editFormProps = (profile: IStudentProfile): IEditForms => ({
    email: emailFormProps(profile),
    phone: phoneFormProps(profile),
    address: addressFormProps(profile),
});

export const useStyles = makeStyles(() => ({
    avatar: {
        '&:hover': {
            filter: 'contrast(0.5)',
        },
    },
    cover: {
        backdropFilter: 'blur(2px)',
    },
}));
