import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';
import instance from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

export interface ITrainingProgramsForm {
    mccDegreeHungarian: string;
    mccDegreeEnglish: string;
    englishName: string;
    trainingProgramLeader: string;
    name: string;
    duration: string;
    collegeYears: number[];
}
const trainingProgramsService = {
    ...crudTemplate(endpoints.masterData.trainingPrograms.main),
    filterByTrainingProgram: (form: IForm, levelId: string | number | undefined) =>
        API.get(endpoints.masterData.trainingPrograms.filter.replace('{levelId}', '' + (levelId || '')), {
            params: form,
        }),
    filterCollegeYearsByLvlProgram: (
        form: IForm,
        levelId: string | number | undefined,
        programId: string | number | undefined,
    ) =>
        API.get(
            endpoints.masterData.trainingPrograms.collegeYearsByLvlProgram
                .replace('{levelId}', '' + (levelId || ''))
                .replace('{programId}', '' + (programId || '')),
            {
                params: form,
            },
        ),
    collegeYears: () => API.get(endpoints.masterData.trainingPrograms.collegeYears, {}),
    collegeYearsNew: (id?: string) =>
        API.get(endpoints.masterData.collegeYears.forSelectNew, id ? { params: { trainingProgram: id } } : {}),
    collegeYearsEdit: (id: string) =>
        API.get(endpoints.masterData.collegeYears.forSelectTrainingProgram.replace('{trainingProgramId}', '' + id)),
    archive: (programId: number) =>
        API.post(endpoints.masterData.trainingPrograms.archive.replace('{programId}', '' + programId), {}),
    fetchCollegeYears: (programId: string | number) =>
        API.get(endpoints.masterData.trainingPrograms.fetchCollegeYears.replace('{programId}', '' + programId)),
    fetchCollegeYearsByTrainingProgramAndStudent: (programId: string, studentId: string) =>
        API.get(
            endpoints.masterData.trainingPrograms.fetchCollegeYearsByTrainingProgramAndStudent
                .replace('{programId}', '' + programId)
                .replace('{studentId}', '' + studentId),
        ),
    collegeYearOptions: (trainingProgram?: number) =>
        API.get(endpoints.masterData.collegeYears.options + '?trainingProgram=' + trainingProgram, {}),
    trainingPrograms: (trainingLevel?: number) =>
        instance.get(endpoints.masterData.trainingPrograms.options + '?trainingLevel=' + trainingLevel),
    optionsForFilterStudent: () => API.get(endpoints.masterData.trainingPrograms.optionsForFilterStudent),
    optionsByTrainingLevel: (levelId: string) =>
        API.get(endpoints.masterData.trainingPrograms.optionsByTrainingLevel.replace('{levelId}', levelId)),
};

export default trainingProgramsService;
