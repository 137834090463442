import Home from '@mui/icons-material/Home';
import ArchiveIcon from '@mui/icons-material/Archive';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
    CancelButton,
    Link,
    SubmitButton,
    TableButton,
    TableDeleteButton,
    TableEditButton,
} from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import trainingProgramsService from '../../../../../../services/masterData/trainingPrograms';
import { create } from '../../../../../../store/notification';
import { clientEndPoints } from '../../../../../../utils/clientEndPoints';
import Visibility from '@mui/icons-material/Visibility';

export function BtnCellRenderer({
    loadTable,
    ...params
}: GridRenderCellParams & { loadTable: () => void; readonly?: boolean }) {
    const [open, setOpen] = useState(false);
    const onClick = () => {
        setOpen(true);
    };
    const { id } = useParams();
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {!params.readonly && (
                <Link
                    to={clientEndPoints.md_training_levels_programs_edit
                        .replace(':id', id ? id.toString() : '')
                        .replace(':programId', params.row.id.toString())}
                >
                    <TableEditButton tooltip={'Szerkesztés'} sx={{ marginRight: '5px' }} />
                </Link>
            )}
            {params.row.deletable && !params.readonly && (
                <TableDeleteButton
                    tooltip={'Törlés'}
                    deleteAction={() => {
                        trainingProgramsService
                            .delete(params.row.id)
                            .then(() => dispatch(create({ type: 'success', message: 'Sikeres törlés' })))
                            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés' })))
                            .finally(loadTable);
                    }}
                    confirmationElement={params.row.name}
                    sx={{ marginRight: '5px' }}
                />
            )}
            {!params.row.archive && !params.readonly && (
                <>
                    <TableButton color={'error'} sx={{ marginRight: '5px' }} tooltip={'Archiválás'} onClick={onClick}>
                        <ArchiveIcon />
                    </TableButton>

                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Archiválás</DialogTitle>
                        <DialogContent style={{ paddingTop: '24px' }}>
                            <DialogContentText>
                                Biztosan archiválja a következő elemet a rendszerből: &quot;{params.row.name}
                                &quot;?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton onClick={handleClose}>Nem</CancelButton>
                            <SubmitButton
                                onClick={() => {
                                    trainingProgramsService
                                        .archive(params.row.id)
                                        .then(() =>
                                            dispatch(create({ type: 'success', message: 'Sikeres archiválás' })),
                                        )
                                        .catch(() =>
                                            dispatch(create({ type: 'error', message: 'Sikertelen archiválás' })),
                                        )
                                        .finally(loadTable);
                                }}
                            >
                                Igen
                            </SubmitButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
            {params.readonly && (
                <Link
                    to={clientEndPoints.md_training_levels_programs_show
                        .replace(':id', id ? id.toString() : '')
                        .replace(':programId', params.row.id.toString())}
                >
                    <TableButton sx={{ marginRight: '5px' }} tooltip={'Adatok megtekintése'}>
                        <Visibility />
                    </TableButton>
                </Link>
            )}
            <Link
                to={clientEndPoints.md_training_levels_programs_requirements_campuses
                    .replace(':id', id ? id.toString() : '')
                    .replace(':programId', params.row.id.toString())}
            >
                <TableButton tooltip={'Követelmények'} sx={{ marginRight: '5px' }}>
                    <Home />
                </TableButton>
            </Link>
        </>
    );
}
