import CheckBox from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { TableButton, Tooltip } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatusEndpoint } from './utils';
import financeFinancialApprovalsService from '../../../services/masterData/finances/financialApprovals/financialApprovals';
import { create } from '../../../store/notification';
import useAccessCheck from '../../../utils/useAccessCheck';
import { RootState } from '../../../store';
import { makeStyles } from '@mui/styles';

export default function ActionButtons({
    id,
    status,
    setRefresh,
}: {
    id: number;
    status: string;
    setRefresh: Dispatch<SetStateAction<boolean>>;
}) {
    const dispatch = useDispatch();

    const editableStatus =
        [
            'wait_to_un_admin_approval',
            'wait_to_leader_approval',
            'invoice_during_issue',
            'waiting_for_payment',
            'waiting_for_payment_out',
        ].indexOf(status) >= 0;
    let tooltipVal = '';

    const hasAccess = useAccessCheck([getStatusEndpoint(status)]);

    switch (status) {
        case 'wait_to_un_admin_approval':
            tooltipVal = 'Jóváhagyás';
            break;
        case 'wait_to_leader_approval':
            tooltipVal = 'Jóváhagyás';
            break;
        case 'invoice_during_issue':
            tooltipVal = 'Számla kiállítás';
            break;
        case 'waiting_for_payment':
            tooltipVal = 'Befizetve';
            break;
        case 'waiting_for_payment_out':
            tooltipVal = 'Kifizetve';
            break;
        default:
            tooltipVal = '-';
            break;
    }

    return <>{NextButton(dispatch, id, setRefresh, tooltipVal, hasAccess, editableStatus)}</>;
}

function NextButton(
    dispatch: Dispatch<unknown>,
    id: number,
    setRefresh: Dispatch<SetStateAction<boolean>>,
    tooltipVal: string,
    canEdit: boolean,
    editableStatus: boolean,
) {
    const [open, setOpen] = useState(false);
    const [secOpen, setSecOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state: RootState) => state.auth);

    const useStyles = makeStyles(() => ({
        eyeIcon: {
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.38)',
            '&:hover': {
                backgroundColor: 'white',
                color: 'rgba(0, 0, 0, 0.38)',
            },
        },
    }));

    const classes = useStyles();

    if (!editableStatus || user.archive) return null;

    return (
        <Grid item>
            <TableButton
                style={{ marginRight: '5px' }}
                className={classes.eyeIcon}
                disabled={!canEdit || loading}
                onClick={() => {
                    setOpen(true);
                }}
            >
                <Tooltip title={tooltipVal}>
                    <CheckBox />
                </Tooltip>
            </TableButton>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Jóváhagyás</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <br />
                        Biztosan jóvá szeretné hagyni a pénzügyi tételt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="success"
                        disabled={loading}
                        onClick={() => {
                            setLoading(true);
                            sendForm(dispatch, setRefresh, id, 'next', setLoading);
                            setOpen(false);
                        }}
                    >
                        Jóváhagyás
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={loading}
                        onClick={() => {
                            setOpen(false);
                            setSecOpen(true);
                        }}
                    >
                        Törlés
                    </Button>
                    <Button variant="contained" onClick={() => setOpen(false)} disabled={loading}>
                        Mégsem
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={secOpen} onClose={() => setSecOpen(false)}>
                <DialogTitle>Törlés</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <br />
                        Biztosan törölni szeretné a pénzügyi tételt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSecOpen(false);
                            setOpen(true);
                        }}
                        disabled={loading}
                    >
                        Nem
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setLoading(true);
                            sendForm(dispatch, setRefresh, id, 'stop', setLoading);
                            setSecOpen(false);
                            setOpen(false);
                        }}
                        disabled={loading}
                    >
                        Igen
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function sendForm(
    dispatch: Dispatch<unknown>,
    setRefresh: Dispatch<SetStateAction<boolean>>,
    id: number,
    method: string,
    setLoading: Dispatch<SetStateAction<boolean>>,
) {
    return financeFinancialApprovalsService
        .update(id, {
            method: method,
        })
        .then(() => {
            dispatch(create({ type: 'success', message: 'Sikeres mentés' }));
            setRefresh((prev) => {
                setLoading(false);
                return !prev;
            });
        })
        .catch(() => {
            dispatch(create({ type: 'error', message: 'Sikertelen mentés' }));
            setLoading(false);
        });
}
