import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import { Loading, TableButton } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import Form from '../../../components/Form/Form';
import { useStyles } from '../../../pages/Administration/Approvals/RequestFormDialog/ApprovalActualStatusDialog';
import studentRequestService from '../../../services/studentServices/studentRequestService';
import {
    initialStudentRequestApproval,
    IStudentRequestCourse,
} from '../../../utils/Interfaces/IStudentRequestApproval';
import CourseInfoDialogForm, { ICourseInfoDialogForm } from './CourseInfoDialogForm';

export interface IInfoDialogProps {
    objectId: number;
    isCentralData?: boolean;
}

const CourseInfoDialog = (props: IInfoDialogProps) => {
    const { objectId, isCentralData } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const [initialValues, setInitialValues] = useState(initialStudentRequestApproval);
    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        studentRequestService
            .getApproval(objectId, isCentralData)
            .then((res) => {
                setInitialValues(res.data.data);
            })
            .finally(() => setInitialLoading(false));
    }, [isCentralData, objectId]);

    if (initialLoading) return <Loading noHeight />;

    const initialValuesForForm: ICourseInfoDialogForm = {
        reason: initialValues.reason,
        ...(initialValues.course as Required<IStudentRequestCourse>),
    };

    return (
        <>
            <TableButton onClick={handleOpen} tooltip={`Változtatások`} color={'info'} className={classes.eyeIcon}>
                <InfoIcon />
            </TableButton>
            <Dialog open={open} onClose={handleClose}>
                <Form
                    fields={[]}
                    onSubmit={() => console.log('/TODO')}
                    initialValues={initialValuesForForm}
                    hideButtons
                >
                    {(formikProps) => (
                        <CourseInfoDialogForm title={initialValues.type} handleClose={handleClose} {...formikProps} />
                    )}
                </Form>
            </Dialog>
        </>
    );
};

export default CourseInfoDialog;
