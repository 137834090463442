import { tableCellClasses } from '@mui/material/TableCell';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Page } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import notificationsService from '../../../services/administration/notifications';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import CheckboxAccordion from './CheckboxAccordion';
import { INotificationForm } from './types';
import { initialNotificationState, notificationTypes, notificationTypesArray } from './utils';
import { EditTable } from './EditTable';
import { useTenant } from '../../../components/TenantContext';

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const initialTypes = {
    TYPE_POPUP: initialNotificationState,
    TYPE_POPUP_ONCE: initialNotificationState,
    TYPE_EMAIL: initialNotificationState,
    TYPE_SYSTEM_MESSAGE: initialNotificationState,
};

export default function NotificationsEdit() {
    const { id } = useParams() as { id: string };
    const [title, setTitle] = useState('');
    const tenant = useTenant();
    const getEvent = useCallback(async (): Promise<AxiosResponse<INotificationForm>> => {
        const { data, ...rest } = await notificationsService.get(id);
        const { types } = data;
        const values = notificationTypesArray.reduce(
            (acc, type) => ({ ...acc, [type]: types?.[type]?.value ? types[type] : initialNotificationState }),
            initialTypes,
        );

        setTitle(data.label);

        return {
            ...rest,
            data: {
                id: data.id,
                types: values,
            },
        };
    }, [id]);

    const header = {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_notifications_list]: 'Értesítések',
            edit: title.charAt(0).toUpperCase() + title.slice(1),
        },
        title: 'Értesítések',
        project: tenant || 'TAR',
    };

    const initialValues: INotificationForm = {
        id,
        types: initialTypes,
    };

    return (
        <Page header={header}>
            <Grid item xs={12} lg={8} container>
                <Grid item xs={12}>
                    <Form
                        initialValues={initialValues}
                        fields={[]}
                        getterFunction={getEvent}
                        back={clientEndPoints.admin_notifications_list}
                        onSubmit={(form: INotificationForm) => {
                            return notificationsService.edit(id, form);
                        }}
                    >
                        {() => (
                            <>
                                {notificationTypes.map((type) => (
                                    <CheckboxAccordion key={type.value} label={type.label} value={type.value} />
                                ))}
                            </>
                        )}
                    </Form>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={4} container>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardHeader title="Kódtábla" />
                        <CardContent>
                            <EditTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
}
