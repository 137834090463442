import React, { createContext, useContext, useState, ReactNode } from 'react';
import { IGift, IITRequest } from './utils';

interface Address {
    gifts?: IGift[];
    communicationNeed?: number[];
    communicationNeedsDescription?: string;
    communicationItemNeeds?: number[];
    communicationItemNeedsDescription?: string;
    itRequests?: IITRequest[];
    visualAppearances?: string[];
    roomLayout?: string;
    receptionType?: string;
    waterFoodNeeded?: boolean;
    waterForPresenter?: boolean;
    cateringFileName?: string;
    cateringFilePath?: string;
}

interface TabStateContextProps {
    tabState: Address[];
    setTabState: React.Dispatch<React.SetStateAction<Address[]>>;
}

const TabStateContext = createContext<TabStateContextProps | undefined>(undefined);

const TabStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [tabState, setTabState] = useState<Address[]>([]);

    return <TabStateContext.Provider value={{ tabState, setTabState }}>{children}</TabStateContext.Provider>;
};

const useTabState = (): TabStateContextProps => {
    const context = useContext(TabStateContext);
    if (!context) {
        throw new Error('useTabState must be used within a TabStateProvider');
    }
    return context;
};

export { TabStateProvider, useTabState };
