import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Label = ({ children }: { children: string }) => (
    <Grid item xs={12}>
        <Typography fontSize={14}>
            <strong>{children}</strong>
        </Typography>
    </Grid>
);

export default Label;
