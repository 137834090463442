import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FieldGenerator, Option } from '@silinfo/front-end-template';
import { FormikProps, useFormikContext } from 'formik';
import { IAddress, IEventForm, IExternalAddress, communicationFields } from '../utils';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SeparateLocationsCheckBox from '../SeparateLocationsCheckBox';
import Tooltip from '@mui/material/Tooltip';
import { useTabState } from '../TabStateContext';

function Need({
    formikProps,
    title,
    index,
    disabled,
    currentTab,
}: {
    formikProps: FormikProps<IEventForm>;
    title: string;
    index: number;
    addition?: string;
    disabled?: boolean;
    currentTab?: number;
}) {
    const addresses = formikProps.values.addresses;
    const fieldName = index === 0 ? 'communicationNeed' : 'communicationItemNeeds';
    const currentIndex = currentTab ? currentTab : 0;

    const handleChange = (_: unknown, value: Option<string, string>[] | string[] | null) => {
        if (currentTab !== undefined) {
            formikProps.setFieldValue(
                'addresses.' + currentIndex + '.' + fieldName,
                value?.map((e) => (typeof e === 'string' ? e : e.value)),
            );
        } else {
            addresses?.forEach((_, index) => {
                formikProps.setFieldValue(
                    'addresses.' + index + '.' + fieldName,
                    value?.map((e) => (typeof e === 'string' ? e : e.value)),
                );
            });
        }
    };
    return (
        <Grid item xs={12} container>
            <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={12} key={communicationFields()[index].name + (disabled ? 'Disabled' : '')}>
                {FieldGenerator({
                    ...communicationFields()[index],
                    ...formikProps,
                    props: {
                        ...communicationFields()[index].props,
                        disabled,
                        key: communicationFields()[index].name + (disabled ? 'Disabled' : ''),
                        value: addresses && addresses[currentIndex][fieldName],
                        onChange: handleChange,
                    },
                    fieldType: 'base',
                })}
            </Grid>
        </Grid>
    );
}

const Inputs = ({ readOnly, currentTab }: { readOnly: boolean | undefined; currentTab?: number }) => {
    const formikProps = useFormikContext<IEventForm>();
    const need = formikProps.values.communicationNeed as Array<string>;
    const items = formikProps.values.communicationItemNeeds as Array<string>;
    const needAddition = need ? '?inactive=1&exception[]=' + need : '';
    const itemAddition = items ? '?inactive=1&exception[]=' + items.join('&exception[]=') : '';
    const addresses = formikProps.values.addresses;
    const currentIndex = currentTab ? currentTab : 0;
    const handleChange = (value: string, fieldName: string) => {
        if (currentTab !== undefined) {
            formikProps.setFieldValue('addresses.' + currentIndex + '.' + fieldName, value);
        } else {
            addresses?.forEach((_, index) => {
                formikProps.setFieldValue('addresses.' + index + '.' + fieldName, value);
            });
        }
    };

    return (
        <Grid container item xs={12} spacing={2}>
            <Need
                formikProps={formikProps}
                title="Kommunikációs igény"
                index={0}
                addition={needAddition}
                disabled={readOnly}
                currentTab={currentTab}
            />
            {(
                (formikProps.values.addresses &&
                    (formikProps.values.addresses[currentIndex].communicationNeed as unknown as string[])) ||
                []
            ).includes('other') && (
                <Grid item xs={12} key={communicationFields()[3].name}>
                    {FieldGenerator({
                        ...communicationFields()[3],
                        ...formikProps,
                        props: {
                            ...communicationFields()[3].props,
                            key: communicationFields()[3].name,
                            disabled: readOnly,
                            value: addresses && addresses[currentIndex].communicationNeedsDescription,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                handleChange(e.target.value, 'communicationNeedsDescription'),
                        },
                        fieldType: 'base',
                    })}
                </Grid>
            )}
            <Need
                formikProps={formikProps}
                title="Kommunikációs eszközigény"
                index={1}
                addition={itemAddition}
                disabled={readOnly}
                currentTab={currentTab}
            />
            {(
                (formikProps.values.addresses &&
                    (formikProps.values.addresses[currentIndex].communicationItemNeeds as unknown as string[])) ||
                []
            ).includes('other') && (
                <Grid item xs={12} key={communicationFields()[2].name}>
                    {FieldGenerator({
                        ...communicationFields()[2],
                        ...formikProps,
                        props: {
                            ...communicationFields()[2].props,
                            key: communicationFields()[2].name,
                            disabled: readOnly,
                            value: addresses && addresses[currentIndex].communicationItemNeedsDescription,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                handleChange(e.target.value, 'communicationItemNeedsDescription'),
                        },
                        fieldType: 'base',
                    })}
                </Grid>
            )}
        </Grid>
    );
};

export default function Communication({ readOnly }: { readOnly: boolean | undefined }) {
    const formikProps = useFormikContext<IEventForm>();
    const [separateLocations, setSeparateLocations] = useState(formikProps.values.communicationPerLocation);
    const [value, setValue] = useState('0');
    const addresses = formikProps.values.addresses;
    const { tabState, setTabState } = useTabState();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    useEffect(() => {
        if (firstLoad) {
            tabState.forEach((address, index) => {
                if (
                    (address.communicationItemNeeds ||
                        address.communicationNeed ||
                        address.communicationItemNeedsDescription ||
                        address.communicationNeedsDescription) &&
                    index < addresses.length
                ) {
                    formikProps.setFieldValue(
                        'addresses.' + index + '.communicationItemNeeds',
                        address.communicationItemNeeds,
                    );
                    formikProps.setFieldValue('addresses.' + index + '.communicationNeed', address.communicationNeed);
                    formikProps.setFieldValue(
                        'addresses.' + index + '.communicationNeedsDescription',
                        address.communicationNeedsDescription,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.communicationItemNeedsDescription',
                        address.communicationItemNeedsDescription,
                    );
                }
            });
            setFirstLoad(false);
        }
    }, [addresses.length, firstLoad, formikProps, tabState]);

    useEffect(() => {
        return () => {
            setTabState((prevTabState) => {
                const updatedTabState = addresses.map((address, index) => {
                    const newNeeds = separateLocations
                        ? addresses[index]?.communicationNeed ?? []
                        : addresses[0]?.communicationNeed ?? [];
                    const newItems = separateLocations
                        ? addresses[index]?.communicationItemNeeds ?? []
                        : addresses[0]?.communicationItemNeeds ?? [];

                    const newCommunicationNeedsDescription = separateLocations
                        ? addresses[index]?.communicationNeedsDescription ?? ''
                        : addresses[0]?.communicationNeedsDescription ?? '';

                    const newCommunicationItemNeedsDescription = separateLocations
                        ? addresses[index]?.communicationItemNeedsDescription ?? ''
                        : addresses[0]?.communicationItemNeedsDescription ?? '';

                    return {
                        ...address,
                        ...prevTabState[index],
                        communicationItemNeeds: newItems,
                        communicationNeed: newNeeds,
                        communicationNeedsDescription: newCommunicationNeedsDescription,
                        communicationItemNeedsDescription: newCommunicationItemNeedsDescription,
                    };
                });

                return updatedTabState;
            });
        };
    }, [addresses, separateLocations, setTabState]);

    const handleChange = (newValue: boolean) => {
        formikProps.setFieldValue('communicationPerLocation', newValue);
        if (separateLocations) {
            addresses?.forEach((_, index) => {
                if (index !== 0) {
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'communicationNeed',
                        addresses[0].communicationNeed,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'communicationNeedsDescription',
                        addresses[0].communicationNeedsDescription,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'communicationItemNeeds',
                        addresses[0].communicationItemNeeds,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'communicationItemNeedsDescription',
                        addresses[0].communicationItemNeedsDescription,
                    );
                }
            });
        }
    };

    return (
        <Grid item xs={12} container spacing={4} sx={{ paddingLeft: '2rem' }}>
            {separateLocations ? (
                <>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '0.5rem' }} width="100%">
                            <TabList
                                variant="fullWidth"
                                onChange={(_, val) => setValue(val)}
                                aria-label="lab API tabs example"
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        backgroundColor: '#fff !important',
                                        color: '#22344c !important',
                                    },
                                }}
                            >
                                {addresses?.map((element: IAddress | IExternalAddress, index) =>
                                    'roomName' in element ? (
                                        <Tab
                                            key={index}
                                            label={
                                                <Tooltip title={element.roomName ?? 'Nincs megadva'}>
                                                    <span>
                                                        {element.roomName.length > 32
                                                            ? element.roomName.substring(0, 32) + '...'
                                                            : element.roomName}
                                                    </span>
                                                </Tooltip>
                                            }
                                            value={'' + index}
                                        />
                                    ) : (
                                        <Tab
                                            key={index}
                                            label={
                                                <Tooltip title={element.location ?? 'Nincs megadva'}>
                                                    <span>
                                                        {element.location.length > 32
                                                            ? element.location.substring(0, 32) + '...'
                                                            : element.location}
                                                    </span>
                                                </Tooltip>
                                            }
                                            value={'' + index}
                                        />
                                    ),
                                )}
                            </TabList>
                            {addresses?.map((_, index) => (
                                <TabPanel key={index} value={'' + index}>
                                    <Inputs currentTab={index} readOnly={readOnly} />
                                </TabPanel>
                            ))}
                        </Box>
                    </TabContext>
                    <SeparateLocationsCheckBox
                        checkedState={[separateLocations, setSeparateLocations]}
                        label="Helyszínenként külön szeretném beállítani a kommunikációs igényeket"
                        handleChange={handleChange}
                        readOnly={readOnly}
                    />
                </>
            ) : (
                <Grid container item xs={12} spacing={2}>
                    <SeparateLocationsCheckBox
                        checkedState={[separateLocations, setSeparateLocations]}
                        label="Helyszínenként külön szeretném beállítani a kommunikációs igényeket"
                        handleChange={handleChange}
                        readOnly={readOnly}
                    />
                    <Inputs readOnly={readOnly} />
                </Grid>
            )}
        </Grid>
    );
}
