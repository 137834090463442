import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import documentService from '../../services/administration/document';
import OperationCellRenderer from './Operations';

const header = (tenant: string | null) => {
    return {
        title: 'Dokumentumok',
        project: tenant || 'TAR',
        breadcrumbs: {
            documents: 'Dokumentumok',
        },
    };
};

const columns: GridColumns = [
    {
        field: 'name',
        headerName: 'Dokumentum megnevezése',
        align: 'left' as const,
    },
    {
        field: 'updatedAt',
        headerName: 'Utolsó frissítés dátuma',
        valueGetter: (params: GridValueGetterParams) => (params.value ? new Date(params.value).toLocaleString() : '-'),
    },
    {
        field: 'operations',
        headerName: 'Műveletek',
        renderCell: OperationCellRenderer,
    },
].map((column) => ({
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    ...column,
}));

const filter = async (): Promise<AxiosResponse> => {
    const response = await documentService.studentFilter();
    const { data } = response;

    return {
        ...response,
        data: {
            data: data,
        },
    };
};

export { header, columns, filter };
