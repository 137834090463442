import Home from '@mui/icons-material/Home';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Link, TableButton } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import EndpointProvider from '../../../../../../../components/EndpointProvider';
import MCCLoading from '../../../../../../../components/MCCLoading';
import floorsService from '../../../../../../../services/masterData/floors';
import roomsService from '../../../../../../../services/masterData/rooms';
import { create } from '../../../../../../../store/notification';
import { paginatorInfoBuild } from '../../../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../../../utils/clientEndPoints';
import { IInfo } from '../../../../../../../utils/Interfaces/IInfo';
import { initialFloor } from '../../../../../../../utils/Interfaces/initialObjects';
import { Floor, IForm } from '../../../../../../../utils/Interfaces/interfaces';
import { useTenant } from '../../../../../../../components/TenantContext';
import Visibility from '@mui/icons-material/Visibility';

const initialInfo: IInfo<IForm> = {
    filter: {},
    sort: {
        name: 'asc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

export default function Rooms() {
    const [data, setData] = useState<[]>([]);
    const [floor, setFloor] = useState<Floor>(initialFloor);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const params = useParams();
    const campusId = params.campusId ?? '';
    const buildingId = params.buildingId ?? '';
    const floorId = params.floorId ?? '';
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const tenant = useTenant();

    const [info, setInfo] = useState(initialInfo);

    const fetchData = useCallback(
        (form: IInfo<IForm>) => {
            setTableLoading(true);
            axios
                .all([
                    roomsService
                        .filter({ ...form.filter, ...paginatorInfoBuild(form) }, campusId, buildingId, floorId)
                        .then((d: AxiosResponse) => {
                            const totalItems = d.data['hydra:totalItems'];
                            setData(d.data['hydra:member']);
                            setInfo((prev) => ({
                                ...prev,
                                metadata: {
                                    'allCount': totalItems,
                                    'filteredCount': totalItems,
                                    'lastPage': totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                                    'page': info.page,
                                    'perpage': info.perpage,
                                    'rowCount': totalItems,
                                },
                            }));
                        }),
                    floorsService.get(floorId).then((response) => setFloor(response.data)),
                ])
                .catch(() =>
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    ),
                )
                .finally(() => {
                    setTableLoading(false);
                    setLoading(false);
                });
        },
        [buildingId, campusId, dispatch, floorId, info.page, info.perpage],
    );

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            fetchData(initialInfo);
        }
    }, [fetchData, firstLoad]);

    // function loadTable(): void {
    //     setTableLoading(true);
    //     roomsService
    //         .filter({}, campusId, buildingId, floorId)
    //         .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
    //         .finally(() => setTableLoading(false));
    // }

    const header = {
        project: tenant || 'TAR',
        title: 'Szobák',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
            [clientEndPoints.md_general_campuses_buildings_list.replace(':campusId', campusId?.toString() || '')]:
                'Épületek',
            [clientEndPoints.md_general_campuses_buildings_floors_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')]: 'Szintek',
            'rooms': 'Szobák',
        },
    };

    const onSubmit = (form: IForm) => {
        setTableLoading(true);

        setInfo((prevState) => ({
            ...prevState,
            filter: form,
        }));
        const filter = { ...form, ...paginatorInfoBuild(info) };

        return roomsService
            .filter(filter, campusId, buildingId, floorId)
            .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
            .finally(() => setTableLoading(false));
    };

    function BtnCellRenderer(params: GridRenderCellParams) {
        return (
            <EndpointProvider
                endpoints={[
                    'md_general_campuses_buildings_floors_rooms_edit',
                    'md_general_campuses_buildings_floors_rooms_items_list',
                ]}
            >
                <EndpointProvider endpoints={['md_general_campuses_buildings_floors_rooms_show']}>
                    <Link
                        to={clientEndPoints.md_general_campuses_buildings_floors_rooms_show
                            .replace(':campusId', campusId?.toString() ?? '')
                            .replace(':buildingId', buildingId?.toString() ?? '')
                            .replace(':floorId', floorId?.toString() ?? '')
                            .replace(':roomId', params.row.id.toString())}
                    >
                        <TableButton tooltip={'Megtekintés'} sx={{ marginRight: '5px' }}>
                            <Visibility />
                        </TableButton>
                    </Link>
                </EndpointProvider>

                <EndpointProvider endpoints={['md_general_campuses_buildings_floors_rooms_items_list']}>
                    <Link
                        to={clientEndPoints.md_general_campuses_buildings_floors_rooms_items_list
                            .replace(':campusId', campusId?.toString() ?? '')
                            .replace(':buildingId', buildingId?.toString() ?? '')
                            .replace(':floorId', floorId?.toString() ?? '')
                            .replace(':roomId', params.row.id.toString())}
                    >
                        <TableButton
                            tooltip={params.row.type === 'room' ? 'Tárgyak listázása' : 'Eszközök listázása'}
                            sx={{ marginRight: '5px' }}
                        >
                            <Home />
                        </TableButton>
                    </Link>
                </EndpointProvider>
            </EndpointProvider>
        );
    }

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <FormikListPage
            header={header}
            filter={{
                initialValues: {
                    campusName: floor?.building.campus.name,
                    buildingName: floor?.building.name,
                    floorName: floor.name,
                },
                onSubmit,
                fields: [
                    {
                        name: 'campusName',
                        label: 'Campus',
                        format: { xs: 12, md: 6, lg: 3 },
                        props: {
                            disabled: true,
                        },
                    },
                    {
                        name: 'buildingName',
                        label: 'Épület',
                        format: { xs: 12, md: 6, lg: 3 },
                        props: {
                            disabled: true,
                        },
                    },
                    {
                        name: 'floorName',
                        label: 'Szint',
                        format: { xs: 12, md: 3, lg: 3 },
                        props: {
                            disabled: true,
                        },
                    },
                    {
                        name: 'name',
                        label: 'Szobaszám',
                        format: { xs: 12, md: 3, lg: 3 },
                    },
                    {
                        name: 'space',
                        label: 'Férőhely',
                        format: { xs: 12, md: 3, lg: 3 },
                    },
                    {
                        name: 'type',
                        label: 'Típus',
                        type: 'multiselect',
                        options: [
                            { value: 'room', label: 'Lakószoba' },
                            { value: 'classroom', label: 'Tanterem' },
                        ],
                        format: { xs: 12, md: 3, lg: 3 },
                    },
                    {
                        name: 'assignable',
                        label: 'Szobagenerálás releváns',
                        type: 'multiselect',
                        options: [
                            { value: '1', label: 'Igen' },
                            { value: '0', label: 'Nem' },
                        ],
                        format: { xs: 12, md: 3, lg: 3 },
                    },
                ],
            }}
            table={{
                columnDefs: [
                    { headerName: 'Szoba', field: 'name' },
                    { headerName: 'Férőhely', field: 'space' },
                    {
                        headerName: 'Típus',
                        field: 'type',
                        valueGetter: (params: GridValueGetterParams) =>
                            params.row.type === 'room' ? 'Lakószoba' : 'Tanterem',
                    },
                    {
                        headerName: 'Szobagenerálás releváns',
                        field: 'assignable',
                        valueGetter: (params: GridValueGetterParams) => (params.row.assignable ? 'Igen' : 'Nem'),
                    },
                    {
                        headerName: 'Műveletek',
                        field: 'operations',
                        cellRenderer: 'btnCellRenderer',
                        sortable: false,
                        minWidth: 200,
                        align: 'left',
                    },
                ].map((col) => ({ flex: 1, minWidth: 150, headerAlign: 'center', align: 'center', ...col })),
                rowData: data,
                loading: tableLoading,
                searchfunc: fetchData,
                info: info,
                server: true,
                initialState: {
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                },
                setInfo: setValueByKey,
                frameworkComponents: {
                    btnCellRenderer: BtnCellRenderer,
                },
            }}
        />
    );
}
