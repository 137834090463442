import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { endpoints } from '../../../utils/endPoints';
import { theme } from '../../../utils/theme';
import useFetch from '../../../utils/useFetch';

interface IProgress {
    completed: number;
    inProgress: number;
    toBeCompleted: number;
}

export default function ProgressBar() {
    const { data, loading } = useFetch<IProgress>(endpoints.students.requirements.progress, '') as {
        data: IProgress;
        loading: boolean;
    };

    const sum = data.completed + data.inProgress + data.toBeCompleted;

    if (loading) {
        return (
            <Grid item xs={12}>
                <Skeleton style={{ height: '100%' }} />
            </Grid>
        );
    }

    if (sum === 0) {
        return (
            <Grid item xs={12}>
                <i>Nincs megjeleníthető adat</i>
            </Grid>
        );
    }

    const complPerc = (data.completed / sum) * 100;
    const toBeComplPerc = (data.toBeCompleted / sum) * 100;
    const inProgPerc = (data.inProgress / sum) * 100;

    return (
        <Grid item xs={12} container style={{ height: '40px' }}>
            <Grid item style={{ width: complPerc + '%', paddingLeft: '0px', paddingRight: '0px' }}>
                <div
                    style={{
                        backgroundColor: theme.palette.success.main,
                        height: '100%',
                    }}
                />
            </Grid>
            <Grid item style={{ width: inProgPerc + '%', paddingLeft: '0px', paddingRight: '0px' }}>
                <div style={{ backgroundColor: theme.palette.info.main, height: '100%' }} />
            </Grid>
            <Grid item style={{ width: toBeComplPerc + '%', paddingLeft: '0px', paddingRight: '0px' }}>
                <div style={{ backgroundColor: '#ddd', height: '100%' }} />
            </Grid>
        </Grid>
    );
}
