import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import { makeStyles } from '@mui/styles';
import { TableButton } from '@silinfo/front-end-template';
import { history } from '../../../store';

const useStyles = makeStyles(() => ({
    eyeIcon: {
        backgroundColor: 'white',
        color: '#0288d1',
    },
}));

export default function RedirectButton() {
    const classes = useStyles();

    return (
        <TableButton
            onClick={() => {
                process.env.REACT_APP_ENROLLMENT_FRONTEND_URL !== undefined
                    ? history.push(process.env.REACT_APP_ENROLLMENT_FRONTEND_URL)
                    : console.log('Nincs megadva az MCC Regisztrációs oldal címe!');
            }}
            tooltip={`Átirányítás az MCC Regisztrációs oldalra`}
            color={'info'}
            className={classes.eyeIcon}
        >
            <KeyboardDoubleArrowRightRoundedIcon />
        </TableButton>
    );
}
