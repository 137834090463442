import React, { createContext, useContext } from 'react';

type TenantContextType = string | undefined;

const defaultContext: TenantContextType = undefined;

const TenantContext = createContext<TenantContextType>(defaultContext);

interface TenantContextProviderProps {
    children: React.ReactNode;
    tenant: TenantContextType;
}

export function TenantContextProvider(props: TenantContextProviderProps) {
    return <TenantContext.Provider value={props.tenant}>{props.children}</TenantContext.Provider>;
}

export const useTenant = () => useContext(TenantContext);

export default TenantContext;
