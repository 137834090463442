import DialogBasic from '@mui/material/Dialog';
import { TabContext, TabPanel } from '@mui/lab';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState } from 'react';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import Box from '@mui/material/Box';
import BasicData from './Tabs/BasicData';
import Gifts from './Tabs/Gifts';
import Form from '../../../../components/Form/Form';
import courseEventService from '../../../../services/courseManagement/courseEvent';
import { Option } from '../../../../utils/AppConst';
import { useParams } from 'react-router-dom';
import { IAddress, REVIEW_VALIDATION_PHASES } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { IViolations } from '@silinfo/front-end-template';
import Communication from './Tabs/Communication';
import ItNeed from './Tabs/ItNeed';
import { AxiosError } from 'axios';
import RoomSchedule from './Tabs/RoomSchedule';
import Catering from './Tabs/Catering';
import TabsContainer from './Tabs/TabsContainer';
import FormButtons from './FormButtons';
import { TabStateProvider } from './TabStateContext';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export default function AddEditEventButton({
    title,
    submitBtnTitle,
    opener,
    objectId,
    setTableLoading,
    readOnly,
    courseTenant,
}: {
    title: string;
    submitBtnTitle: string;
    opener: JSX.Element;
    objectId?: number;
    setTableLoading: (val: boolean) => void;
    course?: IForm;
    readOnly?: boolean;
    courseTenant: string | null;
}) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('1');
    const { courseId } = useParams() as { courseId: string };
    const [loading, setLoading] = useState<boolean>(objectId ? true : false);

    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const initialValues = {
        eventType: '',
        comment: '',
        instructors: [],
        eventStart: '',
        eventEnd: '',
        isRepeated: '',
        numberOfRepetitions: 0,
        pointMin: null,
        pointMax: null,
        guestInstructors: [] as string[],
        external: false,
        category: '1',
        organisingTrainingCenter: '',
        organisers: [] as string[],
        addresses: [
            {
                'campus': '',
                'building': '',
                'floor': '',
                'room': '',
                'spaces': 0,
                'gifts': [],
                'roomName': '',
                'communicationNeed': [],
                'communicationItemNeeds': [],
                'waterForPresenter': false,
                'receptionType': '',
                'waterFoodNeeded': false,
                'cateringFileName': '',
                'cateringFilePath': '',
                'visualAppearances': [],
                'itRequests': [],
                'roomLayout': '',
                'communicationNeedsDescription': '',
                'communicationItemNeedsDescription': '',
                'visualAppearancesDescription': '',
                'customRoomLayoutDescription': '',
                'customRoomLayoutFileName': '',
                'customRoomLayoutFilePath': '',
            },
        ] as IAddress[],
        presentsPerLocation: false,
        communicationPerLocation: false,
        cateringPerLocation: false,
        itNeedPerLocation: false,
        roomLayoutPerLocation: false,
    };

    const getEvent = useCallback(async () => {
        if (!objectId) {
            setLoading(false);
            return;
        }
        const { data } = await courseEventService.get(+courseId, objectId, courseTenant);
        setLoading(false);
        return {
            ...data,
            data: {
                ...data.data,
                external: data?.data?.external,
            },
        };
    }, [objectId, courseId, courseTenant]);

    const { count } = useSelector((state: RootState) => state.loading);
    const handleSubmit = (form: IForm) =>
        (!objectId
            ? courseEventService.create(+courseId, form, courseTenant)
            : courseEventService.update(+courseId, +objectId, form, courseTenant)
        )
            .then(() => {
                setTableLoading(true);
                setTableLoading(false);
                handleClose();
            })
            .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                if (error.response?.status === 422) {
                    const { violations } = error.response.data;
                    const phase = violations[0].propertyPath.split('.')[0];
                    const index = REVIEW_VALIDATION_PHASES.indexOf(phase);
                    if (index !== -1) {
                        setValue('' + (index + 1));
                    }

                    return Promise.reject({
                        ...error,
                        response: {
                            ...error.response,
                            data: {
                                violations: violations.map((violation) => ({
                                    ...violation,
                                    propertyPath: violation.propertyPath.includes('addresses')
                                        ? violation.propertyPath
                                        : violation.propertyPath?.split('.')?.slice(1)?.join('.') ||
                                          violation.propertyPath,
                                })),
                            },
                        },
                    });
                }
                return Promise.reject(error);
            });

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic
                open={open}
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    sx: {
                        maxWidth: '800px !important',
                        height: '100%',
                    },
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <div>
                    <Form
                        hideButtons
                        urlParam="courseId"
                        fields={[]}
                        onSubmit={handleSubmit}
                        submitTransformData={(form) =>
                            Object.entries(form).reduce(
                                (prev, [key, value]: [string, unknown]) => ({
                                    ...prev,
                                    [key]: Array.isArray(value)
                                        ? value.map((elem) => elem?.value || elem)
                                        : (value as Option)?.value || value,
                                }),
                                {},
                            ) as IForm
                        }
                        initialValues={initialValues}
                        {...(objectId
                            ? {
                                  getterFunction: getEvent,
                              }
                            : {})}
                    >
                        {() => (
                            <DialogContent>
                                <TabContext value={value}>
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <TabsContainer setValue={setValue} />
                                    </Box>
                                    <TabStateProvider>
                                        <TabPanel key="1" value="1">
                                            <BasicData
                                                handleClose={handleClose}
                                                objectId={objectId}
                                                readOnly={readOnly}
                                            />
                                        </TabPanel>
                                        <TabPanel key="2" value="2">
                                            <Gifts readOnly={readOnly} />
                                        </TabPanel>
                                        <TabPanel key="3" value="3">
                                            <Communication readOnly={readOnly} />
                                        </TabPanel>
                                        <TabPanel key="4" value="4">
                                            <Catering readOnly={readOnly} />
                                        </TabPanel>
                                        <TabPanel key="5" value="5">
                                            <ItNeed readOnly={readOnly} />
                                        </TabPanel>
                                        <TabPanel key="6" value="6">
                                            <RoomSchedule readOnly={readOnly} />
                                        </TabPanel>
                                    </TabStateProvider>
                                </TabContext>
                                {!loading && (
                                    <FormButtons
                                        count={count}
                                        handleClose={handleClose}
                                        readOnly={readOnly}
                                        submitBtnTitle={submitBtnTitle}
                                    />
                                )}
                            </DialogContent>
                        )}
                    </Form>
                </div>
            </DialogBasic>
        </>
    );
}
