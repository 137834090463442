import EmailLogs from '../../../pages/Administration/SystemLog/EmailLogs';
import EmailLogShow from '../../../pages/Administration/SystemLog/EmailLogs/EmailLogShow';

export const emailLogs = {
    path: 'email-logs',
    children: [
        {
            path: '',
            element: <EmailLogs />,
            name: 'admin_email_logs_list',
        },
        {
            path: ':id/show',
            element: <EmailLogShow />,
            name: 'admin_email_logs_show',
        },
    ],
};
