import Button from '@mui/material/Button';
import { ActionProps } from '../types';

export const NonEditActions = ({ handleClose }: ActionProps) => {
    return (
        <Button onClick={handleClose} variant="outlined">
            Bezárás
        </Button>
    );
};
