import Tooltip from '@mui/material/Tooltip';
import { ICompletionData, ITrainingCompletionData } from '../../types';
import { getColorByStatus, statusTranslations } from '../../utils';

export default function StatusCellRenderer(row: ICompletionData | ITrainingCompletionData) {
    return (
        <Tooltip title={statusTranslations[row.status]}>
            <span style={{ color: getColorByStatus(row.status) }}>{statusTranslations[row.status]}</span>
        </Tooltip>
    );
}
