import { Input } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { IForm } from '../../../../../utils/Interfaces/interfaces';

export const sizes = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'] as const;
export type SizeType = typeof sizes;
export type Size = SizeType[number];

type FormSectionBase = {
    name: string;
    fields: Input[];
    custom?: JSX.Element;
};

export type DynamicSection = FormSectionBase & {
    dynamic: true;
    nameInForm: string;
    url: string;
    formikProps?: undefined;
};

export type DetailedFormSection = FormSectionBase &
    (DynamicSection | { dynamic?: false; nameInForm?: undefined; formikProps: FormikProps<IForm> });

export interface DetailedForm {
    name: string;
    sections: Omit<DetailedFormSection, 'formikProps'>[];
}
