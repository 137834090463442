import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FieldGenerator } from '@silinfo/front-end-template';
import Grid from '@mui/material/Grid';
import { FormikProps } from 'formik';
import { IStudentRequestCourse } from '../../../utils/Interfaces/IStudentRequestApproval';

export interface ICourseInfoDialogForm extends IStudentRequestCourse {
    reason: string;
    student?: string;
    tenant?: string;
}

const CourseInfoDialogForm = (
    props: FormikProps<ICourseInfoDialogForm> & {
        title: string;
        handleClose: () => void;
    },
) => {
    const { title, handleClose } = props;

    return (
        <Grid item container>
            <Grid item xs={12}>
                <DialogTitle>{title}</DialogTitle>
            </Grid>
            <Grid item container xs={12}>
                <DialogContent>
                    {FieldGenerator({
                        ...props,
                        label: 'Kurzus neve',
                        name: 'name',
                        props: {
                            disabled: true,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                    {FieldGenerator({
                        ...props,
                        label: 'Kurzus kódja',
                        name: 'code',
                        props: {
                            disabled: true,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                    {FieldGenerator({
                        ...props,
                        label: 'Szemeszter azonosító',
                        name: 'semesterCode',
                        props: {
                            disabled: true,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                    {FieldGenerator({
                        ...props,
                        label: 'Kurzus aktuális és maximális létszáma:',
                        name: 'headCount',
                        props: {
                            disabled: true,
                            value: `${props.values.normalEnrollments} / ${props.values.maxHeadCount}`,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                    {FieldGenerator({
                        ...props,
                        label: 'Hallgató által beírt indoklás',
                        name: 'reason',
                        props: {
                            disabled: true,
                            multiline: true,
                            rows: 4,
                        },
                        format: {
                            xs: 12,
                        },
                    })}
                </DialogContent>
            </Grid>
            <Grid item xs={12}>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Bezár
                    </Button>
                </DialogActions>
            </Grid>
        </Grid>
    );
};

export default CourseInfoDialogForm;
