/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { endpoints } from '../../../../utils/endPoints';
import { Admission, Document, SelectedRow } from '../types';
import API from '../../../../api';
import Typography from '@mui/material/Typography';
import ModeIcon from '@mui/icons-material/Mode';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AdmissionCompletedIcon from '../Icons/AdmissionCompletedIcon.svg';
import NotCompleted from '../Icons/NotCompleted.svg';
import AdmissionDateExpiredIcon from '../Icons/AdmissionDateExpiredIcon.svg';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import Serial from '../Serial';
import UploadIcon from '../Icons/UploadIcon.svg';
import DisabledUploadIcon from '../Icons/DisabledUploadIcon.svg';
import FillQuestionnaireIcon from '../Icons/FillQuestionnaireIcon.svg';
import AddTrainingProgram from '../Icons/AddTrainingProgram.svg';
import AddTrainingProgramDisabled from '../Icons/AddTrainingProgramDisabled.svg';
import ExpiredIcon from '../Icons/ExpiredIcon.svg';
import { useDispatch } from 'react-redux';
import { create } from '../../../../store/notification';
import CheckIcon from '@mui/icons-material/Check';
import AdmissionsStyle from '../Admissions.module.scss';
import AdmissionsListStyle from './AdmissionsList.module.scss';
import { convertDate } from '../utils';
import axios from 'axios';

export default function AdmissionMobileListElement({
    admission,
    index,
    token,
    isRegistrated,
    firstItem,
    lastItem,
    isProgramSelected,
    isPreviousProgramNull,
    handleOpenDialog,
    selectState,
    handleOpenDocumentDialog,
    handleOpenFinalizeDialog,
    handleRefresh,
    handleLoading,
}: {
    admission: Admission;
    index: number;
    token: string;
    isRegistrated: boolean;
    firstItem: boolean;
    lastItem: boolean;
    isProgramSelected: boolean;
    isPreviousProgramNull: boolean;
    handleOpenDialog: () => void;
    selectState: [SelectedRow | null, React.Dispatch<React.SetStateAction<SelectedRow | null>>];
    handleOpenDocumentDialog: (serial: number | null, isAdmissionDisabled: boolean) => void;
    handleOpenFinalizeDialog: (admission: Admission) => void;
    handleRefresh: () => void;
    handleLoading: (isloading: boolean) => void;
}) {
    const [_selectedRow, _setSelectedRow] = selectState;
    const [maxDocumentCount, setMaxDocumentCount] = useState<number>(0);
    const [uploadedDocumentCount, setUploadedDocumentCount] = useState<number>(0);
    const [isAdmissionDisabled, setIsAdmissionDisabled] = useState<boolean>(false);
    const [isEveryDocumentExpired, setIsEveryDocumentExpired] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [inactive, setInactive] = useState<boolean>(false);

    const handleNavigate = () => {
        if (isRegistrated) {
            window.open(
                clientEndPoints.admission_mcc_questionnaire.replace(':serial', `${admission.serial}`),
                '_blank',
            );
        } else {
            window.open(
                clientEndPoints.admission_token_questionnaire
                    .replace(':serial', `${admission.serial}`)
                    .replace(':tokenId', `${token}`),
                '_blank',
            );
        }
    };

    const handlePreview = () => {
        if (isRegistrated) {
            window.open(
                clientEndPoints.admission_mcc_questionnaire_preview.replace(':serial', `${admission.serial}`),
                '_blank',
            );
        } else {
            window.open(
                clientEndPoints.admission_token_questionnaire_preview
                    .replace(':serial', `${admission.serial}`)
                    .replace(':tokenId', `${token}`),
                '_blank',
            );
        }
    };

    const getAdmissionIcon = () => {
        if (admission.questionnaireFilledDate && maxDocumentCount === uploadedDocumentCount) {
            return <img src={AdmissionCompletedIcon} />;
        }

        if (admission.applicationDeadline) {
            if (isAdmissionDisabled || new Date(admission.applicationDeadline) < new Date()) {
                return <img src={AdmissionDateExpiredIcon} />;
            }
        }

        return <img src={NotCompleted} />;
    };

    const handleMoveDown = () => {
        const endpoint = isRegistrated
            ? endpoints.admission.moveAdmissionDownMcc(admission.serial)
            : endpoints.admission.moveAdmissionDown(token, admission.serial);
        const request = isRegistrated ? API.post(endpoint) : axios.post(endpoint);
        handleLoading(true);
        request
            .then(() => {
                handleRefresh();
                dispatch(create({ type: 'success', message: 'Sikeres sorrendmódosítás!' }));
            })
            .catch((error) => {
                handleLoading(false);
                console.error(error);
                dispatch(create({ type: 'error', message: 'Sikertelen sorrendmódosítás!' }));
            });
    };

    const handleMoveUp = () => {
        const endpoint = isRegistrated
            ? endpoints.admission.moveAdmissionUpMcc(admission.serial)
            : endpoints.admission.moveAdmissionUp(token, admission.serial);
        const request = isRegistrated ? API.post(endpoint) : axios.post(endpoint);
        handleLoading(true);
        request
            .then(() => {
                handleRefresh();
                dispatch(create({ type: 'success', message: 'Sikeres sorrendmódosítás!' }));
            })
            .catch((error) => {
                handleLoading(false);
                console.error(error);
                dispatch(create({ type: 'error', message: 'Sikertelen sorrendmódosítás!' }));
            });
    };

    const getDocumentIcon = () => {
        if (maxDocumentCount === uploadedDocumentCount) {
            return <img src={UploadIcon} />;
        }
        if (isEveryDocumentExpired || (isAdmissionDisabled && uploadedDocumentCount === 0)) {
            return <img src={ExpiredIcon} />;
        }
        return <img src={DisabledUploadIcon} />;
    };

    const getQuestionnaireIcon = () => {
        return <img src={isAdmissionDisabled ? ExpiredIcon : FillQuestionnaireIcon} />;
    };

    const getMaxDocumentCount = (docs: Document[]): number => {
        return docs.reduce((maxCount: number, doc: Document) => {
            if (doc.rule === 'required') {
                return maxCount + 1;
            }
            return maxCount;
        }, 0);
    };

    useEffect(() => {
        if (admission.serial) {
            const endpoint = isRegistrated
                ? endpoints.admission.getDocumentsMcc(admission.serial)
                : endpoints.admission.getDocuments(token, admission.serial?.toString());
            const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
            request.then((response) => {
                if (response.data.uploadableDocuments) {
                    setMaxDocumentCount(getMaxDocumentCount(response.data.uploadableDocuments as Document[]));
                    setUploadedDocumentCount(
                        response.data.uploadableDocuments.filter((e: Document) => e.filePath && e.rule === 'required')
                            .length,
                    );
                    const expiredDocuments = response.data.uploadableDocuments.filter(
                        (e: Document) => e.deadline && new Date(convertDate(e.deadline)) < new Date(),
                    );
                    if (expiredDocuments.length === response.data.uploadableDocuments.length) {
                        setIsEveryDocumentExpired(true);
                    }
                    if (admission.applicationDeadline && new Date(admission.applicationDeadline) < new Date()) {
                        setIsAdmissionDisabled(true);
                    }
                }
            });
        }

        if (admission.status === 'inactive') setInactive(true);
    }, [admission, isRegistrated, token, handleRefresh, inactive]);

    return (
        <Grid className={`borderRadius ${AdmissionsListStyle.mobileBaseContainer}`} item container xs={12}>
            <Grid container item sx={{ alignItems: 'center' }}>
                <Serial serial={admission.serial || index + 1} />
                {!firstItem && isProgramSelected && (
                    <IconButton onClick={handleMoveUp}>
                        <ExpandLessIcon className={AdmissionsListStyle.expandIcon} />
                    </IconButton>
                )}

                {!lastItem && isProgramSelected && (
                    <IconButton onClick={handleMoveDown}>
                        <ExpandMoreIcon className={AdmissionsListStyle.expandIcon} />
                    </IconButton>
                )}
            </Grid>
            <Grid item container xs={12}>
                <Grid item container style={{ marginBottom: '0.5rem' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: admission.trainingProgram ? 700 : 400 }}>
                        {admission.trainingProgram || 'Még nem választottál ki képzést.'}
                    </Typography>
                    {(((!isPreviousProgramNull || firstItem) &&
                        !admission.isFinalized &&
                        admission.editable &&
                        !isAdmissionDisabled) ||
                        admission.deletable) && (
                        <ModeIcon onClick={handleOpenDialog} className={AdmissionsListStyle.modeIcon} />
                    )}
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{admission.campus}</Typography>
                </Grid>
                <Grid item container xs={12}>
                    {admission.trainingProgram ? (
                        inactive ? (
                            <Typography className={`${AdmissionsStyle.bold} ${AdmissionsListStyle.mobileButtonText}`}>
                                A képzést inaktiválták. Töröld a jelentkezésed vagy válassz másik képzést!
                            </Typography>
                        ) : (
                            <>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.mobileButton}`}
                                    onClick={() => handleOpenDocumentDialog(admission.serial, isAdmissionDisabled)}
                                >
                                    <Typography
                                        className={`${AdmissionsStyle.bold}`}
                                        sx={{
                                            fontSize: '12px',
                                            marginRight: '0.5rem',
                                        }}
                                    >
                                        Dokumentumok feltöltése
                                    </Typography>
                                    {getDocumentIcon()}
                                </Grid>
                                {isProgramSelected && (
                                    <Grid item sx={{ marginBottom: '0.5rem' }}>
                                        <Typography
                                            className={`${AdmissionsListStyle.mobileButtonText} ${AdmissionsListStyle.helper}`}
                                        >
                                            Feltöltendő: {uploadedDocumentCount}/{maxDocumentCount} kész
                                        </Typography>
                                    </Grid>
                                )}
                            </>
                        )
                    ) : (
                        <Grid
                            item
                            container
                            xs={12}
                            className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.mobileButton} ${
                                isPreviousProgramNull ? AdmissionsListStyle.helper : ''
                            }`}
                            sx={{
                                cursor: isPreviousProgramNull ? 'default' : 'pointer',
                            }}
                            onClick={() => {
                                if (!isPreviousProgramNull) {
                                    handleOpenDialog();
                                }
                            }}
                        >
                            <Typography className={`${AdmissionsStyle.bolder} ${AdmissionsListStyle.mobileButtonText}`}>
                                Képzés hozzáadása
                            </Typography>
                            <img src={isPreviousProgramNull ? AddTrainingProgramDisabled : AddTrainingProgram} />
                        </Grid>
                    )}
                    {!inactive && admission.trainingProgram && admission.questionnaireFilledDate === null && (
                        <Grid
                            item
                            container
                            xs={12}
                            className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.mobileButton}`}
                            sx={{
                                cursor: isAdmissionDisabled ? 'default' : 'pointer',
                            }}
                            onClick={() => {
                                if (!isAdmissionDisabled) {
                                    handleNavigate();
                                }
                            }}
                        >
                            <Typography className={`${AdmissionsStyle.bolder} ${AdmissionsListStyle.mobileButtonText}`}>
                                Felvételi űrlap kitöltése
                            </Typography>
                            {getQuestionnaireIcon()}
                        </Grid>
                    )}
                    {!inactive && admission.trainingProgram && admission.questionnaireFilledDate && (
                        <Grid
                            item
                            container
                            xs={12}
                            className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.mobileButton}`}
                            onClick={handlePreview}
                        >
                            <Typography className={`${AdmissionsStyle.bolder} ${AdmissionsListStyle.mobileButtonText}`}>
                                Felvételi űrlap megtekintése
                            </Typography>
                            <RemoveRedEyeIcon className={AdmissionsStyle.checkGreen} />
                        </Grid>
                    )}
                    {admission.canFinalize && (
                        <Grid
                            item
                            container
                            xs={12}
                            className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.mobileFinalizeButtonContainer}`}
                            onClick={() => handleOpenFinalizeDialog(admission)}
                        >
                            <Typography
                                className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.mobileFinalizeButton}`}
                            >
                                Jelentkezés véglegesítése
                            </Typography>
                            <CheckIcon className={AdmissionsStyle.checkGreen} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {!inactive && isProgramSelected && (
                <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    className={`${AdmissionsStyle.contentCenter} ${AdmissionsListStyle.mobileRightPanelContainer}`}
                >
                    <Grid item>
                        <Typography className={AdmissionsListStyle.mobileRightPanel}>Jelentkezési határidő:</Typography>
                        <Typography className={AdmissionsListStyle.mobileRightPanel}>
                            {admission.applicationDeadline?.replaceAll('-', '.') || '-'}
                            {admission.applicationDeadline &&
                                new Date(admission.applicationDeadline) < new Date() &&
                                ' (Lejárt)'}
                        </Typography>
                    </Grid>
                    <Grid item>{getAdmissionIcon()}</Grid>
                </Grid>
            )}
        </Grid>
    );
}
