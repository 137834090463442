import Grid from '@mui/material/Grid';
import { Accordion, CancelButton, Loading, Page } from '@silinfo/front-end-template';
import { useNavigate } from 'react-router-dom';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ApprovalIcon from '@mui/icons-material/Approval';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import PaidIcon from '@mui/icons-material/Paid';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { makeStyles } from '@mui/styles';
import { StatusRenderer } from './StatusRenderer';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';
import { useParams } from 'react-router-dom';
import { useTenant } from '../../../components/TenantContext';
export const header = (tenant: string | null) => {
    return {
        breadcrumbs: {
            'finance': 'Pénzügyek',
            [clientEndPoints.finance_financial_approvals_list]: 'Pénzügyi jóváhagyások',
            'finance_timeline_show': 'Idővonal megtekintése',
        },
        title: 'Pénzügyi jóváhagyások',
        project: tenant || 'TAR',
    };
};
type RouteParams = {
    id: string;
};
interface TimelineRowProps {
    id: number;
    actor: string;
    action: string;
    time: string;
}

const useStyles = makeStyles(() => ({
    paper: {
        padding: '6px 16px',
    },
}));

function TimelineRow(props: TimelineRowProps) {
    const classes = useStyles();

    function getIcon(action: string) {
        switch (action) {
            case 'wait_to_leader_approval':
                return <ApprovalIcon />;
                break;
            case 'wait_to_un_admin_approval':
                return <HowToRegIcon />;
                break;
            case 'invoice_during_issue':
                return <ReceiptIcon />;
                break;
            case 'waiting_for_payment':
                return <PaymentIcon />;
                break;
            case 'paid':
                return <PaidIcon />;
                break;
        }
    }

    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                {props.time}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">{getIcon(props.action)}</TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Paper className={classes.paper} style={{ padding: '1em' }} elevation={3}>
                    <Typography variant="h6" component={'h2'} pb="0.5em">
                        {StatusRenderer(props.action)}
                    </Typography>
                    <Typography>{props.actor}</Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
}

export default function TimelineShow() {
    const { id } = useParams<RouteParams>() as { id: string };
    const { data, loading } = useFetch<TimelineRowProps[]>(
        endpoints.finance.financialApprovals.timeline.replace('{id}', id),
        '',
    );
    const tenant = useTenant();
    const navigate = useNavigate();

    return (
        <Page header={header(tenant || 'TAR')}>
            <Accordion title="Idővonal megtekintése">
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ overflow: 'auto' }}>
                        {loading ? (
                            <Loading noHeight />
                        ) : data.length === 0 ? (
                            <Grid item container xs={12}>
                                <Grid item xs={12} md={4} lg={6}>
                                    <Typography variant="h6" style={{ marginTop: '8px' }}>
                                        Státusz nem jeleníthető meg.
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Timeline position="alternate">
                                {data.map((elem: TimelineRowProps) => (
                                    <TimelineRow {...elem} key={elem.id} />
                                ))}
                            </Timeline>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <CancelButton
                            onClick={() =>
                                navigate(
                                    sessionStorage.getItem('financialApprovals')
                                        ? -1
                                        : (clientEndPoints.finance_financial_approvals_list as unknown as number),
                                )
                            }
                        >
                            Vissza
                        </CancelButton>
                    </Grid>
                </Grid>
            </Accordion>
        </Page>
    );
}
