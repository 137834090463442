import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import Logo from '../../images/image.svg';
import { theme } from '../../utils/theme';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10000,
        background: 'rgba(0, 0, 0, 0.7)',
    },
    text: {
        display: 'block',
        width: '100%',
    },
    avatar: {
        margin: theme.spacing(3),
        width: 'auto',
        height: '10vh',
    },
    loaderOuterContainer: {
        position: 'relative',
        width: '20vh',
        height: '20vh',
    },
    logo: {
        position: 'absolute',
        width: '80%',
        top: '5vh',
        left: '2vh',
    },
    loaderInnerContainer: {
        position: 'absolute',
        color: theme.palette.primary.main,
    },
}));

export default function MCCLoading() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.loaderOuterContainer}>
                <img className={classes.logo} src={Logo} alt="Logo" />
                <div className={classes.loaderInnerContainer}>
                    <CircularProgress size="22vh" color="inherit" />
                </div>
            </div>
        </div>
    );
}
