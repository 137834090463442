import Error from '@mui/icons-material/Error';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
// eslint-disable-next-line regex/invalid-warn
import { FormikProps } from 'formik';
import { IForm } from '../../../../../../utils/Interfaces/interfaces';
import { contacts } from '../fields';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { FieldGenerator } from '@silinfo/front-end-template';
import { theme } from '../../../../../../utils/theme';
import { memo } from 'react';

interface ContactsProps {
    formikProps: FormikProps<IForm>;
    activeSection: number;
    number: number;
    setActiveSection: (number: number) => void;
    readOnly: boolean;
    loading: boolean;
}

interface ContactsFormProps {
    formikProps: FormikProps<IForm>;
    type: 'primary' | 'secondary';
    readOnly: boolean;
}

const types: Record<'primary' | 'secondary', string> = {
    primary: 'Elsődleges kapcsolattartó',
    secondary: 'Másodlagos kapcsolattartó',
};

const contactFields = contacts();

const ContactsForm = ({ type, readOnly, formikProps }: ContactsFormProps) => (
    <>
        <Grid item xs={12}>
            <Typography variant="h6">{types[type]}</Typography>
            <Divider />
        </Grid>
        {contactFields.map(
            (field) =>
                field.name.includes(type) &&
                FieldGenerator({
                    ...field,
                    ...formikProps,
                    props: {
                        ...field.props,
                        disabled: readOnly,
                    },
                }),
        )}
    </>
);

function Contacts(props: ContactsProps) {
    const { formikProps, activeSection, number, setActiveSection, readOnly, loading } = props;
    const hasError = () =>
        contactFields
            .map((field) => field.name)
            .some((field) =>
                Object.keys({ ...formikProps.errors, ...(formikProps.status?.errors || {}) }).includes(field),
            );
    if (loading) return <Skeleton height={35} />;
    return (
        <Accordion expanded={activeSection === number} onChange={() => setActiveSection(number)}>
            <AccordionSummary
                expandIcon={
                    <ExpandMore
                        style={{
                            fontSize: 'xx-large',
                            color: theme.palette.secondary.contrastText,
                        }}
                    />
                }
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                }}
            >
                Kapcsolattartók {hasError() && <Error style={{ marginLeft: '2px' }} color="error" />}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={4}>
                    <ContactsForm type="primary" formikProps={formikProps} readOnly={readOnly} />
                    <ContactsForm type="secondary" formikProps={formikProps} readOnly={readOnly} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

const propsAreEquals = (prev: ContactsProps, next: ContactsProps): boolean => {
    const names = contactFields.map((f) => f.name);

    const equalizationArray = names.map(
        (name) =>
            JSON.stringify(prev.formikProps?.values[name]) === JSON.stringify(next.formikProps?.values[name]) &&
            JSON.stringify(prev.formikProps?.errors[name]) === JSON.stringify(next.formikProps?.errors[name]) &&
            JSON.stringify(prev.formikProps?.touched[name]) === JSON.stringify(next.formikProps?.touched[name]) &&
            prev.loading === next.loading &&
            prev.activeSection === next.activeSection,
    );

    return equalizationArray.every((e) => e);
};

const MemoizedContacts = memo(Contacts, propsAreEquals);

export default MemoizedContacts;
