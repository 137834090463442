import Grid from '@mui/material/Grid';
import {
    Accordion,
    CancelButton,
    Checkbox,
    Link,
    Page,
    Select,
    SubmitButton,
    TextField,
} from '@silinfo/front-end-template';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import MCCLoading from '../../../../components/MCCLoading';
import { create } from '../../../../store/notification';
import campusesService, { ICampusesForm, initialForm } from '../../../../services/masterData/campuses';
import Address from '../../../../components/Form/Address';
import { transformApiErrors } from '../../../../utils/AppConst';
import useEntityOptions from '../../../../utils/useEntityOptions';
import countriesService from '../../../../services/masterData/countriesService';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { useTenant } from '../../../../components/TenantContext';

const FormSchema = Yup.object().shape({
    name: Yup.string().max(128, 'A megengedett karakterek száma maximum 128').required('Kötelező megadni'),
    shortName: Yup.string().max(16, 'A megengedett karakterek száma maximum 16').required('Kötelező megadni'),
    active: Yup.bool().required('Kötelező megadni.'),
    country: Yup.string().max(128, 'A megengedett karakterek száma maximum 128').required('Kötelező megadni'),
    address: Yup.object({
        city: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
        houseNumber: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
        postCode: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
        streetName: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
        streetType: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
    }),
});

export default function CampusForm({ readOnly }: { readOnly?: boolean }) {
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [form, setForm] = useState<ICampusesForm>(initialForm);
    const { campusId } = useParams();
    const dispatch = useDispatch();
    const countries = useEntityOptions(countriesService, 'countryName', '@id');
    const tenant = useTenant();

    useEffect(() => {
        if (campusId) {
            setPageLoading(true);
            campusesService
                .get(campusId)
                .then((response: AxiosResponse) => {
                    const data = response.data;
                    setForm(() => ({
                        name: data.name,
                        shortName: data.shortName,
                        active: data.active,
                        country: data?.country ? data?.country['@id'] : null,
                        address: data.address,
                    }));
                })
                .catch(console.error)
                .finally(() => setPageLoading(false));
        }
    }, [campusId]);

    const handleSubmit = (form: ICampusesForm, { setErrors }: FormikHelpers<ICampusesForm>) => {
        setFormLoading(true);
        (campusId ? campusesService.update(campusId, form) : campusesService.create(form))
            .then(() =>
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                        redirect: clientEndPoints.md_general_campuses_list,
                    }),
                ),
            )
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => setFormLoading(false));
    };

    const title = campusId ? form.name + ' megtekintése' : 'Campus megtekintése';

    const header = {
        project: tenant || 'TAR',
        title: 'Campusok',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
            form: title,
        },
    };

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title} defaultExpanded disableClosing>
                <Formik initialValues={form} onSubmit={handleSubmit} validationSchema={FormSchema}>
                    {({ touched, errors, values, setFieldValue }) => {
                        return (
                            <FormikForm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="name">
                                            <TextField
                                                label="Név"
                                                name="name"
                                                error={!!(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                                value={values.name}
                                                onChange={(e) => setFieldValue('name', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="shortName">
                                            <TextField
                                                label="Rövid név"
                                                name="shortName"
                                                error={!!(touched.shortName && errors.shortName)}
                                                helperText={touched.shortName && errors.shortName}
                                                value={values.shortName}
                                                onChange={(e) => setFieldValue('shortName', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Field as="div" field="country">
                                            <Select
                                                label="Ország"
                                                name="country"
                                                error={!!(touched.country && errors.country)}
                                                helperText={touched.country && errors.country}
                                                options={countries}
                                                onChange={(e) => setFieldValue('country', e.target.value)}
                                                value={values.country}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Address
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        disabled={readOnly}
                                    />
                                    <Grid item xs={12}>
                                        <Checkbox
                                            label="Aktív"
                                            checkbox={{
                                                checked: values.active,
                                                name: 'name',
                                                onChange: () => {
                                                    setFieldValue('active', !values.active);
                                                },
                                                disabled: readOnly,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item container xs={12} spacing={2}>
                                        {!readOnly && (
                                            <Grid item>
                                                <SubmitButton loading={formLoading} type="submit">
                                                    Mentés
                                                </SubmitButton>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Link to={clientEndPoints.md_general_campuses_list}>
                                                <CancelButton>Mégsem</CancelButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}
