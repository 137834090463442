import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

type DateStateContextType = [Date, Dispatch<SetStateAction<Date>>];

const defaultContext: DateStateContextType = [
    new Date(),
    () => console.error('No context provider was set above this element'),
];

const DateStateContext = createContext<DateStateContextType>(defaultContext);

interface DateStateContextProviderProps {
    children: React.ReactNode;
    selectedDate?: Date;
}

export function DateStateContextProvider(props: DateStateContextProviderProps) {
    const dateState = useState(props.selectedDate ?? new Date());
    return <DateStateContext.Provider value={dateState}>{props.children}</DateStateContext.Provider>;
}

export default DateStateContext;
