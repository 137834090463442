import CopyAll from '@mui/icons-material/CopyAll';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CancelButton } from '@silinfo/front-end-template';
import { FastField, Form as FormikForm, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '../../../../components/Form/Autocomplete';
import coursesService from '../../../../services/masterData/courses';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const initialValues = {
    course: { label: '', value: '' },
};

export default function CopyButton() {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    function CourseDialog() {
        return (
            <DialogBasic open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
                <DialogTitle className={classes.title}>{'Kurzusmásolás'}</DialogTitle>
                <div>
                    <DialogContent>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={() => {
                                //TODO: remove onsubmit
                            }}
                        >
                            {(formikProps) => {
                                return (
                                    <FormikForm>
                                        <>
                                            <Grid item xs={12} sm={12}>
                                                <FastField as="div" name="course">
                                                    <Autocomplete
                                                        getter={coursesService.listAllCoursesByTerm}
                                                        textFieldProps={{ label: 'Kurzusok keresése...' }}
                                                        errorMessage={formikProps.errors.course?.label || ''}
                                                        autocompleteProps={{
                                                            value: formikProps.values.course.value || '',
                                                            onChange: (_, v) => {
                                                                formikProps.setFieldValue('course', v || initialValues);
                                                            },
                                                        }}
                                                        createOptions={(users: { value: number; label: string }[]) =>
                                                            users.map((user) => ({
                                                                value: user.value.toString(),
                                                                label: user.label,
                                                            }))
                                                        }
                                                    />
                                                </FastField>
                                            </Grid>
                                            <Grid item container spacing={2} justifyContent="flex-end">
                                                <Grid item>
                                                    <LoadingButton
                                                        disabled={!formikProps.values.course.value}
                                                        variant="contained"
                                                        type="submit"
                                                        onClick={() => {
                                                            navigate('new?copyfrom=' + formikProps.values.course.value);
                                                        }}
                                                    >
                                                        Másolás
                                                    </LoadingButton>
                                                </Grid>
                                                <Grid item>
                                                    <CancelButton onClick={() => setOpenDialog(!openDialog)}>
                                                        Mégse
                                                    </CancelButton>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </DialogContent>
                </div>
            </DialogBasic>
        );
    }

    const handleToggle = () => {
        setOpenDialog((prevOpen) => !prevOpen);
    };

    return (
        <Grid item>
            <CourseDialog />
            <Button color="success" variant="outlined" startIcon={<CopyAll />} onClick={handleToggle}>
                Kurzus másolása
            </Button>
        </Grid>
    );
}
