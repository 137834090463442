import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Input } from '@silinfo/front-end-template';
import { Option } from '../../../../utils/AppConst';
import { SemesterActiveCellRenderer } from '../../../../utils/AppConstElements';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { ICourseResponse, ISemesterEntity, ISemesterTable, IStudentResponse } from './types';
import StatusCellRenderer from './StatusCellRenderer';

export const columns = [
    { headerName: 'Név', field: 'name' },
    { headerName: 'Azonosító', field: 'processName' },
    {
        headerName: 'Státusz',
        field: 'active',
        align: 'center',
        renderCell: StatusCellRenderer,
    },
    {
        headerName: 'Beiratkozási időszak aktív',
        field: 'enrollmentActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.enrollmentActive, params.row.enrollmentPeriod),
    },
    {
        headerName: 'Kiiratkozási időszak aktív',
        field: 'terminationActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.terminationActive, params.row.terminationPeriod),
    },
    {
        headerName: 'Kiköltözési időszak aktív',
        field: 'moveOutActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.moveOutActive, params.row.moveOutPeriod),
    },
    {
        headerName: 'Kurzusfelvételi időszak aktív',
        field: 'courseEnrollmentActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.courseEnrollmentActive, params.row.courseEnrollmentPeriod),
    },
    {
        headerName: 'Kurzuskiiratkozási időszak aktív',
        field: 'courseTerminationActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.courseEnrollmentActive, params.row.courseTerminationPeriod),
    },
    {
        headerName: 'Értékelési időszak aktív',
        field: 'gradingPeriodActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.gradingPeriodActive, params.row.gradingPeriod),
    },
    {
        headerName: 'Szobabeosztási időszak aktív',
        field: 'roomAllocationActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.roomAllocationActive, params.row.roomAllocationPeriod),
    },
    {
        headerName: 'Átköltözési időszak aktív',
        field: 'relocationActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.relocationActive, params.row.relocationPeriod),
    },
    {
        headerName: 'Felvételi időszak aktív',
        field: 'admissionActive',
        renderCell: (params: GridRenderCellParams<ISemesterTable>) =>
            SemesterActiveCellRenderer(params.row.admissionActive, params.row.admissionPeriod),
    },
    { headerName: 'Műveletek', field: 'operations', cellRenderer: 'btnCellRenderer' },
].map((col) => ({ minWidth: 150, flex: 1, align: 'center', headerAlign: 'center', ...col }));

export function getRowData(data: ISemesterEntity[]): ISemesterTable[] {
    return data?.map((elem: ISemesterEntity) => {
        return {
            id: elem.id,
            name: elem.name || '',
            processName: elem.processName || '',
            active: elem.active,
            activePeriod: `${elem.startDate || ''} - ${elem.endDate || ''}`,
            enrollmentActive: elem.enrollmentActive,
            enrollmentPeriod: `${elem.enrollmentStartDate || ''} - ${elem.enrollmentEndDate || ''}`,
            terminationActive: elem.terminationActive,
            terminationPeriod: `${elem.terminationStartDate || ''} - ${elem.terminationEndDate || ''}`,
            moveOutActive: elem.moveOutActive,
            moveOutPeriod: `${elem.moveOutStartDate || ''} - ${elem.moveOutEndDate || ''}`,
            courseEnrollmentActive: elem.courseEnrollmentActive,
            courseEnrollmentPeriod: `${elem.courseEnrollmentStartDate || ''} - ${elem.courseEnrollmentEndDate || ''}`,
            courseTerminationActive: elem.courseEnrollmentActive,
            courseTerminationPeriod: `${elem.courseTerminationStartDate || ''} - ${
                elem.courseTerminationEndDate || ''
            }`,
            gradingPeriodActive: elem.gradingPeriodActive,
            gradingPeriod: `${elem.gradingPeriodStartDate || ''} - ${elem.gradingPeriodEndDate || ''}`,
            relocationActive: elem.relocationActive,
            relocationPeriod: `${elem.relocationStartDate || ''} - ${elem.relocationEndDate || ''}`,
            roomAllocationActive: elem.roomAllocationActive,
            roomAllocationPeriod: `${elem.roomAllocationStartDate || ''} - ${elem.roomAllocationEndDate || ''}`,
            admissionPeriod: `${elem.admissionFrom || ''} - ${elem.admissionTo || ''}`,
            admissionActive: elem.admissionActive,
            closed: elem.closed,
        };
    });
}

export const header = (tenant: string | null) => {
    return {
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            'semesters': 'Szemeszterek',
        },
        title: 'Szemeszterek',
        project: tenant || 'TAR',
    };
};

function createYearOptions(): Option[] {
    const yearOptions = [] as Option[];
    for (let i = -2; i <= 5; i++) {
        yearOptions.push({
            value: String(new Date().getFullYear() + i),
            label: String(new Date().getFullYear() + i),
        });
    }

    return yearOptions;
}
const yearOptions = createYearOptions();

const intervalNames = [
    'startDate',
    'endDate',
    'enrollmentStartDate',
    'enrollmentEndDate',
    'terminationStartDate',
    'terminationEndDate',
    'moveOutStartDate',
    'moveOutEndDate',
    'courseEnrollmentStartDate',
    'courseEnrollmentEndDate',
    'courseTerminationStartDate',
    'courseTerminationEndDate',
    'gradingPeriodStartDate',
    'gradingPeriodEndDate',
    'roomAllocationStartDate',
    'roomAllocationEndDate',
    'relocationStartDate',
    'relocationEndDate',
    'admissionFrom',
    'admissionTo',
];

const intervalTranslations: Record<string, string> = {
    startDate: 'Szemeszter kezdete',
    endDate: 'Szemeszter vége',
    enrollmentStartDate: 'Beiratkozási időszak kezdete',
    enrollmentEndDate: 'Beiratkozási időszak vége',
    terminationStartDate: 'Kiiratkozási időszak kezdete',
    terminationEndDate: 'Kiiratkozási időszak vége',
    moveOutStartDate: 'Kiköltözési időszak kezdete',
    moveOutEndDate: 'Kiköltözési időszak vége',
    courseEnrollmentStartDate: 'Kurzusfelvételi időszak kezdete',
    courseEnrollmentEndDate: 'Kurzusfelvételi időszak vége',
    courseTerminationStartDate: 'Kurzuskiiratkozási időszak kezdete',
    courseTerminationEndDate: 'Kurzuskiiratkozási időszak vége',
    gradingPeriodStartDate: 'Értékelési időszak kezdete',
    gradingPeriodEndDate: 'Értékelési időszak vége',
    roomAllocationStartDate: 'Szobabeosztási időszak kezdete',
    roomAllocationEndDate: 'Szobabeosztási időszak vége',
    relocationStartDate: 'Átköltözési időszak kezdete',
    relocationEndDate: 'Átköltözési időszak vége',
    admissionFrom: 'Felvételi időszak kezdete',
    admissionTo: 'Felvételi időszak vége',
};

const intervalFields: Input[] = intervalNames.map((name) => ({
    name,
    label: intervalTranslations[name],
    type: 'date',
    format: {
        xs: 12,
        md: 6,
    },
}));

const isCourseResponse = (response: ICourseResponse | IStudentResponse): response is ICourseResponse => {
    return (response as ICourseResponse).course !== undefined;
};

const PAGE_SIZE = 10;

export { intervalFields, yearOptions, isCourseResponse, PAGE_SIZE };
