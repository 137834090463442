import { theme } from '../../utils/theme';
import { CounterProps } from './types';
import { CSSProperties } from 'react';
import { Theme } from '@mui/material/styles';

const newTheme: Theme = {
    ...theme,
    palette: {
        ...theme.palette,
        info: {
            ...theme.palette.info,
            main: '#0288d1',
        },
    },
};

type CounterBadgeProps = Omit<CounterProps, 'label'> & { size?: 'small' | 'medium' };

const CounterBadge = ({ value, color, size }: CounterBadgeProps) => {
    const themeColor = newTheme.palette[color];
    const isSmall = size === 'small';
    const style: CSSProperties = {
        backgroundColor: isSmall ? theme.palette.success.main : themeColor.main,
        color: 'white',
        padding: '8px',
        borderRadius: '50px',
        width: isSmall ? '12px' : '50px',
        height: isSmall ? '12px' : '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
        fontSize: isSmall ? '10px' : '30px',
        visibility: value === 0 && isSmall ? 'hidden' : 'visible',
    };

    return (
        <div style={style} className="borderRadius">
            {isSmall ? '' : value}
        </div>
    );
};

export default CounterBadge;
