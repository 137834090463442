import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { ICourse } from '../types';
import { smallTableTranslations } from '../utils';
import Header from './Header';
import Row from './Row';

export default function SmallTable({ rows, print }: { rows: ICourse[]; print?: boolean }) {
    const Core = (
        <Table>
            <Header print={print} />
            <TableBody>
                {rows.length > 0 ? (
                    rows.map((row) => <Row key={row.id} print={print} {...row} />)
                ) : (
                    <TableCell align="center" colSpan={Object.keys(smallTableTranslations).length}>
                        <i>Nincs megjeleníthető kurzus.</i>
                    </TableCell>
                )}
            </TableBody>
        </Table>
    );

    if (print) return Core;

    return <TableContainer component={Paper}>{Core}</TableContainer>;
}
