import { useCallback } from 'react';
import PreviewTemplate from '../../../components/PreviewTemplate';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { endpoints } from '../../../utils/endPoints';
import { useParams } from 'react-router-dom';
import API from '../../../api';
import axios from 'axios';

export default function Filler() {
    const { tokenId, serial } = useParams() as { tokenId: string; serial: string };
    const isRegistrated = window.location.pathname.includes('/mcc');
    const endpoint = isRegistrated
        ? endpoints.admission.getQuestionnaireMcc(serial)
        : endpoints.admission.getQuestionnaireToken(tokenId, serial);
    const handleSubmit = useCallback(
        async (values: IForm) => {
            const submitEndpoint = isRegistrated
                ? endpoints.admission.saveQuestionnaireMcc(serial)
                : endpoints.admission.saveQuestionnaireToken(tokenId, serial);

            const request = isRegistrated
                ? API.post(submitEndpoint, {
                      formData: values,
                  })
                : axios.post(submitEndpoint, {
                      formData: values,
                  });
            request.then(() => localStorage.setItem('refresh', 'refresh'));
        },
        [isRegistrated, serial, tokenId],
    );
    return (
        <PreviewTemplate
            url={endpoint}
            keyPrefix={`admission_fill_${tokenId}_${serial}`}
            type="selfEvaluation"
            onSubmit={handleSubmit}
        />
    );
}
