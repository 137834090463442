import { useParams } from 'react-router-dom';
import PreviewTemplate from '../../components/PreviewTemplate';
import { endpoints } from '../../utils/endPoints';

const keyPrefix = 'courseEvaluationReportPreview';

export default function CourseEvaluationReportPreview() {
    const { courseId, studentId } = useParams<{ courseId: string; studentId: string }>();

    if (!courseId || !studentId) return null;

    return (
        <PreviewTemplate
            type="selfEvaluation"
            url={endpoints.courseManagement.courseEvaluationReport.questionnaireFillsByCourseAndStudent(
                courseId,
                studentId,
            )}
            keyPrefix={keyPrefix}
        />
    );
}
