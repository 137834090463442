import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import DropdownChip from '../../../../../components/DropdownChip';
import CardActions from '@mui/material/CardActions';
import { activeColorByRating, getIconByRating, notCountedText, ratingTextByRating } from '../utils';
import { ViewProps } from './types';
import CourseDialog from '../../CourseDialog';
import FillQuestionnaireButton from '../FillQuestionnaireButton';

export default function MobileView(props: ViewProps) {
    const { notCounted, pointsBeforeEvaluation, pointsAfterEvaluation, courseTypeCounted } = props;
    const getPoint = (val: number) => (notCounted ? '-' : val || '-');
    const point = getPoint(props.evaluation ? +pointsAfterEvaluation : +pointsBeforeEvaluation);
    const pointPostFix =
        ' ' + (props.scoringType === 'point' ? 'pont' : 'garas') + (notCounted && !courseTypeCounted ? '*' : '');
    const needPostFix = point !== '-';
    const pointToDisplay = needPostFix ? point + pointPostFix : point;
    const activeColor = `rgb(${activeColorByRating(props.evaluation || '')})`;
    const ratingText = ratingTextByRating(props.evaluation || '');
    const Icon = getIconByRating(props.evaluation || '');
    return (
        <Grid item xs={12}>
            <Card elevation={6} sx={courseTypeCounted && notCounted ? { backgroundColor: '#eee' } : {}}>
                <CardHeader
                    title={
                        <Grid container alignItems="center" justifyContent="space-between" paddingBottom="4px">
                            <Grid item>{props.courseCode}</Grid>
                            <Grid item xs={4} container alignItems="center" justifyContent="flex-end" spacing={1}>
                                {props.availableQuestionnaire ? (
                                    <Grid item>
                                        <FillQuestionnaireButton
                                            isAnonymous={props.questionnaireIsAnonymous}
                                            id={props.id}
                                        />
                                    </Grid>
                                ) : null}
                                <Grid item>
                                    <CourseDialog {...props} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    subheader={props.courseName}
                />
                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            {notCounted ? (
                                <DropdownChip label={<i>{pointToDisplay}</i>}>
                                    <i>{notCountedText}</i>
                                </DropdownChip>
                            ) : (
                                <Chip label={pointToDisplay} color="secondary" />
                            )}
                        </Grid>
                        <Grid item>
                            <Chip
                                icon={Icon && <Icon color="inherit" />}
                                label={ratingText || 'Nincs értékelés'}
                                sx={
                                    activeColor
                                        ? {
                                              backgroundColor: activeColor,
                                              color: 'white',
                                          }
                                        : {}
                                }
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions></CardActions>
            </Card>
        </Grid>
    );
}
