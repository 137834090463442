import { Option } from '../../../../utils/AppConst';
import { baseInitialValues } from './utils';
import { AxiosResponse } from 'axios';

export type TBaseData = typeof baseInitialValues;

export type TrefreshCodeVersion = (
    semester?: string | number,
    checkCodeExist?: boolean,
    courseCode?: string,
    courseType?: string | number,
) => Promise<AxiosResponse> | void;

export interface ISemesterOption extends Option {
    active: boolean;
    extraVal?: string;
    start?: string;
    end?: string;
    enrollmentStart?: string;
    enrollmentEnd?: string;
    courseEnrollmentEnd?: string;
    courseEnrollmentStart?: string;
}

export const TYPE_IMMEDIATE = 'immediate';
export const TYPE_SCHEDULED = 'scheduled';

export type Timing = 'immediate' | 'scheduled';

export const timingOptions: Option[] = [
    { value: TYPE_IMMEDIATE, label: 'Azonnali' },
    { value: TYPE_SCHEDULED, label: 'Ütemezett' },
];

export const deadlineOptions: Option[] = [
    { value: '0', label: 'Nincs' },
    { value: '1', label: 'Van' },
];
