import axios from 'axios';
import instance from '../../api';
import { store } from '../../store';
import { setUnseenNotificationsNumber } from '../../store/auth';
import { getJWTToken } from '../../utils/AuthHelper';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';

const setUnseenNotifications = async () => {
    const AUTH_TOKEN = getJWTToken();
    const promise = await axios.get<{ unseenSystemMessagesCount: number }>(endpoints.seen, {
        headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
        },
    });
    store.dispatch(setUnseenNotificationsNumber(promise.data.unseenSystemMessagesCount));
    return promise;
};

const markAsSeen = (id: number[]) => instance.post(endpoints.seen, { id });
const markAsUnSeen = (id: number[]) => instance.post(endpoints.unseen, { id });
const markAllAsSeen = () => instance.post(endpoints.allSeen);
const markAllAsUnSeen = () => instance.post(endpoints.allUnSeen);

const systemMessagesService = {
    ...crudTemplate(endpoints.systemMessages),
    setUnseenNotifications,
    markAsSeen,
    markAsUnSeen,
    markAllAsSeen,
    markAllAsUnSeen,
};

export default systemMessagesService;
