import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid';

export default function StatusCellRenderer({ params }: { params: GridRenderCellParams }) {
    if (params.row?.status === 'used') {
        return (
            <Tooltip title="Használatban">
                <Chip color="primary" label="Használatban" />
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title="Nincs használatban">
                <Chip color="secondary" label="Nincs használatban" />
            </Tooltip>
        );
    }
}
