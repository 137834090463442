import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid, { GridProps } from '@mui/material/Grid';
import Title from './Title';

export interface TabCardProps {
    title: string | JSX.Element;
    content: JSX.Element;
    cardProps?: CardProps;
    format?: GridProps;
    headerStyle?: GridProps;
}

export default function TabCard({ title, content, cardProps, format, headerStyle }: TabCardProps) {
    return (
        <Grid item {...(format || { xs: 12, md: 6 })}>
            <Card {...cardProps}>
                <CardContent>
                    <Grid container spacing={2}>
                        {title && (
                            <Grid item xs={12} {...headerStyle}>
                                <Title>{title}</Title>
                            </Grid>
                        )}
                        {content}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}
