import Membership from '../../../pages/Administration/Certificates/Membership';

export const membership = {
    path: 'membership',
    children: [
        {
            path: '',
            element: <Membership />,
            name: 'admin_certificates_membership',
        },
    ],
};
