import { GridColDef } from '@mui/x-data-grid';
import { formatNumberToThousands } from '../../../utils/AppConst';
import { IMeasurements } from './types';

const formatNumberToAmount = (value: number | null, currency?: string | undefined) =>
    formatNumberToThousands(value || 0) + ' ' + currency;

const translations: Record<keyof IMeasurements, string> = {
    all: 'Összes',
    project: 'Projekt',
    professional: 'Szakmai',
};

const columns = (currency?: string): GridColDef[] => [
    {
        headerName: 'Azonosító',
        field: 'id',
        flex: 0.5,
        minWidth: 100,
    },
    {
        headerName: 'Megnevezés',
        field: 'name',
        flex: 1,
        minWidth: 200,
    },
    {
        headerName: 'Összeg',
        field: 'amount',
        valueGetter: (params) => formatNumberToThousands(params.value || 0) + ' ' + currency,
        flex: 0.5,
        minWidth: 100,
    },
    {
        headerName: 'Kifizetés dátuma',
        field: 'completionDate',
        flex: 0.5,
        minWidth: 120,
    },
];

export { columns, formatNumberToAmount, translations };
