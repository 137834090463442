import CalendarToday from '@mui/icons-material/CalendarToday';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Paper } from '../../Paper';
import { HiddenDiv } from './HiddenDiv';
import { AlignCenterAbsolutely } from './AlignCenterAbsolutely';
import { Points } from './Points';
import { RowProps } from './types';

export const DesktopView = ({ handleTodayClick, ...props }: RowProps & { handleTodayClick: () => void }) => (
    <>
        <Grid item xs={5}>
            <Paper style={{ textAlign: 'left', position: 'relative' }}>
                <HiddenDiv>
                    <Points {...props} />
                </HiddenDiv>
                <AlignCenterAbsolutely>{props.name}</AlignCenterAbsolutely>
            </Paper>
        </Grid>
        <Grid item xs={2}>
            <Paper>
                <div>{props.completed.point} pont</div>
                <div>{props.completed.copper} garas</div>
            </Paper>
        </Grid>
        <Grid item xs={2}>
            <Paper>
                <Points {...props} />
            </Paper>
        </Grid>
        <Grid item xs={2}>
            <Paper style={{ position: 'relative' }}>
                <HiddenDiv>
                    <Points {...props} />
                </HiddenDiv>
                <AlignCenterAbsolutely alignCenter>
                    <div style={{ textAlign: 'center' }}>{props.minToBeCompleted} pont</div>
                </AlignCenterAbsolutely>
            </Paper>
        </Grid>
        <Grid item xs={1} container alignItems="center">
            {props.id ? (
                <IconButton color="primary" onClick={handleTodayClick}>
                    <CalendarToday />
                </IconButton>
            ) : (
                ''
            )}
        </Grid>
    </>
);
