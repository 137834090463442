import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import OperationCellRenderer from './OperationCellRenderer';
import { Dispatch, SetStateAction } from 'react';

export const tableColumns = (setTableLoading: Dispatch<SetStateAction<boolean>>, fetchData: () => void): GridColDef[] =>
    [
        {
            headerName: 'Tenant',
            field: 'tenant',
        },
        {
            headerName: 'Campus',
            field: 'campus',
        },
        {
            headerName: 'Képzés szint',
            field: 'trainingLevel',
        },
        {
            headerName: 'Képzési program',
            field: 'trainingProgram',
        },
        {
            headerName: 'Évfolyam',
            field: 'collegeYear',
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (props: GridRenderCellParams) => (
                <OperationCellRenderer {...props} setTableLoading={setTableLoading} fetchData={fetchData} />
            ),
            sortable: false,
        },
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));
