import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import API from '../../../../api';
import { endpoints } from '../../../../utils/endPoints';
import { FinalizeDialogProps, VersionInfo } from '../types';
import AdmissionsDialogsStyle from './AdmissionsDialogs.module.scss';
import axios from 'axios';

export default function FinalizeDialog({ openState, token, serialState, refresh, admission }: FinalizeDialogProps) {
    const [open, setOpen] = openState;
    const [versionInfo, setVersionInfo] = useState<VersionInfo | null>(null);
    const [currentSerial, setCurrentSerial] = serialState;
    const handleClose = (force = true) => {
        if (force) {
            refresh();
        }
        setOpen(false);
        setCurrentSerial(null);
    };
    const [loading, setLoading] = useState<boolean>(false);
    const [content, setContent] = useState<string>('A');

    useEffect(() => {
        if (
            versionInfo?.versionId &&
            admission?.finalizedPrivacyAcceptedVersion &&
            !loading &&
            versionInfo?.versionId === admission?.finalizedPrivacyAcceptedVersion
        ) {
            setContent('B');
        }
    }, [versionInfo, admission, loading]);

    const handleFinalize = async () => {
        setLoading(true);
        const endpoint = endpoints.admission.finalize(
            token === 'mcc' ? 'me' : token,
            admission?.serial?.toString() || '',
        );

        try {
            const request =
                token === 'mcc'
                    ? API.post(endpoint, {
                          acceptVersion: versionInfo?.versionId ?? 0,
                          finalize: content === 'B' ? 1 : 0,
                      })
                    : axios.post(endpoint, {
                          acceptVersion: versionInfo?.versionId ?? 0,
                          finalize: content === 'B' ? 1 : 0,
                      });

            await request;
            if (content === 'B') {
                setOpen(false);
                refresh();
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            const endpoint = endpoints.admission.getPrivacy(
                token === 'mcc' ? 'me' : token,
                admission?.serial?.toString() || '',
            );

            const request = token === 'mcc' ? API.get(endpoint) : axios.get(endpoint);
            request
                .then((response) => {
                    setVersionInfo(response.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [currentSerial, token, admission, open, setVersionInfo]);

    return (
        <Modal open={open}>
            <Grid
                container
                className={`${AdmissionsDialogsStyle.finalizeModal}`}
                sx={{
                    maxWidth: content === 'A' ? '900px' : '500px',
                }}
            >
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={64} />
                    </div>
                ) : (
                    <Grid container>
                        <Grid item container xs={12} sx={{ justifyContent: 'center' }}>
                            <Typography sx={{ fontSize: '24px', fontWeight: 700, marginBottom: '1rem' }}>
                                {content === 'A' && 'Adatkezelési nyilatkozat'}
                                {content === 'B' && 'Megerősítés'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {content === 'A' && (
                                <div
                                    style={{ padding: '1rem' }}
                                    dangerouslySetInnerHTML={{ __html: versionInfo?.versionText ?? '' }}
                                ></div>
                            )}
                            {content === 'B' && (
                                <FinalizeDialogConfirm acceptProgram={versionInfo?.acceptProgram ?? ''} />
                            )}
                        </Grid>

                        <Grid item container className={`${AdmissionsDialogsStyle.finalizeModalButtons}`}>
                            <Button
                                onClick={() => handleClose(false)}
                                variant="outlined"
                                sx={{ marginRight: '0.75rem' }}
                            >
                                Mégsem
                            </Button>
                            {content === 'A' && (
                                <Button
                                    onClick={() => {
                                        handleFinalize();
                                        setContent('B');
                                    }}
                                    variant="contained"
                                    sx={{ marginLeft: '0.75rem' }}
                                    disabled={false}
                                >
                                    Elfogadom
                                </Button>
                            )}
                            {content === 'B' && (
                                <Button
                                    onClick={handleFinalize}
                                    variant="contained"
                                    sx={{ marginLeft: '0.75rem', textTransform: 'capitalize' }}
                                    disabled={false}
                                >
                                    Véglegesítés
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
}

function FinalizeDialogConfirm({ acceptProgram }: { acceptProgram: string }) {
    return (
        <Grid>
            Biztosan véglegesíteni szeretnéd a jelentkezésedet az alábbi képzésre?
            <br />
            <br />
            <Grid
                sx={{
                    fontWeight: 'bold',
                }}
            >
                {acceptProgram}
            </Grid>
            <br />A művelet nem vonható vissza!
        </Grid>
    );
}
