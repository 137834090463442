import { AxiosResponse } from 'axios';
import { TParentInput } from '../../../../../../components/Form/DependentSelectInputGenerator';
import trainingLevelsService from '../../../../../../services/masterData/trainingLevels';
import trainingProgramsService from '../../../../../../services/masterData/trainingPrograms';
import { dependentSelectOptionsGetterArchive } from '../../../../../../utils/AppConst';
import { TDependentSelect } from '../../../../../../utils/Interfaces/interfaces';

export function dependentSelectOptionsGetter(service: { fetch: (id: string) => Promise<AxiosResponse> }) {
    return async (value: string | number) => {
        const response = await service.fetch(value as string);
        return response.data.map((item: { value: number; label: string }) => {
            return {
                value: item.value,
                label: item.label,
            };
        });
    };
}

const collegeYearInputProps = (disabled: boolean, studentId: string): TDependentSelect => ({
    name: 'collegeYear',
    type: 'select',
    label: 'MCC évfolyam',
    optionsGetter: dependentSelectOptionsGetter({
        fetch: (id: string) => trainingProgramsService.fetchCollegeYearsByTrainingProgramAndStudent(id, studentId),
    }),
    disabled,
    keyOfResponse: 'data',
});

const trainingProgramInputProps = (disabled: boolean, studentId: string): TDependentSelect => ({
    name: 'trainingProgram',
    type: 'select',
    label: 'Képzési program',
    optionsGetter: dependentSelectOptionsGetterArchive(
        {
            fetch: (id: string) => trainingLevelsService.trainingProgramsForStudent(id, studentId),
        },
        'data',
    ),
    children: [collegeYearInputProps(disabled, studentId)],
    disabled,
    keyOfResponse: 'data',
});

export const inputProps = (disabled: boolean, studentId: string): TParentInput => ({
    type: 'select',
    name: 'trainingLevel',
    label: 'Képzési szint',
    children: [trainingProgramInputProps(disabled, studentId)],
    disabled,
});
