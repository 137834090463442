import SystemLogs from '../../../pages/Administration/SystemLog/SystemLog/SystemLogs';

export const systemLogs = {
    path: 'system-logs',
    children: [
        {
            path: '',
            element: <SystemLogs />,
            name: 'admin_sys_logs_list',
        },
    ],
};
