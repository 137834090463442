import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EndpointProvider from '../../../../../../components/EndpointProvider';
import actualUniversityStudiesService from '../../../../../../services/studentServices/actualUniversityStudiesService';
import { endpoints } from '../../../../../../utils/endPoints';
import { IForm } from '../../../../../../utils/Interfaces/interfaces';
import useFetch from '../../../../../../utils/useFetch';
import { useStudies } from '../fields';
import { SectionRow } from '../SectionGenerator';
import ActualUniversityStudiesDialog from './ActualUniversityStudiesDialog';
import ActualUniversityStudiesTable from './ActualUniversityStudiesTable';
import { GetUniversityOptions } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

export default function ActualUniversityStudiesSection() {
    const actualUniversityStudiesFields = useStudies();
    const { id } = useParams();
    const { user } = useSelector((state: RootState) => state.auth);
    const [refresh, setRefresh] = useState(false);
    const { data, loading } = useFetch<SectionRow[]>(
        endpoints.masterData.students.actualUniversityStudies.main.replaceAll('{studentId}', id as string),
        'hydra:member',
        refresh,
    );
    const optionData = GetUniversityOptions();
    const [row, setRow] = useState<SectionRow | undefined>(undefined);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        data.map((row) => {
            row.student = ('' + row.student).split('/')[2];
            row.university = ('' + row.university).split('/').slice(-1)[0];
        });
    }, [data]);

    const handleSubmit = useCallback(
        (form: IForm) =>
            actualUniversityStudiesService.saveActualUniversityStudies(row, form).then(() => {
                setOpen(false);
                setRow(undefined);
                setRefresh(!refresh);
            }),
        [row, refresh],
    );

    const handleCancel = useCallback(() => {
        setRow(undefined);
        setOpen(false);
    }, []);

    const handleClose = useCallback(() => setOpen(!open), [open]);

    return (
        <>
            <ActualUniversityStudiesDialog
                open={open}
                row={row}
                handleSubmit={handleSubmit}
                actualUniversityStudiesFields={actualUniversityStudiesFields}
                optionData={optionData}
                handleCancel={handleCancel}
                id={id}
            />
            {!user.archive && !loading && data.length < 3 && (
                <EndpointProvider endpoints={['md_general_students_edit_detailed']}>
                    <Grid item xs={12} justifyContent="flex-end">
                        <Button color="success" variant="contained" onClick={handleClose}>
                            Egyetemi tanulmány hozzáadása
                        </Button>
                    </Grid>
                </EndpointProvider>
            )}
            <Grid item xs={12} style={{ overflow: 'auto' }}>
                <ActualUniversityStudiesTable
                    allData={data}
                    allLoading={loading}
                    /**
                     * We need to filter out the 'foreign university name out of the fields:
                     */
                    fields={actualUniversityStudiesFields.filter((field) => field.name !== 'foreignUniversity')}
                    setRefresh={setRefresh}
                    setRow={setRow}
                    setOpen={setOpen}
                    optionData={optionData}
                />
            </Grid>
        </>
    );
}
