import { Input } from '@silinfo/front-end-template';
import axios from 'axios';
import { useEffect, useState } from 'react';
import countriesService from '../../../../services/masterData/countriesService';
import universitiesAllService from '../../../../services/masterData/universitiesAll';
import { Option } from '../../../../utils/AppConst';

const countryService = { filter: countriesService.countriesWithUniversities };

export interface ICountry {
    id: string;
    '@id': string;
    countryName: string;
}

export default function useUniversityFields(refresh: boolean): { loading: boolean; fields: Input[] } {
    const [countries, setCountries] = useState<Option[]>([]);
    const [universities, setUniversities] = useState<Option[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios
            .all([countryService.filter(), universitiesAllService.filter({})])
            .then((res) => {
                setCountries(
                    res[0].data['hydra:member'].map((row: ICountry) => ({ value: row['@id'], label: row.countryName })),
                );
                setUniversities(res[1].data.map((row: Option) => ({ value: row.label, label: row.label })));
            })
            .finally(() => setLoading(false));
    }, [refresh]);

    return {
        loading,
        fields: [
            {
                name: 'name',
                label: 'Név',
                type: 'multiselect',
                options: universities,
                format: { xs: 12, md: 4, lg: 4 },
            },
            {
                name: 'shortName',
                label: 'Rövid név',
                format: { xs: 12, md: 4, lg: 4 },
            },
            {
                name: 'country',
                label: 'Ország',
                type: 'multiselect',
                options: countries,
                format: { xs: 12, md: 4, lg: 4 },
            },
        ],
    };
}
