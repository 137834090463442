import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// eslint-disable-next-line regex/invalid-warn
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import coursePrerequisitesService from '../../../../services/masterData/courses/coursePrerequisites/coursePrerequisites';
import { Option } from '../../../../utils/AppConst';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { theme } from '../../../../utils/theme';
import PrerequisiteForm from './PrerequisiteForm';

export interface ICoursePrerequisitePreSubmitForm extends IForm {
    course: string;
    courseReferenceCode: string;
    courseReferenceId: string;
    coursePrerequisite: Option | string;
}

const initialValues: ICoursePrerequisitePreSubmitForm = {
    course: '',
    courseReferenceCode: '',
    coursePrerequisite: '',
    courseReferenceId: '',
};

const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export default function AddPrerequisiteButton({
    title,
    submitBtnTitle,
    opener,
    objectId,
    setTableLoading,
    courseTenant,
}: {
    title: string;
    submitBtnTitle: string;
    opener: JSX.Element;
    objectId?: number;
    setTableLoading: (val: boolean) => void;
    courseTenant: string | null;
}) {
    const { courseId } = useParams() as { courseId: string };
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <div>
                    <DialogContent>
                        <Form
                            hideButtons
                            urlParam="courseId"
                            fields={[]}
                            onSubmit={(form: ICoursePrerequisitePreSubmitForm) => {
                                coursePrerequisitesService
                                    .create(
                                        {
                                            ...form,
                                            coursePrerequisite: form.coursePrerequisite,
                                        },
                                        courseTenant,
                                    )
                                    .then(() => {
                                        setTableLoading(true);
                                        setTableLoading(false);
                                        handleClose();
                                    });
                            }}
                            initialValues={initialValues}
                        >
                            {(props) => (
                                <PrerequisiteForm
                                    submitBtnTitle={submitBtnTitle}
                                    handleClose={handleClose}
                                    objectId={objectId}
                                    courseId={+courseId}
                                    courseTenant={courseTenant}
                                    {...props}
                                />
                            )}
                        </Form>
                    </DialogContent>
                </div>
            </DialogBasic>
        </>
    );
}
