import { crudTemplate } from '../templates';
import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const apiKeysService = {
    ...crudTemplate(endpoints.administration.apiKeys.main),
    update: (id: string | number, form: IForm) => API.post(endpoints.administration.apiKeys.main + '/' + id, form),
};

export default apiKeysService;
