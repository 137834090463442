import { FieldGenerator } from '@silinfo/front-end-template';
import { FormikProps, useFormikContext } from 'formik';
import usersService, { IUsersForm } from '../../../../services/masterData/users';
import { Options } from './Form';
import { Option } from '../../../../utils/AppConst';
import ROLES from '../../../../utils/Roles';
import { useNavigate } from 'react-router-dom';
import { clientEndPoints } from '../../../../utils/clientEndPoints';

interface BaseUsersFormProps {
    props: FormikProps<IUsersForm>;
    options: Options;
    readonly: boolean;
    existsInCentral: boolean;
    setExistsInCentral: React.Dispatch<React.SetStateAction<boolean>>;
    setActivated: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CreateUsersForm({
    props,
    options,
    readonly,
    existsInCentral,
    setExistsInCentral,
    setActivated,
}: BaseUsersFormProps) {
    const navigate = useNavigate();
    const formikProps = useFormikContext<IUsersForm>();
    const { setFieldValue } = formikProps;

    return (
        <>
            {FieldGenerator({
                ...props,
                name: 'lastName',
                label: 'Vezetéknév',
                props: {
                    disabled: readonly || existsInCentral,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'firstName',
                label: 'Keresztnév',
                props: {
                    disabled: readonly || existsInCentral,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'email',
                label: 'E-mail',
                props: {
                    disabled: readonly || existsInCentral,
                    onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === '') {
                            return;
                        }

                        usersService.getCentralUser(e.target.value).then((res) => {
                            // ha létezik a jelnlegi TAR-ban a user átnavigálja a szerkesztés oldalra
                            if (res.data?.id) {
                                navigate(clientEndPoints.md_general_users_edit.replace(':id', res.data.id));
                            }

                            if (res.data?.email) {
                                setExistsInCentral(true);
                                setFieldValue('lastName', res.data?.lastName);
                                setFieldValue('firstName', res.data?.firstName);
                                setFieldValue('email', '');
                                setFieldValue('email', res.data?.email);
                                setFieldValue('mccId', res.data?.mccId);
                                setActivated(res.data?.activated);
                            }
                        });
                    },
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'mccId',
                label: 'MCC azonosító',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'campus',
                label: 'Campus',
                type: 'select',
                options: options.campuses,
                fieldType: 'base',
                props: {
                    disabled: readonly,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'roles',
                label: 'Jogosultságok',
                type: 'multiselect',
                options: options.roles.filter((e: Option) => e.value !== ROLES.STUDENT),
                fieldType: 'base',
                props: {
                    disabled: readonly,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
        </>
    );
}
