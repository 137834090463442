import MasterDataFinance from '../../pages/MasterData/Finance';
import { administrationFees } from './Finance/AdministrationFees';
export const finance = {
    path: 'finance',
    children: [
        {
            path: '',
            element: <MasterDataFinance />,
            name: 'md_finance',
        },
        administrationFees,
    ],
};
