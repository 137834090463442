import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import studentsService from '../../../../../services/masterData/students';
import { theme } from '../../../../../utils/theme';

export default function CertificateInstituteAutocomplete({
    value,
    onChange,
    errorMessage,
    disabled,
}: {
    value: string | null;
    onChange: (value: string | null) => void;
    errorMessage?: string;
    disabled?: boolean;
}) {
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const n = 3;

    const handleInputChange = (_: React.SyntheticEvent<Element, Event>, value: string) => {
        setInputValue(value);
        if (value.length >= n) {
            setLoading(true);
            studentsService
                .instituteSearch(value)
                .then((response: AxiosResponse<{ data: string[] }>) => setOptions(response.data.data))
                .finally(() => setLoading(false));
        }
    };

    return (
        <Grid item xs={12}>
            <Autocomplete
                onInputChange={(_, value) => {
                    handleInputChange(_, value);
                    onChange(value);
                }}
                disabled={disabled}
                onClose={() => setOptions([])}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Intézmény"
                        placeholder={'Írjon be ' + n + ' karaktert a kereséshez'}
                        size="small"
                        helperText={errorMessage}
                        error={!!errorMessage}
                    />
                )}
                options={options}
                noOptionsText={
                    loading
                        ? 'Betöltés...'
                        : inputValue.length >= n
                        ? 'Nincs találat'
                        : 'Írjon be ' + n + ' karaktert a kereséshez'
                }
                ChipProps={{
                    sx: {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        '& .MuiChip-deleteIcon': {
                            color: theme.palette.primary.main,
                        },
                    },
                }}
                value={value}
                filterSelectedOptions
                freeSolo
                openOnFocus
                onChange={(_, value) => onChange(value)}
            />
        </Grid>
    );
}
