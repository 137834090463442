import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { CheckResponse } from './types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface ConfirmationDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    groups: CheckResponse[][];
}

export default function ConfirmationDialog({ openState, groups }: ConfirmationDialogProps) {
    const [open, setOpen] = openState;

    const { submitForm } = useFormikContext();

    const handleClick = useCallback(() => {
        setOpen(false);
        submitForm();
    }, [setOpen, submitForm]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Megerősítés</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>
                <DialogContentText>
                    A hozzáadni kívánt képzési program már szerepel az alábbi csoport{groups.length > 1 ? 'ok' : ''}ban:
                </DialogContentText>
                <DialogContentText style={{ margin: '16px 0' }}>
                    <Table style={{ border: '1px solid #ddd' }}>
                        <TableHead>
                            <TableCell>Campus / Képzési program</TableCell>
                            <TableCell>Csoport neve</TableCell>
                        </TableHead>
                        <TableBody>
                            {groups.map((group) => (
                                <TableRow key={group[0].exist}>
                                    <TableCell>{group[0].exist}</TableCell>
                                    <TableCell>
                                        <ul>
                                            {group.map((g) => (
                                                <li key={g.scholarshipGroupName}>{g.scholarshipGroupName}</li>
                                            ))}
                                        </ul>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContentText>
                <DialogContentText>
                    Biztos, hogy újabb csoporthoz kívánod hozzáadni? Így előfordulhat, hogy egyes hallgatók két
                    ösztöndíjat is kapnak egy ösztöndíjtípusból!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Nem
                </Button>
                <LoadingButton variant="contained" onClick={handleClick}>
                    Igen
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
