import { Accordion, FieldGenerator, Page } from '@silinfo/front-end-template';
import { AxiosError, AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Form from '../../../components/Form/Form';
import MCCLoading from '../../../components/MCCLoading';
import i18n from '../../../i18n';
import settingsService, { ISettingsForm } from '../../../services/administration/settings';
import { create } from '../../../store/notification';
import { transformApiErrors } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { useTenant } from '../../../components/TenantContext';

const FormSchema = Yup.object().shape({
    settingVal: Yup.string().required('Kötelező megadni'),
});

export const SYSTEM_SHUTDOWN_PERIOD_NOTIFICATION = 'SYSTEM_SHUTDOWN_PERIOD_NOTIFICATION';

export default function SettingsForm() {
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [settingForm, setSettingForm] = useState({
        settingVal: '',
        settingKey: '',
        from: '',
        to: '',
    });
    const { settingId } = useParams();
    const dispatch = useDispatch();
    const tenant = useTenant();

    useEffect(() => {
        if (settingId) {
            setPageLoading(true);
            settingsService
                .get(settingId)
                .then((response: AxiosResponse) => {
                    if (response.data.settingKey === SYSTEM_SHUTDOWN_PERIOD_NOTIFICATION) {
                        setSettingForm({
                            settingKey: response.data.settingKey,
                            settingVal: response.data.settingVal,
                            from: response.data.settingVal.split(';')[0]?.replace(' ', 'T'),
                            to: response.data.settingVal.split(';')[1]?.replace(' ', 'T'),
                        });
                    } else {
                        setSettingForm(response.data);
                    }
                })
                .catch(() => dispatch({ type: 'error', message: 'Sikertelen művelet' }))
                .finally(() => setPageLoading(false));
        }
    }, [dispatch, settingId]);

    const handleSubmit = (form: ISettingsForm, { setErrors }: FormikHelpers<ISettingsForm>) => {
        if (form.settingKey === SYSTEM_SHUTDOWN_PERIOD_NOTIFICATION) {
            const from = form.from?.replace('T', ' ');
            const to = form.to?.replace('T', ' ') || null;

            form.settingVal = `${from};${to}`;
        }

        (settingId ? settingsService.changeSettingValue(settingId, form) : settingsService.create(form))
            .then(() =>
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                        redirect: clientEndPoints.admin_settings_list,
                    }),
                ),
            )
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            });
    };

    const title = settingId ? 'Beállítás szerkesztése' : '';

    const header = {
        project: tenant || 'TAR',
        title: 'Beállítások',
        breadcrumbs: {
            admin: 'Adminisztráció',
            [clientEndPoints.admin_settings_list]: 'Beállítások',
            form: title,
        },
    };

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title} defaultExpanded disableClosing>
                <Form
                    fields={[]}
                    initialValues={settingForm}
                    onSubmit={handleSubmit}
                    validationSchema={FormSchema}
                    back={clientEndPoints.admin_settings_list}
                >
                    {(props) => {
                        return (
                            <>
                                {FieldGenerator({
                                    name: 'settingKey',
                                    label: 'Megnevezés',
                                    type: 'text',
                                    format: { xs: 12, md: 4 },
                                    props: {
                                        value: i18n.t('administration.settings.' + settingForm.settingKey),
                                        disabled: true,
                                    },
                                    ...props,
                                })}
                                {settingForm.settingKey !== SYSTEM_SHUTDOWN_PERIOD_NOTIFICATION &&
                                    FieldGenerator({
                                        name: 'settingVal',
                                        label: 'Érték',
                                        type: 'text',
                                        format: { xs: 12, md: 4 },
                                        ...props,
                                    })}
                                {settingForm.settingKey === SYSTEM_SHUTDOWN_PERIOD_NOTIFICATION &&
                                    FieldGenerator({
                                        name: 'from',
                                        label: 'Időszak kezdete',
                                        type: 'datetime-local',
                                        format: { xs: 12, md: 4 },
                                        ...props,
                                    })}
                                {settingForm.settingKey === SYSTEM_SHUTDOWN_PERIOD_NOTIFICATION &&
                                    FieldGenerator({
                                        name: 'to',
                                        label: 'Időszak vége',
                                        type: 'datetime-local',
                                        format: { xs: 12, md: 4 },
                                        ...props,
                                    })}
                            </>
                        );
                    }}
                </Form>
            </Accordion>
        </Page>
    );
}
