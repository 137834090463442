import Grid from '@mui/material/Grid';
import { Accordion, FieldGenerator, Page } from '@silinfo/front-end-template';
import axios from 'axios';
import { FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import usersService, { IUsersForm } from '../../../../services/masterData/users';
import { RootState } from '../../../../store';
import { Option } from '../../../../utils/AppConst';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { Campus } from '../../../../utils/Interfaces/interfaces';
import ROLES from '../../../../utils/Roles';
import { useConditionalUsersFields } from './fields';
import CreateUsersForm from './CreateUsersForm';
import EditUsersForm from './EditUsersForm';
import { endpoints } from '../../../../utils/endPoints';
import API from '../../../../api';
import { useTenant } from '../../../../components/TenantContext';

const URL = clientEndPoints.md_general_users_list;

const initialForm = {
    lastName: '',
    firstName: '',
    email: '',
    campus: '',
    mccId: '',
    roles: [],
    disabled: false,
    roleTrainingProgramProgramId: '',
    roleTrainingLeaderProgramId: '',
    roleEpCoordinatorCampusId: '',
    roleOperatorCampusId: '',
    roleFinanceAdminCampusId: '',
    roleBoardCampusIds: [],
    profilePicture: { origFileName: '', uuidFileName: '' },
    activated: false,
};

const normalizeConnectedEntitiesForFrom = (
    data: Record<keyof IUsersForm, Record<string, string> | string>,
): IUsersForm => {
    Object.keys(data).forEach((key) => {
        const elem = data[key] as Record<string, string>;
        if (elem && typeof elem === 'object' && '@id' in elem) {
            data[key] = elem['@id'];
        }
    });
    return data as IUsersForm;
};

export interface Options {
    roles: Option[];
    campuses: Option[];
    trainingPrograms: Option[];
}

interface ConditionalFormProps {
    options: Options;
    formikProps: FormikProps<IUsersForm>;
    readonly: boolean;
    existsInCentral: boolean;
    activated: boolean;
}

const ConditionalForm: React.FC<ConditionalFormProps> = ({
    options,
    formikProps,
    readonly,
    existsInCentral,
    activated,
}) => {
    const conditionalUsersFields = useConditionalUsersFields(options, readonly, existsInCentral, activated);

    return <>{conditionalUsersFields.map((field) => FieldGenerator({ ...field, ...formikProps }))}</>;
};

export default function UsersForm({ readOnly = false }: { readOnly?: boolean }) {
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [form, setForm] = useState<IUsersForm>(initialForm);
    const { user } = useSelector((state: RootState) => state.auth);
    const { id } = useParams();
    const tenant = useTenant();
    const [existsInCentral, setExistsInCentral] = useState(false);
    const [activated, setActivated] = useState(false);
    const [options, setOptions] = useState<Options>({
        roles: [],
        campuses: [],
        trainingPrograms: [],
    });
    const isEdit = id ? true : false;
    const title = (id: string | undefined, readOnly: boolean) => {
        if (readOnly) {
            return 'Felhasználó megtekintése';
        } else if (id) {
            return form.lastName + ' ' + form.firstName + ' szerkesztése';
        } else {
            return 'Felhasználó hozzáadása';
        }
    };
    const header = {
        project: tenant || 'TAR',
        title: 'Felhasználókezelés',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_users_list]: 'Felhasználókezelés',
            form: title(id, readOnly),
        },
    };

    const allOptions = useCallback(
        (currentProgramIds: string[]) => {
            axios
                .all([
                    usersService.roles(),
                    usersService.campuses(id),
                    usersService.trainingPrograms2(currentProgramIds),
                ])
                .then((res) => {
                    setOptions((prev) => {
                        /**
                         * Sentry bug miatt fix:
                         */
                        if (res[1].data && !Array.isArray(res[1].data)) {
                            res[1].data = Object.values(res[1].data);
                        }

                        if (!id) {
                            const filtered = res[1].data.filter((campus: Campus) => campus.active == true);
                            return {
                                ...prev,
                                roles: res[0].data,
                                campuses: filtered,
                                trainingPrograms: res[2].data,
                            };
                        } else {
                            return {
                                ...prev,
                                roles: res[0].data,
                                campuses: res[1].data,
                                trainingPrograms: res[2].data,
                            };
                        }
                    });
                });
        },
        [id],
    );

    const [existsInOtherTenants, setExistsInOtherTenants] = useState(false);

    useEffect(() => {
        setPageLoading(true);
        const currentProgramIds: string[] = [];

        if (id) {
            usersService.get2(id).then((res) => {
                setForm(normalizeConnectedEntitiesForFrom(res.data));

                if (res.data.roleTrainingLeaderProgramId) {
                    currentProgramIds.push(res.data.roleTrainingLeaderProgramId);
                }
                if (res.data.roleTrainingProgramProgramId) {
                    currentProgramIds.push(res.data.roleTrainingProgramProgramId);
                }

                allOptions(currentProgramIds);

                usersService.getCentralUser(res.data?.email).then((centralRes) => {
                    if (centralRes.data?.tenants && centralRes.data?.tenants.length > 1) {
                        setExistsInOtherTenants(true);
                    }
                    setPageLoading(false);
                });
            });
        } else {
            allOptions(currentProgramIds);
        }

        if (!id) {
            setPageLoading(true);
            axios
                .all([usersService.mccId()])
                .then((res) => {
                    setForm((prev) => ({ ...prev, mccId: res[0].data }));
                })
                .finally(() => setPageLoading(false));
        }
    }, [id, user.id, allOptions]);
    const handleSubmit = (form: IUsersForm) =>
        id
            ? API.patch(endpoints.masterData.users.main + '/' + id + '?activated=' + activated, form)
            : API.post(endpoints.masterData.users.main + '?activated=' + activated, form);

    form.roles = form.roles.filter(function (e: string) {
        return e !== ROLES.USER && e !== ROLES.STUDENT;
    });

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Grid item xs={12} sm={8} md={6}>
                <Accordion title={title(id, readOnly)} defaultExpanded disableClosing>
                    <Form initialValues={form} fields={[]} onSubmit={handleSubmit} back={URL} readonly={readOnly}>
                        {(formikProps) => (
                            <>
                                {isEdit ? (
                                    <EditUsersForm
                                        options={options}
                                        props={formikProps}
                                        readonly={readOnly}
                                        existsInOtherTenants={existsInOtherTenants}
                                        setActivated={setActivated}
                                    />
                                ) : (
                                    <CreateUsersForm
                                        options={options}
                                        props={formikProps}
                                        readonly={readOnly}
                                        existsInCentral={existsInCentral}
                                        setExistsInCentral={setExistsInCentral}
                                        setActivated={setActivated}
                                    />
                                )}

                                <ConditionalForm
                                    options={options}
                                    formikProps={formikProps}
                                    readonly={readOnly}
                                    existsInCentral={existsInCentral}
                                    activated={activated}
                                />
                            </>
                        )}
                    </Form>
                </Accordion>
            </Grid>
        </Page>
    );
}
