import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useState } from 'react';
import PDFDownloader from '../../../../../components/PDFDownloader';
import { handleClick, SelfEvaluationDocumentType } from './DownloadTemplate';
import FormToPrint from './FormToPrint';
import { FormToPrintProps } from './types';

const DownloadButton = ({
    url,
    filename,
    type,
}: {
    url: string;
    filename: string;
    type?: SelfEvaluationDocumentType;
}) => {
    const [loading, setLoading] = useState(false);
    const onClick = useCallback(() => {
        setLoading(true);
        handleClick(url, filename).finally(() => setLoading(false));
    }, [url, filename]);

    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => (
            <FormToPrint type={type || 'selfEvaluation'} {...formToPrintProps} />
        ),
        [type],
    );

    if (loading)
        return (
            <Grid item>
                <CircularProgress size={24} />
            </Grid>
        );

    if (!type) {
        return (
            <Grid item>
                <Tooltip title="Letöltés">
                    <IconButton
                        color="primary"
                        sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                        size="small"
                        onClick={onClick}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={20} /> : <DownloadIcon />}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }

    return (
        <Grid item>
            <Tooltip title="Letöltés">
                <PDFDownloader
                    url={url}
                    ComponentToPrint={FormToPrintByType}
                    DownloadIcon={<DownloadIcon color="primary" />}
                    transform={(elem) => ({
                        row: elem as FormToPrintProps['data'],
                        filename,
                    })}
                    portrait
                />
            </Tooltip>
        </Grid>
    );
};

export default DownloadButton;
