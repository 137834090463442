import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TSelfAssessmentDocumentStatus, TSelfAssessmentPreviousDocumentStatus } from '../types';

const componentMap: Record<TSelfAssessmentDocumentStatus | TSelfAssessmentPreviousDocumentStatus, JSX.Element> = {
    filled: <CheckCircleIcon color="success" />,
    available: <CheckCircleIcon color="success" />,
    none: <BlockIcon color="disabled" />,
    wait_to_fill: <HighlightOffIcon color="error" />,
};

const confirmationTextsByKey: Record<string, string> = {
    selfAssessmentConversation: 'Az önértékelési beszélgetés megtörtént?',
};

const conversationMapper = new Map<boolean | null, TSelfAssessmentDocumentStatus>([
    [true, 'filled'],
    [false, 'wait_to_fill'],
    [null, 'none'],
]);

const statusMapper = new Map<boolean | null, TSelfAssessmentDocumentStatus>([
    [true, 'filled'],
    [false, 'wait_to_fill'],
    [null, 'none'],
]);

const universityIndexMapper = new Map<string | null, TSelfAssessmentDocumentStatus>([
    ['uploaded', 'filled'],
    ['wait_to_upload', 'wait_to_fill'],
    [null, 'none'],
]);

export { componentMap, confirmationTextsByKey, statusMapper, conversationMapper, universityIndexMapper };
