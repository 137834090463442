import GroupManagement from '../../pages/Scholarship/GroupManagement';
import GroupManagementForm from '../../pages/Scholarship/GroupManagement/Form';

export const groupManagement = {
    path: 'group-management',
    children: [
        {
            path: '',
            element: <GroupManagement />,
            name: 'group_management',
        },
        {
            path: ':semesterId/new',
            element: <GroupManagementForm />,
            name: 'group_management_new',
        },
        {
            path: ':semesterId/edit/:id',
            element: <GroupManagementForm />,
            name: 'group_management_edit',
        },
        {
            path: ':semesterId/view/:id',
            element: <GroupManagementForm view />,
            name: 'group_management_view',
        },
    ],
};
