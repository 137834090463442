import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { ThemeProvider } from '@mui/material/styles';
import { FormikListPage, Header, TextField, useFetch } from '@silinfo/front-end-template';
import { useState } from 'react';
import { defaultTheme, Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import { breadcrumbs, header, tableProps } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

export default function CourseCompletions() {
    const [trainingProgram, setTrainingProgram] = useState<Option | null>(null);
    const { data, loading } = useFetch<Option[]>(
        endpoints.masterData.trainingPrograms.options + '?withoutArchive=1&withLevel=1',
        '',
    );
    const { data: campuses } = useFetch<Option[]>(endpoints.administration.courseCompletions.campuses, '');
    const { user } = useSelector((state: RootState) => state.auth);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...headerWithTenant, breadcrumbs }} />
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    <Skeleton height="50px" />
                ) : (
                    <Autocomplete
                        options={data}
                        value={trainingProgram}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => setTrainingProgram(value)}
                        getOptionLabel={(option) => option.label}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Képzési program" />}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {trainingProgram ? (
                    <FormikListPage {...tableProps(trainingProgram.value, campuses, user.archive, tenant)} />
                ) : (
                    <ThemeProvider theme={defaultTheme}>
                        <Alert severity="info">Kérjük, válassz képzési programot az adatok megtekintéséhez</Alert>
                    </ThemeProvider>
                )}
            </Grid>
        </Grid>
    );
}
