import { theme } from '../../../../../../../utils/theme';
import { ICompletionData, ITrainingCompletionData } from '../../types';
import Grid from '@mui/material/Grid';

export default function EnrolledCoursesCellRenderer(row: ITrainingCompletionData | ICompletionData) {
    return (
        <Grid container spacing={1}>
            <Grid
                item
                xs={12}
                style={
                    row.enrolledCourses[1] < row.minToBeCompleted
                        ? { color: theme.palette.warning.main, textAlign: 'center' }
                        : { textAlign: 'center' }
                }
            >
                {row.enrolledCourses[0] === row.enrolledCourses[1]
                    ? row.enrolledCourses[0]
                    : row.enrolledCourses.join('-')}{' '}
                pont
            </Grid>
            <Grid item xs={12} style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {row.enrolledCoursesCopper[0] === row.enrolledCoursesCopper[1]
                    ? row.enrolledCoursesCopper[0]
                    : row.enrolledCoursesCopper.join('-')}{' '}
                garas
            </Grid>
        </Grid>
    );
}
