import Edit from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DownloadButton, Input, Tooltip } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Children, Dispatch, SetStateAction } from 'react';
import EndpointProvider from '../../../../../../components/EndpointProvider';
import { theme } from '../../../../../../utils/theme';
import { exceptionFields, SectionRow } from '../SectionGenerator';
import { DeleteButton } from './DeleteButton';
import { cellOrder } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

export default function ActualUniversityStudiesTable({
    allData,
    allLoading,
    fields,
    setRefresh,
    setRow,
    setOpen,
    optionData,
}: {
    allData: SectionRow[];
    allLoading: boolean;
    fields: Input[];
    setRefresh: Dispatch<SetStateAction<boolean>>;
    setRow: Dispatch<SetStateAction<SectionRow | undefined>>;
    setOpen: Dispatch<SetStateAction<boolean>>;
    optionData: Option[];
}) {
    const { user } = useSelector((state: RootState) => state.auth);

    const getCellValue = (
        key: string,
        value: string | Record<string, string>,
        currentRow: SectionRow,
    ): string | JSX.Element => {
        if (exceptionFields.includes(key) && typeof value !== 'string') {
            return value?.countryName;
        }
        const f = fields.find((field) => field.name === key);
        if (key == 'university') {
            const universityName = optionData.find((elem: Option) => elem.value == value)?.label || '-';

            if (currentRow.foreignUniversity) {
                return `${currentRow.foreignUniversity} (${universityName})`;
            }

            return universityName;
        }
        if (!(f || key.toLowerCase().includes('filename'))) return '';
        if (key.toLowerCase().includes('filename') && typeof value === 'string') {
            const fileField = fields.find((field) => field.type === 'file');
            if (!fileField || fileField.type !== 'file') return '';
            return (
                <Tooltip title="Dokumentum letöltése">
                    <DownloadButton
                        url={fileField.downloadUrl.replaceAll('{documentId}', '' + currentRow.id)}
                        downloadName={value}
                    />
                </Tooltip>
            );
        }

        return (
            f?.type === 'select' ? f.options.find((option) => option.value === value)?.label || value : value
        ) as string;
    };

    const Content = Children.toArray(
        (allData as SectionRow[]).map((currentRow) => {
            return (
                <TableRow key={currentRow.id}>
                    {Children.toArray(
                        Object.entries(currentRow)
                            .filter(([key, value]) => getCellValue(key, value as string, currentRow) !== '')
                            .sort((a, b) => cellOrder(a[0], b[0]))
                            .map(([key, value]) => {
                                return (
                                    <TableCell key={key}>
                                        {getCellValue(key, value as string | Record<string, string>, currentRow)}
                                    </TableCell>
                                );
                            }),
                    )}
                    <TableCell>
                        {!user.archive && (
                            <EndpointProvider endpoints={['md_general_students_edit_detailed']}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <IconButton
                                            onClick={() => {
                                                if (currentRow.jobStartEnd === null) {
                                                    currentRow.jobStartEnd = '';
                                                }
                                                setRow(currentRow);
                                                setOpen(true);
                                            }}
                                            color="warning"
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Grid>
                                    <DeleteButton row={currentRow} setRefresh={setRefresh} />
                                </Grid>
                            </EndpointProvider>
                        )}
                    </TableCell>
                </TableRow>
            );
        }),
    );

    if (allLoading) return <Skeleton variant="rectangular" />;
    return allData.length > 0 ? (
        <Table>
            <TableHead sx={{ backgroundColor: theme.palette.secondary.main }}>
                <TableRow>
                    {Children.toArray(
                        fields.map((field) => (
                            <TableCell key={field.name} sx={{ color: theme.palette.secondary.contrastText }}>
                                {field.label}
                            </TableCell>
                        )),
                    )}
                    <EndpointProvider endpoints={['md_general_students_edit_detailed']}>
                        <TableCell sx={{ color: theme.palette.secondary.contrastText }}>Műveletek</TableCell>
                    </EndpointProvider>
                </TableRow>
            </TableHead>
            <TableBody>{Content}</TableBody>
        </Table>
    ) : (
        <Typography>Nincs megjeleníthető adat</Typography>
    );
}
