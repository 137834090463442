import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import EventPresenceRow from './EventPresenceRow';
import React, { useEffect, useState } from 'react';
import { getScoringTypeText } from '../BaseData/utils';
import { FormikProps } from 'formik';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IEvent } from './types';
import TabList from '@mui/lab/TabList';
import { TabContext, TabPanel } from '@mui/lab';
import courseBaseDataService from '../../../../services/courseManagement/courseBaseData';
import { ICourseView } from '../types';
import { theme } from '../../../../utils/theme';

export default function EventPresenceTable({
    row,
    id,
    formikProps,
    enrollments,
    tenants,
    readOnly,
    hasAccessToEditRoster,
    scoringType = 'point',
    courseTenant,
}: GridRenderCellParams<IEvent, IEvent> & {
    id: number;
    formikProps: FormikProps<object>;
    enrollments: Record<string, Record<string, string>[]>;
    tenants: Record<string, string>;
    readOnly?: boolean;
    hasAccessToEditRoster: boolean;
    scoringType: string;
    courseTenant: string | null;
}) {
    const [tab, setTab] = useState('0');
    const text = getScoringTypeText(scoringType);
    const [customPointLoading, setCustomPointLoading] = useState(false);
    const [data, setData] = useState<ICourseView>();
    const tenantKeyIndex = Object.keys(tenants).map((key) => key);
    const [errorMessage, setErrorMessage] = useState('');
    const checkValid = (row: IEvent, targetValue: number) => {
        if (isNaN(targetValue)) {
            setErrorMessage('Kitöltése kötelező!');
            return false;
        }

        const points = row.point ? row.point.split(' - ') : [];
        setErrorMessage('A ' + text + 'nak ' + points[0] + ' és ' + points[1] + ' között kell lennie');
        if (targetValue < parseFloat(points[0]) || targetValue > parseFloat(points[1])) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setCustomPointLoading(true);
        courseBaseDataService
            .get(id, courseTenant)
            .then((response) => setData(response.data.data))
            .finally(() => setCustomPointLoading(false));
    }, [id, courseTenant]);
    if (customPointLoading) {
        return <></>;
    }

    return (
        <TabContext value={tab}>
            <Box>
                <TabList
                    variant="fullWidth"
                    onChange={(_, val) => setTab(val)}
                    sx={{
                        '& .MuiButtonBase-root': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff !important',
                            borderBottom: '1px solid grey',
                        },
                        '& .Mui-selected': {
                            color: '#f3ca12 !important',
                        },
                    }}
                >
                    {Object.keys(tenants).map((tenant) => {
                        const tabindex = tenantKeyIndex.findIndex((key) => key === tenant);
                        return <Tab key={'' + tabindex} label={tenants[tenant]} value={'' + tabindex} />;
                    })}
                </TabList>
            </Box>
            {Object.keys(tenants).map((tenant) => {
                const tabindex = tenantKeyIndex.findIndex((key) => key === tenant);
                return (
                    <TabPanel key={'' + tabindex} value={'' + tabindex} sx={{ paddingTop: '0' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                        Név
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                        E-mail cím
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', paddingRight: '35px' }} align={'center'}>
                                        Jelenlét
                                    </TableCell>
                                    {data?.customCoursePointPerEvent && !readOnly ? (
                                        <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                            {!row.point
                                                ? 'Adott ' + text + ' nincs megadva!'
                                                : `Adott ${text} (${row.point})`}
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <>
                                    {React.Children.toArray(
                                        enrollments[parseInt(tenant)].map(
                                            (elem: Record<string, string>, index: number) => (
                                                <EventPresenceRow
                                                    key={index}
                                                    row={elem}
                                                    eventRow={row}
                                                    formikProps={formikProps}
                                                    scoringTypeText={text}
                                                    readOnly={readOnly ?? false}
                                                    hasAccessToEditRoster={hasAccessToEditRoster}
                                                    customPointPerEvent={data?.customCoursePointPerEvent ? true : false}
                                                    errorMessage={errorMessage}
                                                    checkValid={checkValid}
                                                />
                                            ),
                                        ),
                                    )}
                                </>
                            </TableBody>
                        </Table>
                    </TabPanel>
                );
            })}
        </TabContext>
    );
}
