import DownloadIcon from '@mui/icons-material/Download';
import { FormPreview } from '@silinfo/form-builder-2';
import { useFetch } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Logo from '../../images/MCC_logo_for_form.png';
import { RootState } from '../../store';
import { filenameMap } from '../../studentPages/SelfAssessment/CellRenderers/Operations/Download/DownloadTemplate';
import FormToPrint from '../../studentPages/SelfAssessment/CellRenderers/Operations/Download/FormToPrint';
import { FormToPrintProps } from '../../studentPages/SelfAssessment/CellRenderers/Operations/Download/types';
import { formFilledSuccessfullyMessage, questionnaireFileProps } from '../../utils/AppConst';
import { theme } from '../../utils/theme';
import MCCLoading from '../MCCLoading/index';
import PDFDownloader from '../PDFDownloader';
import { IFillerStudent, IResponse, PreviewTemplateProps } from './types';
import { createFileName, dummyAsyncFunction, getForms, useFormPreviewStyles } from './utils';
//import { IForm } from '@silinfo/form-builder-2';
import MultiFormPreview from './MultiFormPreview';
import axios from 'axios';
import api from '../../api';

export default function PreviewTemplate(props: PreviewTemplateProps) {
    const { url, responseKey, keyPrefix, successMessage, onSubmit, formName, type } = props;
    const { user } = useSelector((state: RootState) => state.auth);
    const [formToSet, setFormArr] = useState<Record<string, unknown>[]>([]);
    const [form, setForm] = useState({});
    const { id } = useParams() as { id: string };
    const regex = /\/questionnaire\/[a-zA-Z0-9]{10,}/;
    const useAxios = regex.test(url);
    const instance = useAxios ? axios : api;
    const { data, loading } = useFetch<IResponse>(url, responseKey || '', undefined, instance);
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const classes = useFormPreviewStyles();
    const dispatch = useDispatch();

    // Mentés&Visszatöltés kliens oldalon
    const lskey = [keyPrefix, user.id, id].join('_');
    useEffect(() => {
        if (isFirstLoading && !loading && !data.dateFilled) {
            try {
                const saved = localStorage.getItem(lskey);
                if (saved) {
                    setForm(JSON.parse(saved));
                }
                setIsFirstLoading(false);
            } catch (e) {
                console.error('Storage get error', e);
            }
        }
    }, [lskey, loading, data, isFirstLoading]);

    useEffect(() => {
        try {
            if (!(isFirstLoading || loading || data.dateFilled)) {
                localStorage.setItem(lskey, JSON.stringify(form));
            }
        } catch (e) {
            console.error('Storage save error', e);
        }
    }, [data, form, isFirstLoading, loading, lskey]);

    useEffect(() => {
        if (!loading && data.fill) {
            // TODO: Vajon miért volt itt más a form data?
            //if (window.location.pathname.includes('/elonezet') || window.location.pathname.includes('/preview')) {
            //    setForm(data.fill[0]?.formData ? data.fill[0]?.formData : {});
            //} else {
            if (data.fill[0] && data.fill[0].formData) {
                setForm(data.fill[0].formData ?? {});
            } else {
                setForm(data.fill[0] ?? {});
            }
            if (typeof data.fill !== 'function') {
                setFormArr(data.fill);
            }
            //}
            setIsFirstLoading(false);
        }
    }, [loading, data]);

    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => (
            <FormToPrint
                type={type || 'selfEvaluation'}
                givenUser={loading ? undefined : data.student || (user as IFillerStudent)}
                {...formToPrintProps}
            />
        ),
        [loading, data, type, user],
    );

    if (loading || isFirstLoading) return <MCCLoading />;

    const forms = getForms(data);

    return (
        <>
            {data.dateFilled ||
            window.location.pathname.includes('/elonezet') ||
            window.location.pathname.includes('/preview') ? (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        zIndex: 5000,
                        background: theme.palette.secondary.main,
                        borderRadius: '50%',
                        padding: '5px',
                    }}
                    className="borderRadius"
                >
                    <PDFDownloader
                        data={{
                            row: {
                                fill: data.fillPdf ?? [],
                                forms: data.forms,
                                dateFilled: true,
                            },
                            filename: createFileName(filenameMap[type || 'selfEvaluation'], data.student),
                        }}
                        ComponentToPrint={FormToPrintByType}
                        DownloadIcon={<DownloadIcon color="primary" />}
                        portrait
                    />
                </div>
            ) : null}
            {forms && forms.length === 1 && (
                <div className={data?.dateFilled ? classes.readableEnabled : classes.readableDisabled}>
                    <FormPreview
                        formToSet={form}
                        setForm={setForm}
                        form={forms[0]}
                        image={Logo}
                        name={data.name || formName || ''}
                        notificationSuccess={successMessage || formFilledSuccessfullyMessage}
                        onSubmit={
                            onSubmit
                                ? async (form: Record<string, unknown>): Promise<unknown> => {
                                      return await onSubmit(form, 0);
                                  }
                                : dummyAsyncFunction
                        }
                        readonly={data.dateFilled}
                        fileProps={questionnaireFileProps(dispatch)}
                    />
                </div>
            )}
            {forms && forms.length > 1 && (
                <div className={classes.readableDisabled}>
                    <MultiFormPreview
                        image={Logo}
                        dateFilled={!!data.dateFilled}
                        forms={forms}
                        onSubmit={onSubmit || dummyAsyncFunction}
                        setForm={setFormArr}
                        formToSet={formToSet}
                        rules={data.rules}
                        groupDateFilled={!!data.groupDateFilled}
                    />
                </div>
            )}
        </>
    );
}
