import Calendar from '../../studentPages/Calendar';
import StudentCourseEnrollment from '../../studentPages/CourseEnrollment';
import Documents from '../../studentPages/Documents';
import Finances from '../../studentPages/Finances';
import Processes from '../../studentPages/Processes';
import Profile from '../../studentPages/Profile';
import Questionnaires from '../../studentPages/Questionnaires';
import Requirements from '../../studentPages/Requirements';
import SelfAssessment from '../../studentPages/SelfAssessment';

export const studentPage = {
    path: 'student',
    children: [
        {
            path: 'course-enrollment',
            element: <StudentCourseEnrollment />,
        },
        {
            path: 'profile',
            element: <Profile tabValue={0} />,
        },
        {
            path: 'profile/study-data',
            element: <Profile tabValue={1} />,
        },
        {
            path: 'requirements',
            element: <Requirements />,
        },
        {
            path: 'finances',
            element: <Finances />,
        },
        {
            path: 'calendar',
            element: <Calendar />,
        },
        {
            path: 'processes',
            element: <Processes />,
        },
        {
            path: 'self-assessment',
            element: <SelfAssessment />,
        },
        {
            path: 'questionnaires',
            element: <Questionnaires />,
        },
        {
            path: 'documents',
            element: <Documents />,
        },
    ],
};
