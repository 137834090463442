import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { TextField } from '@silinfo/front-end-template';
import parse from 'autosuggest-highlight/parse';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { GoogleMapsAutocompleteProps, PlaceType } from './types';
import { create } from '../../../../store/notification';
import { endpoints } from '../../../../utils/endPoints';
import api from '../../../../api';

function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}
const autocompleteService = { current: null as unknown as google.maps.places.AutocompleteService };

export default function GoogleMapsAutocomplete({ setter, initialValue, disabled, error }: GoogleMapsAutocompleteProps) {
    const [value, setValue] = React.useState<PlaceType | null>(initialValue || null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    React.useEffect(() => {
        if (!(value && value.place_id)) {
            return;
        }

        api.post(endpoints.googleMapsGeocode, { place_id: value?.place_id })
            .then((response) => {
                const { location, formatted_address } = response.data;
                setter({ ...location, location: formatted_address });
            })
            .catch(() => {
                dispatch(create({ message: 'Hiba történt a helyszín mentése közben', type: 'error' }));
            });
    }, [dispatch, setter, value]);

    const fetch = React.useMemo(
        () =>
            debounce((request: { input: string }, callback: (results?: readonly PlaceType[] | null) => void) => {
                try {
                    autocompleteService.current?.getPlacePredictions?.(request, callback);
                } finally {
                    setLoading(false);
                }
            }, 400),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        setLoading(true);
        fetch({ input: inputValue }, (results?: readonly PlaceType[] | null) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id="google-map-demo"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={inputValue ? 'Nincs találat' : 'Kezdj el gépelni...'}
            loadingText="Betöltés..."
            loading={loading}
            onChange={(_, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                if (!newValue) setter({ lat: 0, lng: 0, location: '' });
            }}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            size="small"
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Külső helyszín megnevezése"
                    fullWidth
                    error={!!error}
                    helperText={error}
                />
            )}
            renderOption={(props, option) => {
                if (!option.structured_formatting) return;

                const matches = option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part) => (
                                    <Box
                                        key={part.text}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
