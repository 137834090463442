import Edit from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { LanguageExamBase } from './types';
import { createLanguageExamLabel } from './utils';
import { languageOptions } from '../../../../pages/MasterData/General/Students/Form/options';

const LanguageChip = <T extends LanguageExamBase>({
    languageExam,
    setOpen,
    setLanguageExam,
}: {
    languageExam: T;
    setOpen: (value: boolean) => void;
    setLanguageExam: (value: T) => void;
}) => {
    const { isEdit } = useSelector((state: RootState) => state.studentProfile);

    const getLabel = (value: string) => {
        let ret;
        languageOptions.forEach((item) => {
            if (item.value === value) {
                ret = item.label;
            }
        });
        return ret;
    };

    return (
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Chip
                color={
                    languageExam.validated === 'yes'
                        ? 'success'
                        : languageExam.validated === 'waiting'
                        ? 'warning'
                        : 'error'
                }
                label={createLanguageExamLabel({ ...languageExam, language: getLabel(languageExam.language) })}
                deleteIcon={
                    isEdit && (languageExam.validated === 'no' || languageExam.validated === 'waiting') ? (
                        <Edit />
                    ) : (
                        <InfoIcon />
                    )
                }
                onClick={() => {
                    setLanguageExam(languageExam);
                    setOpen(true);
                }}
                onDelete={() => {
                    setLanguageExam(languageExam);
                    setOpen(true);
                }}
            />
        </Grid>
    );
};

export default LanguageChip;
