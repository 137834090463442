import Title from '../../../../../../components/Title';
import { IStudentAllocationInfo } from './types';
import { TextField } from '@silinfo/front-end-template';
import Grid from '@mui/material/Grid';
import { getMembershipStatus } from '../../utils';

export type RoomAllocationType = 'old' | 'new';

export interface SemesterAllocationInfoProps {
    data: IStudentAllocationInfo;
    type: RoomAllocationType;
}

export default function SemesterAllocationInfo(props: SemesterAllocationInfoProps) {
    const { data, type } = props;

    const title = `${
        type === 'old' ? `Legutolsó aktív szemeszter - ${data?.semester}` : `Következő szemeszter - ${data?.semester}`
    }`;

    return (
        <Title title={title}>
            <Grid item xs={12} container spacing={1} direction="row">
                <Grid item xs={12} md={6}>
                    <TextField name="trainingLevel" label="Képzési szint" value={data?.trainingLevel || '-'} disabled />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="trainingProgram"
                        label="Képzési program"
                        value={data?.trainingProgram || '-'}
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} direction="row">
                <Grid item xs={12} md={6}>
                    <TextField name="collegeYear" label="Évfolyam" value={data?.collegeYear || '-'} disabled />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="currentMembershipStatus"
                        label="Státusz"
                        value={getMembershipStatus(data.currentMembershipStatus) || '-'}
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} direction="row">
                <Grid item xs={12} md={6}>
                    <TextField name="campus" label="Campus" value={data?.campus || '-'} disabled />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField name="building" label="Épület" value={data?.building || '-'} disabled />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} direction="row">
                <Grid item xs={12} md={6}>
                    <TextField name="floor" label="Szint" value={data?.floor || '-'} disabled />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField name="room" label="Szoba" value={data?.room || '-'} disabled />
                </Grid>
            </Grid>
        </Title>
    );
}
