import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { ITrainingCompletionData } from '../types';
import Header from './Header';
import Row from './Row';
import TableCell from './TableCell';

export default function CollapsibleTable(props: { rows: ITrainingCompletionData[]; loading: boolean }) {
    const { rows, loading } = props;

    const NoDataDisplay = (
        <TableRow>
            <TableCell colSpan={10}>
                <i>{loading ? 'Betöltés...' : 'Nincs megjeleníthető adat'}</i>
            </TableCell>
        </TableRow>
    );

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <Header />
                <TableBody>
                    {loading || rows.length < 1 ? NoDataDisplay : rows.map((row) => <Row key={row.id} row={row} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
