import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { GridAlignment, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Input } from '@silinfo/front-end-template';
import { createContext } from 'react';
import instance from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import OperationsButton from './OperationsButton';
import { CourseClosingType } from './types';

const breadcrumbs = {
    administration: 'Adminisztráció',
    courseCompletions: 'Képzészárás',
};

const header = (tenant: string | null) => ({
    title: 'Képzésteljesítések',
    breadcrumbs: {},
    project: tenant || 'TAR',
});

const studentColumn: GridColDef = {
    field: 'student',
    headerName: 'Hallgató',
    minWidth: 200,
    flex: 1,
    align: 'left',
};

const requirementColumns: GridColDef[] = [
    {
        field: 'semesterCount',
        headerName: 'Szemeszterszám',
    },
    {
        field: 'minimumSum',
        headerName: 'Minimum összpontszám',
    },
    {
        field: 'minimumCoCourse',
        headerName: 'Társképzésből teljesítendő minimum pontszám',
    },
    {
        field: 'mandatoryCourses',
        headerName: 'Kötelező kurzusok',
    },
    {
        field: 'electiveCourses',
        headerName: 'Kötelezően választható kurzusok',
    },
    {
        field: 'languageExamRequirement',
        headerName: 'Nyelvvizsga követelmény',
    },
    {
        field: 'financialDebt',
        headerName: 'Pénzügyi tartozás',
    },
].map((column) => ({
    flex: 1,
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) =>
        params.value === 'success' ? (
            <Tooltip title="Teljesítve" placement="top">
                <CheckIcon color="success" />
            </Tooltip>
        ) : (
            <Tooltip title="Nem teljesítve" placement="top">
                <CloseIcon color="error" />
            </Tooltip>
        ),
    ...column,
}));

const operationsColumn = (tc: string) => {
    return {
        field: 'operations',
        headerName: 'Műveletek',
        sortable: false,
        renderCell: (params: GridRenderCellParams) => OperationsButton(tc, params),
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
    };
};

const filterFields: Input[] = [
    {
        name: 'student',
        label: 'Hallgató',
    },
];

const listPageProps = (tenant: string | null) => ({
    header: header(tenant || 'TAR'),
    table: {
        autoPageSize: false,
        pageSize: 10,
        paginationMode: 'client',

        initialState: {
            pinnedColumns: {
                right: ['operations'],
            },
            pagination: {
                pageSize: 10,
            },
        },
        columnGroupingModel: [
            {
                groupId: 'Képzési követelmények',
                headerAlign: 'center' as GridAlignment,
                children: requirementColumns,
            },
        ],
        experimentalFeatures: {
            columnGrouping: true,
        },
    },
});

const defaultFilter = {
    student: '',
};

const handleClick = (programId: string | number, studentId: string | number) => (set: CourseClosingType) =>
    instance.post(endpoints.administration.courseClosing.closing(programId, studentId), { set });

const ListPageContext = createContext({
    programId: '',
    refresh: console.error,
});

export {
    breadcrumbs,
    defaultFilter,
    filterFields,
    handleClick,
    header,
    listPageProps,
    operationsColumn,
    requirementColumns,
    studentColumn,
    ListPageContext,
};
