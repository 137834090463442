import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FileNameCellRenderer, UpdatedAtCellRenderer } from '.';
import { OperationCellRenderer } from './OperationCellRenderer';
import { Dispatch, SetStateAction } from 'react';

export const tableColumns = (
    setTableLoading: Dispatch<SetStateAction<boolean>>,
    courseTenant: string | null,
    readOnly?: boolean,
): GridColDef[] =>
    [
        {
            headerName: 'Dokumentum megnevezése',
            field: 'fileName',
            renderCell: FileNameCellRenderer,
            sortable: false,
            minWidth: 200,
        },
        {
            headerName: 'Módosítás dátuma',
            field: 'updateDate',
            renderCell: UpdatedAtCellRenderer,
            minWidth: 180,
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (props: GridRenderCellParams) => (
                <OperationCellRenderer
                    {...props}
                    setTableLoading={setTableLoading}
                    readOnly={readOnly}
                    courseTenant={courseTenant}
                />
            ),
            sortable: false,
        },
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));
