import { AlertColor } from '@mui/material/Alert';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface INotification {
    type: AlertColor;
    message: string;
    redirect?: string;
    autoHideDuration?: number;
}

interface INotificationState extends INotification {
    isOpen: boolean;
}

const initialState: INotificationState = { isOpen: false, type: 'info', message: '', autoHideDuration: 4000 };

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        create(_, action: PayloadAction<INotification>) {
            const { type, message, redirect, autoHideDuration } = action.payload;

            return {
                isOpen: true,
                type,
                message,
                redirect,
                autoHideDuration,
            };
        },
        remove: () => initialState,
    },
});

export const { create, remove } = notificationSlice.actions;
export default notificationSlice.reducer;
