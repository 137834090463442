import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Page, TabPanel } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ProgramLevel from './ProgramLevel';
import SemesterLevel from './SemesterLevel';
import { useTenant } from '../../components/TenantContext';

export default function Requirements() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab') || 0);
    const tenant = useTenant();
    const header: HeaderProps = {
        title: 'Követelmények',
        project: tenant || 'TAR',
        breadcrumbs: {
            requirements: 'Követelmények',
        },
    };

    useEffect(() => {
        setTab(+(searchParams.get('tab') || 0));
    }, [searchParams]);

    return (
        <Page header={header}>
            {/*
            <Grid item xs={12}>
                <Alert severity="warning">
                    <Typography>
                        Figyelem! Az oldalon található adatok tájékoztató jellegűek. A végleges adatok feltöltése
                        folyamatban van. Köszönjük a türelmet!
                    </Typography>
                </Alert>
            </Grid>
            */}
            <Grid container item xs={12}>
                <AppBar position="static" sx={{ backgroundColor: '#ddd' }}>
                    <Tabs
                        value={+tab}
                        onChange={(_, v) => {
                            setTab(v);
                            setSearchParams({ tab: v });
                        }}
                        className="studentProfile"
                        textColor="secondary"
                        TabIndicatorProps={{
                            sx: {
                                height: 5,
                            },
                        }}
                    >
                        <Tab label="Program szint" className="studentProfile" />
                        <Tab label="Szemeszter szint" className="studentProfile" />
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0} style={{ width: '100%' }}>
                    <ProgramLevel />
                </TabPanel>
                <TabPanel value={tab} index={1} style={{ width: '100%' }}>
                    <SemesterLevel />
                </TabPanel>
            </Grid>
        </Page>
    );
}
