import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Skeleton from '@mui/material/Skeleton';
import { TextField, useFetch } from '@silinfo/front-end-template';
import { Option, getOptionLabel, isOptionEqualToValue } from '../../utils/AppConst';
import { useCallback, useEffect } from 'react';

const Input = (params: AutocompleteRenderInputParams) => <TextField {...params} label="Szemeszter" />;

interface SemesterSelectorProps {
    url: string;
    responseKey?: string;
    semesterState: [Option<string> | null, (value: Option<string> | null) => void];
    defaultActive?: boolean;
}

export default function SemesterSelector({ url, responseKey, semesterState, defaultActive }: SemesterSelectorProps) {
    const [semester, setSemester] = semesterState;
    const { data, loading } = useFetch<(Option & { active: boolean })[]>(url, responseKey || '');
    const semesterOptions = data.map((item) => ({ label: item.label, value: item.value }));

    const handleChange = useCallback((_: unknown, value: Option<string> | null) => setSemester(value), [setSemester]);

    useEffect(() => {
        if (loading || data.length < 1 || !defaultActive) return;
        const activeSemester = data.find((item) => item.active);
        if (activeSemester) setSemester(activeSemester);
    }, [loading, data, setSemester, defaultActive]);

    return loading ? (
        <Skeleton height="50px" />
    ) : (
        <Autocomplete
            options={semesterOptions}
            value={semester}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleChange}
            getOptionLabel={getOptionLabel}
            size="small"
            renderInput={Input}
        />
    );
}
