import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FieldGenerator } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { ILangExamReportForm } from '../../../pages/Administration/LangExamReport/LangExportDialogForm';
import { endpoints } from '../../../utils/endPoints';

const LanguageExamInfoDialogForm = (
    props: FormikProps<ILangExamReportForm> & {
        objectId: number;
        handleClose: () => void;
    },
) => {
    const { objectId, handleClose } = props;

    return (
        <Grid item container>
            <Grid item xs={12}>
                <DialogTitle>Nyelvvizsga feltöltés</DialogTitle>
            </Grid>
            <Grid item container xs={12}>
                <DialogContent>
                    {FieldGenerator({
                        name: 'language',
                        label: 'Nyelv',
                        format: { xs: 12, sm: 12, md: 12 },
                        props: {
                            disabled: true,
                        },
                        ...props,
                    })}
                    {FieldGenerator({
                        name: 'level',
                        label: 'Szint',
                        format: { xs: 12, sm: 12, md: 12 },
                        props: {
                            disabled: true,
                        },
                        ...props,
                    })}
                    {FieldGenerator({
                        name: 'category',
                        label: 'Kategória',
                        format: { xs: 12, sm: 12, md: 12 },
                        props: {
                            disabled: true,
                        },
                        ...props,
                    })}
                    {FieldGenerator({
                        name: 'acquisitonPlace',
                        label: 'Szerzés helye',
                        format: { xs: 12, sm: 12, md: 12 },
                        props: {
                            disabled: true,
                        },
                        ...props,
                    })}
                    {FieldGenerator({
                        name: 'acquisitonDate',
                        label: 'Szerzés dátuma',
                        format: { xs: 12, sm: 12, md: 12 },
                        props: {
                            disabled: true,
                        },
                        ...props,
                    })}
                    <Grid item {...{ xs: 12, sm: 12 }}>
                        {FieldGenerator({
                            name: 'name',
                            label: 'Bizonyítvány',
                            type: 'file',
                            origFileName: 'certificateFileName',
                            uuidFileName: 'certificateFilePath',
                            tempType: 'student-document',
                            downloadUrl: props.initialValues.certificateFileName
                                ? `${endpoints.fileManagement.download}?id=${objectId}&type=student-document&subType=student-language-exam`
                                : '',
                            format: { xs: 12, sm: 12 },
                            props: {
                                disabled: true,
                            },
                            ...props,
                        })}
                    </Grid>
                </DialogContent>
            </Grid>
            <Grid item xs={12}>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Bezár
                    </Button>
                </DialogActions>
            </Grid>
        </Grid>
    );
};

export default LanguageExamInfoDialogForm;
