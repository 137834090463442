import Info from '@mui/icons-material/Info';
import DialogBasic from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableButton } from '@silinfo/front-end-template';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Api from '../../../api';
import i18n from '../../../i18n';
import { endpoints } from '../../../utils/endPoints';
import { useStyles } from '../SystemLog/EmailLogs/utils';
import { LanguageExamType } from './types';

export interface IStudentLanguageExam {
    ['@id']: string;
    ['@type']: string;
    category: string;
    certificateFileName: string;
    certificateFilePath: string;
    examDate: string;
    examLocation: string;
    id: number;
    language: string;
    type: LanguageExamType;
    level: string;
    updatedAt: string;
    validated: string;
    validationTime: string | null;
}

export default function StudentLanguageExamsDialog(props: { studentId: number }) {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [languageExams, setLanguageExams] = useState([]);
    const classes = useStyles();
    const { studentId } = props;

    useEffect(() => {
        if (open) {
            setLoading(true);
            Api.get(endpoints.masterData.students.languageExams.main.replace('{studentId}', studentId?.toString()))
                .then((res) => setLanguageExams(res.data['data']))
                .finally(() => setLoading(false));
        }
    }, [open, studentId]);

    return (
        <>
            <TableButton
                color="info"
                className={classes.eyeIcon}
                tooltip={'Hallgató nyelvvizsgái'}
                onClick={() => setOpen(true)}
            >
                <Info />
            </TableButton>

            <DialogBasic open={open} onClose={() => setOpen(false)} maxWidth={'lg'}>
                <DialogTitle>Hallgató nyelvvizsgái</DialogTitle>
                {!loading ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                    Nyelv
                                </TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                    Típus
                                </TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                    Szint
                                </TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                    Kategória
                                </TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                    Nyelvvizsga dátuma
                                </TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                    Nyelvvizsga helyszíne
                                </TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                    Validált
                                </TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                    Validáció dátuma
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {React.Children.toArray(
                                languageExams.length ? (
                                    languageExams.map((elem: IStudentLanguageExam) => (
                                        <>
                                            <TableRow key={elem.id}>
                                                <TableCell align={'center'}>
                                                    {
                                                        i18n.t(
                                                            'master_data.training_levels.language_exam_requirements.languages.' +
                                                                elem.language,
                                                        ) as string
                                                    }
                                                </TableCell>
                                                <TableCell align={'center'}>{elem.type}</TableCell>
                                                <TableCell align={'center'}>{elem.level}</TableCell>
                                                <TableCell align={'center'}>
                                                    {
                                                        i18n.t(
                                                            'master_data.training_levels.language_exam_requirements.category.' +
                                                                elem.category,
                                                        ) as string
                                                    }
                                                </TableCell>
                                                <TableCell align={'center'}>{elem.examDate}</TableCell>
                                                <TableCell align={'center'}>{elem.examLocation}</TableCell>
                                                <TableCell align={'center'}>
                                                    {i18n.t('master_data.validated.' + elem.validated) as string}
                                                </TableCell>
                                                <TableCell align={'center'}>{elem.validationTime || '-'}</TableCell>
                                            </TableRow>
                                        </>
                                    ))
                                ) : (
                                    <TableCell colSpan={7} align="center">
                                        A hallgatónak nincs feltöltött nyelvvizsgája.
                                    </TableCell>
                                ),
                            )}
                        </TableBody>
                    </Table>
                ) : (
                    <Skeleton style={{ width: '100%' }} />
                )}
            </DialogBasic>
        </>
    );
}
