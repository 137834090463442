import Grid from '@mui/material/Grid';
import { Page, TabsCompleted } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import Create from './Create';
import Elements from './Elements';
import { header } from './utils';
import MCCLoading from '../../../../components/MCCLoading';
import { useTenant } from '../../../../components/TenantContext';

export default function GroupManagementForm({ view }: { view?: boolean }) {
    const idFromUrl = useParams().id;
    const { semesterId } = useParams();
    const [id, setId] = useState(idFromUrl || '');
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const tenant = useTenant();

    const tabContents = [
        {
            label: 'Alapadatok',
            value: <Create setId={setId} />,
        },
        {
            label: 'Csoport elemei',
            value: <Elements view={view} />,
            disabled: !id,
        },
    ];

    useEffect(() => {
        if (!idFromUrl && id) {
            !view
                ? navigate(
                      clientEndPoints.scholarship_group_management_edit
                          .replace(':id', id)
                          .replace(':semesterId', semesterId || ''),
                  )
                : navigate(
                      clientEndPoints.scholarship_group_management_view
                          .replace(':id', id)
                          .replace(':semesterId', semesterId || ''),
                  );
        }
    }, [id, idFromUrl, navigate, semesterId, view]);

    useEffect(() => {
        if (searchParams.get('tab') == '1' && !id) {
            setSearchParams({ tab: '0' });
        } else {
            setLoading(false);
        }
    }, [id, searchParams, setSearchParams]);

    if (loading) return <MCCLoading />;

    return (
        <Page header={header(!!id, tenant || 'TAR')}>
            <Grid item xs={12}>
                <TabsCompleted tabContents={tabContents} />
            </Grid>
        </Page>
    );
}
