import { Theme } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from '../../../utils/theme';

export interface ListItemProps {
    text: string;
    to: string;
    icon: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
    active: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    inactive: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}));

const ListItem = ({ text, icon, to }: ListItemProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    return (
        <ListItemButton
            onClick={() => navigate(to)}
            className={clsx(location.pathname === to ? classes.active : classes.inactive)}
        >
            <ListItemIcon
                style={{
                    color: location.pathname === to ? theme.palette.secondary.main : theme.palette.primary.main,
                }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText primary={text} />
        </ListItemButton>
    );
};

export default ListItem;
