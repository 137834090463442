import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { fixDateRangeFields } from '../../utils/AppConst';
import { IForm } from '../../utils/Interfaces/interfaces';

const approvalsService = {
    filter: (form: IForm) => API.get(endpoints.administration.approvals.main, { params: fixDateRangeFields(form) }),
    filterDataStatuses: () => API.get(endpoints.administration.approvals.filterData.statuses),
    filterDataTypes: () => API.get(endpoints.administration.approvals.filterData.types),
    save: (objectId: number, form: IForm) =>
        API.post(endpoints.administration.approvals.save.replace('{id}', objectId.toString()), form),
    get: (objectId: number) => API.get(endpoints.administration.approvals.get.replace('{id}', objectId.toString())),
};

export default approvalsService;
