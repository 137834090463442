import CompletionSheet from './CompletionSheet';
import PreviousSummary, { PreviousSummaryDocumentMobile } from './PreviousSummary';
import SelfAssessmentConversation from './SelfAssessmentConversation';
import SelfAssessmentQuestionnaire, { SelfAssessmentQuestionnaireMobile } from './SelfAssessmentQuestionnaire';
import UniversityIndex, { UniversityIndexMobile } from './UniversityIndex';
import CareerGuidanceSheet from './CareerGuidanceSheet';

export type ComponentMapKeys =
    | 'previousSummary'
    | 'selfAssessmentQuestionnaire'
    | 'selfAssessmentConversation'
    | 'universityIndex'
    | 'careerGuidanceSheet'
    | 'completionSheet';

export const allowedComponents: ComponentMapKeys[] = [
    'previousSummary',
    'selfAssessmentQuestionnaire',
    'selfAssessmentConversation',
    'universityIndex',
    'careerGuidanceSheet',
    'completionSheet',
];

export const ComponentMap = {
    'previousSummary': PreviousSummary,
    'selfAssessmentQuestionnaire': SelfAssessmentQuestionnaire,
    'selfAssessmentConversation': SelfAssessmentConversation,
    //'summaryDocument' : SummaryDocument,
    'universityIndex': UniversityIndex,
    'careerGuidanceSheet': CareerGuidanceSheet,
    'completionSheet': CompletionSheet,
};

export const ComponentMapMobile = {
    'previousSummary': PreviousSummaryDocumentMobile,
    'selfAssessmentQuestionnaire': SelfAssessmentQuestionnaireMobile,
    'selfAssessmentConversation': SelfAssessmentConversation,
    //'summaryDocument' : SummaryDocumentMobile,
    'universityIndex': UniversityIndexMobile,
    'careerGuidanceSheet': CareerGuidanceSheet,
    'completionSheet': CompletionSheet,
};

export const openStudentPreview = (semesterId: string | number, url: string) => () => {
    window.open(url.replace(':semesterId', '' + semesterId), '_blank');
};
