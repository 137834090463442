import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import instance from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

export interface ITrainingLevelsForm {
    name: string;
}

const trainingLevelsService = {
    ...crudTemplate(endpoints.masterData.trainingLevels.main),
    trainingLevels: () => instance.get(endpoints.masterData.trainingLevels.trainingLevels),
    archive: (levelId: number) =>
        instance.post(endpoints.masterData.trainingLevels.archive.replace('{levelId}', '' + levelId)),
    forStudent: (studentId: string) =>
        instance.get(endpoints.masterData.trainingLevels.forStudent.replace('{studentId}', '' + studentId)),
    trainingProgramsForStudent: (trainingLevelId: string, studentId: string) =>
        instance.get(
            endpoints.masterData.trainingLevels.trainingProgramsForStudent
                .replace('{trainingLevelId}', '' + trainingLevelId)
                .replace('{studentId}', '' + studentId),
        ),

    requirementsInitiateExport: (format: IForm, form: IForm) =>
        instance.post(endpoints.masterData.trainingLevels.requirementExport.initiate, { ...format, ...form }),
    requirementsCheckExport: (id: number) =>
        instance.get(endpoints.masterData.trainingLevels.requirementExport.check.replace('{id}', id.toString())),
    requirementsDownloadExport: (id: number) =>
        instance.get(endpoints.masterData.trainingLevels.requirementExport.download.replace('{id}', id.toString()), {
            responseType: 'arraybuffer',
        }),
};

export default trainingLevelsService;
