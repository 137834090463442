import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Paper } from '../Requirements/Paper';
import { theme } from '../../utils/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Loading } from '@silinfo/front-end-template';

interface OpenProcessesCountProps {
    count: number;
    loading?: boolean;
}

export default function OpenProcessesCount({ count, loading }: OpenProcessesCountProps) {
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    if (loading) {
        return <Loading noHeight />;
    }

    return (
        <Grid item container xs={12} md={6}>
            <Paper style={matches ? {} : { width: '100%' }}>
                <Grid item container spacing={1} alignItems="center" xs={12} justifyContent="space-between">
                    <Grid item>
                        <Typography>Folyamatban lévő ügyek</Typography>
                    </Grid>
                    <Grid item>
                        <Paper style={{ background: '#eee' }}>
                            <strong>{count} db</strong>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
