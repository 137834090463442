import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useCallback, useContext, useState } from 'react';
import GridRenderCellParamsContext from '../List/GridRenderCellParamsContextProvider';
import MenuContext from '../List/MenuContextProvider';
import { handleExportClick } from './utils';

export default function GroupListExport({ disabled }: { disabled?: boolean }) {
    const [loading, setLoading] = useState(false);

    const setter = useContext(MenuContext);
    const params = useContext(GridRenderCellParamsContext);

    const handleClick = useCallback(() => {
        if (!params) return;
        handleExportClick({ id: params.row.id, setter, setLoading });
    }, [params, setter]);

    if (params?.row.calculationType !== 'manual') return null;

    return (
        <MenuItem onClick={handleClick} disabled={disabled}>
            <ListItemIcon>{loading ? <CircularProgress size={24} /> : <FileDownloadIcon color="error" />}</ListItemIcon>
            <ListItemText>Csoportlista tábla generálása és exportálása</ListItemText>
        </MenuItem>
    );
}
