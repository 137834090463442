import Grid from '@mui/material/Grid';
import { Page } from '@silinfo/front-end-template';
import { AxiosError, AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Form from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import semestersService, { ISemestersForm } from '../../../../services/masterData/semesters';
import { IViolations } from '../../../../services/ResponseInterfaces';
import { create } from '../../../../store/notification';
import { transformApiErrors } from '../../../../utils/AppConst';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import SemesterFormFields from './SemesterFormFields';
import instance from '../../../../api';
import { ICheckResponse } from './types';
import { endpoints } from '../../../../utils/endPoints';
import { useTenant } from '../../../../components/TenantContext';

//TODO: validációt befejezni, megfelelő datepickerrel
const FormSchema = yup.object().shape({
    /*  year: yup.string().required("Kötelező megadni"),
      period: yup.string().required("Kötelező megadni"),*/
    name: yup
        .string()
        .max(128, 'Túl hosszú! A maximumálisan megadható karakterek száma 128.')
        .required('Kötelező megadni'),
    processName: yup
        .string()
        .trim()
        .matches(
            /\d\d([OT])/g,
            'Az azonosítónak az év utolsó 2 számjegyének, illetve őszi félév esetén O, tavaszi esetén pedig T karakterek össszességének kell lennie. Ez az azonosító alkotja az adott szemeszterben indított folyamatok középső 3 karakterét. Példa.: 21T',
        )
        .min(3, 'Pontosan 3 karaktert kell tartalmaznia az azonosítónak')
        .max(3, 'Pontosan 3 karaktert kell tartalmaznia az azonosítónak')
        .required(),
    startDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('endDate'), 'Nem lehet később a "Szemeszter vége" dátumnál')
        .required('Kötelező megadni'),
    endDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('startDate'), 'Nem lehet előbb a "Szemeszter kezdete" dátumnál')
        .required('Kötelező megadni'),
    enrollmentStartDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('enrollmentEndDate'), 'Nem lehet később a "Beiratkozás vége" dátumnál'),
    enrollmentEndDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('enrollmentStartDate'), 'Nem lehet előbb a "Beiratkozás kezdete" dátumnál'),
    terminationStartDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('terminationEndDate'), 'Nem lehet később a "Kiiratkozási időszak vége" dátumnál'),
    terminationEndDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('terminationStartDate'), 'Nem lehet előbb a "Kiiratkozási időszak kezdete" dátumnál'),
    moveOutStartDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('moveOutEndDate'), 'Nem lehet később a "Kiköltözési időszak vége" dátumnál'),
    moveOutEndDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('moveOutStartDate'), 'Nem lehet előbb a "Kiköltözési időszak kezdete" dátumnál'),
    courseEnrollmentStartDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('courseEnrollmentEndDate'), 'Nem lehet később a "Kurzusfelvételi időszak vége" dátumnál'),
    courseEnrollmentEndDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('courseEnrollmentStartDate'), 'Nem lehet előbb a "Kurzusfelvételi időszak kezdete" dátumnál'),
    courseTerminationStartDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('courseTerminationEndDate'), 'Nem lehet később a "Kurzuskiiratkozási időszak vége" dátumnál'),
    courseTerminationEndDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('courseTerminationStartDate'), 'Nem lehet előbb a "Kurzuskiiratkozási időszak kezdete" dátumnál'),
    gradingPeriodStartDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('gradingPeriodEndDate'), 'Nem lehet később az "Értékelési időszak időszak vége" dátumnál'),
    gradingPeriodEndDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('gradingPeriodStartDate'), 'Nem lehet előbb az "Értékelési időszak időszak kezdete" dátumnál'),
    roomAllocationStartDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('roomAllocationEndDate'), 'Nem lehet később a befejezés dátumnál'),
    roomAllocationEndDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('roomAllocationStartDate'), 'Nem lehet előbb a kezdeti dátumnál'),
    relocationStartDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('relocationEndDate'), 'Nem lehet később a befejezés dátumnál'),
    relocationEndDate: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('relocationStartDate'), 'Nem lehet előbb a kezdeti dátumnál'),
    admissionFrom: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .max(yup.ref('admissionTo'), 'Nem lehet később a befejezés dátumnál'),
    admissionTo: yup
        .date()
        .nullable()
        .typeError('Érvénytelen dátum formátum')
        .min(yup.ref('admissionFrom'), 'Nem lehet előbb a kezdeti dátumnál'),
});
const initialForm = {
    name: '',
    processName: '',
    startDate: '',
    endDate: '',
    year: '',
    period: '',
    /* enrollmentStartDate: "",
     enrollmentEndDate: "",
     terminationStartDate: "",
     terminationEndDate: "",
     courseEnrollmentStartDate: "",
     courseEnrollmentEndDate: "",
     courseTerminationStartDate: "",
     courseTerminationEndDate: "",
     gradingPeriodStartDate: "",
     gradingPeriodEndDate: ""*/
    holidays: [],
};
export default function SemesterForm({ readOnly = false }: { readOnly?: boolean }) {
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [form, setForm] = useState<ISemestersForm>(initialForm);
    const { semesterId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [lastClosingProcesssLog, setLastClosingProcesssLog] = useState<null | number>(null);
    const tenant = useTenant();

    useEffect(() => {
        if (semesterId) {
            semestersService
                .get(semesterId)
                .then((response: AxiosResponse) => {
                    if (response.data.closed) {
                        navigate(clientEndPoints.md_general_semesters_show.replace(':semesterId', semesterId));
                    }
                    setForm(response.data);
                })
                .then(() => {
                    instance
                        .get<ICheckResponse>(endpoints.masterData.semesters.checkClosingStatus(semesterId, '0'))
                        .then((response: AxiosResponse) => {
                            setLastClosingProcesssLog(response.data?.log);
                        })
                        .catch(console.error)
                        .finally(() => setPageLoading(false));
                });
        } else {
            setPageLoading(false);
        }
    }, [navigate, semesterId]);

    const handleSubmit = (form: ISemestersForm, { setErrors }: FormikHelpers<ISemestersForm>) => {
        for (const [key, value] of Object.entries(form)) {
            if (!value) {
                delete form[key as keyof ISemestersForm];
            }
        }

        if (form.holidays) {
            form.holidays = form.holidays.map((holiday) => ({ ...holiday, id: holiday['@id'] || undefined }));
        }

        (semesterId ? semestersService.replace(semesterId, form) : semestersService.create(form))
            .then(() =>
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                        redirect: clientEndPoints.md_general_semesters_list,
                    }),
                ),
            )
            .catch((error: AxiosError) => {
                let message = '';
                if (error.response?.status === 422) {
                    error.response.data.violations?.forEach((elem: IViolations) => {
                        if (elem.propertyPath === 'holiday') {
                            message = elem.message;
                        }
                    });

                    setErrors(transformApiErrors(error.response?.data.violations));
                }

                if (message) {
                    dispatch(create({ type: 'error', message: message }));
                } else {
                    dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
                }
            });
    };

    const title = (id: string | undefined, readOnly: boolean) => {
        if (readOnly) {
            return 'Szemeszter megtekintése';
        } else if (id) {
            return form.name + ' szerkesztése';
        } else {
            return 'Szemeszter hozzáadása';
        }
    };

    const header = {
        project: tenant || 'TAR',
        title: 'Törzsadatok - ' + title(semesterId, readOnly),
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_semesters_list]: 'Szemeszterek',
            form: title(semesterId, readOnly),
        },
    };

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Grid item xs={12}>
                <Form
                    hideButtons
                    fields={[]}
                    onSubmit={handleSubmit}
                    initialValues={form}
                    validationSchema={FormSchema}
                >
                    {(props) => (
                        <SemesterFormFields
                            {...props}
                            setForm={setForm}
                            form={form}
                            readonly={readOnly}
                            setPageLoading={setPageLoading}
                            lastClosingProcesssLog={lastClosingProcesssLog}
                        />
                    )}
                </Form>
            </Grid>
        </Page>
    );
}
