import React, { Dispatch, SetStateAction, createContext } from 'react';

export type MenuContextType = Dispatch<SetStateAction<HTMLElement | null>>;

const MenuContext = createContext<MenuContextType>(console.error);

interface RefreshContextProviderProps {
    children: React.ReactNode;
    setter: MenuContextType;
}

export function MenuContextProvider(props: RefreshContextProviderProps) {
    return <MenuContext.Provider value={props.setter}>{props.children}</MenuContext.Provider>;
}

export default MenuContext;
