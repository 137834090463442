import { IForm } from '../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../utils/clientEndPoints';

export interface IApiKeysForm extends IForm {
    id: number;
    token: string;
    ipRestrictions: string[];
    functions: string[];
    comment: string;
    active: boolean;
}

export function formHeader(id: string | number, tenant: string | null) {
    return {
        project: tenant || 'TAR',
        title: 'API kulcsok',
        breadcrumbs: {
            masterData: 'Adminisztráció',
            [clientEndPoints.admin_api_keys_list]: 'API kulcsok',
            ...(id ? { edit: 'API kulcs szerkesztése' } : { add: 'API kulcs hozzáadása' }),
        },
    };
}

export const title = (id: string | undefined) => {
    if (id) {
        return 'API kulcs szerkesztése';
    } else {
        return 'API kulcs hozzáadása';
    }
};

export const initialForm = {
    id: 0,
    token: '',
    ipRestrictions: [],
    functions: [],
    comment: '',
    active: true,
} satisfies IApiKeysForm;
