import Info from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Dialog, TableButton } from '@silinfo/front-end-template';
import { financeFinancialApprovalStatuses } from '../../utils/AppConst';
import { IFinanceRow } from './FinanceTable';

export default function FinanceDialog(props: GridRenderCellParams<unknown, IFinanceRow>) {
    const DisplayTextfield = (props: TextFieldProps) => (
        <Grid item xs={12}>
            <TextField value={props.value} label={props.label} disabled fullWidth {...props} />
        </Grid>
    );

    return (
        <Dialog
            opener={
                <TableButton className="borderRadius" style={{ borderRadius: '50%' }}>
                    <Info />
                </TableButton>
            }
            title="Pénzügyi tétel"
        >
            <Grid item container spacing={3} xs={12}>
                <DisplayTextfield value={props.row.identifier} label="Azonosító" />
                <DisplayTextfield value={props.row.name} label="Díj megnevezése" />
                <DisplayTextfield value={props.row.amount} label="Összeg" />
                <DisplayTextfield
                    value={financeFinancialApprovalStatuses[props.row.status] || 'Ismeretlen'}
                    label="Státusz"
                />
                {props.row.comment && (
                    <DisplayTextfield value={props.row.comment} label="Megjegyzés" rows={4} multiline />
                )}
            </Grid>
        </Dialog>
    );
}
