import BasicDialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Input } from '@silinfo/front-end-template';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { setEdit } from '../../../../../store/studentProfile';
import EditContent from './EditContent';
import InfoContent from './InfoContent';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<unknown, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = <T extends { id: number | string }>({
    open,
    setOpen,
    value,
    setValue,
    fields,
    children,
    url,
    title,
    readOnly = false,
}: {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValue: (value?: T) => void;
    value?: T;
    fields: (id?: string | number) => Input[];
    children: JSX.Element | JSX.Element[] | string;
    url: string;
    title: string;
    readOnly?: boolean | undefined;
}) => {
    const { isEdit } = useSelector((state: RootState) => state.studentProfile);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(setEdit(false));
        setTimeout(() => setValue(undefined), 400); //transition miatt ne ugráljon
    };

    return (
        <BasicDialog open={open} onClose={handleClose} fullWidth maxWidth="lg" TransitionComponent={Transition}>
            <DialogTitle className="studentProfile">
                {value ? title + ' szerkesztése' : title + ' hozzáadása'}
            </DialogTitle>
            {value && !isEdit ? (
                <InfoContent handleClose={handleClose} readOnly={readOnly}>
                    {children}
                </InfoContent>
            ) : (
                <EditContent
                    setOpen={setOpen}
                    value={value}
                    handleClose={handleClose}
                    fields={fields(value?.id)}
                    url={url}
                />
            )}
        </BasicDialog>
    );
};

export default Dialog;
