import Grid from '@mui/material/Grid';
import { useFetch } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { endpoints } from '../../../../../utils/endPoints';
import CollapsibleTable from './CollapsibleTable';
import RefreshButton from './RefreshButton';
import { ITrainingCompletionData } from './types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

export default function StudentView() {
    const { id } = useParams<{ id: string }>();
    const { user } = useSelector((state: RootState) => state.auth);
    const [refresh, setRefresh] = useState(false);
    const { data, loading } = useFetch<ITrainingCompletionData[]>(
        endpoints.masterData.students.trainingProgramCompletion.main(id || '0'),
        '',
        refresh,
    );

    return (
        <Grid container spacing={2}>
            {!user.archive && <RefreshButton setRefresh={setRefresh} />}
            <Grid item xs={12}>
                <CollapsibleTable rows={data} loading={loading} />
            </Grid>
        </Grid>
    );
}
