import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

export interface IItemsForm {
    category: string;
    campus: string;
    building: string;
    floor: string;
    room: string;
    roomName: string;
    name: string;
    amount: number;
    status: string;
    inventoryNumber: string;
}

const itemsService = {
    ...crudTemplate<IItemsForm>(endpoints.masterData.items.main),
    filter: (form: IForm, campusId: string, buildingId: string, floorId: string, roomId: string) =>
        API.get(
            endpoints.masterData.items.filter
                .replace('{campusId}', campusId.toString())
                .replace('{buildingId}', buildingId.toString())
                .replace('{floorId}', floorId.toString())
                .replace('{roomId}', roomId.toString()),
            { params: form },
        ),
};

export default itemsService;
