import { CancelButton, SubmitButton } from '@silinfo/front-end-template';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

export default function Buttons(props: { buttonLoading: boolean; cancel: string; readOnly: boolean }) {
    const navigate = useNavigate();
    const { buttonLoading } = props;

    return (
        <Grid item container xs={12} spacing={1}>
            {!props.readOnly ? (
                <Grid item>
                    <SubmitButton type="submit" fullWidth loading={buttonLoading}>
                        Mentés
                    </SubmitButton>
                </Grid>
            ) : (
                <></>
            )}
            <Grid item>
                <CancelButton type="button" fullWidth onClick={() => navigate(props.cancel)}>
                    Mégsem
                </CancelButton>
            </Grid>
        </Grid>
    );
}
