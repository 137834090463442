import { GridRenderCellParams } from '@mui/x-data-grid';
import { ISemesterTable } from './types';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

export default function StatusCellRenderer(params: GridRenderCellParams<boolean, ISemesterTable>) {
    if (params.row.closed)
        return (
            <Tooltip title={new Date(params.row.closed).toLocaleDateString()}>
                <Chip label="Lezárt" color="error" />
            </Tooltip>
        );

    return params.value ? <Chip label="Aktív" color="success" /> : <Chip label="Inaktív" />;
}
