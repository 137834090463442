import Grid from '@mui/material/Grid';
import { Page, TabsCompleted } from '@silinfo/front-end-template';
import { useParams } from 'react-router-dom';
import BaseData from './BaseData';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import PointTable from './PointTable';
import { useEffect, useState } from 'react';
import instance from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { IGroupForm } from './types';
import Skeleton from '@mui/material/Skeleton';
import GroupElements from './GroupElements';
import QuestionnaireTemplateSelect from './QuestionnaireTemplateSelect';
import { useTenant } from '../../../components/TenantContext';

const initialValues: IGroupForm = {
    id: 0,
    admissionId: 0,
    active: false,
    closed: false,
    name: '',
};

export default function GroupForm() {
    const { groupId } = useParams();
    const [groupForm, setGroupForm] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const readonly = groupForm.active || groupForm.closed;
    const tenant = useTenant();

    const header = {
        project: tenant || 'TAR',
        title: `${groupId ? 'Csoport szerkesztése' : 'Csoport létrehozása'}`,
        breadcrumbs: {
            masterData: 'Felvételi',
            [clientEndPoints.admission_administration]: 'Adminisztráció',
            form: `${groupId ? 'Csoport szerkesztése' : 'Csoport létrehozása'}`,
        },
    };

    useEffect(() => {
        if (groupId) {
            setLoading(true);
            instance
                .get(endpoints.admission.admissionGroup.getAdmission.replace('{groupId}', groupId))
                .then((response) => setGroupForm(response.data.data))
                .finally(() => setLoading(false));
        }
    }, [groupId]);

    const tabContents = [
        {
            label: 'Csoport alapadatai',
            value: <BaseData groupForm={groupForm} setGroupForm={setGroupForm} readonly={readonly} />,
        },
        {
            label: 'Csoport elemei',
            value: <GroupElements groupName={groupForm.name} readonly={readonly} />,
            disabled: !groupId,
        },
        {
            label: 'Űrlap sablon, beállítások',
            value: <QuestionnaireTemplateSelect admissionId={groupForm.admissionId} readonly={readonly} />,
            disabled: !groupId,
        },
        {
            label: 'Pontozó tábla beállításai',
            value: <PointTable admissionId={groupForm.admissionId} readonly={readonly} closed={groupForm.closed} />,
            disabled: !groupId,
        },
    ];

    if (loading) {
        return <Skeleton />;
    }

    return (
        <Page header={header}>
            <Grid item xs={12}>
                <TabsCompleted tabContents={tabContents} />
            </Grid>
        </Page>
    );
}
