import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { setEdit } from '../../../../../store/studentProfile';
import { LanguageExam } from '../types';
import { createLanguageExamLabel } from '../utils';
import EditContent from './EditContent';
import InfoContent from './InfoContent';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LanguageDialog = ({
    open,
    setOpen,
    languageExam,
    setLanguageExam,
}: {
    open: boolean;
    setOpen: (value: boolean) => void;
    setLanguageExam: (languageExam?: LanguageExam) => void;
    languageExam?: LanguageExam;
}) => {
    const { isEdit } = useSelector((state: RootState) => state.studentProfile);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(setEdit(false));
        setTimeout(() => setLanguageExam(undefined), 400); //transition miatt ne ugráljon
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" TransitionComponent={Transition}>
            <DialogTitle className="studentProfile">
                {languageExam ? createLanguageExamLabel(languageExam) : 'Hozzáadás'}
            </DialogTitle>
            {languageExam && (!isEdit || languageExam.validated === 'yes') ? (
                <InfoContent languageExam={languageExam} handleClose={handleClose} />
            ) : (
                <EditContent setOpen={setOpen} languageExam={languageExam} handleClose={handleClose} />
            )}
        </Dialog>
    );
};

export default LanguageDialog;
