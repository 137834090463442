import Floors from '../../../pages/MasterData/General/Campuses/Buildings/Floors/Floors';
import FloorsForm from '../../../pages/MasterData/General/Campuses/Buildings/Floors/FloorsForm';
import { campusesBuildingsFloorsRooms } from './CampusesBuildingsFloorsRooms';

export const campusesBuildingsFloors = {
    path: ':buildingId/floors',
    children: [
        {
            path: '',
            element: <Floors />,
            name: 'md_general_campuses_buildings_floors_list',
        },
        {
            path: ':floorId/show',
            element: <FloorsForm readOnly />,
            name: 'md_general_campuses_buildings_floors_show',
        },
        campusesBuildingsFloorsRooms,
    ],
};
