import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { create } from '../../../store/notification';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import Delete from '@mui/icons-material/Delete';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Dialog, SubmitButton, TableButton } from '@silinfo/front-end-template';

interface ElementOperationsCellRendererProps extends GridRenderCellParams {
    setRefresh: Dispatch<SetStateAction<boolean>>;
    groupId: string;
    groupName: string;
}

export default function ElementOperationsCellRenderer(props: ElementOperationsCellRendererProps) {
    const { setRefresh, groupId, row, groupName } = props;
    const dispatch = useDispatch();

    const handleDelete = useCallback(() => {
        const deleteEndpoint = endpoints.admission.admissionGroupElement.delete
            .replace('{groupId}', groupId)
            .replace('{elementId}', row.id);

        API.delete(deleteEndpoint)
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres törlés.' }));
                setRefresh((prev) => !prev);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés.' })));
    }, [row.id, groupId, setRefresh, dispatch]);

    return (
        <Dialog
            title="Csoport elem törlése"
            opener={
                <TableButton color="error" tooltip="Csoport elem törlése">
                    <Delete />
                </TableButton>
            }
            action={<SubmitButton onClick={handleDelete}>Igen</SubmitButton>}
            cancelText="Mégsem"
        >
            <DialogContent>
                <DialogContentText>Biztosan törli a(z) {groupName} nevű csoport ezen elemét?</DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
