import Info from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Loading } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction, useState } from 'react';
import { ICourseView } from '../../pages/CourseManagement/Courses/types';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';
import BaseData from '../Requirements/SemesterLevel/BaseData';
import CloseButton from '../Requirements/SemesterLevel/CloseButton';
import Documents from '../Requirements/SemesterLevel/Documents';
import Events from '../Requirements/SemesterLevel/Events';
import InstructorData from '../Requirements/SemesterLevel/InstructorData';
import Criteria from './Criteria';
import RightPanel from './RightPanel';
import CourseName from '../Requirements/SemesterLevel/CourseName';

const Content = ({ id, setOpen }: { id: string; setOpen: Dispatch<SetStateAction<boolean>> }) => {
    const { data, loading } = useFetch<{ data: ICourseView }>(
        endpoints.courseManagement.courseList.coursesByCollegeYear + '/' + id,
        '',
    );

    return (
        <DialogContent>
            {loading ? (
                <Loading noHeight />
            ) : (
                <Grid container spacing={2}>
                    <CloseButton setOpen={setOpen} />
                    <CourseName {...data.data} />
                    <BaseData {...data.data} />
                    <InstructorData {...data.data} />
                    <Documents {...data.data} />
                    <Criteria {...data.data} />
                    <RightPanel {...data.data} />
                    <Events {...data.data} />
                </Grid>
            )}
        </DialogContent>
    );
};

export default function CourseDialog({ id }: { id: string }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button startIcon={<Info />} onClick={() => setOpen(true)}>
                Kurzus adatai
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                fullWidth
                maxWidth="lg"
            >
                {open && <Content id={id} setOpen={setOpen} />}
            </Dialog>
        </>
    );
}
