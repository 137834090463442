import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import ChangesView from './ChangesView';
import { IProcessesRow } from './ProcessesTable';

type BtnCellRendererProps = {
    params: GridRenderCellParams<IProcessesRow>;
};

export default function BtnCellRenderer({ params }: BtnCellRendererProps) {
    return <ChangesView row={params.row} />;
}
