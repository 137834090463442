import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import { useSearchParams } from 'react-router-dom';

export default function ViewDataButton(props: GridRenderCellParams<unknown, IForm>) {
    const { row } = props;
    const [_, setSearchParams] = useSearchParams();

    const handleClick = () => {
        setSearchParams({ tab: '1', trainingProgram: '' + row.trainingProgramId, semester: '' + row.semesterId });
        document.location.reload(); // nem szép megoldás; TARDEV-886 (refakt) jeggyel javítandó
    };

    return (
        <IconButton color="primary" onClick={handleClick}>
            <Visibility />
        </IconButton>
    );
}
