import { useEffect, useState } from 'react';
import { getBase64 } from '../../../../../utils/AppConst';
import { endpoints } from '../../../../../utils/endPoints';

interface ProfilePictureAdminState {
    loading: boolean;
    profilePicture: string;
}

export default function useProfilePictureAdmin(studentId: string | number | undefined): ProfilePictureAdminState {
    const [loading, setLoading] = useState(false);
    const [profilePicture, setProfilePicture] = useState('');
    const profilePictureUrl = `${endpoints.fileManagement.download}?id=${studentId}&type=profile-image`;

    useEffect(() => {
        setLoading(true);
        getBase64(profilePictureUrl)
            .then(setProfilePicture)
            .finally(() => setLoading(false));
    }, [setProfilePicture, profilePictureUrl]);

    useEffect(() => {
        if (studentId) setProfilePicture('');
    }, [studentId]);

    return { loading, profilePicture };
}
