import Error from '@mui/icons-material/Error';
import Grid from '@mui/material/Grid';
import { FieldGenerator, TabsCompleted } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import LanguageTab from './LanguageTab';
import { INotificationForm, NotificationKey } from './types';
import { createFields, languages } from './utils';

const AccordionForm = ({ value }: { value: NotificationKey }) => {
    const formikProps = useFormikContext<INotificationForm>();
    const { values, errors } = formikProps;
    const fields = useMemo(() => createFields(value), [value]);

    // Check if a language tab contains an error
    const hasError = (language: string): boolean =>
        !!errors[('types.' + value + '.message.translations.' + language + '.title') as keyof INotificationForm] ||
        !!errors[('types.' + value + '.message.translations.' + language + '.content') as keyof INotificationForm];

    return (
        <Grid item container xs={12} spacing={2}>
            {FieldGenerator({
                ...formikProps,
                ...fields[0],
                format: { xs: 16, md: 6 },
                fieldType: 'base',
                props: {
                    value: values.types[value].message.identification,
                    error: errors[('types.' + value + '.message.identification') as keyof INotificationForm],
                    helperText: errors[('types.' + value + '.message.identification') as keyof INotificationForm],
                },
            })}
            {FieldGenerator({
                ...formikProps,
                ...fields[1],
                format: { xs: 16, md: 6 },
                fieldType: 'base',
                props: {
                    value: values.types[value].message.name,
                    error: errors[('types.' + value + '.message.name') as keyof INotificationForm],
                    helperText: errors[('types.' + value + '.message.name') as keyof INotificationForm],
                },
            })}
            <Grid item xs={12}>
                <TabsCompleted
                    tabContents={languages.map((language) => ({
                        value: <LanguageTab language={language.value} value={value} />,
                        label: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {language.label}{' '}
                                {hasError(language.value) && <Error color="error" style={{ marginLeft: '4px' }} />}
                            </div>
                        ),
                    }))}
                />
            </Grid>
        </Grid>
    );
};

export default AccordionForm;
