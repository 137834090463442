import SystemLog from '../../pages/Administration/SystemLog';
import { systemLogSubMenus } from '../../components/Layout';
import { systemLogs } from './SystemLog/SystemLogs';
import { emailLogs } from './SystemLog/EmailLogs';
import { eventLogs } from './SystemLog/EventLogs';
import { charmLogs } from './SystemLog/CharmLogs';

export const log = {
    path: 'log',
    children: [
        {
            path: '',
            element: <SystemLog name="Napló" submenus={systemLogSubMenus} />,
            name: 'admin_sys_log',
        },
        systemLogs,
        emailLogs,
        eventLogs,
        charmLogs,
    ],
};
