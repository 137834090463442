import { theme } from '../../../../../utils/theme';
import { ICompletionData, ICourse, TBaseCompletion, TCompletion, TEvaluation, TStatus } from './types';

export const INFO_COLOR = '#0288d1';

type TNotTranslatable =
    | 'id'
    | 'evaluationStatus'
    | 'scoringType'
    | 'worthCourseType'
    | 'worthCourse'
    | 'isSharedCourse'
    | 'courseTenantId'
    | 'courseCentralId';

export const smallTableTranslations: Record<keyof Omit<ICourse, TNotTranslatable>, string> = {
    courseCode: 'Kurzuskód',
    courseName: 'Kurzusnév',
    point: 'Érték',
    presence: 'Jelenlét',
    evaluation: 'Értékelés',
};

type TOmitFromCompletionData =
    | 'id'
    | 'courses'
    | 'disabled'
    | 'completionId'
    | 'filename'
    | 'completedCopper'
    | 'enrolledCoursesCopper'
    | 'student';

export const completionDataTranslations: Record<keyof Omit<ICompletionData, TOmitFromCompletionData>, string> = {
    name: 'Megnevezés',
    completed: 'Teljesített',
    enrolledCourses: 'Felvett kurzusok',
    minToBeCompleted: 'Minimum teljesítendő',
    mandatoryCourses: 'Kötelező kurzusok',
    electiveCourses: 'Köt. vál. kurzusok',
    coCourses: 'Társképzésből teljesítendő',
    languageExamRequirements: 'Nyelvvizsga követelmények',
    status: 'Státusz',
};

const completionBaseTranslations: Record<TBaseCompletion, string> = {
    success: 'Teljesített',
    failed: 'Nem teljesített',
};

export const getColorByEvaluationStatus = (evaluationStatus: TStatus): string => {
    switch (evaluationStatus) {
        case 'in_progress':
            return INFO_COLOR;
        case 'success':
            return theme.palette.success.main;
        default:
            return theme.palette.error.main;
    }
};

export const evaluationTranslations: Record<TEvaluation, string> = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    notEvaluable: 'Nem értékelhető',
    in_progress: 'Folyamatban',
    ...completionBaseTranslations,
};

export const getColorByCompletion = (mandatoryCourse: TCompletion): string => {
    switch (mandatoryCourse) {
        case 'failed':
            return theme.palette.error.main;
        case 'none':
            return theme.palette.common.black;
        default:
            return theme.palette.success.main;
    }
};

export const completionTranslations: Record<TCompletion, string> = {
    none: 'Nincs',
    ...completionBaseTranslations,
};

export const getColorByStatus = (status: TStatus): string => {
    switch (status) {
        case 'failed':
            return theme.palette.error.main;
        case 'in_progress':
            return INFO_COLOR;
        default:
            return theme.palette.success.main;
    }
};

export const getColorByCompletiondAndStatus = (
    completed: number,
    minToBeCompleted: number,
    status: TStatus,
): string => {
    if (status === 'in_progress') return INFO_COLOR;
    return theme.palette[completed >= minToBeCompleted ? 'success' : 'error'].main;
};

export const statusTranslations: Record<TStatus, string> = {
    ...completionBaseTranslations,
    in_progress: 'Folyamatban',
};
