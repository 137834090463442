import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Loading } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import instance from '../../../../../../../api';
import { ICompletionData, ITrainingCompletionData } from '../../types';
import { completionTranslations, getColorByCompletion } from '../../utils';

const Core = <T extends ICompletionData | ITrainingCompletionData>({
    name,
    row,
}: {
    name: 'mandatoryCourses' | 'languageExamRequirements';
    row: T;
}) => (
    <span style={{ color: getColorByCompletion(row[name]) }}>
        {row[name] ? completionTranslations[row[name]] : '-'}
    </span>
);

const DataRenderer = ({ data }: { data: [string, 'failed' | 'success'][] | null }) => {
    if (!data) {
        return (
            <Typography variant="body2" color="error">
                Hiba a betöltés során.
            </Typography>
        );
    }

    if (data.length === 0) {
        return <Typography variant="body2">Nincs megjeleníthető adat.</Typography>;
    }

    return (
        <>
            {data.map(([name, status]) => (
                <Grid container key={name} justifyContent="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h6">{name}</Typography>
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                        <Tooltip title={status === 'success' ? 'Teljesített' : 'Nem teljesített'}>
                            {status === 'success' ? <Done color="success" /> : <Close color="error" />}
                        </Tooltip>
                    </Grid>
                </Grid>
            ))}
        </>
    );
};

const PopperContent = ({
    row,
    cacheState,
    url,
    idKey = 'id',
}: {
    row: ICompletionData | ITrainingCompletionData;
    cacheState: [
        Record<string, 'failed' | 'success'> | null,
        Dispatch<SetStateAction<Record<string, 'failed' | 'success'> | null>>,
    ];
    url: (id: string | number) => string;
    idKey?: keyof (ICompletionData | ITrainingCompletionData);
}) => {
    const [cache, setCache] = cacheState;
    const [loading, setLoading] = useState(!cache);
    const data = cache ? Object.entries(cache) : null;
    const key = row[idKey];

    useEffect(() => {
        if (!cache) {
            setLoading(true);
            instance
                .get(url('' + key))
                .then((res) => {
                    setCache(res.data);
                })
                .finally(() => setLoading(false));
        }
    }, [cache, row.id, setCache, url, key]);

    return (
        <>
            <Paper>
                <div style={{ padding: '8px' }}>
                    {loading ? (
                        <div>
                            <Loading noHeight />
                        </div>
                    ) : (
                        <DataRenderer data={data} />
                    )}
                </div>
            </Paper>
        </>
    );
};

export default function RequirementCellRenderer<T extends ICompletionData | ITrainingCompletionData>({
    name,
    row,
    url,
    idKey = 'id',
}: {
    name: 'mandatoryCourses' | 'languageExamRequirements';
    row: T;
    url: (id: string | number) => string;
    idKey?: keyof (ICompletionData | ITrainingCompletionData);
}) {
    const isNone = row[name] === 'none' || !row[name];
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const cacheState = useState<Record<string, 'failed' | 'success'> | null>(null);

    return !isNone ? (
        <>
            <Button
                onClick={(e) => setAnchorEl((prev) => (prev ? null : e.currentTarget))}
                style={{ textTransform: 'none' }}
            >
                <Core row={row} name={name} />
            </Button>
            {anchorEl ? (
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Popper open={!!anchorEl} anchorEl={anchorEl} placement="bottom">
                        <PopperContent row={row} cacheState={cacheState} url={url} idKey={idKey} />
                    </Popper>
                </ClickAwayListener>
            ) : null}
        </>
    ) : (
        <Core row={row} name={name} />
    );
}
