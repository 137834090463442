import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import { EditMode } from './ActualStudiesEdit';
import { Option } from '../../../../utils/AppConst';
import { IActualStudies } from './utils';

export const AddMode = ({
    universities,
    legalRelationships,
    trainingForms,
    initialValues,
}: {
    universities: Option<string>[];
    legalRelationships: Option<string>[];
    trainingForms: Option<string>[];
    initialValues: IActualStudies;
}) => {
    const [isAdding, setIsAdding] = useState(false);

    if (!isAdding) {
        return (
            <IconButton color="success" onClick={() => setIsAdding(true)}>
                <Add />
            </IconButton>
        );
    } else {
        return (
            <EditMode
                universities={universities}
                legalRelationships={legalRelationships}
                trainingForms={trainingForms}
                initialValues={initialValues}
                setIsOneEdit={setIsAdding}
            />
        );
    }
};
