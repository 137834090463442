import { LoadingButton } from '@mui/lab';
import DialogBasic from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CancelButton, Loading } from '@silinfo/front-end-template';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { FieldGenerator } from '../../../components/Form/FieldGenerator';
import Form from '../../../components/Form/Form';
import langExamReportService from '../../../services/administration/langExamReport';
import languageExamRequirements from '../../../services/masterData/languageExamRequirements';
import { Option } from '../../../utils/AppConst';
import { languageExamTypeOptions, validatedOptions } from '../../MasterData/General/Students/Form/options';
import { endpoints } from '../../../utils/endPoints';
import ConfirmationDialog from './ConfirmationDialog';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { LanguageExamType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

interface LangExportDialogFormProps {
    opener: JSX.Element;
    handleClose: () => void;
    objectId: number;
    setTableLoading: (val: boolean) => void;
}

export default function LangExportDialogForm(props: LangExportDialogFormProps) {
    return <DialogElement {...props} />;
}

// Dialog element létrehozás
function DialogElement(props: LangExportDialogFormProps) {
    const classes = useStyles();

    return (
        <DialogBasic open={true} onClose={props.handleClose} fullWidth>
            <DialogTitle className={classes.title}>Nyelvvizsga szerkesztése</DialogTitle>
            <DialogContent>
                <LangForm {...props} />
            </DialogContent>
        </DialogBasic>
    );
}

interface LangFormOptions {
    categories: Option[];
    levels: Option[];
    languages: Option[];
    types: Option[];
}

export interface ILangExamReportForm extends IForm {
    acquisitonDate: string;
    acquisitonPlace: string;
    category: string;
    certificateFileName: string;
    certificateFilePath: string;
    language: string;
    level: string;
    type: LanguageExamType;
    validated: string;
    validatedConfirmation: number;
}

export const initialLangExamReportForm: ILangExamReportForm = {
    acquisitonDate: '',
    acquisitonPlace: '',
    category: '',
    certificateFileName: '',
    certificateFilePath: '',
    language: '',
    level: '',
    type: null,
    validated: 'yes',
    validatedConfirmation: 0,
};

// Űrlap generálás
function LangForm(props: LangExportDialogFormProps) {
    const { handleClose, objectId, setTableLoading } = props;
    const [initialValues, setInitialValues] = useState(initialLangExamReportForm);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [options, setOptions] = useState<LangFormOptions>({
        categories: [],
        levels: [],
        languages: [],
        types: languageExamTypeOptions,
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        axios
            .all([
                languageExamRequirements.categories(),
                languageExamRequirements.levels(),
                languageExamRequirements.languages(),
                langExamReportService.get(objectId),
            ])
            .then((res) => {
                setOptions((prev) => {
                    res[2].data.shift();
                    return {
                        ...prev,
                        categories: res[0].data,
                        levels: res[1].data,
                        languages: [...res[2].data, { value: 'WE', label: 'Egyéb' }],
                    };
                });
                setInitialValues(res[3].data.data);
            })
            .finally(() => setInitialLoading(false));
    }, [objectId]);

    const submitAction = useCallback(
        (form: IForm) => {
            setLoadingForm(true);
            return langExamReportService
                .save(objectId, form)
                .then(() => {
                    handleClose();
                    setTableLoading(false);
                })
                .finally(() => {
                    setLoadingForm(false);
                });
        },
        [handleClose, objectId, setTableLoading],
    );

    const handleSubmit = (form: IForm) => {
        const validated = form.validated === 'yes' || form.validated === 'no' || form.validated == 'waiting';
        const initialValidated = initialValues.validated === 'yes' || initialValues.validated === 'no';
        if (
            JSON.stringify(form) !== JSON.stringify(initialValues) &&
            validated &&
            initialValidated &&
            form.validatedConfirmation !== 1
        ) {
            setOpen(true);
        } else {
            return submitAction(form);
        }
    };

    if (initialLoading) return <Loading noHeight />;

    return (
        <Form
            hideButtons
            fields={[]}
            onSubmit={handleSubmit}
            submitTransformData={(form) =>
                Object.entries(form).reduce(
                    (prev, [key, value]: [string, unknown]) => ({
                        ...prev,
                        [key]: Array.isArray(value)
                            ? value.map((elem) => elem?.value || elem)
                            : (value as Option)?.value || value,
                    }),
                    {},
                ) as IForm
            }
            initialValues={initialValues}
        >
            {(props) => {
                return loadingForm ? (
                    <Loading noHeight />
                ) : (
                    <>
                        {FieldGenerator({
                            name: 'language',
                            label: 'Nyelv',
                            type: 'select',
                            options: options?.languages,
                            format: { xs: 12, sm: 12, md: 12 },
                            ...props,
                        })}
                        {FieldGenerator({
                            name: 'type',
                            label: 'Típus',
                            type: 'select',
                            options: options?.types,
                            format: { xs: 12, sm: 12, md: 12 },
                            ...props,
                        })}
                        {FieldGenerator({
                            name: 'level',
                            label: 'Szint',
                            type: 'select',
                            options: options?.levels,
                            format: { xs: 12, sm: 12, md: 12 },
                            ...props,
                        })}
                        {FieldGenerator({
                            name: 'category',
                            label: 'Kategória',
                            type: 'select',
                            options: options?.categories,
                            format: { xs: 12, sm: 12, md: 12 },
                            ...props,
                        })}
                        {FieldGenerator({
                            name: 'acquisitonDate',
                            label: 'Szerzés dátuma',
                            type: 'date',
                            format: { xs: 12, sm: 12, md: 12 },
                            ...props,
                        })}
                        {FieldGenerator({
                            name: 'acquisitonPlace',
                            label: 'Szerzés helye',
                            type: 'text',
                            format: { xs: 12, sm: 12, md: 12 },
                            ...props,
                        })}
                        <Grid item {...{ xs: 12, sm: 12 }}>
                            {FieldGenerator({
                                name: 'name',
                                label: 'Bizonyítvány',
                                type: 'file',
                                origFileName: 'certificateFileName',
                                uuidFileName: 'certificateFilePath',
                                tempType: 'student-document',
                                downloadUrl: initialValues.certificateFileName
                                    ? `${endpoints.fileManagement.download}?id=${objectId}&type=student-document&subType=student-language-exam`
                                    : '',
                                format: { xs: 12, sm: 12 },
                                ...props,
                            })}
                        </Grid>

                        {FieldGenerator({
                            name: 'validated',
                            label: 'Validált',
                            type: 'select',
                            options: validatedOptions,
                            format: { xs: 12, sm: 12, md: 12 },
                            ...props,
                        })}

                        <Grid item container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <LoadingButton loading={loadingForm} variant="contained" type="submit">
                                    Mentés
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <CancelButton onClick={handleClose}>Bezár</CancelButton>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog open={open} setOpen={setOpen} handleSubmit={submitAction} />
                    </>
                );
            }}
        </Form>
    );
}
