export interface ITargetAudience {
    id: number;
    type: string;
    trainingProgram: string;
    trainingLevel: string;
    collegeYear: string;
    campus: string | null;
}

export interface ITargetAudience {
    id: number;
    type: string;
    trainingProgram: string;
    trainingLevel: string;
    collegeYear: string;
    campus: string | null;
}

export const initialTargetAudienceForm = {
    id: 0,
    tenant: '',
    campus: '',
    trainingLevel: '',
    trainingProgram: '',
    collegeYear: '',
};

export type ITargetAudienceForm = typeof initialTargetAudienceForm;
