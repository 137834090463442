import { Option } from '../../utils/AppConst';
import { CounterProps, IEvent } from './types';
import moment from 'moment';

export type TEventType = Option & { color: 'primary' | 'secondary' | 'error' | 'info' };

export const isThisWeek = (date: Date) => {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    return date >= firstDayOfWeek && date <= lastDayOfWeek;
};

export const getCounters = (eventType: TEventType, data: IEvent[]): CounterProps => ({
    ...eventType,
    value: data.filter((event) => event.type === eventType.value && isThisWeek(moment(event.date).toDate())).length,
});

export const eventTypes: TEventType[] = [
    { value: 'lecture', label: 'Előadás', color: 'secondary' },
    { value: 'exam', label: 'Vizsga', color: 'primary' },
    { value: 'deadline', label: 'Határidő', color: 'error' },
    { value: 'other', label: 'Egyéb', color: 'info' },
];

export const getDay = (dateToGetFrom: Date) => dateToGetFrom.toLocaleDateString('hu-HU', { weekday: 'long' });
export const oneYearTime = 1000 * 60 * 60 * 24 * 365;

export const header = (tenant: string | null) => {
    return {
        title: 'Naptár',
        project: tenant || 'TAR',
        breadcrumbs: {
            calendar: 'Naptár',
        },
    };
};
