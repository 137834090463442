import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { TScoringType } from '../../../services/masterData/courses/courseTypes';
import { getScoringTypeText } from '../../../pages/CourseManagement/Courses/BaseData/utils';

export interface PerformanceIndicatorProps {
    color: 'error' | 'info' | 'success';
    label: string;
    value: number | [number, number];
    subValue?: number | [number, number];
    ratio: number;
    Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
}

const getTooltip = (value?: number | [number, number], scoringType?: TScoringType) => {
    if (!(value !== undefined && scoringType)) {
        return null;
    }

    if (Array.isArray(value)) {
        return (
            <div>
                <div>
                    Minimum {value[0]} {getScoringTypeText(scoringType)}
                </div>{' '}
                <div>
                    Maximum {value[1]} {getScoringTypeText(scoringType)}
                </div>
            </div>
        );
    }
    return `${value} ${getScoringTypeText(scoringType)}`;
};

const PerformanceIndicator = ({ color, label, value, ratio, Icon, subValue }: PerformanceIndicatorProps) => (
    <Grid item container xs={4} md={2} spacing={1} alignItems="center" justifyContent="center" alignSelf="flex-start">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CircularProgressWithLabel color={color} value={ratio}>
                <Icon color={color} />
            </CircularProgressWithLabel>
        </Grid>
        <Grid item xs={12}>
            <Typography textAlign="center">{label}</Typography>
            <Tooltip title={getTooltip(value, 'point')}>
                <Typography textAlign="center">
                    {Array.isArray(value) ? value[0] + ' - ' + value[1] : value} pont
                </Typography>
            </Tooltip>
            {color !== 'error' ? (
                <Tooltip title={getTooltip(subValue, 'copper')}>
                    <Typography textAlign="center">
                        {Array.isArray(subValue) ? subValue[0] + ' - ' + subValue[1] : subValue} garas
                    </Typography>
                </Tooltip>
            ) : null}
        </Grid>
    </Grid>
);

export default PerformanceIndicator;
