import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CourseDialog from '../../CourseDialog';
import { Rating } from '../Rating';
import FillQuestionnaireButton from '../FillQuestionnaireButton';
import Tooltip from '@mui/material/Tooltip';
import { notCountedText } from '../utils';
import { ViewProps } from './types';
import { Cell as BasicCell } from './Cell';
import { CellProps } from '../type';
import { useCallback } from 'react';

export const DesktopView = ({
    notCounted,
    pointsBeforeEvaluation,
    pointsAfterEvaluation,
    courseTypeCounted,
    ...props
}: ViewProps) => {
    const Cell = useCallback(
        (props: CellProps & { notCounted: boolean }) => (
            <BasicCell
                {...props}
                style={{
                    backgroundColor: notCounted ? '#eee' : '',
                }}
            />
        ),
        [notCounted],
    );

    const getPoint = (val: number) => (!courseTypeCounted || notCounted ? '-' : val || '-');

    return (
        <Tooltip title={!courseTypeCounted || notCounted ? notCountedText : ''}>
            <Grid item container columns={36} xs={36} spacing={1}>
                <Cell width={7} notCounted={!courseTypeCounted || notCounted}>
                    {props.courseCode}
                </Cell>
                <Cell style={{ textAlign: 'left' }} width={17} notCounted={!courseTypeCounted || notCounted}>
                    {props.courseName}
                </Cell>
                <Cell width={3} notCounted={notCounted}>
                    <Typography textAlign="center">
                        {getPoint(props.evaluation ? +pointsAfterEvaluation : +pointsBeforeEvaluation)}{' '}
                    </Typography>
                </Cell>
                <Grid item xs={5}>
                    <Rating rating={props.evaluation || ''} />
                </Grid>
                <Grid item container alignItems="center" xs={1} spacing={1}>
                    <Grid item xs={6}>
                        <CourseDialog notCounted={notCounted} courseTypeCounted={courseTypeCounted} {...props} />
                    </Grid>
                    {props.availableQuestionnaire ? (
                        <Grid item xs={6}>
                            <FillQuestionnaireButton isAnonymous={props.questionnaireIsAnonymous} id={props.id} />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Tooltip>
    );
};
