import { FormikListPage, Header, TextField, useFetch } from '@silinfo/front-end-template';
import { defaultTheme } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { header, breadcrumbs, tableProps, service } from './utils';
import { ThemeProvider } from '@mui/styles';
import {
    ConfirmationCertificateProgramSelected,
    TrainingProgramOption,
    initialConfirmationCertificateProgramSelected,
} from './types';
import SelectedExportButton from '../../../../components/Buttons/SelectedExportButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useTenant } from '../../../../components/TenantContext';

export default function ConfirmationTrainingProgram() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [trainingProgram, setTrainingProgram] = useState<TrainingProgramOption | null>(null);
    const [selected, setSelected] = useState<ConfirmationCertificateProgramSelected>(
        initialConfirmationCertificateProgramSelected,
    );
    const { data, loading } = useFetch<TrainingProgramOption[]>(
        endpoints.administration.certificates.confirmation.preTrainingProgramOptions,
        '',
    );
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');

    const exportContext = {
        initiateExport: () => service(trainingProgram?.value).initiateExport(selected),
        checkExport: service(trainingProgram?.value).checkExport,
        downloadExport: service(trainingProgram?.value).downloadExport,
        selected: selected,
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...headerWithTenant, breadcrumbs }} />
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    <Skeleton height="50px" />
                ) : (
                    <Autocomplete
                        options={data}
                        value={trainingProgram}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => setTrainingProgram(value)}
                        getOptionLabel={(option) => option.label}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Képzési programok" />}
                        renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: TrainingProgramOption) => (
                            <li {...props} style={{ color: !option.archive ? 'black' : '#606060' }}>
                                {option.label}
                            </li>
                        )}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {trainingProgram ? (
                    <FormikListPage
                        {...tableProps(setSelected, trainingProgram.value, user.archive, tenant)}
                        customButtons={
                            !user.archive ? (
                                <Grid item>
                                    <SelectedExportButton
                                        exportContext={exportContext}
                                        title={'Generálás'}
                                        disabled={selected.selected.length === 0}
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )
                        }
                    />
                ) : (
                    <ThemeProvider theme={defaultTheme}>
                        <Alert severity="info">Kérjük, válassz képzési programot az adatok megtekintéséhez</Alert>
                    </ThemeProvider>
                )}
            </Grid>
        </Grid>
    );
}
