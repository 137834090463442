import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Input } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../api';
import Form from '../../../components/Form/Form';
import { RootState } from '../../../store';
import { create } from '../../../store/notification';
import { setJWTToken } from '../../../utils/AuthHelper';
import { endpoints } from '../../../utils/endPoints';

const fields: Input[] = [{ name: 'email', label: 'E-mail', format: { xs: 12 } }];

const Edit = ({
    setOpen,
    setIsEditOpen,
}: {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setIsEditOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const { profile } = useSelector((state: RootState) => state.studentProfile);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    return (
        <Form
            fields={fields}
            onSubmit={(data: { email: string }, helpers) => {
                if (profile.email === data.email) {
                    dispatch(create({ type: 'info', message: 'Email címed nem változott.' }));
                    return;
                }
                setLoading(true);
                API.post(endpoints.changeEmailGenCode, data)
                    .then((response) => {
                        setOpen(true);
                        dispatch(create({ type: 'success', message: response.data.message }));
                    })
                    .catch((error) => {
                        if (error.response.data.emailEq) {
                            dispatch(create({ type: 'info', message: error.response.data.message }));
                        } else {
                            helpers.setErrors({ email: error.response.data.message });
                            dispatch(create({ type: 'error', message: error.response.data.message }));
                        }
                    })
                    .finally(() => setLoading(false));
            }}
            initialValues={{ email: profile.email }}
            hideButtons
        >
            {() => (
                <Grid item container xs={12} spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button variant="outlined" onClick={() => setIsEditOpen(false)}>
                            Mégsem
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingButton type="submit" variant="contained" loading={loading}>
                            Mentés
                        </LoadingButton>
                    </Grid>
                </Grid>
            )}
        </Form>
    );
};

export default function Email() {
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isEditOpen, setIsEditOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setIsEditOpen(false);
        setCode('');
        setEmailError('');
    };

    const handleSave = () => {
        setLoading(true);
        API.post(endpoints.changeEmailChange, { code: code })
            .then((response) => {
                setOpen(false);
                dispatch(create({ type: 'success', message: response.data.message }));

                setJWTToken(response.data.token);
                window.location.reload();

                setCode('');
                setEmailError('');
                setLoading(false);
            })
            .catch((error) => {
                setEmailError(error.response.data.message);
                dispatch(create({ type: 'error', message: error.response.data.message }));
            })
            .finally(() => setLoading(false));
    };

    if (user.archive) return <></>;

    return (
        <>
            <IconButton size="small" onClick={() => setIsEditOpen(true)}>
                <EditIcon />
            </IconButton>
            <Dialog open={isEditOpen} onClose={handleClose}>
                <DialogTitle>Szerkesztés</DialogTitle>
                <DialogContent sx={{ marginTop: '8px' }}>
                    <Edit setOpen={setOpen} setIsEditOpen={setIsEditOpen} />
                </DialogContent>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Megerősítés</DialogTitle>
                <DialogContent style={{ marginTop: '8px' }}>
                    <DialogContentText>
                        Az új e-mail címedre kapott kóddal tudod véglegesíteni a változtatást
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="code"
                        label="Kód"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(event) => setCode(event.target.value)}
                        value={code}
                        error={emailError !== ''}
                        helperText={emailError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Mégsem
                    </Button>
                    <LoadingButton loading={loading} variant="contained" onClick={handleSave}>
                        Mentés
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
