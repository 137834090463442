import Add from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Input } from '@silinfo/front-end-template';
import { Children, useState } from 'react';
import Chip from './Chip';
import Dialog from './Dialog';

export type TCard = { id: string; [key: string]: string | string[] };

export default function Card<T extends TCard>({
    label,
    initData,
    fields,
    children,
    url,
    title,
    readOnly,
}: {
    initData: T[];
    label: (value: T) => string;
    fields: (id?: string | number) => Input[];
    children: (value: T) => JSX.Element | JSX.Element[] | string;
    url: string;
    title: string;
    readOnly: boolean;
}) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<T | undefined>(undefined);
    return (
        <Grid item xs={12} container spacing={0.5}>
            {Children.toArray(
                initData.map((elem) => (
                    <Chip key={elem.id} label={label(elem)} value={elem} setOpen={setOpen} setValue={setValue} />
                )),
            )}
            {!readOnly ? (
                <Grid item>
                    <IconButton
                        color="success"
                        onClick={() => {
                            setValue(undefined);
                            setOpen(true);
                        }}
                    >
                        <Add />
                    </IconButton>
                </Grid>
            ) : (
                <></>
            )}

            <Dialog
                open={open}
                setOpen={setOpen}
                value={value}
                setValue={setValue}
                fields={fields}
                url={url}
                title={title}
                readOnly={readOnly}
            >
                {value ? children(value) : <i>Nincs megjeleníthető adat.</i>}
            </Dialog>
        </Grid>
    );
}
