import { useEffect, useState } from 'react';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../../utils/theme';
import { fieldTypeOrder } from './utils';
import BasicData from './BasicData/BasicData';
import MCCLoading from '../../../components/MCCLoading';
import AdmissionListMobile from './List/AdmissionListMobile';
import UploadDocuments from './UploadDocuments';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from './Header';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdmissionIconDisabled from './Icons/AdmissionsIconDisabled.svg';
import AdmissionsIconNotAvailable from './Icons/AdmissionsIconNotAvailable.svg';
import AdmissionIcon from './Icons/AdmissionIcon.svg';
import PersonIcon from './Icons/PersonIcon.svg';
import PersonIconDisabled from './Icons/PersonIconDisabled.svg';
import MobileHeader from './MobileHeader';
import { Field, SelectedRow } from './types';
import axios from 'axios';

export default function AdmissionComponent() {
    const [email, setEmail] = useState<string>('');
    const [semester, setSemester] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [fields, setFields] = useState<Field[]>([]);
    const [isForeignStudent, setIsForeignStudent] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<SelectedRow | null>(null);
    const [admissionReload, setAdmissionReload] = useState<boolean>(false);
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const isRegistrated = location.pathname.includes('/mcc');
    const token = window.location.pathname.split('/')[2];
    const navigate = useNavigate();
    const [tagParams, setTabParams] = useSearchParams();
    const [value, setValue] = useState(tagParams.get('tab') || '1');
    const [dataFilled, setDataFilled] = useState(false);

    const handleAdmissionReload = () => {
        setAdmissionReload(!admissionReload);
    };

    useEffect(() => {
        (() => {
            const endpoint = isRegistrated
                ? endpoints.admission.checkMccToken
                : endpoints.admission.checkToken.replace('{token}', token);
            const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
            request
                .then((response) => {
                    setIsForeignStudent(response.data.data.foreignStudent);
                    setEmail(response.data.data.email);
                    const fetchedFields: Field[] = response.data.data.fields;
                    const sortedFields: Field[] = [];
                    fieldTypeOrder.forEach((type) => {
                        const nextElement = fetchedFields.find((e) => e.type === type);
                        if (nextElement) {
                            sortedFields.push(nextElement);
                        }
                    });
                    const nameField = fetchedFields.find((e) => e.type === 'firstName')?.value;
                    if (!nameField) {
                        setDataFilled(false);
                    } else {
                        setDataFilled(true);
                    }
                    setFields(sortedFields);
                    if (response.data.data.email === 'notFound') {
                        setStatus('notFound');
                    } else if (response.data.data.email === 'expired') {
                        setStatus('expired');
                    } else {
                        axios.get(endpoints.admission.period).then((response) => {
                            setStatus(response.data.status);
                            if (response.data.semester) {
                                setSemester(response.data.semester);
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (error.response?.data.status === 'notFound') {
                        navigate('/felveteli/token-not-found');
                        setStatus('notFound');
                    } else if (error.response?.data.status === 'expired') {
                        navigate('/felveteli/expired-token');
                        setStatus('expired');
                    } else if (error.response?.data.status === 'closed') {
                        navigate('/felveteli');
                        setStatus('closed');
                    } else {
                        navigate('/felveteli');
                    }
                });
        })();
    }, [isRegistrated, token, navigate]);

    const refreshFields = () => {
        const endpoint = isRegistrated
            ? endpoints.admission.checkMccToken
            : endpoints.admission.checkToken.replace('{token}', token);
        const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
        request.then((response) => {
            setIsForeignStudent(response.data.data.foreignStudent);
            setEmail(response.data.data.email);
            const fetchedFields: Field[] = response.data.data.fields;
            const sortedFields: Field[] = [];
            fieldTypeOrder.forEach((type) => {
                const nextElement = fetchedFields.find((e) => e.type === type);
                if (nextElement) {
                    sortedFields.push(nextElement);
                }
            });
            const nameField = fetchedFields.find((e) => e.type === 'firstName')?.value;
            if (!nameField) {
                setDataFilled(false);
            } else {
                setDataFilled(true);
            }
            setFields(sortedFields);
            if (response.data.data.email === 'notFound') {
                setStatus('notFound');
            } else if (response.data.data.email === 'expired') {
                setStatus('expired');
            } else {
                axios.get(endpoints.admission.period).then((response) => {
                    setStatus(response.data.status);
                    if (response.data.semester) {
                        setSemester(response.data.semester);
                    }
                });
            }
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const refreshNeeded = localStorage.getItem('refresh') === 'refresh';
            if (refreshNeeded) {
                localStorage.setItem('refresh', 'idle');
                location.reload();
            }
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    if (!status) {
        return <MCCLoading />;
    }

    const getNotOpenStatusLabel = () => {
        switch (status) {
            case 'notFound':
                return 'Nem létezik ez az azonosító!';
            case 'expired':
                return 'Az azonosító token lejárt!';
            default:
                return 'Sajnáljuk, de jelenleg nincs felvételi időszak. Kérjük, térj vissza később!';
        }
    };

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setTabParams({ tab: newValue });
    };

    const handleNavigateToAdmissions = () => {
        setValue('2');
        setTabParams({ tab: '2' });
        const endpoint = isRegistrated
            ? endpoints.admission.checkMccToken
            : endpoints.admission.checkToken.replace('{token}', token);
        const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
        request.then((response) => {
            setEmail(response.data.data.email);
            const fetchedFields: Field[] = response.data.data.fields;
            const sortedFields: Field[] = [];
            fieldTypeOrder.forEach((type) => {
                const nextElement = fetchedFields.find((e) => e.type === type);
                if (nextElement) {
                    sortedFields.push(nextElement);
                }
            });
            const nameField = fetchedFields.find((e) => e.type === 'firstName')?.value;
            if (!nameField) {
                setDataFilled(false);
            } else {
                setDataFilled(true);
            }
            setFields(sortedFields);
        });
    };

    return status === 'open' ? (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                ...(!matches && { overflow: 'hidden', height: '100dvh' }),
            }}
        >
            {matches ? (
                <Header email={email} semester={semester} matches={matches} status={status} />
            ) : (
                <MobileHeader semester={semester} />
            )}
            <Grid
                style={{
                    height: '100%',
                    width: '100%',
                    ...(!matches && { overflow: 'auto' }),
                    ...(matches && { paddingLeft: '0.75rem', paddingRight: '0.75rem', marginTop: '2rem' }),
                }}
            >
                <TabContext value={value}>
                    <Grid sx={{ ...(!matches && { paddingLeft: '0.5rem', paddingRight: '0.5rem' }) }}>
                        <TabList
                            className="borderRadius"
                            onChange={handleChange}
                            variant={matches ? 'fullWidth' : 'standard'}
                            sx={{
                                height: '3rem',
                                alignItems: 'center',
                                borderRadius: matches ? '15px 15px 0 0 !important' : '5px !important',
                                ...(!matches && {
                                    display: 'flex',
                                    marginBottom: '1rem',
                                    width: '100%',
                                    minHeight: '0',
                                    height: '2rem',
                                    marginTop: '1rem',
                                }),
                            }}
                        >
                            <Tab
                                className="borderRadius"
                                icon={
                                    <img
                                        src={value === '1' ? PersonIcon : PersonIconDisabled}
                                        style={{ ...(!matches && value !== '1' && { marginLeft: '0.5rem' }) }}
                                    />
                                }
                                iconPosition="start"
                                label={
                                    <Typography
                                        sx={{
                                            color: `${value === '1' ? '#f3ca12;' : '#364354'} !important`,
                                            fontSize: matches ? '18px' : '12px',
                                            fontFamily: "'Roboto', sans-serif !important",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {(value === '1' || matches) && 'Személyes adatok megadása'}
                                    </Typography>
                                }
                                value="1"
                                sx={{
                                    backgroundColor: `${
                                        value === '1' ? `${matches ? '#4A5C73' : '#364354'}` : '#C1CBD7'
                                    } !important`,
                                    textTransform: 'none',
                                    borderRadius: '20px, 0px 0px 0px !important',
                                    ...(value === '1' && !matches && { width: '75%' }),
                                    ...(value !== '1' && !matches && { width: '25%' }),
                                    ...(!matches && {
                                        borderRadius: '20px !important',
                                    }),
                                }}
                            />
                            <Tab
                                icon={
                                    <img
                                        src={
                                            value === '2'
                                                ? AdmissionIcon
                                                : dataFilled
                                                ? AdmissionIconDisabled
                                                : AdmissionsIconNotAvailable
                                        }
                                        style={{ ...(!matches && value !== '2' && { marginLeft: '0.5rem' }) }}
                                    />
                                }
                                iconPosition="start"
                                label={
                                    <Typography
                                        sx={{
                                            color: `${
                                                dataFilled ? (value === '2' ? '#f3ca12;' : '#364354') : '#899BB3'
                                            } !important`,
                                            fontSize: matches ? '18px' : '12px',
                                            fontFamily: "'Roboto', sans-serif !important",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {(value === '2' || matches) && 'Képzés kiválasztása'}
                                    </Typography>
                                }
                                value="2"
                                sx={{
                                    backgroundColor: `${
                                        value === '2' ? `${matches ? '#4A5C73' : '#364354'}` : '#C1CBD7'
                                    } !important`,
                                    textTransform: 'none',
                                    ...(value === '2' && !matches && { width: '75%' }),
                                    ...(value !== '2' && !matches && { width: '25%' }),
                                    ...(!matches && {
                                        borderRadius: '20px !important',
                                    }),
                                }}
                                disabled={!dataFilled}
                            />
                        </TabList>
                    </Grid>
                    <TabPanel
                        className="borderRadius"
                        value="1"
                        sx={{
                            ...(matches && { background: '#FFFFFF' }),
                            borderRadius: '0px 0px 20px 20px !important',
                            ...(!matches && { padding: 0 }),
                        }}
                    >
                        <BasicData
                            fields={fields}
                            isRegistrated={isRegistrated}
                            token={token}
                            handleNavigateToAdmissions={handleNavigateToAdmissions}
                            refreshFields={refreshFields}
                            isForeignStudent={isForeignStudent}
                            matches={matches}
                        />
                    </TabPanel>
                    <TabPanel
                        className="borderRadius"
                        value="2"
                        sx={{
                            ...(matches && { background: '#FFFFFF' }),
                            borderRadius: '0px 0px 20px 20px !important',
                            ...(!matches && { paddingLeft: '0.5rem', paddingRight: '0.5rem', paddingTop: '0.15rem' }),
                        }}
                    >
                        {fields.length > 0 && (
                            <AdmissionListMobile
                                isRegistrated={isRegistrated}
                                token={token}
                                selectState={[selectedRow, setSelectedRow]}
                                matches={matches}
                            />
                        )}
                        {selectedRow && (
                            <UploadDocuments
                                isRegistrated={isRegistrated}
                                selectedRow={selectedRow}
                                matches={matches}
                                admissionReload={handleAdmissionReload}
                            />
                        )}
                    </TabPanel>
                </TabContext>
            </Grid>
        </div>
    ) : (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography sx={{ textAlign: 'center', fontSize: '25px', color: '#364354' }}>
                {getNotOpenStatusLabel()}
            </Typography>
        </div>
    );
}
