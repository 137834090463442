import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface SeparateLocationsCheckBoxProps {
    checkedState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    label: string;
    handleChange?: (newValue: boolean) => void;
    readOnly: boolean | undefined;
}

export default function SeparateLocationsCheckBox({
    checkedState,
    label,
    handleChange,
    readOnly,
}: SeparateLocationsCheckBoxProps) {
    const [checked, setChecked] = checkedState;

    const onChange = () => {
        setChecked((prev) => {
            if (handleChange) {
                handleChange(!prev);
            }
            return !prev;
        });
    };

    const changeEvent = () => {
        if (!readOnly) {
            onChange();
        }
    };
    return (
        <Grid item xs={12} container alignItems="center">
            <Checkbox
                name="separateLocation"
                value={checked}
                checked={!!checked}
                onChange={onChange}
                disabled={readOnly}
            />
            <Typography sx={{ ...(!readOnly && { cursor: 'pointer' }) }} onClick={changeEvent}>
                {label}
            </Typography>
        </Grid>
    );
}
