import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { CancelButton } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import pagesService from '../../../../services/administration/pages';
import { IPageVersion } from '../types';

export default function PrivacyPolicyModal({ open, close }: { open: boolean; close: () => void }) {
    const [privacyPolicy, setPrivacyPolicy] = useState<IPageVersion>();

    useEffect(() => {
        pagesService.getActivePrivacyStatementAdmission().then((response) => {
            setPrivacyPolicy(response.data);
        });
    }, []);

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '70vw',
                        '@media (max-width: 900px)': {
                            maxWidth: '90%',
                        },
                    },
                },
            }}
            fullWidth
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DialogTitle>{privacyPolicy?.page?.translations?.hu?.title}</DialogTitle>
                </Grid>
                <Grid item xs={12}>
                    <DialogContent
                        sx={{ paddingBottom: 0 }}
                        dangerouslySetInnerHTML={{ __html: privacyPolicy?.translations?.hu?.content ?? '' }}
                    ></DialogContent>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <CancelButton onClick={close}>Vissza</CancelButton>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}
