import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useCallback, useState } from 'react';
import SchedulerDialog from './Dialog';

export default function Scheduler() {
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => setOpen(true), []);

    return (
        <>
            <MenuItem onClick={handleOpen}>
                <ListItemIcon>
                    <CalendarMonthIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>Ösztöndíjak ütemezése</ListItemText>
            </MenuItem>
            <SchedulerDialog openState={[open, setOpen]} />
        </>
    );
}
