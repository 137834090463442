import CourseEvaluationReport from '../../pages/CourseManagement/CourseEvaluationReport';

const courseEvaluationReportRoute = {
    path: 'course-evaluation-report',
    children: [
        {
            path: '',
            element: <CourseEvaluationReport />,
            name: 'course_evaluation_report',
        },
    ],
};

export default courseEvaluationReportRoute;
