import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AdmissionGuideModal from './Dialogs/AdmissionGuideModal';
import { UserProfileActionsProps } from './types';
import AdmissionsStyle from './Admissions.module.scss';

const UserProfileActions: React.FC<UserProfileActionsProps> = ({
    handleGuide,
    openModal,
    handleCloseModal,
    logout,
}: UserProfileActionsProps) => {
    return (
        <Grid container item className={`${AdmissionsStyle.profileContainer}`}>
            <Grid container item alignItems="right" justifyContent="flex-end" spacing={0.5} onClick={handleGuide}>
                <Grid item>
                    <Typography sx={{}}>Segédlet</Typography>
                </Grid>
                <Grid item>
                    <DescriptionIcon className={`${AdmissionsStyle.profileClickable}`} />
                </Grid>
            </Grid>
            <AdmissionGuideModal open={openModal} handleClose={handleCloseModal} />
            <Grid container item alignItems="right" justifyContent="flex-end" spacing={0.5} onClick={logout}>
                <Grid item>
                    <Typography className={`${AdmissionsStyle.profileClickable}`} sx={{ paddingRight: '0.25rem' }}>
                        Kijelentkezés
                    </Typography>
                </Grid>
                <Grid item>
                    <ArrowForwardIcon className={`${AdmissionsStyle.profileClickable}`} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserProfileActions;
