import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const NoDataDisplay = ({ loading }: { loading: boolean }) => (
    <TableRow>
        <TableCell align="center" colSpan={3}>
            {loading ? 'Betöltés...' : 'Nincs megjeleníthető adat.'}
        </TableCell>
    </TableRow>
);

export default NoDataDisplay;
