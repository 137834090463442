import React, { useRef } from 'react';
import Grid from '@mui/material/Grid';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import { endpoints } from '../../../utils/endPoints';
import API from '../../../api';
import { downloadFile } from './utils';
import TextField from '@mui/material/TextField';
import { IAdmissionDocument } from './types';
import { create } from '../../../store/notification';
import { FormikProps } from 'formik';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function UploadRow({
    dispatch,
    admissionForm,
    setAdmissionForm,
    element,
    readonly,
    index,
    props,
}: {
    dispatch: React.Dispatch<unknown>;
    admissionForm: Record<string, unknown>;
    setAdmissionForm: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
    element: IAdmissionDocument;
    readonly: boolean;
    index: number;
    props: FormikProps<Record<string, unknown>>;
}) {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleFileDownload = (document: IAdmissionDocument) => {
        if (document.origFileName) {
            dispatch(create({ type: 'error', message: 'A funkció használatához a változtatások mentése szükséges.' }));
            return;
        }
        API.get(
            endpoints.admission.admissionDocument.fileDownload
                .replace('{admissionId}', document.admissionId.toString())
                .replace('{documentId}', document.id.toString()),
            { responseType: 'blob' },
        ).then((data) => {
            downloadFile(data.data, document.fileName);
        });
    };

    const handleFileUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleClearFileInput = (element: IAdmissionDocument) => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        if (admissionForm && Array.isArray(admissionForm.informationDocuments)) {
            const updatedInformationDocuments = admissionForm.informationDocuments.map((item) => {
                if (item.id === element.id) {
                    return {
                        ...item,
                        fileName: '',
                        filePath: '',
                    };
                }
                return item;
            });
            setAdmissionForm({
                ...admissionForm,
                informationDocuments: updatedInformationDocuments,
            });
        }
    };

    const handleFileChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, documentId: number) => {
            const fileTmp = event.target.files?.[0];
            if (!fileTmp) {
                return;
            }

            const formData = new FormData();
            formData.append('file', fileTmp as unknown as Blob);
            formData.append('type', 'admission_document');

            API.post(
                endpoints.admission.admissionDocument.fileUpload,
                formData as unknown as { file: File; type: string },
            )
                .then((response) => {
                    setAdmissionForm((prev) => ({
                        ...prev,
                        informationDocuments: (prev.informationDocuments as IAdmissionDocument[]).map(
                            (document: IAdmissionDocument) =>
                                document.id === documentId
                                    ? {
                                          ...document,
                                          origFileName: response.data.data.origFileName,
                                          filePath: response.data.data.tempPath,
                                          uuidFileName: response.data.data.uuidFileName,
                                      }
                                    : document,
                        ),
                    }));
                })
                .catch((err) => {
                    dispatch(create({ type: 'error', message: err.response.data.err ?? 'Sikertelen feltöltés' }));
                });
        },
        [dispatch, setAdmissionForm],
    );
    return (
        <Grid item container xs={12} key={element.id}>
            <Grid container sx={{ marginBottom: '10px' }} spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label="Dokumentum neve"
                        variant="outlined"
                        value={element.name}
                        size="small"
                        fullWidth
                        disabled={readonly}
                        onChange={(e) => {
                            setAdmissionForm((prev) => ({
                                ...prev,
                                informationDocuments: (prev.informationDocuments as IAdmissionDocument[]).map(
                                    (document: IAdmissionDocument) =>
                                        document.id === element.id
                                            ? {
                                                  ...document,
                                                  name: e.target.value,
                                              }
                                            : document,
                                ),
                            }));
                        }}
                        error={!!props.errors['informationName' + (index + 1)]}
                        helperText={props.errors['informationName' + (index + 1)]}
                    />
                </Grid>
                {!element.filePath ? (
                    <>
                        <Grid item xs={3}>
                            {!readonly && (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleFileUploadClick}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        Dokumentum feltöltése
                                    </span>

                                    <input
                                        hidden
                                        type="file"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleFileChange(event, element.id)
                                        }
                                        ref={fileInputRef}
                                    />
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="success"
                                onClick={() => handleFileDownload(element)}
                                startIcon={<FileDownloadIcon />}
                            >
                                <span
                                    style={{
                                        display: 'inline-block',
                                        maxWidth: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {element.fileName ? element.fileName : element.origFileName}
                                </span>
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="error"
                                disabled={readonly}
                                onClick={() => handleClearFileInput(element)}
                                startIcon={<DeleteIcon />}
                            >
                                <span
                                    style={{
                                        display: 'inline-block',
                                        maxWidth: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    Törlés
                                </span>
                            </Button>
                        </Grid>
                    </>
                )}
                <Grid item xs={1}>
                    <IconButton
                        color={'error'}
                        disabled={readonly}
                        onClick={() =>
                            setAdmissionForm((prev) => ({
                                ...prev,
                                informationDocuments: (prev.informationDocuments as IAdmissionDocument[]).filter(
                                    (item) => item.id != element.id,
                                ),
                            }))
                        }
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
