import { Input } from '@silinfo/front-end-template';
import { financingFormOptions, trainingFormOptions } from '../../../../pages/MasterData/General/Students/Form/options';
import { Option } from '../../../../utils/AppConst';
import { IForm } from '../../../../utils/Interfaces/interfaces';

export const statusLabels: Record<string, string> = {
    active: 'Aktív',
    passive: 'Passzív',
    foreign_scholarship: 'Külföldi ösztöndíjas',
};

export const fieldsToDisplay: (Input & { getValue?: (value: string, options?: Option[]) => string })[] = [
    {
        name: 'university',
        label: 'Egyetem neve',
        getValue: (value, universities) => {
            if (!universities) return '-';
            value = value.split('/').slice(-1)[0];
            return universities.find((option) => option.value == value)?.label || '-';
        },
    },
    { name: 'major', label: 'Szak' },
    {
        name: 'trainingForm',
        label: 'Képzés formája',
        getValue: (value) => trainingFormOptions.find((option) => option.value === value)?.label || '-',
    },
    {
        name: 'financeForm',
        label: 'Finanszírozás formája',
        getValue: (value: string) => financingFormOptions.find((option) => option.value === value)?.label || '-',
    },
    {
        name: 'semesterStatus',
        label: 'Hallgatói jogviszony',
        getValue: (value) => statusLabels[value] || '-',
    },
    { name: 'neptun', label: 'Neptun kód' },
    { name: 'pedigreeNumber', label: 'Törzskönyvi szám (Număr matricol)' },
];

export interface IActualStudies extends IForm {
    id: number;
    university: string;
    foreignUniversity?: string;
    major: string;
    trainingForm: string;
    financeForm: string;
    semesterStatus: string;
    neptun: string;
    fileName: string;
    filePath: string;
}
