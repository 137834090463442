import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Option } from '../../../utils/AppConst';
import { Paper } from '../Paper';

const CourseData = (courseData: Option) => (
    <Grid item xs={12} md={6}>
        <Paper elevation={4}>
            <Typography>{courseData.label}</Typography>
            <Typography>{courseData.value}</Typography>
        </Paper>
    </Grid>
);

export default CourseData;
