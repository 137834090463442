import Typography from '@mui/material/Typography';
import { theme } from '../../../../utils/theme';
import { Paper } from '../../Paper';
import { activeColorByRating, ratingTextByRating } from './utils';

export const Rating = ({ rating }: { rating: string }) => {
    const activeColor = activeColorByRating(rating);
    const ratingText = ratingTextByRating(rating);
    const getContent = () => {
        return (
            <Typography
                textAlign="center"
                style={{ color: ratingText ? `rgb(${activeColor.split(' ').join(', ')})` : theme.palette.warning.main }}
            >
                {ratingText || <i>Nem elérhető</i>}
            </Typography>
        );
    };

    return (
        <Paper
            style={
                ratingText
                    ? {
                          boxShadow: `0px 2px 4px -1px rgb(${activeColor} / 20%), 0px 4px 5px 0px rgb(${activeColor} / 14%), 0px 1px 10px 0px rgb(${activeColor} / 12%)`,
                      }
                    : {}
            }
        >
            {getContent()}
        </Paper>
    );
};
