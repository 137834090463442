import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FieldGenerator, Option, Tooltip } from '@silinfo/front-end-template';
import { ErrorText } from './ErrorText';
import { Field, FieldName, FormField, UserData } from '../types';
import { Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';
import { getAddressFieldSizes } from '../utils';
import AdmissionsStyle from '../Admissions.module.scss';
import { bankAccountNumberFormatter } from '../../../../utils/AppConst';

export default function BasicDataOtherFields({
    setBasicData,
    basicData,
    otherDataFields,
    addressDataFields,
    otherDataFieldsFirstRow,
    nationalities,
    formData,
    formProps,
    errors,
    handleCheckboxChange,
    checked,
    matches,
    editMode,
}: {
    setBasicData: Dispatch<SetStateAction<UserData>>;
    basicData: UserData;
    otherDataFields: Field[];
    addressDataFields: Field[];
    otherDataFieldsFirstRow: Field[];
    nationalities: Option[];
    formData: FormField[];
    formProps: FormikProps<Record<string, unknown>>;
    errors: { [key: string]: string };
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    matches: boolean;
    editMode: boolean;
}) {
    const toRender = (fieldName: string | undefined) => {
        return (
            !checked ||
            (fieldName !== 'omId' &&
                fieldName !== 'taxId' &&
                fieldName !== 'idCardNumber' &&
                fieldName !== 'identificationNumberCnp' &&
                fieldName !== 'kepIdCardNumber' &&
                fieldName !== 'socialSecurityNumber')
        );
    };

    const helperText = (type: string) => {
        let helperText;
        switch (type) {
            case 'idCardNumber':
                helperText = 'pl.: 346243XX';
                break;
            case 'kepIdCardNumber':
                helperText = 'pl.: RB219594';
                break;
            case 'identificationNumberCnp':
                helperText = 'pl.: 8203114106118';
                break;
            case 'omId':
                helperText = 'pl.: 71234567891';
                break;
            case 'taxId':
                helperText = 'pl.: 8412731018';
                break;
            case 'socialSecurityNumber':
                helperText = 'pl.: 115652436';
                break;
            default:
                helperText = '';
                break;
        }
        return helperText;
    };

    return (
        <>
            {(otherDataFieldsFirstRow.length > 0 || otherDataFields.length > 0 || addressDataFields.length > 0) && (
                <Grid
                    container
                    xs={12}
                    spacing={1}
                    sx={{
                        ...(!matches && {
                            background: '#FFFFFF',
                            marginTop: '2rem',
                            paddingLeft: '0.5rem',
                            borderRadius: '5px',
                            border: '1px solid #C1CBD7',
                        }),
                    }}
                    className="borderRadius"
                >
                    <Grid container sx={{ ...(matches && { marginBottom: '1rem', marginTop: '2rem' }) }}>
                        <Typography
                            sx={{
                                fontSize: matches ? '24px' : '16px',
                                fontWeight: 700,
                                ...(!matches && {
                                    marginLeft: '0.5rem',
                                    marginTop: '1rem',
                                    color: '#181E25',
                                }),
                            }}
                        >
                            Bővített adatok
                        </Typography>
                    </Grid>
                    {/* Ez a funkció mégsem kellett, de még nem töröltük */}
                    {false && (
                        <Tooltip
                            title="Amennyiben határon túli vagy, úgy a TAJ számot, személyi igazolvány számot, adóazonosító jelet és oktatási azonosítót a rendszer nem kéri el tőled!"
                            arrow
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#C1CBD7',
                                        color: '#364354',
                                        '& .MuiTooltip-arrow': {
                                            color: '#C1CBD7',
                                        },
                                    },
                                },
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            color: '#f3ca12',
                                        }}
                                        onChange={handleCheckboxChange}
                                        defaultChecked={checked}
                                        disabled={!editMode}
                                    />
                                }
                                label="Határon túli vagyok"
                                labelPlacement="end"
                            />
                        </Tooltip>
                    )}
                    <Grid container xs={12} spacing={1} sx={{ marginTop: '0.5rem' }}>
                        {otherDataFieldsFirstRow.map((e, index) => {
                            const label = formData.find((el) => el.name === e.type)?.label;
                            const name = formData.find((el) => el.name === e.type)?.name;

                            if (e.type === 'nationality') {
                                return (
                                    <Grid key={index} item container xs={matches ? 4 : 12}>
                                        <Typography
                                            sx={{
                                                ...(!matches && {
                                                    fontSize: '14px',
                                                }),
                                            }}
                                        >
                                            Állampolgárság
                                        </Typography>
                                        {e.optional && (
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}
                                            >
                                                (opcionális)
                                            </Typography>
                                        )}
                                        {FieldGenerator({
                                            name: 'nationality',
                                            label: '',
                                            type: 'multiselect',
                                            fieldType: 'base',
                                            options: nationalities,
                                            format: { xs: 12, md: 12, lg: 12 },
                                            props: {
                                                disabled: !editMode,
                                                sx: {
                                                    '& .MuiOutlinedInput-root fieldset ': {
                                                        border: '1px solid #C1CBD7 !important',
                                                        borderRadius: '0px',
                                                    },
                                                },
                                                onChange: (_e: unknown, value: { value: string; label: string }[]) => {
                                                    setBasicData((prevData) => ({
                                                        ...prevData,
                                                        nationality: value.map((elem) =>
                                                            typeof elem === 'string' ? elem : elem.value,
                                                        ),
                                                    }));
                                                },
                                                value: basicData.nationality,
                                            },
                                            ...formProps,
                                        })}
                                        <ErrorText message={errors[name as string]} matches={matches} />
                                    </Grid>
                                );
                            }
                            if (e.type === 'birthPlace') {
                                return (
                                    <Grid key={index} item container xs={matches ? 4 : 12}>
                                        <Typography
                                            sx={{
                                                ...(!matches && {
                                                    fontSize: '14px',
                                                }),
                                            }}
                                        >
                                            Születési hely
                                        </Typography>
                                        {e.optional && (
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}
                                            >
                                                (opcionális)
                                            </Typography>
                                        )}
                                        {FieldGenerator({
                                            name: 'birthPlace',
                                            label: '',
                                            type: 'text',
                                            fieldType: 'base',
                                            format: { xs: 12, md: 12, lg: 12 },
                                            props: {
                                                disabled: !editMode,
                                                sx: {
                                                    '& .MuiOutlinedInput-root fieldset ': {
                                                        border: '1px solid #C1CBD7 !important',
                                                        borderRadius: '0px',
                                                    },
                                                },
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setBasicData((prevData) => ({
                                                        ...prevData,
                                                        birthPlace: e.target.value,
                                                    }));
                                                },
                                                value: basicData.birthPlace,
                                            },
                                            ...formProps,
                                        })}
                                        <ErrorText message={errors[name as string]} matches={matches} />
                                    </Grid>
                                );
                            }
                            if (e.type === 'birthDate') {
                                return (
                                    <Grid key={index} item container xs={matches ? 4 : 12}>
                                        <Typography
                                            sx={{
                                                ...(!matches && {
                                                    fontSize: '14px',
                                                }),
                                            }}
                                        >
                                            Születési idő
                                        </Typography>
                                        {e.optional && (
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}
                                            >
                                                (opcionális)
                                            </Typography>
                                        )}
                                        {FieldGenerator({
                                            name: 'birthDate',
                                            label: '',
                                            type: 'date',
                                            fieldType: 'base',
                                            format: { xs: 12, md: 12, lg: 12 },
                                            props: {
                                                disabled: !editMode,
                                                sx: {
                                                    '& .MuiOutlinedInput-root fieldset ': {
                                                        border: '1px solid #C1CBD7 !important',
                                                        borderRadius: '0px',
                                                    },
                                                },
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setBasicData((prevData) => ({
                                                        ...prevData,
                                                        birthDate: e.target.value,
                                                    }));
                                                },
                                                value: basicData.birthDate,
                                            },
                                            ...formProps,
                                        })}
                                        <ErrorText message={errors[name as string]} matches={matches} />
                                    </Grid>
                                );
                            }

                            return (
                                <Grid key={index} item container xs={matches ? 4 : 12}>
                                    <Typography
                                        sx={{
                                            ...(!matches && {
                                                fontSize: '14px',
                                            }),
                                        }}
                                    >
                                        {label}
                                    </Typography>
                                    {FieldGenerator({
                                        name: name || '',
                                        label: '',
                                        type: 'text',
                                        fieldType: 'base',
                                        format: { xs: 12, md: 12, lg: 12 },
                                        props: {
                                            disabled: !editMode,
                                            sx: {
                                                '& .MuiOutlinedInput-root fieldset ': {
                                                    border: '1px solid #C1CBD7 !important',
                                                    borderRadius: '0px',
                                                },
                                            },
                                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                setBasicData((prevData) => ({
                                                    ...prevData,
                                                    [name as string]: e.target.value,
                                                }));
                                            },
                                            value: basicData[e.type],
                                            inputProps: {
                                                maxLength: 255,
                                            },
                                        },
                                        ...formProps,
                                    })}
                                    <ErrorText message={errors[name as string]} matches={matches} />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid container xs={12} spacing={1}>
                        {otherDataFields.map((e, index) => {
                            const label = formData.find((el) => el.name === e.type)?.label;
                            const name = formData.find((el) => el.name === e.type)?.name;
                            const maxLength = formData.find((field) => field.name === name)?.maxLength || 128;
                            if (toRender(name)) {
                                return (
                                    <Grid key={index} item container xs={matches ? 6 : 12}>
                                        <Typography
                                            sx={{
                                                ...(!matches && {
                                                    fontSize: '14px',
                                                }),
                                            }}
                                        >
                                            {label}
                                        </Typography>
                                        {e.optional && (
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}
                                            >
                                                (opcionális)
                                            </Typography>
                                        )}
                                        {FieldGenerator({
                                            name: name || '',
                                            label: '',
                                            type: 'text',
                                            fieldType: 'base',
                                            format: { xs: 12, md: 12, lg: 12 },
                                            props: {
                                                helperText: helperText(e.type) || ' ',
                                                disabled: !editMode,
                                                sx: {
                                                    '& .MuiOutlinedInput-root fieldset ': {
                                                        border: '1px solid #C1CBD7 !important',
                                                        borderRadius: '0px',
                                                    },
                                                },
                                                onChange:
                                                    e.type === FieldName.BankAccountNumber
                                                        ? bankAccountNumberFormatter((key: string, value: string) =>
                                                              setBasicData((prevData) => ({
                                                                  ...prevData,
                                                                  [key as string]: value,
                                                              })),
                                                          )
                                                        : (event: React.ChangeEvent<HTMLInputElement>) => {
                                                              setBasicData((prevData) => ({
                                                                  ...prevData,
                                                                  [name as string]: event.target.value,
                                                              }));
                                                          },
                                                value: basicData[e.type],
                                                inputProps: {
                                                    maxLength: maxLength,
                                                },
                                            },
                                            ...formProps,
                                        })}
                                        {name === 'socialSecurityNumber' ? (
                                            <ErrorText message={errors.taj} matches={matches} />
                                        ) : (
                                            <ErrorText message={errors[name as string]} matches={matches} />
                                        )}
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>
                    {addressDataFields.length > 0 && (
                        <Typography
                            className={`${AdmissionsStyle.bolder} ${AdmissionsStyle.basicDataAddress}`}
                            sx={{
                                fontSize: matches ? '24px' : '16px',
                            }}
                        >
                            Állandó lakcím
                        </Typography>
                    )}
                    <Grid container xs={12} spacing={1}>
                        {addressDataFields.map((e, index) => {
                            const label = formData.find((el) => el.name === e.type)?.label;
                            const name = formData.find((el) => el.name === e.type)?.name;
                            const maxLength = formData.find((field) => field.name === name)?.maxLength || 128;
                            return (
                                <Grid key={index} item container xs={getAddressFieldSizes(e.type, matches)}>
                                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            sx={{
                                                ...(!matches && {
                                                    fontSize: '14px',
                                                }),
                                            }}
                                        >
                                            {label}
                                        </Typography>
                                        {e.optional && (
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}
                                            >
                                                (opcionális)
                                            </Typography>
                                        )}
                                    </Grid>

                                    {FieldGenerator({
                                        name: name || '',
                                        label: '',
                                        type: 'text',
                                        fieldType: 'base',
                                        format: { xs: 12, md: 12, lg: 12 },
                                        props: {
                                            disabled: !editMode,
                                            sx: {
                                                '& .MuiOutlinedInput-root fieldset': {
                                                    border: '1px solid #C1CBD7 !important',
                                                    borderRadius: '0px',
                                                },
                                            },
                                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                setBasicData((prevData) => ({
                                                    ...prevData,
                                                    [name as string]: e.target.value,
                                                }));
                                            },
                                            value: basicData[e.type],
                                            inputProps: {
                                                maxLength: maxLength,
                                            },
                                        },
                                        ...formProps,
                                    })}
                                    {name === 'socialSecurityNumber' ? (
                                        <ErrorText message={errors.taj} matches={matches} />
                                    ) : (
                                        <ErrorText message={errors[name as string]} matches={matches} />
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            )}
        </>
    );
}
