import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import API from '../../../../api';
import { useCallback, useEffect, useState } from 'react';
import { endpoints } from '../../../../utils/endPoints';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { MobileProgramSelectDialogProps } from '../types';
import AdmissionsDialogsStyle from './AdmissionsDialogs.module.scss';
import axios from 'axios';

export default function MobileProgramSelectDialog({
    openState,
    serialState,
    tempSerialState,
    token,
    handleSaveBody,
    refresh,
    checkIsProgramAlreadyAdded,
    loadingState,
}: MobileProgramSelectDialogProps) {
    const [open, setOpen] = openState;
    const [campuses, setCampuses] = useState<{ value: number; label: string }[]>([]);
    const [programs, setPrograms] = useState<{ value: number; label: string }[]>([]);
    const [campus, setCampus] = useState<string | null>();
    const [program, setProgram] = useState<string | null>();
    const [campusValue, setCampusValue] = useState<string | number | null>();
    const [programValue, setProgramValue] = useState<string | number | null>();
    const [editable, setEditable] = useState<boolean>(false);
    const [removable, setRemovable] = useState<boolean>(false);
    const [programError, setProgramError] = useState<boolean>(false);
    const [currentSerial, setCurrentSerial] = serialState;
    const [tempSerial, setTempSerial] = tempSerialState;
    const [loading, setLoading] = loadingState;

    const handleClose = useCallback(() => {
        setOpen(false);
        setCurrentSerial(null);
        setCampusValue(null);
        setProgramValue(null);
        setTempSerial(null);
        setProgramError(false);
    }, [setOpen, setCurrentSerial, setTempSerial]);

    const handleSave = () => {
        if (campusValue && programValue) {
            setLoading(true);
            const serial = currentSerial || tempSerial;
            const isNew = currentSerial ? false : true;
            handleSaveBody(serial, campusValue, programValue, isNew);
        }
    };

    const handleDelete = () => {
        const endpoint = window.location.pathname.includes('/mcc')
            ? endpoints.admission.removeProgramMcc.replace('{serial}', `${currentSerial}`)
            : endpoints.admission.removeProgramToken
                  .replace('{serial}', `${currentSerial}`)
                  .replace('{token}', `${token}`);
        API.delete(endpoint).then(() => {
            refresh();
            handleClose();
        });
    };

    const handleChange = (e: SelectChangeEvent<string | number>) => {
        setProgramError(false);
        setProgramValue(e.target.value);
        if (campusValue && e.target.value) {
            const isAlreadyAdded = checkIsProgramAlreadyAdded(campusValue as number, e.target.value as number);
            if (isAlreadyAdded) {
                setProgramError(true);
            }
        }
    };

    useEffect(() => {
        (() => {
            if (currentSerial) {
                const endpoint = window.location.pathname.includes('/mcc')
                    ? endpoints.admission.getProgramMcc.replace('{serial}', `${currentSerial}`)
                    : endpoints.admission.getProgramToken
                          .replace('{serial}', `${currentSerial}`)
                          .replace('{token}', `${token}`);
                const request = window.location.pathname.includes('/mcc') ? API.get(endpoint) : axios.get(endpoint);
                request.then((response) => {
                    setEditable(response.data.editable);
                    setRemovable(response.data.removable);
                    setCampusValue(response.data.campus.value);
                    setProgramValue(response.data.program.value);
                    setCampus(response.data.campus.label);
                    setProgram(response.data.program.label);
                });
            }
            axios.get(endpoints.admission.listCampuses).then((response) => setCampuses(response.data));
        })();
    }, [currentSerial, token]);

    useEffect(() => {
        (() => {
            if (campusValue) {
                axios
                    .get(endpoints.admission.listPrograms.replace('{campus}', `${campusValue}`))
                    .then((response) => setPrograms(response.data));
            }
        })();
    }, [campusValue]);

    useEffect(() => {
        setEditable(true);
        setRemovable(false);
        setCampusValue(null);
        setProgramValue(null);
        setCampus(null);
        setProgram(null);
    }, [openState]);

    return (
        <Dialog open={open} onClose={handleClose} fullScreen sx={{ marginTop: '3rem' }}>
            <Grid container xs={12} className={AdmissionsDialogsStyle.mobileBackground}>
                {loading ? (
                    <Grid
                        item
                        container
                        xs={12}
                        className={AdmissionsDialogsStyle.mobileBackground}
                        sx={{ justifyContent: 'center' }}
                    >
                        <CircularProgress size={100} />
                    </Grid>
                ) : (
                    <Grid container className={AdmissionsDialogsStyle.programsContainer}>
                        <Grid
                            item
                            container
                            sx={{
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography className={AdmissionsDialogsStyle.title}>Képzés kiválasztása</Typography>
                            <CloseIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={handleClose} />
                        </Grid>
                        <Grid
                            className={`borderRadius ${AdmissionsDialogsStyle.mobileDocumentContainer}`}
                            item
                            container
                        >
                            <FormControl fullWidth>
                                {editable ? (
                                    <>
                                        <Typography className={AdmissionsDialogsStyle.subTitle}>
                                            Képzési központ
                                        </Typography>
                                        <Select
                                            id="campus"
                                            value={campusValue ? campusValue : ''}
                                            onChange={(e) => {
                                                setCampusValue(e.target.value);
                                                setProgramError(false);
                                                setProgramValue(null);
                                            }}
                                            disabled={!editable}
                                            fullWidth
                                            size="small"
                                            className={AdmissionsDialogsStyle.programsSelector}
                                        >
                                            {campuses.map((e, index) => (
                                                <MenuItem key={index} value={e.value}>
                                                    {e.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                ) : (
                                    <TextField
                                        label="Campus"
                                        value={campus}
                                        disabled
                                        size="small"
                                        sx={{ mb: '20px' }}
                                    />
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                {editable ? (
                                    <>
                                        <Typography className={AdmissionsDialogsStyle.subTitle}>
                                            Képzési program
                                        </Typography>
                                        <Select
                                            id="program"
                                            value={programValue ? programValue : ''}
                                            onChange={handleChange}
                                            disabled={!campusValue || !editable}
                                            size="small"
                                            fullWidth
                                            className={AdmissionsDialogsStyle.programsSelector}
                                            error={programError}
                                        >
                                            {programs.map((e, index) => (
                                                <MenuItem key={index} value={e.value}>
                                                    {e.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                ) : (
                                    <TextField label="Képzési program" value={program} disabled size="small" />
                                )}

                                {programError && (
                                    <FormHelperText error={programError}>
                                        Ezt a képzést már kiválasztottad, kérlek válassz másikat!
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            container
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                marginTop: '1rem',
                            }}
                        >
                            {editable && (
                                <Button
                                    onClick={handleSave}
                                    disabled={!(campusValue && programValue) || programError}
                                    variant="contained"
                                    fullWidth
                                    sx={{ marginBottom: '0.5rem' }}
                                >
                                    Mentés
                                </Button>
                            )}

                            {removable && (
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: '#D27070', marginBottom: '0.5rem' }}
                                    onClick={handleDelete}
                                    fullWidth
                                >
                                    Jelentkezés törlése
                                </Button>
                            )}
                            <Button onClick={handleClose} variant="outlined" fullWidth>
                                Mégsem
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
}
