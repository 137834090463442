import { LoadingButton } from '@mui/lab';
import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { CancelButton } from '@silinfo/front-end-template';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import accessManagementService from '../../../services/administration/accessManagement';
import { create } from '../../../store/notification';
import { IModification } from './AccessManagement';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

type THeaderData = GridColDef;

export default function SaveButton({
    modifications,
    listData,
    headerData,
    opener,
    setRefresh,
    setModifications,
}: {
    modifications: Record<string, IModification>;
    listData: GridValidRowModel[];
    headerData: THeaderData[];
    opener: JSX.Element;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setModifications: React.Dispatch<React.SetStateAction<Record<string, IModification>>>;
}) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setModifications({});
        setRefresh((prev) => !prev);
    };

    const handleSubmit = () => {
        setLoading(true);
        accessManagementService
            .save(modifications)
            .then(() => {
                setModifications({});
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                    }),
                );
                setRefresh((prev) => !prev);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen mentés!' })));
    };

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>Megerősítés</DialogTitle>
                <DialogContent>
                    <Grid container>
                        Biztosan szeretnéd menteni az alábbi változtatásokat?
                        {getChangesPrintable(modifications, listData, headerData)}
                    </Grid>

                    <Grid item container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <LoadingButton variant="contained" loading={loading} type="submit" onClick={handleSubmit}>
                                Mentés
                            </LoadingButton>
                        </Grid>
                        <Grid item>
                            <CancelButton
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Mégsem
                            </CancelButton>
                        </Grid>
                        <Grid item>
                            <CancelButton onClick={handleClose}>Eredeti állapot visszatöltése</CancelButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </DialogBasic>
        </>
    );
}

function getChangesPrintable(
    modifications: Record<string, IModification>,
    listData: GridValidRowModel[],
    headerData: THeaderData[],
) {
    const m: Record<string, { roleName: string; modifies: Record<string, { label: string; checked: boolean }> }> = {};
    const accessRoleIdToRole: Record<string, string> = {};
    const rkeys = Object.keys(listData[0]);

    for (let i = 0; i < rkeys.length; i++) {
        if (rkeys[i].indexOf('ROLE_') !== -1) {
            accessRoleIdToRole[listData[0][rkeys[i]].accessRoleId] = rkeys[i];
        }
    }

    const okeys = Object.keys(headerData);
    for (let i = 0; i < okeys.length; i++) {
        m[headerData[+okeys[i]].field] = {
            roleName: headerData[+okeys[i]].headerName || '',
            modifies: {},
        };
    }

    const mkeys = Object.keys(modifications);
    for (let i = 0; i < mkeys.length; i++) {
        const accFunc = modifications[mkeys[i]].accessFunctionId;
        const accRole = modifications[mkeys[i]].accessRoleId;
        const checked = modifications[mkeys[i]].checked;
        const row = listData.find((e) => e.accessFunctionId === accFunc);

        if (row) {
            m[accessRoleIdToRole[accRole]].modifies[accFunc] = {
                label: row.label,
                checked: checked,
            };
        }
    }

    return (
        <ul>
            {Object.values(m).map((element) => {
                if (Object.keys(element.modifies).length === 0) {
                    return '';
                }

                return (
                    <li key={element.roleName}>
                        {element.roleName}
                        <ul>
                            {Object.values(element.modifies).map((el) => {
                                return (
                                    <li key={el.label}>
                                        {el.label} {'=>'} {el.checked ? 'HAMIS > IGAZ' : 'IGAZ > HAMIS'}
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </ul>
    );
}
