import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import { endpoints } from '../../../utils/endPoints';
import LanguageExamRow from './LanguageExamRow';
import { useEffect, useState } from 'react';
import instance from '../../../api';

export interface ILanguageExam {
    id: number;
    language: string;
    level: string; //A1, B2, stb.
    category: string;
    status: 'completed' | 'incompleted';
}

export default function LanguageExamRequirements({ selectedProgram }: { selectedProgram: null | number }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ILanguageExam[]>([]);

    useEffect(() => {
        if (!selectedProgram) {
            return;
        }
        setLoading(true);
        setData([]);
        instance
            .get(endpoints.students.requirements.languageExams + (selectedProgram ? '?program=' + selectedProgram : ''))
            .then((res) => {
                setData(res.data);
                setLoading(false);
            });
    }, [selectedProgram, setData, setLoading]);

    return (
        <Grid item container xs={12}>
            <fieldset style={{ width: '100%', border: '1px solid #ddd', textAlign: 'center' }}>
                <legend>Nyelvvizsga-követelmények státusza</legend>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={5}>
                        Nyelv
                    </Grid>
                    <Grid item xs={5}>
                        Szint
                    </Grid>
                    <Grid item xs={2}>
                        Státusz
                    </Grid>

                    <Grid item xs={12}></Grid>
                    {loading ? (
                        <Grid item xs={12}>
                            <Skeleton style={{ height: '100%' }} />{' '}
                        </Grid>
                    ) : data.length > 0 ? (
                        data.map((elem) => <LanguageExamRow key={elem.id} {...elem} />)
                    ) : (
                        <Grid item xs={12}>
                            <i>Nincs nyelvvizsga-követelmény</i>
                        </Grid>
                    )}
                </Grid>
            </fieldset>
        </Grid>
    );
}
