import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { TableButton } from '@silinfo/front-end-template';
import useOpen from '../../utils/useOpen';
import Dialog from '@mui/material/Dialog';
import ActionDialogContent from './ActionDialogContent';
import { useCallback } from 'react';
import { clientEndPoints } from '../../utils/clientEndPoints';

export interface Questionnaire {
    courseId: number;
    courseName: string;
    courseCode: string;
    semester: string;
    deadline: string | null;
    anonym: boolean;
    sharedType: boolean;
}

export interface OperationsMobileProps {
    row: Questionnaire;
}

const OperationsMobile: React.FC<OperationsMobileProps> = ({ row }) => {
    const { isOpen, open, close } = useOpen();

    const openQuestionnaire = useCallback(() => {
        window.open(clientEndPoints.student_questionnaires_fill.replace(':id', '' + row.courseId), '_blank');
    }, [row.courseId]);

    const handleOpen = () => {
        if (row.anonym) {
            openQuestionnaire();
        } else {
            open();
        }
    };

    return (
        <>
            <TableButton color="primary" onClick={handleOpen}>
                <DriveFileRenameOutlineIcon />
            </TableButton>
            <Dialog open={isOpen} onClose={close} fullWidth>
                <ActionDialogContent close={close} isAnonymous={!!row.anonym} id={+row.courseId} />
            </Dialog>
        </>
    );
};

export default OperationsMobile;
