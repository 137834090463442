import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import DownloadIcon from '@mui/icons-material/Download';
import Divider from './Divider';
import { downloadFile } from '../Administration/utils';
import { DocumentsDialogProps, UploadedDocument, UploadingDocument } from './types';

const FileRow = ({
    document,
    downloadable,
    semesterId,
    currentApplicantId,
    currentProgramId,
}: {
    document: UploadedDocument | UploadingDocument;
    downloadable: boolean;
    semesterId?: number;
    currentApplicantId?: number | null;
    currentProgramId?: number | null;
}) => {
    const handleDownload = (fileName: string) => {
        if (semesterId && currentApplicantId && currentProgramId) {
            API.get(
                endpoints.admission.downloadApplicantDocument(
                    semesterId,
                    currentApplicantId,
                    currentProgramId,
                    document.admissionDocumentId,
                ),
                {
                    responseType: 'blob',
                },
            ).then((response) => downloadFile(response.data, fileName));
        }
    };

    return (
        <Grid container item sx={{ alignItems: 'center', marginBottom: '1rem', justifyContent: 'space-between' }}>
            <>
                <TextField
                    size="small"
                    placeholder={document.name}
                    value={document.name}
                    label="Dokumentum neve"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{
                        marginRight: '0.75rem',
                        boxShadow: '0px 1px 6px -3px rgba(0,0,0,0.4)',
                        width: 260,
                    }}
                    disabled
                />
                {downloadable && (
                    <DownloadIcon
                        onClick={() => {
                            handleDownload(document.name);
                        }}
                        sx={{
                            cursor: 'pointer',
                            marginRight: '1rem',
                        }}
                    />
                )}
            </>
            <TextField
                size="small"
                placeholder="Feltöltési határidő"
                value={document.deadline}
                label="Feltöltési határidő"
                InputProps={{
                    readOnly: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{
                    boxShadow: '0px 1px 6px -3px rgba(0,0,0,0.4)',
                }}
                disabled
            />
        </Grid>
    );
};

export default function DocumentsDialog({ openState, semester, applicantState, programState }: DocumentsDialogProps) {
    const [open, setOpen] = openState;
    const [currentApplicantId, setCurrentApplicantId] = applicantState;
    const [currentProgramId, setCurrentProgramId] = programState;
    const [uploadedDocuments, setUploadedDocuments] = useState<UploadedDocument[]>([]);
    const [uploadingDocuments, setUploadingDocuments] = useState<UploadingDocument[]>([]);

    const handleClose = () => {
        setOpen(false);
        setCurrentApplicantId(null);
        setCurrentProgramId(null);
    };

    useEffect(() => {
        if (currentApplicantId && currentProgramId && open) {
            API.get(
                endpoints.admission.getApplicantDocuments(semester.value, currentApplicantId, currentProgramId),
            ).then((response) => {
                setUploadedDocuments(response.data.uploadedDocuments);
                setUploadingDocuments(response.data.uploadingDocuments);
            });
        }
    }, [semester.value, currentApplicantId, currentProgramId, open]);

    return (
        <Dialog open={open} fullWidth onClose={handleClose}>
            <DialogTitle>Dokumentum feltöltések</DialogTitle>
            <DialogContent>
                <Grid container sx={{ marginTop: '0.5rem', flexDirection: 'column' }}>
                    <Divider />
                    <Typography
                        sx={{
                            marginBottom: '1rem',
                            marginTop: '0.5rem',
                            fontWeight: 700,
                        }}
                    >
                        Feltöltött dokumentumok
                    </Typography>
                    {uploadedDocuments.map((e, index) => (
                        <FileRow
                            key={index}
                            document={e}
                            downloadable
                            semesterId={semester.value}
                            currentApplicantId={currentApplicantId}
                            currentProgramId={currentProgramId}
                        />
                    ))}
                    {uploadedDocuments.length === 0 && (
                        <Typography sx={{ fontStyle: 'italic', marginBottom: '1rem' }} align="center">
                            A jelentkező nem töltött fel dokumentumot!
                        </Typography>
                    )}
                    {uploadingDocuments.length !== 0 && (
                        <>
                            <Divider />
                            <Typography
                                sx={{
                                    marginBottom: '1rem',
                                    marginTop: '0.5rem',
                                    fontWeight: 700,
                                }}
                            >
                                Feltöltendő dokumentumok
                            </Typography>
                        </>
                    )}
                    {uploadingDocuments.length !== 0 &&
                        uploadingDocuments.map((e, index) => <FileRow key={index} document={e} downloadable={false} />)}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
}
