import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../utils/theme';
import Grid from '@mui/material/Grid';

const useStyle = makeStyles(() => ({
    title: {
        marginLeft: '5px',
        fontSize: 'large',
        borderBottom: '1px solid ' + theme.palette.secondary.main,
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
    },
    error: {
        color: '#f44336',
        fontSize: '0.75rem',
    },
}));

export interface TitleProps {
    title: string;
    titleButton?: React.ReactNode;
    error?: string;
    children?: React.ReactNode;
}

export default function Title(props: TitleProps) {
    const containsButton = props.titleButton ? true : false;
    const classes = useStyle(containsButton);
    const { title } = props;

    return (
        <Grid container sx={{ margin: '10px 0 0 10px' }}>
            <Grid item container spacing={2}>
                <Grid item xs={11}>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item>{props.titleButton}</Grid>
                {!containsButton ? <Grid item xs={1}></Grid> : <></>}
            </Grid>
            <Grid container>{props.children}</Grid>
            {props.error && <Typography className={classes.error}>{props.error}</Typography>}
        </Grid>
    );
}
