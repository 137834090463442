import { Option } from '../../../../../utils/AppConst';
import { Input } from '@silinfo/front-end-template/src/components/Form/types';
import { IForm } from '../../../../../utils/Interfaces/interfaces';

export interface ILanguageExamRequirement extends IForm {
    id: number;
    language: string;
    level: string;
    category: string;
    gracePeriod: string;
    trainingLevel?: string;
}

export const filterFields = (levelOptions: Option[], categoryOptions: Option[], languageOptions: Option[]): Input[] => [
    {
        name: 'language',
        label: 'Nyelv',
        type: 'multiselect',
        options: languageOptions,
        format: {
            xs: 6,
        },
    },
    {
        name: 'level',
        label: 'Szint',
        type: 'multiselect',
        options: levelOptions,
        format: {
            xs: 6,
        },
    },
    {
        name: 'category',
        label: 'Kategória',
        type: 'multiselect',
        options: categoryOptions,
        format: {
            xs: 6,
        },
    },
    {
        name: 'gracePeriod',
        label: 'Türelmi idő',
        format: {
            xs: 6,
        },
        type: 'select',
        options: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
        ],
    },
];
