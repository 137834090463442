import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

export interface IFloorsForm {
    building: string;
    name: string;
    buildingName: string;
    campusName: string;
}

const floorsService = {
    ...crudTemplate<IFloorsForm>(endpoints.masterData.floors.main),
    filter: (form: IForm, campusId: string, buildingId: string) =>
        API.get(
            endpoints.masterData.floors.filter.replace('{campusId}', campusId).replace('{buildingId}', buildingId),
            { params: form },
        ),
    fetch: (buildingId: string) => API.get(endpoints.masterData.floors.fetch.replace('{buildingId}', buildingId + '')),
};

export default floorsService;
