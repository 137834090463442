import Grid from '@mui/material/Grid';
import { TabsCompleted } from '@silinfo/front-end-template';
import { Children, useState } from 'react';
import { DetailedFormSection } from './interfaces';
import PersonalData from './PersonalData';
import SectionGenerator from './SectionGenerator';
import { useDetailedForm } from './sections';

export interface IStudentProcessIds {
    enrollments: number[];
    studentAllocationPreferences: number[];
    relocations: number[];
}

export const initialStudentProcessIds: IStudentProcessIds = {
    enrollments: [],
    studentAllocationPreferences: [],
    relocations: [],
};

export default function DetailedForm({ readOnly }: { readOnly?: boolean }) {
    const sections = useDetailedForm();
    const [activeSection, setActiveSection] = useState(0);

    return (
        <Grid item xs={12}>
            <TabsCompleted
                tabContents={sections.map((section) => ({
                    label: section.name,
                    value: section.sections.some((s) => s.dynamic) ? (
                        Children.toArray(
                            section.sections.map((field, j) => (
                                <SectionGenerator
                                    key={field.name}
                                    loading={false}
                                    readOnly={readOnly}
                                    section={field as DetailedFormSection}
                                    activeSection={activeSection}
                                    setActiveSection={setActiveSection}
                                    number={j}
                                />
                            )),
                        )
                    ) : (
                        <PersonalData
                            sections={sections}
                            section={section}
                            readOnly={readOnly}
                            activeSectionState={[activeSection, setActiveSection]}
                        />
                    ),
                }))}
            />
        </Grid>
    );
}
