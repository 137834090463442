export const tooltipHTML = `
<div>
    <p>A gombra kattintva a rendezvénynaptárból az alábbi szinkronizált törzsadatok kerülnek befrissítésre manuálisan:</p>
    <ul style="margin-top: 0; padding-left: 20px;">
        <li>Képzési központok (Campusok)</li>
        <li>Szervezeti egységek</li>
        <li>Eseménykategóriák</li>
        <li>Ajándéktípusok</li>
        <li>Kommunikációs igények</li>
        <li>Kommunikációs eszközigények</li>
        <li>Arculati megjelenések</li>
        <li>IT igények</li>
        <li>Teremelrendezések</li>
    </ul>
    <p>Az automatizált frissítés minden nap ütemezetten félóránként fut.</p>
</div>
`;
