import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { CancelButton, TextField } from '@silinfo/front-end-template';
import { FastField, FormikProps } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '../../../../../../../components/Form/Autocomplete';
import coursesService from '../../../../../../../services/masterData/courses';
import trainingProgramRequirementCourseReferenceCodeService from '../../../../../../../services/masterData/trainingProgramRequirementCourseReferenceCode';
import { RootState } from '../../../../../../../store';
import { IAddRequiredCourseCodeButtonForm } from './AddRequiredCourseCodeButton';

export default function RequiredCourseCodeForm({
    submitBtnTitle,
    handleClose,
    id,
    programId,
    campusId,
    ...props
}: FormikProps<IAddRequiredCourseCodeButtonForm> & {
    submitBtnTitle: string;
    handleClose: () => void;
    objectId?: number;

    id?: string;
    programId?: string;
    campusId?: string;
}) {
    const { count } = useSelector((state: RootState) => state.loading);

    const { setFieldValue } = props;

    useEffect(() => {
        if (props.values.coursePrerequisite && typeof props.values.coursePrerequisite === 'object') {
            coursesService.get(+props.values.coursePrerequisite?.value).then((res) => {
                setFieldValue('courseReferenceCode', res.data.courseReferenceCode);
            });
        }
    }, [props.values.coursePrerequisite, setFieldValue]);
    return (
        <>
            <Grid item xs={12} sm={12}>
                <FastField as="div" name="coursePrerequisite">
                    <Autocomplete
                        getter={(term: string) => {
                            return trainingProgramRequirementCourseReferenceCodeService.refCodeSearch(
                                id,
                                programId,
                                campusId,
                                term,
                            );
                        }}
                        textFieldProps={{ label: 'Keresés referencia alapján' }}
                        errorMessage={props.errors.coursePrerequisite as string}
                        autocompleteProps={{
                            value: props.values.coursePrerequisite || '',
                            onChange: (_, v) => props.setFieldValue('coursePrerequisite', v),
                        }}
                        createOptions={(courses: { value: number; label: string }[]) =>
                            courses.map((course) => ({
                                value: course.value.toString(),
                                label: course.label,
                            }))
                        }
                    />
                </FastField>
                <TextField
                    required
                    label="Kurzus neve"
                    onChange={(e) => props.setFieldValue('courseName', e.target.value)}
                ></TextField>
            </Grid>
            <Grid item container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <LoadingButton loading={count > 0} variant="contained" type="submit">
                        {submitBtnTitle}
                    </LoadingButton>
                </Grid>
                <Grid item>
                    <CancelButton onClick={handleClose}>Bezár</CancelButton>
                </Grid>
            </Grid>
        </>
    );
}
