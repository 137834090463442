import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import MCCLogo from '../../../../images/MCClogo_negacolor.svg';
import ActualStatusDialog, { greySettingIcon } from '../ActualStudies/ActualStatusDialog';
import Semesters from '../Semesters';
import SemesterStatusDialog from '../SemesterStatusDialog';
import Data from './Data';
import PreviousStudies from './PreviousStudies';
import StatusBadge from './StatusBadge';
import SimpleBadge from './SimpleBadge';

export default function MCCStudies() {
    const { profile } = useSelector((state: RootState) => state.studentProfile);
    const { user } = useSelector((state: RootState) => state.auth);

    if (!profile.responsibilities) {
        return null;
    }

    const hasBadge =
        profile.responsibilities.includes('educator') ||
        profile.responsibilities.includes('campaignManager') ||
        profile.isSecondarySchoolProgram ||
        profile.isTalentCaretakerProgram;

    return (
        <Grid item container xs={12} alignItems="center" spacing={2}>
            <Grid item container xs={12}>
                <Grid item>
                    <img src={MCCLogo} alt="logo" width="200" />
                </Grid>
            </Grid>
            <Grid item container flexDirection="column" spacing={2} xs={12} md={4} alignSelf="start" lg={3}>
                <Data label="Campus">{profile.campus?.name || '-'}</Data>
                <Data label="Képzés">
                    <>
                        {profile.trainingLevel?.name} - {profile.trainingProgram?.name}
                    </>
                </Data>
                <Data label="Évfolyam">{profile.collegeYear?.name || '-'}</Data>
            </Grid>
            {profile.room && (
                <Grid item container flexDirection="column" spacing={2} md={4} lg={3} alignSelf="start">
                    <Data label="Épület">
                        <>
                            {profile.campus?.name} - {profile.building?.name}
                        </>
                    </Data>
                    <Data label="Emelet">
                        <> {profile.floor?.name} </>
                    </Data>
                    <Data label="Szoba">
                        <> {profile.room?.name} </>
                    </Data>
                </Grid>
            )}
            <Grid item container spacing={2} xs={10} md={4} lg={3} justifyContent="flex-start">
                {profile.currentSemesterRegistered ? (
                    <Grid item container flexDirection="column" xs={8}>
                        <StatusBadge label="Státusz" value={profile.status} />
                        <StatusBadge
                            label="Aktuális státusz"
                            value={profile.actualStatus}
                            dialog={
                                <ActualStatusDialog
                                    actualStatus={profile.actualStatus}
                                    enabled={!!profile.canRequestActualStatusChange}
                                    readOnly={user.archive}
                                />
                            }
                        />
                        <StatusBadge
                            label="Szemeszter státusz"
                            value={profile.semesterStatus}
                            dialog={
                                <SemesterStatusDialog
                                    actualStatus={profile.semesterStatus}
                                    enabled={!!profile.canRequestSemesterStatusChange}
                                    readOnly={user.archive}
                                />
                            }
                        />
                    </Grid>
                ) : (
                    <Grid item container flexDirection="column" xs={8}>
                        <StatusBadge label="Státusz" value="none" />
                        <StatusBadge label="Aktuális státusz" value="none" dialog={greySettingIcon()} />
                        <StatusBadge label="Szemeszter státusz" value="none" dialog={greySettingIcon()} />
                    </Grid>
                )}

                {hasBadge ? (
                    <Grid item container flexDirection="column" spacing={2} alignSelf="start" xs={4}>
                        {profile.responsibilities.includes('educator') ? (
                            <SimpleBadge type={'role'} tooltip={'Nevelőtanár'} />
                        ) : null}
                        {profile.responsibilities.includes('campaignManager') ? (
                            <SimpleBadge type={'role'} tooltip={'Kampányfelelős'} />
                        ) : null}
                        {profile.isSecondarySchoolProgram ? (
                            <SimpleBadge type={'setting'} tooltip={'Középiskolás program'} />
                        ) : null}
                        {profile.isTalentCaretakerProgram ? (
                            <SimpleBadge type={'setting'} tooltip={'Fiatal tehetség program'} />
                        ) : null}
                    </Grid>
                ) : null}
            </Grid>
            <PreviousStudies />
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Semesters />
                </Grid>
            </Grid>
        </Grid>
    );
}
