import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormikListPage, Header } from '@silinfo/front-end-template';
import { header, breadcrumbs, tableProps, AdmissionRiportFilter, initialAdmissionRiportFilterState } from './utils';
import { endpoints } from '../../../utils/endPoints';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import BasicDataDialog from './BasicDataDialog';
import API from '../../../api';
import { defaultTheme, fixDateRangeFields } from '../../../utils/AppConst';
import StatusChangeDialog from './StatusChangeDialog';
import DocumentsDialog from './DocumentsDialog';
import ScoringDialog from './ScoringDialog';
import ConfirmFinalizeDialog from './ConfirmFinalizeDialog';
import { Semester } from './types';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import instance from '../../../api';
import { IForm } from '../../../utils/Interfaces/interfaces';
import MCCLoading from '../../../components/MCCLoading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

export default function Riport() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [semester, setSemester] = useState<Semester | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingButtonsDisabled, setLoadingButtonsDisabled] = useState<boolean>(false);
    const [mccLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [semesters, setSemesters] = useState<Semester[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false);
    const [currentApplicantId, setCurrentApplicantId] = useState<number | null>(null);
    const [currentProgramId, setCurrentProgramId] = useState<number | null>(null);
    const [documentDialogOpen, setDocumentDialogOpen] = useState<boolean>(false);
    const [scoringDialogOpen, setScoringDialogOpen] = useState<boolean>(false);
    const [finalizeDialogOpen, setFinalizeDialogOpen] = useState<boolean>(false);
    const [applicantNameLabel, setApplicantNameLabel] = useState<string>('');
    const [isCurrentRowDisabled, setIsCurrentRowDisabled] = useState<boolean>(false);
    const [info, setInfo] = useState<IInfo<AdmissionRiportFilter>>(initialAdmissionRiportFilterState());
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const exportContext = {
        initiateExport: (form: { format: string }, filter: IForm) =>
            instance.post(endpoints.admission.export.initiate, form, {
                params: fixDateRangeFields(filter),
            }),
        checkExport: (id: number) => instance.get(endpoints.admission.export.check.replace('{id}', id.toString())),
        downloadExport: (id: number) =>
            instance.get(endpoints.admission.export.download.replace('{id}', id.toString()), {
                responseType: 'arraybuffer',
            }),
        info,
        filter: info.filter,
    };

    const toggleRefresh = () => setRefresh((prev) => !prev);

    const handleOpenModal = (applicantId: number) => {
        setOpen(true);
        setCurrentApplicantId(applicantId);
    };

    const handleOpenStatusModal = (applicantId: number, programId: number, isDisabled: boolean) => {
        setStatusDialogOpen(true);
        setCurrentApplicantId(applicantId);
        setCurrentProgramId(programId);
        setIsCurrentRowDisabled(isDisabled);
    };

    const handleOpenDocumentModal = (applicantId: number, programId: number) => {
        setDocumentDialogOpen(true);
        setCurrentApplicantId(applicantId);
        setCurrentProgramId(programId);
    };

    const handleOpenScoringModal = (applicantId: number, programId: number, isDisabled: boolean) => {
        setScoringDialogOpen(true);
        setCurrentApplicantId(applicantId);
        setCurrentProgramId(programId);
        setIsCurrentRowDisabled(isDisabled);
    };

    const handleOpenFinalizeModal = (applicantId: number, programId: number, applicantNameLabel: string) => {
        setFinalizeDialogOpen(true);
        setCurrentApplicantId(applicantId);
        setCurrentProgramId(programId);
        setApplicantNameLabel(applicantNameLabel);
    };

    useEffect(() => {
        setLoading(true);
        API.get(endpoints.admission.getSemesterOptions)
            .then((res) => {
                setSemesters(res.data);
                setLoading(false);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (semester) {
            toggleRefresh();
        }
    }, [semester]);

    if (mccLoading) {
        return <MCCLoading />;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...headerWithTenant, breadcrumbs }} />
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    <Skeleton height="50px" />
                ) : (
                    <Autocomplete
                        options={semesters}
                        value={semester}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => {
                            setSemester(value);
                            setInfo((prevState) => ({
                                ...prevState,
                                filter: { ...prevState.filter, semester: value?.value },
                            }));
                        }}
                        getOptionLabel={(option) => option.label}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Szemeszter" />}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {semester ? (
                    <>
                        <BasicDataDialog
                            openState={[open, setOpen]}
                            semester={semester}
                            applicantState={[currentApplicantId, setCurrentApplicantId]}
                        />
                        <StatusChangeDialog
                            openState={[statusDialogOpen, setStatusDialogOpen]}
                            semester={semester}
                            applicantState={[currentApplicantId, setCurrentApplicantId]}
                            programState={[currentProgramId, setCurrentProgramId]}
                            disabledState={[isCurrentRowDisabled, setIsCurrentRowDisabled]}
                            toggleRefresh={toggleRefresh}
                        />
                        <DocumentsDialog
                            openState={[documentDialogOpen, setDocumentDialogOpen]}
                            semester={semester}
                            applicantState={[currentApplicantId, setCurrentApplicantId]}
                            programState={[currentProgramId, setCurrentProgramId]}
                        />
                        <ScoringDialog
                            openState={[scoringDialogOpen, setScoringDialogOpen]}
                            semester={semester}
                            applicantState={[currentApplicantId, setCurrentApplicantId]}
                            programState={[currentProgramId, setCurrentProgramId]}
                            disabledState={[isCurrentRowDisabled, setIsCurrentRowDisabled]}
                            toggleRefresh={toggleRefresh}
                        />
                        <ConfirmFinalizeDialog
                            openState={[finalizeDialogOpen, setFinalizeDialogOpen]}
                            semester={semester}
                            applicantState={[currentApplicantId, setCurrentApplicantId]}
                            programState={[currentProgramId, setCurrentProgramId]}
                            applicantNameLabelState={[applicantNameLabel, setApplicantNameLabel]}
                            toggleRefresh={toggleRefresh}
                        />
                        <FormikListPage
                            {...tableProps(
                                semester.value,
                                exportContext,
                                setInfo,
                                info,
                                handleOpenModal,
                                handleOpenStatusModal,
                                handleOpenDocumentModal,
                                handleOpenScoringModal,
                                handleOpenFinalizeModal,
                                loadingButtonsDisabled,
                                setLoadingButtonsDisabled,
                                user.archive,
                                tenant,
                            )}
                            refresh={refresh}
                        />
                    </>
                ) : (
                    <ThemeProvider theme={defaultTheme}>
                        <Alert severity="info">Kérjük, válassz szemesztert az adatok megtekintéséhez</Alert>
                    </ThemeProvider>
                )}
            </Grid>
        </Grid>
    );
}
