export const TooltipContent = ({ min, max, isPoint }: { min: number; max: number; isPoint: boolean }) => (
    <div>
        <div>
            Minimum {min} {isPoint ? 'pont' : 'garas'}
        </div>
        <div>
            Maximum {max} {isPoint ? 'pont' : 'garas'}
        </div>
    </div>
);
