import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { TableButton as Button } from '@silinfo/front-end-template';
import RateReview from '@mui/icons-material/RateReview';
import { ButtonProps } from '@mui/material/Button';

const StyledTableEditButton = withStyles(
    (theme: Theme) => ({
        root: {
            background: theme.palette.info.main,
            borderColor: theme.palette.info.main,
            color: theme.palette.common.white,
            boxShadow: theme.palette.common.black,
            '&:hover': {
                background: theme.palette.common.white,
                borderColor: theme.palette.info.dark,
                color: theme.palette.info.dark,
            },
        },
    }),
    { withTheme: true },
)(Button);

const RateButton = (props: ButtonProps & { target?: string; tooltip?: string }) => {
    const navigate = useNavigate();
    const { onClick, target, ...rest } = props;

    const onClickFunc = onClick
        ? onClick
        : () => {
              if (target) {
                  navigate(target);
              } else {
                  console.error("'target' property is not defined");
              }
          };
    return (
        <StyledTableEditButton {...rest} onClick={onClickFunc}>
            <RateReview />
        </StyledTableEditButton>
    );
};
export default RateButton;
