import { TabsCompleted } from '@silinfo/front-end-template';
import React, { useEffect, useState } from 'react';
import LangExamRequirementCompletion from './LangExamRequirementCompletion';
import LangExamReport from './LangExamReport';
import { useSearchParams } from 'react-router-dom';
import { createObjectFromParams } from '../../../utils/AppConst';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { fields } from './utils';

const tabContents = [
    {
        label: 'Nyelvvizsgák',
        value: <LangExamReport />,
    },
    {
        label: 'Hallgatók',
        value: <LangExamRequirementCompletion />,
    },
];

export default function LangExams() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [prevTab, setPrevTab] = useState(searchParams.get('tab') || '0');
    const [filterInfo, setFilterInfo] = useState<IForm[]>([{ tab: '0' }, { tab: '1' }]);

    useEffect(() => {
        const tab = searchParams.get('tab') || '0';
        if (prevTab !== tab) {
            if (prevTab) {
                const newSearchParams = createObjectFromParams(Array.from(searchParams.entries()), fields[+prevTab]);
                setFilterInfo((prev) =>
                    prev.map((item, index) => (index === +prevTab ? { ...newSearchParams, tab: prevTab } : item)),
                );
                setSearchParams(filterInfo[+(tab || 0)] as Record<string, string>);
            }
            setPrevTab(tab || '0');
        }
    }, [filterInfo, prevTab, searchParams, setSearchParams]);

    console.log(filterInfo, 'filterInfo');

    return <TabsCompleted tabContents={tabContents} format={{ xs: 12 }} />;
}
