import { Dialog, SubmitButton, TableEditButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { IHoliday, ISemestersForm } from '../../../../../services/masterData/semesters';
import Form, { isValidHoliday } from './Form';

export interface EditProps {
    setSemesterForm: React.Dispatch<React.SetStateAction<ISemestersForm>>;
    setFieldValue: (field: string, value: IHoliday[], shouldValidate?: boolean | undefined) => void;
    holidays: IHoliday[];
    holidayToEdit: IHoliday;
}
type Key = 'name' | 'startDate' | 'endDate';

export default function EditHoliday({ setSemesterForm, setFieldValue, holidays, holidayToEdit }: EditProps) {
    const [form, setForm] = useState<IHoliday>(holidayToEdit);
    const [touched, setTouched] = useState<{ [key in Key]?: boolean }>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const handleSubmit = () => {
        const newHolidays = holidays.map((b) => (b.id === holidayToEdit.id ? { ...form } : b));
        setTouched({});

        setFieldValue('holidays', newHolidays);
        setSemesterForm((prev) => ({
            ...prev,
            holidays: newHolidays,
        }));
    };

    return (
        <Dialog
            title="Szünet szerkesztése"
            opener={<TableEditButton tooltip="Szünet szerkesztése" />}
            action={
                <SubmitButton disabled={!isValidHoliday(form)} onClick={handleSubmit}>
                    Mentés
                </SubmitButton>
            }
        >
            <Form handleChange={handleChange} form={form} touched={touched} setTouched={setTouched} />
        </Dialog>
    );
}
