import { default as API } from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
import { endpoints } from '../../utils/endPoints';
import { fixDateRangeFields } from '../../utils/AppConst';

const selfEvaluationReportService = {
    initiateExport: (form: { format: string }, filter: IForm) => {
        return API.post(endpoints.selfEvaluation.report.export.initiate(filter.semester as string), form, {
            params: fixDateRangeFields(filter),
        });
    },
    checkExport: (semesterId: string, id: number) =>
        API.get(endpoints.selfEvaluation.report.export.check(semesterId, id as unknown as string)),
    downloadExport: (semesterId: string, id: number) =>
        API.get(endpoints.selfEvaluation.report.export.download(semesterId, id as unknown as string), {
            responseType: 'arraybuffer',
        }),
    studentSearch: (term: string) => API.get(endpoints.selfEvaluation.report.studentSearch + '?term=' + term),
    writeFill: (studentSelfEvaluationId: string, form: IForm, formIndex: number, finalization?: boolean) =>
        API.post(
            endpoints.selfEvaluation.report.fillForm(studentSelfEvaluationId) +
                '?formIndex=' +
                formIndex +
                '&finalization=' +
                (finalization ? '1' : '0'),
            form,
        ),
};

export default selfEvaluationReportService;
