import * as React from 'react';
import { CancelButton } from '@silinfo/front-end-template';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import i18n from '../../../../i18n';

const useStyles = makeStyles(() => ({
    paper: { minWidth: '400px' },
}));

export interface IEmailErrorText {
    errorText: string | undefined;
}

const ErrorStatusButton = (props: IEmailErrorText) => {
    const [open, setOpen] = React.useState(false);
    const { errorText } = props;
    const classes = useStyles();

    const onClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Chip color="error" label={i18n.t('administration.email_log.status.error') as string} onClick={onClick} />

            <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
                <DialogTitle>Hiba</DialogTitle>
                <DialogContent style={{ paddingTop: '24px' }}>{errorText}</DialogContent>
                <DialogActions>
                    <CancelButton onClick={handleClose}>Bezár</CancelButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
export default ErrorStatusButton;
