import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { endpoints } from '../../../../../../utils/endPoints';
import SmallTable from '../SmallTable';
import { ICompletionData, ITrainingCompletionData } from './../types';
import {
    CompletedCellRenderer,
    CompletionCell,
    EnrolledCoursesCellRenderer,
    LanguageExamRequirementsCellRenderer,
    MandatoryCoursesCellRenderer,
    StatusCellRenderer,
} from './CellRenderers';
import TableCell from './TableCell';
import { getRowBackgroundColor, isOpen, isTrainingProgram } from './utils';
import PDFDownloader from '../../../../../../components/PDFDownloader';
import ComponentToPrint from './ComponentToPrint';
import InfoDialog from '../../../../../Administration/CourseCompletions/utils';
import { useParams } from 'react-router-dom';
import { theme } from '../../../../../../utils/theme';

const Core = (props: { row: ICompletionData | ITrainingCompletionData }) => {
    const { row } = props;
    const isSemester = !isTrainingProgram(row);
    const [searchParams] = useSearchParams();
    const [open, setOpen] = React.useState(
        isOpen(row, searchParams.get('trainingProgram') || '', searchParams.get('semester') || ''),
    );
    const { id } = useParams() as { id: string };

    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    backgroundColor: getRowBackgroundColor(!!row.disabled, isSemester),
                }}
            >
                <TableCell style={isSemester ? { paddingRight: 0 } : { paddingLeft: 0 }}>
                    {row.disabled ? null : (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell>
                    <CompletedCellRenderer {...row} />
                </TableCell>
                <TableCell>
                    <EnrolledCoursesCellRenderer {...row} />
                </TableCell>
                <TableCell>{row.minToBeCompleted} pont</TableCell>
                <TableCell>
                    <MandatoryCoursesCellRenderer
                        row={row}
                        url={endpoints.masterData.students.trainingProgramCompletion.requiredCourseInfo}
                    />
                </TableCell>
                <TableCell>
                    <CompletionCell {...row.electiveCourses} />
                </TableCell>
                <TableCell>
                    <CompletionCell {...row.coCourses} />
                </TableCell>
                <TableCell>
                    <LanguageExamRequirementsCellRenderer
                        row={row}
                        url={endpoints.masterData.students.trainingProgramCompletion.langInfo}
                    />
                </TableCell>
                <TableCell>
                    <StatusCellRenderer {...row} />
                </TableCell>
                <TableCell>
                    {isSemester ? (
                        <>
                            <PDFDownloader
                                data={{ filename: row.filename || new Date().toISOString(), row }}
                                ComponentToPrint={ComponentToPrint}
                            />
                        </>
                    ) : (
                        <>
                            <InfoDialog
                                url={endpoints.masterData.students.trainingProgramCompletion.courseTypeInfo(id, row.id)}
                                sx={{
                                    border: '1px solid ' + theme.palette.primary.main,
                                    '&:hover': { backgroundColor: theme.palette.action.hover },
                                    padding: '5px',
                                    marginBottom: '5px',
                                }}
                            />
                        </>
                    )}
                </TableCell>
            </TableRow>
            {row.disabled ? null : isSemester ? (
                <TableRow style={{ backgroundColor: '#b4c7e755' }}>
                    <TableCell style={{ padding: 0 }} colSpan={2} />
                    <TableCell style={{ padding: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <SmallTable rows={row.courses} />
                        </Collapse>
                    </TableCell>
                </TableRow>
            ) : (
                <>
                    {row.semesters.length > 0 ? (
                        row.semesters.map((semester) => <Row key={semester.id} row={semester} openFromParent={open} />)
                    ) : (
                        <TableRow>
                            <TableCell colSpan={10}>
                                <i>Nincs megjeleníthető szemeszter.</i>
                            </TableCell>
                        </TableRow>
                    )}
                </>
            )}
        </>
    );
};

export default function Row(props: { row: ICompletionData | ITrainingCompletionData; openFromParent?: boolean }) {
    const { row, openFromParent } = props;

    return openFromParent === undefined ? <Core row={row} /> : openFromParent ? <Core row={row} /> : null;
}
