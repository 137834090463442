import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { IFinanceRow } from './FinanceTable';
import './Statistics.css';

const sum = (rows: IFinanceRow[]) => rows.reduce((acc, row) => acc + row.amount, 0);

const createChartData = (rows: [string, IFinanceRow[]][], currency?: string) => [
    ['Díj', 'ezer ' + currency],
    ...rows.map((row) => [
        row[0],
        {
            v: sum(row[1]),
            f: `~${sum(row[1]) > 1000 ? Math.round(sum(row[1]) / 1000) + 'e' : sum(row[1])} ${currency}`,
        },
    ]),
];

const options = {
    pieHole: 0.4,
    is3D: false,
    backgroundColor: '#f5f5f5',
    legend: { position: 'bottom', orientation: 'horizontal' },
    pieSliceText: 'value',
    color: 'red',
    chartArea: {
        top: '5%',
        width: '100%',
        height: '60%',
    },
};

type ChartView = 'paid' | 'waiting_for_payment' | 'all';

export default function Statistics(props: { fees: IFinanceRow[]; currency?: string }) {
    const { fees, currency } = props;
    const groupByName = (rows: IFinanceRow[]): Map<string, IFinanceRow[]> => {
        const result = new Map<string, IFinanceRow[]>();
        rows.forEach((row) => {
            const key = row.identifier.split('-')[0] === 'EGY' ? 'Egyedi díj' : row.name || 'Egyéb';
            if (!result.has(key)) {
                result.set(key, []);
            }
            result.get(key)?.push(row);
        });
        return result;
    };

    const groupBy = (array: IFinanceRow[]): [string, IFinanceRow[]][] =>
        Object.entries(Object.fromEntries(groupByName(array)));

    const [view, setView] = useState<ChartView>('all');
    const [data, setData] = useState<[string, IFinanceRow[]][]>(groupBy(fees.filter((fee) => fee.status === view)));

    useEffect(() => {
        const result = Object.fromEntries(groupByName(fees.filter((fee) => view === 'all' || fee.status === view)));
        setData(Object.entries(result));
    }, [view, fees]);

    return (
        <Grid item container xs={12} md={4}>
            <Grid item xs={12}>
                <RadioGroup
                    name="radio-buttons-group"
                    className="noTransition"
                    row
                    style={{ justifyContent: 'center' }}
                    onChange={(e) => setView(e.target.value as ChartView)}
                    value={view}
                >
                    <FormControlLabel value="paid" control={<Radio />} label="Fizetett" />
                    <FormControlLabel value="waiting_for_payment" control={<Radio />} label="Fizetendő" />
                    <FormControlLabel value="all" control={<Radio />} label="Összes" />
                </RadioGroup>
            </Grid>
            <Grid item xs={12}>
                {data.length > 0 ? (
                    <Chart
                        chartType="PieChart"
                        width="100%"
                        height="400px"
                        data={createChartData(data, currency)}
                        options={options}
                    />
                ) : (
                    <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <i>Nincs megjeleníthető adat</i>
                    </div>
                )}
            </Grid>
        </Grid>
    );
}
