import { FormikListPage, Input, Link, NewButton, TabsCompleted } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { GridColDef } from '@mui/x-data-grid-pro';
import { TooltipRenderer } from '../../../utils/AppConstElements';
import { QUESTIONNAIRE_TEMPLATE_TYPE_OPTIONS, useGroupFields, useTemplateFields } from './useTemplateFields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useCallback, useState } from 'react';
import { BtnCellRenderer } from './BtnCellRenderer';
import Grid from '@mui/material/Grid';
import questionnaireTemplateService from '../../../services/selfEvalution/questionnaireTemplate';
import StatusCellRenderer from './StatusCellRenderer';
import EndpointProvider from '../../../components/EndpointProvider';
import { ArchiveCellRenderer } from '../../MasterData/General/TrainingLevels/ArchiveCellRenderer';
import GroupList from './GroupList';
import { useTenant } from '../../../components/TenantContext';

function TemplateListTable({ fields }: { fields: Input[] }) {
    const { user } = useSelector((state: RootState) => state.auth);
    const [refresh, setRefresh] = useState<boolean>(false);
    const tenant = useTenant();

    const doRefresh = useCallback(() => setRefresh(!refresh), [refresh]);

    const columns: GridColDef[] = [
        {
            headerName: 'Név',
            field: 'name',
            renderCell: (params) => TooltipRenderer(params.row?.name),
        },
        {
            headerName: 'Kérdőív típusa',
            field: 'type',
            valueGetter: (params) => {
                const typeOption = QUESTIONNAIRE_TEMPLATE_TYPE_OPTIONS.find((o) => o.value === params.row?.type);
                return typeOption ? typeOption.label : '-';
            },
            align: 'center',
            sortable: false,
        },
        {
            headerName: 'Státusz',
            field: 'status',
            renderCell: (params) => <StatusCellRenderer params={params} />,
            align: 'center',
            sortable: false,
        },
        {
            headerName: 'Archivált',
            field: 'archive',
            align: 'center',
            sortable: false,
            renderCell: (params) => <ArchiveCellRenderer {...params} />,
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            align: 'center',
            renderCell: (params) => <BtnCellRenderer params={params} refresh={doRefresh} />,
            sortable: false,
        },
    ];

    const header = {
        title: 'Adminisztráció',
        project: tenant || 'TAR',
        breadcrumbs: {
            administration: 'Adminisztráció',
            [clientEndPoints.admin_questionnaire_templates + '?tab=0']: 'Kérdőívsablonok',
        },
    };

    return (
        <FormikListPage
            autoload
            service={{
                filter: (form) => {
                    return questionnaireTemplateService.filter(form);
                },
            }}
            header={header}
            refresh={refresh}
            filter={{ fields, initialValues: { archived: 'false' } }}
            columns={columns.map((column) => ({ flex: 1, minWidth: 200, headerAlign: 'center', ...column }))}
            defaultSort={{ name: 'asc' }}
            tableProps={{
                columnBuffer: columns.length,
                initialState: {
                    pinnedColumns: {
                        right: ['operations'],
                    },
                },
            }}
            responseKey={'data'}
            customButtons={
                !user.archive ? (
                    <EndpointProvider
                        endpoints={[
                            'self_evaluation_edit',
                            'admin_questionnaire_templates_create',
                            'admin_questionnaire_groups_create',
                        ]}
                    >
                        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Link to="new">
                                    <NewButton>Új kérdőívsablon</NewButton>
                                </Link>
                            </Grid>
                        </Grid>
                    </EndpointProvider>
                ) : (
                    <></>
                )
            }
        />
    );
}

export default function Templates() {
    const { fields } = useTemplateFields();
    const { groupFields } = useGroupFields();

    const tabContents = [
        {
            label: 'Kérdőívsablonok',
            value: <TemplateListTable fields={fields} />,
        },
        {
            label: 'Kérdőívcsoportok',
            value: <GroupList fields={groupFields} />,
        },
    ];

    return <TabsCompleted tabContents={tabContents} />;
}
