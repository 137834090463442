import instance from '../../../../../api';
import { endpoints } from '../../../../../utils/endPoints';

const errorMessage =
    'A feltöltendő táblázat formátuma eltér az megengedett sablontól. Kérlek, az itt letölthető exportált listát kitöltve ismételd meg az importálást!';

const unknownError = 'Hiba történt a fájl feltöltése során';

const importContext = {
    upload: (form: FormData) => instance.post(endpoints.scholarships.admin.import.upload, form),
    check: (id: string) => instance.get(endpoints.scholarships.admin.import.check(id)),
};

export { errorMessage, importContext, unknownError };
