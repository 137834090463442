import axios from 'axios';
import { endpoints } from '../../../utils/endPoints';
import { AddressFieldSizes, FieldName, FormField } from './types';

export const basicDataTypes = ['email', 'firstName', 'lastName', 'gender', 'phone'];
export const otherDataTypesFirstRow = ['birthDate', 'birthPlace', 'nationality'];
export const addressFieldTypes = [
    'postCode',
    'city',
    'streetName',
    'streetType',
    'houseNumber',
    'addressFloor',
    'door',
];
export const getAddressFieldSizes = (type: string, matches: boolean) => {
    const sizes: AddressFieldSizes = {
        'postCode': matches ? 2 : 12,
        'city': matches ? 4 : 12,
        'streetName': matches ? 6 : 12,
        'streetType': matches ? 3 : 12,
        'houseNumber': matches ? 3 : 12,
        'addressFloor': matches ? 3 : 12,
        'door': matches ? 3 : 12,
    };
    return sizes[type];
};

export const fieldTypeOrder = [
    'lastName',
    'firstName',
    'gender',
    'phone',
    'email',
    'foreignStudent',
    'birthDate',
    'birthPlace',
    'nationality',
    'motherLastName',
    'motherFirstName',
    'omId',
    'taxId',
    'socialSecurityNumber',
    'idCardNumber',
    'kepIdCardNumber',
    'identificationNumberCnp',
    'bankName',
    'bankAccountNumber',
    'postCode',
    'city',
    'streetName',
    'streetType',
    'houseNumber',
    'addressFloor',
    'door',
];

export const initialBaseData = {
    email: '',
    lastName: '',
    firstName: '',
    foreignStudent: null,
    idCardNumber: '',
    identificationNumberCnp: '',
    kepIdCardNumber: '',
    birthPlace: '',
    birthDate: '',
    nationality: [],
    socialSecurityNumber: '',
    omId: '',
    motherLastName: '',
    motherFirstName: '',
    gender: '',
    phone: '',
    taxId: '',
    bankName: '',
    bankAccountNumber: '',
    postCode: '',
    city: '',
    streetName: '',
    streetType: '',
    houseNumber: '',
    addressFloor: '',
    door: '',
};

export const formFields = () => {
    return [
        {
            name: FieldName.Email,
            label: 'E-mail cím',
        },
        {
            name: FieldName.LastName,
            label: 'Vezetéknév',
        },
        {
            name: FieldName.FirstName,
            label: 'Keresztnév',
        },
        {
            name: FieldName.IdCardNumber,
            label: 'Személyi igazolvány szám',
            maxLength: 32,
        },
        {
            name: FieldName.KepIdCardNumber,
            label: 'SZIG szám - KEP',
            maxLength: 32,
        },
        {
            name: FieldName.IdentificationNumberCnp,
            label: 'Személyi szám (CNP)',
            maxLength: 13,
        },
        {
            name: FieldName.BirthPlace,
            label: 'Születési hely',
        },
        {
            name: FieldName.BirthDate,
            label: 'Születési idő',
        },
        {
            name: FieldName.Nationality,
            label: 'Állampolgárság',
        },
        {
            name: FieldName.SocialSecurityNumber,
            label: 'TAJ szám',
            maxLength: 32,
        },
        {
            name: FieldName.OmId,
            label: 'Oktatási azonosító',
            maxLength: 32,
        },
        {
            name: FieldName.MotherFirstName,
            label: 'Anyja keresztneve',
        },
        {
            name: FieldName.MotherLastName,
            label: 'Anyja vezetékneve',
        },
        {
            name: FieldName.Gender,
            label: 'Nem',
        },
        {
            name: FieldName.Phone,
            label: 'Telefonszám',
        },
        {
            name: FieldName.TaxId,
            label: 'Adóazonosító jel',
            maxLength: 32,
        },
        {
            name: FieldName.BankName,
            label: 'Pénzintézet neve',
            maxLength: 32,
        },
        {
            name: FieldName.BankAccountNumber,
            label: 'Bankszámlaszám',
            maxLength: 26,
        },
        {
            name: FieldName.PostCode,
            label: 'Irányítószám',
            maxLength: 128,
        },
        {
            name: FieldName.City,
            label: 'Település',
            maxLength: 128,
        },
        {
            name: FieldName.StreetName,
            label: 'Közterület neve',
            maxLength: 128,
        },
        {
            name: FieldName.StreetType,
            label: 'Közterület jellege',
            maxLength: 128,
        },
        {
            name: FieldName.HouseNumber,
            label: 'Házszám',
            maxLength: 128,
        },
        {
            name: FieldName.Floor,
            label: 'Emelet',
            maxLength: 128,
        },
        {
            name: FieldName.Door,
            label: 'Ajtó',
            maxLength: 128,
        },
    ] as FormField[];
};

export async function checkAdmissionInactive(
    campusId: number | null,
    trainingProgramId: number | null,
): Promise<boolean> {
    if (campusId) {
        try {
            const response = await axios.get(endpoints.admission.listPrograms.replace('{campus}', `${campusId}`));
            const programs = response.data;

            let found = false;

            for (const program of programs) {
                if (program.value === trainingProgramId) {
                    found = true;
                    break;
                }
            }

            return !found;
        } catch (error) {
            return false;
        }
    }

    return false;
}

export const convertDate = (dateString: string) => {
    return `${dateString} 23:59`;
};
