import { endpoints } from '../../utils/endPoints';
import instance from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const studentProfileService = {
    saveBaseDataPrimaryForm: (form: IForm) => instance.post(endpoints.students.profile.baseData.savePrimaryForm, form),
    saveBaseDataSecondaryForm: (form: IForm) =>
        instance.post(endpoints.students.profile.baseData.saveSecondaryForm, form),
    saveProfilePicture: (form: IForm) => instance.post(endpoints.students.profile.baseData.saveProfilePicture, form),
    saveActualUniversityStudies: (form: IForm) =>
        instance.post(endpoints.students.profile.baseData.saveActualUniversityStudies, form),
};

export default studentProfileService;
