import ApiKeys from '../../pages/Administration/ApiKeys/ApiKeys';
import ApiKeysForm from '../../pages/Administration/ApiKeys/Form';

export const apiKeys = {
    path: 'api-keys',
    children: [
        {
            path: '',
            element: <ApiKeys />,
            name: 'admin_api_keys_list',
        },
        {
            path: ':apiKeyId/edit',
            element: <ApiKeysForm />,
            name: 'admin_api_keys_edit',
        },
        {
            path: 'new',
            element: <ApiKeysForm />,
            name: 'admin_api_keys_new',
        },
    ],
};
