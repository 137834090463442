import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { handleAdminPreviewOpen } from '../../../../utils/AppConst';

export default function PreviewButton({ id }: { id: number | string }) {
    if (!id) return <></>;
    const handleClick = handleAdminPreviewOpen(id);

    return (
        <Tooltip title="Előnézet">
            <IconButton color="primary" onClick={handleClick} size="small">
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    );
}
