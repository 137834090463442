import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import { TableButton } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import Form from '../../../components/Form/Form';
import { ActualStatusForm } from '../../Profile/Tabs/ActualStudies/ActualStatusDialog';
import ActualStatusDialogForm from '../../Profile/Tabs/ActualStudies/ActualStatusDialogForm';

export const useStyles = makeStyles(() => ({
    eyeIcon: {
        backgroundColor: 'white',
        color: '#0288d1',
    },
}));

const ActualStatusInfoDialog = (row: ActualStatusForm) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const initialValues: ActualStatusForm = row;

    return (
        <>
            <TableButton onClick={handleOpen} tooltip={`Változtatások`} color={'info'} className={classes.eyeIcon}>
                <InfoIcon />
            </TableButton>
            <Dialog open={open} onClose={handleClose}>
                <Form fields={[]} onSubmit={() => console.log('/TODO')} initialValues={initialValues} hideButtons>
                    {(props) => (
                        <ActualStatusDialogForm
                            actualStatus={row.actualStatus}
                            handleClose={handleClose}
                            readOnly={true}
                            loading={false}
                            {...props}
                        />
                    )}
                </Form>
            </Dialog>
        </>
    );
};

export default ActualStatusInfoDialog;
