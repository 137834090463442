import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import { create, remove } from '../../../store/notification';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { downloadFile } from '../../../utils/AppConst';

export interface ExportContext {
    initiateExport: (form: { format: string }, filter: IForm) => Promise<AxiosResponse>;
    checkExport: (id: number) => Promise<AxiosResponse>;
    downloadExport: (id: number) => Promise<AxiosResponse>;
    info: IInfo<IForm>;
    filter: IForm;
}

type ExportButtonProps = {
    exportContext: ExportContext;
    outerLoading?: (loading: boolean) => void;
};

export default function ExportButton(props: ExportButtonProps): JSX.Element {
    const { exportContext, outerLoading } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        const toSendForm = {
            format: 'xlsx',
        };

        dispatch(create({ type: 'info', message: 'A fájl előállítása folyamatban...' }));
        const searchForm = exportContext.filter;
        setIsLoading(true);
        if (outerLoading) {
            outerLoading(true);
        }
        exportContext
            .initiateExport(toSendForm, searchForm)
            .then((data: AxiosResponse) => {
                const id = data.data.id;
                let ready = false;
                let isError = false;
                let fileName = '';

                const interval = setInterval(() => {
                    exportContext
                        .checkExport(id)
                        .then((d: AxiosResponse) => {
                            const status = d.data.status;
                            fileName = d.data.fileName;
                            ready = status === 'finished' || status === 'unknown_error';
                            isError = status !== 'finished';
                        })
                        .catch((error: AxiosError) => console.log(error, 'hiba'));
                    if (ready) {
                        clearInterval(interval);
                        if (!isError) {
                            exportContext
                                .downloadExport(id)
                                .then((response: AxiosResponse) => {
                                    downloadFile(response.data, fileName);
                                    setIsLoading(false);
                                    if (outerLoading) {
                                        outerLoading(false);
                                    }
                                    dispatch(remove());
                                    dispatch(create({ type: 'success', message: `A fájl sikeresen előállítva!` }));
                                })
                                .catch(() => {
                                    setIsLoading(false);
                                    if (outerLoading) {
                                        outerLoading(false);
                                    }
                                    dispatch(remove());
                                    dispatch(
                                        create({ type: 'error', message: `Hiba történt a fájl előállítása során` }),
                                    );
                                });
                        } else {
                            setIsLoading(false);
                            if (outerLoading) {
                                outerLoading(false);
                            }
                            dispatch(remove());
                            dispatch(create({ type: 'error', message: `Hiba történt a fájl előállítása során` }));
                        }
                    }
                }, 2000);
            })
            .catch((error: AxiosError) => console.log(error));
    };

    return (
        <LoadingButton
            variant="contained"
            disabled={isLoading}
            loading={isLoading}
            loadingPosition="start"
            onClick={handleClick}
            startIcon={<DownloadIcon />}
        >
            Exportálás
        </LoadingButton>
    );
}
