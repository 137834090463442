export const initialAddress = {
    postCode: '',
    city: '',
    streetType: '',
    streetName: '',
    houseNumber: '',
};

export const initialCampus = {
    id: 0,
    name: '',
    shortName: '',
    active: false,
    country: '',
    address: initialAddress,
    buildingCount: 0,
};

export const initialBuilding = {
    id: 0,
    name: '',
    campus: initialCampus,
    address: initialAddress,
    floorCount: 0,
};
export const initialFloor = {
    id: 0,
    name: '',
    building: initialBuilding,
};

export const initialRoom = {
    id: 0,
    name: '',
    floor: initialFloor,
    type: 'room',
    space: 4,
};

export const initialTrainingLevel = {
    id: 0,
    name: '',
    archive: false,
};

export const initialTrainingProgram = {
    id: 0,
    name: '',
    collegeYearIRIs: [],
    collegeYears: [],
    duration: '',
};
