import { Input } from '@silinfo/front-end-template';
import IEntityBase from '../../../../../interface/IEntityBase';
import { makeOptionsFromHydra, YEAR_OPTIONS } from './utils';

const fields = (collegeYears: IEntityBase[]): Input[] => [
    {
        name: 'trainingLevelName',
        label: 'Képzési szint',
        format: { xs: 12, md: 4, lg: 4 },
        props: {
            disabled: true,
        },
    },
    {
        name: 'name',
        label: 'Program neve',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'duration',
        label: 'Időtartam',
        type: 'select',
        options: YEAR_OPTIONS,
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'collegeYears',
        label: 'Évfolyamok',
        type: 'multiselect',
        options: makeOptionsFromHydra(collegeYears, 'college-years'),
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'archive',
        label: 'Archiválva?',
        type: 'multiselect',
        options: [
            { value: '1', label: 'Igen' },
            { value: '0', label: 'Nem' },
        ],
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'trainingProgramLeader',
        label: 'Képzési program vezető',
        format: { xs: 12, md: 4, lg: 4 },
    },
];

export default fields;
