import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { create } from '../../../store/notification';
import { IForm } from '../../../utils/Interfaces/interfaces';

export default function ConfirmationDialog({
    open,
    setOpen,
    handleSubmit,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    handleSubmit: (form: IForm) => Promise<void>;
}) {
    const { resetForm, values } = useFormikContext<IForm>();
    const dispatch = useDispatch();

    const handleNoClick = () => {
        resetForm();
        setOpen(false);
    };

    const handleYesClick = () => {
        handleSubmit(values)
            .then(() => {
                dispatch(create({ message: 'Sikeres mentés!', type: 'success' }));
            })
            .catch(() => {
                dispatch(create({ message: 'Hiba a mentés során!', type: 'error' }));
            });
        setOpen(false);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Megerősítés</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ paddingTop: '10px' }}>
                    Figyelem! Már jóváhagyott nyelvvizsga jellemzőit változtattad meg. Biztosan jóváhagyod a
                    változásokat?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleNoClick}>
                    Nem
                </Button>
                <Button variant="contained" onClick={handleYesClick}>
                    Igen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
