import Users from '../../../pages/MasterData/General/Users';
import Form from '../../../pages/MasterData/General/Users/Form';

export const users = {
    path: 'users',
    children: [
        {
            path: '',
            element: <Users />,
            name: 'md_general_users_list',
        },
        {
            path: 'new',
            element: <Form />,
            name: 'md_general_users_create',
        },
        {
            path: 'edit/:id',
            element: <Form />,
            name: 'md_general_users_edit',
        },
        {
            path: 'show/:id',
            element: <Form readOnly={true} />,
            name: 'md_general_users_show',
        },
    ],
};
