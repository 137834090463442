import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { FieldGenerator, Form, Option } from '@silinfo/front-end-template';
import { formFields } from '../Admissions/utils';
import { FormField } from '../Admissions/types';
import studentsService from '../../../services/masterData/students';
import Divider from './Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../../utils/theme';
import { ApplicantData, BasicDataDialogProps } from './types';
import RiportsStyle from './Riports.module.scss';
import { basicDataTypes, sortOrder } from './utils';

export default function BasicDataDialog({ openState, semester, applicantState }: BasicDataDialogProps) {
    const [open, setOpen] = openState;
    const [basicFields, setBasicFields] = useState<ApplicantData[]>([]);
    const [additionalFields, setAdditionalFields] = useState<ApplicantData[]>([]);
    const [nationalities, setNationalities] = useState<Option[]>([]);
    const [currentApplicantId, setCurrentApplicantId] = applicantState;
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleClose = () => {
        setOpen(false);
        setCurrentApplicantId(null);
    };
    const formData = formFields();

    additionalFields.sort((a, b) => {
        return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
    });

    useEffect(() => {
        if (currentApplicantId && open) {
            API.get(endpoints.admission.getApplicantBaseData(semester.value, currentApplicantId)).then((res) => {
                const fields = res.data.fields;
                setBasicFields(fields.filter((e: ApplicantData) => basicDataTypes.includes(e.type)));
                setAdditionalFields(fields.filter((e: ApplicantData) => !basicDataTypes.includes(e.type)));
            });
            studentsService.nationalities().then((res) => setNationalities(res.data));
        }
    }, [semester.value, currentApplicantId, open]);

    return (
        <Dialog open={open} fullWidth onClose={handleClose}>
            <DialogTitle>Általános felvételi adatok beállítása</DialogTitle>
            <DialogContent>
                <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item container className={RiportsStyle.dataStyle} xs={12}>
                        <Divider />
                        <Typography>Alapadatok</Typography>
                        <BaseDataForm basicFields={basicFields} formData={formData} matches={matches} />
                    </Grid>
                    <Grid item container className={RiportsStyle.dataStyle} xs={12}>
                        <Divider />
                        <Typography>Bővített alapadatok</Typography>
                        <ExtendedDataForm
                            additionalFields={additionalFields}
                            formData={formData}
                            matches={matches}
                            nationalities={nationalities}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function BaseDataForm({
    basicFields,
    formData,
    matches,
}: {
    basicFields: ApplicantData[];
    formData: FormField[];
    matches: boolean;
}) {
    return (
        <Form fields={[]} onSubmit={() => console.log('/TODO')} hideButtons>
            {(props) => {
                return (
                    <Grid container sx={{ marginTop: '1rem', marginLeft: '1rem', justifyContent: 'space-between' }}>
                        {basicFields.map((e, index) => {
                            const label = formData.find((el) => el.name === e.type)?.label;
                            const name = formData.find((el) => el.name === e.type)?.name;

                            if (e.type === 'phone') {
                                return (
                                    <div key={index}>
                                        {FieldGenerator({
                                            name: 'phone',
                                            label: 'Telefonszám',
                                            type: 'phone',
                                            fieldType: 'base',
                                            format: { xs: 12, md: 12 },
                                            props: {
                                                disabled: true,
                                                style: {
                                                    width: matches ? 250 : '100%',
                                                    marginTop: '0.25rem',
                                                },
                                                value: e.value,
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            },
                                            ...props,
                                        })}
                                    </div>
                                );
                            }

                            if (e.type === 'gender') {
                                return (
                                    <div key={index}>
                                        {FieldGenerator({
                                            ...props,
                                            label: label || '',
                                            name: name || '',
                                            type: 'select',
                                            fieldType: 'base',
                                            options: [
                                                {
                                                    value: 'male',
                                                    label: 'Férfi',
                                                },
                                                {
                                                    value: 'female',
                                                    label: 'Nő',
                                                },
                                                {
                                                    value: 'unknown',
                                                    label: 'Egyéb',
                                                },
                                            ],
                                            format: { xs: 12, md: 12 },
                                            props: {
                                                disabled: true,
                                                value: e.value,
                                                style: { width: matches ? 250 : '100%' },
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            },
                                        })}
                                    </div>
                                );
                            }

                            return (
                                <div key={index}>
                                    {FieldGenerator({
                                        ...props,
                                        label: label || '',
                                        name: name || '',
                                        type: 'text',
                                        fieldType: 'base',
                                        format: { xs: 12, md: 12 },
                                        props: {
                                            disabled: true,
                                            value: e.value,
                                            style: { width: matches ? 250 : '100%' },
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        },
                                    })}
                                </div>
                            );
                        })}
                    </Grid>
                );
            }}
        </Form>
    );
}

function ExtendedDataForm({
    additionalFields,
    formData,
    matches,
    nationalities,
}: {
    additionalFields: ApplicantData[];
    formData: FormField[];
    matches: boolean;
    nationalities: Option[];
}) {
    return (
        <Form fields={[]} onSubmit={() => console.log('/TODO')} hideButtons>
            {(props) => {
                return (
                    <Grid container sx={{ marginTop: '1rem', marginLeft: '1rem', justifyContent: 'space-between' }}>
                        {additionalFields.map((e, index) => {
                            if (e.type === 'mothersName') {
                                return null;
                            }
                            const label = formData.find((el) => el.name === e.type)?.label;
                            const name = formData.find((el) => el.name === e.type)?.name;

                            if (e.type === 'nationality') {
                                return (
                                    <div key={index}>
                                        {FieldGenerator({
                                            name: 'nationality',
                                            label: 'Állampolgárság',
                                            type: 'multiselect',
                                            fieldType: 'base',
                                            options: nationalities,
                                            format: { xs: 12, md: 12 },
                                            props: {
                                                disabled: true,
                                                style: {
                                                    width: matches ? 250 : '100%',
                                                },
                                                value: e.value,
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            },
                                            ...props,
                                        })}
                                    </div>
                                );
                            }

                            return (
                                <div key={index}>
                                    {FieldGenerator({
                                        ...props,
                                        label: label || '',
                                        name: name || '',
                                        type: 'text',
                                        fieldType: 'base',
                                        format: { xs: 12, md: 12 },
                                        props: {
                                            disabled: true,
                                            value: e.value,
                                            style: { width: matches ? 250 : '100%' },
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        },
                                    })}
                                </div>
                            );
                        })}
                    </Grid>
                );
            }}
        </Form>
    );
}
