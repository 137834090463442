import Grid from '@mui/material/Grid';
import {
    Accordion,
    CancelButton,
    Link,
    Page,
    Select,
    SubmitButton,
    TextField,
    Checkbox,
} from '@silinfo/front-end-template';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Field, Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import MCCLoading from '../../../../../../../components/MCCLoading';
import { create } from '../../../../../../../store/notification';
import floorsService from '../../../../../../../services/masterData/floors';
import roomsService, { IRoomsForm } from '../../../../../../../services/masterData/rooms';
import { transformApiErrors } from '../../../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../../../utils/clientEndPoints';
import { useTenant } from '../../../../../../../components/TenantContext';

const FormSchema = Yup.object().shape({
    name: Yup.string()
        .max(16, 'Túl hosszú! A maximumálisan megadható karakterek száma 16.')
        .required('Kötelező megadni'),
    space: Yup.number()
        .typeError('Az értéknek számnak kell lennie')
        .min(1, 'Túl alacsony érték')
        .max(99, 'Túl magas érték')
        .required('Kötelező megadni'),
    type: Yup.string().required('Kötelező megadni').oneOf(['room', 'classroom']),
});

const ClassRoomFormSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Túl hosszú! A maximumálisan megadható karakterek száma 50.')
        .required('Kötelező megadni'),
    space: Yup.number()
        .typeError('Az értéknek számnak kell lennie')
        .min(1, 'Túl alacsony érték')
        .max(99, 'Túl magas érték'),
    type: Yup.string().required('Kötelező megadni').oneOf(['room', 'classroom']),
});

const initialForm = {
    campus: '',
    building: '',
    floor: '',
    floorName: '',
    name: '',
    space: 4,
    type: 'room',
    assignable: true,
};

export default function RoomsForm({ readOnly }: { readOnly?: boolean }) {
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [form, setForm] = useState<IRoomsForm>(initialForm);
    const { campusId, buildingId, floorId, roomId } = useParams();
    const dispatch = useDispatch();
    const [type, setType] = useState<string>('room');
    const [myIsRoom, setMyIsRoom] = useState<boolean>(false);
    const tenant = useTenant();

    const URL = clientEndPoints.md_general_campuses_buildings_floors_rooms_list
        .replace(':campusId', campusId?.toString() ?? '')
        .replace(':buildingId', buildingId?.toString() ?? '')
        .replace(':floorId', floorId?.toString() ?? '');

    useEffect(() => {
        if (!floorId) {
            dispatch(create({ type: 'error', message: 'Hiba nincs floorId' }));
        } else {
            setPageLoading(true);
            axios
                .all([
                    ...(roomId
                        ? [
                              roomsService
                                  .get(roomId)
                                  .then((response: AxiosResponse) => {
                                      const data = response.data;
                                      setForm({
                                          campus: data?.floor?.building?.campus?.name,
                                          building: data?.floor?.building?.name,
                                          floor: data.floor['@id'],
                                          floorName: data.floor?.name,
                                          name: data.name,
                                          space: data.space,
                                          type: data.type,
                                          assignable: data.assignable,
                                      });
                                      setType(data.type);
                                  })
                                  .catch(console.error),
                          ]
                        : [
                              floorsService
                                  .get(floorId)
                                  .then((response: AxiosResponse) => {
                                      const data = response.data;
                                      setForm((prevState) => ({
                                          ...prevState,
                                          campus: data?.building?.campus?.name,
                                          building: data?.building?.name,
                                          floor: data['@id'],
                                          floorName: data.name,
                                      }));
                                  })
                                  .catch(console.error),
                          ]),
                ])
                .finally(() => setPageLoading(false));
        }
    }, [dispatch, floorId, roomId]);

    useEffect(() => {
        setMyIsRoom((prev) => !prev);
    }, [type]);

    const handleSubmit = (form: IRoomsForm, { setErrors }: FormikHelpers<IRoomsForm>) => {
        setFormLoading(true);
        (roomId
            ? roomsService.update(roomId, { ...form, space: parseInt(String(form.space)) })
            : roomsService.create({ ...form, space: parseInt(String(form.space)) })
        )
            .then(() => dispatch(create({ type: 'success', message: 'Sikeres mentés!', redirect: URL })))
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => setFormLoading(false));
    };

    const isRoom = type === 'room' ? 'szoba' : 'tanterem';
    const isRoomBoolean = type === 'room' ? true : false;
    const title = roomId ? form.name + ' ' + isRoom + ' megtekintése' : isRoom + ' megtekintése';
    const header = {
        project: tenant || 'TAR',
        title: title,
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
            [clientEndPoints.md_general_campuses_buildings_list.replace(':campusId', campusId?.toString() || '')]:
                'Épületek',
            [clientEndPoints.md_general_campuses_buildings_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')]: 'Szintek',
            [clientEndPoints.md_general_campuses_buildings_list
                .replace(':campusId', campusId?.toString() || '')
                .replace(':buildingId', buildingId?.toString() || '')
                .replace(':floorId', floorId?.toString() || '')]: 'Szobák',
            form: title,
        },
    };

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title} defaultExpanded disableClosing>
                <Formik
                    initialValues={form}
                    onSubmit={handleSubmit}
                    validationSchema={myIsRoom ? FormSchema : ClassRoomFormSchema}
                >
                    {({ touched, errors, values, setFieldValue }) => {
                        return (
                            <FormikForm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="campus">
                                            <TextField label="Campus" name="campus" disabled value={values?.campus} />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="building">
                                            <TextField
                                                label="Épület"
                                                name="building"
                                                value={values?.building}
                                                disabled
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="floorName">
                                            <TextField
                                                label="Szint"
                                                name="floorName"
                                                value={values.floorName}
                                                disabled
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field as="div" field="type">
                                            <Select
                                                label="Típus"
                                                name="type"
                                                error={!!(touched.type && errors.type)}
                                                helperText={touched.type && errors.type}
                                                options={[
                                                    { value: 'room', label: 'Lakószoba' },
                                                    { value: 'classroom', label: 'Tanterem' },
                                                ]}
                                                onChange={(e) => {
                                                    setFieldValue('type', e.target.value);
                                                    setType(e.target.value);
                                                    if (e.target.value != 'room') {
                                                        setFieldValue('assignable', false);
                                                    }
                                                }}
                                                value={values.type}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="name">
                                            <TextField
                                                label={values.type === 'room' ? 'Szobaszám' : 'Tanterem'}
                                                name="name"
                                                error={!!(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                                value={values.name}
                                                onChange={(e) => setFieldValue('name', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field as="div" field="space">
                                            <TextField
                                                label="Férőhely"
                                                name="space"
                                                error={!!(touched.space && errors.space)}
                                                helperText={touched.space && errors.space}
                                                value={values.space}
                                                onChange={(e) => setFieldValue('space', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field as="div" field="assignable">
                                            <Checkbox
                                                label="Szobagenerálás releváns"
                                                checkbox={{
                                                    name: 'assignable',
                                                    onChange: (e) => {
                                                        if (isRoomBoolean) {
                                                            setFieldValue('assignable', !e.target.checked);
                                                        } else {
                                                            setFieldValue('assignable', false);
                                                        }
                                                    },
                                                    value: values.assignable,
                                                    disabled: !isRoomBoolean || readOnly,
                                                    checked: values.assignable,
                                                }}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item container xs={12} spacing={2}>
                                        {!readOnly && (
                                            <Grid item>
                                                <SubmitButton loading={formLoading} type="submit">
                                                    Mentés
                                                </SubmitButton>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Link to={URL}>
                                                <CancelButton>Mégsem</CancelButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}
