import { GridRenderCellParams } from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import instance from '../../../../../api';
import { store } from '../../../../../store';
import { create, remove } from '../../../../../store/notification';
import { createFileNameString, downloadFile } from '../../../../../utils/AppConst';
import { endpoints } from '../../../../../utils/endPoints';
import { scholarshipOptions } from '../../../GroupManagement/utils';
import { IExportButtonProps } from './types';

const exportContext = {
    initiateExport: (id: string) =>
        instance.post(endpoints.scholarships.admin.export.initiate, { format: 'xlsx', scholarshipGroup: +id }),
    checkExport: (id: string) => instance.get(endpoints.scholarships.admin.export.check(id)),
    downloadExport: (id: string) =>
        instance.get(endpoints.scholarships.admin.export.download(id), { responseType: 'blob' }),
};

const getFileName = (params: GridRenderCellParams) => {
    const group = createFileNameString(params?.row.name || 'ismeretlen');
    const scholarshipType =
        scholarshipOptions.find((option) => option.value === params?.row.scholarshipType)?.label || 'ismeretlen';
    const type = createFileNameString(scholarshipType).toLowerCase();
    const date = new Date();
    const year = date.getFullYear().toString().slice(2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const dateString = `${year}${month}${day}-${hour}${minute}`;
    return `${group}_${type}_manualis_osztondij_kalkulacio_${dateString}.xlsx`;
};

const handleExportClick = async ({ id, setter, setLoading }: IExportButtonProps) => {
    setLoading(true);
    store.dispatch(create({ type: 'info', message: 'A fájl előállítása folyamatban...' }));
    const data = await exportContext.initiateExport('' + id);
    const id_2 = data.data.id;
    let ready = false;
    let isError = false;
    let fileName = '';
    const interval = setInterval(() => {
        if (ready) {
            clearInterval(interval);
            if (!isError) {
                exportContext
                    .downloadExport(id_2)
                    .then((response: AxiosResponse) => {
                        downloadFile(response.data, fileName);
                        setter?.(null);
                        store.dispatch(remove());
                        store.dispatch(create({ type: 'success', message: `A fájl sikeresen előállítva!` }));
                    })
                    .catch(() => {
                        store.dispatch(remove());
                        store.dispatch(create({ type: 'error', message: `Hiba történt a fájl előállítása során` }));
                    })
                    .finally(() => setLoading(false));
            } else {
                store.dispatch(remove());
                store.dispatch(create({ type: 'error', message: `Hiba történt a fájl előállítása során` }));
            }
        } else {
            exportContext
                .checkExport(id_2)
                .then((d: AxiosResponse) => {
                    const status = d.data.status;
                    ready = status === 'finished' || status === 'unknown_error';
                    fileName = d.data.fileName;
                    isError = status !== 'finished';
                })
                .catch((error: AxiosError) => {
                    console.log(error, 'hiba');
                    setLoading(false);
                });
        }
    }, 2000);
};

export { exportContext, getFileName, handleExportClick };
