import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldGenerator } from '../../../../../components/Form/FieldGenerator';
import { Option } from '../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { ISemesterFee } from './Form';

export default function AddFields(props: FormikProps<ISemesterFee>, semesterOptions: Option[]) {
    const navigate = useNavigate();
    const { feeId } = useParams() as { feeId: string; feeValId: string };

    return (
        <>
            {semesterOptions.length > 0 ? (
                <>
                    {FieldGenerator({
                        name: 'semester',
                        label: 'Szemeszter',
                        type: 'select',
                        fieldType: 'base',
                        options: semesterOptions,
                        format: { md: 6, xs: 12 },
                        ...props,
                    })}
                    {FieldGenerator({
                        name: 'amount',
                        label: 'Összeg',
                        format: { md: 6, xs: 12 },
                        props: {
                            inputProps: { maxLength: 8 },
                        },
                        ...props,
                    })}
                </>
            ) : (
                <>
                    <Grid item>
                        <Typography variant={'h5'}>
                            A kiválasztott adminisztrációs díjat nem lehet hozzárendelni egy szemeszterhez sem, mivel
                            korábban már az összeshez hozzá lett rendelve.{' '}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                        <Grid item>
                            <Button
                                style={{ marginTop: '5px' }}
                                onClick={() =>
                                    navigate(
                                        clientEndPoints.md_finance_administration_fees_semesters_list.replace(
                                            ':feeId',
                                            feeId.toString(),
                                        ),
                                    )
                                }
                                variant="contained"
                            >
                                Vissza
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
