export interface ICourseTypeRequirement {
    id: number;
    courseType: string;
    type: string;
}

export interface ICourseTypeRequirementForm {
    id: number;
    courseType: number;
    eventType: string;
    type: string;
    numberOfCoursesToBeCompleted: number;
    numberOfCoursesToBeCompletedPerSemester: number;
    minAmountToBeCompletedFromOtherTrainingPrograms: number;
    visibleOnCompletionPage: number;
    worthPoint: number;
    includedInTheScholarship: number;
}

export const initialCourseTypeRequirementForm = {
    id: 0,
    courseType: 0,
    eventType: '',
    type: 'optional',
    numberOfCoursesToBeCompleted: 0,
    numberOfCoursesToBeCompletedPerSemester: 0,
    minAmountToBeCompletedFromOtherTrainingPrograms: 0,
    visibleOnCompletionPage: 1,
    worthPoint: 0,
    includedInTheScholarship: 0,
};
