import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { PaperProps } from '@mui/material/Paper';
import { useCallback, useState } from 'react';
import { Paper } from '../../../../Requirements/Paper';
import { ISemester } from '../types';
import SemesterInfo from './SemesterInfo';

const Cell = (props: PaperProps) => (
    <Grid item xs={2} textAlign="center">
        <Paper {...props} />
    </Grid>
);

export default function Row(semester: ISemester) {
    const [open, setOpen] = useState(false);

    const toggle = useCallback(() => setOpen((prev) => !prev), []);

    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item xs={5}>
                <Paper
                    sx={{
                        padding: 1,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    {semester.name}
                </Paper>
            </Grid>
            <Cell>
                <div>{semester.completed.point} pont</div>
                <div>{semester.completed.copper} garas</div>
            </Cell>
            <Cell>
                <div>
                    {semester.enrolledCourses.point.min === semester.enrolledCourses.point.max
                        ? semester.enrolledCourses.point.min
                        : semester.enrolledCourses.point.min + ' - ' + semester.enrolledCourses.point.max}{' '}
                    pont
                </div>
                <div>
                    {semester.enrolledCourses.copper.min === semester.enrolledCourses.copper.max
                        ? semester.enrolledCourses.copper.min
                        : semester.enrolledCourses.copper.min + ' - ' + semester.enrolledCourses.copper.max}{' '}
                    garas
                </div>
            </Cell>
            <Cell>{semester.minToBeCompleted} pont</Cell>
            <Grid item xs={1} textAlign="center" alignSelf="center">
                <IconButton onClick={toggle}>
                    <ExpandMore style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={open}>
                    <SemesterInfo courses={semester.courses} />
                </Collapse>
            </Grid>
        </Grid>
    );
}
