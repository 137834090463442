import {
    Accordion,
    CancelButton,
    FieldGenerator,
    Page,
    SubmitButton,
    TabsCompleted,
    TextField,
    transformApiErrors,
} from '@silinfo/front-end-template';
import { Formik, Form as FormikForm, useFormikContext, FastField, FormikErrors } from 'formik';
import Grid from '@mui/material/Grid';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import MCCLoading from '../../../components/MCCLoading';
import { IPageForm } from './types';
import { SetStateAction, useEffect, useState } from 'react';
import pagesService from '../../../services/administration/pages';
import { create } from '../../../store/notification';
import axios, { AxiosError } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { LanguageObject, languagesObjects } from './utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { useTenant } from '../../../components/TenantContext';

export default function PageForm() {
    const { pageId } = useParams();
    const title = pageId ? 'Oldal szerkesztése' : 'Oldal hozzáadása';
    const [submitting, setSubmitting] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initForm, setInitForm] = useState({
        type: '',
        title: {
            hu: '',
            en: '',
        },
    });
    const tenant = useTenant();

    const header = {
        project: tenant || 'TAR',
        title: 'Oldalak',
        breadcrumbs: {
            admin: 'Adminisztráció',
            [clientEndPoints.admin_pages_list]: 'Oldalak',
            form: title,
        },
    };

    const myTabContents = languagesObjects.map((language) => {
        return {
            label: language.label,
            value: <TabContent key={language.label} readOnly={readOnly} language={language} />,
        };
    });

    useEffect(() => {
        if (!pageId) {
            if (loading) {
                setLoading(false);
            }
            return;
        }

        axios
            .all([pagesService.getPage(pageId)])
            .then((response) => {
                const pageData = response[0].data;
                setReadOnly(!pageData.pageType);

                setInitForm({
                    type: pageData.pageType,
                    title: {
                        hu: pageData.translations.hu.title,
                        en: pageData.translations.en.title,
                    },
                });
                // setPage(pageData);
            })
            .catch((error) => alert(error))
            .finally(() => setLoading(false));
    }, [pageId, loading, setLoading, setInitForm, setReadOnly]);

    if (loading) return <MCCLoading />;
    return (
        <Page header={header}>
            <Accordion title={title}>
                <Formik
                    onSubmit={(form: IPageForm, { setErrors }) =>
                        handleSubmit(dispatch, setSubmitting, form, setErrors, pageId)
                    }
                    initialValues={initForm}
                >
                    {(props) => {
                        return (
                            <FormikForm>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TabsCompleted tabContents={myTabContents} />
                                    </Grid>
                                    <Grid container item xs={12} sx={{ margin: '8px' }} gap={1}>
                                        <Grid item xs={12}>
                                            {FieldGenerator({
                                                name: 'type',
                                                label: 'Típus',
                                                type: 'select',
                                                fieldType: 'base',
                                                options: [
                                                    {
                                                        value: 'admission_privacy',
                                                        label: 'Felvételi adatkezelési nyilatkozat',
                                                    },
                                                ],
                                                props: {
                                                    disabled: readOnly,
                                                },
                                                ...props,
                                            })}
                                        </Grid>

                                        <Grid item container xs={12} spacing={2}>
                                            {!readOnly && (
                                                <Grid item>
                                                    <SubmitButton type="submit" loading={submitting}>
                                                        Mentés
                                                    </SubmitButton>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <CancelButton
                                                    disabled={submitting}
                                                    onClick={() => navigate(clientEndPoints.admin_pages_list)}
                                                >
                                                    {readOnly ? 'Vissza' : 'Mégsem'}
                                                </CancelButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}

const TabContent = ({ readOnly, language }: { readOnly?: boolean; language: LanguageObject }) => {
    const { setFieldValue, values, errors } = useFormikContext<IPageForm>();
    const l = language?.value || 'hu';
    const fieldname = 'title.' + l;
    const validationKey = ('translations[' + l + '].title') as keyof IPageForm;
    return (
        <Grid item xs={12}>
            <FastField as="div" field={fieldname}>
                <TextField
                    id={fieldname}
                    label="Név"
                    name={fieldname}
                    disabled={readOnly}
                    value={values?.title[l] || ''}
                    onChange={(e) => {
                        setFieldValue(`title[${language.value}]`, e.target.value);
                    }}
                    type="text"
                    error={!!errors[validationKey]}
                    helperText={errors[validationKey] as string}
                    sx={{ width: '50%' }}
                />
            </FastField>
        </Grid>
    );
};

function handleSubmit(
    dispatch: Dispatch,
    setSubmitting: { (value: SetStateAction<boolean>): void; (arg0: boolean): void },
    form: IPageForm,
    setErrors: (errors: FormikErrors<IPageForm>) => void,
    pageId?: string,
) {
    // setSubmitting(true);
    const formToSend: IPageForm = {
        pageId: pageId ? parseInt(pageId) : undefined,
        title: {
            en: form.title.en,
            hu: form.title.hu,
        },
        type: form.type,
    };

    return pagesService
        .createPage(formToSend)
        .then(() =>
            dispatch(
                create({
                    type: 'success',
                    message: 'Sikeres létrehozás!',
                    redirect: clientEndPoints.admin_pages_list,
                }),
            ),
        )
        .catch((e: AxiosError) => {
            dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            if (e.response?.status === 422) {
                setErrors(transformApiErrors<IForm>(e.response?.data.violations));
            } else {
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            }
        })
        .finally(() => setSubmitting(false));
}
