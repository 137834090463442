import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAccessManagementState {
    accessTree: Record<string, string[]>;
}

const accessManagementSlice = createSlice({
    name: 'accessManagement',
    initialState: { accessTree: {} } as IAccessManagementState,
    reducers: {
        setAccessTree(state, action: PayloadAction<Record<string, string[]>>) {
            state.accessTree = action.payload;
        },
    },
});

export const { setAccessTree } = accessManagementSlice.actions;
export default accessManagementSlice.reducer;
