import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import { IHoliday } from '../../../../../services/masterData/semesters';
import moment from 'moment';

type Key = 'name' | 'startDate' | 'endDate';

export const isValidHoliday = (form: Omit<IHoliday, 'id'>): boolean =>
    Object.entries(form).filter((e) => e[0] !== 'id' && e[1]).length > 2 &&
    moment(form.endDate).toDate().getTime() >= moment(form.startDate).toDate().getTime() &&
    form.name.length <= 128;

export default function Form({
    handleChange,
    form,
    touched,
    setTouched,
}: {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    form: Omit<IHoliday, 'id'>;
    touched: { [key in Key]?: boolean };
    setTouched: React.Dispatch<React.SetStateAction<{ [key in Key]?: boolean }>>;
}) {
    const validateName = (value: string): string =>
        value.length > 128
            ? 'Maximum 128 karakter hosszú lehet a megnevezés'
            : !value && touched.name
            ? 'Kötelező kitölteni'
            : '';
    const validateFrom = (value: string): string => {
        if (!touched.startDate) return '';

        if (!value) return 'Kötelező kitölteni';
        if (form.endDate && moment(form.endDate).toDate().getTime() < moment(value).toDate().getTime())
            return 'Az első nap nem lehet később, mint az utolsó';
        return '';
    };
    const validateTo = (value: string): string => {
        if (!touched.endDate) return '';

        if (!value) return 'Kötelező kitölteni';
        if (form.startDate && moment(form.startDate).toDate().getTime() > moment(value).toDate().getTime())
            return 'Az utolsó nap nem lehet előbb, mint az első';
        return '';
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Megnevezés"
                    onChange={handleChange}
                    value={form.name}
                    error={!!validateName(form.name)}
                    helperText={validateName(form.name)}
                    onBlur={(e) => setTouched((prev) => ({ ...prev, [e.target.name]: true }))}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="startDate"
                    label="Szünet első napja"
                    onChange={handleChange}
                    value={form.startDate}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    error={!!validateFrom(form.startDate)}
                    helperText={validateFrom(form.startDate)}
                    onBlur={(e) => setTouched((prev) => ({ ...prev, [e.target.name]: true }))}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="endDate"
                    label="Szünet utolsó napja"
                    onChange={handleChange}
                    value={form.endDate}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    error={!!validateTo(form.endDate)}
                    helperText={validateTo(form.endDate)}
                    onBlur={(e) => setTouched((prev) => ({ ...prev, [e.target.name]: true }))}
                />
            </Grid>
        </Grid>
    );
}
