import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import ROLES from './Roles';

export type LayoutType = 'admin' | 'student';

export default function useSwitchCheck(): { loading: boolean; layoutTypes: LayoutType[] } {
    const { user } = useSelector(
        (state: RootState) => state.auth,
        (left, right) => JSON.stringify(left.user) === JSON.stringify(right.user),
    );
    const [roles, setRoles] = useState<LayoutType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.roles) {
            const checkRole = (role: string) => user.roles.indexOf(role) !== -1;
            if (checkRole(ROLES.USER)) {
                setRoles((prev) => [...prev, 'admin']);
            }
            if (checkRole(ROLES.STUDENT) && user.student) {
                setRoles((prev) => [...prev, 'student']);
            }
        }
        setLoading(false);
    }, [user.roles, setRoles, setLoading, user.student]);

    return { loading, layoutTypes: roles };
}
