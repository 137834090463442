export const AlignCenterAbsolutely = ({
    children,
    alignCenter,
}: {
    children: React.ReactNode;
    alignCenter?: boolean;
}) => (
    <div
        style={{
            position: 'absolute',
            top: '50%',
            transform: alignCenter ? 'translate(-50%, -50%)' : 'translateY(-50%)',
            ...(alignCenter ? { left: '50%' } : {}),
        }}
    >
        {children}
    </div>
);
