import { endpoints } from '../../utils/endPoints';
import instance from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const studentRequestService = {
    requestChangeSemesterStatus: (form: IForm) =>
        instance.post(endpoints.students.courseEnrollment.request.changeSemesterStatus, form),
    requestChangeActualStatus: (form: IForm) =>
        instance.post(endpoints.students.courseEnrollment.request.changeActualStatus, form),
    getApproval: (id: number, isCentralData?: boolean) =>
        instance.get(
            endpoints.students.processes.getApproval.replace('{id}', id.toString()) +
                (isCentralData ? '?isCentralData=true' : ''),
        ),
    getLanguageExam: (id: number) =>
        instance.get(endpoints.students.processes.getLanguageExam.replace('{id}', id.toString())),
};

export default studentRequestService;
