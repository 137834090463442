import { endpoints } from '../../utils/endPoints';

import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const accessManagementService = {
    getTable: () => API.get(endpoints.administration.accessManagement.table),
    save: (modifications: IForm) => API.post(endpoints.administration.accessManagement.save, modifications),
};

export default accessManagementService;
