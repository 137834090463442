import Grid from '@mui/material/Grid';
import { Page } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import MCCLoading from '../../components/MCCLoading';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';
import { makeQuery, makeUrl } from '../CourseEnrollment/utils';
import OpenProcessesCount from './OpenProcessesCount';
import ProcessesTable, { IProcessesTableData } from './ProcessesTable';
import { header, initialInfo } from './utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../utils/theme';
import ProcessesTableMobile from './ProcessesTableMobile';
import { useTenant } from '../../components/TenantContext';

export default function Processes() {
    const [openInfo, setOpenInfo] = useState(initialInfo);
    const [closedInfo, setClosedInfo] = useState(initialInfo);
    const [openUrl, setOpenUrl] = useState(endpoints.students.processes.listOpen);
    const [closedUrl, setClosedUrl] = useState(endpoints.students.processes.listClosed);
    const tenant = useTenant();

    const [openProcessesCount, setOpenProcessesCount] = useState(0);

    const { data: openProcesses, loading: openProcessesLoading } = useFetch<IProcessesTableData>(openUrl, '');
    const { data: closedProcesses, loading: closedProcessesLoading } = useFetch<IProcessesTableData>(closedUrl, '');

    const matches = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        setOpenUrl(
            makeUrl(endpoints.students.processes.listOpen, openInfo) +
                (makeQuery(openInfo.filter || {}) ? '&' + makeQuery(openInfo.filter || {}) : ''),
        );
    }, [openInfo]);

    useEffect(() => {
        setClosedUrl(
            makeUrl(endpoints.students.processes.listClosed, closedInfo) +
                (makeQuery(closedInfo.filter || {}) ? '&' + makeQuery(closedInfo.filter || {}) : ''),
        );
    }, [closedInfo]);

    useEffect(() => {
        if (!Array.isArray(openProcesses) && openProcessesCount !== openProcesses?.metadata?.allCount) {
            setOpenProcessesCount(openProcesses?.metadata?.allCount || 0);
        }
    }, [openProcesses, openProcessesCount]);

    if (openProcessesLoading || closedProcessesLoading) {
        return <MCCLoading />;
    }

    return (
        <Page header={header(tenant || 'TAR')}>
            <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} spacing={2}>
                    <OpenProcessesCount count={openProcessesCount} />
                </Grid>
                {matches ? (
                    <>
                        <ProcessesTable
                            title="Folyamatban lévő ügyek"
                            data={openProcesses}
                            loading={openProcessesLoading}
                            info={openInfo}
                            setInfo={setOpenInfo}
                        />
                        <ProcessesTable
                            title="Korábbi, lezárt ügyek"
                            data={closedProcesses}
                            loading={closedProcessesLoading}
                            info={closedInfo}
                            setInfo={setClosedInfo}
                        />
                    </>
                ) : (
                    <>
                        <ProcessesTableMobile title="Folyamatban lévő ügyek" data={openProcesses} />
                        <ProcessesTableMobile title="Korábbi, lezárt ügyek" data={closedProcesses} />
                    </>
                )}
            </Grid>
        </Page>
    );
}
