import Grid from '@mui/material/Grid';
import { GridAlignment, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AutoloadTable, Page } from '@silinfo/front-end-template';
import { useState } from 'react';
import notificationsService from '../../../services/administration/notifications';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { CheckboxCellRenderer, EditCellRenderer } from './CellRenderers';
import { useTenant } from '../../../components/TenantContext';

export default function Notifications() {
    const [refresh, setRefresh] = useState(false);
    const tenant = useTenant();
    const header = {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_notifications_list]: 'Értesítések',
        },
        title: 'Értesítések',
        project: tenant || 'TAR',
    };

    const checkboxColumns: GridColDef[] = [
        {
            headerName: 'Pop-up',
            field: 'TYPE_POPUP',
        },
        {
            headerName: 'Egyszeri pop-up',
            field: 'TYPE_POPUP_ONCE',
        },
        {
            headerName: 'Rendszerüzenet',
            field: 'TYPE_SYSTEM_MESSAGE',
        },
        {
            headerName: 'E-mail',
            field: 'TYPE_EMAIL',
        },
    ].map((col) => ({
        ...col,
        renderCell: CheckboxCellRenderer,
        align: 'center',
        minWidth: 100,
        headerAlign: 'center',
    }));

    const columns = [
        {
            headerName: 'Kategória',
            field: 'category',
            sortable: false,
            description: 'Kategória',
        },
        {
            headerName: 'Esemény',
            field: 'label',
            description: 'Esemény',
        },
        ...checkboxColumns,
        {
            headerName: 'Műveletek',
            field: 'operations',
            align: 'center' as GridAlignment,
            headerAlign: 'center' as GridAlignment,
            renderCell: (props: GridRenderCellParams) => EditCellRenderer(props, setRefresh),
        },
    ].map((col) => ({ minWidth: 150, flex: 1, sortable: false, ...col }));

    return (
        <Page header={header}>
            <Grid item xs={12} style={{ height: '80vh' }} className="noTransition">
                <AutoloadTable
                    filter={(form) => notificationsService.filter(form)}
                    columns={columns}
                    responseKey="data"
                    hideFooter
                    disableSelectionOnClick
                    disableColumnMenu
                    noAccordionHeader={true}
                    refresh={refresh}
                    columnBuffer={columns.length}
                />
            </Grid>
        </Page>
    );
}
