import { Option } from '@silinfo/front-end-template';
import { IForm } from '../../../../utils/Interfaces/interfaces';

export interface ICertificateStudent {
    id: number | string;
    email?: string;
    firstName?: string;
    lastName?: string;
    campus?: string;
    trainingLevel?: string;
    trainingProgram?: string;
    collegeYear?: string;
    semesterStatus?: string;
    actualMembershipStatus?: string;
}

export type CampusOption = Option & { active: boolean };
export type TrainingLevelOption = Option & { archive: boolean };
export type TrainingProgramOption = Option & { trainingLevel: string; archive: boolean };

export interface MembershipCertificateSelected extends IForm {
    semester?: string;
    selected: (string | number)[];
}

export const initialMembershipCertificateSelected: MembershipCertificateSelected = {
    semester: '',
    selected: [],
};
