import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../../components/Form/Form';
import studentProfileService from '../../../services/studentServices/studentProfileService';
import { RootState } from '../../../store';
import { refreshAction } from '../../../store/studentProfile';
import { filterUnusedKeys } from '../../../utils/form';
import useProfilePicture from '../../components/Layout/useProfilePicture';
import { IProfilePictureForm } from './types';
import { AVATAR_SIZE, useStyles } from './utils';

export const ProfilePictureComponent = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { profile } = useSelector((state: RootState) => state.studentProfile);
    const dispatch = useDispatch();
    const { loading, profilePicture, profilePictureUrl } = useProfilePicture();

    const handleSubmit = (values: IProfilePictureForm) =>
        studentProfileService.saveProfilePicture(values).then(() => {
            setOpen(false);
            dispatch(refreshAction());
        });
    return (
        <div
            style={{
                position: 'absolute',
                bottom: 0,
                left: 20,
                marginBottom: -AVATAR_SIZE / 2,
                zIndex: 1100,
            }}
        >
            <IconButton sx={{ padding: 0 }} onClick={() => setOpen(true)}>
                {loading ? (
                    <Skeleton width={AVATAR_SIZE} height={AVATAR_SIZE} variant="circular" className="borderRadius" />
                ) : (
                    <Avatar
                        alt="profile picture"
                        src={profilePicture}
                        sx={{
                            width: AVATAR_SIZE,
                            height: AVATAR_SIZE,
                        }}
                        className={classes.avatar}
                    />
                )}
            </IconButton>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Profilkép</DialogTitle>
                <DialogContent style={{ marginTop: '4px' }}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={filterUnusedKeys(profile, ['profilePicture']) as IProfilePictureForm}
                        fields={[
                            {
                                type: 'file',
                                name: 'profilePicture',
                                label: 'Profilkép',
                                origFileName: 'profilePicture.origFileName',
                                uuidFileName: 'profilePicture.uuidFileName',
                                tempType: 'profile-image',
                                props: {
                                    inputProps: {
                                        accept: 'image/*',
                                    },
                                },
                                downloadUrl: profilePictureUrl,
                                format: {
                                    xs: 12,
                                },
                            },
                        ]}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};
