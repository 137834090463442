import { useEffect } from 'react';
import { FieldGenerator } from '../../../components/Form/FieldGenerator';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import * as React from 'react';
import { FormikProps } from 'formik';
import Autocomplete from '../../../components/Form/Autocomplete';
import studentsService from '../../../services/masterData/students';
import { LoadingButton } from '@mui/lab';
import { CancelButton } from '@silinfo/front-end-template';
import { ADMIN_FEE_CATEGORIES } from '../../../utils/AppConst';

export interface IAdministrationFee {
    category: string;
    students: never[];
    uniqueFeeName: string;
    uniqueFeeComment: string;
    amount: string;
    student: string;
}

export default function UniqueFeeDialogForm(
    props: FormikProps<IAdministrationFee> & {
        uniqueFeeId?: number;
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
        formLoading: boolean;
        currency?: string;
    },
) {
    const { uniqueFeeId, setFieldValue, setOpen, formLoading, currency } = props;

    function numberWithCommas(x: string | number) {
        const parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join('.');
    }

    useEffect(() => {
        const amountWithoutSpaces = props.values.amount.toString().replace(/\s/g, '');
        setFieldValue('amount', numberWithCommas(amountWithoutSpaces));
    }, [setFieldValue, props.values.amount]);

    return (
        <>
            {FieldGenerator({
                name: 'category',
                label: 'Díjkategória',
                format: { xs: 12, sm: 12, md: 12 },
                props: {
                    disabled: !!uniqueFeeId,
                },
                type: 'select',
                options: ADMIN_FEE_CATEGORIES,
                ...props,
            })}
            {FieldGenerator({
                name: 'uniqueFeeName',
                label: 'Díj megnevezése',
                format: { xs: 12, sm: 12, md: 12 },
                props: {
                    disabled: !!uniqueFeeId,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'amount',
                label: 'Összeg',
                props: {
                    InputProps: {
                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                    },
                    inputProps: {
                        maxLength: 10,
                    },
                    disabled: !!uniqueFeeId,
                },
                format: { xs: 12, sm: 12, md: 12 },
                ...props,
            })}
            {FieldGenerator({
                name: 'uniqueFeeComment',
                label: 'Megjegyzés',
                props: {
                    multiline: true,
                    rows: 8,
                    disabled: !!uniqueFeeId,
                },
                format: { xs: 12, sm: 12, md: 12 },
                ...props,
            })}
            <Grid item xs={12}>
                {!uniqueFeeId ? (
                    <Autocomplete
                        getter={(term) => {
                            return studentsService.searchByTerm(term);
                        }}
                        textFieldProps={{ label: 'Hallgató' }}
                        errorMessage={props.errors.students as string}
                        autocompleteProps={{
                            value: props.values.students || '',
                            onChange: (_, v) => props.setFieldValue('students', v),
                            multiple: true,
                        }}
                        createOptions={(users: { value: number; label: string }[]) =>
                            users.map((user) => ({
                                value: user.value.toString(),
                                label: user.label,
                            }))
                        }
                    />
                ) : (
                    <>
                        {FieldGenerator({
                            name: 'student',
                            label: 'Hallgató',
                            props: {
                                disabled: true,
                            },
                            format: { xs: 12, sm: 12, md: 12 },
                            ...props,
                        })}
                    </>
                )}
            </Grid>
            <Grid item mt={0.5} container spacing={2} justifyContent="flex-end">
                {!uniqueFeeId && (
                    <Grid item>
                        <LoadingButton loading={formLoading} variant="contained" type="submit">
                            Mentés
                        </LoadingButton>
                    </Grid>
                )}
                <Grid item>
                    <CancelButton onClick={() => setOpen(false)}>{uniqueFeeId ? 'Vissza' : 'Mégse'}</CancelButton>
                </Grid>
            </Grid>
        </>
    );
}
