import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IConflict } from './types';
import { useEffect, useState } from 'react';

export default function ConflictDialog({ conflicts }: { conflicts: IConflict[] }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (conflicts.length > 0) {
            setOpen(true);
        }
    }, [conflicts]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>Figyelmeztetés</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>
                <DialogContentText style={{ margin: '16px 0' }}>
                    A szemeszterben a következő képzés(ek) már felvételre kerültek egy aktív csoporthoz:
                </DialogContentText>
                <DialogContentText style={{ margin: '16px 0' }}>
                    <Table style={{ border: '1px solid #ddd' }}>
                        <TableHead>
                            <TableCell>Campus</TableCell>
                            <TableCell>Képzési szint</TableCell>
                            <TableCell>Képzési program</TableCell>
                        </TableHead>
                        <TableBody>
                            {conflicts.map((conflict: IConflict) => (
                                <TableRow key={conflict.campus + ' ' + conflict.trainingProgram}>
                                    <TableCell>{conflict.campus}</TableCell>
                                    <TableCell>{conflict.trainingLevel}</TableCell>
                                    <TableCell>{conflict.trainingProgram}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContentText>
                <DialogContentText>
                    Egy képzés egyszerre csak egy aktív csoportban szerepelhet. Aktiváláshoz kérlek módosítsd az aktív
                    felvételi csoportok képzéseit!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} variant="outlined">
                    Rendben
                </Button>
            </DialogActions>
        </Dialog>
    );
}
