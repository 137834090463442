import Grid from '@mui/material/Grid';
import { Page, TabsCompleted } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import MCCLoading from '../../../components/MCCLoading';
import courseBaseDataService from '../../../services/courseManagement/courseBaseData';
import { RootState } from '../../../store';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { userHasAccess } from '../../../utils/useAccessCheck';
import BaseData from './BaseData';
import CourseEnrollments from './CourseEnrollment';
import Documents from './Documents';
import Events from './Events';
import Prerequisites from './Prerequisites';
import TargetAudience from './TargetAudience';
import { ICourseView } from './types';
import { useTenant } from '../../../components/TenantContext';

export default function CourseForm({ readOnly = false }: { readOnly?: boolean }) {
    const { courseId } = useParams();
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');
    const { user } = useSelector((state: RootState) => state.auth);
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const [loading, setLoading] = useState<boolean>(false);
    const [closed, setClosed] = useState<boolean>(false);
    const [eventAssignable, setEventAssignable] = useState<boolean>(false);
    const [course, setCourse] = useState({} as ICourseView);
    const tenant = useTenant();

    useEffect(() => {
        if (courseId) {
            setLoading(true);
            courseBaseDataService
                .get(parseInt(courseId), courseTenant)
                .then((response) => {
                    setClosed(response.data.data.closed);
                    setCourse({ ...response.data.data, campus: response.data.data.campus?.value || '' });
                    setEventAssignable(response.data.data.eventAssignable);
                })
                .finally(() => setLoading(false));
        }
    }, [courseId, courseTenant]);

    const title = (id: string | undefined, readOnly: boolean) => {
        if (readOnly) {
            return course.courseName + ' (' + course.courseCode + ') megtekintése';
        } else if (id) {
            return course.courseName + ' (' + course.courseCode + ') szerkesztése';
        } else {
            return 'Kurzus hozzáadása';
        }
    };

    const header = {
        project: tenant || 'TAR',
        title: 'Kurzusok',
        breadcrumbs: {
            masterData: 'Kurzuskezelés',
            [clientEndPoints.course_manamagement_course_list]: 'Kurzuslista',
            form: title(courseId, readOnly),
        },
    };

    const userHasAccessToTab = (endpoints: string[]) => userHasAccess(endpoints, user, accessTree);

    const tabContents = [
        {
            label: 'Kurzus alapadatok',
            value: <BaseData />,
            hasAccess: userHasAccessToTab([
                'course_manamagement_course_edit_base',
                'course_manamagement_course_create',
                'course_manamagement_course_copy',
                'course_manamagement_course_show_base',
            ]),
        },
        {
            label: 'Előfeltételek',
            value: <Prerequisites closed={closed} readOnly={readOnly} />,
            disabled: !courseId,
            hasAccess: userHasAccessToTab([
                'course_manamagement_course_edit_base',
                'course_manamagement_course_show_base',
            ]),
        },
        {
            label: 'Események',
            value: <Events closed={closed} course={course} readOnly={readOnly} eventAssignable={eventAssignable} />,
            hasAccess: userHasAccessToTab(['course_manamagement_course_event']),
            disabled: !courseId,
        },
        {
            label: 'Célközönség',
            hasAccess: userHasAccessToTab([
                'course_manamagement_course_edit_base',
                'course_manamagement_course_show_base',
            ]),
            value: <TargetAudience closed={closed} readOnly={readOnly} />,
            disabled: !courseId,
        },
        {
            label: 'Dokumentumok',
            hasAccess: userHasAccessToTab([
                'course_manamagement_course_add_document',
                'course_manamagement_course_edit_base',
                'course_manamagement_course_create',
                'course_manamagement_course_show_base',
            ]),
            value: <Documents closed={closed} readOnly={readOnly} />,
            disabled: !courseId,
        },
        {
            label: 'Névsor, napló',
            hasAccess: userHasAccessToTab(['course_manamagement_course_roster']),
            value: <CourseEnrollments closed={closed} readOnly={readOnly} course={course} />,
            disabled: !courseId,
        },
    ].filter((elem) => elem.hasAccess);

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Page header={header}>
            <Grid item xs={12}>
                <TabsCompleted tabContents={tabContents} />
            </Grid>
        </Page>
    );
}
