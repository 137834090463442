import Grid from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Option } from '../../../../../utils/AppConst';
import { endpoints } from '../../../../../utils/endPoints';
import useFetch from '../../../../../utils/useFetch';
import PreviousStudiesBadge from './PreviousStudiesBadge';

const InfoTypography = (props: TypographyProps) => (
    <Grid item xs={12}>
        <Typography variant="body1" textAlign="center" color="white" {...props}>
            <i>{props.children}</i>
        </Typography>
    </Grid>
);

const Content = () => {
    const { data, loading } = useFetch(endpoints.students.profile.studies.previousStudies) as {
        data: Option[];
        loading: boolean;
    };

    if (loading) return <InfoTypography>Betöltés...</InfoTypography>;
    if (data.length < 1) return <InfoTypography>Nincs korábban elvégzett képzésed.</InfoTypography>;
    return (
        <>
            {data.map((item) => (
                <Grid item key={item.value}>
                    <PreviousStudiesBadge {...item} />
                </Grid>
            ))}
        </>
    );
};

export default function PreviousStudies() {
    return (
        <Grid
            item
            container
            flexDirection="column"
            alignItems="flex-start"
            alignSelf="flex-start"
            spacing={2}
            xs={12}
            sm={6}
            md={3}
        >
            <Grid item xs={12}>
                <Typography variant="h5" color="white">
                    Korábbi képzéseim
                </Typography>
            </Grid>
            <Grid item container spacing={1}>
                <Content />
            </Grid>
        </Grid>
    );
}
