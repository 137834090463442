import { ICompletionData, ITrainingCompletionData } from './../types';

export const isTrainingProgram = (row: ICompletionData | ITrainingCompletionData): row is ITrainingCompletionData =>
    (row as ITrainingCompletionData).semesters !== undefined;

export const isOpen = (
    row: ICompletionData | ITrainingCompletionData,
    trainingProgramId: string,
    semesterId: string,
): boolean => (isTrainingProgram(row) && row.id == trainingProgramId) || row.id == semesterId;

export const getRowBackgroundColor = (disabled: boolean, isSemester: boolean) => {
    if (disabled) return '#bfbfbf';
    if (isSemester) return '#b4c7e799';
    return '#b4c7e7';
};
