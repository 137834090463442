import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { endpoints } from '../../utils/endPoints';
import { IForm } from '../../utils/Interfaces/interfaces';
import PreviewTemplate from './../../components/PreviewTemplate/index';
import instance from '../../api';
import { setRefreshLocalStorage } from '../../components/PreviewTemplate/utils';

export default function Filler() {
    const { id } = useParams() as { id: string };

    const handleSubmit = useCallback(
        async (values: IForm) => {
            await instance.post(endpoints.students.questionnaires.main(+id), values);
            setRefreshLocalStorage();
        },
        [id],
    );
    return (
        <PreviewTemplate
            url={endpoints.students.questionnaires.main(+id)}
            keyPrefix="student_fill"
            type="selfEvaluation"
            onSubmit={handleSubmit}
        />
    );
}
