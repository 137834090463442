import QuizIcon from '@mui/icons-material/Quiz';
import Dialog from '@mui/material/Dialog';
import { TableButton } from '@silinfo/front-end-template';
import useOpen from '../../../../utils/useOpen';
import ActionDialogContent from '../../../Questionnaires/ActionDialogContent';

interface FillQuestionnaireButtonProps {
    isAnonymous: boolean;
    id: number;
}

export default function FillQuestionnaireButton({ id, isAnonymous }: FillQuestionnaireButtonProps) {
    const { isOpen, open, close } = useOpen();

    return (
        <>
            <TableButton
                color="secondary"
                onClick={open}
                className="borderRadius"
                style={{
                    boxShadow:
                        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
                    borderRadius: '50%',
                }}
                tooltip="Kérdőív kitöltése"
            >
                <QuizIcon />
            </TableButton>
            <Dialog open={isOpen} onClose={close} fullWidth>
                <ActionDialogContent close={close} isAnonymous={isAnonymous} id={id} />
            </Dialog>
        </>
    );
}
