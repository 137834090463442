import { Accordion, FieldGenerator, Page, Form } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../components/MCCLoading';
import { IApiKeysForm, formHeader, initialForm, title } from './utils';
import { FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import apiKeysService from '../../../services/administration/apiKeysService';
import { endpoints } from '../../../utils/endPoints';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import DynamicFields from '../../../components/DynamicFields';
import { useTenant } from '../../../components/TenantContext';

export default function ApiKeysForm() {
    const [form, setForm] = useState<IApiKeysForm>(initialForm);
    const { apiKeyId } = useParams() as { apiKeyId: string };
    const [pageLoading, setPageLoading] = useState<boolean>(!!apiKeyId);
    const tenant = useTenant();

    const generateToken = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 25; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };

    const handleGenerateClick = (formikProps: FormikProps<IApiKeysForm>) => {
        const token: string = generateToken();
        formikProps.setFieldValue('token', token);
    };

    useEffect(() => {
        if (apiKeyId) {
            setPageLoading(true);
            apiKeysService
                .get(apiKeyId)
                .then((res) => {
                    const transformedIps = res.data.ipRestrictions.map((ip: string) => {
                        return { ip: ip };
                    });
                    setForm({ ...res.data, ipRestrictions: transformedIps });
                })
                .finally(() => setPageLoading(false));
        }
    }, [apiKeyId]);

    const handleSubmit = (form: IApiKeysForm) => {
        let ipArray = form.ipRestrictions.map((obj) => Object.values(obj)[0]);
        ipArray = ipArray.length === 1 && ipArray[0] === '' ? [] : ipArray;
        return apiKeyId
            ? apiKeysService.update(apiKeyId, { ...form, ipRestrictions: ipArray })
            : apiKeysService.create({ ...form, ipRestrictions: ipArray });
    };

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={formHeader(apiKeyId, tenant || 'TAR')}>
            <Accordion title={title(apiKeyId)} defaultExpanded disableClosing>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={form}
                    fields={[]}
                    back={clientEndPoints.admin_api_keys_list}
                >
                    {(props) => (
                        <>
                            {FieldGenerator({
                                ...props,
                                label: 'Token',
                                name: 'token',
                                type: 'text',
                                format: { xs: 6, md: 6 },
                                props: {
                                    disabled: true,
                                },
                            })}
                            {!apiKeyId && (
                                <Grid
                                    item
                                    xs={6}
                                    md={6}
                                    textAlign="center"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <LoadingButton
                                        onClick={() => handleGenerateClick(props)}
                                        style={{
                                            justifyContent: 'space-between',
                                        }}
                                        variant="contained"
                                    >
                                        Generálás
                                    </LoadingButton>
                                </Grid>
                            )}
                            {FieldGenerator({
                                ...props,
                                label: 'Funkciók',
                                name: 'functions',
                                type: 'backendSelect',
                                url: endpoints.administration.apiKeys.functions,
                                multiple: true,
                                justValue: true,
                                format: { xs: 12, md: 6 },
                            })}
                            <Grid container item>
                                <Grid item xs={12} md={6} sx={{ padding: '5px' }}>
                                    <Typography variant="h6">IP korlátozások</Typography>
                                    <Typography variant="body1">
                                        Csak ezeknek az IP címeknek engedélyezett az API kulcs használata. Amennyiben
                                        nincs megadva, úgy bármilyen IP-ről elérhető!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DynamicFields
                                        keyName="ipRestrictions"
                                        label="IP cím"
                                        header="body1"
                                        required={false}
                                        inputFields={(index) => [
                                            {
                                                label: '',
                                                name: 'ip',
                                                props: {
                                                    error: !!props.errors[`ipRestrictions.${index}.ip`],
                                                    helperText: props.errors[`ipRestrictions.${index}.ip`],
                                                },
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            {FieldGenerator({
                                ...props,
                                label: 'Megjegyzés',
                                name: 'comment',
                                type: 'text',
                                format: { xs: 12, md: 12 },
                                props: {
                                    rows: 4,
                                    multiline: true,
                                    maxRows: 4,
                                },
                            })}
                            {FieldGenerator({
                                type: 'checkbox',
                                label: 'Aktív',
                                name: 'active',
                                format: { xs: 12, md: 12 },
                                ...props,
                            })}
                        </>
                    )}
                </Form>
            </Accordion>
        </Page>
    );
}
