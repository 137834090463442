import Info from '@mui/icons-material/Info';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import Cell from './Cell';
import { IEvent } from './types';
import { createTheme } from '@mui/material/styles';
import { eventTypes } from './utils';
import Content from './Content';

export default function InfoDialog(event: IEvent) {
    const [open, setOpen] = useState(false);
    const theme = createTheme();

    return (
        <Cell>
            <IconButton color="info" onClick={() => setOpen((prev) => !prev)}>
                <Info
                    className="borderRadius"
                    style={{
                        fontSize: '2rem',
                        backgroundColor: theme.palette.info.main,
                        color: 'white',
                        borderRadius: '50%',
                    }}
                />
            </IconButton>
            <Dialog open={open} maxWidth="sm" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle>
                    {event.label}{' '}
                    {(eventTypes.find((eventType) => eventType.value === event.type)?.label || '').toLowerCase()} (
                    {event.course})
                </DialogTitle>
                <Content id={event.id} />
            </Dialog>
        </Cell>
    );
}
