import Grid from '@mui/material/Grid';
import { formatNumberToThousands } from '../../utils/AppConst';
import PriceTag from './PriceTag';

interface UnpaidAmountProps {
    amount: number;
    currency?: string;
}

export default function UnpaidAmount({ amount, currency }: UnpaidAmountProps) {
    return (
        <Grid item container xs={12} md={6} justifyContent="flex-end">
            <PriceTag value={(formatNumberToThousands(amount) || '0') + ' ' + currency} label="Fizetendő összeg" />
        </Grid>
    );
}
