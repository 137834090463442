import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import { theme } from '../../../utils/theme';
import AccordionForm from './AccordionForm';
import { CheckboxAccordionProps, INotificationForm } from './types';

const CheckboxAccordion = ({ label, value }: CheckboxAccordionProps) => {
    const { setFieldValue, values } = useFormikContext<INotificationForm>();

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            color="secondary"
            expandIcon={<Checkbox sx={{ color: theme.palette.primary.main }} checked={values.types[value].value} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
        },
    }));

    const handleChange = () => {
        setFieldValue('types.' + value + '.value', !values.types[value].value);
        if (value === 'TYPE_POPUP') {
            setFieldValue('types.TYPE_POPUP_ONCE.value', false);
        } else if (value === 'TYPE_POPUP_ONCE') {
            setFieldValue('types.TYPE_POPUP.value', false);
        }
    };

    return (
        <Grid item xs={12}>
            <Accordion elevation={4} expanded={values.types[value].value} onChange={handleChange}>
                <AccordionSummary>{label}</AccordionSummary>
                <AccordionDetails>
                    <AccordionForm value={value} />
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default CheckboxAccordion;
