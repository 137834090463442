import { Input } from '@silinfo/front-end-template';
import { ADMIN_FEE_CATEGORIES, financeFinancialApprovalStatuses } from '../../../utils/AppConst';
import { FinancialApprovalsView } from './utils';

const statusVals = Object.values(financeFinancialApprovalStatuses);

export const filterFields = (view: FinancialApprovalsView): Input[] => [
    {
        name: 'category',
        label: 'Díjkategória',
        type: 'multiselect',
        options: ADMIN_FEE_CATEGORIES,
        format: {
            xs: 12,
            md: 4,
        },
    },
    {
        name: 'identifier',
        label: 'Azonosító',
        format: {
            xs: 12,
            md: 4,
        },
    },
    {
        name: 'student',
        label: 'Hallgató',
        format: {
            xs: 12,
            md: 4,
        },
    },
    {
        name: 'feeName',
        label: 'Díj típusa',
        type: 'multiselect',
        format: {
            xs: 12,
            md: 4,
        },
        options: [
            { value: 'Kurzusfelvételi/Kurzusleadási díj', label: 'Kurzusfelvételi/Kurzusleadási díj' },
            { value: 'Kaució', label: 'Kaució' },
            { value: 'Egyedi díj', label: 'Egyedi díj' },
            { value: 'Kötbér', label: 'Kötbér' },
            { value: 'Szobakulcs', label: 'Szobakulcs' },
            { value: 'Nyelvvizsga díj', label: 'Nyelvvizsga díj' },
            { value: 'Kurzus-nemteljesítés', label: 'Kurzus-nemteljesítés' },
        ],
    },
    {
        name: 'createdAtFrom',
        label: 'Létrehozás dátuma (-tól)',
        type: 'datetime-local',
        format: { xs: 12, md: 6 },
    },
    {
        name: 'createdAtUntil',
        label: 'Létrehozás dátuma (-ig)',
        type: 'datetime-local',
        format: { xs: 12, md: 6 },
    },
    {
        name: 'semester',
        label: 'Szemeszter',
        format: {
            xs: 12,
            md: 6,
        },
        type: 'multiselect',
        options: [],
    },
    {
        name: 'status',
        label: 'Státusz',
        format: {
            xs: 12,
            md: 6,
        },
        type: 'multiselect',
        options: Object.keys(financeFinancialApprovalStatuses).map((value: string, index: number) => {
            return {
                value: value,
                label: statusVals[index],
            };
        }),
    },
    {
        name: 'amount',
        label: 'Összeg',
        type: 'fromTo',
        format: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: 'taxNumber',
        label: 'Adóazonosító jel',
        format: {
            xs: 12,
            md: 4,
        },
    },
    {
        name: 'bankNumber',
        label: 'Bankszámlaszám',
        format: {
            xs: 12,
            md: 4,
        },
    },
    {
        name: 'address',
        label: 'Lakcím',
        format: {
            xs: 12,
            md: 4,
        },
    },
    ...(view === 'guest'
        ? [
              {
                  name: 'tenant',
                  label: 'Tenant',
                  type: 'multiselect',
                  options: [],
                  format: {
                      xs: 12,
                      md: 4,
                  },
              } as Input,
          ]
        : []),
];
