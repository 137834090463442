import Category from '@mui/icons-material/Category';
import Grade from '@mui/icons-material/Grade';
import AccountBalance from '@mui/icons-material/AccountBalance';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Done from '@mui/icons-material/Done';
import Badge from '@mui/icons-material/Badge';
import Public from '@mui/icons-material/Public';
import LocationCity from '@mui/icons-material/LocationCity';
import BorderColor from '@mui/icons-material/BorderColor';
import CardTravel from '@mui/icons-material/CardTravel';
import Gite from '@mui/icons-material/Gite';
import Work from '@mui/icons-material/Work';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import MilitaryTech from '@mui/icons-material/MilitaryTech';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const educationIcons: {
    [key: string]: OverridableComponent<SvgIconTypeMap<Record<string, string>, 'svg'>> & { muiName: string };
} = {
    institute: AccountBalance,
    major: Grade,
    trainingType: Category,
    certificateFileName: VerifiedUser,
};

export const internshipIcons: {
    [key: string]: OverridableComponent<SvgIconTypeMap<Record<string, string>, 'svg'>> & { muiName: string };
} = {
    internshipStart: PlayArrow,
    internshipEnd: Done,
    employer: Badge,
    internshipCountry: Public,
    internshipCity: LocationCity,
    activity: BorderColor,
};

export const foreingScholarshipIcons: {
    [key: string]: OverridableComponent<SvgIconTypeMap<Record<string, string>, 'svg'>> & { muiName: string };
} = {
    stayAbroadStart: PlayArrow,
    stayAbroadEnd: Done,
    stayAbroadType: CardTravel,
    hostName: Gite,
    hostCountry: Public,
    hostCity: LocationCity,
    hostMajor: Grade,
};

export const workplaceIcons: {
    [key: string]: OverridableComponent<SvgIconTypeMap<Record<string, string>, 'svg'>> & { muiName: string };
} = {
    jobStart: PlayArrow,
    jobStartEnd: Done,
    jobName: Work,
    jobCountry: Public,
    jobCity: LocationCity,
    jobActivity: BorderColor,
};

export const competitionIcons: {
    [key: string]: OverridableComponent<SvgIconTypeMap<Record<string, string>, 'svg'>> & { muiName: string };
} = {
    competitionInstitution: AccountBalance,
    competition: EmojiEvents,
    competitionResult: MilitaryTech,
    certificateFileName: VerifiedUser,
};
