import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@silinfo/front-end-template';

const useStyles = makeStyles(() => ({
    truncate: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export function CapacityCellRenderer(params: GridRenderCellParams) {
    const classes = useStyles();

    const headCount = parseInt(params.row.capacity.split('/')[0]);
    const maxHeadCount = parseInt(params.row.capacity.split('/')[1]);
    const waitingListCount = parseInt(params.row.capacity.split('/')[2]) - headCount;

    let color = 'green';

    if (headCount > maxHeadCount) {
        color = 'red';
    } else if (headCount < maxHeadCount) {
        color = 'green';
    } else {
        color = '#7EC8E3';
    }

    return (
        <Tooltip
            title={
                <div>
                    Jelentkezett hallgatók: {headCount} fő
                    <br />
                    Férőhelyek: {maxHeadCount} fő
                    <br />
                    Várólistára jelentkezve: {waitingListCount} fő
                </div>
            }
        >
            <div style={{ color }} className={classes.truncate}>
                {waitingListCount === 0
                    ? headCount + '/' + maxHeadCount
                    : headCount + '/' + maxHeadCount + ' (' + waitingListCount + ')'}
            </div>
        </Tooltip>
    );
}
