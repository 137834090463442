import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Form, { FormProps } from '../../components/Form/Form';
import { setProfile } from '../../store/studentProfile';
import DialogTitle from '@mui/material/DialogTitle';

const EditDialog = <T extends Record<string, unknown>, D extends object>(
    props: FormProps<T, D> & { openState: [boolean, (val: boolean) => void] },
) => {
    const [open, setOpen] = props.openState;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    return (
        <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
            <DialogTitle>Szerkesztés</DialogTitle>
            <DialogContent sx={{ marginTop: '8px' }}>
                <Form
                    {...props}
                    hideButtons
                    onSubmit={(values, formikHelpers) => {
                        const result = props.onSubmit(values, formikHelpers);
                        if (result instanceof Promise) {
                            setLoading(true);
                            result
                                .then((res) => {
                                    setOpen(false);
                                    if ((res as AxiosResponse)?.data?.data) {
                                        dispatch(setProfile((res as AxiosResponse).data.data));
                                    }
                                })
                                .finally(() => setLoading(false));
                        }
                        return result;
                    }}
                >
                    {(formikProps) => (
                        <>
                            {props.children && props.children(formikProps)}
                            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <Button onClick={() => setOpen(false)} variant="outlined">
                                        Mégsem
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <LoadingButton loading={loading} type="submit" variant="contained">
                                        Mentés
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default EditDialog;
