import { ICourseTypeRequirement } from './types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

export const tableColumns = (
    renderCell: (row: GridRenderCellParams<ICourseTypeRequirement, ICourseTypeRequirement>) => JSX.Element,
    renderCbCell: (row: GridRenderCellParams<ICourseTypeRequirement, ICourseTypeRequirement>) => JSX.Element,
): GridColDef[] =>
    [
        {
            headerName: 'Kurzustípus',
            field: 'courseType',
            minWidth: 200,
        },
        {
            headerName: 'Követelmény',
            field: 'type',
            minWidth: 200,
        },
        {
            headerName: 'Képzés alatt teljesítendő kurzusok száma',
            field: 'numberOfCoursesToBeCompleted',
            sortable: false,
        },
        {
            headerName: '1 szemeszter alatt teljesítendő kurzusok száma',
            field: 'numberOfCoursesToBeCompletedPerSemester',
            sortable: false,
        },
        {
            headerName: 'Minimum kurzusszám társ képzésből',
            field: 'minAmountToBeCompletedFromOtherTrainingPrograms',
            sortable: false,
        },
        {
            headerName: 'Teljesítési igazoláson megjelenik',
            field: 'visibleOnCompletionPage',
            renderCell: renderCbCell,
            sortable: false,
        },
        {
            headerName: 'Pontot ér',
            field: 'worthPoint',
            renderCell: renderCbCell,
            sortable: false,
        },
        {
            headerName: 'Ösztöndíjba beleszámít',
            field: 'includedInTheScholarship',
            renderCell: renderCbCell,
            sortable: false,
        },
        {
            headerName: 'Pontozásba nem számító kurzusok száma',
            field: 'numberOfCoursesNotCounting',
            sortable: false,
        },
        { headerName: 'Műveletek', field: 'operations', renderCell, sortable: false, align: 'center' },
    ].map((col) => ({
        minWidth: 150,
        flex: 1,
        align: col.minWidth ? 'left' : 'center',
        headerAlign: col.minWidth ? 'left' : 'center',
        ...col,
    })) as GridColDef[];
