import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import instance from '../../api';
import { fixDateRangeFields } from '../../utils/AppConst';
import { ICourseEnrollmentForm, IForm } from '../../utils/Interfaces/interfaces';
import { RequestForm } from '../../studentPages/CourseEnrollment/RequestSendButton';
import IEntityBase from '../../interface/IEntityBase';

export interface ICourseType {
    type: 'waiting_list' | 'normal';
    course: number;
    student: number;
}

const courseEnrollmentService = {
    ...crudTemplate<ICourseType>(endpoints.students.courseEnrollment.main),
    enrollment: (form: IForm) => instance.post(endpoints.students.courseEnrollment.enrollment, form),
    dismissal: (form: IForm) => instance.post(endpoints.students.courseEnrollment.dismissal, form),
    getAllByCourse: (form: IForm, courseId: string, courseTenant: string | null) => {
        const endpoint = endpoints.students.courseEnrollment.getAllByCourse.replace('{courseId}', courseId);

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant, {
                params: fixDateRangeFields(form),
            });
        } else {
            return instance.get(endpoint, {
                params: fixDateRangeFields(form),
            });
        }
    },
    getAllByCoursePaginated: (form: IForm, courseTenant: string | null, courseId: string) => {
        const endpoint = endpoints.students.courseEnrollment.getAllByCoursePaginated.replace('{courseId}', courseId);

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant, {
                params: fixDateRangeFields(form),
            });
        } else {
            return instance.get(endpoint, {
                params: fixDateRangeFields(form),
            });
        }
    },

    getEnrolledStudentsNames: (courseId: string, courseTenant: string | number) =>
        instance.get(
            endpoints.students.courseEnrollment.getEnrolledStudentsNames
                .replace('{id}', courseId)
                .replace('{courseTenant}', courseTenant.toString()),
        ),
    getCurrentSemesterInfo: () => instance.get(endpoints.students.courseEnrollment.currentSemesterInfo),
    requestEnrollment: (form: RequestForm) =>
        instance.post(endpoints.students.courseEnrollment.request.enrollment, form),
    requestDismissal: (form: RequestForm) => instance.post(endpoints.students.courseEnrollment.request.dismissal, form),
    initiateExport: (form: { format: string }, filter: ICourseEnrollmentForm) =>
        instance.post(endpoints.students.courseEnrollment.export.initiate, form, {
            params: fixDateRangeFields<string | IEntityBase>(filter),
        }),
    checkExport: (id: number) =>
        instance.get(endpoints.students.courseEnrollment.export.check.replace('{id}', id.toString())),
    downloadExport: (id: number) =>
        instance.get(endpoints.students.courseEnrollment.export.download.replace('{id}', id.toString()), {
            responseType: 'arraybuffer',
        }),
    prerequisites: (courseId: string, courseTenant: string | null) => {
        const endpoint = endpoints.students.courseEnrollment.request.prerequisite.replace('{courseId}', courseId ?? '');

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
};

export default courseEnrollmentService;
