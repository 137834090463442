import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Paper } from '../Requirements/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../utils/theme';

interface PriceTagProps {
    value: string;
    label: string;
}

export default function PriceTag({ value, label }: PriceTagProps) {
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Paper style={matches ? {} : { width: '100%', height: '90%' }}>
            <Grid item container spacing={1} alignItems="center" xs={12} justifyContent="space-between">
                <Grid item>
                    <Typography>{label}</Typography>
                </Grid>
                <Grid item>
                    <Paper
                        style={
                            matches
                                ? { background: '#eee' }
                                : { background: '#eee', paddingTop: '4px', paddingBottom: '4px' }
                        }
                    >
                        <strong>{value}</strong>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}
