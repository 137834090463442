import { default as DeleteIcon } from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { SubmitButton } from '@silinfo/front-end-template';
import { CancelButton, DownloadButton } from '@silinfo/front-end-template/lib/esm/components/Button';
import { Children, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../../../../api';
import { refreshAction } from '../../../../store/studentProfile';
import { isKep, Option } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import { IActualUniversityStudies } from '../../../../utils/Interfaces/interfaces';
import Data from '../Data';
import { EditMode } from './ActualStudiesEdit';
import { fieldsToDisplay, IActualStudies } from './utils';
import { RootState } from '../../../../store';

export const NormalMode = ({
    aus,
    universities,
    legalRelationships,
    trainingForms,
    initialValues,
}: {
    aus: IActualUniversityStudies;
    universities: Option<string>[];
    legalRelationships: Option<string>[];
    trainingForms: Option<string>[];
    initialValues: IActualStudies;
}) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const [isOneEdit, setIsOneEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const handleDialogOpen = useCallback(() => setDialogOpen(true), []);
    const handleDialogClose = useCallback(() => setDialogOpen(false), []);

    const handleDelete = (id: number) => {
        return instance.delete(endpoints.students.profile.baseData.saveActualUniversityStudies + '/' + id).then(() => {
            dispatch(refreshAction());
        });
    };

    let fieldsDisplay = fieldsToDisplay;

    if (isKep()) {
        fieldsDisplay = fieldsDisplay.filter((field) => field.name !== 'neptun');
    } else {
        fieldsDisplay = fieldsDisplay.filter((field) => field.name !== 'pedigreeNumber');
    }

    if (!isOneEdit) {
        return (
            <Grid item container spacing={2} xs={12}>
                {Children.toArray(
                    fieldsDisplay.map((field) => {
                        if (field.name === 'university') {
                            let universityName = field.getValue ? field.getValue(aus[field.name], universities) : '-';

                            if (aus.foreignUniversity) {
                                universityName = `${aus.foreignUniversity} (${universityName})`;
                            }

                            return (
                                <Data label={field.label} format={field.format} key={field.name}>
                                    {universityName}
                                </Data>
                            );
                        }
                        return (
                            <Data label={field.label} format={field.format} key={field.name}>
                                {field.getValue
                                    ? '' + field.getValue('' + aus[field.name])
                                    : '' + aus[field.name] || '-'}
                            </Data>
                        );
                    }),
                )}
                <Grid item xs={9}>
                    {aus?.filePath && (
                        <DownloadButton
                            buttonText={'Felvételi határozat'}
                            downloadName={aus?.fileName || ''}
                            url={`${endpoints.fileManagement.download}?id=${aus?.id}&type=student-document&subType=actual-university-studies`}
                        />
                    )}
                </Grid>
                {!user.archive && (
                    <Grid item container justifyContent={'flex-end'} xs={3}>
                        <IconButton onClick={() => setIsOneEdit(true)}>
                            <Edit color={'primary'} />
                        </IconButton>
                        <IconButton onClick={handleDialogOpen}>
                            <DeleteIcon color={'error'} />
                        </IconButton>
                        <Dialog open={dialogOpen} onClose={handleDialogClose} style={{ minWidth: '400px' }}>
                            <DialogTitle>Aktuális egyetemi tanulmány törlése</DialogTitle>
                            <DialogContent style={{ paddingTop: '24px' }}>Biztosan törli?</DialogContent>
                            <DialogActions>
                                <CancelButton onClick={handleDialogClose}>Nem</CancelButton>
                                <SubmitButton onClick={() => handleDelete(aus.id)}>Igen</SubmitButton>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                )}
            </Grid>
        );
    } else {
        return (
            <EditMode
                aus={aus}
                universities={universities}
                legalRelationships={legalRelationships}
                trainingForms={trainingForms}
                initialValues={initialValues}
                setIsOneEdit={setIsOneEdit}
            />
        );
    }
};
