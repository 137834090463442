import moment from 'moment';
import api from '../api';
import { endpoints } from '../utils/endPoints';
import axios from 'axios';

export async function fetchLocalData() {
    let ret;
    const regex = /\/felveteli\/(?=.*[a-zA-Z0-9]).{10,}$/;
    const request =
        window.location.pathname === '/felveteli' || regex.test(window.location.pathname)
            ? axios.get(endpoints.currentTenantAll)
            : api.get(endpoints.currentTenantAll);
    try {
        const response = await request;
        ret = response.data;
    } catch (error) {
        console.error('Error fetching current tenant data:', error);
        ret = null;
    }

    return ret;
}

export function formatDate(date: string | undefined): string {
    if (!date) {
        return '-';
    }

    const momentDate = moment(date);
    const adjustedDate = momentDate.add(localStorage.getItem('offset'), 'seconds');
    return adjustedDate.format('YYYY.MM.DD. HH:mm:ss');
}
export function formatDateWithoutConvert(date: string | undefined): string {
    if (!date) {
        return '-';
    }

    const momentDate = moment(date);
    return momentDate.format('YYYY.MM.DD. HH:mm:ss');
}

export function addOffsetToDate(date: string): string {
    const momentDate = moment(date);
    const adjustedDate = momentDate.add(localStorage.getItem('offset'), 'seconds');
    return adjustedDate.format('YYYY-MM-DDTHH:mm');
}
