import { clientEndPoints } from '../../../utils/clientEndPoints';

export const header = (tenant: string | null) => {
    return {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_settings_list]: 'Beállítások',
        },
        title: 'Beállítások',
        project: tenant || 'TAR',
    };
};
