import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import TableRow from '@mui/material/TableRow';
import { endpoints } from '../../../../../../utils/endPoints';
import SmallTable from '../SmallTable';
import { getColorByStatus, statusTranslations } from '../utils';
import { ICompletionData, ITrainingCompletionData } from './../types';
import {
    CompletedCellRenderer,
    CompletionCell,
    EnrolledCoursesCellRenderer,
    LanguageExamRequirementsCellRenderer,
    MandatoryCoursesCellRenderer,
} from './CellRenderers';
import Header from './Header';
import TableCell from './TableCell';
import { findLabelByValue } from '../../../../../../utils/AppConst';
import { semesterStatusOptions } from '../../Form/options';

const ComponentToPrint = ({ data }: { data: ICompletionData | ITrainingCompletionData }) => {
    const row = data;
    return (
        <>
            <h1>MCC teljesítési lap</h1>
            <h1>
                {data.student?.name || 'Ismeretlen'}, MCC azonosító: {data.student?.mccId || '-'}, Státusz:{' '}
                {findLabelByValue(data.student?.status || '', semesterStatusOptions) || 'ismeretlen'} {'('}
                {new Date().toLocaleString()}
                {')'}
            </h1>
            <Header print />
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset !important' },
                    fontFamily: "'Roboto', sans-serif !important",
                    height: '50px',
                    borderWidth: '0 !important',
                }}
            >
                <TableCell
                    align="left"
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {row.name}
                </TableCell>
                <TableCell>
                    <CompletedCellRenderer {...row} />
                </TableCell>
                <TableCell>
                    <EnrolledCoursesCellRenderer {...row} />
                </TableCell>
                <TableCell>{row.minToBeCompleted} pont</TableCell>
                <TableCell>
                    <MandatoryCoursesCellRenderer
                        row={row}
                        url={endpoints.masterData.students.trainingProgramCompletion.requiredCourseInfo}
                    />
                </TableCell>
                <TableCell>
                    <CompletionCell {...row.electiveCourses} />
                </TableCell>
                <TableCell>
                    <CompletionCell {...row.coCourses} />
                </TableCell>
                <TableCell>
                    <LanguageExamRequirementsCellRenderer
                        row={row}
                        url={endpoints.masterData.students.trainingProgramCompletion.langInfo}
                    />
                </TableCell>
                <TableCell>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item>
                            <span style={{ color: getColorByStatus(row.status) }}>
                                {statusTranslations[row.status]}
                            </span>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            {row.disabled ? null : (
                <TableRow style={{ padding: 0, borderWidth: 0 }}>
                    <TableCell style={{ padding: 0, borderWidth: 0 }} colSpan={10}>
                        <Collapse in timeout="auto" unmountOnExit>
                            <SmallTable print rows={(row as ICompletionData).courses || []} />
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default ComponentToPrint;
