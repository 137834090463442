import { IInfo } from '../../../utils/Interfaces/IInfo';

export type IScholarshipReport = {
    id: number;
    groups: string;
    professional: string;
    project: string;
    total: string;
    paid: string;
    remaining: string;
} & ({ student?: string; semester?: never } | { semester?: string; student?: never });

export interface ISchedule {
    month: string;
    amount: number;
    status: string;
}

export interface ScholarshipReportFilter {
    student: number[];
    campus: number[];
    trainingLevel: number[];
    trainingProgram: number[];
    groups: string[];
    scholarshipType: string;
}

export const initialReportFilterState = (semester: string): IInfo<ScholarshipReportFilter> => ({
    filter: {
        semester: semester ? [semester] : [],
    },
    sort: {},
    page: 1,
    perpage: 25,
    metadata: {},
});
