import Grid from '@mui/material/Grid';
import { FormikProps } from 'formik';
import Autocomplete from '../../../../components/Form/Autocomplete';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import { ICourseInfo, TAddStudentButton } from './AddStudentButton';
import courseListService from '../../../../services/courseManagement/courseList';
import { useEffect } from 'react';
import { useTenant } from '../../../../components/TenantContext';

export default function WaitingListManagement(
    props: FormikProps<TAddStudentButton> & {
        courseInfo: ICourseInfo;
        courseId: number | string;
        onWaitingListValueChange: (value: boolean) => void;
    },
) {
    const tenant = useTenant();

    const incrementMaxHeadCountMessage =
        `A ${tenant} tenant maximális létszáma bővítésre kerül! Jelenlegi maximális létszám: ` +
        props.courseInfo.maxHeadCount +
        '. Új maximális létszám: ' +
        (+props.courseInfo.maxHeadCount + 1);
    useEffect(() => {
        const isIncrementMaxHeadCountSelected = props.values.operation === 'incrementMaxHeadCount';
        const isStudentToWaitingListSelected = !!props.values.studentToWaitingList;
        props.onWaitingListValueChange(isIncrementMaxHeadCountSelected || isStudentToWaitingListSelected);
    }, [props.values.operation, props.values.studentToWaitingList, props.onWaitingListValueChange, props]);
    return (
        <>
            {FieldGenerator({
                name: 'operation',
                label: 'Művelet',
                type: 'select',
                fieldType: 'base',
                options: [
                    { value: 'incrementMaxHeadCount', label: 'Kurzus maximális létszámának bővítése' },
                    ...(props.courseInfo.waitlist
                        ? [{ value: 'moveStudentToWaitingList', label: 'Hallgató áthelyezése várólistára' }]
                        : []),
                ],
                format: { xs: 12, sm: 12 },
                ...props,
            })}

            {props.values.operation === 'incrementMaxHeadCount' && (
                <Grid item xs={12}>
                    {incrementMaxHeadCountMessage}
                </Grid>
            )}

            {props.values.operation === 'moveStudentToWaitingList' && (
                <Grid item xs={12}>
                    <Autocomplete
                        getter={(term) => {
                            return courseListService.centralStudentSearchByCourse(term, props.courseId ?? '');
                        }}
                        textFieldProps={{ label: 'Várólistára helyezendő hallgató' }}
                        errorMessage={props.errors.studentToWaitingList as string}
                        autocompleteProps={{
                            value: props.values.studentToWaitingList || '',
                            onChange: (_, v) => props.setFieldValue('studentToWaitingList', v),
                        }}
                        createOptions={(users: { value: number; label: string }[]) =>
                            users.map((user) => ({
                                value: user.value.toString(),
                                label: user.label,
                            }))
                        }
                    />
                </Grid>
            )}
        </>
    );
}
