import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

const translation: Record<string, string> = {
    yes: 'Igen',
    no: 'Nem',
    waiting_list: 'Várólistán',
};

type EnrolledUserRendererProps = {
    params: GridRenderCellParams;
};

const Icon = ({
    isEnrolled,
    hasOpenCourseDismissalRequest,
    hasOpenCourseEnrollmentRequest,
}: {
    isEnrolled: string;
    hasOpenCourseDismissalRequest: boolean;
    hasOpenCourseEnrollmentRequest: boolean;
}) => {
    switch (isEnrolled) {
        case 'yes':
            if (hasOpenCourseDismissalRequest) return <HourglassTopIcon color="warning" />;
            return <CheckIcon color="success" />;
            break;
        case 'waiting_list':
            return <HourglassTopIcon color="warning" />;
            break;
        default:
            if (hasOpenCourseEnrollmentRequest) return <HourglassTopIcon color="warning" />;
            return <CloseIcon color="error" />;
    }
};

export default function EnrolledUserRenderer({ params }: EnrolledUserRendererProps) {
    const { isEnrolled, hasOpenCourseDismissalRequest, hasOpenCourseEnrollmentRequest } = params.row;

    let title = '';

    if (hasOpenCourseEnrollmentRequest) {
        title = 'Felvételi kérelem jóváhagyás alatt';
    } else if (hasOpenCourseDismissalRequest) {
        title = 'Kurzusleadási kérelem jóváhagyás alatt';
    } else {
        title = translation[isEnrolled] || isEnrolled;
    }

    return (
        <Tooltip title={title}>
            <div>
                <Icon
                    isEnrolled={isEnrolled}
                    hasOpenCourseDismissalRequest={hasOpenCourseDismissalRequest}
                    hasOpenCourseEnrollmentRequest={hasOpenCourseEnrollmentRequest}
                />
            </div>
        </Tooltip>
    );
}
