import { useMemo } from 'react';
import DependentSelectInputGenerator from '../../../../../../components/Form/DependentSelectInputGenerator';
import { endpoints } from '../../../../../../utils/endPoints';
import { createOptions, inputProps } from './utils';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const AddressInput = ({ props }: { props: { disabled: boolean; campus: string; detailed: boolean } }) => {
    const location = useLocation().pathname;
    const { id } = useParams();
    const isEdit = id && !props.detailed ? true : false;

    const input = useMemo(() => inputProps(props.disabled, location), [location, props.disabled]);
    return (
        <DependentSelectInputGenerator
            parentInput={input}
            endpoint={`${endpoints.courseManagement.courseList.campuses}`}
            keyOfResponse={''}
            campus={props.campus}
            optionCreator={createOptions}
            disabled={props.disabled || isEdit}
        />
    );
};

export default AddressInput;
