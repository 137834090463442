import { Page, TabsCompleted } from '@silinfo/front-end-template';
import { header } from './utils';
import TabContent from './TabContent';
import { useTenant } from '../../../components/TenantContext';

export default function CourseEvaluationReport() {
    const tenant = useTenant();
    const tabContents = [
        {
            label: 'Kurzuslista',
            value: <TabContent view="course" />,
        },
        {
            label: 'Hallgatói lista',
            value: <TabContent view="student" />,
        },
    ];

    return (
        <Page header={header(tenant || 'TAR')}>
            <TabsCompleted tabContents={tabContents} />
        </Page>
    );
}
