import Grid from '@mui/material/Grid';
import { FormikListPage, Header, Loading, useFetch } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import instance from '../../../api';
import EndpointProvider from '../../../components/EndpointProvider';
import SemesterSelector from '../../../components/SemesterSelector';
import { SemesterContextProvider } from '../../../components/SemesterSelector/SemesterContextProvider';
import IEntityBase from '../../../interface/IEntityBase';
import selfEvaluationReportService from '../../../services/selfEvalution/report';
import { RefreshContextProvider } from '../../../studentPages/SelfAssessment/RefreshContextProvider';
import { Option, initialInfo } from '../../../utils/AppConst';
import { IForm, TrainingProgramOption } from '../../../utils/Interfaces/interfaces';
import { endpoints } from '../../../utils/endPoints';
import ExportButton, { ExportContextWithSemester } from './ExportButton';
import RefreshButton from './RefreshButton';
import { breadcrumbs, header, tableProps } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

export default function SelfAssessment() {
    const { user } = useSelector((state: RootState) => state.auth);
    const semesterState = useState<Option | null>(null);
    const [semester] = semesterState;
    const [exportInfo, setExportInfo] = useState<IForm>(initialInfo({ student: 'asc' }));
    const [refresh, setRefresh] = useState(false);
    const toggleRefresh = useCallback(() => setRefresh((prev) => !prev), []);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const { data: campus, loading: campusLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.campuses.main,
        'hydra:member',
    );
    const { data: collegeYear, loading: collegeYearLoading } = useFetch<Option[]>(
        endpoints.masterData.collegeYears.options,
        '',
    );
    const { data: trainingLevel, loading: trainingLevelLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.trainingLevels.main,
        'hydra:member',
    );
    const { data: trainingProgram, loading: trainingProgramLoading } = useFetch<TrainingProgramOption[]>(
        endpoints.masterData.trainingPrograms.main,
        'hydra:member',
    );

    const options: Record<string, Option[]> = {
        campus: campus
            .map((item) => ({ label: item.name, value: '' + item.id }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        collegeYear,
        trainingLevel: trainingLevel
            .map((item) => ({ label: item.name, value: '' + item.id }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        trainingProgram: trainingProgram
            .map((item: TrainingProgramOption) => ({
                label: (item.trainingLevel ? item.trainingLevel.name + ' / ' : '') + item.name,
                value: '' + item.id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
    };

    const loading = campusLoading || collegeYearLoading || trainingLevelLoading || trainingProgramLoading;

    const handleFilter = useCallback(
        (filter: IForm) => {
            setExportInfo({ ...filter, semester: semester?.value || '0' });
            return instance.get(endpoints.selfEvaluation.report.filter(semester?.value || '0'), { params: filter });
        },
        [semester],
    );

    const exportContext: ExportContextWithSemester = {
        initiateExport: selfEvaluationReportService.initiateExport,
        checkExport: selfEvaluationReportService.checkExport,
        downloadExport: selfEvaluationReportService.downloadExport,
        info: initialInfo({ student: 'asc' }),
        filter: exportInfo,
        semesterId: semester?.value || '0',
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...headerWithTenant, breadcrumbs }} />
            </Grid>
            <Grid item xs={11}>
                <SemesterSelector
                    defaultActive
                    semesterState={semesterState}
                    url={endpoints.selfEvaluation.report.semesters}
                />
            </Grid>
            {!user.archive && (
                <EndpointProvider endpoints={['self_evaluation_edit']}>
                    <RefreshButton semester={semester?.value || ''} toggleRefresh={toggleRefresh} />
                </EndpointProvider>
            )}
            <Grid item xs={12}>
                <RefreshContextProvider refresh={toggleRefresh}>
                    <SemesterContextProvider semester={semester?.value || ''}>
                        {!loading ? (
                            <FormikListPage
                                {...tableProps(semester?.value || '', options, tenant)}
                                service={{ filter: handleFilter }}
                                refresh={JSON.stringify([refresh, semester?.value || ''])}
                                customButtons={
                                    semester && !user.archive ? (
                                        <Grid item xs={12} textAlign="right">
                                            <ExportButton exportContext={exportContext} />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        ) : (
                            <Loading noHeight />
                        )}
                    </SemesterContextProvider>
                </RefreshContextProvider>
            </Grid>
        </Grid>
    );
}
