import { LoadingButton } from '@mui/lab';
import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { CancelButton } from '@silinfo/front-end-template';
import React, { useCallback, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import Form from '../../../../components/Form/Form';
import courseDocumentService from '../../../../services/courseManagement/courseDocument';
import { RootState } from '../../../../store';
import { endpoints } from '../../../../utils/endPoints';
import { IForm } from '../../../../utils/Interfaces/interfaces';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export interface IDocumentForm {
    name: string;
    uuidFileName: string;
    origFileName: string;
    isSyllabus: string;
}

const initialValues = {
    name: '',
    uuidFileName: '',
    origFileName: '',
    isSyllabus: false,
};

export default function AddEditDocumentButton({
    title,
    submitBtnTitle,
    opener,
    objectId,
    setTableLoading,
    wasSyllabus,
    courseTenant,
}: {
    title: string;
    submitBtnTitle: string;
    opener: JSX.Element;
    objectId?: number;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    wasSyllabus: boolean;
    courseTenant: string | null;
}) {
    const { courseId } = useParams() as { courseId: string };
    const [open, setOpen] = useState(false);
    const [isSyllabus, setIsSyllabus] = useState(wasSyllabus);
    const { count } = useSelector((state: RootState) => state.loading);

    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIsSyllabus(false);
    };

    const getEvent = useCallback(async () => {
        if (!objectId) return;
        const { data } = await courseDocumentService.get(+courseId, objectId, courseTenant);
        return {
            ...data,
            data: {
                name: data.data.name,
                uuidFileName: data.data.filePath.split('/')[1],
                origFileName: data.data.fileName,
                isSyllabus: data.data.type === 'syllabus' ? '1' : '0',
            },
        };
    }, [objectId, courseId, courseTenant]);

    const getterFunctionProps = useMemo(
        () =>
            objectId
                ? {
                      getterFunction: getEvent,
                  }
                : {},
        [getEvent, objectId],
    );

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <div>
                    <DialogContent>
                        <Form
                            hideButtons
                            fields={[]}
                            urlParam={'courseId'}
                            onSubmit={(form: IForm) =>
                                (!objectId
                                    ? courseDocumentService.create(courseId, form, courseTenant)
                                    : courseDocumentService.update(courseId, objectId, form, courseTenant)
                                ).then(() => {
                                    handleClose();
                                    setTableLoading((prev) => !prev);
                                })
                            }
                            initialValues={initialValues}
                            {...getterFunctionProps}
                        >
                            {(formikProps) => {
                                return (
                                    <>
                                        {FieldGenerator({
                                            name: 'name',
                                            label: 'Dokumentum megnevezése',
                                            format: { xs: 12, sm: 12 },
                                            fieldType: 'base',
                                            props: {
                                                disabled: isSyllabus,
                                            },
                                            ...formikProps,
                                        })}
                                        {FieldGenerator({
                                            name: 'isSyllabus',
                                            label: 'Syllabus feltöltése',
                                            type: 'checkbox',
                                            format: { xs: 12 },
                                            props: {
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const { checked } = e.target;

                                                    if (checked) {
                                                        setIsSyllabus(true);
                                                        formikProps.setFieldValue('name', 'Syllabus');
                                                    } else {
                                                        setIsSyllabus(false);
                                                    }

                                                    formikProps.setFieldValue('origFileName', '');
                                                    formikProps.setFieldValue('uuidFileName', '');
                                                },
                                            },
                                            ...formikProps,
                                        })}

                                        <Grid item {...{ xs: 12, sm: 12 }}>
                                            {FieldGenerator({
                                                name: 'name',
                                                label: 'Dokumentum',
                                                type: 'file',
                                                fieldType: 'base',
                                                origFileName: 'origFileName',
                                                uuidFileName: 'uuidFileName',
                                                tempType: isSyllabus ? 'course_document_syllabus' : 'course_document',
                                                downloadUrl: `${
                                                    endpoints.fileManagement.download
                                                }?id=${objectId}&type=${
                                                    isSyllabus ? 'course_document_syllabus' : 'course_document'
                                                }`,
                                                format: { xs: 12, sm: 12 },
                                                ...formikProps,
                                            })}
                                        </Grid>

                                        <Grid item container spacing={2} justifyContent="flex-end">
                                            <Grid item>
                                                <LoadingButton loading={count > 0} variant="contained" type="submit">
                                                    {submitBtnTitle}
                                                </LoadingButton>
                                            </Grid>
                                            <Grid item>
                                                <CancelButton onClick={handleClose}>Bezár</CancelButton>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            }}
                        </Form>
                    </DialogContent>
                </div>
            </DialogBasic>
        </>
    );
}
