import { default as API } from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
import { endpoints } from '../../utils/endPoints';

const questionnaireTemplateService = {
    filter: (form: IForm) => API.get(endpoints.selfEvaluation.questionnaireTemplate.list, { params: form }),
    delete: (id: string) => API.delete(endpoints.selfEvaluation.questionnaireTemplate.delete.replace('{id}', id)),
    duplicate: (id: string) => API.post(endpoints.selfEvaluation.questionnaireTemplate.duplicate.replace('{id}', id)),
    archive: (id: string) => API.delete(endpoints.selfEvaluation.questionnaireTemplate.archive.replace('{id}', id)),
    unarchive: (id: string) => API.post(endpoints.selfEvaluation.questionnaireTemplate.unarchive.replace('{id}', id)),
    get: (id: string) => API.get(endpoints.selfEvaluation.questionnaireTemplate.show.replace('{id}', id)),
    update: (id: string, form: IForm) =>
        API.post(endpoints.selfEvaluation.questionnaireTemplate.show.replace('{id}', id), form),
    create: (form: IForm) => API.post(endpoints.selfEvaluation.questionnaireTemplate.list, form),
    getForm: (semesterId: string) => API.get(endpoints.students.selfEvaluation.form(semesterId)),
    fillForm: (semesterId: string, form: IForm) => API.post(endpoints.students.selfEvaluation.form(semesterId), form),
    fillForm2: (semesterId: string, form: IForm, formIndex: number, finalization?: boolean) =>
        API.post(
            endpoints.students.selfEvaluation.form(semesterId) +
                '?formIndex=' +
                formIndex +
                '&finalization=' +
                (finalization ? '1' : '0'),
            form,
        ),
    getSemester: (semesterId: string) => API.get(endpoints.students.selfEvaluation.semester(semesterId)),
    getPreview: (formBuilder: IForm) => API.post(endpoints.selfEvaluation.questionnaireTemplate.preview, formBuilder),
    search: (term: string) => API.get(endpoints.selfEvaluation.questionnaireTemplate.search + '?term=' + term),
    courseTypes: () => API.get(endpoints.selfEvaluation.questionnaireTemplate.courseTypes),
    getOptions: () => API.get(endpoints.selfEvaluation.questionnaireTemplate.templateOptions),
};

export default questionnaireTemplateService;
