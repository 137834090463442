import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { PROFILE_PICTURE } from '../../Profile/ProfileHeader/utils';

type TLayoutContext = {
    profilePictureState: [string, Dispatch<SetStateAction<string>>];
};

const defaultContext: TLayoutContext = {
    profilePictureState: [PROFILE_PICTURE, () => console.error('No context provider was set above this element')],
};

const LayoutContext = createContext<TLayoutContext>(defaultContext);

interface LayoutContextProviderProps {
    children: React.ReactNode;
}

export function LayoutContextProvider(props: LayoutContextProviderProps) {
    const profilePictureState = useState(PROFILE_PICTURE);
    return <LayoutContext.Provider value={{ profilePictureState }}>{props.children}</LayoutContext.Provider>;
}

export default LayoutContext;
