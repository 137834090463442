import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContextText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useMemo, useState } from 'react';
import { clientEndPoints } from '../../utils/clientEndPoints';
import RefreshContext from '../SelfAssessment/RefreshContextProvider';
import instance from '../../api';
import { endpoints } from '../../utils/endPoints';

type Stage = 'confirmation' | 'decline' | 'anonymous';
const STAGES = ['confirmation', 'decline', 'anonymous'] as const;

interface StageStateButtonProps {
    label: string;
    onClick: () => void;
}

interface StageStateActions {
    no: StageStateButtonProps;
    yes: StageStateButtonProps;
}

interface StageState {
    content: JSX.Element;
    actions: StageStateActions;
}

interface ActionDialogContentProps {
    close: () => void;
    isAnonymous: boolean;
    id: number;
}

export default function ActionDialogContent({ close, isAnonymous, id }: ActionDialogContentProps) {
    const [stage, setStage] = useState<Stage>(STAGES[2]);
    const refresh = useContext(RefreshContext);
    const openQuestionnaire = useCallback(() => {
        window.open(clientEndPoints.student_questionnaires_fill.replace(':id', '' + id), '_blank');
        close();
    }, [close, id]);
    const handleOpen = useCallback(
        () => (isAnonymous ? openQuestionnaire() : setStage(STAGES[2])),
        [isAnonymous, openQuestionnaire],
    );

    const STAGE_STATES: Record<Stage, StageState> = useMemo(
        () => ({
            [STAGES[0]]: {
                content: <DialogContextText>Biztosan kitöltöd a kérdőívet?</DialogContextText>,
                actions: {
                    no: {
                        label: 'Nem tudom kitölteni',
                        onClick: () => setStage(STAGES[1]),
                    },
                    yes: {
                        label: 'Kitöltöm',
                        onClick: handleOpen,
                    },
                },
            },
            [STAGES[1]]: {
                content: (
                    <>
                        <DialogContextText>
                            Csak abban az esetben tagadhatod meg a kérdőív kitöltését, ha nem vettél részt a kurzuson.
                            Ez az alábbiakat vonhatja maga után:
                        </DialogContextText>
                        <ul>
                            <li>
                                <DialogContextText>
                                    innentől nem lesz anonim a kérdőíved, az adminisztrátorok látni fogják, hogy nem
                                    töltötted ki a kérdőívet
                                </DialogContextText>
                            </li>
                            <li>
                                <DialogContextText>
                                    megeshet, hogy a kurzusra {`"nem értékelhető"`} vagy {`"nem teljesített"`}{' '}
                                    értékelést kapsz
                                </DialogContextText>
                            </li>
                        </ul>
                        <DialogContextText>Biztos, hogy nem tudod kitölteni a kérdőívet?</DialogContextText>
                    </>
                ),
                actions: {
                    no: {
                        label: 'Nem',
                        onClick: close,
                    },
                    yes: {
                        label: 'Igen',
                        onClick: async () => {
                            await instance.post(endpoints.students.questionnaires.main(id), { refusalToFill: true });
                            close();
                            refresh();
                        },
                    },
                },
            },
            [STAGES[2]]: {
                content: (
                    <DialogContextText>
                        Kérjük, vedd figyelembe, hogy a kitöltendő kérdőív <strong>nem anonim</strong>, így az{' '}
                        <strong>adminisztrátorok látni fogják az adott válaszok mellett a neved és azonosítód</strong>!
                    </DialogContextText>
                ),
                actions: {
                    no: {
                        label: 'Mégsem',
                        onClick: close,
                    },
                    yes: {
                        label: 'Tovább',
                        onClick: openQuestionnaire,
                    },
                },
            },
        }),
        [handleOpen, close, openQuestionnaire, id, refresh],
    );

    const { content, actions } = STAGE_STATES[stage];
    const { no, yes } = actions;
    const { label: noLabel, onClick: noClick } = no;
    const { label: yesLabel, onClick: yesClick } = yes;

    return (
        <>
            <DialogTitle>Megerősítés</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>{content}</DialogContent>
            <DialogActions>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button variant="outlined" onClick={noClick}>
                            {noLabel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={yesClick}>
                            {yesLabel}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}
