import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Input, Option } from '@silinfo/front-end-template/src/components/Form/types';
import Form from '../../../../../../components/Form/Form';
import { IForm } from '../../../../../../utils/Interfaces/interfaces';
import { SectionRow } from '../SectionGenerator';
import ActualUniversityStudiesDialogForm from './ActualUniversityStudiesDialogForm';
import { createInitialValues, getFileName, getFilePath, newRow } from './utils';

type ActualUniversityStudiesDialogProps = {
    open: boolean;
    row: SectionRow | undefined;
    handleSubmit: (form: IForm) => void;
    handleCancel: () => void;
    id: string | undefined;
    actualUniversityStudiesFields: Input[];
    optionData: [] | Option<string, string>[];
};

export default function ActualUniversityStudiesDialog(props: ActualUniversityStudiesDialogProps) {
    const { open, row, handleSubmit, handleCancel, actualUniversityStudiesFields, optionData, id } = props;

    const getInitialValues = () => {
        if (row) {
            return {
                ...row,
                ...newRow(row),
                uuidFileName: getFilePath(row),
                origFileName: getFileName(row),
            };
        }
        return createInitialValues(id);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>{'Egyetemi tanulmány' + (row ? ' szerkesztése' : ' hozzáadása')}</DialogTitle>
            <Form fields={[]} onSubmit={handleSubmit} hideButtons initialValues={getInitialValues()}>
                {(formikProps) => (
                    <ActualUniversityStudiesDialogForm
                        formikProps={formikProps}
                        actualUniversityStudiesFields={actualUniversityStudiesFields}
                        optionData={optionData}
                        handleCancel={handleCancel}
                        row={row}
                    />
                )}
            </Form>
        </Dialog>
    );
}
