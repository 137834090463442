import Administration from '../../pages/Admission/Administration';
import GroupForm from '../../pages/Admission/Administration/GroupForm';

const administration = {
    path: 'administration',
    children: [
        {
            path: '',
            element: <Administration />,
            name: 'administration',
        },
        {
            path: ':semesterId/new',
            element: <GroupForm />,
            name: 'admission_administration_new_group',
        },
        {
            path: ':semesterId/:groupId',
            element: <GroupForm />,
            name: 'admission_administration_modify_group',
        },
    ],
};

export default administration;
