import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Loading } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import studentsService from '../../../../../../services/masterData/students';
import StudentAllocationPreferenceDialogContent from './StudentAllocationPreferenceDialogContent';
import { initialStudentAllocationPreference, IStudentAllocationPreference, ProcessDialogProps } from './types';

export default function StudentAllocationPreferenceDialog(props: ProcessDialogProps) {
    const { id, open, handleClose } = props;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IStudentAllocationPreference>(initialStudentAllocationPreference);

    useEffect(() => {
        if (open) {
            setLoading(true);
            studentsService
                .getStudentAllocationPreference(id)
                .then((response: AxiosResponse) => {
                    setData(response.data);
                })
                .finally(() => setLoading(false));
        }
    }, [open, id]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid item container>
                <Grid item xs={12}>
                    <DialogTitle>Folyamat adatlapja</DialogTitle>
                </Grid>
                <Grid item container xs={12}>
                    <DialogContent>
                        {loading ? <Loading noHeight /> : <StudentAllocationPreferenceDialogContent data={data} />}
                    </DialogContent>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>
                            Bezár
                        </Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}
