import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PROCESS_STATUS_OPTIONS_DETAILED } from '../utils';
import EnrollmentDialog from './ProcessDialogs/EnrollmentDialog';
import { ProcessDialogButton } from './ProcessDialogs/ProcessDialogButton';
import RelocationDialog from './ProcessDialogs/RelocationDialog';
import StudentAllocationPreferenceDialog from './ProcessDialogs/StudentAllocationPreferenceDialog';
import { Tooltip } from '@silinfo/front-end-template';
import TerminationDialog from './ProcessDialogs/TerminationDialog';

export const ProcessTypeCellRenderer = (props: GridRenderCellParams) => {
    return PROCESS_STATUS_OPTIONS_DETAILED.find(({ type_en }) => type_en === props.row.processType)?.type_hu;
};

export const DisplayIdCellRenderer = (props: GridRenderCellParams) => {
    return (
        <>
            {props.row.displayId}
            {props.row.hasModification && (
                <Tooltip title="Státuszváltozásokat tartalmaz">
                    <b>*</b>
                </Tooltip>
            )}
        </>
    );
};

export const ProcessStatusCellRenderer = (props: GridRenderCellParams) => {
    return PROCESS_STATUS_OPTIONS_DETAILED.find(
        ({ value, type_en }) => type_en === props.row.processType && value === props.row.processStatus,
    )?.label;
};

export const ButtonCellRenderer = (props: GridRenderCellParams) => {
    const processType = props.row.processType;
    const id = props.row.id;

    switch (processType) {
        case 'student_allocation_preference':
            return <ProcessDialogButton id={id} Dialog={StudentAllocationPreferenceDialog} />;
        case 'enrollment':
            return <ProcessDialogButton id={id} Dialog={EnrollmentDialog} />;
        case 'relocation':
            return <ProcessDialogButton id={id} Dialog={RelocationDialog} />;
        case 'termination':
            return <ProcessDialogButton id={id} Dialog={TerminationDialog} isMoveOut={false} />;
        case 'move_out':
            return <ProcessDialogButton id={id} Dialog={TerminationDialog} isMoveOut={true} />;
        default:
            return <></>;
    }
};
