import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { GridColDef } from '@mui/x-data-grid-pro';
import { AutoloadTable } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import studentsService from '../../../../../services/masterData/students';
import { theme } from '../../../../../utils/theme';
import { mergeStudentProcessIds } from '../utils';
import { IStudentProcessIds } from './DetailedForm';
import {
    ButtonCellRenderer,
    DisplayIdCellRenderer,
    ProcessStatusCellRenderer,
    ProcessTypeCellRenderer,
} from './StudentProcessCellRenderers';
import { IProcess } from './types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const studentProcessColumns = (archive?: boolean): GridColDef[] =>
    [
        { headerName: 'Sorszám', field: 'displayId', renderCell: DisplayIdCellRenderer },
        { headerName: 'Típus', field: 'processType', renderCell: ProcessTypeCellRenderer },
        { headerName: 'Folyamatstátusz', field: 'processStatus', renderCell: ProcessStatusCellRenderer },
        { headerName: 'Szemeszter', field: 'semester' },
        { headerName: 'Campus', field: 'campus' },
        ...(!archive
            ? [{ headerName: 'Műveletek', field: 'operations', renderCell: ButtonCellRenderer, sortable: false }]
            : []),
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));

export interface StudentProcessesProps {
    loading: boolean;
    activeSection: number;
    setActiveSection: (section: number) => void;
    number: number;
    studentProcessIds: IStudentProcessIds;
    setStudentProcessIds: (studentProcessIds: IStudentProcessIds) => void;
}

function StudentProcesses(props: StudentProcessesProps) {
    const { loading, activeSection, setActiveSection, number, studentProcessIds, setStudentProcessIds } = props;
    const { id } = useParams();
    const { user } = useSelector((state: RootState) => state.auth);

    if (!id) {
        return <></>;
    }

    if (loading) return <Skeleton height={35} />;

    const filterProcesses = async (filterFunction: (id: string) => Promise<AxiosResponse>) => {
        const { data }: { data: { data: IProcess[]; success: boolean } } = await filterFunction(id);
        setStudentProcessIds(mergeStudentProcessIds(data.data, studentProcessIds));
        return filterFunction(id);
    };

    return (
        <Accordion expanded={activeSection === number} onChange={() => setActiveSection(number)}>
            <AccordionSummary
                expandIcon={
                    <ExpandMore
                        style={{
                            fontSize: 'xx-large',
                            color: theme.palette.secondary.contrastText,
                        }}
                    />
                }
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                }}
            >
                Hallgatói folyamatok
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={4}>
                    <AutoloadTable
                        title="Aktív folyamatok"
                        columns={studentProcessColumns(user.archive)}
                        filter={() => filterProcesses(studentsService.ongoingProcesses)}
                        responseKey="data"
                    />
                    <AutoloadTable
                        title="Lezárult folyamatok"
                        columns={studentProcessColumns(user.archive)}
                        filter={() => filterProcesses(studentsService.doneProcesses)}
                        responseKey="data"
                    />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

const propsAreEquals = (prev: StudentProcessesProps, next: StudentProcessesProps): boolean => {
    return prev.loading === next.loading && prev.activeSection === next.activeSection;
};

const MemoizedStudentProcesses = memo(StudentProcesses, propsAreEquals);

export default MemoizedStudentProcesses;
