import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ICourseReferenceCodeRequirement } from './types';

export const tableColumns = (
    renderCell: (row: GridRenderCellParams<ICourseReferenceCodeRequirement>) => JSX.Element,
): GridColDef[] => [
    {
        headerName: 'Kurzus törzsszáma',
        field: 'courseReferenceCode',
    },
    {
        headerName: 'Kurzus neve',
        field: 'courseName',
    },
    { headerName: 'Műveletek', field: 'operations', renderCell, sortable: false },
];
