import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useEffect, useState } from 'react';
import instance from '../../../api';
import PDFDownloader from '../../../components/PDFDownloader';
import { TPDFResponse } from '../../../components/PDFDownloader/types';
import { IResponse } from '../../../components/PreviewTemplate/types';
import { handleClick } from '../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/DownloadTemplate';
import FormToPrint from '../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/FormToPrint';
import { FormToPrintProps } from '../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/types';

const OnClickDownloadButton = ({
    url,
    filename,
    type,
    downloadOnClick = true,
}: {
    url: string;
    filename: string;
    type?: 'summary' | 'selfEvaluation'; //FIXME: ez nem kell
    downloadOnClick?: boolean;
}) => {
    const [loading, setLoading] = useState(false);
    const [dataToDownload, setDataToDownload] = useState<TPDFResponse<IResponse>>({
        row: {
            fill: [],
            dateFilled: true,
            forms: [{ pages: {}, name: '' }],
        },
        filename,
    });

    const handleDownload = useCallback(() => {
        setLoading(true);
        instance
            .get<IResponse>(url)
            .then(({ data }) => {
                setDataToDownload((prev) => ({ ...prev, row: data }));
            })
            .finally(() => setLoading(false));
    }, [url]);

    const onClick = useCallback(() => {
        setLoading(true);
        instance
            .get<IResponse>(url)
            .then(({ data }) => {
                setDataToDownload((prev) => ({ ...prev, row: data }));
            })
            .then(() => handleClick(url, filename))
            .finally(() => setLoading(false));
    }, [url, filename]);

    useEffect(() => {
        if (!downloadOnClick) {
            handleDownload();
        }
    }, [handleDownload, downloadOnClick]);

    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => (
            <FormToPrint type={type || 'selfEvaluation'} {...formToPrintProps} />
        ),
        [type],
    );

    if (loading)
        return (
            <Grid item>
                <CircularProgress size={24} />
            </Grid>
        );

    if (!type) {
        return (
            <Grid item>
                <Tooltip title="Letöltés">
                    <IconButton
                        color="primary"
                        sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                        size="small"
                        onClick={onClick}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={20} /> : <DownloadIcon />}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }

    return (
        <Grid item>
            <Tooltip title="Letöltés">
                <PDFDownloader
                    data={dataToDownload}
                    ComponentToPrint={FormToPrintByType}
                    DownloadIcon={<DownloadIcon color="primary" />}
                    portrait
                />
            </Tooltip>
        </Grid>
    );
};

export default OnClickDownloadButton;
