import Grid from '@mui/material/Grid';
import { Accordion, Input, Link, Page } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { IF_FIRST_IS_FINALIZED, WRITE_FIELD_NAME, getFields, header, initialValue } from './utils';
import Button from '@mui/material/Button';
import styles from './Form.module.scss';
import questionnaireGroupService from '../../../../services/selfEvalution/questionnaireGroup';
import { ITemplateGroupForm } from './types';
import { useFormikContext } from 'formik';
import { FINALIZED_CASE_GROUP_WRITEABLE_OPTIONS, QUESTIONNAIRE_GROUP_WRITEABLE_OPTIONS } from '../useTemplateFields';
import { useTenant } from '../../../../components/TenantContext';

const ConditionChecker = ({ setFields }: { setFields: React.Dispatch<React.SetStateAction<Input[]>> }) => {
    const { values }: { values: ITemplateGroupForm } = useFormikContext();

    useEffect(() => {
        const conditionalValue = values.ruleQuestionnaire2Read;
        if (conditionalValue.toString() === IF_FIRST_IS_FINALIZED) {
            setFields((fields) => {
                const index = fields.findIndex((field) => field.name === WRITE_FIELD_NAME);
                if (index !== -1) {
                    const updatedFields = [...fields];
                    updatedFields[index] = {
                        ...updatedFields[index],
                        options: FINALIZED_CASE_GROUP_WRITEABLE_OPTIONS,
                    } as Input;
                    return updatedFields;
                }

                return fields;
            });
        } else {
            setFields((fields) => {
                const index = fields.findIndex((field) => field.name === WRITE_FIELD_NAME);
                if (index !== -1) {
                    const updatedFields = [...fields];
                    updatedFields[index] = {
                        ...updatedFields[index],
                        options: QUESTIONNAIRE_GROUP_WRITEABLE_OPTIONS,
                    } as Input;

                    return updatedFields;
                }

                return fields;
            });
        }
    }, [setFields, values.ruleQuestionnaire2Read]);

    return null;
};

export default function GroupFromTemplate() {
    const { id } = useParams();
    const title = id ? 'Kérdőívcsoport szerkesztése' : 'Új kérdőívcsoport';
    const [initialValues, setInitialValues] = useState(initialValue);
    const [loading, setLoading] = useState(!!id);
    const [optionsLoading, setOptionsLoading] = useState(true);
    const [fields, setFields] = useState<Input[]>([]);
    const tenant = useTenant();

    useEffect(() => {
        const fetchFields = async () => {
            const fetchedFields = await getFields();
            setFields(fetchedFields);
            setOptionsLoading(false);
        };
        fetchFields();
    }, []);

    useEffect(() => {
        if (id) {
            questionnaireGroupService
                .get(id)
                .then((res) => {
                    setInitialValues(res.data.data);
                })
                .finally(() => setLoading(false));
        }
    }, [id]);

    const handleSubmit = useCallback(
        (values: ITemplateGroupForm) => {
            const newValues = { ...values };
            return id ? questionnaireGroupService.update(id, newValues) : questionnaireGroupService.create(newValues);
        },
        [id],
    );

    if (loading || optionsLoading) return <MCCLoading />;

    return (
        <Page header={header(title, tenant || 'TAR')}>
            <Grid item xs={12} className={styles.resetStyles}>
                <Accordion title={title} defaultExpanded disableClosing>
                    <Form
                        onSubmit={handleSubmit}
                        fields={fields}
                        initialValues={initialValues}
                        back={clientEndPoints.admin_questionnaire_templates + '?tab=1'}
                        hideButtons
                    >
                        {() => (
                            <Grid item xs={12} container spacing={2}>
                                <ConditionChecker setFields={setFields} />
                                <Grid item xs={12} container spacing={1}>
                                    <Grid item>
                                        <Button variant="contained" color="primary" type="submit">
                                            Mentés
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Link to={clientEndPoints.admin_questionnaire_templates + '?tab=1'}>
                                            <Button variant="outlined" color="primary">
                                                Vissza
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Form>
                </Accordion>
            </Grid>
        </Page>
    );
}
