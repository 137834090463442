import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { ConfirmFinalizeDialogProps } from './types';

export default function ConfirmFinalizeDialog({
    openState,
    semester,
    applicantState,
    programState,
    applicantNameLabelState,
    toggleRefresh,
}: ConfirmFinalizeDialogProps) {
    const [open, setOpen] = openState;
    const [currentApplicantId, setCurrentApplicantId] = applicantState;
    const [currentProgramId, setCurrentProgramId] = programState;
    const [applicantNameLabel, setApplicantNameLabel] = applicantNameLabelState;
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setCurrentApplicantId(null);
        setCurrentProgramId(null);
        setApplicantNameLabel('');
    };

    const handleConfirm = () => {
        setLoading(true);
        API.post(endpoints.admission.finalizeAdmission(semester.value, currentApplicantId, currentProgramId))
            .then(() => {
                handleClose();
                toggleRefresh();
            })
            .finally(() => setLoading(false));
    };

    return (
        <Dialog open={open} fullWidth onClose={handleClose}>
            <DialogTitle>Jelentkezés véglegesítése</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>
                <DialogContentText>
                    Biztosan véglegesíted {applicantNameLabel} nevű képzési programra való jelentkezését? A művelet
                    utólag nem módosítható és nem vonható vissza!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={loading}
                    onClick={handleConfirm}
                    variant="contained"
                    disabled={!currentApplicantId && !currentProgramId}
                >
                    Igen
                </LoadingButton>
                <Button variant="contained" color="error" onClick={handleClose}>
                    Mégsem
                </Button>
            </DialogActions>
        </Dialog>
    );
}
