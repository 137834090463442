import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, SubmitButton } from '@silinfo/front-end-template';
import { getJWTToken } from '../../utils/AuthHelper';

type Code = 403 | 404 | 500 | 503;

interface IError {
    code: Code;
    message: string;
}

const errors: IError[] = [
    {
        code: 403,
        message: 'A megadott oldalhoz nincs jogosultsága.',
    },
    {
        code: 404,
        message: 'A megadott oldal nem található.',
    },
    {
        code: 500,
        message: 'Szerverhiba történt.',
    },
    {
        code: 503,
        message: 'Karbantartás',
    },
];

export default function Error({ statusCode }: { statusCode: Code }) {
    const { code, message } = errors.find((e) => e.code === statusCode) as IError;

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center" variant="h1">
                    {code}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" variant="h4">
                    {message}
                </Typography>
            </Grid>
            {getJWTToken() ? (
                <>
                    <Grid item xs={12}>
                        <Link to="/">
                            <SubmitButton fullWidth size="large">
                                Vissza a főoldalra
                            </SubmitButton>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <a href={process.env.REACT_APP_AUTH_URL + '/logout'} style={{ textDecoration: 'none' }}>
                            <SubmitButton fullWidth size="large">
                                Kijelentkezés
                            </SubmitButton>
                        </a>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <a href={process.env.REACT_APP_AUTH_URL + '/login'} style={{ textDecoration: 'none' }}>
                        <SubmitButton fullWidth size="large">
                            Bejelentkezés
                        </SubmitButton>
                    </a>
                </Grid>
            )}
        </Grid>
    );
}
