import Tooltip from '@mui/material/Tooltip';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Grid from '@mui/material/Grid';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Typography from '@mui/material/Typography';
import Edit from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import EditDialog from './EditDialog';
import { FormProps } from '../../components/Form/Form';

type InfoIconProps<T extends Record<string, unknown>, D extends object> = {
    Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
        muiName: string;
    };
    label: string;
    value: string;
    custom?: JSX.Element;
} & ({ editable?: boolean; formProps: FormProps<T, D> } | { editable?: never | false; formProps?: never });

const InfoIcon = <T extends Record<string, unknown>, D extends object>({
    value,
    label,
    Icon,
    editable,
    formProps,
    custom,
}: InfoIconProps<T, D>) => {
    const [open, setOpen] = useState(false);

    return (
        <Grid item>
            <Typography color="secondary" fontSize="12px" fontWeight="bold" marginLeft={3}>
                {label}{' '}
                {custom || (
                    <>
                        <IconButton
                            size="small"
                            onClick={() => setOpen(true)}
                            style={editable ? {} : { visibility: 'hidden' }}
                        >
                            <Edit />
                        </IconButton>
                        {editable ? <EditDialog openState={[open, setOpen]} {...formProps} /> : null}
                    </>
                )}
            </Typography>
            <Tooltip title={label}>
                <Typography color="secondary" display="flex">
                    <Icon style={{ marginRight: 3 }} /> {value || <i>Nincs adat</i>}
                </Typography>
            </Tooltip>
        </Grid>
    );
};

export default InfoIcon;
