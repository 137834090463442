import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';
import { TableCell, TableRow } from './Edit';

export const EditTable = () => {
    const { data, loading } = useFetch(endpoints.administration.notifications.codeTable, '') as {
        loading: boolean;
        data: Record<string, string>;
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Kulcs</TableCell>
                        <TableCell>Magyarázat</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                <i>Betöltés...</i>
                            </TableCell>
                        </TableRow>
                    ) : (
                        Object.entries(data).map(([key, value]) => (
                            <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{value}</TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
