import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useDispatch } from 'react-redux';
import SemesterContext from '../../../../components/SemesterSelector/SemesterContextProvider';
import UploadButton from '../../../../components/UploadButton';
import fileManagementServices from '../../../../services/masterData/fileManagements';
import questionnaireProgramService from '../../../../services/selfEvalution/questionnaireProgram';
import { create } from '../../../../store/notification';
import { theme } from '../../../../utils/theme';

interface FileUploadDialogProps {
    setRefresh: Dispatch<SetStateAction<boolean>>;
    filename: string | undefined;
}

export default function FileUploadDialog({ setRefresh, filename }: FileUploadDialogProps) {
    //   const { uploadFunction, ...other } = props;
    const [file, setFile] = React.useState<File | null>(null);
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [deleted, setDeleted] = React.useState(false);
    const [uuidFileName, setUuidFileName] = React.useState('');
    const [origFileName, setOrigFileName] = React.useState('');
    const semester = useContext(SemesterContext);
    const dispatch = useDispatch();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const onClose = React.useCallback(() => {
        setOpen(false);
        setFile(null);
        setError(null);
        if (!matches) window.location.reload();
    }, [matches]);

    const handleFileChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setLoading(true);
            const fileTmp = event.target.files?.[0];
            if (!fileTmp) {
                setError('A fájl nem található');
                setLoading(false);
                return;
            }

            const formData = new FormData();
            formData.append('file', fileTmp as unknown as Blob);
            /**
             * TODO: ezt kivenni, ha lesz másik is
             */
            formData.append('type', 'university-index');

            fileManagementServices
                .fileUpload(formData as unknown as { file: File; type: string })
                .then((res) => {
                    setUuidFileName(res.data.data.uuidFileName);
                    setOrigFileName(res.data.data.origFileName);
                    if (fileTmp) {
                        setFile(fileTmp);
                    }
                    setError(null);
                })
                .catch((error) => setError(error.response?.data?.err))
                .finally(() => setLoading(false));
        },
        [setUuidFileName, setOrigFileName],
    );

    const handleUpload = React.useCallback(() => {
        if (file) {
            setLoading(true);
            questionnaireProgramService
                .uploadUniversityIndex(semester, { uuidFileName, origFileName })
                .then(() => {
                    setLoading(false);
                    setRefresh((prev) => !prev);
                    dispatch(create({ message: 'Sikeres fájl feltöltés!', type: 'success' }));
                })
                .catch((error) => {
                    setLoading(false);
                    setError(error.response?.data?.violations?.[0]?.message || error.message);
                    dispatch(create({ message: 'Sikertelen fájl feltöltés!', type: 'error' }));
                })
                .finally(() => onClose());
        } else if (deleted) {
            setLoading(true);
            questionnaireProgramService
                .removeUniversityIndex(semester)
                .then(() => {
                    setLoading(false);
                    setRefresh((prev) => !prev);
                    dispatch(create({ message: 'Sikeres törlés!', type: 'success' }));
                })
                .catch((error) => {
                    setLoading(false);
                    setError(error.response?.data?.violations?.[0]?.message || error.message);
                    dispatch(create({ message: 'Sikertelen törlés!', type: 'error' }));
                })
                .finally(() => onClose());
        }
    }, [file, deleted, onClose, semester, uuidFileName, origFileName, setRefresh, dispatch]);

    const handleClick = React.useCallback(() => {
        setDeleted(false);
        setOpen(true);
        const fname = filename;
        if (fname) {
            setOrigFileName(fname);
            setFile(new File([], fname));
        }
    }, [filename]);
    const handleDelete = React.useCallback(() => {
        setDeleted(true);
        setFile(null);
    }, []);

    return (
        <Grid item>
            <Tooltip title="Dokumentum feltöltése">
                <IconButton
                    color="primary"
                    sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                    size="small"
                    onClick={handleClick}
                    disabled={loading}
                >
                    <UploadIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={onClose} /* {...other} */>
                <DialogTitle>Új fájl feltöltése</DialogTitle>
                <DialogContent style={{ marginTop: '16px' }}>
                    <DialogContentText>
                        A fájl feltöltéséhez válaszd ki a feltöltendő fájlt, majd kattints a Feltöltés gombra.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                value={file?.name || ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <InsertDriveFileIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <UploadButton handleFileChange={handleFileChange} loading={loading} /* file={file} */ />
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<DeleteIcon />}
                                    disabled={loading || !file}
                                    onClick={handleDelete}
                                    color="error"
                                >
                                    Törlés
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {error && (
                        <Grid item xs={12} style={{ marginTop: '8px' }}>
                            <Alert severity="error">{error}</Alert>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} variant="outlined">
                        Mégse
                    </Button>
                    <LoadingButton
                        onClick={handleUpload}
                        disabled={!file && !deleted}
                        loading={loading}
                        variant="contained"
                    >
                        Mentés
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
