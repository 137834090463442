import { Tooltip } from '@silinfo/front-end-template';
import { makeStyles } from '@mui/styles';
import { onlySpaces } from './AppConst';
import { Address } from './Interfaces/interfaces';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles(() => ({
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

type TooltipCellRendererProps = {
    params: GridRenderCellParams;
};

export function TooltipCellRenderer({ params }: TooltipCellRendererProps) {
    const classes = useStyles();
    const value = params.value;
    return (
        <Tooltip title={value}>
            <div className={classes.truncate}>{value}</div>
        </Tooltip>
    );
}

export function TooltipRenderer(param: string | number) {
    const classes = useStyles();

    //itt ellenőrzünk 0-ra, hiszen az ag-grid nem jeleníti meg, hanem null-nak veszi
    const value = param === 0 ? '0' : param;

    return (
        <Tooltip title={value}>
            <div className={classes.truncate}>{value ? value : '-'}</div>
        </Tooltip>
    );
}

export function EnrolledCountCellRenderer(headCount: number, maxHeadCount: number, waitingListCount: number) {
    const classes = useStyles();

    return (
        <Tooltip
            title={
                <div>
                    Jelentkezett hallgatók: {headCount} fő
                    <br />
                    Férőhelyek: {maxHeadCount} fő
                    <br />
                    Várólistára jelentkezve: {waitingListCount} fő
                </div>
            }
        >
            <div className={classes.truncate}>
                {waitingListCount === 0
                    ? headCount + '/' + maxHeadCount
                    : headCount + '/' + maxHeadCount + ' (' + waitingListCount + ')'}
            </div>
        </Tooltip>
    );
}

export function AddressCellRenderer(params: GridRenderCellParams) {
    const address = params.row?.address;

    let addressString = '-';

    if (address && address.length !== 0) {
        addressString = `${address.postCode || ''} ${address.city || ''}`;

        if (address.streetName) {
            addressString += `, ${address.streetName || ''} ${address.streetType || ''} ${address.houseNumber || ''}`;
        }

        if (
            address.floor !== null &&
            address.floor !== undefined &&
            address.door !== null &&
            address.door !== undefined
        ) {
            addressString = addressString + ` ${address.floor}/${address.door}. ajtó`;
        }
        if (
            (address.floor === null || address.floor === undefined) &&
            address.door !== null &&
            address.door !== undefined
        ) {
            addressString = addressString + ` ${address.door || ''}. ajtó`;
        }
    }

    return TooltipRenderer(addressString);
}

export const addressToString = (address: Address) =>
    `${address.postCode ? address.postCode + ' ' : ''}${address.city ? address.city + ', ' : ''}${
        address.streetName ? address.streetName + ' ' : ''
    }${address.streetType ? address.streetType + ' ' : ''}${address.houseNumber ? address.houseNumber + ' ' : ''}${
        address.addressFloor ? address.addressFloor + ' ' : ''
    }${address.door ? address.door : ''}`;

export function ActiveCellRenderer(params: GridRenderCellParams) {
    const result = params.row.active ? 'Aktív' : 'Inaktív';
    return TooltipRenderer(result);
}

export function SemesterActiveCellRenderer(active: boolean, period: string) {
    const activeText = active ? 'Aktív' : 'Inaktív';
    const periodText = onlySpaces(period) ? 'Nincs megadva' : period;
    return DifferentCellDifferentTooltipChipCellRenderer(activeText, periodText, active);
}

/**
 * Szemeszterek listaoldalon használjuk ezt a függvényt
 */
export function DifferentCellDifferentTooltipChipCellRenderer(
    cell: number | string,
    toolTip: number | string,
    active: boolean,
) {
    const classes = useStyles();

    //itt ellenőrzünk 0-ra, hiszen az ag-grid nem jeleníti meg, hanem null-nak veszi
    const toolTipValue = toolTip === 0 ? '0' : toolTip;
    const label = cell === 0 ? '0' : cell;
    const color = 'success';

    return (
        <Tooltip title={toolTipValue}>
            {active ? (
                <Chip className={classes.truncate} {...{ label, color }} />
            ) : (
                <Chip className={classes.truncate} label={label} />
            )}
        </Tooltip>
    );
}

export function DifferentCellDifferentTooltipCellRenderer(cell: number | string, toolTip: number | string) {
    const classes = useStyles();

    //itt ellenőrzünk 0-ra, hiszen az ag-grid nem jeleníti meg, hanem null-nak veszi
    const toolTipValue = toolTip === 0 ? '0' : toolTip;
    const label = cell === 0 ? '0' : cell;

    return (
        <Tooltip title={toolTipValue}>
            <div className={classes.truncate}>{label}</div>
        </Tooltip>
    );
}
