import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CancelButton, SubmitButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import questionnaireProgramService from '../../../../services/selfEvalution/questionnaireProgram';
import { create } from '../../../../store/notification';
import { QuestionnaireProgram, TYPE_QUESTIONNAIRE } from '../utils';
import Tooltip from '@mui/material/Tooltip';

export type OperationsCellRendererProps = {
    setRefresh: Dispatch<SetStateAction<boolean>>;
    params: GridRenderCellParams<unknown, QuestionnaireProgram>;
    type: string;
};

const ActivationSwitch = (props: OperationsCellRendererProps) => {
    const { setRefresh, params, type } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const mainLabel = type === TYPE_QUESTIONNAIRE ? 'Kérdőív' : 'Összefoglaló dokumentum';
    const [checked, setChecked] = useState(type === TYPE_QUESTIONNAIRE ? params.row.active : params.row.activeSummary);
    const [checkedConditional, setCheckedConditional] = useState(
        type === TYPE_QUESTIONNAIRE ? params.row.active : params.row.activeSummary,
    );
    const disabled =
        type === TYPE_QUESTIONNAIRE
            ? !params.row.questionnaireTemplate && !params.row.questionnaireTemplateGroup && !params.row.active
            : !params.row.questionnaireTemplateSummary && !params.row.activeSummary;

    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const handleChange = useCallback(
        (_: unknown, checked: boolean) => {
            setCheckedConditional(checked);
            handleOpen();
        },
        [handleOpen],
    );

    let dialogContent = '';

    if (checkedConditional) {
        if (type === TYPE_QUESTIONNAIRE) {
            dialogContent =
                'A kérdőív aktiválásával az önértékelés és célkitűzés elérhetővé válik minden érintett hallgatónak. Biztosan aktiválod a kérdőívet?';
        } else {
            dialogContent =
                'A kérdőív aktiválásával az összefoglaló dokumentum elérhetővé válik minden érintett hallgatónak. Biztosan aktiválod a dokumentumot?';
        }
    } else {
        if (type === TYPE_QUESTIONNAIRE) {
            dialogContent =
                'A kérdőív deaktiválásával az önértékelés és célkitűzés a továbbiakban nem lesz elérhető a hallgatónak, valamint ha a kérdőívsablon kicserélésre kerül, az eddigi összes kitöltés törlésre kerül. Biztosan inaktívvá akarod tenni a kérdőívet?';
        } else {
            dialogContent =
                'A deaktiválással az összefoglaló dokumentum a továbbiakban nem lesz elérhető a hallgatónak, valamint ha a kapcsolódó sablon kicserélésre kerül, az eddigi összes kitöltés törlésre kerül. Biztosan inaktívvá akarod tenni?';
        }
    }

    const handleSubmit = useCallback(() => {
        (type === TYPE_QUESTIONNAIRE
            ? questionnaireProgramService.updateStatus(params.row.id as unknown as string, {
                  active: checkedConditional ? '1' : '0',
              })
            : questionnaireProgramService.updateStatusSummary(params.row.id as unknown as string, {
                  active: checkedConditional ? '1' : '0',
              })
        )
            .then(() => {
                dispatch(
                    create({
                        type: 'success',
                        message: `${mainLabel} sikeresen ${checkedConditional ? '' : 'de'}aktiválva`,
                    }),
                );
                setRefresh((refresh) => !refresh);
                handleClose();
                setChecked(checkedConditional);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen művelet.' })));
    }, [checkedConditional, dispatch, handleClose, params.row.id, setRefresh, mainLabel, type]);

    return (
        <>
            <Tooltip title={'Aktiválás'}>
                <Switch checked={checked} onChange={handleChange} disabled={disabled} size="small" />
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <Grid item container>
                    <Grid item xs={12}>
                        <DialogTitle>
                            {checkedConditional ? mainLabel + ' aktiválása' : mainLabel + ' deaktiválása'}
                        </DialogTitle>
                    </Grid>
                    <Grid item container xs={12}>
                        <DialogContent>{dialogContent}</DialogContent>
                    </Grid>
                    <Grid item container spacing={1} xs={12} justifyContent={'flex-end'}>
                        <DialogActions>
                            <SubmitButton onClick={handleSubmit}>Igen</SubmitButton>
                            <CancelButton onClick={handleClose}>Nem</CancelButton>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export default ActivationSwitch;
