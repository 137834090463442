import { IViolations, Select } from '@silinfo/front-end-template';
import { IRelevance } from './types';

const options = [
    { value: 'true', label: 'Igen' },
    { value: 'false', label: 'Nem' },
];

export interface RelevantEditableCellProps {
    errors?: IViolations[];
    relevances: IRelevance[];
    handleChange: (id: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    studentId: number;
}

export default function RelevantEditableCell({
    errors,
    relevances,
    handleChange,
    studentId,
}: RelevantEditableCellProps) {
    const errorIds = errors?.map((element: IViolations) => {
        return Number(element.propertyPath.split('.')[1]);
    });
    return (
        <Select
            options={options}
            defaultValue={relevances.find((elem) => elem.id === studentId)?.new ? 'true' : 'false'}
            onBlur={handleChange('' + studentId)}
            error={errorIds?.includes(studentId)}
        />
    );
}
