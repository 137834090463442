import StarIcon from '@mui/icons-material/Star';
import GroupsIcon from '@mui/icons-material/Groups';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

const icons: Record<string, JSX.Element> = {
    role: <GroupsIcon />,
    setting: <StarIcon />,
};

export default function SimpleBadge({ type, tooltip }: { type: string; tooltip: string }) {
    return (
        <Grid item>
            <Tooltip title={tooltip}>
                <Chip color="secondary" label={icons[type] || <QuestionMark />} />
            </Tooltip>
        </Grid>
    );
}
