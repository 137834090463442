import { clientEndPoints } from '../../../utils/clientEndPoints';
import { categoryColumn } from '../FinancialApprovals/utils';
import { StatusRenderer } from './StatusRenderer';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

export const SESSION_KEY = 'administrationFee';

export const header = (tenant: string | null) => {
    return {
        project: tenant || 'TAR',
        title: 'Adminisztrációs díjak',
        breadcrumbs: {
            finance: 'Pénzügyek',
            [clientEndPoints.finance_administration_fees_list]: 'Adminisztrációs díjak',
        },
    };
};

export type AdministrationFeesView = 'base' | 'guest';

export interface TabContentProps {
    view: AdministrationFeesView;
}

export const columns = (view: AdministrationFeesView): GridColDef[] => [
    categoryColumn,
    { headerName: 'Azonosító', field: 'identifier', maxWidth: 130 },
    { headerName: 'Hallgató', field: 'student' },
    ...(view === 'guest' ? [{ headerName: 'Tenant', field: 'tenant', maxWidth: 130 }] : []),
    { headerName: 'Adóazonosító jel', field: 'taxNumber', maxWidth: 130 },
    { headerName: 'Lakcím', field: 'address' },
    { headerName: 'Bankszámlaszám', field: 'bankNumber' },
    { headerName: 'Díj típusa', field: 'feeName' },
    { headerName: 'Összeg', field: 'amount', maxWidth: 75 },
    { headerName: 'Szemeszter', field: 'semester' },
    { headerName: 'Létrehozás dátuma', field: 'createdAt' },
    {
        headerName: 'Státusz',
        field: 'status',
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => StatusRenderer(params.row.status),
    },
];
