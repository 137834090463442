import Badge from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import House from '@mui/icons-material/House';
import Phone from '@mui/icons-material/Phone';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ChangeEventHandler, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { addressToString } from '../../../utils/AppConstElements';
import InfoIcon from '../InfoIcon';
import Email from '../Tabs/Email';
import { ProfilePictureComponent } from './ProfilePictureComponent';
import { ProfileTabs } from './ProfileTabs';
import { ProfileHeaderProps } from './types';
import { AVATAR_SIZE, COVER_IMAGE, editFormProps, useStyles } from './utils';

export default function ProfileHeader({ value, setValue }: ProfileHeaderProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    const { profile } = useSelector((state: RootState) => state.studentProfile);
    const coverPictureRef = useRef<HTMLInputElement>(null);
    const [coverPicture, setCoverPicture] = useState(COVER_IMAGE);
    const { phone, address } = editFormProps(profile);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleCoverPictureChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!e.target?.files?.[0]) return;
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setCoverPicture(reader.result as string);
        });
        reader.readAsDataURL(e.target.files[0]);
    };

    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Card>
                <div
                    style={{
                        background: `url(${coverPicture})`,
                        width: '100%',
                        height: '140px',
                        backgroundSize: 'cover',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: '1000',
                            backgroundColor: '#364354',
                        }}
                        className={classes.cover}
                        // TODO: Ha mentjük a borítóképet is, ezt vissza kell állítani
                        // onClick={() => coverPictureRef?.current && coverPictureRef.current.click()}
                    ></div>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <input
                            type="file"
                            id="file"
                            ref={coverPictureRef}
                            onChange={handleCoverPictureChange}
                            style={{ display: 'none' }}
                            accept="image/*"
                        />
                        <ProfilePictureComponent />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 25 + AVATAR_SIZE,
                                marginBottom: 0,
                                zIndex: 1100,
                            }}
                        >
                            <Typography variant="h5" style={{ color: 'white' }}>
                                {user.lastName} {user.firstName}
                            </Typography>
                        </div>
                    </div>
                </div>
                <CardContent style={{ marginTop: AVATAR_SIZE / 2 }} className="studentProfile">
                    <Grid container spacing={2} justifyContent="space-between">
                        <InfoIcon Icon={EmailIcon} label="E-mail cím" value={profile.email} custom={<Email />} />
                        <InfoIcon
                            Icon={Phone}
                            label="Telefonszám"
                            value={profile.phone}
                            editable={!user.archive}
                            formProps={phone}
                        />
                        <InfoIcon Icon={Badge} label="MCC azonosító" value={profile.mccId} />
                        <InfoIcon
                            Icon={House}
                            label="Lakcím"
                            value={addressToString(profile)}
                            editable={!user.archive}
                            formProps={address}
                        />
                        <ProfileTabs value={value} handleChange={handleChange} />
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}
