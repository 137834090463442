import { AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import courseBaseDataService from '../../../../services/courseManagement/courseBaseData';
import { RootState } from '../../../../store';
import { Option } from '../../../../utils/AppConst';
import { onlyCourseManagerRole, onlyTeacherRole } from '../../../../utils/AuthHelper';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import ROLES from '../../../../utils/Roles';
import BaseDataForm from './BaseDataForm';
import { TBaseData } from './types';
import { baseInitialValues } from './utils';
import BaseDataFormTenant from './BaseDataFormTenant';

export default function BaseData() {
    const [searchParams] = useSearchParams();
    const id = useParams().courseId;
    const courseTenant = searchParams.get('courseTenant');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(baseInitialValues);
    const [instructorsIsDisabled, setInstructorsIsDisabled] = useState<boolean>(false);
    const [courseManagerIsDisabled, setCourseManagerIsDisabled] = useState<boolean>(false);
    const { user } = useSelector((state: RootState) => state.auth);
    const [versionNames, setVersionNames] = useState([]); // Amennyiben a verzió nem 01, úgy a hozzá tartozó neveket tartalmazza

    const copyFromBaseData = async (copyId: string | null) => {
        const { data } = await courseBaseDataService.copyFrom(+(copyId as string));
        data.data.closed = false;
        data.data.readonly = false;
        return data;
    };

    const courseManagerInstructorsChecker = useCallback(() => {
        if (!id) {
            if (
                (user.roles.indexOf(ROLES.TEACHER) !== -1 && user.roles.indexOf(ROLES.COURSE_MANAGER) !== -1) ||
                onlyCourseManagerRole(user)
            ) {
                setInitialValues((prev) => {
                    return {
                        ...prev,
                        courseManager: {
                            value: user.id.toString(),
                            label: `${user.lastName} ${user.firstName} / ${user.email}`,
                        },
                    };
                });
                setCourseManagerIsDisabled(
                    (prev) =>
                        (user.roles.indexOf(ROLES.TEACHER) !== -1 && user.roles.indexOf(ROLES.COURSE_MANAGER) !== -1) ||
                        !onlyCourseManagerRole(user) ||
                        prev,
                );
            } else if (onlyTeacherRole(user)) {
                setInitialValues((prev) => {
                    return {
                        ...prev,
                        instructors: [
                            { value: user.id.toString(), label: `${user.lastName} ${user.firstName} / ${user.email}` },
                        ],
                    };
                });
                setInstructorsIsDisabled(true);
            }
        } else {
            if (
                (user.roles.indexOf(ROLES.TEACHER) !== -1 && user.roles.indexOf(ROLES.COURSE_MANAGER) !== -1) ||
                onlyCourseManagerRole(user)
            ) {
                setCourseManagerIsDisabled(true);
            } else if (onlyTeacherRole(user)) {
                setInstructorsIsDisabled(true);
            }
        }
    }, [id, user]);

    useEffect(() => {
        if (searchParams.get('copyfrom')) {
            setLoading(true);
            copyFromBaseData(searchParams.get('copyfrom')).then((response) => {
                const params = {
                    semester: +response.data.semester?.value,
                    courseType: response.data.courseType,
                    courseCode: response.data.courseCode?.split('-')[1],
                };

                if (!(params.semester && params.courseCode && params.courseType)) {
                    return;
                }

                courseBaseDataService
                    .getCourseCodeVersion(params)
                    .then((res) => {
                        const post = res.data.version.toString().padStart(2, '0');
                        setVersionNames(res.data?.names);
                        setInitialValues((prev) => {
                            return {
                                ...prev,
                                ...response.data,
                                semester: +response.data.semester?.value || '',
                                organiser: (response.data.organiser || []).map((elem: Option | string | number) =>
                                    typeof elem === 'string' || typeof elem === 'number' ? elem : elem.value,
                                ),
                                organiserCampus: +response.data.organiserCampus?.value || '',
                                campus: response.data.campus?.value || '',
                                building: response.data.building?.value || '',
                                floor: response.data.floor?.value || '',
                                room: response.data.room?.value || '',
                                isExternalLocation: !!response.data.externalLocationName,
                                achievementType: response.data.achievementType.value,
                                courseCode: response.data.courseCode.slice(0, -2) + post,
                                courseCodePost: post,
                            };
                        });

                        courseManagerInstructorsChecker();
                    })
                    .finally(() => setLoading(false));
            });
        } else {
            courseManagerInstructorsChecker();
            setLoading(false);
        }
    }, [courseManagerInstructorsChecker, searchParams]);

    if (!user) {
        console.error('Missing logged in user should never happen');
        navigate('/403');
        return <></>;
    }

    const onSubmit = async (form: IForm, helpers: FormikHelpers<TBaseData>) => {
        const copyfrom = searchParams.get('copyfrom');
        return await (!id
            ? copyfrom !== null
                ? courseBaseDataService.copy(+copyfrom, form)
                : courseBaseDataService.create(form)
            : courseBaseDataService.update(+id, form)
        ).then((response: AxiosResponse<{ data: { id: number; courseCode: string } }>) => {
            const redirect = form.redirect;
            if (redirect === 'edit') {
                if (!id) {
                    const elemId = response.data.data.id;
                    navigate(clientEndPoints.course_manamagement_course_edit.replace(':courseId', elemId.toString()));
                    helpers.setFieldValue('courseCode', response.data.data.courseCode);
                }
            } else {
                navigate(redirect as string);
            }
        });
    };

    const submitTransformData = (form: TBaseData) => {
        return Object.entries(form).reduce(
            (prev, [key, value]: [string, unknown]) => ({
                ...prev,
                [key]: Array.isArray(value)
                    ? value.map((elem) => elem?.value || elem)
                    : (value as Option)?.value || value,
                ...(searchParams.get('copyfrom') && {
                    copyCourse: searchParams.get('copyfrom'),
                    courseCode: form?.courseCode?.split('-')[1],
                }),
            }),
            {},
        );
    };

    const getBaseData = async () => {
        const { data } = await courseBaseDataService.get(+(id as string), courseTenant);
        if (courseTenant) {
            return data;
        }
        return {
            ...data,
            data: {
                ...data.data,
                semester: +data.data.semester?.value || '',
                campus: +data.data.campus?.value || '',
                organiser: (data.data.organiser || []).map((elem: Option | number | string) =>
                    typeof elem === 'string' || typeof elem === 'number' ? elem : elem.value,
                ),
                organiserCampus: +data.data.organiserCampus?.value,
                building: data.data.building?.value || '',
                floor: data.data.floor?.value || '',
                room: data.data.room?.value || '',
                isExternalLocation: !!data.data.externalLocationName,
                achievementType: data.data.achievementType.value,
                readonly: data.data.readonly ? true : searchParams.get('copyfrom') !== null ? false : data.data.closed, //bencével ezt beszéltük meg
            },
        };
    };

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Form
            hideButtons
            fields={[]}
            onSubmit={onSubmit}
            initialValues={initialValues}
            submitTransformData={submitTransformData}
            getterFunction={getBaseData}
            urlParam="courseId"
        >
            {(props) => {
                if (props.values.isTenant) {
                    return <BaseDataFormTenant {...props} />;
                } else {
                    return (
                        <BaseDataForm
                            {...props}
                            versionNames={versionNames}
                            setVersionNames={setVersionNames}
                            instructorsIsDisabled={instructorsIsDisabled}
                            courseManagerIsDisabled={courseManagerIsDisabled}
                        />
                    );
                }
            }}
        </Form>
    );
}
