import { Input } from '@silinfo/front-end-template';
import axios from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import usersService from '../../../../services/masterData/users';
import { Option } from '../../../../utils/AppConst';
import ROLES from '../../../../utils/Roles';

export default function useUserFields(refresh: boolean): {
    loading: boolean;
    fields: Input[];
    rolesOptions: Option[];
    setLoading: Dispatch<SetStateAction<boolean>>;
} {
    const [rolesOptions, setRolesOptions] = useState<Option[]>([]);
    const [campusOptions, setCampusOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState(true);
    const rolesOptionsPrint = rolesOptions.filter(function (e) {
        return e.value !== ROLES.STUDENT;
    });

    useEffect(() => {
        setLoading(true);
        axios
            .all([usersService.roles(), usersService.campuses()])
            .then((res) => {
                setRolesOptions(res[0].data);
                setCampusOptions(res[1].data);
            })
            .finally(() => setLoading(false));
    }, [refresh]);

    const disabledOptions = [
        {
            value: '1',
            label: 'igen',
        },
        {
            value: '0',
            label: 'nem',
        },
    ];
    return {
        setLoading,
        rolesOptions: rolesOptions,
        loading,
        fields: [
            { label: 'E-mail cím', name: 'email', format: { xs: 12, md: 4, lg: 4 } },
            { label: 'Vezetéknév', name: 'lastName', format: { xs: 12, md: 4, lg: 4 } },
            { label: 'Keresztnév', name: 'firstName', format: { xs: 12, md: 4, lg: 4 } },
            {
                label: 'Campus',
                name: 'campus',
                type: 'multiselect',
                format: { xs: 12, md: 4, lg: 4 },
                options: campusOptions,
            },
            { label: 'MCC azonosító', name: 'mccId', format: { xs: 12, md: 4, lg: 4 } },
            {
                label: 'Jogosultság',
                name: 'roles',
                type: 'multiselect',
                format: { xs: 12, md: 4, lg: 4 },
                options: rolesOptionsPrint,
            },
            {
                name: 'disabled',
                label: 'Letiltva',
                type: 'select',
                options: disabledOptions,
                format: { xs: 12, md: 4, lg: 4 },
            },
            {
                name: 'activated',
                label: 'Aktiválva',
                type: 'select',
                options: disabledOptions,
                format: { xs: 12, md: 4, lg: 4 },
            },
            {
                type: 'date',
                label: 'Utolsó belépés (-tól)',
                name: 'lastLoggedInAtFrom',
                format: { xs: 12, md: 4, lg: 4 },
            },
            {
                type: 'date',
                label: 'Utolsó belépés (-ig)',
                name: 'lastLoggedInAtUntil',
                format: { xs: 12, md: 4, lg: 4 },
            },
        ],
    };
}
