export interface IHoliday {
    start: string;
    end: string;
}
export interface ISemesterInfo {
    start: string;
    end: string;
    holidays: IHoliday[];
}

export const initialSemesterInfo: ISemesterInfo = {
    start: '',
    end: '',
    holidays: [],
};
