import { TableButton } from '@silinfo/front-end-template';

import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ArchiveIcon from '@mui/icons-material/Archive';
import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import usersService from '../../services/masterData/users';
import { create } from '../../store/notification';

export interface TableButtonArchiveProps {
    id: number | string;
    archive: boolean;
    setLoading?: Dispatch<SetStateAction<boolean>>;
    setRefresh?: Dispatch<SetStateAction<boolean>>;
}
export default function TableButtonArchive(props: TableButtonArchiveProps) {
    const { id, archive, setLoading, setRefresh } = props;
    const dispatch = useDispatch();
    return (
        <TableButton
            color={archive ? 'success' : 'error'}
            tooltip={archive ? 'Archiválás visszavonása' : 'Archiválás'}
            onClick={() => {
                setLoading?.(true);
                usersService
                    .archive(id)
                    .then(() => dispatch(create({ type: 'success', message: 'Sikeres archiválás.' })))
                    .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen archiválás.' })))
                    .finally(() => {
                        setRefresh?.(true);
                        setLoading?.(false);
                    });
            }}
        >
            {archive ? <UnarchiveIcon color={'success'} /> : <ArchiveIcon color={'error'} />}
        </TableButton>
    );
}
