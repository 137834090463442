import Grid from '@mui/material/Grid';
import { Checkbox } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { TBaseData } from './types';

export default function Waitlist() {
    const { user } = useSelector((state: RootState) => state.auth);
    const formikProps = useFormikContext<TBaseData>();
    const { values, setFieldValue } = formikProps;
    const disabled = user.archive || values.readonly;
    // const { courseId } = useParams();
    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item>
                <Checkbox
                    label="Várólista engedélyezése"
                    checkbox={{
                        disabled,
                        checked: values.waitlist,
                        name: 'waitlist',
                        onChange: (_: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('waitlist', !values.waitlist);
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
}
