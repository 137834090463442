import { endpoints } from '../../../../utils/endPoints';
import instance from '../../../../api';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { fixDateRangeFields } from '../../../../utils/AppConst';

const financeFinancialApprovalsService = {
    filter: (form: IForm) =>
        instance.get(endpoints.finance.financialApprovals.list, { params: fixDateRangeFields(form) }),
    filterGuest: (form: IForm) =>
        instance.get(endpoints.finance.financialApprovals.listGuest, { params: fixDateRangeFields(form) }),
    semesters: () => instance.get(endpoints.finance.financialApprovals.semesters),
    update: (id: number, form: IForm) =>
        instance.post(endpoints.finance.financialApprovals.update.replace('{id}', id.toString()), form),
    updateMany: (form: IForm) => instance.post(endpoints.finance.financialApprovals.updateMany, form),

    /**
     * Export
     *
     */
    initiateExport: (form: { format: string }, filter: IForm) =>
        instance.post(endpoints.finance.financialApprovals.initiateExport, form, { params: filter }),
    checkExport: (exportId: number) =>
        instance.get(endpoints.finance.financialApprovals.checkExport.replace('{id}', `${exportId}`)),

    downloadExport: (exportId: number) =>
        instance.get(endpoints.finance.financialApprovals.downloadExport.replace('{id}', `${exportId}`), {
            responseType: 'arraybuffer',
        }),
    initiateExportGuest: (form: { format: string }, filter: IForm) =>
        instance.post(endpoints.finance.financialApprovals.initiateExportGuest, form, { params: filter }),
    checkExportGuest: (exportId: number) =>
        instance.get(endpoints.finance.financialApprovals.checkExportGuest.replace('{id}', `${exportId}`)),

    downloadExportGuest: (exportId: number) =>
        instance.get(endpoints.finance.financialApprovals.downloadExportGuest.replace('{id}', `${exportId}`), {
            responseType: 'arraybuffer',
        }),
    downloadInvoice: (invoiceFile: string) =>
        instance.get(endpoints.finance.financialApprovals.downloadInvoice, {
            params: { 'invoiceFile': invoiceFile },
            responseType: 'arraybuffer',
        }),
    downloadInvoiceStudent: (invoiceFile: string) =>
        instance.get(endpoints.finance.financialApprovals.downloadInvoiceStudent, {
            params: { 'invoiceFile': invoiceFile },
            responseType: 'arraybuffer',
        }),
    refreshCharmData: () => instance.post(endpoints.finance.financialApprovals.refreshCharmData),
    checkCharmRequest: (sessionId: number) =>
        instance.get(endpoints.finance.financialApprovals.checkCharmRequest.replace('{id}', `${sessionId}`)),
};

export default financeFinancialApprovalsService;
