import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import { Accordion, CancelButton, Checkbox, FieldGenerator, Page } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import MCCLoading from '../../../components/MCCLoading';
import documentService from '../../../services/administration/document';
import { RootState } from '../../../store';
import { create } from '../../../store/notification';
import BackendSelect from '../../../studentPages/components/BackendSelect';
import { defaultTheme, transformApiErrors } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { endpoints } from '../../../utils/endPoints';
import useOptions from './useOptions';
import { SESSION_KEY } from './utils';
import { useTenant } from '../../../components/TenantContext';

const initialValues = {
    id: '',
    name: '',
    campus: '',
    trainingLevels: [],
    trainingPrograms: [],
    semester: '',
    isEnrollmentDocument: false,
    required: false,
    acceptText: '',
    consentText: '',
    filePath: '',
    fileName: '',
};

export type TDocumentForm = typeof initialValues;

const title = (id: string | undefined, readOnly: boolean) => {
    if (readOnly) {
        return 'Dokumentum megtekintése';
    } else if (id) {
        return 'Dokumentum szerkesztése';
    } else {
        return 'Dokumentum hozzáadása';
    }
};

export default function DocumentForm({ readOnly = false }: { readOnly?: boolean }) {
    const { count } = useSelector((state: RootState) => state.loading);
    const { id } = useParams();
    const navigate = useNavigate();
    const { options, loading } = useOptions();
    const URL = clientEndPoints.admin_documents;
    const [isEnrollmentDocument, setIsEnrollmentDocument] = useState(false);
    const [required, setRequired] = useState(false);
    const dispatch = useDispatch();
    const [formLoading, setFormLoading] = useState(false);
    const [form, setForm] = useState(initialValues);
    const [dataLoading, setDataLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const tenant = useTenant();
    const header = {
        project: tenant || 'TAR',
        title: 'Dokumentumok',
        breadcrumbs: {
            'administration': 'Adminisztráció',
            [clientEndPoints.admin_documents]: 'Dokumentumok',
            [id ? 'documentsEdit' : 'documentsNew']: title(id, readOnly),
        },
    };

    useEffect(() => {
        if (id) {
            setDataLoading(true);
            documentService
                .get(id)
                .then((response) => {
                    /**
                     * Here we need to go through the response.data.data.trainingPrograms and
                     * add the "/training-programs/" to the beginning of the value
                     *
                     
                    if (response.data.data.trainingPrograms) {
                        const trainginProgramsTmp: [] = response.data.data.trainingPrograms as unknown as [];
                        const trainingProgramsTransformed = trainginProgramsTmp.map((trainingProgram: string) => {
                            const transformed = `/training-programs/${trainingProgram}`;
                            return transformed;
                        });
                        response.data.data.trainingPrograms = trainingProgramsTransformed;
                    }
                    */
                    if (response.data.data.trainingPrograms) {
                        const transformedPrograms = response.data.data.trainingPrograms.map(
                            (trainingProgram: { value: number; label: string; level: string }) => {
                                const combined: string = trainingProgram.label + ' / ' + trainingProgram.level;
                                return { value: trainingProgram.value, label: combined };
                            },
                        );
                        response.data.data.trainingPrograms = transformedPrograms;
                    }
                    setForm(response.data.data);

                    setIsEnrollmentDocument(response.data.data.isEnrollmentDocument);
                    setRequired(response.data.data.required);
                })
                .catch(() => dispatch(create({ type: 'error', message: 'Hiba a dokumentum betöltése során!' })))
                .finally(() => setDataLoading(false));
        }
    }, [id, dispatch]);

    const handleSubmit = useCallback(
        (form: TDocumentForm, { setErrors }: FormikHelpers<TDocumentForm>) => {
            setFormLoading(true);
            const tempForm = { ...form };

            /**
             * We need to go through the form.trainingProgram options and trim the value from:
             * "/training-programs/33" to 33
             */
            // if (tempForm.trainingProgram) {
            //      tempForm.trainingProgram = trimTrainingPrograms(tempForm.trainingProgram);
            //      const trainginProgramsTmp: [] = programsToTrim as unknown as [];
            //      const trainingProgramsTrimmed = trainginProgramsTmp.map((trainingProgram: string) => {
            //          const trimmed = trainingProgram.split('/').pop();
            //          return trimmed;
            //      });
            //      tempForm.trainingProgram = trainingProgramsTrimmed;
            // }

            (id ? documentService.update(id, tempForm) : documentService.create(tempForm))
                .then(() => {
                    setFormSubmitted(true);
                    dispatch(create({ type: 'success', message: 'Sikeres mentés!', redirect: URL }));
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 422) {
                        setErrors(transformApiErrors(error.response?.data.violations));
                    }
                    dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
                })
                .finally(() => setFormLoading(false));
        },
        [URL, dispatch, id],
    );

    const handleButtonClick = () => {
        if (formSubmitted) {
            handleBack();
        }
    };

    const handleBack = useCallback(
        () => navigate(sessionStorage.getItem(SESSION_KEY) ? -1 : (URL as unknown as number)),
        [navigate, URL],
    );

    if (loading || dataLoading) return <MCCLoading />;

    const getLevelValues = (trainingLevels: number[] | { value: number; label: string }[]): number[] =>
        trainingLevels.map((trainingLevel) =>
            typeof trainingLevel === 'number' ? trainingLevel : trainingLevel.value,
        );

    return (
        <Page header={header}>
            <Accordion title={title(id, readOnly)} defaultExpanded disableClosing>
                <Form hideButtons fields={[]} onSubmit={handleSubmit} initialValues={form}>
                    {(props) => {
                        return (
                            <>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Checkbox
                                        checkbox={{
                                            checked: isEnrollmentDocument,
                                            onChange: () => {
                                                setIsEnrollmentDocument(!isEnrollmentDocument);
                                                props.setFieldValue(
                                                    'isEnrollmentDocument',
                                                    !isEnrollmentDocument ? true : false,
                                                );
                                            },
                                        }}
                                        label={'Beiratkozási dokumentum'}
                                    />
                                </Grid>
                                {FieldGenerator({
                                    name: 'name',
                                    label: 'Dokumentum neve',
                                    ...props,
                                })}
                                {FieldGenerator({
                                    name: 'campus',
                                    label: 'Campus',
                                    type: 'multiselect',
                                    fieldType: 'base',
                                    options: options.campuses,
                                    ...props,
                                })}
                                {!isEnrollmentDocument ? (
                                    <>
                                        {FieldGenerator({
                                            name: 'trainingLevels',
                                            label: 'Képzési szint',
                                            type: 'multiselect',
                                            fieldType: 'base',
                                            options: options.trainingLevels,
                                            ...props,
                                        })}
                                        <Grid item sm={6} md={4} lg={4}>
                                            <BackendSelect
                                                url={
                                                    endpoints.masterData.trainingPrograms.options +
                                                    (props.values.trainingLevels.length === 0 ? '?empty=1&' : '?') +
                                                    'withLevel=1&trainingLevel=' +
                                                    getLevelValues(props.values.trainingLevels)
                                                }
                                                setter={(programs) => props.setFieldValue('trainingPrograms', programs)}
                                                value={props.values.trainingPrograms}
                                                label="Képzési program"
                                                responseKey=""
                                                multiple
                                                error={!!props.errors?.trainingPrograms}
                                                helperText={props.errors?.trainingPrograms as string}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        {FieldGenerator({
                                            name: 'semester',
                                            label: 'Szemeszter',
                                            type: 'select',
                                            fieldType: 'base',
                                            options: options.semesters,
                                            ...props,
                                        })}
                                        {FieldGenerator({
                                            name: 'acceptText',
                                            label: 'Dokumentum elfogadási szöveg',
                                            format: { xs: 12 },
                                            props: {
                                                multiline: true,
                                                rows: 4,
                                            },
                                            ...props,
                                        })}
                                        <Grid item xs={12} style={{ display: 'flex' }}>
                                            <Checkbox
                                                checkbox={{
                                                    checked: required,
                                                    onChange: () => {
                                                        setRequired(!required);
                                                        props.setFieldValue('required', !required ? true : false);
                                                    },
                                                }}
                                                label={'Hozzájárulási opció hozzáadása a dokumentumhoz'}
                                            />
                                        </Grid>
                                        {required && (
                                            <>
                                                {FieldGenerator({
                                                    name: 'consentText',
                                                    label: 'Dokumentum hozzájárulási szöveg',
                                                    format: { xs: 12 },
                                                    props: {
                                                        multiline: true,
                                                        rows: 4,
                                                    },
                                                    ...props,
                                                })}
                                            </>
                                        )}
                                    </>
                                )}
                                {FieldGenerator({
                                    name: 'document',
                                    type: 'file',
                                    label: 'Dokumentum',
                                    origFileName: 'fileName',
                                    uuidFileName: 'filePath',
                                    tempType: 'document-temp',
                                    format: { xs: 12 },
                                    downloadUrl: `${endpoints.fileManagement.download}?id=${id}&type=document-temp`,
                                    ...props,
                                })}
                                {!props.values.filePath && !props.values.fileName && (
                                    <Grid item xs={12} md={6} lg={4} style={{ marginTop: '8px' }}>
                                        <ThemeProvider theme={defaultTheme}>
                                            <Alert severity="info">Elfogadott fájlformátum: pdf</Alert>
                                        </ThemeProvider>
                                    </Grid>
                                )}
                                <Grid item container spacing={2} justifyContent="flex-end">
                                    <Grid item>
                                        <LoadingButton
                                            loading={count > 0 || formLoading}
                                            variant="contained"
                                            type="submit"
                                            onClick={handleButtonClick}
                                        >
                                            Mentés
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item>
                                        <CancelButton onClick={handleBack}>Vissza</CancelButton>
                                    </Grid>
                                </Grid>
                            </>
                        );
                    }}
                </Form>
            </Accordion>
        </Page>
    );
}
