import { exceptionFields, SectionRow } from '../SectionGenerator';
import useFetch from '../../../../../../utils/useFetch';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { endpoints } from '../../../../../../utils/endPoints';
import { IForm } from '../../../../../../utils/Interfaces/interfaces';

// Nem szép megoldás, de legalább így olyan sorrenben van, amilyenben kell
export const cellOrder = (a: string, b: string) => {
    if (a === 'university' && b === 'major') {
        return -1;
    }

    if (a === 'pedigreeNumber' && b === 'fileName') {
        return -1;
    }

    return 0;
};

export const getFilePath = (row: SectionRow) => {
    const f = Object.entries(row).find(([key]) => key.toLowerCase().includes('filepath'));
    return f && typeof f[1] === 'string' ? f[1]?.split('/')[1] : '';
};

export function newRow(row: SectionRow) {
    const result = Object.entries(row).find(([key]) => exceptionFields.includes(key));

    if (result) {
        return {
            [result[0]]: typeof result[1] === 'string' ? result[1] : result[1]?.id || '',
        };
    }
    return {};
}

export const getFileName = (row: SectionRow) => {
    const f = Object.entries(row).find(([key]) => key.toLowerCase().includes('filename'));
    return f ? f[1] : '';
};

export function GetUniversityOptions() {
    const { data } = useFetch<Option[]>(endpoints.masterData.students.universities, '');
    return data;
}

export const createInitialValues = (id: string | undefined): IForm => {
    return {
        student: id,
        university: 0,
        foreignUniversity: '',
        major: '',
        trainingForm: '',
        financeForm: '',
        semesterStatus: '',
        neptun: '',
        admission: '',
        filePath: '',
        fileName: '',
    };
};
