import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ListPage, setInfo as setInfo2, TextField } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MCCLoading from '../../../../components/MCCLoading';
import charmLogsService from '../../../../services/administration/charmLogs';
import { create } from '../../../../store/notification';
import { paginatorInfoBuild } from '../../../../utils/AppConst';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { IInfo } from '../../../../utils/Interfaces/IInfo';
import { CharmLog } from '../../../../utils/Interfaces/interfaces';
import ActionCellRenderer from '../../LogCellRenderers/ActionCellRenderer';
import ActorCellRenderer from '../../LogCellRenderers/ActorCellRenderer';
import BtnCellRenderer from '../../LogCellRenderers/BtnCellRenderer';
import TypeCellRenderer from '../../LogCellRenderers/TypeCellRenderer';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { columns } from './utils';
import { useTenant } from '../../../../components/TenantContext';

export const useStyles = makeStyles((theme: Theme) => ({
    eyeIcon: {
        color: 'white',
        backgroundColor: '#0288d1',
        '&:hover': {
            backgroundColor: 'white',
            color: '#0288d1',
        },
    },
    title: {
        fontSize: 'large',
        borderBottom: '1px solid ' + theme.palette.secondary.main,
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
    },
    error: {
        color: '#f44336',
        fontSize: '0.75rem',
    },
    tableHeaderCell: {
        fontWeight: 'bold',
    },
}));

interface CharmLogFilter {
    createdAt: string;
    'createdAt[after]': string;
    'createdAt[before]': string;
}

const initialState: IInfo<CharmLogFilter> = {
    filter: {},
    sort: {
        createdAt: 'desc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

export default function CharmLogs() {
    const [data, setData] = useState<CharmLog[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [info, setInfo] = useState(initialState);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const tenant = useTenant();

    const search = useCallback(
        (form: IInfo<CharmLogFilter>) =>
            charmLogsService
                .filter({ ...form.filter, ...paginatorInfoBuild(form) })
                .then((d: AxiosResponse) => {
                    const totalItems = d.data.metadata.allCount;
                    const filteredItems = d.data.metadata?.filteredCount;
                    setData(d.data.data);

                    setInfo((prev) => ({
                        ...prev,
                        metadata: {
                            'allCount': totalItems,
                            'filteredCount': filteredItems,
                            'lastPage': totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                            'page': info.page,
                            'perpage': info.perpage,
                        },
                    }));
                })
                .catch(() =>
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    ),
                )
                .finally(() => {
                    setTableLoading(false);
                    setLoading(false);
                }),
        [dispatch, info.page, info.perpage],
    );

    const fetchData = useCallback(
        (form: IInfo<CharmLogFilter>) => {
            setTableLoading(true);
            search(form);
        },
        [search],
    );

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            fetchData(initialState);
        }
    }, [fetchData, firstLoad]);

    const header = {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_sys_log]: 'Napló',
            [clientEndPoints.admin_charm_logs_list]: 'ChaRM napló',
        },
        title: 'ChaRM napló',
        project: tenant || 'TAR',
    };

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInfo((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [event.target.name]: event.target.value,
            },
        }));
    };

    const propsSearch = {
        info: info,
        setInfo: (name: string, data: IInfo<CharmLogFilter>) =>
            setInfo((prevState) => ({
                ...prevState,
                [name]: data,
            })),
        search: (form: IInfo<CharmLogFilter>) => {
            search(form);
        },
    };

    const resetForm = () => {
        setInfo(initialState);
    };

    const filterParam = {
        form: info.filter,
        setForm: () => {
            resetForm();
            setInfo2(propsSearch, 'filter', {});
        },
        onSubmit: () => {
            setInfo2(propsSearch, 'filter', info.filter);
        },
    };
    if (loading) {
        return <MCCLoading />;
    }

    return (
        <ListPage
            header={header}
            filter={filterParam}
            table={{
                columnDefs: columns,
                columnBuffer: columns.length,
                rowData: data,
                loading: tableLoading,
                searchfunc: fetchData,
                info: info,
                server: true,
                sortingMode: 'server',
                initialState: {
                    sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                },
                setInfo: setValueByKey,
                frameworkComponents: {
                    btnCellRenderer: BtnCellRenderer,
                    actorCellRenderer: ActorCellRenderer,
                    actionCellRenderer: (params: GridRenderCellParams) => (
                        <ActionCellRenderer {...params} page="charmlog" />
                    ),
                    typeCellRenderer: (params: GridRenderCellParams) => (
                        <TypeCellRenderer {...params} page="charmlog" />
                    ),
                },
            }}
        >
            <Grid item container spacing={2} xs={12} wrap={'wrap'} style={{ marginTop: 3 }}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>{'Időpont'}</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Időpont eleje"
                        name="createdAt[after]"
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={info.filter['createdAt[after]'] || ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Időpont vége"
                        name="createdAt[before]"
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={info.filter['createdAt[before]'] || ''}
                    />
                </Grid>
            </Grid>
        </ListPage>
    );
}
