import { Link, TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { useDispatch } from 'react-redux';
import semestersService from '../../../../services/masterData/semesters';
import { create } from '../../../../store/notification';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { BtnCellRendererProps } from '../../../../utils/Interfaces/interfaces';
import Visibility from '@mui/icons-material/Visibility';

export function BtnCellRenderer({ loadTable, ...params }: BtnCellRendererProps & { readonly?: boolean }) {
    const dispatch = useDispatch();

    if (params.readonly || params.row.closed) {
        return (
            <Link to={clientEndPoints.md_general_semesters_show.replace(':semesterId', params.row.id.toString())}>
                <TableButton sx={{ marginRight: '5px' }} tooltip={'Adatok megtekintése'}>
                    <Visibility />
                </TableButton>
            </Link>
        );
    }

    return (
        <>
            <Link to={clientEndPoints.md_general_semesters_edit.replace(':semesterId', params.row.id.toString())}>
                <TableEditButton tooltip={'Szerkesztés'} sx={{ marginRight: '5px' }} />
            </Link>
            {params.row.financeAdministrationValsCount === 0 &&
                params.row.holidaysCount === 0 &&
                params.row.studentProcessesCount === 0 &&
                params.row.coursesCount === 0 && (
                    <TableDeleteButton
                        tooltip={'Törlés'}
                        deleteAction={() => {
                            semestersService
                                .delete(params.row.id)
                                .then(() => dispatch(create({ type: 'success', message: 'Sikeres törlés' })))
                                .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés' })))
                                .finally(loadTable);
                        }}
                        confirmationElement={params.row.name}
                        sx={{ marginRight: '5px' }}
                    />
                )}
        </>
    );
}
