import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IMetadata } from '../../utils/Interfaces/interfaces';
import { IStudentRequestData } from '../../utils/Interfaces/IStudentRequestApproval';
import { Paper } from '../Requirements/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import ChangesView from './ChangesView';

const useStyles = makeStyles((theme: Theme) => ({
    qContainer: {
        display: 'flex',
        border: '1px solid #647C9B',
        margin: theme.spacing(1),
        padding: theme.spacing(1.5),
        boxSizing: 'border-box',
    },
    qBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    name: {
        fontSize: '12px',
    },
    date: {
        fontSize: '11px',
    },
    operations: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
}));

export interface IProcessesRow {
    id: number;
    type: string;
    name: string;
    date: string;
    status: string;
    requestData?: IStudentRequestData;
    externalId?: number;
}

export interface IProcessesTableData {
    data: IProcessesRow[];
    metadata: IMetadata;
}

interface ProcessesTableProps {
    title: string;
    data: IProcessesTableData;
}

export default function ProcessesTable(props: ProcessesTableProps) {
    const { data, title } = props;

    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Paper elevation={3}>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {data.data.map((item: IProcessesRow) => (
                            <div key={item.id} className={classes.qContainer}>
                                <div className={classes.qBox}>
                                    <Typography className={classes.name}>{item.name}</Typography>
                                    <Typography className={classes.date}>{item.date}</Typography>
                                    <Typography className={classes.name}>{item.status}</Typography>
                                </div>
                                <div className={classes.operations}>
                                    <ChangesView row={item} />
                                </div>
                            </div>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
