import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { TabPanel } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Children, useState } from 'react';
import { ICourseView } from '../../../pages/CourseManagement/Courses/types';
import { theme } from '../../../utils/theme';
import { Paper } from '../Paper';
import { translations } from './Table/utils';
import { getTitlesAndBodyData, uniformStyle } from './utils';

const HeaderCell = ({ label, value }: Option) => (
    <Grid item container xs={12} md={3} spacing={1} alignItems="center" style={{ borderRight: '1px solid #ddd' }}>
        <Grid item xs={8}>
            <Typography>{label}</Typography>
        </Grid>
        <Grid item>
            <Paper {...uniformStyle}>{value}</Paper>
        </Grid>
    </Grid>
);

const BodyRow = ({ row }: { row: [JSX.Element, JSX.Element, JSX.Element] }) => {
    return (
        <Grid item container xs={12} md={8} alignItems="center" spacing={2}>
            {row.map((elem, i) => (
                <Grid
                    key={i}
                    style={i === 2 ? {} : { borderLeft: '1px solid #ddd', height: '100%' }}
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={4}
                >
                    {elem}
                </Grid>
            ))}
        </Grid>
    );
};

const Rating = (props: ICourseView) => {
    const [tab, setTab] = useState(0);
    const isCopper = props.scoringType === 'copper';
    const ratingText =
        (props.evaluation || '') in translations
            ? translations[props.evaluation as keyof typeof translations]
            : props.evaluation;

    const headerData: Option[] = [
        {
            label: 'Maximális ' + (isCopper ? 'garasok száma' : 'pontszám'),
            value: '' + props.point.maximumAchievablePoint,
        },

        {
            label: 'Eddig megszerzett ' + (isCopper ? 'garasok' : 'pontok'),
            value: '' + props.totalPointsOnCourse.totalPoints,
        },
        {
            label: 'Hiányzások (aktuális / maximum)',
            value: (props.absencesSoFar ?? 0) + ' / ' + props.maxAbsence,
        },
        { label: 'Aktuális értékelés', value: ratingText || '-' },
    ];

    const { titles, bodyData } = getTitlesAndBodyData(props);

    return (
        <Grid item xs={12} style={{ height: '100%', overflow: 'auto' }}>
            <Paper>
                <Grid item xs={12} container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Értékelés</Typography>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        {Children.toArray(headerData.map(HeaderCell))}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs
                            textColor="secondary"
                            indicatorColor="secondary"
                            value={tab}
                            onChange={(_, v) => setTab(v)}
                            className="studentProfile"
                            TabIndicatorProps={{
                                className: 'studentProfile',
                            }}
                            variant="scrollable"
                        >
                            {titles.map((title, i) => (
                                <Tab
                                    className="studentProfile"
                                    label={title}
                                    sx={{
                                        backgroundColor: tab === i ? theme.palette.primary.main : '#eee',
                                    }}
                                    key={i}
                                />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item container xs={12}>
                        {Children.toArray(
                            bodyData.map((elem, i) => (
                                <Grid item xs={12} key={i}>
                                    <TabPanel value={tab} index={i}>
                                        <BodyRow row={elem as unknown as [JSX.Element, JSX.Element, JSX.Element]} />
                                    </TabPanel>
                                </Grid>
                            )),
                        )}
                    </Grid>
                    {props.evaluationText ? (
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" textAlign="left">
                                    Szöveges értékelés
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div dangerouslySetInnerHTML={{ __html: props.evaluationText || '' }}></div>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default Rating;
