import { Input } from '@silinfo/front-end-template';

export const SESSION_KEY = 'langExamReport';

const langExamsField: Input[] = [
    {
        name: 'student',
        label: 'Hallgató',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'lang',
        label: 'Nyelv',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'type',
        label: 'Típus',
        format: { xs: 12, md: 4, lg: 4 },
        type: 'multiselect',
        options: [],
    },
    {
        name: 'category',
        label: 'Kategória',
        format: { xs: 12, md: 4, lg: 4 },
        type: 'multiselect',
        options: [],
    },
    {
        name: 'level',
        label: 'Szint',
        format: { xs: 12, md: 4, lg: 4 },
        type: 'multiselect',
        options: [],
    },
    {
        name: 'createdAtFrom',
        label: 'Feltöltés ideje (-tól)',
        type: 'datetime-local',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'createdAtUntil',
        label: 'Feltöltés ideje (-ig)',
        type: 'datetime-local',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'validated',
        label: 'Validált',
        type: 'multiselect',
        options: [],
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'onlyActiveStudents',
        label: 'Csak beiratkozott hallgatók',
        type: 'select',
        options: [],
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'trainingLevel',
        label: 'Képzési szint',
        type: 'multiselect',
        options: [],
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'trainingProgram',
        label: 'Képzési program',
        type: 'multiselect',
        options: [],
        format: { xs: 12, md: 4, lg: 4 },
    },
];

const studentsFields: Input[] = [
    {
        name: 'student',
        label: 'Hallgató',
        format: {
            xs: 4,
        },
    },
    {
        name: 'status',
        label: 'Státusz',
        type: 'multiselect',
        options: [
            { value: 'full_completion', label: 'Teljesítve' },
            { value: 'no_completion_but_have_chance', label: 'Nem teljesítve - Türelmi idővel' },
            { value: 'no_completion', label: 'Nem teljesítve' },
        ],
        format: {
            xs: 4,
        },
    },
    {
        name: 'semesterStatus',
        label: 'Szemeszter státusz',
        fieldType: 'base',
        type: 'multiselect',
        options: [],
    },
    {
        name: 'campus',
        label: 'Campus',
        format: {
            xs: 4,
        },
        type: 'multiselect',
        options: [],
    },
    {
        name: 'trainingLevel',
        label: 'Képzési szint',
        format: {
            xs: 4,
        },
        type: 'multiselect',
        options: [],
    },
    {
        name: 'trainingProgram',
        label: 'Képzési program',
        format: {
            xs: 4,
        },
        type: 'multiselect',
        options: [],
    },
    {
        type: 'date',
        label: 'Teljesítés időpontja (-tól)',
        name: 'completionDateFrom',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: 'date',
        label: 'Teljesítés időpontja (-ig)',
        name: 'completionDateUntil',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'billing',
        label: 'Számlázás',
        fieldType: 'base',
        type: 'multiselect',
        options: [],
    },
];

const fields: Input[][] = [langExamsField, studentsFields];

export { fields };
