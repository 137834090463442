import Grid from '@mui/material/Grid';
import PriceTag from './PriceTag';

interface UnpaidCountProps {
    count: number;
}

export default function UnpaidCount({ count }: UnpaidCountProps) {
    return (
        <Grid item container xs={12} md={6}>
            <PriceTag value={count + ' db'} label="Befizetésre váró tételek" />
        </Grid>
    );
}
