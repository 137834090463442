import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { financeFinancialApprovalStatuses } from '../../../utils/AppConst';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../../utils/theme';
import Tooltip from '@mui/material/Tooltip';

export type Color = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;

type GeneralStatus = typeof financeFinancialApprovalStatuses;
export type Key = keyof GeneralStatus;
export type Status = GeneralStatus[Key];

export function StatusRenderer(param: Status) {
    let text = param;

    const getColor = (status: Status): Color => {
        switch (status) {
            case financeFinancialApprovalStatuses.wait_to_un_admin_approval:
                return 'warning';
            case financeFinancialApprovalStatuses.wait_to_leader_approval:
                return 'primary';
            case financeFinancialApprovalStatuses.invoice_during_issue:
                return 'info';
            case financeFinancialApprovalStatuses.waiting_for_payment:
                return 'error';
            case financeFinancialApprovalStatuses.paid:
                return 'success';
            case financeFinancialApprovalStatuses.waiting_for_payment_out:
                return 'error';
            case financeFinancialApprovalStatuses.paid_for:
                return 'success';
            default:
                return 'default';
        }
    };

    // financeFinancialApprovalStatuses-ből kellene szedni
    switch (param) {
        case 'wait_to_un_admin_approval':
            text = financeFinancialApprovalStatuses.wait_to_un_admin_approval;
            break;
        case 'wait_to_leader_approval':
            text = financeFinancialApprovalStatuses.wait_to_leader_approval;
            break;
        case 'invoice_during_issue':
            text = financeFinancialApprovalStatuses.invoice_during_issue;
            break;
        case 'waiting_for_payment':
            text = financeFinancialApprovalStatuses.waiting_for_payment;
            break;
        case 'waiting_for_payment_out':
            text = financeFinancialApprovalStatuses.waiting_for_payment_out;
            break;
        case 'paid':
            text = financeFinancialApprovalStatuses.paid;
            break;
        case 'paid_for':
            text = financeFinancialApprovalStatuses.paid_for;
            break;

        default:
            text = financeFinancialApprovalStatuses.deleted;
            break;
    }

    return (
        <ThemeProvider
            theme={{ ...theme, palette: { ...theme.palette, info: { ...theme.palette.info, main: '#7EC8E3' } } }}
        >
            <Stack direction="row" spacing={1}>
                <Tooltip title={text}>
                    <Chip label={text} color={getColor(text)} />
                </Tooltip>
            </Stack>
        </ThemeProvider>
    );
}
