import { CancelButton, SubmitButton, TextField, transformApiErrors } from '@silinfo/front-end-template';
import { useNavigate, useParams } from 'react-router-dom';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { Dispatch, SetStateAction, useState } from 'react';
import { IGroupForm } from './types';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { useDispatch } from 'react-redux';
import { create } from '../../../store/notification';
import { IForm } from '../../../utils/Interfaces/interfaces';

export default function BaseData({
    groupForm,
    setGroupForm,
    readonly,
}: {
    groupForm: IGroupForm;
    setGroupForm: Dispatch<SetStateAction<IGroupForm>>;
    readonly: boolean;
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<IForm>({} as IForm);
    const { semesterId } = useParams();

    const handleNavigateBack = () => navigate(clientEndPoints.admission_administration);
    const handleSave = (navigateBack: boolean) => {
        setErrors({} as IForm);
        if (groupForm.id) {
            API.post(endpoints.admission.admissionGroup.update.replace('{groupId}', groupForm.id.toString()), {
                ...groupForm,
                semester: semesterId,
            })
                .then(() => {
                    dispatch(create({ type: 'success', message: 'Sikeres mentés.' }));
                    if (navigateBack) {
                        handleNavigateBack();
                    }
                })
                .catch((error) => setErrors(transformApiErrors<IForm>(error.response?.data.violations)));
        } else {
            API.post(endpoints.admission.admissionGroup.create, {
                ...groupForm,
                semester: semesterId,
            })
                .then((response) => {
                    dispatch(create({ type: 'success', message: 'Sikeres mentés.' }));
                    navigate(
                        clientEndPoints.admission_administration_modify_group
                            .replace(':semesterId', semesterId?.toString() ?? '')
                            .replace(':groupId', response.data.data.id),
                    );
                    if (navigateBack) {
                        handleNavigateBack();
                    }
                })
                .catch((error) => setErrors(transformApiErrors<IForm>(error.response?.data.violations)));
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flex: 1 }}>
                <TextField
                    style={{ height: '2rem' }}
                    size="small"
                    name="name"
                    placeholder="Csoport neve"
                    value={groupForm.name}
                    defaultValue={groupForm.name}
                    onChange={(e) => {
                        setGroupForm((prev) => ({
                            ...prev,
                            name: e.target.value,
                        }));
                    }}
                    error={!!errors.name}
                    helperText={errors.name as string}
                    disabled={readonly}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, marginTop: '5rem' }}>
                {!readonly && (
                    <>
                        <SubmitButton sx={{ height: '2rem' }} type="submit" onClick={() => handleSave(false)}>
                            Mentés
                        </SubmitButton>
                        <SubmitButton
                            onClick={() => handleSave(true)}
                            sx={{ marginX: '1rem', height: '2rem' }}
                            type="submit"
                        >
                            Mentés és vissza a listaoldalra
                        </SubmitButton>
                    </>
                )}

                <CancelButton sx={{ height: '2rem' }} type="button" onClick={handleNavigateBack}>
                    Mégsem
                </CancelButton>
            </div>
        </div>
    );
}
