import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { CancelButton, SubmitButton } from '@silinfo/front-end-template';
import { AxiosError, AxiosResponse } from 'axios';
import { SetStateAction, useEffect, useState, Dispatch } from 'react';
import Autocomplete from '../../components/Form/Autocomplete';
import userService from '../../services/masterData/users';
import { Option } from '../../utils/AppConst';
import { removeLayoutTyper, setJWTToken } from '../../utils/AuthHelper';
import { useDispatch, useSelector } from 'react-redux';
import { create } from '../../store/notification';
import { RootState } from '../../store';
import Cookies from 'universal-cookie';

interface LoginInAsProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    epregFlag: boolean;
}

function LoginInAs(props: LoginInAsProps) {
    const dispatch = useDispatch();
    const { open, setOpen, epregFlag } = props;
    const [value, setValue] = useState<string | Option<string>>('');
    const [skipSentryErrors, setSkipSentryErrors] = useState<boolean>(true);
    const { user } = useSelector((state: RootState) => state.auth);
    const cookies = new Cookies();

    useEffect(() => {
        setValue('');
    }, [open]);

    const handleSubmit = () => {
        if (typeof value !== 'string' && value?.value) {
            if (skipSentryErrors) {
                cookies.set('skip_sentry_errors', 'true');
            }
            userService
                .userLoginInAs(parseInt(value.value))
                .then((response: AxiosResponse) => {
                    setJWTToken(response.data.token);
                    removeLayoutTyper();
                    if (epregFlag) {
                        window.location.href = `${process.env.REACT_APP_ENROLLMENT_FRONTEND_URL}`;
                    } else {
                        window.location.href = `${process.env.REACT_APP_AUTH_URL}`;
                    }
                })
                .catch((error: AxiosError) => {
                    console.error(error.response?.data?.error);
                    dispatch(create({ type: 'error', message: error.response?.data?.error }));
                });
        }
    };

    const handleLogout = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>Megszemélyesítés</DialogTitle>
            <DialogContent>
                <p>Kérjük, válaszd ki a felhasználót, akinek a nevében használni szeretnéd a rendszert!</p>
                <Autocomplete
                    getter={(term) => {
                        return userService.userSearchLoginInAs(term);
                    }}
                    textFieldProps={{ label: 'Felhasználó' }}
                    autocompleteProps={{
                        value: value || '',
                        onChange: (_, v) => setValue(v || ''),
                        isOptionEqualToValue: undefined,
                    }}
                    createOptions={(users: { value: number; label: string }[]) =>
                        users.map((user) => ({
                            value: user.value.toString(),
                            label: user.label,
                        }))
                    }
                />
                {user.admin === true && (
                    <Tooltip
                        title="Ha be van jelölve, a SENTRY 403 hibák nem kerülnek jelentésre megszemélyesítés során"
                        arrow
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={skipSentryErrors}
                                    onChange={(e) => setSkipSentryErrors(e.target.checked)}
                                />
                            }
                            label="Jogosultság ellenőrzés SENTRY hibák KIHAGYÁSA"
                        />
                    </Tooltip>
                )}
            </DialogContent>
            <DialogActions>
                <SubmitButton onClick={handleSubmit}>Belépés</SubmitButton>
                <CancelButton onClick={handleLogout}>Mégsem</CancelButton>
            </DialogActions>
        </Dialog>
    );
}

export default LoginInAs;
