import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { Accordion, CancelButton, Link, Page } from '@silinfo/front-end-template';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FieldGenerator } from '../../../../../components/Form/FieldGenerator';
import Form from '../../../../../components/Form/Form';
import MCCLoading from '../../../../../components/MCCLoading';
import languageExamRequirements from '../../../../../services/masterData/languageExamRequirements';
import { create } from '../../../../../store/notification';
import { Option } from '../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { ILanguageExamRequirement } from './fields';
import trainingLevelService from '../../../../../services/masterData/trainingLevels';
import { useTenant } from '../../../../../components/TenantContext';

const Schema = Yup.object().shape({
    level: Yup.string().required('Kötelező megadni'),
    category: Yup.string()
        .max(255, 'Túl hosszú! A maximumálisan megadható karakterek száma 255.')
        .required('Kötelező megadni.'),
});

export default function LanguageExamRequirementForm() {
    const { trainingLevelId, languageExamRequirementId } = useParams() as {
        trainingLevelId: string;
        languageExamRequirementId: string;
    };
    const [options, setOptions] = useState<{ categories: Option[]; levels: Option[]; languages: Option[] }>({
        categories: [],
        levels: [],
        languages: [],
    });
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const initialValues: ILanguageExamRequirement = {
        id: 0,
        language: '',
        level: '',
        category: '',
        gracePeriod: '',
        trainingLevel: trainingLevelId,
    };
    const [levelName, setLevelName] = useState('');
    const tenant = useTenant();

    useEffect(() => {
        setLoading(true);
        axios
            .all([
                languageExamRequirements.categories(),
                languageExamRequirements.levels(),
                languageExamRequirements.languages(),
            ])
            .then((res) => {
                setOptions((prev) => {
                    return {
                        ...prev,
                        categories: res[0].data,
                        levels: res[1].data,
                        languages: res[2].data,
                    };
                });
            })
            .catch(() =>
                dispatch(
                    create({
                        type: 'error',
                        message: 'Sikertelen betöltés',
                    }),
                ),
            )
            .finally(() => setLoading(false));
        trainingLevelService.trainingLevels().then((response) => {
            setLevelName(response.data.find((element: Option) => element.value == trainingLevelId).label);
        });
    }, [trainingLevelId, dispatch]);

    const title = languageExamRequirementId ? 'Követelmény szerkesztése' : 'Követelmény hozzáadása';

    const header = {
        project: tenant || 'TAR',
        title: 'Nyelvvizsga követelmények',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: levelName + ' szint',
            [clientEndPoints.md_training_levels_language_exam_requirements_list.replace(
                ':trainingLevelId',
                trainingLevelId?.toString(),
            )]: 'Nyelvvizsga követelmények',
            form: title,
        },
    };

    const getBaseData = async () => {
        const { data } = await languageExamRequirements.get(+languageExamRequirementId);

        return {
            ...data,
            data: {
                ...data,
                gracePeriod: '' + data.gracePeriod,
            },
            gracePeriod: '' + data.gracePeriod,
        };
    };
    const onSubmit = (form: ILanguageExamRequirement) => {
        return languageExamRequirementId
            ? languageExamRequirements.updateLanguageExamRequirement(languageExamRequirementId, form)
            : languageExamRequirements.create(form);
    };

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Page header={header}>
            <Accordion title={title}>
                <Grid item xs={12}>
                    <Form
                        hideButtons
                        fields={[]}
                        back={clientEndPoints.md_training_levels_language_exam_requirements_list.replace(
                            ':trainingLevelId',
                            trainingLevelId?.toString(),
                        )}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        getterFunction={getBaseData}
                        urlParam="languageExamRequirementId"
                    >
                        {(props) => (
                            <>
                                {FieldGenerator({
                                    name: 'language',
                                    label: 'Nyelv',
                                    type: 'select',
                                    options: options?.languages,
                                    format: { xs: 12, sm: 6, md: 6 },
                                    ...props,
                                })}
                                {FieldGenerator({
                                    name: 'level',
                                    label: 'Szint',
                                    type: 'select',
                                    options: options?.levels,
                                    format: { xs: 12, sm: 6, md: 6 },
                                    ...props,
                                })}
                                {FieldGenerator({
                                    name: 'category',
                                    label: 'Kategória',
                                    type: 'select',
                                    options: options?.categories,
                                    format: { xs: 12, sm: 6, md: 6 },
                                    ...props,
                                })}
                                {FieldGenerator({
                                    name: 'gracePeriod',
                                    label: 'Türelmi idő',
                                    type: 'select',
                                    options: [
                                        { value: '0', label: 'Nincs' },
                                        { value: '1', label: '1' },
                                        { value: '2', label: '2' },
                                        { value: '3', label: '3' },
                                        { value: '4', label: '4' },
                                    ],
                                    format: { xs: 12, sm: 6, md: 6 },
                                    ...props,
                                })}

                                <Grid item container spacing={2} justifyContent="flex-start">
                                    <Grid item>
                                        <LoadingButton variant="contained" type="submit">
                                            Mentés
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            to={clientEndPoints.md_training_levels_language_exam_requirements_list.replace(
                                                ':trainingLevelId',
                                                trainingLevelId?.toString(),
                                            )}
                                        >
                                            <CancelButton>Mégse</CancelButton>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Form>
                </Grid>
            </Accordion>
        </Page>
    );
}
