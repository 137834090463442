import Buildings from '../../../pages/MasterData/General/Campuses/Buildings/Buildings';
import BuildingsForm from '../../../pages/MasterData/General/Campuses/Buildings/BuildingsForm';
import { campusesBuildingsFloors } from './CampusesBuildingsFloors';

export const campusesBuildings = {
    path: ':campusId/buildings',
    children: [
        {
            path: '',
            element: <Buildings />,
            name: 'md_general_campuses_buildings_list',
        },
        {
            path: ':buildingId/show',
            element: <BuildingsForm readOnly />,
            name: 'md_general_campuses_buildings_show',
        },
        campusesBuildingsFloors,
    ],
};
