import PublishIcon from '@mui/icons-material/Publish';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FileUploadButtonProps } from './types';
import { useCallback, useRef } from 'react';

const UploadButton = ({ handleFileChange, loading, accept }: FileUploadButtonProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const handleClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    }, []);
    return (
        <Grid item>
            <Button
                variant="contained"
                component="label"
                startIcon={<PublishIcon />}
                disabled={loading}
                onClick={handleClick}
            >
                Fájl kiválasztása
                <input
                    type="file"
                    accept={accept || 'application/pdf'}
                    onChange={handleFileChange}
                    hidden
                    ref={inputRef}
                />
            </Button>
        </Grid>
    );
};

export default UploadButton;
