import { CourseQuestionnaireFills } from './types';

const initialCourseQuestionnaireFills: CourseQuestionnaireFills = {
    form: {
        pages: [],
    },
    courseCode: '',
    fills: [],
};

export { initialCourseQuestionnaireFills };
