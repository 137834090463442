import { Outlet } from 'react-router-dom';
import OffLayout from '../../components/OffLayout';
import TokenNotFoundComponent from '../../pages/Admission/Admissions/TokenNotFoundComponent';

export const admissionTokenNotFoundPage = {
    path: '/',
    element: (
        <OffLayout>
            <Outlet />
        </OffLayout>
    ),
    children: [
        {
            path: 'felveteli/token-not-found',
            element: <TokenNotFoundComponent />,
            name: 'admission_token_not_found',
        },
    ],
};
