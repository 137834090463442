import TrainingLevelForm from '../../../pages/MasterData/General/TrainingLevels/TrainingLevelForm';
import TrainingLevels from '../../../pages/MasterData/General/TrainingLevels';
import { trainingLevelsPrograms } from './TrainingLevelsPrograms';
import { trainingLevelsLanguageExamRequirements } from './TrainingLevelsLanguageExamRequirements';

export const trainingLevels = {
    path: 'training-levels',
    children: [
        {
            path: '',
            element: <TrainingLevels />,
            name: 'md_training_levels_list',
        },
        {
            path: 'new',
            element: <TrainingLevelForm />,
            name: 'md_training_levels_create',
        },
        {
            path: ':id/edit',
            element: <TrainingLevelForm />,
            name: 'md_training_levels_edit',
        },
        {
            path: ':id/show',
            element: <TrainingLevelForm readOnly={true} />,
            name: 'md_training_levels_show',
        },
        trainingLevelsPrograms,
        trainingLevelsLanguageExamRequirements,
    ],
};
