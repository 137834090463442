import Rooms from '../../../pages/MasterData/General/Campuses/Buildings/Floors/Rooms/Rooms';
import RoomsForm from '../../../pages/MasterData/General/Campuses/Buildings/Floors/Rooms/RoomsForm';
import { campusesBuildingsFloorsRoomsItems } from './CampusesBuildingsFloorsRoomsItems';

export const campusesBuildingsFloorsRooms = {
    path: ':floorId/rooms',
    children: [
        {
            path: '',
            element: <Rooms />,
            name: 'md_general_campuses_buildings_floors_rooms_list',
        },
        {
            path: ':roomId/show',
            element: <RoomsForm readOnly />,
            name: 'md_general_campuses_buildings_floors_rooms_show',
        },
        campusesBuildingsFloorsRoomsItems,
    ],
};
