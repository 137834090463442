import { IForm, IPage } from '@silinfo/form-builder-2';
import IElement from '@silinfo/form-builder-2/lib/esm/types/IElement';
import { IFormAnswerDetail } from './types';
import { IForm2, IResponse } from '../../../../../components/PreviewTemplate/types';

const findDetailsByFormAnswerId = (formAnswerId: string, data: IForm): IFormAnswerDetail => {
    const pages = Object.values(data.pages);

    for (const page of pages) {
        const panels = Object.values(page.panels);
        for (const panel of panels) {
            const elements = Object.values(panel.elements);
            for (const element of elements) {
                if (element.type !== 'Paragraph' && element.formAnswer.ID === formAnswerId) {
                    return {
                        page: page.index + 1,
                        panel: panel.title || page.index + 1 + '. oldal ' + (+panel.index + 1) + '. panel (névtelen)',
                        question: element.question,
                        element,
                        index: element.index,
                    };
                }
            }
        }
    }

    return {
        page: 0,
        panel: 'ismeretlen panel',
        question: '',
        index: 0,
        element: {
            type: 'text',
            formAnswer: {
                ID: '',
            },
        } as IElement,
    };
};

// function that returns groups of answers and details by page, which has groups of answers and details by panel
const getAnswersAndDetailsByPage = (answersAndDetails: (IFormAnswerDetail & { answer: string | JSX.Element })[]) => {
    const answersAndDetailsByPage: {
        [key: number]: { [key: string]: (IFormAnswerDetail & { answer: string | JSX.Element })[] };
    } = {};

    for (const answerAndDetails of answersAndDetails) {
        const { page, panel } = answerAndDetails;
        if (!answersAndDetailsByPage[page]) {
            answersAndDetailsByPage[page] = {};
        }
        if (!answersAndDetailsByPage[page][panel]) {
            answersAndDetailsByPage[page][panel] = [];
        }
        answersAndDetailsByPage[page][panel].push(answerAndDetails);
        answersAndDetailsByPage[page][panel].sort((a, b) => a.index - b.index);
    }

    return answersAndDetailsByPage;
};

const getAnswerByElement = (answer: unknown, element: IElement): string | JSX.Element => {
    if (element.type === 'scale') return answer + ' / ' + element.formAnswer.toScale;
    if (element.type === 'multiselect')
        return (answer as string[])
            .map(
                (elem) =>
                    Object.values(element.formAnswer.elements).find((e) => e.ID === elem)?.value || 'ismeretlen válasz',
            )
            .join(', ');
    if (element.type === 'select' || element.type === 'selectradio') {
        return Object.values(element.formAnswer.elements).find((e) => e.ID === answer)?.value || 'ismeretlen válasz';
    }

    if (element.type === 'table') {
        const table = answer as Record<string, string>;
        return (
            <ul>
                {Object.entries(table).map(([key, value]) => {
                    const question = element.formAnswer.rows.find((e) => e.ID === key)?.value || 'ismeretlen sor';
                    const answer =
                        element.formAnswer.cols.find((e) => e.ID === value.split('___')[1])?.value ||
                        'ismeretlen válasz';
                    return (
                        <li key={question}>
                            {question}: {answer}
                        </li>
                    );
                })}
            </ul>
        );
    }

    return answer + '';
};

const addDescriptions = (
    groups: {
        [key: number]: {
            [key: string]: (IFormAnswerDetail & {
                answer: string | JSX.Element;
            })[];
        };
    },
    form: IForm,
) => {
    const groupsWithDescriptions = { ...groups };

    for (const page of Object.values(form?.pages || {})) {
        for (const panel of Object.values(page.panels)) {
            const elements = Object.values(panel.elements);
            const index = elements.findIndex((e) => e.type === 'Paragraph');
            const description = (elements[index] as { value: string })?.value || '';
            if (
                description &&
                groupsWithDescriptions[page.index + 1] &&
                groupsWithDescriptions[page.index + 1][
                    panel.title || page.index + 1 + '. oldal ' + (+panel.index + 1) + '. panel (névtelen)'
                ]
            ) {
                groupsWithDescriptions[page.index + 1][
                    panel.title || page.index + 1 + '. oldal ' + (+panel.index + 1) + '. panel (névtelen)'
                ].splice(index, 0, {
                    page: page.index + 1,
                    panel: panel.title || page.index + 1 + '. oldal ' + (+panel.index + 1) + '. panel (névtelen)',
                    question: '',
                    element: {
                        type: 'Paragraph',
                    } as IElement,
                    answer: <div dangerouslySetInnerHTML={{ __html: description }} />,
                    index,
                });
            }
        }
    }

    return groupsWithDescriptions;
};

function getDescriptionAndDetails(data: IResponse) {
    return data.forms.map((form, key) => {
        const fill: Record<string, unknown> = (
            data.fill[key] && data.fill[key].formData ? data.fill[key].formData : data.fill[key]
        ) as Record<string, unknown>;
        const answersAndDetails: IAnswerAndDetail[] = [];
        const pages = Object.values(form.pages);

        for (const page of pages) {
            const panels = Object.values(page.panels);
            for (const panel of panels) {
                const elements = Object.values(panel.elements);

                if (panel.multiple) {
                    const fillsPanel = Object.values(fill[panel.ID] ?? []);
                    for (let l = 0; l < fillsPanel.length; l++) {
                        for (const element of elements) {
                            let panelName =
                                panel.title || page.index + 1 + '. oldal ' + (+panel.index + 1) + '. panel (névtelen)';
                            panelName += ' (' + (l + 1) + ')';
                            addElement(page, form, panelName, element, fillsPanel[l], answersAndDetails);
                        }
                    }
                } else {
                    for (const element of elements) {
                        const panelName =
                            panel.title || page.index + 1 + '. oldal ' + (+panel.index + 1) + '. panel (névtelen)';
                        addElement(page, form, panelName, element, fill, answersAndDetails);
                    }
                }
            }
        }

        const groupedAnswersAndDetails = getAnswersAndDetailsByPage(answersAndDetails);
        const groupedAnswersAndDetailsWithDescriptions = addDescriptions(groupedAnswersAndDetails, form);

        return groupedAnswersAndDetailsWithDescriptions;
    });
}
interface IAnswerAndDetail extends IFormAnswerDetail {
    answer: string | JSX.Element;
}

function addElement(
    page: IPage,
    form: IForm2,
    panelName: string,
    element: IElement,
    fill: Record<string, unknown>,
    answersAndDetails: IAnswerAndDetail[],
) {
    if (element.type !== 'Paragraph') {
        const answer = fill?.[element.formAnswer.ID] ?? '(nincs megadva)';

        answersAndDetails.push({
            page: page.index + 1,
            panel: panelName,
            question: element.question,
            element,
            index: element.index,
            answer: getAnswerByElement(answer, findDetailsByFormAnswerId(element.formAnswer.ID, form).element),
        });
    }
}

export {
    findDetailsByFormAnswerId,
    getAnswersAndDetailsByPage,
    getAnswerByElement,
    addDescriptions,
    getDescriptionAndDetails,
};
