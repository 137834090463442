import Typography from '@mui/material/Typography';
import AdmissionsStyle from '../Admissions.module.scss';

export const ErrorText = ({ message, matches }: { message: string; matches: boolean }) => {
    return (
        <Typography
            className={`${AdmissionsStyle.basicDataErrorText}`}
            sx={{
                minHeight: matches ? '1rem' : 0,
            }}
        >
            {message}
        </Typography>
    );
};
