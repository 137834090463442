import Grid from '@mui/material/Grid';
import { FormikListPage, Header, Loading, useFetch } from '@silinfo/front-end-template';
import { useState, useEffect } from 'react';
import { SemesterContextProvider } from '../../../components/SemesterSelector/SemesterContextProvider';
import campusesService from '../../../services/masterData/campuses';
import scholarshipReportService from '../../../services/scholarship/scholarshipReport';
import { Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import useEntityOptions from '../../../utils/useEntityOptions';
import SemesterSelector from './../../../components/SemesterSelector/index';
import ExportButton from '../../../components/Buttons/ExportButton';
import { filterFields } from './fields';
import { breadcrumbs, columns, tableStyling, header } from './utils';
import instance from '../../../api';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import { initialReportFilterState, IScholarshipReport } from './types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';

export default function Report() {
    const { user } = useSelector((state: RootState) => state.auth);
    const semesterState = useState<Option | null>(null);
    const campusOptions = useEntityOptions(campusesService, 'name', 'id');
    const [semester] = semesterState;
    const [info, setInfo] = useState<IInfo<IScholarshipReport>>(initialReportFilterState(semester?.value || ''));
    const { data: trainingLevelOptions } = useFetch<Option[]>(endpoints.masterData.trainingLevels.trainingLevels, '');
    const { data: trainingProgramOptions } = useFetch<Option[]>(
        endpoints.masterData.trainingPrograms.options + '?withoutOther=1&withLevel=1',
        '',
    );
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const options = {
        campus: campusOptions,
        trainingLevel: trainingLevelOptions,
        trainingProgram: trainingProgramOptions,
    };

    const optionsLoading = Object.values(options).some((option) => option.length === 0);

    const exportContext = {
        initiateExport: scholarshipReportService(semester?.value || '').initiateExport,
        checkExport: scholarshipReportService(semester?.value || '').checkExport,
        downloadExport: scholarshipReportService(semester?.value || '').downloadExport,
        info,
        filter: info.filter,
    };

    useEffect(() => {
        setInfo((prev) => ({ ...prev, semester: semester ? [semester.value] : [] }));
    }, [semester]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...headerWithTenant, breadcrumbs }} />
            </Grid>
            <Grid item xs={12}>
                <SemesterSelector
                    defaultActive
                    semesterState={semesterState}
                    url={endpoints.scholarships.report.semesters}
                />
            </Grid>
            <Grid item xs={12}>
                <SemesterContextProvider semester={semester?.value || ''}>
                    {optionsLoading ? (
                        <Loading noHeight />
                    ) : (
                        <FormikListPage
                            filter={{
                                fields: filterFields(options, semester?.value || ''),
                            }}
                            header={{ ...headerWithTenant, breadcrumbs: {} } as HeaderProps}
                            responseKey="data"
                            autoload
                            service={{
                                filter: (form) => {
                                    setInfo((prevState) => ({ ...prevState, filter: form })); // Export miatt van itt
                                    return instance.get(endpoints.scholarships.report.list(semester?.value || ''), {
                                        params: form,
                                    });
                                },
                            }}
                            columns={columns(semester?.value || '', user.archive)}
                            refresh={semester}
                            tableProps={tableStyling}
                            customButtons={
                                <>
                                    {!user.archive && (
                                        <Grid item>
                                            <ExportButton exportContext={exportContext} />
                                        </Grid>
                                    )}
                                </>
                            }
                        />
                    )}
                </SemesterContextProvider>
            </Grid>
        </Grid>
    );
}
