import Grid from '@mui/material/Grid';
import { Option } from '../../../../utils/AppConst';

export default function ColorExplanation({ value, label }: Option) {
    return (
        <Grid item>
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        backgroundColor: value,
                        width: '20px',
                        height: '20px',
                        marginRight: '2px',
                        fontSize: '1rem',
                    }}
                />
                <i style={{ fontSize: '14px' }}>{label}</i>
            </div>
        </Grid>
    );
}
