import LanguageExamRequirements from '../../../pages/MasterData/General/TrainingLevels/LanguageExamRequirements';
import LanguageExamRequirementForm from '../../../pages/MasterData/General/TrainingLevels/LanguageExamRequirements/Form';

export const trainingLevelsLanguageExamRequirements = {
    path: ':trainingLevelId/language-exam-requirements',
    children: [
        {
            path: '',
            element: <LanguageExamRequirements />,
            name: 'md_training_levels_language_exam_requirements_list',
        },
        {
            path: 'new',
            element: <LanguageExamRequirementForm />,
            name: 'md_training_levels_language_exam_requirements_create',
        },
        {
            path: ':languageExamRequirementId/edit',
            element: <LanguageExamRequirementForm />,
            name: 'md_training_levels_language_exam_requirements_edit',
        },
    ],
};
