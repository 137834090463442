import API from '../../api';
import { endpoints } from '../../utils/endPoints';
import { IForm } from '../../utils/Interfaces/interfaces';
import { crudTemplate } from '../templates';

export interface IBuildingsForm {
    campus: string;
    campusName: string;
    name: string;
    address: {
        postCode: string;
        city: string;
        streetType: string;
        streetName: string;
        houseNumber: string;
    };
}

export const initialForm = {
    campusName: '',
    campus: '',
    name: '',
    address: {
        postCode: '',
        city: '',
        streetType: '',
        streetName: '',
        houseNumber: '',
    },
    errors: [],
};

const buildingsService = {
    ...crudTemplate<IBuildingsForm>(endpoints.masterData.buildings.main),
    filter: (form: IForm, campusId: string) =>
        API.get(endpoints.masterData.buildings.filter.replace('{campusId}', campusId.toString()), { params: form }),
    fetch: (campusId: string) => API.get(endpoints.masterData.buildings.filter.replace('{campusId}', campusId + '')),
};

export default buildingsService;
