import { FieldGenerator, Form, SubmitButton, transformApiErrors } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { IForm } from '../../../utils/Interfaces/interfaces';
import Grid from '@mui/material/Grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import Title from './Title';
import { endpoints } from '../../../utils/endPoints';
import API from '../../../api';
import { initialDocument, initialValues } from './utils';
import TextField from '@mui/material/TextField';
import { IAdmissionDocument } from './types';
import { create } from '../../../store/notification';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import UploadRow from './UploadRow';

export default function QuestionnaireTemplateSelect({
    admissionId,
    readonly,
}: {
    admissionId: number;
    readonly: boolean;
}) {
    const dispatch = useDispatch();
    const [admissionForm, setAdmissionForm] = useState(initialValues);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [customId, setCustomId] = useState(-1);
    const uploadableCount = (admissionForm.uploadableDocuments as IAdmissionDocument[]).length;
    const informationCount = (admissionForm.informationDocuments as IAdmissionDocument[]).length;

    useEffect(() => {
        if (admissionId) {
            API.get(
                endpoints.admission.admissionDocument.admissionForm.replace('{admissionId}', admissionId.toString()),
            )
                .then((data) => setAdmissionForm(data.data))
                .finally(() => setLoading(false));
        }
    }, [admissionId, refresh]);

    const handleSubmit = (form: IForm, formikHelpers: FormikHelpers<IForm>) => {
        setButtonLoading(true);
        API.post(endpoints.admission.admissionDocument.admissionForm.replace('{admissionId}', admissionId.toString()), {
            ...form,
            informationDocuments: admissionForm.informationDocuments,
            uploadableDocuments: admissionForm.uploadableDocuments,
        })
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres mentés.' }));
                setRefresh((prev) => !prev);
            })
            .catch((error) => {
                if (error.response?.data.violations) {
                    formikHelpers.setErrors(transformApiErrors<IForm>(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Sikertelen mentés.' }));
            })
            .finally(() => setButtonLoading(false));
    };

    if (loading) {
        return <Skeleton />;
    }

    return (
        <Box
            component={Grid}
            container
            boxShadow={3}
            sx={{
                width: '75%',
                padding: '10px',
                margin: '10px auto 0',
                marginLeft: 'auto',
                marginRight: 'auto',
                '& form': { width: '100%' },
            }}
        >
            <Grid style={{ margin: '15px' }}>
                <Form hideButtons urlParam="courseId" fields={[]} onSubmit={handleSubmit} initialValues={admissionForm}>
                    {(props) => (
                        <>
                            <Title title="Általános beállítások">
                                <Grid container spacing={2}>
                                    {FieldGenerator({
                                        name: 'questionnaireTemplate',
                                        label: 'Felvételi űrlap sablon',
                                        type: 'backendSelect',
                                        url: endpoints.admission.admissionDocument.questionnaireTemplates.replace(
                                            '{admissionId}',
                                            `${props.values.id}`,
                                        ),
                                        format: { xs: 12 },
                                        justValue: true,
                                        props: {
                                            disabled: readonly,
                                        },
                                        ...props,
                                    })}
                                </Grid>
                                <Grid container spacing={2}>
                                    {FieldGenerator({
                                        name: 'privacyStatement',
                                        label: 'Felvételi adatkezelési nyilatkozat',
                                        type: 'backendSelect',
                                        url: endpoints.admission.admissionDocument.privacyStatements.replace(
                                            '{admissionId}',
                                            `${props.values.id}`,
                                        ),
                                        format: { xs: 12 },
                                        justValue: true,
                                        props: {
                                            disabled: readonly,
                                        },
                                        ...props,
                                    })}
                                </Grid>
                            </Title>
                            <Title title="Határidők">
                                <Grid container spacing={2}>
                                    {FieldGenerator({
                                        name: 'applicationDeadline',
                                        label: 'Jelentkezési határidő',
                                        type: 'date',
                                        format: { xs: 6 },
                                        props: {
                                            disabled: readonly,
                                        },
                                        ...props,
                                    })}

                                    {FieldGenerator({
                                        name: 'closingDeadline',
                                        label: 'Zárási határidő',
                                        type: 'date',
                                        format: { xs: 6 },
                                        props: {
                                            disabled: readonly,
                                        },
                                        ...props,
                                    })}
                                </Grid>
                            </Title>
                            <Title
                                title="Feltöltendő dokumentumok"
                                titleButton={
                                    <IconButton
                                        color={'info'}
                                        onClick={() => {
                                            setAdmissionForm((prev) => ({
                                                ...prev,
                                                uploadableDocuments: [
                                                    ...(prev.uploadableDocuments as IAdmissionDocument[]),
                                                    initialDocument(customId, admissionId),
                                                ],
                                            }));
                                            setCustomId(customId - 1);
                                        }}
                                        disabled={uploadableCount >= 10 || readonly}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                }
                            >
                                {(admissionForm.uploadableDocuments as IAdmissionDocument[]).map(
                                    (element: IAdmissionDocument, index: number) => (
                                        <>
                                            <Grid container sx={{ marginBottom: '10px' }} spacing={2}>
                                                <Grid item xs={4} sx={{ paddingRight: '6px' }}>
                                                    <TextField
                                                        label="Dokumentum neve"
                                                        variant="outlined"
                                                        value={element.name}
                                                        defaultValue={element.name}
                                                        size="small"
                                                        fullWidth
                                                        onChange={(e) => {
                                                            setAdmissionForm((prev) => ({
                                                                ...prev,
                                                                uploadableDocuments: (
                                                                    prev.uploadableDocuments as IAdmissionDocument[]
                                                                ).map((document: IAdmissionDocument) =>
                                                                    document.id === element.id
                                                                        ? {
                                                                              ...document,
                                                                              name: e.target.value,
                                                                          }
                                                                        : document,
                                                                ),
                                                            }));
                                                        }}
                                                        disabled={readonly}
                                                        error={!!props.errors['uploadableName' + (index + 1)]}
                                                        helperText={props.errors['uploadableName' + (index + 1)]}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sx={{ paddingLeft: '6px' }}>
                                                    <TextField
                                                        type="date"
                                                        label="Feltöltési határidő"
                                                        variant="outlined"
                                                        value={element.deadline}
                                                        defaultValue={element.deadline}
                                                        size="small"
                                                        fullWidth
                                                        disabled={readonly}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={(e) => {
                                                            setAdmissionForm((prev) => ({
                                                                ...prev,
                                                                uploadableDocuments: (
                                                                    prev.uploadableDocuments as IAdmissionDocument[]
                                                                ).map((document: IAdmissionDocument) =>
                                                                    document.id === element.id
                                                                        ? {
                                                                              ...document,
                                                                              deadline: e.target.value,
                                                                          }
                                                                        : document,
                                                                ),
                                                            }));
                                                        }}
                                                        inputProps={{
                                                            max: '9999-12-31',
                                                        }}
                                                        error={!!props.errors['deadline' + (index + 1)]}
                                                        helperText={props.errors['deadline' + (index + 1)]}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        paddingLeft: '6px',
                                                        paddingTop: '8px !important',
                                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'rgba(0, 0, 0, 0.38)',
                                                        },
                                                    }}
                                                >
                                                    {FieldGenerator({
                                                        name: 'rule',
                                                        label: 'Szabály',
                                                        type: 'select',
                                                        format: { xs: 12 },
                                                        fieldType: 'base',
                                                        options: [
                                                            { value: 'required', label: 'Kötelező' },
                                                            { value: 'optional', label: 'Opcionális' },
                                                        ],
                                                        props: {
                                                            disabled: readonly,
                                                            value: element.rule,
                                                            defaultValue: element.rule,
                                                            onChange: (e: { target: { value: string } }) => {
                                                                setAdmissionForm((prev) => ({
                                                                    ...prev,
                                                                    uploadableDocuments: (
                                                                        prev.uploadableDocuments as IAdmissionDocument[]
                                                                    ).map((document: IAdmissionDocument) =>
                                                                        document.id === element.id
                                                                            ? {
                                                                                  ...document,
                                                                                  rule: e.target.value,
                                                                              }
                                                                            : document,
                                                                    ),
                                                                }));
                                                            },
                                                        },
                                                        ...props,
                                                    })}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        color={'error'}
                                                        disabled={readonly}
                                                        onClick={() =>
                                                            setAdmissionForm((prev) => ({
                                                                ...prev,
                                                                uploadableDocuments: (
                                                                    prev.uploadableDocuments as IAdmissionDocument[]
                                                                ).filter((item) => item.id != element.id),
                                                            }))
                                                        }
                                                    >
                                                        <RemoveCircleOutlineIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ),
                                )}
                            </Title>
                            <Title
                                title="Tájékoztató dokumentumok"
                                titleButton={
                                    <IconButton
                                        color={'info'}
                                        onClick={() => {
                                            setAdmissionForm((prev) => ({
                                                ...prev,
                                                informationDocuments: [
                                                    ...(prev.informationDocuments as IAdmissionDocument[]),
                                                    initialDocument(customId, admissionId),
                                                ],
                                            }));
                                            setCustomId(customId - 1);
                                        }}
                                        disabled={informationCount >= 10 || readonly}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                }
                            >
                                {(admissionForm.informationDocuments as IAdmissionDocument[]).map(
                                    (element: IAdmissionDocument, index: number) => (
                                        <UploadRow
                                            key={element.id}
                                            dispatch={dispatch}
                                            admissionForm={admissionForm}
                                            setAdmissionForm={setAdmissionForm}
                                            element={element}
                                            readonly={readonly}
                                            index={index}
                                            props={props}
                                        />
                                    ),
                                )}
                            </Title>
                            <Grid
                                item
                                container
                                xs={12}
                                spacing={2}
                                sx={{
                                    justifyContent: 'flex-end',
                                    margin: '0 10px 10px 0',
                                    paddingTop: '0px !important',
                                }}
                            >
                                {!readonly && (
                                    <Grid item>
                                        <SubmitButton loading={buttonLoading} type="submit">
                                            Mentés
                                        </SubmitButton>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                </Form>
            </Grid>
        </Box>
    );
}
