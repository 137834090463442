import { FormikListPage, useBoolean } from '@silinfo/front-end-template';
import { tableFields } from './fields';
import apiKeysService from '../../../services/administration/apiKeysService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';
import { clientEndPoints } from '../../../utils/clientEndPoints';

export default function ApiKeys() {
    const { value: refresh, toggle } = useBoolean();
    const { user } = useSelector((state: RootState) => state.auth);
    const tenant = useTenant();
    const header = {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_api_keys_list]: 'API kulcsok',
        },
        title: 'API kulcsok',
        project: tenant || 'TAR',
    };
    return (
        <FormikListPage
            service={apiKeysService}
            header={header}
            columns={tableFields(toggle, user.archive)}
            autoload
            responseKey="data"
            refresh={refresh}
            tableProps={{}}
            {...(!user.archive ? { newLabel: 'API kulcs hozzáadása' } : {})}
        />
    );
}
