import SelfAssessment from '../../pages/Administration/SelfAssessment';

export const report = {
    path: 'report',
    children: [
        {
            path: '',
            element: <SelfAssessment />,
            name: 'report',
        },
    ],
};
