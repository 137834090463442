import Markunread from '@mui/icons-material/Markunread';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import OperationCellRenderer from './OperationCellRenderer';
import './SystemMessages.css';
import moment from 'moment';
import CheckBoxSelectButton from './CheckBoxSelectButton';
import { SetStateAction } from 'react';

export const header = (tenant: string | null) => {
    return {
        title: 'Rendszerüzenetek',
        breadcrumbs: {
            'systemMessages': 'Rendszerüzenetek',
        },
        project: tenant || 'TAR',
    };
};

export const columns = (
    refresh: () => void,
    setChecked: React.Dispatch<SetStateAction<number[]>>,
    allChecked: boolean,
    visibleChecked: boolean,
): GridColDef[] =>
    [
        {
            field: 'select',
            headerName: '',
            renderCell: (props: GridRenderCellParams) => (
                <CheckBoxSelectButton
                    refresh={refresh}
                    setCheckbox={setChecked}
                    allChecked={allChecked}
                    visibleChecked={visibleChecked}
                    {...props}
                />
            ),
            flex: 0.1,
        },
        {
            field: 'seen',
            headerName: '',
            flex: 0.1,
            renderCell: (params: GridRenderCellParams) =>
                params.row.lastSeen === null ? <Markunread color="secondary" /> : '',
            cellClassName: 'seen',
        },
        {
            field: 'createdAt',
            headerName: 'Értesítés időpontja',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) =>
                params.value ? moment(params.value).toDate().toLocaleString() : '-',
        },
        {
            field: 'messageTitle',
            headerName: 'Értesítés címe',
            minWidth: 150,
        },
        {
            field: 'operations',
            headerName: 'Műveletek',
            renderCell: (props: GridRenderCellParams) => <OperationCellRenderer refresh={refresh} {...props} />,
            minWidth: 150,
        },
    ].map((column) => ({ flex: 1, ...column }));
