import ListAltIcon from '@mui/icons-material/ListAlt';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { jsPDF } from 'jspdf';
import { useCallback, useEffect, useRef, useState } from 'react';
import instance from '../../api';
import { PDFDownloaderProps, TPDFResponse } from './types';
import { boldFont, font, italicFont } from './utils';

const PDFDownloader = <T,>({
    url,
    data,
    ComponentToPrint,
    DownloadIcon,
    portrait,
    transform,
}: PDFDownloaderProps<T>) => {
    const [cached, setCached] = useState<TPDFResponse<T> | null>(data || null);
    const [loading, setLoading] = useState(false);
    const refToPrint = useRef<HTMLDivElement>(null);
    const [intentional, setIntentional] = useState(false);

    const handleDownloadPdf = useCallback(async () => {
        if (!refToPrint || !cached) return;
        const element = refToPrint.current;
        if (!element) return;
        setLoading(true);
        try {
            element.style.fontFamily = 'Roboto';
            const pdf = new jsPDF(portrait ? 'portrait' : 'landscape', 'px', 'a4', false);
            pdf.addFileToVFS('Roboto-Black-normal.ttf', font);
            pdf.addFileToVFS('Roboto-Black-bold.ttf', boldFont);
            pdf.addFileToVFS('Roboto-Black-italic.ttf', italicFont);
            pdf.addFont('Roboto-Black-normal.ttf', 'Roboto', 'normal');
            pdf.addFont('Roboto-Black-bold.ttf', 'Roboto', 'bold');
            pdf.addFont('Roboto-Black-italic.ttf', 'Roboto', 'italic');
            pdf.setFont('Roboto');
            pdf.html(element, {
                margin: [24, 0, 24, 0],
                callback: (done) => {
                    done.save(cached.filename);
                },
                html2canvas: {
                    scale: 0.43,
                    letterRendering: true,
                },
                autoPaging: 'text',
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [cached, portrait]);

    const handleClick = useCallback(async () => {
        if (url) {
            try {
                setIntentional(true);
                setLoading(true);
                const { data } = await instance.get<TPDFResponse<T>>(url);
                setCached(transform ? transform(data) : data);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        } else {
            handleDownloadPdf();
        }
    }, [url, transform, handleDownloadPdf]);

    useEffect(() => {
        if (cached && intentional) {
            handleDownloadPdf();
        }
    }, [cached, handleDownloadPdf, intentional]);

    return (
        <>
            <Tooltip title="Letöltés">
                <IconButton
                    size="small"
                    onClick={handleClick}
                    disabled={loading}
                    style={{ borderWidth: '1px', borderStyle: 'solid' }}
                    color="primary"
                >
                    {loading ? <CircularProgress size={20} /> : DownloadIcon || <ListAltIcon />}
                </IconButton>
            </Tooltip>
            {cached ? (
                <div style={{ position: 'fixed', left: -10000 }}>
                    <div
                        ref={refToPrint}
                        style={{ margin: '0 72pt', width: '1278px' /* !!! BŰVÖS SZÁM NE NYÚLJ HOZZÁ !!! */ }}
                    >
                        <ComponentToPrint data={cached.row} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default PDFDownloader;
