import { Page, TabsCompleted } from '@silinfo/front-end-template';
import TabContent from './TabContent';
import { header } from './utils';
import { useTenant } from '../../../components/TenantContext';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';

export default function FinancialApprovals() {
    const tenant = useTenant();
    const tabContents = [
        {
            label: 'Pénzügyi jóváhagyások',
            value: <TabContent view="base" />,
        },
        {
            label: 'Vendég hallgatók pénzügyi jóváhagyásai',
            value: <TabContent view="guest" />,
        },
    ];

    return (
        <Page header={header(tenant || 'TAR') as HeaderProps}>
            <TabsCompleted tabContents={tabContents} />
        </Page>
    );
}
