import { FormikProps } from 'formik';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import { TBaseData } from './types';
import { achievementOptions } from '../utils';
import Questionnaire from './Questionnaire';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Checkbox } from '@silinfo/front-end-template';
import TextField from '@mui/material/TextField';
import { ICourseEventLecture } from '../../../../interface/ICourseEventLecture';
import { useCallback, useEffect, useState } from 'react';
import {
    getAchievedMinimumPoints,
    getAvaibleMaximumPoints,
    getCustomCoursePointPerEventPoints,
    getScoringTypeText,
} from './utils';
import { ILocation } from '../types';
import GoogleMapsAutocomplete from '../Events/GoogleMapsAutocomplete';
import Waitlist from './Waitlist';

export default function BaseDataFormTenant(props: FormikProps<TBaseData>) {
    const [eventLectures, _setEventLectures] = useState<ICourseEventLecture[]>([]);
    const [eventAll, _setEventAll] = useState<ICourseEventLecture[]>([]);
    const [avaibleMaximumPoints, setAvaibleMaximumPoints] = useState(0);
    const [achievedMinimumPoints, setAchievedMinimumPoints] = useState(0);
    const scoringType = '' + (props.values.scoringType || 'point');
    const text = getScoringTypeText(scoringType);
    const v = eventLectures.length * (+props.values.fixedCoursePointPerEventValPoint || 0);

    const setter = useCallback(
        (location: ILocation) => {
            if (location) {
                props.setFieldValue('externalLocationName', location);
            }
        },
        [props],
    );

    useEffect(() => {
        setAvaibleMaximumPoints(getAvaibleMaximumPoints(props.values, eventLectures, eventAll));
        setAchievedMinimumPoints(getAchievedMinimumPoints(props.values, eventLectures, eventAll));
    }, [eventLectures, eventAll, props.values]);

    return (
        <>
            {FieldGenerator({
                name: 'courseType',
                label: 'Kurzustípus',
                format: { xs: 12, sm: 6, md: 3 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'courseName',
                label: 'Kurzus megnevezése',
                format: { xs: 12, sm: 6, md: 5 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'courseNameEnglish',
                label: 'Kurzus megnevezése angol nyelven',
                format: { xs: 12, sm: 6, md: 4 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'organiser',
                label: 'Szervező',
                fieldType: 'base',
                type: 'multiselect',
                options: props.values.organiser,
                format: { xs: 12, sm: 3 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {props.values.otherOrganiser &&
                FieldGenerator({
                    name: 'otherOrganiser',
                    label: 'Egyéb szervező',
                    fieldType: 'base',
                    type: 'multiselect',
                    options: [],
                    format: { xs: 12, sm: 3 },
                    props: {
                        disabled: true,
                    },
                    ...props,
                })}
            {FieldGenerator({
                name: 'organiserCampus',
                label: 'Szervező campus',
                format: { xs: 12, sm: 6, md: 3 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'trainingProgram',
                label: 'Kapcsolódó képzési program',
                type: 'multiselect',
                fieldType: 'base',
                options: props.values.trainingProgram,
                format: { xs: 12, sm: 6, md: 3 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'semester',
                label: 'Szemeszter',
                format: { xs: 12, sm: 6 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'enrollmentStartDate',
                label: 'Kurzusfelvételi időszak kezdete',
                type: 'date',
                format: { xs: 12, sm: 3, md: 2 },
                props: {
                    disabled: true,
                    value: props.values?.enrollmentStartDate ? props.values.enrollmentStartDate.split(' ')[0] : '',
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'enrollmentEndDate',
                label: 'Kurzusfelvételi időszak vége',
                type: 'date',
                format: { xs: 12, sm: 3, md: 2 },
                props: {
                    disabled: true,
                    value: props.values?.enrollmentEndDate ? props.values.enrollmentEndDate.split(' ')[0] : '',
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'courseCode',
                label: 'Kurzuskód',
                format: { xs: 12, sm: 6, md: 2 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'courseManager',
                label: 'Kurzuskezelő',
                format: { xs: 12, sm: 6, md: 2 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'instructors',
                label: 'Oktató',
                format: { xs: 12, sm: 6, md: 2 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'guestInstructors',
                label: 'Vendégelőadó',
                format: { xs: 12, sm: 6, md: 2 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'achievementType',
                label: 'Teljesítési típus',
                type: 'select',
                options: achievementOptions,
                format: { xs: 12, sm: 6 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'courseCategory',
                label: 'Kurzuskategória',
                format: { xs: 12, sm: 6 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'tenants',
                label: 'Tenant',
                format: { xs: 12, sm: 6 },
                props: {
                    disabled: true,
                },
                ...props,
            })}
            {props.values.tenants.map((tenant) => {
                const maxAbsenceName = `headCounts.${tenant}.maxAbsence`;
                const minHeadCountName = `headCounts.${tenant}.minHeadCount`;
                const maxHeadCountName = `headCounts.${tenant}.maxHeadCount`;

                return (
                    <>
                        <Typography style={{ padding: '16px 0 0 16px', fontWeight: 700, fontSize: '20px' }}>
                            {tenant + ' létszám adatok'}
                        </Typography>
                        <Grid item xs={12} container spacing={2} alignItems="flex-start">
                            {FieldGenerator({
                                name: maxAbsenceName,
                                label: 'Maximum hiányzás',
                                format: { xs: 12, sm: 6, md: 3, lg: 2 },
                                props: {
                                    disabled: true,
                                    value: props.values.headCounts?.[tenant]?.maxAbsence || '',
                                },
                                ...props,
                            })}
                            {FieldGenerator({
                                fieldType: 'base',
                                name: minHeadCountName,
                                label: 'Minimum létszám',
                                props: {
                                    disabled: true,
                                    value: props.values.headCounts?.[tenant]?.minHeadCount || '',
                                },
                                format: { xs: 12, sm: 6, md: 3, lg: 2 },
                                ...props,
                            })}
                            {FieldGenerator({
                                name: maxHeadCountName,
                                label: 'Maximum létszám',
                                fieldType: 'base',
                                props: {
                                    disabled: true,
                                    value: props.values.headCounts?.[tenant]?.maxHeadCount || '',
                                },
                                format: { xs: 12, sm: 6, md: 3, lg: 2 },
                                ...props,
                            })}
                        </Grid>
                    </>
                );
            })}
            <Waitlist />
            <Questionnaire isTenant={true} />
            <Grid item xs={12}>
                <Typography variant="h6" style={{ marginTop: '8px' }}>
                    Helyszín adatok
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                <Checkbox
                    checkbox={{
                        disabled: true,
                        checked: props.values.external,
                    }}
                    label={'Külső helyszín'}
                />
            </Grid>
            {!props.values.external ? (
                <>
                    {FieldGenerator({
                        name: 'campus',
                        label: 'Campus',
                        format: { xs: 12, sm: 6 },
                        props: {
                            disabled: true,
                        },
                        ...props,
                    })}
                    {FieldGenerator({
                        ...{
                            name: 'building',
                            label: 'Épület',
                            props: {
                                disabled: true,
                            },
                        },
                        ...props,
                    })}
                    {FieldGenerator({
                        ...{
                            name: 'floor',
                            label: 'Szint',
                            props: {
                                disabled: true,
                            },
                        },
                        ...props,
                    })}
                    {FieldGenerator({
                        ...{
                            name: 'room',
                            label: 'Szoba',
                            props: {
                                disabled: true,
                            },
                        },
                        ...props,
                    })}
                </>
            ) : (
                <Grid item xs={12}>
                    <GoogleMapsAutocomplete
                        setter={setter}
                        disabled={true}
                        initialValue={{
                            ...(props.values.externalLocationName || {}),
                            description: (props.values.externalLocationName as Record<string, string>)?.location || '',
                        }}
                    />
                </Grid>
            )}
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Grid item container xs={12}>
                        <Grid item xs={12} md={4} lg={6}>
                            <Typography variant="h6" style={{ marginTop: '8px' }}>
                                Pontozási szabályok
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={8}
                            lg={6}
                            style={{ marginTop: '8px' }}
                            justifyContent="space-between"
                        >
                            <Grid item>
                                Elérendő minimum {text}: <b>{achievedMinimumPoints}</b>
                            </Grid>
                            <Grid item>
                                Elérhető maximum {text}: <b>{avaibleMaximumPoints}</b>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} style={{ minHeight: '60px' }}>
                    {FieldGenerator({
                        ...{
                            name: 'fixCoursePoint',
                            label: 'Fix kurzuspont',
                            type: 'checkbox',
                            format: { xs: 12, md: 4, lg: 6 },
                            props: {
                                disabled: true,
                            },
                        },
                        ...props,
                    })}
                    {props.values.fixCoursePoint == '1' &&
                        FieldGenerator({
                            ...{
                                name: 'fixCoursePointVal',
                                label: text.charAt(0).toUpperCase() + text.slice(1) + ' a kurzus teljesítéséért',
                                fieldType: 'base',
                                props: {
                                    disabled: true,
                                    inputProps: {
                                        maxLength: 4,
                                    },
                                },
                                format: { xs: 12, md: 8, lg: 6 },
                            },
                            ...props,
                        })}
                </Grid>
                <Grid item container xs={12} style={{ minHeight: '60px' }}>
                    {FieldGenerator({
                        ...{
                            name: 'customCoursePoint',
                            label: 'Egyéni elbírálású kurzuspont',
                            type: 'checkbox',
                            format: { xs: 6 },
                            props: {
                                disabled: true,
                            },
                        },
                        ...props,
                    })}
                    {props.values.customCoursePoint == '1' && (
                        <Grid item container xs={12} sm={6} spacing={2}>
                            {FieldGenerator({
                                ...{
                                    name: 'customCoursePointValMin',
                                    label: 'Min. ' + text,
                                    fieldType: 'base',
                                    props: {
                                        disabled: true,
                                        inputProps: {
                                            maxLength: 4,
                                        },
                                    },
                                    format: { xs: 6 },
                                },
                                ...props,
                            })}
                            {FieldGenerator({
                                ...{
                                    name: 'customCoursePointValMax',
                                    label: 'Max. ' + text,
                                    fieldType: 'base',
                                    props: {
                                        disabled: true,
                                    },
                                    format: { xs: 6 },
                                },
                                ...props,
                            })}
                        </Grid>
                    )}
                </Grid>
                <Grid item container xs={12} style={{ minHeight: '60px' }}>
                    {FieldGenerator({
                        ...{
                            name: 'fixedCoursePointPerEvent',
                            label: 'Eseményenként fixált pont',
                            type: 'checkbox',
                            format: { xs: 12, md: 4 },
                            props: {
                                disabled: true,
                            },
                        },
                        ...props,
                    })}
                    {props.values.fixedCoursePointPerEvent == '1' && (
                        <Grid item container xs={12} md={8} spacing={2} alignItems="center">
                            {FieldGenerator({
                                ...{
                                    name: 'fixedCoursePointPerEventValMin',
                                    label: 'Min. eseményen történő részvétel',
                                    props: {
                                        disabled: true,
                                    },
                                    format: { xs: 12, sm: 6, md: 4 },
                                },
                                ...props,
                            })}
                            {FieldGenerator({
                                ...{
                                    name: 'fixedCoursePointPerEventValPoint',
                                    label: 'Eseményenként járó ' + text,
                                    fieldType: 'base',
                                    props: {
                                        disabled: true,
                                    },
                                    format: { xs: 12, sm: 6, md: 3 },
                                },
                                ...props,
                            })}
                            <Grid item xs={6} md={3}>
                                <TextField value={eventAll.length} disabled label="Felvett események száma" />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <TextField value={isNaN(v) ? '' : v.toFixed(1)} disabled label="Összesen" />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container xs={12}>
                        {FieldGenerator({
                            ...{
                                name: 'customCoursePointPerEvent',
                                label: 'Eseményenként egyéni elbírálású pontozás',
                                type: 'checkbox',
                                props: {
                                    disabled: true,
                                },
                                format: { xs: 6 },
                            },
                            ...props,
                        })}
                        {props.values.customCoursePointPerEvent == '1' && (
                            <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <strong>{`${getCustomCoursePointPerEventPoints(
                                        props.values,
                                        eventAll,
                                    )} - ${getCustomCoursePointPerEventPoints(
                                        props.values,
                                        eventAll,
                                        true,
                                    )}`}</strong>{' '}
                                    {text} összesen eseményeknél
                                </div>
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
