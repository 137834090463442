import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { CancelButton, Link, Loading } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FieldGenerator } from '../../../../../../../components/Form/FieldGenerator';
import { RootState } from '../../../../../../../store';
import { clientEndPoints } from '../../../../../../../utils/clientEndPoints';
import { IBaseRequirements } from './BaseRequirements';

interface BaseForm {
    numberOfSemesters: number;
    mandatoryPointToFullCourse: number;
    minPointPerSemester: number;
}

export default function BaseForm(props: FormikProps<IBaseRequirements> & { readonly?: boolean }) {
    const { count } = useSelector((state: RootState) => state.loading);
    const { id, programId } = useParams();

    if (count) {
        return <Loading noHeight />;
    }

    return (
        <>
            {FieldGenerator({
                name: 'numberOfSemesters',
                label: 'Szemeszterek száma',
                format: { xs: 12, sm: 6, md: 6 },
                props: {
                    disabled: !!props.readonly,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'mandatoryPointToFullCourse',
                label: 'Szükséges összpontszám',
                format: { xs: 12, sm: 6, md: 6 },
                props: {
                    disabled: !!props.readonly,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'minPointPerSemester',
                label: 'Minimum pontszám szemeszterenként',
                format: { xs: 12, sm: 6, md: 6 },
                props: {
                    disabled: !!props.readonly,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'minPointCoTrainingCourses',
                label: 'Minimum pontszám (társképzés kurzusai)',
                format: { xs: 12, sm: 6, md: 6 },
                props: {
                    disabled: !!props.readonly,
                },
                ...props,
            })}
            <Grid item container spacing={2} justifyContent="flex-end">
                {!props.readonly && (
                    <Grid item>
                        <LoadingButton
                            loading={count > 0}
                            onClick={() => props.setFieldValue('redirect', 'edit')}
                            variant="contained"
                            type="submit"
                        >
                            Mentés
                        </LoadingButton>
                    </Grid>
                )}
                {!props.readonly && (
                    <Grid item>
                        <LoadingButton
                            loading={count > 0}
                            onClick={() =>
                                props.setFieldValue(
                                    'redirect',
                                    clientEndPoints.md_training_levels_programs_requirements_campuses
                                        .replace(':id', id ? id : '')
                                        .replace(':programId', programId ? programId : ''),
                                )
                            }
                            variant="contained"
                            type="submit"
                        >
                            Mentés és vissza
                        </LoadingButton>
                    </Grid>
                )}
                <Grid item>
                    <Link
                        to={clientEndPoints.md_training_levels_programs_requirements_campuses
                            .replace(':id', id ? id : '')
                            .replace(':programId', programId ? programId : '')}
                    >
                        <CancelButton>Mégse</CancelButton>
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}
