import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import '../CourseEnrollment.scss';
import { FilterProps } from '../types';
import Filter from './Filter';

export default function CustomToolbar({ info, setInfo }: FilterProps) {
    return (
        <GridToolbarContainer>
            <Filter info={info} setInfo={setInfo} />
        </GridToolbarContainer>
    );
}
