import { endpoints } from '../../utils/endPoints';
import instance from '../../api';

const coursesService = {
    semesterInfo: (courseId: number) =>
        instance.get(endpoints.courseManagement.courseEvent.semesterInfo.replace('{courseId}', courseId.toString())),
    get: (courseId: number) => instance.get(endpoints.courseManagement.courseList.main + '/' + courseId),
    asPrerequisite: (tenant: string, prerequisiteId: string, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseList.asPrerequisite
            .replace('{tenant}', tenant)
            .replace('{prerequisite}', prerequisiteId);

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
    listAllCoursesByTerm: (term: string) =>
        instance.get(endpoints.courseManagement.courseList.listAllCoursesByTerm + '?term=' + term),
};

export default coursesService;
