import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';

export const ValidityCell = (params: GridRenderCellParams) => {
    const { startDate, endDate } = params.row;
    const startDateValue = moment(startDate).toDate().getTime();
    const endDateValue = moment(endDate).toDate().getTime();
    const todayValue = new Date().getTime();

    if (startDateValue <= todayValue) {
        return !endDateValue || endDateValue >= todayValue ? (
            <Chip color="success" label="Érvényes" />
        ) : (
            <Chip color="warning" label="Archív" />
        );
    }

    return <Chip color="info" label="Jövőbeli" />;
};
