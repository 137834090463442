import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useMemo } from 'react';
import { theme } from '../../../../../utils/theme';
import { Paper } from '../../../../Requirements/Paper';
import { IScoringType } from '../../../../Requirements/ProgramLevel/Semesters';
import { ISemester } from '../types';
import { IScoringTypeInterval } from './../../../../Requirements/ProgramLevel/Semesters';
import Row from './Row';

interface ISums {
    completed: IScoringType;
    enrolled: IScoringTypeInterval;
    minToBeCompleted: number;
}

const getSumsOfSemesters = (semesters: ISemester[]): ISums => {
    const result: ISums = {
        completed: {
            point: 0,
            copper: 0,
        },
        enrolled: {
            point: {
                min: 0,
                max: 0,
            },
            copper: {
                min: 0,
                max: 0,
            },
        },
        minToBeCompleted: 0,
    };

    semesters.forEach((semester) => {
        result.completed.point += semester.completed.point;
        result.completed.copper += semester.completed.copper;
        result.enrolled.point.min += semester.enrolledCourses.point.min;
        result.enrolled.point.max += semester.enrolledCourses.point.max;
        result.enrolled.copper.min += semester.enrolledCourses.copper.min;
        result.enrolled.copper.max += semester.enrolledCourses.copper.max;
        result.minToBeCompleted += semester.minToBeCompleted;
    });

    return result;
};

export default function Table({ semesters }: { semesters: ISemester[] }) {
    const sums = useMemo(() => getSumsOfSemesters(semesters), [semesters]);

    return (
        <Grid item container xs={12} style={{ overflowX: 'auto' }}>
            <Grid
                item
                container
                xs={12}
                style={{ minWidth: '800px', overflowX: 'auto', paddingBottom: '8px' }}
                spacing={2}
            >
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={2} textAlign="center" alignSelf="center">
                        Teljesített
                    </Grid>
                    <Grid item xs={2} textAlign="center" alignSelf="center">
                        Felvett kurzusok
                    </Grid>
                    <Grid item xs={2} textAlign="center" alignSelf="center">
                        Minimum teljesítendő
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={5}>
                        <Paper
                            sx={{
                                padding: 1,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            Teljes képzés
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper>
                            <div>{sums.completed.point} pont</div>
                            <div>{sums.completed.copper} garas</div>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper>
                            <div>
                                {sums.enrolled.point.min === sums.enrolled.point.max
                                    ? sums.enrolled.point.min + ' pont'
                                    : sums.enrolled.point.min + ' - ' + sums.enrolled.point.max + ' pont'}
                            </div>
                            <div>
                                {sums.enrolled.copper.min === sums.enrolled.copper.max
                                    ? sums.enrolled.copper.min + ' garas'
                                    : sums.enrolled.copper.min + ' - ' + sums.enrolled.copper.max + ' garas'}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper>{sums.minToBeCompleted} pont</Paper>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider
                        sx={{
                            '&::before, &::after': {
                                borderColor: theme.palette.primary.main,
                            },
                            color: theme.palette.primary.main,
                        }}
                    >
                        Szemeszterre bontva
                    </Divider>
                </Grid>
                {semesters.map(Row)}
            </Grid>
        </Grid>
    );
}
