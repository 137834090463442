import { IInfo } from '../../../utils/Interfaces/IInfo';

export type LanguageExamType = 'general' | 'professional' | null;
export interface LangExamReportFilter {
    student: string;
    lang: string;
    type: LanguageExamType;
    category: string;
    level: string;
    validated: string;
    onlyActiveStudents: string;
    trainingLevel: string;
    trainingProgram: string;
}

export const initialLangExamReportFilterState: IInfo<LangExamReportFilter> = {
    filter: {},
    sort: {},
    page: 1,
    perpage: 25,
    metadata: {},
};
