import { Option } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';

export const SESSION_KEY = 'courseList';
export const header = (tenant: string | null) => {
    return {
        title: 'Kurzuslista',
        project: tenant || 'TAR',
        breadcrumbs: {
            masterData: 'Kurzuskezelés',
            [clientEndPoints.course_manamagement_course_list]: 'Kurzuslista',
        },
    };
};

export const courseCategoryOptions: Option[] = [
    { value: 'conventional', label: 'Konvencionális' },
    { value: 'unconventional', label: 'Nem konvencionális' },
];

export const courseManagerOptions: Option[] = [
    { value: 'manager1', label: 'Kurzuskezelő 1' },
    { value: 'manager2', label: 'Kurzuskezelő 2' },
];
export const instructorOptions: Option[] = [
    { value: '/instructors/1', label: 'Oktató1' },
    { value: '/instructors/2', label: 'Oktató2' },
];
export const achievementOptions: Option[] = [
    { value: 'achievementGrade', label: 'Osztályzat' },
    { value: 'achievementSufficient', label: 'Teljesített/Nem teljesített' },
];

export const eventTypeOptions: Option[] = [
    { value: 'lecture', label: 'Előadás' },
    { value: 'exam', label: 'Vizsga' },
    { value: 'deadline', label: 'Határidő' },
    { value: 'other', label: 'Egyéb' },
];

export function printDateTime(d: Date): string {
    return (
        d.getFullYear() +
        '. ' +
        (d.getMonth() + 1).toString().padStart(2, '0') +
        '. ' +
        d.getDate().toString().padStart(2, '0') +
        '. ' +
        d.getHours().toString().padStart(2, '0') +
        ':' +
        d.getMinutes().toString().padStart(2, '0')
    );
}
