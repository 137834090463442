import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { IForm } from '@silinfo/form-builder-2';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import questionnaireTemplateService from '../../../../services/selfEvalution/questionnaireTemplate';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { ITemplatesForm } from './types';

interface PreviewButtonProps {
    formBuilder: IForm;
}

export default function PreviewButton({ formBuilder }: PreviewButtonProps) {
    const { values } = useFormikContext<ITemplatesForm>();
    const [loading, setLoading] = useState(false);

    const handlePreviewClick = useCallback(() => {
        setLoading(true);
        questionnaireTemplateService
            .getPreview({ ...values, content: formBuilder })
            .then((res) => {
                window.open(
                    clientEndPoints.admin_questionnaire_templates_preview.replace(':id', res.data.session),
                    '_blank',
                );
            })
            .finally(() => setLoading(false));
    }, [formBuilder, values]);

    return (
        <Grid item>
            <LoadingButton variant="contained" color="secondary" onClick={handlePreviewClick} loading={loading}>
                Előnézet
            </LoadingButton>
        </Grid>
    );
}
