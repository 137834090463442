import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import clsx from 'clsx';
import styles from './RefreshButton.module.scss';
import instance from '../../../../../../api';
import { endpoints } from '../../../../../../utils/endPoints';
import { useParams } from 'react-router-dom';

interface RefreshButtonProps {
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RefreshButton({ setRefresh }: RefreshButtonProps) {
    const [loading, setLoading] = useState(false);
    const { id } = useParams<{ id: string }>();

    const handleClick = () => {
        setLoading(true);
        instance
            .get(endpoints.masterData.students.trainingProgramCompletion.recalc(id || ''))
            .then(() => setRefresh((prev) => !prev))
            .finally(() => setLoading(false));
    };

    return (
        <Grid item container justifyContent="flex-end" xs={12}>
            <Tooltip title="Adatok újraszámolása" className="borderRadius">
                <IconButton
                    onClick={handleClick}
                    color="primary"
                    disabled={loading}
                    className={'noTransition ' + clsx(loading && styles.spinning)}
                >
                    <RefreshIcon />
                </IconButton>
            </Tooltip>
        </Grid>
    );
}
