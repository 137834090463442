import Grid, { GridProps } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const Data = ({ label, children, format }: { label: string; children: React.ReactNode; format?: GridProps }) => (
    <Grid item xs={12} sm={6} md={4} {...format} overflow={'hidden'}>
        <Typography color="gray">{label}</Typography>
        <Tooltip title={children}>
            <Typography>
                <strong>{children}</strong>
            </Typography>
        </Tooltip>
    </Grid>
);

export default Data;
