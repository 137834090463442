import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Children, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { endpoints } from '../../../utils/endPoints';
import Header from './Header';
import Row from './Row';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import instance from '../../../api';

export interface IScoringType {
    copper: number;
    point: number;
}

export interface IInterval {
    min: number;
    max: number;
}

export interface IScoringTypeInterval {
    copper: IInterval;
    point: IInterval;
}

export interface ISemester {
    id: number;
    program?: { id: number; label: string };
    name: string;
    completed: IScoringType;
    enrolledCourses: IScoringTypeInterval;
    minToBeCompleted: number;
}

export default function Semesters({ selectedProgram }: { selectedProgram: null | number }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ISemester[]>([]);

    useEffect(() => {
        setLoading(true);
        setData([]);
        instance
            .get(endpoints.students.requirements.semesters + (selectedProgram ? '?program=' + selectedProgram : ''))
            .then((res) => {
                setData(res.data?.data);
                setLoading(false);
            });
    }, [selectedProgram, setData, setLoading]);

    const [_, setSearchParams] = useSearchParams();
    let prevProgram = 0;
    const matches = useMediaQuery('(min-width:960px)');

    const NoData = loading ? (
        <Grid item xs={12}>
            <Skeleton style={{ height: '100%' }} />
        </Grid>
    ) : (
        <Typography variant="h6" color="textPrimary">
            <i>Nincsenek megjelenítendő szemeszterek</i>
        </Typography>
    );

    return (
        <Grid
            item
            xs={12}
            container
            alignItems="center"
            alignSelf="flex-start"
            style={matches ? { overflow: 'auto' } : {}}
        >
            {matches && <Header />}
            {data.length > 0 ? (
                <Grid
                    item
                    container
                    xs={12}
                    style={{ height: '100%', minWidth: matches ? '500px' : 0, paddingBottom: '8px' }}
                    spacing={1}
                >
                    {Children.toArray(
                        data.map((elem) => {
                            const listProgram = prevProgram !== elem?.program?.id;
                            prevProgram = elem.program?.id || 0;
                            return (
                                <>
                                    {matches && listProgram && (
                                        <Grid item xs={12}>
                                            <Divider>{elem.program?.label}</Divider>
                                        </Grid>
                                    )}
                                    <Row
                                        key={elem.id}
                                        {...elem}
                                        setSearchParams={setSearchParams}
                                        listProgram={elem.program?.label || ''}
                                    />
                                </>
                            );
                        }),
                    )}
                </Grid>
            ) : (
                NoData
            )}
        </Grid>
    );
}
