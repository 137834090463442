import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableButton, TableEditButton } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { handleAdminPreviewTemplateGroupOpen } from '../../../utils/AppConst';
import EndpointProvider from '../../../components/EndpointProvider';
import Visibility from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

type GroupBtnCellRendererProps = {
    params: GridRenderCellParams;
};

export function GroupBtnCellRenderer({ params }: GroupBtnCellRendererProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    const used = params.row.status == 'used' ? true : false;
    const handlePreviewClick = handleAdminPreviewTemplateGroupOpen(params.row.id);

    return (
        <>
            {!user.archive && (
                <>
                    <EndpointProvider
                        endpoints={[
                            'self_evaluation_edit',
                            'admin_questionnaire_templates_edit',
                            'admin_questionnaire_groups_edit',
                        ]}
                    >
                        <Link to={clientEndPoints.admin_questionnaire_groups_edit.replace(':id', '' + params.id)}>
                            <TableEditButton
                                disabled={used}
                                tooltip="Szerkesztés"
                                sx={{ marginRight: '5px' }}
                                color="warning"
                            />
                        </Link>
                    </EndpointProvider>
                </>
            )}
            <EndpointProvider
                endpoints={[
                    'admin_questionnaire_templates_preview',
                    'admin_questionnaire_template_groups_preview',
                    'self_evaluation_edit',
                ]}
            >
                <TableButton onClick={handlePreviewClick} sx={{ marginRight: '5px' }} tooltip={'Előnézet'}>
                    <Visibility />
                </TableButton>
            </EndpointProvider>
        </>
    );
}
