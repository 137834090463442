import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { translations } from '../../../../Requirements/SemesterLevel/Table/utils';
import { ICourse } from '../types';

export default function SemesterInfo({ courses }: { courses: ICourse[] }) {
    return (
        <Table style={{ border: '1px solid #ddd' }}>
            <TableHead>
                <TableRow>
                    <TableCell align="center">Kurzuskód</TableCell>
                    <TableCell align="center">Kurzus neve</TableCell>
                    <TableCell align="center">Érték</TableCell>
                    <TableCell align="center">Értékelés</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {courses.map((course) => (
                    <TableRow key={course.courseCode}>
                        <TableCell align="center" style={{ width: '150px' }}>
                            {course.courseCode}
                        </TableCell>
                        <TableCell style={{ width: '437px' }}>{course.courseName}</TableCell>
                        <TableCell align="center" style={{ width: '100px' }}>
                            {course.point} {course.scoringType === 'copper' ? ' garas' : ' pont'}
                        </TableCell>
                        <TableCell align="center" style={{ width: '133px' }}>
                            {course.evaluation in translations ? translations[course.evaluation] : course.evaluation}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
