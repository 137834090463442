import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import { IStudentRequestData } from '../../../utils/Interfaces/IStudentRequestApproval';
import { stayAbroadTypeOptions } from '../../MasterData/General/Students/Form/options';
import { CountryContext } from './Approvals';

export interface IStatusChangeInfoProps {
    data: IStudentRequestData;
}

export default function ForeignScholarshipChangeInfo(props: IStatusChangeInfoProps) {
    const { data } = props;
    const countries = useContext(CountryContext);
    const country = countries.find((e) => e.value === data.hostCountry);
    return (
        <>
            <Grid item xs={12}>
                <b>Külföldi tartózkodás kezdete: </b> {data.stayAbroadStart}
            </Grid>
            <Grid item xs={12}>
                <b>Külföldi tartózkodás vége: </b> {data.stayAbroadEnd}
            </Grid>
            <Grid item xs={12}>
                <b>Külföldi tartózkodás típusa: </b>{' '}
                {stayAbroadTypeOptions.find((elem) => elem.value === data?.stayAbroadType)?.label}
            </Grid>
            <Grid item xs={12}>
                <b>Fogadó intézmény teljes neve: </b> {data.hostName}
            </Grid>
            <Grid item xs={12}>
                <b>Fogadó intézmény országa: </b> {country?.label}
            </Grid>
            <Grid item xs={12}>
                <b>Fogadó intézmény városa: </b> {data.hostCity}
            </Grid>
            <Grid item xs={12}>
                <b>Fogadó intézménynél végzett szak/tevékenység megnevezése: </b> {data.hostMajor}
            </Grid>
        </>
    );
}
