import { TableButton } from '@silinfo/front-end-template';
import financeFinancialApprovalsService from '../../../services/masterData/finances/financialApprovals/financialApprovals';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { create, remove } from '../../../store/notification';
import { useState } from 'react';
import { downloadPdfFile } from '../../../utils/AppConst';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

interface DownloadInvoiceButtonProps {
    invoiceFile: string;
    updatedFileName?: string;
    student?: boolean;
}

function DownloadInvoiceButton({ invoiceFile, student, updatedFileName }: DownloadInvoiceButtonProps) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const useStyles = makeStyles(() => ({
        eyeIcon: {
            color: 'white',
            backgroundColor: '#ffc83d;',
            '&:hover': {
                backgroundColor: 'white',
                color: '#ffc83d',
            },
        },
    }));

    const classes = useStyles();

    return isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} />
        </div>
    ) : (
        <TableButton
            style={{ marginRight: '5px' }}
            tooltip="Számla megtekintése"
            className={classes.eyeIcon}
            onClick={() => {
                setIsLoading(true);
                const functionName = student ? 'downloadInvoiceStudent' : 'downloadInvoice';
                financeFinancialApprovalsService[functionName](invoiceFile)
                    .then((response: AxiosResponse) => {
                        downloadPdfFile(
                            response.data,
                            updatedFileName ?? invoiceFile.substring(invoiceFile.indexOf('/') + 1),
                        );
                        setIsLoading(false);
                        dispatch(remove());
                        dispatch(create({ type: 'success', message: `A fájl sikeresen letöltve!` }));
                    })
                    .catch(() => {
                        setIsLoading(false);
                        dispatch(remove());
                        dispatch(create({ type: 'error', message: `Hiba történt a fájl letöltése során!` }));
                    });
            }}
        >
            <ReceiptLongIcon />
        </TableButton>
    );
}

export default DownloadInvoiceButton;
