import { Outlet } from 'react-router-dom';
import OffLayout from '../../components/OffLayout';
import AdmissionLandingComponent from '../../pages/Admission/Admissions/AdmissionLandingComponent';

export const admissionLandingPage = {
    path: '/',
    element: (
        <OffLayout hideLogo>
            <Outlet />
        </OffLayout>
    ),
    children: [
        {
            path: 'felveteli',
            element: <AdmissionLandingComponent />,
            name: 'admission_landing_page',
        },
    ],
};
