import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { FormikListPage, Header, Loading, TextField, useFetch } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import API from '../../../api';
import { defaultTheme, Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import { header, breadcrumbs, tableProps } from './utils';
import { ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import SettingsModal from './SettingsModal';
import IconButton from '@mui/material/IconButton';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import Button from '@mui/material/Button';
import { theme } from '../../../utils/theme';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

const Input = (params: AutocompleteRenderInputParams) => <TextField {...params} label="Szemeszter" />;

type SemesterOption = Option<number> & { active?: boolean };

export default function Administration() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [semester, setSemester] = useState<SemesterOption | null>(null);
    const [refresh, setRefresh] = useState(false);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const { data: semesterOptions, loading: semestersLoading } = useFetch<SemesterOption[]>(
        endpoints.admission.administration.semesterOptions,
        '',
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const refreshing = () => setRefresh((prev) => !prev);

    useEffect(() => {
        if (semestersLoading || semesterOptions.length < 1) return;
        const activeSemester = semesterOptions.find((item) => item.active);
        if (activeSemester) setSemester(activeSemester);
    }, [semestersLoading, semesterOptions]);

    const filter = useCallback(
        (form: Record<string, unknown>) => {
            if (!semester) {
                return Promise.resolve([] as unknown as AxiosResponse);
            }

            return API.get(
                endpoints.admission.administration.list.replace('{semesterId}', semester.value as unknown as string),
                { params: form },
            );
        },
        [semester],
    );

    const handleSemesterChange = useCallback(
        (_: unknown, value: Option<number> | null) => {
            setSemester(value);
            refreshing();
        },
        [setSemester],
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...headerWithTenant, breadcrumbs }} />
            </Grid>
            <Grid item container spacing={1} xs={12}>
                {semestersLoading ? (
                    <Loading noHeight />
                ) : (
                    <>
                        <Grid item xs={6} md={9}>
                            <Autocomplete
                                options={semesterOptions}
                                value={semester}
                                onChange={handleSemesterChange}
                                size="small"
                                renderInput={Input}
                            />
                        </Grid>
                        <Grid item container xs={6} md={3} alignItems="center" justifyContent="flex-end">
                            {semester && !user.archive ? (
                                <>
                                    <Button
                                        startIcon={<SettingsOutlined sx={{ color: theme.palette.success.main }} />}
                                        onClick={handleModalOpen}
                                        sx={{
                                            color: theme.palette.success.main,
                                            border: '1px solid #35A899',
                                            minHeight: 40,
                                            marginTop: '0.25rem',
                                        }}
                                    >
                                        Általános felvételi adatok beállítása
                                    </Button>
                                </>
                            ) : (
                                <IconButton disabled>
                                    <SettingsOutlined />
                                </IconButton>
                            )}
                            {/*<Grid item>  <RefreshButton setRefresh={setRefresh} semester={semester} /> </Grid>*/}
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                {semester ? (
                    <FormikListPage
                        {...tableProps(refresh, semester.value, refreshing, user.archive, tenant)}
                        service={{ filter }}
                    />
                ) : (
                    <ThemeProvider theme={defaultTheme}>
                        <Alert severity="info">Kérjük, válassz szemesztert az adatok megtekintéséhez</Alert>
                    </ThemeProvider>
                )}
            </Grid>
            {semester && <SettingsModal open={isModalOpen} onClose={handleModalClose} semester={semester} />}
        </Grid>
    );
}
