import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// eslint-disable-next-line regex/invalid-warn
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Accordion } from '@silinfo/front-end-template';
import { Children } from 'react';
import { theme } from '../../../../../utils/theme';
import { IHoliday } from './../../../../../services/masterData/semesters';
import AddHoliday from './AddHoliday';
import DeleteHoliday from './DeleteHoliday';
import EditHoliday, { EditProps } from './EditHoliday';
import moment from 'moment';

const OperationCellRenderer = ({
    editProps,
    row,
    readOnly,
}: {
    editProps: Omit<EditProps, 'holidayToEdit'>;
    row: IHoliday;
    readOnly: boolean;
}) => {
    return !readOnly ? (
        <Grid container spacing={1}>
            <Grid item>
                <EditHoliday {...editProps} holidayToEdit={row} />
            </Grid>
            <Grid item>
                <DeleteHoliday {...editProps} holidayToEdit={row} />
            </Grid>
        </Grid>
    ) : (
        <></>
    );
};

export default function Holidays({
    rows,
    editProps,
    readOnly,
}: {
    rows: IHoliday[];
    editProps: Omit<EditProps, 'holidayToEdit'>;
    readOnly: boolean;
}) {
    const columns: GridColDef[] = [
        { headerName: 'Megnevezés', field: 'name' },
        {
            headerName: 'Dátum',
            field: 'date',
            valueGetter: (params: GridValueGetterParams<string, IHoliday>) =>
                moment(params.row.startDate).toDate().toLocaleDateString() +
                ' - ' +
                moment(params.row.endDate).toDate().toLocaleDateString(),
        },
    ];

    return (
        <Accordion title="Szünetek">
            <Grid container spacing={2}>
                <Grid item container xs={12} justifyContent="flex-end">
                    {!readOnly ? (
                        <Grid item>
                            <AddHoliday
                                setSemesterForm={editProps.setSemesterForm}
                                setFieldValue={editProps.setFieldValue}
                                holidays={editProps.holidays || []}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {rows.length > 0 ? (
                        <Table>
                            <TableHead sx={{ backgroundColor: theme.palette.secondary.main }}>
                                <TableRow>
                                    {Children.toArray(
                                        Object.values(columns).map((value) => (
                                            <TableCell
                                                key={value.field}
                                                sx={{ color: theme.palette.secondary.contrastText }}
                                            >
                                                {value.headerName}
                                            </TableCell>
                                        )),
                                    )}
                                    <TableCell sx={{ color: theme.palette.secondary.contrastText }}>
                                        Műveletek
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Children.toArray(
                                    rows.map((value) => (
                                        <TableRow key={value.id}>
                                            <TableCell>{value.name}</TableCell>
                                            <TableCell>
                                                {moment(value.startDate).toDate().toLocaleDateString()} -{' '}
                                                {moment(value.endDate).toDate().toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>
                                                <OperationCellRenderer
                                                    editProps={editProps}
                                                    row={value}
                                                    readOnly={readOnly}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )),
                                )}
                            </TableBody>
                        </Table>
                    ) : (
                        'Nincs megjeleníthető adat'
                    )}
                </Grid>
            </Grid>
        </Accordion>
    );
}
