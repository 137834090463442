import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import Form from '../../../components/Form/Form';
import { semesterStatusOptions } from '../../../pages/MasterData/General/Students/Form/options';
import studentRequestService from '../../../services/studentServices/studentRequestService';
import { IForm } from '../../../utils/Interfaces/interfaces';
import SemesterStatusDialogForm from './SemesterStatusDialogForm';

export type SemesterStatus = 'active' | 'passive' | 'foreign_scholarship';
export interface ActivePassiveSemesterStatusForm extends IForm {
    actualStatus?: SemesterStatus;
    requestedStatus?: SemesterStatus;
    reason?: string;
}

export interface ForeignScholarshipSemesterStatusForm extends ActivePassiveSemesterStatusForm {
    stayAbroadStart?: string;
    stayAbroadEnd?: string;
    stayAbroadType?: string | string[];
    hostName?: string;
    hostCountry?: string;
    hostCity?: string;
    hostMajor?: string;
}

const SemesterStatusDialog = ({
    actualStatus,
    enabled,
    readOnly = false,
}: {
    actualStatus: SemesterStatus;
    enabled: boolean;
    readOnly?: boolean;
}) => {
    const [open, setOpen] = useState(false);
    const [localEnabled, setLocalEnabled] = useState(enabled);
    const [buttonLoading, setButtonLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const initialValues: ForeignScholarshipSemesterStatusForm = {
        requestedStatus: semesterStatusOptions.find(({ value }) => value !== actualStatus)?.value || actualStatus,
        reason: '',
        actualStatus,
        stayAbroadStart: '',
        stayAbroadEnd: '',
        stayAbroadType: '',
        hostName: '',
        hostCountry: '',
        hostCity: '',
        hostMajor: '',
    };
    const handleSubmit = (form: ActivePassiveSemesterStatusForm | ForeignScholarshipSemesterStatusForm) => {
        setButtonLoading(true);
        return studentRequestService
            .requestChangeSemesterStatus(form)
            .then(() => {
                setLocalEnabled(false);
                setOpen(false);
            })
            .finally(() => setButtonLoading(false));
    };

    return (
        <>
            {!readOnly ? (
                <>
                    {localEnabled ? (
                        <Tooltip title="Státusz változtatás">
                            <IconButton onClick={handleOpen} color="primary">
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Már van azonos típusú nyitott kérvényed">
                            <IconButton style={{ color: 'grey' }}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ) : (
                <></>
            )}

            <Dialog open={open} onClose={handleClose}>
                <Form fields={[]} onSubmit={handleSubmit} initialValues={initialValues} hideButtons>
                    {(formikProps) => (
                        <SemesterStatusDialogForm
                            actualStatus={actualStatus}
                            handleClose={handleClose}
                            readOnly={readOnly}
                            buttonLoading={buttonLoading}
                            {...formikProps}
                        />
                    )}
                </Form>
            </Dialog>
        </>
    );
};

export default SemesterStatusDialog;
