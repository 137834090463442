import Universities from '../../../pages/MasterData/General/Universities';
import UniversityForm from '../../../pages/MasterData/General/Universities/UniversityForm';

export const universites = {
    path: 'universities',
    children: [
        {
            path: '',
            element: <Universities />,
            name: 'md_general_universities_list',
        },
        {
            path: 'new',
            element: <UniversityForm />,
            name: 'md_general_universities_create',
        },
        {
            path: ':id/edit',
            element: <UniversityForm />,
            name: 'md_general_universities_edit',
        },
        {
            path: ':id/show',
            element: <UniversityForm readOnly={true} />,
            name: 'md_general_universities_show',
        },
    ],
};
