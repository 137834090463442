import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { IPrivacyStatement, IUser } from '../utils/Interfaces/interfaces';
import { LayoutType } from '../utils/useSwitchCheck';
import * as Sentry from '@sentry/browser';

const cookies = new Cookies();

interface IAuthState {
    user: IUser;
    activePrivacyStatement?: IPrivacyStatement;
    layoutType: LayoutType;
    unseenNotifications: number;
}

const initialState: IAuthState = {
    layoutType: cookies.get('layout_type') || '',
    unseenNotifications: 0,
    user: {
        id: 0,
        email: '',
        firstName: '',
        lastName: '',
        lang: 'en',
        disabled: false,
        acceptedPrivacyStatement: 0,
        roles: [],
        archive: false,
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state: IAuthState, action: PayloadAction<IUser>) {
            Sentry.setUser({
                id: '' + action.payload.id,
                email: action.payload.email,
                roles: action.payload.roles,
            });
            state.user = action.payload;
        },
        setActivePrivacyStatement(state: IAuthState, action: PayloadAction<IPrivacyStatement>) {
            state.activePrivacyStatement = action.payload;
        },
        setLayoutTypeRedux(state: IAuthState, action: PayloadAction<LayoutType>) {
            cookies.set('layout_type', action.payload, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
            state.layoutType = action.payload;
        },
        setUnseenNotificationsNumber(state: IAuthState, action: PayloadAction<number>) {
            state.unseenNotifications = action.payload;
        },
    },
});

export const { setUser, setActivePrivacyStatement, setLayoutTypeRedux, setUnseenNotificationsNumber } =
    authSlice.actions;
export default authSlice.reducer;
