import { endpoints } from '../../../../utils/endPoints';
import { crudTemplate } from '../../../templates';
import instance from '../../../../api';
import { fixDateRangeFields, Option } from '../../../../utils/AppConst';
import API from '../../../../api';

export interface ICoursePrerequisite {
    id: number;
    courseName: string;
    courseCode: string;
    courseReferenceCode: string;
}

export interface ICoursePrerequisiteForm extends Record<string, string | Option> {
    course: string;
    courseReferenceCode: string;
    coursePrerequisite: string | Option;
}

const coursePrerequisitesService = {
    ...crudTemplate<ICoursePrerequisiteForm>(endpoints.courseManagement.prerequisites.main),
    create: (form: ICoursePrerequisiteForm, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.prerequisites.main;

        if (courseTenant) {
            return API.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return API.post(endpoint, form);
        }
    },
    filterByCourse: (form: ICoursePrerequisiteForm, id: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.prerequisites.prerequisitesByCourse.replace(
            '{courseId}',
            id.toString(),
        );
        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant, {
                params: fixDateRangeFields(form),
            });
        } else {
            return instance.get(endpoint, {
                params: fixDateRangeFields(form),
            });
        }
    },
    listByCourseRefCode: (term: string, courseTenant: string | null, tenant?: string, courseId?: number) => {
        const endpoint =
            endpoints.courseManagement.prerequisites.listByCourseRefCode +
            '?courseRefCode=' +
            term +
            '&tenant=' +
            tenant +
            '&courseId=' +
            courseId;

        if (courseTenant) {
            return instance.get(endpoint + '&courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
    delete: (courseId: number, preRequisiteId: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.prerequisites.delete
            .replace('{courseId}', courseId?.toString())
            .replace('{preRequisiteId}', preRequisiteId?.toString());

        if (courseTenant) {
            return API.delete(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return API.delete(endpoint);
        }
    },
};

export default coursePrerequisitesService;
