import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useState } from 'react';
import clsx from 'clsx';
import styles from './RefreshButton.module.scss';
import questionnaireProgramService from '../../../../services/selfEvalution/questionnaireProgram';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';

interface RefreshButtonProps {
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    semester: Option<number> | null;
}

export default function RefreshButton({ setRefresh, semester }: RefreshButtonProps) {
    const [loading, setLoading] = useState(false);

    const handleClick = useCallback(() => {
        if (semester) {
            setLoading(true);
            questionnaireProgramService
                .refreshProgramsForSemester(semester.value as unknown as string)
                .then(() => setRefresh((prev) => !prev))
                .finally(() => setLoading(false));
        }
    }, [semester, setRefresh]);

    return (
        <Grid item container justifyContent="flex-end" xs={12}>
            <Tooltip title="Adatok frissítése" className="borderRadius">
                <IconButton
                    onClick={handleClick}
                    color="primary"
                    disabled={loading || !semester}
                    className={'noTransition ' + clsx(loading && styles.spinning)}
                >
                    <RefreshIcon />
                </IconButton>
            </Tooltip>
        </Grid>
    );
}
