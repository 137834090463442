import instance from '../../api';
import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const courseEventService = {
    filter: (courseId: number, courseTenant: string | null, form?: IForm) => {
        const endpoint = endpoints.courseManagement.courseEvent.list.replace('{courseId}', courseId.toString());

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant, {
                params: form,
            });
        } else {
            return instance.get(endpoint, {
                params: form,
            });
        }
    },
    create: (courseId: number, form: IForm, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseEvent.create.replace('{courseId}', courseId.toString());

        if (courseTenant) {
            return API.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return API.post(endpoint, form);
        }
    },
    get: (courseId: number, id: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseEvent.get
            .replace('{courseId}', courseId.toString())
            .replace('{id}', id.toString());

        if (courseTenant) {
            return instance.get(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return instance.get(endpoint);
        }
    },
    update: (courseId: number, id: number, form: IForm, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseEvent.update
            .replace('{courseId}', courseId.toString())
            .replace('{id}', id.toString());

        if (courseTenant) {
            return API.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return API.post(endpoint, form);
        }
    },
    delete: (courseId: number, id: number, courseTenant: string | null) => {
        const endpoint = endpoints.courseManagement.courseEvent.delete
            .replace('{courseId}', courseId.toString())
            .replace('{id}', id.toString());

        if (courseTenant) {
            return API.delete(endpoint + '?courseTenant=' + courseTenant);
        } else {
            return API.delete(endpoint);
        }
    },
    remove: (courseId: number, id: number) =>
        API.delete(
            endpoints.courseManagement.courseEvent.remove
                .replace('{courseId}', courseId.toString())
                .replace('{eventId}', id.toString()),
        ),
    getRoomTaken: (form?: IForm) =>
        instance.get(endpoints.courseManagement.courseEvent.getRoomTaken, {
            params: form,
        }),
};

export default courseEventService;
