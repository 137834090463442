import { Accordion, Loading, Page } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../../../components/Form/Form';
import administrationFeeValuesService from '../../../../../services/masterData/finances/administrationFees/administrationFeeValues/administrationFeeValues';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import AddFields from './AddFields';
import EditFields from './EditFields';
import administrationFeesService from '../../../../../services/masterData/finances/administrationFees/administrationFees';
import { useTenant } from '../../../../../components/TenantContext';

export interface ISemesterFee {
    amount: string;
    semester: string;
    financeAdministrationFee: string;
}

const initialValues: ISemesterFee = { amount: '', semester: '', financeAdministrationFee: '0' };

export default function SemesterFeeForm() {
    const { feeId, feeValId } = useParams() as { feeId: string; feeValId: string };
    const [semesterOptions, setSemesterOptions] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [feeName, setFeeName] = useState('');
    const [valName, setValName] = useState('');
    const tenant = useTenant();

    useEffect(() => {
        administrationFeesService.get(feeId).then((res) => setFeeName(res.data.type));
        if (!feeValId) {
            setLoading(true);
            administrationFeeValuesService
                .semesters(feeId)
                .then((response) => setSemesterOptions(response.data))
                .finally(() => setLoading(false));
        } else {
            administrationFeeValuesService.get(feeValId).then((res) => setValName(res.data.semester.name));
        }
    }, [feeId, feeValId]);

    const header = {
        project: tenant || 'TAR',
        title: 'Adminisztrációs díjak szemeszterenként',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_finance]: 'Pénzügy',
            [clientEndPoints.md_finance_administration_fees_list]: feeName + ' díj',
            [clientEndPoints.md_finance_administration_fees_semesters_list.replace(':feeId', feeId)]:
                'Adminisztrációs díjak szemeszterenként',
            form: feeValId ? valName + ' díj szerkesztése' : 'Adminisztrációs díjak hozzáadása szemeszterenként ',
        },
    };

    const getValue = useCallback(async (): Promise<AxiosResponse<ISemesterFee>> => {
        setLoading(true);
        const { data, ...rest } = await administrationFeeValuesService.get(feeValId || 0);
        const response = await administrationFeeValuesService.semestersEdit(feeId, data.semester.id);
        setSemesterOptions(response.data);
        setLoading(false);
        return {
            ...rest,
            data: {
                semester: data.semester.id,
                amount: data.amount,
                financeAdministrationFee: feeId,
            },
        };
    }, [feeId, feeValId]);

    const submitTransform = useCallback(
        (values: ISemesterFee) => {
            return {
                ...values,
                financeAdministrationFee: feeId,
            };
        },
        [feeId],
    );

    const Fields = (props: FormikProps<ISemesterFee>) =>
        feeValId ? EditFields(props, semesterOptions) : AddFields(props, semesterOptions);

    return (
        <Page header={header}>
            <Accordion title={feeValId ? 'Szerkesztés' : 'Hozzáadás'}>
                <Form
                    urlParam={'feeValId'}
                    hideButtons={semesterOptions.length === 0}
                    fields={[]}
                    onSubmit={(form: ISemesterFee) =>
                        feeValId
                            ? administrationFeeValuesService.updateValue(feeValId, form)
                            : administrationFeeValuesService.create(form)
                    }
                    initialValues={{ ...initialValues, financeAdministrationFee: feeValId ? feeId : '0' }}
                    getterFunction={feeValId ? getValue : undefined}
                    submitTransformData={submitTransform}
                    back={clientEndPoints.md_finance_administration_fees_semesters_list.replace(
                        ':feeId',
                        feeId.toString(),
                    )}
                >
                    {(props) => (loading ? <Loading noHeight /> : <Fields {...props} />)}
                </Form>
            </Accordion>
        </Page>
    );
}
