import ContentCopy from '@mui/icons-material/ContentCopy';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Accordion, CancelButton, Link, Page, SubmitButton, TextField } from '@silinfo/front-end-template';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Field, Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Address from '../../../../../components/Form/Address';
import MCCLoading from '../../../../../components/MCCLoading';
import buildingsService, { IBuildingsForm, initialForm } from '../../../../../services/masterData/buildings';
import campusesService from '../../../../../services/masterData/campuses';
import { create } from '../../../../../store/notification';
import { transformApiErrors } from '../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { IForm } from '../../../../../utils/Interfaces/interfaces';
import { useTenant } from '../../../../../components/TenantContext';

const FormSchema = Yup.object().shape({
    name: Yup.string()
        .min(1, 'Nem lehet üres')
        .max(128, 'Túl hosszú! A maximumálisan megadható karakterek száma 128.')
        .required('Kötelező megadni'),
    address: Yup.object({
        city: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
        houseNumber: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
        postCode: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
        streetName: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
        streetType: Yup.string().max(128, 'A megengedett karakterek száma maximum 128'),
    }),
});

//If the form is readonly, the inputs are disabled and the submit button is hidden.

export default function BuildingsForm({ readOnly }: { readOnly?: boolean }) {
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [campus, setCampus] = useState<IForm>({});
    const { campusId, buildingId } = useParams();
    const dispatch = useDispatch();
    const [form, setForm] = useState<IBuildingsForm>(initialForm);
    const tenant = useTenant();

    const URL = clientEndPoints.md_general_campuses_buildings_list.replace(':campusId', campusId?.toString() ?? '');

    useEffect(() => {
        setPageLoading(true);

        axios
            .all([
                ...(buildingId
                    ? [
                          buildingsService.get(buildingId).then((response: AxiosResponse) => {
                              const data = response.data;
                              setForm((prevState) => ({
                                  ...prevState,
                                  campus: data.campus['@id'],
                                  campusName: data.campus.name,
                                  name: data.name,
                                  address: data.address,
                              }));
                              setCampus({
                                  postCode: data?.campus?.address?.postCode,
                                  city: data?.campus?.address?.city,
                                  streetType: data?.campus?.address?.streetType,
                                  streetName: data?.campus?.address?.streetName,
                                  houseNumber: data?.campus?.address?.houseNumber,
                              });
                          }),
                      ]
                    : [
                          campusesService.get(campusId as string).then((response) => {
                              const data = response.data;
                              setForm((prevState) => ({
                                  ...prevState,
                                  campus: data['@id'],
                                  campusName: data.name,
                                  name: '',
                                  address: {
                                      postCode: '',
                                      city: '',
                                      streetType: '',
                                      streetName: '',
                                      houseNumber: '',
                                  },
                              }));

                              setCampus({
                                  postCode: data?.address?.postCode,
                                  city: data?.address?.city,
                                  streetType: data?.address?.streetType,
                                  streetName: data?.address?.streetName,
                                  houseNumber: data?.address?.houseNumber,
                              });
                          }),
                      ]),
            ])
            .catch(() =>
                dispatch(
                    create({
                        type: 'error',
                        message: 'Sikertelen betöltés',
                    }),
                ),
            )
            .finally(() => setPageLoading(false));
    }, [buildingId, campusId, dispatch]);

    const handleSubmit = (form: IBuildingsForm, { setErrors }: FormikHelpers<IBuildingsForm>) => {
        setFormLoading(true);
        (buildingId ? buildingsService.update(buildingId, form) : buildingsService.create(form))
            .then(() => dispatch(create({ type: 'success', message: 'Sikeres mentés!', redirect: URL })))
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => setFormLoading(false));
    };

    const title = buildingId ? form.name + ' megtekintése' : 'Épület megtekintése';

    const header = {
        project: tenant || 'TAR',
        title: title,
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
            [clientEndPoints.md_general_campuses_buildings_list.replace(':campusId', campusId?.toString() || '')]:
                'Épületek',
            form: title,
        },
    };

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title} defaultExpanded disableClosing>
                <Formik initialValues={form} onSubmit={handleSubmit} validationSchema={FormSchema}>
                    {({ touched, errors, values, setFieldValue }) => {
                        return (
                            <FormikForm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Field as="div" field="campus">
                                            <TextField
                                                label="Campus"
                                                name="campus"
                                                value={values.campusName}
                                                disabled
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Field as="div" field="name">
                                            <TextField
                                                label="Épület"
                                                name="name"
                                                error={Boolean(errors.name && touched.name)}
                                                helperText={touched.name && errors.name}
                                                value={values.name}
                                                onChange={(e) => setFieldValue('name', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>

                                    <Address
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        disabled={readOnly}
                                    />

                                    <Grid item container xs={12} spacing={2}>
                                        {!readOnly && (
                                            <>
                                                <Grid item>
                                                    <IconButton
                                                        onClick={() => setFieldValue('address', campus)}
                                                        title={`Campus címének másolása`}
                                                    >
                                                        <ContentCopy />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <SubmitButton loading={formLoading} type="submit">
                                                        Mentés
                                                    </SubmitButton>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item>
                                            <Link to={URL}>
                                                <CancelButton>Mégsem</CancelButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}
