import { DataGridProProps, getGridStringOperators, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { translation } from '@silinfo/front-end-template';
import { IInfo } from '../../utils/Interfaces/IInfo';
import { IForm } from '../../utils/Interfaces/interfaces';
import BtnCellRenderer from './BtnCellRenderer';
import { IProcessesRow } from './ProcessesTable';

export const initialInfo: IInfo<IForm> = {
    perpage: 25,
    page: 1,
    count: 0,
    sort: { date: 'desc' },
    metadata: {
        'allCount': 0,
        'filteredCount': 0,
        'lastPage': 1,
        'page': 1,
        'perpage': 25,
        'rowCount': 0,
    },
    filter: {},
};

export const header = (tenant: string | null) => {
    return {
        title: 'Ügyeim, folyamataim',
        project: tenant || 'TAR',
        breadcrumbs: {
            finances: 'Ügyeim, folyamataim',
        },
    };
};

export const partialTableProps: Partial<DataGridProProps> = {
    autoHeight: true,
    density: 'comfortable',
    classes: {
        cell: 'noBorder',
    },
    localeText: translation,
    initialState: {
        sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
        },
    },
    columnBuffer: 7,
    paginationMode: 'server',
    filterMode: 'server',
};

export const columns: GridColDef[] = [
    {
        headerName: 'Megnevezés',
        field: 'name',
        minWidth: 300,
    },
    {
        headerName: 'Dátum',
        field: 'date',
    },
    {
        headerName: 'Státusz',
        field: 'status',
    },
    {
        headerName: '',
        renderCell: (params: GridRenderCellParams<IProcessesRow>) => <BtnCellRenderer params={params} />,
        field: 'info',
        maxWidth: 150,
    },
].map((col) => ({
    ...col,
    flex: 1,
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
}));
