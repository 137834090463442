import Grid from '@mui/material/Grid';
import { Page, TabsCompleted } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ExportButton, { ExportContext } from '../../../../components/Buttons/ExportButton';
import MCCLoading from '../../../../components/MCCLoading';
import studentsService from '../../../../services/masterData/students';
import { RootState } from '../../../../store';
import { createObjectFromParams, fetchData, initialInfo } from '../../../../utils/AppConst';
import { IInfo } from '../../../../utils/Interfaces/IInfo';
import { IForm, Student } from '../../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import useAccessCheck from '../../../../utils/useAccessCheck';
import { baseFields } from './Form/fields';
import { ViewProps } from './Form/types';
import View from './View';
import { columns } from './columns';
import { Options, fetchData as fetchOptions, initOptions } from './utils';
import { useTenant } from '../../../../components/TenantContext';
import Box from '@mui/material/Box';

const initFilter: { base: IForm; detailed: IForm } = {
    base: { terminating: ['0'] },
    detailed: { terminating: ['0'] },
};

export default function Students({ baseView, readOnly }: { baseView?: boolean; readOnly?: boolean }) {
    const { user } = useSelector((state: RootState) => state.auth);
    if (user.archive) {
        readOnly = true;
    }
    const [rawSearchParams, setSearchParams] = useSearchParams();
    const searchParams = createObjectFromParams([...rawSearchParams.entries()], baseFields());
    const [filter, setFilter] = useState<{ base: IForm; detailed: IForm }>(
        [...rawSearchParams.entries()].length > 0
            ? {
                  base: searchParams,
                  detailed: searchParams,
              }
            : initFilter,
    );
    const [options, setOptions] = useState<Options>(initOptions);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataBase, setDataBase] = useState<Student[]>([]);
    const [dataDetailed, setDataDetailed] = useState<Student[]>([]);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [tableLoadingBase, setTableLoadingBase] = useState<boolean>(false);
    const [infoBase, setInfoBase] = useState<IInfo<Student>>(initialInfo({ name: 'asc' }));
    const [infoDetailed, setInfoDetailed] = useState<IInfo<Student>>(initialInfo({ name: 'asc' }));
    const hasAccessToEdit = useAccessCheck(['md_general_students_edit_base', 'md_general_students_edit_detailed']);
    const hasAccessToAdd = useAccessCheck(['md_general_students_create']);
    const tenant = useTenant();

    const fetchDataBase = useCallback(
        (isDefault = false) => {
            setSearchParams({ tab: '0', ...(isDefault ? initFilter.base : filter.base) } as Record<string, string>);
            fetchData(
                isDefault ? initFilter.base : filter.base,
                studentsService,
                setTableLoadingBase,
                setDataBase,
                isDefault ? initialInfo({ name: 'asc' }) : infoBase,
                setInfoBase,
            );
        },
        [filter.base, infoBase, setSearchParams],
    );
    const fetchDataDetailed = useCallback(
        (isDefault = false) => {
            setSearchParams({ tab: '1', ...(isDefault ? initFilter.detailed : filter.detailed) } as Record<
                string,
                string
            >);

            fetchData(
                isDefault ? initFilter.detailed : filter.detailed,
                studentsService,
                setTableLoadingBase,
                setDataDetailed,
                isDefault ? initialInfo({ name: 'asc' }) : infoDetailed,
                setInfoDetailed,
            );
        },
        [filter.detailed, infoDetailed, setSearchParams],
    );

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            sessionStorage.setItem('students', 'studentVal');
            fetchOptions(setLoading, setOptions);
            fetchData(
                filter.base,
                studentsService,
                setTableLoadingBase,
                setDataBase,
                initialInfo({ name: 'asc' }),
                setInfoBase,
            );
            if (!baseView) {
                fetchData(
                    filter.detailed,
                    studentsService,
                    setTableLoadingBase,
                    setDataDetailed,
                    initialInfo({ name: 'asc' }),
                    setInfoDetailed,
                );
            }
        }
    }, [baseView, filter.base, filter.detailed, isFirstRender]);

    const changeForm = (type: 'base' | 'detailed') => (e: { target: { name: string; value: unknown } }) => {
        setFilter((prev) => ({ ...prev, [type]: { ...prev[type], [e.target.name]: e.target.value } }));
    };

    const header = {
        project: tenant || 'TAR',
        title: 'Hallgatók',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            students: 'Hallgatók',
        },
    };

    const base: Omit<ViewProps<Student>, 'changeForm'> = {
        filter: {
            onSubmit: fetchDataBase,
            form: filter.base,
            setForm: () => {
                setFilter((prev) => ({ ...prev, base: { terminating: ['0'] } }));
                fetchDataBase(true);
            },
            fields: baseFields(options),
        },
        table: {
            initialState: {
                pinnedColumns: {
                    right: ['operations'],
                },
            },
            columns: columns(fetchDataBase, 'base', !(!readOnly && hasAccessToEdit)),
            rows: dataBase,
            loading: tableLoadingBase,
            setInfo: (name: string, data: string) =>
                setInfoBase((prevState) => ({
                    ...prevState,
                    [name]: data,
                })),
            searchfunc: (i) =>
                fetchData(filter.base, studentsService, setTableLoadingBase, setDataBase, i, setInfoBase),
            info: infoBase,
            newButton:
                readOnly || !hasAccessToAdd
                    ? undefined
                    : {
                          target: clientEndPoints.md_general_students_create,
                          text: 'Hallgató hozzáadása',
                      },
            server: true,
            columnBuffer: columns(fetchDataBase, 'base', !(!readOnly && hasAccessToEdit)).length,
        },
    };

    const exportContextDetailed: ExportContext = {
        initiateExport: studentsService.initiateExport as (
            form: { format: string },
            filter: IForm,
        ) => Promise<AxiosResponse>,
        checkExport: studentsService.checkExport,
        downloadExport: studentsService.downloadExport,
        info: infoDetailed,
        filter: filter.detailed,
    };

    const exportContextFurtherStudies: ExportContext = {
        initiateExport: studentsService.initiateExportFurtherStudies as (
            form: { format: string },
            filter: IForm,
        ) => Promise<AxiosResponse>,
        checkExport: studentsService.checkExportFurtherStudies,
        downloadExport: studentsService.downloadExportFurtherStudies,
        info: infoDetailed,
        filter: filter.detailed,
    };

    const detailed: Omit<ViewProps<Student>, 'changeForm'> = {
        ...base,
        table: {
            ...base.table,
            info: infoDetailed,
            rows: dataDetailed,
            searchfunc: (i) => {
                setSearchParams(filter.detailed as Record<string, string>);
                return fetchData(
                    filter.detailed,
                    studentsService,
                    setTableLoadingBase,
                    setDataDetailed,
                    i,
                    setInfoDetailed,
                );
            },
            columns: columns(fetchDataDetailed, 'detailed', !(!readOnly && hasAccessToEdit)),
            setInfo: (name: string, data: string) =>
                setInfoDetailed((prevState) => ({
                    ...prevState,
                    [name]: data,
                })),
            customButtons: !user.archive ? (
                <Grid item>
                    <Box display="inline-flex" gap={2}>
                        <ExportButton exportContext={exportContextDetailed} />
                        <ExportButton
                            title={'További tanulmányok exportálása'}
                            exportContext={exportContextFurtherStudies}
                        />
                    </Box>
                </Grid>
            ) : (
                <></>
            ),
        },
        filter: {
            onSubmit: fetchDataDetailed,
            form: filter.detailed,
            setForm: () => {
                setFilter((prev) => ({ ...prev, detailed: { terminating: ['0'] } })); // függvény ide, itt kell elmenteni
                fetchDataDetailed(true);
            },
            fields: [
                ...base.filter.fields,
                //TODO: amennyiben meglesznek, hogy milyen mezők kellenek még, ezt bővíteni kell velük
            ],
        },
    };

    const tabContents = [
        { label: 'Alap nézet', value: <View changeForm={changeForm('base')} {...base} /> },
        { label: 'Részletes nézet', value: <View changeForm={changeForm('detailed')} {...detailed} /> },
    ];

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            {baseView ? (
                <View changeForm={changeForm('base')} {...base} />
            ) : (
                <TabsCompleted tabContents={tabContents} />
            )}
        </Page>
    );
}
