import { GridRenderCellParams } from '@mui/x-data-grid';
import { IFinanceRow } from './FinanceTable';
import FinanceDialog from './FinanceDialog';
import DownloadInvoiceButton from '../../pages/Finance/FinancialApprovals/DownloadInvoiceButton';

export default function FinanceInfoComponent(props: GridRenderCellParams<unknown, IFinanceRow>) {
    return (
        <>
            <FinanceDialog {...props} />
            {props.row.invoiceFile && props.row.updatedFileName && (
                <DownloadInvoiceButton
                    invoiceFile={props.row.invoiceFile}
                    student={true}
                    updatedFileName={props.row.updatedFileName}
                />
            )}
        </>
    );
}
