import { Outlet } from 'react-router-dom';
import AdmissionComponent from '../../pages/Admission/Admissions/AdmissionComponent';
import AdmissionLayout from './AdmissionLayout/AdmissionLayout';

export const admissionPage = {
    path: '/',
    element: (
        <AdmissionLayout>
            <Outlet />
        </AdmissionLayout>
    ),
    children: [
        {
            path: 'felveteli/mcc',
            element: <AdmissionComponent />,
            name: 'admission_mcc',
        },
        {
            path: 'felveteli/:tokenId',
            element: <AdmissionComponent />,
            name: 'admission_mccWithToken',
        },
    ],
};
