import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function Header() {
    return (
        <Grid item container xs={12} alignItems="flex-start" spacing={2} style={{ minWidth: '500px' }}>
            <Grid item xs={5} />
            <Grid item xs={2}>
                <Typography textAlign="center">Teljesített</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography textAlign="center">Felvett kurzusok</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography textAlign="center">Minimum teljesítendő</Typography>
            </Grid>
            <Grid item xs={1} />
        </Grid>
    );
}
