import Grid from '@mui/material/Grid';
import { TableEditButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction } from 'react';
import DeleteTargetAudience from './DeleteTargetAudience';
import EditTargetAudienceButton from './EditTargetAudienceButton';
import { ITargetAudience } from './types';
import { campus } from './index';
import { useParams, useSearchParams } from 'react-router-dom';

const OperationCellRenderer = ({
    row,
    setTableLoading,
    fetchData,
}: {
    row: ITargetAudience;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    fetchData: () => void;
}) => {
    const { courseId } = useParams() as { courseId: string };
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');
    return (
        <Grid container spacing={1}>
            {!closed && (
                <>
                    <Grid item>
                        <EditTargetAudienceButton
                            title="Célközönség szerkesztése"
                            submitBtnTitle="Mentés"
                            opener={<TableEditButton tooltip="Célközönség szerkesztése" />}
                            objectId={row.id}
                            campus={campus}
                            setTableLoading={setTableLoading}
                            onUpdate={fetchData}
                            courseTenant={courseTenant}
                        />
                    </Grid>
                    <Grid item>
                        <DeleteTargetAudience
                            courseId={+courseId}
                            row={row}
                            setLoading={setTableLoading}
                            courseTenant={courseTenant}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default OperationCellRenderer;
