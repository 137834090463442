import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getForceShowFirstLoginPopup, setForceShowFirstLoginPopup } from '../../utils/AuthHelper';
import UserNotifications from './UserNotifications';

export default function UserNotificationsRenderer() {
    const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
    const location = useLocation();

    const checkIfNotificationIsNeeded = (): void => {
        setForceShowFirstLoginPopup('true');
        const forceShowFirstLoginPopup = getForceShowFirstLoginPopup() === 'true';
        setIsNotificationOpen(forceShowFirstLoginPopup);
    };

    useEffect(() => {
        checkIfNotificationIsNeeded();
    }, []);

    return location.pathname !== '/switch-layout' && isNotificationOpen ? (
        <UserNotifications setIsNotificationOpen={setIsNotificationOpen} />
    ) : null;
}
