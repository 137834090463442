import DialogBasic from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CancelButton, FieldGenerator, SubmitButton } from '@silinfo/front-end-template';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '../../components/Form/Form';
import courseEnrollmentService from '../../services/studentServices/courseEnrollment';
import { create } from '../../store/notification';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export interface RequestForm {
    course: string;
    reason: string;
    courseTenant: string | null;
    externalCourseId?: string;
}

export default function RequestSendButton({
    opener,
    courseId,
    type,
    handleCloseParent,
    courseTenant,
    externalCourseId,
}: {
    opener: JSX.Element;
    courseId?: string;
    type: string;
    handleCloseParent: () => void;
    courseTenant: string | null;
    externalCourseId?: string;
}) {
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        handleCloseParent();
    };

    const handleError = () => dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));

    const handleSubmit = (form: RequestForm) => {
        if (!courseId) return;
        setLoading(true);
        form.course = courseId;
        if (courseTenant) {
            form.courseTenant = courseTenant;
        }
        if (externalCourseId) {
            form.externalCourseId = externalCourseId;
        }

        (type === 'enroll'
            ? courseEnrollmentService.requestEnrollment(form)
            : courseEnrollmentService.requestDismissal(form)
        )
            .then((ret) => {
                setLoading(false);
                if (!ret?.data?.success) {
                    dispatch(create({ type: 'error', message: 'Hiba: ' + (ret?.data?.message || 'Ismeretlen hiba') }));
                } else {
                    dispatch(create({ type: 'success', message: 'A kérvény sikeresen elküldve!' }));
                    handleCloseParent();
                }
            })
            .catch(handleError);
    };

    return (
        <>
            {React.cloneElement(opener, {
                onClick: handleOpen,
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>Megerősítés</DialogTitle>
                <Form
                    hideButtons
                    fields={[]}
                    onSubmit={handleSubmit}
                    initialValues={{ course: '', reason: '', courseTenant: null }}
                >
                    {(props) => {
                        return (
                            <>
                                <Grid item xs={12}>
                                    <DialogContent>
                                        <DialogContentText>
                                            A kérelem elküldéséhez, kérjük, adj meg egy rövid indoklást.
                                        </DialogContentText>
                                        {FieldGenerator({
                                            ...{
                                                name: 'reason',
                                                label: 'Indoklás',
                                                format: { xs: 12 },
                                                type: 'password',
                                                props: {
                                                    multiline: true,
                                                    rows: 5,
                                                },
                                            },
                                            ...props,
                                        })}
                                    </DialogContent>
                                </Grid>

                                <Grid item xs={12}>
                                    <DialogActions>
                                        <SubmitButton type="submit" loading={false}>
                                            Küldés
                                        </SubmitButton>
                                        <CancelButton onClick={handleClose}>Mégse</CancelButton>
                                    </DialogActions>
                                </Grid>
                            </>
                        );
                    }}
                </Form>
            </DialogBasic>
        </>
    );
}
