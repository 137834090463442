import GpsNotFixed from '@mui/icons-material/GpsNotFixed';
import SwapHorizontalCircle from '@mui/icons-material/SwapHorizontalCircle';
import WarningAmber from '@mui/icons-material/WarningAmber';
import Tooltip from '@mui/material/Tooltip';

type TypeCellRendererProps = {
    type: string;
};

export function TypeCellRenderer({ type }: TypeCellRendererProps) {
    switch (type) {
        case 'required':
            return (
                <Tooltip title="Kötelező kurzus">
                    <WarningAmber color={'error'} />
                </Tooltip>
            );
        case 'mandatory':
            return (
                <Tooltip title="Kötelezően választható kurzus">
                    <SwapHorizontalCircle color={'primary'} />
                </Tooltip>
            );
        case 'optional':
            return (
                <Tooltip title="Választható kurzus">
                    <GpsNotFixed color={'success'} />
                </Tooltip>
            );
        default:
            return <></>;
    }
}
