import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, SubmitButton, transformApiErrors } from '@silinfo/front-end-template';
import Title from './Title';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { IAdmissionPointTablePoint } from './types';
import { initialPartialPoint } from './utils';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { endpoints } from '../../../utils/endPoints';
import API from '../../../api';
import { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { create } from '../../../store/notification';
import { IForm } from '../../../utils/Interfaces/interfaces';

const initialValues: Record<string, unknown> = {
    id: 0,
    maximumPoints: '0',
    partialPoints: [],
    documents: [],
};

export default function PointTable({
    admissionId,
    readonly,
    closed,
}: {
    admissionId: number;
    readonly: boolean;
    closed: boolean;
}) {
    const dispatch = useDispatch();
    const [form, setForm] = useState(initialValues);
    const [customId, setCustomId] = useState(-1);
    const [loading, setLoading] = useState(true);
    const [maxPoints, setMaxPoints] = useState(initialValues.maximumPoints);
    const partialPointCount = (form.partialPoints as IAdmissionPointTablePoint[]).length;
    const regex = /^\d{0,3}\.?$|^\.$|^\d{0,3}\.\d{0,2}$/;

    useEffect(() => {
        if (admissionId) {
            API.get(endpoints.admission.admissionPointTable.pointTable.replace('{admissionId}', admissionId.toString()))
                .then((data) => {
                    setForm(data.data);
                    setMaxPoints(data.data.maximumPoints);
                })
                .finally(() => setLoading(false));
        }
    }, [admissionId]);

    useEffect(() => {
        const pps = (form.partialPoints as IAdmissionPointTablePoint[])
            .map((element) => parseFloat(element.point))
            .reduce((ac, cur) => ac + cur, 0);
        const dps = (form.documents as IAdmissionPointTablePoint[])
            .map((element) => parseFloat(element.point))
            .reduce((ac, cur) => ac + cur, 0);

        setMaxPoints((pps + dps).toFixed(2).toString());
    }, [form.partialPoints, form.documents]);

    const handleSubmit = (submitform: IForm, formikHelpers: FormikHelpers<IForm>) => {
        API.post(endpoints.admission.admissionPointTable.pointTable.replace('{admissionId}', admissionId.toString()), {
            ...submitform,
            partialPoints: form.partialPoints,
            documents: form.documents,
        })
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres mentés.' }));
            })
            .catch((error) => {
                formikHelpers.setErrors(transformApiErrors<IForm>(error.response?.data.violations));
                dispatch(create({ type: 'error', message: 'Sikertelen mentés.' }));
            });
    };

    if (loading) {
        return <Skeleton />;
    }

    return (
        <Grid container spacing={0} direction="column" alignItems="center">
            <Box
                component={Grid}
                container
                boxShadow={3}
                sx={{
                    width: '75%',
                    padding: '10px',
                    margin: '10px auto 0',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    '& form': { width: '100%' },
                }}
            >
                <Form hideButtons urlParam="groupId" fields={[]} onSubmit={handleSubmit}>
                    {(props) => (
                        <Grid item container alignItems="center" spacing={2} sx={{ marginLeft: 0 }}>
                            <Grid item xs={12} sx={{ marginRight: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>Elérhető maximális pontszám összesen:</Typography>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        sx={{ marginLeft: '10px' }}
                                        disabled={true}
                                        value={maxPoints}
                                        defaultValue={maxPoints}
                                    />
                                </div>
                            </Grid>
                            <Title
                                title="Részpontok"
                                titleButton={
                                    <IconButton
                                        color={'info'}
                                        onClick={() => {
                                            setForm((prev) => ({
                                                ...prev,
                                                partialPoints: [
                                                    ...(prev.partialPoints as IAdmissionPointTablePoint[]),
                                                    initialPartialPoint(customId, form.id as number),
                                                ],
                                            }));
                                            setCustomId(customId - 1);
                                        }}
                                        disabled={partialPointCount >= 10 || (readonly && !closed)}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                }
                            >
                                {(form.partialPoints as IAdmissionPointTablePoint[]).map(
                                    (element: IAdmissionPointTablePoint, index: number) => (
                                        <>
                                            <Grid container sx={{ marginBottom: '10px' }} spacing={2}>
                                                <Grid item xs={9}>
                                                    <TextField
                                                        label="Pontozási összetevő neve"
                                                        variant="outlined"
                                                        value={element.name}
                                                        size="small"
                                                        fullWidth
                                                        disabled={readonly && !closed}
                                                        onChange={(e) => {
                                                            setForm((prev) => ({
                                                                ...prev,
                                                                partialPoints: (
                                                                    prev.partialPoints as IAdmissionPointTablePoint[]
                                                                ).map((document: IAdmissionPointTablePoint) =>
                                                                    document.id === element.id
                                                                        ? {
                                                                              ...document,
                                                                              name: e.target.value,
                                                                          }
                                                                        : document,
                                                                ),
                                                            }));
                                                        }}
                                                        error={!!props.errors['partialPointName' + (index + 1)]}
                                                        helperText={props.errors['partialPointName' + (index + 1)]}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        label="Pont"
                                                        variant="outlined"
                                                        value={element.point}
                                                        size="small"
                                                        fullWidth
                                                        disabled={readonly && !closed}
                                                        onChange={(e) => {
                                                            setForm((prev) => ({
                                                                ...prev,

                                                                partialPoints: (
                                                                    prev.partialPoints as IAdmissionPointTablePoint[]
                                                                ).map((point: IAdmissionPointTablePoint) =>
                                                                    point.id === element.id
                                                                        ? {
                                                                              ...point,
                                                                              point: regex.test(e.target.value)
                                                                                  ? e.target.value
                                                                                  : point.point,
                                                                          }
                                                                        : point,
                                                                ),
                                                            }));
                                                        }}
                                                        error={!!props.errors['partialPointPoint' + (index + 1)]}
                                                        helperText={props.errors['partialPointPoint' + (index + 1)]}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        color={'error'}
                                                        disabled={readonly && !closed}
                                                        onClick={() =>
                                                            setForm((prev) => ({
                                                                ...prev,
                                                                partialPoints: (
                                                                    prev.partialPoints as IAdmissionPointTablePoint[]
                                                                ).filter((item) => item.id != element.id),
                                                            }))
                                                        }
                                                    >
                                                        <RemoveCircleOutlineIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ),
                                )}
                            </Title>
                            <Title title="Feltöltendő dokumentumok">
                                {(form.documents as IAdmissionPointTablePoint[]).map(
                                    (element: IAdmissionPointTablePoint, index: number) => (
                                        <>
                                            <Grid container sx={{ marginBottom: '10px' }} spacing={2}>
                                                <Grid item xs={9}>
                                                    <TextField
                                                        label=""
                                                        variant="outlined"
                                                        value={element.name}
                                                        size="small"
                                                        fullWidth
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        label="Pont"
                                                        variant="outlined"
                                                        value={element.point}
                                                        size="small"
                                                        fullWidth
                                                        disabled={readonly && !closed}
                                                        onChange={(e) => {
                                                            setForm((prev) => ({
                                                                ...prev,
                                                                documents: (
                                                                    prev.documents as IAdmissionPointTablePoint[]
                                                                ).map((document: IAdmissionPointTablePoint) =>
                                                                    document.documentId === element.documentId
                                                                        ? {
                                                                              ...document,
                                                                              point: regex.test(e.target.value)
                                                                                  ? e.target.value
                                                                                  : document.point,
                                                                          }
                                                                        : document,
                                                                ),
                                                            }));
                                                        }}
                                                        error={!!props.errors['documentPoint' + (index + 1)]}
                                                        helperText={props.errors['documentPoint' + (index + 1)]}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}></Grid>
                                            </Grid>
                                        </>
                                    ),
                                )}
                            </Title>
                            <Grid
                                item
                                container
                                xs={12}
                                spacing={2}
                                sx={{
                                    justifyContent: 'flex-end',
                                    margin: '0 10px 10px 0',
                                    paddingTop: '0px !important',
                                }}
                            >
                                {(!readonly || closed) && (
                                    <Grid item>
                                        <SubmitButton loading={false} type="submit">
                                            Mentés
                                        </SubmitButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Form>
            </Box>
        </Grid>
    );
}
