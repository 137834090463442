import { Action, ThunkAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { BrowserHistory, createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { getLayoutType } from '../utils/AuthHelper';
import accessManagement from './accessManagement';
import auth from './auth';
import loading from './loading';
import notification from './notification';
import studentProfile from './studentProfile';

export const history: BrowserHistory = createBrowserHistory();
const loggerMiddleware = createLogger();

const rootReducer = (history: BrowserHistory) =>
    combineReducers({
        router: connectRouter(history),
        notification,
        loading,
        auth,
        accessManagement,
        studentProfile,
    });

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware, loggerMiddleware)),
);

const layoutCheckTime = 1000 * 5;
const excludedPaths = ['/switch-layout', '/felveteli'];
const regex = /\/felveteli\/(?=.*[a-zA-Z0-9]).{10,}$/;

window.setInterval(() => {
    const layoutRedux = store.getState().auth.layoutType;
    const layoutCookie = getLayoutType();
    if (layoutRedux !== layoutCookie && !excludedPaths.includes(window.location.pathname)) {
        if (!regex.test(window.location.pathname)) {
            window.location.href = '/';
        }
    }
}, layoutCheckTime);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
