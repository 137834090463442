import Grid from '@mui/material/Grid';
import IStudentRequestApproval from '../../../utils/Interfaces/IStudentRequestApproval';
import { actualStatusOptions, semesterStatusOptions } from '../../MasterData/General/Students/Form/options';
import ForeignScholarshipChangeInfo from './ForeignScholarshipChangeInfo';

export interface IStatusChangeInfoProps {
    data?: IStudentRequestApproval;
}

export function StatusChangeInfo(props: IStatusChangeInfoProps) {
    const { data } = props;
    const options = [...actualStatusOptions, ...semesterStatusOptions];

    if (!data || !data.requestData) {
        return <></>;
    }

    const semesterLabel =
        data.requestData.requestedStatus === 'foreign_scholarship'
            ? 'Jelenlegi szemeszter státusz:'
            : 'Aktuális státusz:';
    return (
        <>
            <Grid item xs={12}>
                <b>Kérvény típusa: </b> {data.type}
            </Grid>
            <Grid item xs={12}>
                <b>Hallgató: </b> {data.student}
            </Grid>
            <>
                <Grid item xs={12}>
                    <b>{semesterLabel}</b>{' '}
                    {options.find((elem) => elem.value === data.requestData?.actualStatus)?.label}
                </Grid>
                <Grid item xs={12}>
                    <b>Igényelt státusz: </b>{' '}
                    {options.find((elem) => elem.value === data.requestData?.requestedStatus)?.label}
                </Grid>
            </>
            {data.requestData &&
                data.typeOrig === 'change_semester_status' &&
                data.requestData.requestedStatus === 'foreign_scholarship' && (
                    <ForeignScholarshipChangeInfo data={data.requestData} />
                )}
            <Grid item xs={12}>
                <b>Hallgató által beírt indoklás:</b> {data?.reason}
            </Grid>
        </>
    );
}
