const ROLES_RAW = ['ROLE_ADMIN'] as const;

export type GeneralRole = typeof ROLES_RAW;
export type Role = GeneralRole[number];

const ROLES = {
    ADMIN: 'ROLE_SUPERADMIN',
    USER: 'ROLE_USER_FUNCTIONAL',
    TEACHER: 'ROLE_TEACHER',
    COURSE_MANAGER: 'ROLE_COURSE_MANAGER',
    TRAINING_PROGRAM_LEADER: 'ROLE_TRAINING_PROGRAM_LEADER',
    TRAINING_PROGRAM_ADMIN: 'ROLE_TRAINING_PROGRAM_ADMIN',
    EP_COORDINATOR: 'ROLE_EP_COORDINATOR',
    FINANCIAL_ADMIN: 'ROLE_FINANCIAL_ADMIN',
    APPROVER_DECISION_MAKER: 'ROLE_APPROVER_DECISION_MAKER',
    OPERATOR: 'ROLE_OPERATOR',
    BOARD: 'ROLE_BOARD',
    STUDENT: 'ROLE_STUDENT',
};

export default ROLES;
