import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Visibility from '@mui/icons-material/Visibility';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableButton } from '@silinfo/front-end-template';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import EndpointProvider from '../../../components/EndpointProvider';
import SemesterContext from '../../../components/SemesterSelector/SemesterContextProvider';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { endpoints } from '../../../utils/endPoints';
import DeleteButton from './DeleteButton';

export default function OperationsCellRenderer(params: GridRenderCellParams) {
    const navigate = useNavigate();
    const semester = useContext(SemesterContext);
    const deletable = !params.row.used;
    const url = endpoints.scholarships[deletable ? 'delete' : 'archivate'];

    const navigateToEdit = useCallback(() => {
        navigate(
            clientEndPoints.scholarship_group_management_edit
                .replace(':id', params.row.id)
                .replace(':semesterId', semester),
        );
    }, [navigate, params.row.id, semester]);

    const handleNavigateToView = useCallback(() => {
        navigate(
            clientEndPoints.scholarship_group_management_view
                .replace(':id', params.row.id)
                .replace(':semesterId', semester),
        );
    }, [navigate, params.row.id, semester]);

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
            <EndpointProvider endpoints={['scholarship_group_management_edit']}>
                <Grid item>
                    {deletable ? (
                        <TableButton color="warning" onClick={navigateToEdit}>
                            <DriveFileRenameOutlineIcon />
                        </TableButton>
                    ) : (
                        <TableButton color="info" onClick={handleNavigateToView}>
                            <Visibility />
                        </TableButton>
                    )}
                </Grid>
                <DeleteButton
                    id={params.row.id}
                    deletable={deletable}
                    archived={params.row.archived}
                    url={url}
                    groupName={params.row.name}
                />
            </EndpointProvider>
            <EndpointProvider
                endpoints={['scholarship_group_management_view']}
                endpointsInverse={['scholarship_group_management_edit']}
            >
                <TableButton color="info" onClick={handleNavigateToView}>
                    <Visibility />
                </TableButton>
            </EndpointProvider>
        </Grid>
    );
}
