import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useContext, useState, useCallback } from 'react';
import GridRenderCellParamsContext from '../List/GridRenderCellParamsContextProvider';
import AddchartIcon from '@mui/icons-material/Addchart';
import ImportDialog from './Dialog';

export default function ListImport({ disabled }: { disabled?: boolean }) {
    const [open, setOpen] = useState(false);
    const params = useContext(GridRenderCellParamsContext);

    const handleOpen = useCallback(() => setOpen(true), []);

    if (params?.row.calculationType !== 'manual') {
        return null;
    }

    return (
        <>
            <MenuItem onClick={handleOpen} disabled={disabled}>
                <ListItemIcon>
                    <AddchartIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>Manuálisan kitöltött lista importálása</ListItemText>
            </MenuItem>
            <ImportDialog openState={[open, setOpen]} />
        </>
    );
}
