import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridFilterAltIcon } from '@mui/x-data-grid-pro';
import { MouseEventHandler, useCallback, useState } from 'react';
import { Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';
import '../CourseEnrollment.scss';
import { FilterProps } from '../types';
import { initialFilter } from '../utils';
import FilterForm from './FilterForm';

export default function Filter({ info, setInfo }: FilterProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const matches = useMediaQuery('(min-width:600px)');
    const courseTypes = useFetch<Option[]>(endpoints.masterData.courses.courseTypes.studentEnrollmentFilterOptions, '');
    const campuses = useFetch<Option[]>(endpoints.masterData.courses.campuses.studentEnrollmentFilterOptions, '');
    const options = {
        campuses: campuses.data.sort((a, b) => a.label.localeCompare(b.label)),
        courseTypes: courseTypes.data
            .map((e) => ({ label: e.label, value: '' + e.value }))
            .sort((a, b) => a.label.localeCompare(b.label)),
    };

    const active = JSON.stringify(info.filter) !== JSON.stringify(initialFilter);

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            if (matches) {
                setAnchorEl(e.currentTarget);
            }
            setOpen(!matches);
        },
        [matches],
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setOpen(false);
    }, []);
    return (
        <>
            <Button
                size="large"
                onClick={handleClick}
                startIcon={<GridFilterAltIcon sx={{ color: active ? 'red' : '#ddd' }} />}
            >
                Szűrés
            </Button>
            <Menu
                open={!!anchorEl || open}
                anchorEl={anchorEl}
                PaperProps={{
                    style: matches
                        ? {
                              maxWidth: 600,
                          }
                        : {},
                }}
                variant="menu"
                disableAutoFocusItem
            >
                <div style={{ textAlign: 'end' }}>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </div>
                <DialogContent>
                    <FilterForm setInfo={setInfo} info={info} options={options} handleClose={handleClose} />
                </DialogContent>
            </Menu>
        </>
    );
}
