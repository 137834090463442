import EventLogs from '../../../pages/Administration/SystemLog/EventLogs/EventLogs';

export const eventLogs = {
    path: 'event-logs',
    children: [
        {
            path: '',
            element: <EventLogs />,
            name: 'admin_event_logs_list',
        },
    ],
};
