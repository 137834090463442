import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FieldGenerator, Form } from '@silinfo/front-end-template';
import API from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { useDispatch } from 'react-redux';
import { create } from '../../../store/notification';
import { Option, transformApiErrors } from '../../../utils/AppConst';
import CircularProgress from '@mui/material/CircularProgress';
import { FormikHelpers } from 'formik';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { StatusChangeDialogProps } from './types';

export default function StatusChangeDialog({
    openState,
    semester,
    applicantState,
    programState,
    disabledState,
    toggleRefresh,
}: StatusChangeDialogProps) {
    const [open, setOpen] = openState;
    const [statusOptions, setStatusOptions] = useState<Option[]>([]);
    const [statusValue, setStatusValue] = useState<string>('awaiting_decision');
    const [currentApplicantId, setCurrentApplicantId] = applicantState;
    const [currentProgramId, setCurrentProgramId] = programState;
    const [isDisabled, setIsDisabled] = disabledState;
    const [loading, setLoading] = useState<boolean>(true);

    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        setCurrentApplicantId(null);
        setCurrentProgramId(null);
        setIsDisabled(false);
    };

    const handleSave = (_: IForm, { setErrors }: FormikHelpers<IForm>) => {
        if (currentApplicantId && currentProgramId) {
            API.post(endpoints.admission.changeStatus(semester.value, currentApplicantId, currentProgramId), {
                status: statusValue,
            })
                .then(() => {
                    dispatch(create({ type: 'success', message: 'Sikeres státuszváltás' }));
                    toggleRefresh();
                    handleClose();
                })
                .catch((err) => {
                    if (err.response?.status === 422) {
                        setErrors(transformApiErrors(err.response?.data.violations));
                    }
                    dispatch(create({ type: 'error', message: 'Sikertelen státuszváltás' }));
                });
        }
    };

    useEffect(() => {
        API.get(endpoints.admission.getStatusOptions).then((response) => setStatusOptions(response.data));
        if (currentApplicantId && currentProgramId && open) {
            setLoading(true);
            API.get(endpoints.admission.getStatus(semester.value, currentApplicantId, currentProgramId))
                .then((res) => setStatusValue(res.data.status))
                .finally(() => setLoading(false));
        }
    }, [semester.value, currentApplicantId, currentProgramId, open]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>Státuszváltás</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Grid container xs={12} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={100} />
                    </Grid>
                ) : (
                    <Form fields={[]} onSubmit={handleSave} hideButtons>
                        {(props) => {
                            return (
                                <Grid container sx={{ marginTop: '10px', paddingLeft: '1rem' }} spacing={2}>
                                    {FieldGenerator({
                                        ...props,
                                        label: 'Státusz',
                                        name: 'status',
                                        type: 'select',
                                        fieldType: 'base',
                                        options: statusOptions,
                                        format: { xs: 12, md: 12 },
                                        props: {
                                            value: statusValue,
                                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                                setStatusValue(e.target.value),
                                            disabled: isDisabled,
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                            error: !!(props.errors as Record<string, string>).status,
                                            helperText: (props.errors as Record<string, string>).status,
                                        },
                                    })}
                                    <Grid item container justifyContent="right" spacing={1}>
                                        <Grid item>
                                            <Button variant="contained" type="submit" disabled={isDisabled}>
                                                Mentés
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={handleClose} variant="outlined">
                                                Bezár
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }}
                    </Form>
                )}
            </DialogContent>
        </Dialog>
    );
}
