import Alert from '@mui/material/Alert';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../../utils/AppConst';

export default function NoSemesterAlert() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Alert severity="info">Kérjük, válassz szemesztert az adatok megtekintéséhez</Alert>
        </ThemeProvider>
    );
}
