import { Accordion, Page } from '@silinfo/front-end-template';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Form from '../../../../components/Form/Form';
import universitiesService from '../../../../services/masterData/universities';
import { fields } from './fields';
import countriesService from './../../../../services/masterData/countriesService';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { AxiosResponse } from 'axios';
import { Option } from '../../../../utils/AppConst';
import { useTenant } from '../../../../components/TenantContext';

const UniversityFormSchema = Yup.object().shape({
    name: Yup.string().max(128, 'A maximálisan megadható karakterek száma 128').required('Kötelező megadni'),
    shortName: Yup.string().max(16, 'A maximálisan megadható karakterek száma 16').required('Kötelező megadni'),
    country: Yup.string().max(128, 'A maximálisan megadható karakterek száma 128').required('Kötelező megadni'),
});

export default function UniversityForm({ readOnly = false }: { readOnly?: boolean }) {
    const { id } = useParams();
    const tenant = useTenant();

    const title = (id: string | undefined, readOnly: boolean) => {
        if (readOnly) {
            return 'Egyetem megtekintése';
        } else if (id) {
            return 'Egyetem szerkesztése';
        } else {
            return 'Egyetem hozzáadása';
        }
    };

    const header = {
        project: tenant || 'TAR',
        title: 'Egyetemek',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_universities_list]: 'Egyetemek',
            [id ? 'universitiesEdit' : 'universitiesNew']: title(id, readOnly),
        },
    };

    const getUniversity = async (): Promise<AxiosResponse> => {
        const response = await universitiesService.get(id || 0);
        const { data } = response;

        return {
            ...response,
            data: {
                name: data.name,
                shortName: data.shortName,
                country: data.country['@id'],
            },
        };
    };

    return (
        <Page header={header}>
            <Accordion title={title(id, readOnly)}>
                <Form
                    validationSchema={UniversityFormSchema}
                    onSubmit={(values) =>
                        id ? universitiesService.update(id, values) : universitiesService.create(values)
                    }
                    initialValues={id ? undefined : { country: '/countries/102', name: '', shortName: '' }}
                    fields={(options) => fields(options as { country: Option[] })}
                    optionLabel="countryName"
                    loadOptions={{ country: () => countriesService.filter({}) }}
                    back={clientEndPoints.md_general_universities_list}
                    getterFunction={getUniversity}
                    readonly={readOnly}
                />
            </Accordion>
        </Page>
    );
}
