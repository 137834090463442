import CourseCompletions from '../../pages/Administration/CourseCompletions';

export const courseCompletions = {
    path: 'course-completions',
    children: [
        {
            path: '',
            element: <CourseCompletions />,
            name: 'courseCompleions',
        },
    ],
};
