import CourseClosing from '../../pages/Administration/CourseClosing';

export const courseClosing = {
    path: 'program-closing',
    children: [
        {
            path: '',
            element: <CourseClosing />,
            name: 'courseClosing',
        },
    ],
};
