import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import api from '../api';

type FetchState<T> =
    | {
          loading: false;
          data: T;
          error: string;
      }
    | {
          loading: true;
          data: [];
          error: string;
      };

const useFetch = <T>(url: string, keyOfResponse = 'data', dependence: unknown = false): FetchState<T> => {
    const [state, setState] = useState<FetchState<T>>({
        loading: true,
        error: '',
        data: [],
    });

    const setValue = useCallback(
        (key: keyof FetchState<T>, value: FetchState<T>[keyof FetchState<T>]) =>
            setState((prev) => ({ ...prev, [key]: value })),
        [setState],
    );

    useEffect(() => {
        let isSubscribed = true;
        if (url) {
            setValue('loading', true);

            api.get(url)
                .then((response: AxiosResponse) => {
                    if (isSubscribed) {
                        if (keyOfResponse !== '') {
                            setValue('data', response.data[keyOfResponse]);
                        } else {
                            const data = response.data;
                            setValue('data', data);
                        }
                    }
                })
                .catch((error: AxiosError) => isSubscribed && setValue('error', error.message))
                .finally(() => isSubscribed && setValue('loading', false));
        } else {
            setValue('loading', false);
        }

        return () => {
            isSubscribed = false;
        };
    }, [url, dependence, keyOfResponse, setValue]);

    return state;
};

export default useFetch;
