import Delete from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { Dialog, SubmitButton, TableButton } from '@silinfo/front-end-template';
import { useDispatch } from 'react-redux';
import semestersService from '../../../../../services/masterData/semesters';
import { create } from '../../../../../store/notification';
import { EditProps } from './EditHoliday';

export default function DeleteHoliday({ setSemesterForm, setFieldValue, holidays, holidayToEdit }: EditProps) {
    const dispatch = useDispatch();

    const deleteCore = () => {
        const newHolidays = holidays.filter((b) => b.id !== holidayToEdit.id);
        setFieldValue('holidays', newHolidays);
        setSemesterForm((prev) => ({ ...prev, holidays: newHolidays }));
    };

    const handleDelete = () => {
        if (holidayToEdit['@id'] && holidayToEdit.id) {
            semestersService
                .deleteHoliday(holidayToEdit.id)
                .then(deleteCore)
                .catch(() => dispatch(create({ type: 'error', message: 'Hiba a törlés során!' })));
        } else {
            deleteCore();
        }
    };

    return (
        <Dialog
            title="Szünet törlése"
            opener={
                <TableButton color="error" tooltip="Szünet törlése">
                    <Delete />
                </TableButton>
            }
            action={<SubmitButton onClick={handleDelete}>Igen</SubmitButton>}
            cancelText="Mégsem"
        >
            <Typography variant="h5">Biztosan törli a(z) {holidayToEdit.name} nevű szünetet?</Typography>
        </Dialog>
    );
}
