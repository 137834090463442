import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MobileMccLogo from './Icons/MobileMccLogo.svg';
import MobileUserIcon from './Icons/MobileUserIcon.svg';
import { useState } from 'react';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import AdmissionGuideModal from './Dialogs/AdmissionGuideModal';
import AdmissionsStyle from './Admissions.module.scss';

export default function MobileHeader({ semester }: { semester: string }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openModal, setOpenModal] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        window.location.href = clientEndPoints.admission_landing_page;
    };

    const handleGuide = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <Grid container xs={12} className={`${AdmissionsStyle.mobileHeaderContainer}`}>
            <Grid item xs={2}>
                <img src={MobileMccLogo} />
            </Grid>
            <Grid item container sx={{ flexDirection: 'column', justifyContent: 'center' }} xs={7}>
                <Typography className={`${AdmissionsStyle.mobileHeaderPageTitle}`}>Felvételi adatlap</Typography>
                <Typography className={`${AdmissionsStyle.mobileHeaderPageSemester}`}>
                    {semester}i szemeszter
                </Typography>
            </Grid>
            <Grid item container xs={2} className={`${AdmissionsStyle.mobileHeaderProfileIcon}`}>
                <img
                    src={MobileUserIcon}
                    style={{
                        cursor: 'pointer',
                        marginBottom: '0.25rem',
                    }}
                    onClick={handleClick}
                />
            </Grid>
            <Menu
                anchorEl={anchorEl}
                id="user-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                className={`borderRadius ${AdmissionsStyle.mobileHeaderMenu}`}
                disableAutoFocusItem
            >
                <MenuItem onClick={handleGuide}>Segédlet</MenuItem>
                <MenuItem onClick={logout}>Kijelentkezés</MenuItem>
            </Menu>
            <AdmissionGuideModal open={openModal} handleClose={handleCloseModal} />
        </Grid>
    );
}
