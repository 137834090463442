import Visibility from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Loading, TableButton } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import systemMessagesService from '../../services/systemMessages';
import { setUnseenNotificationsNumber } from '../../store/auth';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';

export interface INotification {
    id: string;
    messageContent: string;
    messageTitle: string;
    lastSeen: string | null;
}

const Content = ({ id, setShouldRefresh }: { id: string; setShouldRefresh: Dispatch<SetStateAction<boolean>> }) => {
    const dispatch = useDispatch();
    const { loading, data } = useFetch<INotification>(endpoints.systemMessages + '/' + id, '') as {
        loading: boolean;
        data: INotification;
    };

    useEffect(() => {
        if (!loading && data.lastSeen === null) {
            systemMessagesService
                .markAsSeen([+id])
                .then(({ data }: AxiosResponse<{ unseenSystemMessagesCount: number }>) => {
                    dispatch(setUnseenNotificationsNumber(data.unseenSystemMessagesCount));
                    setShouldRefresh(true);
                });
        } else if (!loading && data.lastSeen !== null) {
            setShouldRefresh(false);
        }
    }, [loading, data, id, dispatch, setShouldRefresh]);

    return (
        <>
            <DialogTitle>{loading ? 'Betöltés...' : data.messageTitle}</DialogTitle>
            <DialogContent style={{ marginTop: '4px' }}>
                {loading ? (
                    <Loading noHeight />
                ) : (
                    <div
                        style={{ wordBreak: 'break-word' }}
                        dangerouslySetInnerHTML={{ __html: data.messageContent }}
                    />
                )}
            </DialogContent>
        </>
    );
};

export default function ViewMessageButton({ id, refresh }: { id: string; refresh: () => void }) {
    const [open, setOpen] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const handleClose = () => {
        setOpen(false);
        if (shouldRefresh) {
            refresh();
        }
    };

    return (
        <Grid item>
            <TableButton onClick={() => setOpen(true)}>
                <Visibility />
            </TableButton>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <Content id={id} setShouldRefresh={setShouldRefresh} />
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Bezár
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
