import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Tooltip } from '@silinfo/front-end-template';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';
import PriceTag from '../PriceTag';
import { IMeasurements } from './types';
import { formatNumberToAmount, translations } from './utils';
import React from 'react';

interface PriceTagProps {
    currency?: string;
}

export default function PriceTags(props: PriceTagProps) {
    const { currency } = props;
    const { data: array, loading } = useFetch<IMeasurements[]>(
        endpoints.students.finances.scholarships.measurements,
        '',
    );

    if (loading)
        return (
            <Grid item xs={12} md={4}>
                <Skeleton height="100%" />
            </Grid>
        );

    const data = array[0];

    const priceTags = data
        ? Object.entries(data).map(([key, value]) => ({
              label: translations[key as keyof IMeasurements],
              value: formatNumberToAmount(value, currency),
          }))
        : [];

    return (
        <Grid item container xs={12} md={4} spacing={1} alignSelf="flex-start">
            {priceTags.map((priceTag) => (
                <React.Fragment key={priceTag.label}>
                    <Tooltip
                        title={'Az aktuális szemeszterre vonatkozó ' + priceTag.label.toLowerCase() + ' ösztöndíj'}
                    >
                        <Grid item xs={12}>
                            <PriceTag value={priceTag.value} label={priceTag.label} />
                        </Grid>
                    </Tooltip>
                </React.Fragment>
            ))}
        </Grid>
    );
}
