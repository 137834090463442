import { useFetch } from '@silinfo/front-end-template';
import IEntityBase from '../../../interface/IEntityBase';
import { Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';

export default function useOptions() {
    const { data: semesterOptions, loading: semestersLoading } = useFetch<Option<number>[]>(
        endpoints.selfEvaluation.questionnaireProgram.semesters,
        '',
    );

    const { data: trainingLevels, loading: trainingLevelsLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.trainingLevels.main,
        'hydra:member',
    );

    const { data: trainingPrograms, loading: trainingProgramsLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.trainingPrograms.optionsForFilterStudent,
        '',
    );

    const { data: campuses, loading: campusesLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.campuses.main,
        'hydra:member',
    );

    const loading = semestersLoading || trainingLevelsLoading || trainingProgramsLoading || campusesLoading;

    const options: Record<string, Option[]> = {
        trainingLevels: trainingLevels.map((e) => ({ value: e.id as string, label: e.name })),
        trainingPrograms: trainingPrograms as unknown as Option[],
        campuses: campuses.map((e) => ({ value: e.id as string, label: e.name })),
        semesters: semesterOptions.map((e) => ({ value: e.value as unknown as string, label: e.label })),
        isEnrollmentDocument: [
            { value: 1, label: 'Igen' },
            { value: 0, label: 'Nem' },
        ].map((e) => ({ value: e.value.toString(), label: e.label })),
    };

    return { loading, options };
}
