import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../api';
import { endpoints } from '../../../../utils/endPoints';
import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';

export default function AdmissionGuideModal({ open, handleClose }: { open: boolean; handleClose: () => void }) {
    const [guideContent, setGuideContent] = useState<string | null>(null);

    async function getGuide() {
        api.get(endpoints.admission.guide)
            .then((response) => {
                const content = response.data.translations.hu.content;
                if (content) {
                    setGuideContent(content);
                    return content;
                }
            })
            .catch((e: AxiosError) => {
                console.error(e);
            });
    }

    useEffect(() => {
        async function fetchData() {
            await getGuide();
        }

        if (open) {
            fetchData();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem' }}>
                <Typography
                    variant="h6"
                    style={{
                        color: '#000000',
                        textAlign: 'center',
                        backgroundColor: '#ffffff',
                        flex: '1',
                        marginRight: 'auto',
                        marginLeft: '35px',
                    }}
                >
                    Felvételi segédlet
                </Typography>
                <IconButton onClick={handleClose} sx={{ padding: 0 }}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </div>
            <Box border="1px solid #ccc" style={{ margin: '2rem' }}>
                <DialogContent style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                    <div dangerouslySetInnerHTML={{ __html: guideContent || '' }} />
                </DialogContent>
            </Box>
            <DialogActions
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                    style={{
                        marginBottom: '1.5rem',
                        display: 'flex',
                    }}
                >
                    Vissza
                </Button>
            </DialogActions>
        </Dialog>
    );
}
