import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Checkbox, FieldGenerator, useFetch } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Option } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import { ITemplatesForm } from './types';

export default function CourseEvaulationFields() {
    const formikProps = useFormikContext<ITemplatesForm>();
    const { values, setFieldValue } = formikProps;

    const { data: courseTypeOptions, loading } = useFetch<Option[]>(
        endpoints.selfEvaluation.questionnaireTemplate.courseTypes,
        '',
    );

    useEffect(() => {
        if (values.type === 'course_evaluation_questionnaire' && values.anonymous !== false) {
            setFieldValue('anonymous', true);
        }
    }, [values.type, setFieldValue, values.anonymous]);

    return (
        <>
            {loading ? (
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
            ) : (
                FieldGenerator({
                    name: 'relatedCourseType',
                    label: 'Kapcsolódó kurzustípus',
                    type: 'select',
                    options: courseTypeOptions,
                    fieldType: 'base',
                    format: {
                        xs: 12,
                    },
                    ...formikProps,
                })
            )}
            <Grid item xs={12}>
                <Checkbox
                    label="Anonim"
                    checkbox={{
                        checked: values.anonymous,
                        name: 'anonymous',
                        onChange: () => {
                            setFieldValue('anonymous', !values.anonymous);
                        },
                    }}
                />
            </Grid>
        </>
    );
}
