import { endpoints } from '../../utils/endPoints';
import instance from '../../api';
import { INotificationForm } from '../../pages/Administration/Notifications/types';
import { IForm } from '../../utils/Interfaces/interfaces';

const notificationsService = {
    filter: (form: IForm) => instance.get(endpoints.administration.notifications.table, { params: form }),
    get: (eventId: string | number) =>
        instance.get(endpoints.administration.notifications.get.replace('{eventId}', eventId?.toString())),
    edit: (eventId: string | number, form: INotificationForm) =>
        instance.post(endpoints.administration.notifications.edit.replace('{eventId}', eventId?.toString()), form),
    activation: (eventId: string | number, active: boolean) =>
        instance.post(endpoints.administration.notifications.activation.replace('{eventId}', eventId?.toString()), {
            activation: active,
        }),
};

export default notificationsService;
