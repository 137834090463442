import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TextField } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import CustomPointPerEvent from './CustomPointPerEvent';
import { getScoringTypeText } from './utils';

interface ScoringRulesProps {
    courseId?: string;
    eventLectures: { id: number }[];
    achievedMinimumPoints: number; // elérendő minimum pontszám
    avaibleMaximumPoints: number;
    customCoursePointPerEventPoints: number;
    customCoursePointPerEventPointsMin: number;
    eventCount: number;
    isArchiveUser?: boolean;
}

export default function ScoringRules<T extends Record<string, string | number>>({
    eventLectures,
    achievedMinimumPoints,
    avaibleMaximumPoints,
    customCoursePointPerEventPoints,
    customCoursePointPerEventPointsMin,
    eventCount,
    isArchiveUser = false,
}: ScoringRulesProps) {
    const formikProps = useFormikContext<T>();
    const { values } = formikProps;
    const scoringType = '' + (values.scoringType || 'point');
    const text = getScoringTypeText(scoringType);
    const [fixCoursePointVal, setFixCoursePointVal] = useState(values.fixCoursePointVal);
    const [customCoursePointValMin, setCustomCoursePointValMin] = useState(values.customCoursePointValMin);
    const [customCoursePointValMax, setCustomCoursePointValMax] = useState(values.customCoursePointValMax);
    const [fixedCoursePointPerEventValMin, setFixedCoursePointPerEventValMin] = useState(
        values.fixedCoursePointPerEventValMin,
    );
    const [fixedCoursePointPerEventValPoint, setFixedCoursePointPerEventValPoint] = useState(
        values.fixedCoursePointPerEventValPoint,
    );
    const v = eventLectures.length * (+formikProps.values.fixedCoursePointPerEventValPoint || 0);

    const error = (fixedCoursePointPerEventValMin as number) > eventCount;
    const title = error
        ? 'A kurzushoz felvett események száma nem éri el a követelményként megadott minimum részvételi számot. Kérlek, vegyél fel további eseményeket!'
        : '';

    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Grid item container xs={12}>
                    <Grid item xs={12} md={4} lg={6}>
                        <Typography variant="h6" style={{ marginTop: '8px' }}>
                            Pontozási szabályok
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        md={8}
                        lg={6}
                        style={{ marginTop: '8px' }}
                        justifyContent="space-between"
                    >
                        <Grid item>
                            Elérendő minimum {text}: <b>{achievedMinimumPoints}</b>
                        </Grid>
                        <Grid item>
                            Elérhető maximum {text}: <b>{avaibleMaximumPoints}</b>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={12} style={{ minHeight: '60px' }}>
                {FieldGenerator({
                    ...{
                        name: 'fixCoursePoint',
                        label: 'Fix kurzuspont',
                        type: 'checkbox',
                        format: { xs: 12, md: 4, lg: 6 },
                        props: {
                            disabled: isArchiveUser || values.readonly,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.checked) {
                                    formikProps.setFieldValue('customCoursePoint', 0);
                                }
                            },
                        },
                    },
                    ...formikProps,
                })}
                {values.fixCoursePoint == 1 &&
                    FieldGenerator({
                        ...{
                            name: 'fixCoursePointVal',
                            label: text.charAt(0).toUpperCase() + text.slice(1) + ' a kurzus teljesítéséért',
                            fieldType: 'base',
                            props: {
                                disabled: isArchiveUser || values.readonly,
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                    const { value } = event.target;
                                    // if ((value && (+value < 0 || +value > 100)) || isNaN(+value) || value.length > 4)
                                    setFixCoursePointVal(value);
                                },
                                value: fixCoursePointVal,
                                inputProps: {
                                    maxLength: 4,
                                },
                            },
                            format: { xs: 12, md: 8, lg: 6 },
                        },
                        ...formikProps,
                    })}
            </Grid>
            <Grid item container xs={12} style={{ minHeight: '60px' }}>
                {FieldGenerator({
                    ...{
                        name: 'customCoursePoint',
                        label: 'Egyéni elbírálású kurzuspont',
                        type: 'checkbox',
                        format: { xs: 6 },
                        props: {
                            disabled: isArchiveUser || values.readonly,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.checked) {
                                    formikProps.setFieldValue('fixCoursePoint', 0);
                                }
                            },
                        },
                    },
                    ...formikProps,
                })}
                {values.customCoursePoint == 1 && (
                    <Grid item container xs={12} sm={6} spacing={2}>
                        {FieldGenerator({
                            ...{
                                name: 'customCoursePointValMin',
                                label: 'Min. ' + text,
                                fieldType: 'base',
                                props: {
                                    disabled: isArchiveUser || values.readonly,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                        const { value } = event.target;
                                        setCustomCoursePointValMin(value);
                                    },
                                    value: customCoursePointValMin,
                                    inputProps: {
                                        maxLength: 4,
                                    },
                                },
                                format: { xs: 6 },
                            },
                            ...formikProps,
                        })}
                        {FieldGenerator({
                            ...{
                                name: 'customCoursePointValMax',
                                label: 'Max. ' + text,
                                fieldType: 'base',
                                props: {
                                    disabled: isArchiveUser || values.readonly,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                        const { value } = event.target;
                                        setCustomCoursePointValMax(value);
                                    },
                                    value: customCoursePointValMax,
                                    inputProps: {
                                        maxLength: 4,
                                    },
                                },
                                format: { xs: 6 },
                            },
                            ...formikProps,
                        })}
                    </Grid>
                )}
            </Grid>
            <Grid item container xs={12} style={{ minHeight: '60px' }}>
                {FieldGenerator({
                    ...{
                        name: 'fixedCoursePointPerEvent',
                        label: 'Eseményenként fixált pont',
                        type: 'checkbox',
                        format: { xs: 12, md: 4 },
                        props: {
                            disabled: isArchiveUser || values.readonly,
                        },
                    },
                    ...formikProps,
                })}
                {values.fixedCoursePointPerEvent == 1 && (
                    <Grid item container xs={12} md={8} spacing={2} alignItems="center">
                        {FieldGenerator({
                            ...{
                                name: 'fixedCoursePointPerEventValMin',
                                label: 'Min. eseményen történő részvétel',
                                props: {
                                    disabled: isArchiveUser || values.readonly,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                        const { value } = event.target;
                                        if (isNaN(+value)) return;
                                        setFixedCoursePointPerEventValMin('' + +value);
                                    },
                                    value: fixedCoursePointPerEventValMin,
                                    inputProps: {
                                        maxLength: 3,
                                    },
                                },
                                format: { xs: 12, sm: 6, md: 4 },
                            },
                            ...formikProps,
                        })}
                        {FieldGenerator({
                            ...{
                                name: 'fixedCoursePointPerEventValPoint',
                                label: 'Eseményenként járó ' + text,
                                fieldType: 'base',
                                props: {
                                    disabled: isArchiveUser || values.readonly,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                        const { value } = event.target;
                                        setFixedCoursePointPerEventValPoint(value);
                                    },
                                    value: fixedCoursePointPerEventValPoint,
                                    inputProps: {
                                        maxLength: 4,
                                    },
                                },
                                format: { xs: 12, sm: 6, md: 3 },
                            },
                            ...formikProps,
                        })}
                        <Tooltip title={title}>
                            <Grid item xs={6} md={3}>
                                <TextField value={eventCount} disabled label="Felvett események száma" error={error} />
                            </Grid>
                        </Tooltip>
                        <Grid item xs={6} md={2}>
                            <TextField value={isNaN(v) ? '' : v.toFixed(1)} disabled label="Összesen" />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <CustomPointPerEvent
                customCoursePointPerEventPoints={customCoursePointPerEventPoints}
                customCoursePointPerEventPointsMin={customCoursePointPerEventPointsMin}
                isArchiveUser={isArchiveUser}
            />
        </Grid>
    );
}
