import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {
    Accordion,
    CancelButton,
    CKEditor,
    Page,
    SubmitButton,
    TabsCompleted,
    TextField,
} from '@silinfo/front-end-template';
import axios, { AxiosError } from 'axios';
import { FastField, Form as FormikForm, Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MCCLoading from '../../../components/MCCLoading';
import pagesService, { IPagesVersionsForm } from '../../../services/administration/pages';
import { create } from '../../../store/notification';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { IEditor } from '../../../utils/Interfaces/interfaces';
import { IPage, IVersion } from './types';
import { initialPage, initialVersion, LanguageObject, languagesObjects } from './utils';
import moment from 'moment';
import { useTenant } from '../../../components/TenantContext';

const useStyles = makeStyles(() => ({
    richTextEditor: {
        '& .ck-editor__main > .ck-editor__editable': {
            height: '50vh',
        },
    },
}));

const TabContent = ({
    readOnly,
    language,
    page,
    version,
}: {
    readOnly?: boolean;
    language: LanguageObject;
    page: IPage;
    version: IVersion;
}) => {
    const classes = useStyles();
    const { setFieldValue, values } = useFormikContext<IVersion>();

    return (
        <>
            <FastField as="div">
                <TextField disabled value={page.translations[language?.value || 'hu']?.title || ''} />
            </FastField>
            {readOnly ? (
                <>
                    <Typography>Tartalom</Typography>
                    <div
                        style={{ padding: '4px 8px', margin: '4px 0', backgroundColor: '#ccc' }}
                        dangerouslySetInnerHTML={{
                            __html: version.translations[language.value]?.content || '',
                        }}
                    />
                </>
            ) : (
                <div className={classes.richTextEditor}>
                    <FastField as="div">
                        <CKEditor
                            data={values.translations[language.value]?.content || ''}
                            onChange={(_: unknown, editor: IEditor) => {
                                setFieldValue(`translations[${language.value}].content`, editor.getData());
                            }}
                        />
                    </FastField>
                </div>
            )}
        </>
    );
};

export default function VersionForm({ readOnly }: { readOnly?: boolean }) {
    const { pageId, versionId } = useParams();
    const [loading, setLoading] = useState<boolean>(!!pageId);
    const [page, setPage] = useState<IPage>(initialPage);
    const [version, setVersion] = useState<IVersion>(initialVersion);
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const title = versionId ? (readOnly ? 'Verzió megtekintése' : 'Verzió szerkesztése') : 'Verzió hozzáadása';
    const navigate = useNavigate();
    const tenant = useTenant();

    useEffect(() => {
        if (pageId) {
            axios
                .all([
                    pagesService.getPage(pageId),
                    pagesService.filterVersions(pageId, {}),
                    ...(versionId ? [pagesService.getVersion(pageId, versionId)] : []),
                ])
                .then((response) => {
                    const versionsData = response[1].data['hydra:member'];
                    const pageData = response[0].data;
                    setPage(pageData);

                    if (versionId) {
                        const versionData = response[2].data;

                        const startDate = moment(versionData.startDate)
                            .toDate()
                            .toLocaleDateString('hu')
                            .replaceAll('.', '')
                            .replaceAll(' ', '-');
                        setVersion({ ...versionData, startDate });
                    } else {
                        if (versionsData.length > 0) {
                            const date = moment(versionsData.slice(-1)[0].startDate).toDate();
                            const nextDay = moment(date).toDate();
                            nextDay.setDate(date.getDate() + 1);
                            const startDate = moment(nextDay)
                                .toDate()
                                .toLocaleDateString('hu')
                                .replaceAll('.', '')
                                .replaceAll(' ', '-');
                            setVersion((prev) => ({
                                ...prev,
                                startDate: startDate,
                            }));
                        } else {
                            const date = new Date();
                            setVersion((prev) => ({
                                ...prev,
                                startDate: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
                            }));
                        }
                    }
                })
                .catch((error) => alert(error))
                .finally(() => setLoading(false));
        }
    }, [pageId, versionId]);

    const header = {
        project: tenant || 'TAR',
        title: 'Oldalak',
        breadcrumbs: {
            admin: 'Adminisztráció',
            [clientEndPoints.admin_pages_list]: 'Oldalak',
            [clientEndPoints.admin_pages_versions_list.replace(':pageId', pageId ? pageId.toString() : '')]:
                page.translations[null || 'hu'].title, //TODO: Felhasználó nyelve szerint
            form: title,
        },
    };

    const myTabContents = languagesObjects.map((language) => {
        return {
            label: language.label,
            value: (
                <TabContent
                    key={language.label}
                    readOnly={readOnly}
                    language={language}
                    page={page}
                    version={version}
                />
            ),
        };
    });

    const handleSubmit = (form: IVersion) => {
        setSubmitting(true);

        const startDateDate = moment(form.startDate || '').toDate();

        const formToSend: IPagesVersionsForm = {
            content: {
                en: {
                    content: form.translations.en.content,
                },
                hu: {
                    content: form.translations.hu.content,
                },
            },
            startDate:
                startDateDate.getFullYear() + '-' + (startDateDate.getMonth() + 1) + '-' + startDateDate.getDate(),
        };

        if (pageId) {
            (versionId
                ? pagesService.updateVersion(pageId, versionId, formToSend)
                : pagesService.createVersion(pageId, formToSend)
            )
                .then(() =>
                    dispatch(
                        create({
                            type: 'success',
                            message: versionId ? 'Sikeres módosítás!' : 'Sikeres létrehozás!',
                            redirect: clientEndPoints.admin_pages_versions_list.replace(':pageId', pageId ?? ''),
                        }),
                    ),
                )
                .catch((e: AxiosError) => {
                    if (e.response?.status === 422) {
                        dispatch(create({ type: 'error', message: e.response.data.violations[0].message }));
                    } else {
                        dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
                    }
                })
                .finally(() => setSubmitting(false));
        }
    };

    if (loading) return <MCCLoading />;
    return (
        <Page header={header}>
            <Accordion title={title}>
                <Formik initialValues={version} onSubmit={handleSubmit}>
                    {({ values, setFieldValue }) => {
                        return (
                            <FormikForm>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TabsCompleted tabContents={myTabContents} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FastField as="div" field="startDate">
                                            <TextField
                                                id="startDate"
                                                label="Hatálybalépés dátuma"
                                                name="startDate"
                                                disabled={readOnly}
                                                value={values.startDate}
                                                onChange={(e) => {
                                                    setFieldValue('startDate', e.target.value);
                                                }}
                                                type="date"
                                            />
                                        </FastField>
                                    </Grid>
                                    <Grid item container xs={12} spacing={2}>
                                        {!readOnly && (
                                            <Grid item>
                                                <SubmitButton type="submit" loading={submitting}>
                                                    Mentés
                                                </SubmitButton>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <CancelButton
                                                disabled={submitting}
                                                onClick={() =>
                                                    navigate(
                                                        clientEndPoints.admin_pages_versions_list.replace(
                                                            ':pageId',
                                                            pageId ?? '',
                                                        ),
                                                    )
                                                }
                                            >
                                                {readOnly ? 'Vissza' : 'Mégsem'}
                                            </CancelButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}
