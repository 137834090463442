import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Paper } from '../Requirements/Paper';
import Event from './Event';
import './EventCalendar.css';
import Tile from './Tile';
import { IEvent } from './types';
import { getDay, oneYearTime } from './utils';
import DateStateContext from './DateStateContext';
import moment from 'moment';

export default function EventCalendar({ data }: { data: IEvent[] }) {
    const [date, setDate] = useContext(DateStateContext);
    const [activeStartDate, setActiveStartDate] = useState(new Date());

    useEffect(() => {
        setActiveStartDate(date);
    }, [date]);

    const events = data.filter(
        (event) => moment(event.date).format('YYYY. MM. DD.') === moment(date).format('YYYY. MM. DD.'),
    );

    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} md={8}>
                <Paper>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <Calendar
                                value={date}
                                onChange={setDate}
                                minDetail="month"
                                maxDetail="month"
                                activeStartDate={activeStartDate}
                                onActiveStartDateChange={({ activeStartDate }) => setActiveStartDate(activeStartDate)}
                                tileContent={({ date }) => <Tile date={date} events={data} />}
                                minDate={new Date(new Date().getTime() - oneYearTime * 3)}
                                maxDate={new Date(new Date().getTime() + oneYearTime * 3)}
                                nextLabel={<KeyboardArrowRight />}
                                next2Label={<KeyboardDoubleArrowRight />}
                                prevLabel={<KeyboardArrowLeft />}
                                prev2Label={<KeyboardDoubleArrowLeft />}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} alignSelf="flex-start">
                <Paper>
                    <Grid item container xs={12} spacing={3}>
                        <Grid item xs={12}>
                            <Typography textAlign="center" variant="h6">
                                <strong>
                                    {date.toLocaleDateString()}, {getDay(date)}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} spacing={1} alignSelf="flex-start">
                            {events.length > 0 ? (
                                events.map((event) => <Event key={event.id} {...event} />)
                            ) : (
                                <Grid item xs={12}>
                                    <Typography textAlign="center">
                                        <i>Nincs megjelenítendő adat</i>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
