import Chip, { ChipProps } from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import { useCallback, useState } from 'react';

interface DropdownChipProps extends Omit<ChipProps, 'children'> {
    children: React.ReactNode;
}

export default function DropdownChip({ children, ...rest }: DropdownChipProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    return (
        <>
            <Chip onClick={handleOpen} {...rest} />
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                PaperProps={{ style: { padding: '0 8px' } }}
            >
                {children}
            </Menu>
        </>
    );
}
