import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { CancelButton, FieldGenerator, Option, TextField } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import coursesService from '../../../../services/masterData/courses';
import coursePrerequisitesService from '../../../../services/masterData/courses/coursePrerequisites/coursePrerequisites';
import { RootState } from '../../../../store';
import { ICoursePrerequisitePreSubmitForm } from './AddPrerequisiteButton';
import courseListService from '../../../../services/courseManagement/courseList';
import { AxiosResponse } from 'axios';
import { theme } from '../../../../utils/theme';

export default function PrerequisiteForm({
    submitBtnTitle,
    handleClose,
    courseId,
    courseTenant,
    ...props
}: FormikProps<ICoursePrerequisitePreSubmitForm> & {
    submitBtnTitle: string;
    handleClose: () => void;
    objectId?: number;
    courseId: number;
    courseTenant: string | null;
}) {
    const [tenantOptions, setTenantOptions] = useState<Option[]>([]);
    const { count } = useSelector((state: RootState) => state.loading);
    const [options, setOptions] = useState<Option[]>([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const n = 3;
    const { setFieldValue } = props;

    useEffect(() => {
        courseListService.tenantsByCourse(courseId.toString()).then((res) => {
            setTenantOptions([...res.data]);
        });
        setFieldValue('course', courseId);
    }, [setFieldValue, courseId]);

    useEffect(() => {
        if (
            typeof props.values.coursePrerequisite === 'object' &&
            props.values.coursePrerequisite &&
            props.values.tenant
        ) {
            coursesService
                .asPrerequisite(props.values.tenant as string, props.values.coursePrerequisite.value, courseTenant)
                .then((res) => {
                    setFieldValue('courseReferenceCode', res.data.courseReferenceCode);
                    setFieldValue('courseReferenceId', res.data.id);
                });
        }
    }, [props.values.coursePrerequisite, props.values.tenant, setFieldValue, courseTenant]);

    const handleInputChange = (e: React.SyntheticEvent<Element, Event>, value: string) => {
        if (!e) return props.setFieldValue('coursePrerequisite', value ?? '');
        props.setFieldValue('coursePrerequisite', value);
        if (value.length >= n) {
            setLoading(true);
            coursePrerequisitesService
                .listByCourseRefCode(value, courseTenant, props.values?.tenant as string, courseId)
                .then((response: AxiosResponse) =>
                    setOptions(
                        response.data.map((course: { value: string; label: string }) => ({
                            value: course.value.toString(),
                            label: course.label,
                        })),
                    ),
                )
                .finally(() => setLoading(false));
        }
    };

    return (
        <>
            <Grid item xs={12} sm={12}>
                {FieldGenerator({
                    name: 'tenant',
                    label: 'Tenant',
                    type: 'select',
                    fieldType: 'base',
                    format: { xs: 12 },
                    options: tenantOptions,
                    props: {
                        disableClearable: true,
                        renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                            <li {...props}>{option.label}</li>
                        ),
                        getOptionLabel: (option: Option) => option.label || '',
                    },
                    ...props,
                })}
                <Autocomplete
                    onInputChange={handleInputChange}
                    onOpen={() => setOpen(true)}
                    onClose={() => {
                        setOptions([]);
                        setOpen(false);
                    }}
                    inputValue={(props.values?.coursePrerequisite as string) ?? ''}
                    open={open}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={'Írjon be ' + n + ' karaktert a kereséshez'} size="small" />
                    )}
                    options={options}
                    noOptionsText={
                        loading
                            ? 'Betöltés...'
                            : (props.values?.coursePrerequisite as string).length >= n
                            ? 'Nincs találat'
                            : 'Írjon be ' + n + ' karaktert a kereséshez'
                    }
                    isOptionEqualToValue={(option, value) =>
                        (typeof option !== 'string' && typeof value !== 'string' && option.value == value.value) ||
                        option == value
                    }
                    ChipProps={{
                        sx: {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText,
                            '& .MuiChip-deleteIcon': {
                                color: theme.palette.primary.main,
                            },
                        },
                    }}
                    disabled={!('tenant' in props.values)}
                    value={props.values?.coursePrerequisite as Option<string>}
                    onChange={(_, v) => props.setFieldValue('coursePrerequisite', v ?? '')}
                />
            </Grid>
            <Grid item container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <LoadingButton loading={count > 0} variant="contained" type="submit">
                        {submitBtnTitle}
                    </LoadingButton>
                </Grid>
                <Grid item>
                    <CancelButton onClick={handleClose}>Bezár</CancelButton>
                </Grid>
            </Grid>
        </>
    );
}
