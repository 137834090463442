import { useEffect, useState } from 'react';
import { deleteRefreshLocalStorage, getRefreshLocalStorage } from '../components/PreviewTemplate/utils';

export default function useCheckRefresh(): boolean {
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const checker = setInterval(() => {
            const item = getRefreshLocalStorage();
            if (item) {
                setRefresh((prev) => !prev);
                deleteRefreshLocalStorage();
            }
        }, 1000);
        return () => clearInterval(checker);
    }, [setRefresh]);

    return refresh;
}
