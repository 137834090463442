import { endpoints } from '../../../../utils/endPoints';
import { crudTemplate } from '../../../templates';
import instance from '../../../../api';
import { fixDateRangeFields } from '../../../../utils/AppConst';
import { ISemester } from '../../../../pages/CourseManagement/Courses/types';
import { IForm } from '../../../../utils/Interfaces/interfaces';

export interface IAdministrationFeeType {
    id: string | number;
    flag: boolean;
    used: boolean;
    scholarship: boolean;
    type: string;
    category?: string;
    typeShort: string;
    valuesCount: number;
    financeAdministrationFeeVals: IAdministrationFeeValType[];
}

export interface IAdministrationFeeValType {
    id: number;
    amount: number;
    financeAdministrationFee: IAdministrationFeeType;
    semester: ISemester;
}

export interface IAdministrationFeeValueFormType {
    id: string | number;
    semester: string | number;
    amount: string | number;
}

export interface IAdministrationFeeForm extends IForm {
    category: string;
    type: string;
    typeShort: string;
}
const administrationFeesService = {
    ...crudTemplate<IAdministrationFeeForm>(endpoints.masterData.finance.administrationFee.main),
    feesByTypes: (form: IForm) =>
        instance.get(endpoints.masterData.finance.administrationFee.feesByTypes, { params: fixDateRangeFields(form) }),
    semestersWithGivenAdministrationFee: (administrationFeeId: string | number) =>
        instance.get(
            endpoints.masterData.finance.administrationFee.semestersWithGivenAdministrationFee +
                '?administrationFee=' +
                administrationFeeId.toString(),
        ),
    listForMultiselect: () => instance.get(endpoints.masterData.finance.administrationFee.list),
    getUniqueAdministrationFee: (id: number) =>
        instance.get(endpoints.masterData.students.uniqueAdministrationFee.replace('{id}', id?.toString())),
    getStudentFeeProcesses: () => instance.get(endpoints.masterData.finance.administrationFee.getProcesses),
    filter: (form: IForm) =>
        instance.get(endpoints.finance.studentFeeProcesses.list, { params: fixDateRangeFields(form) }),
    filterGuest: (form: IForm) =>
        instance.get(endpoints.finance.studentFeeProcesses.listGuest, { params: fixDateRangeFields(form) }),
    semesters: () => instance.get(endpoints.finance.studentFeeProcesses.semesters),
};

export default administrationFeesService;
