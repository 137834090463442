import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { Input } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/src/components/Form/types';
import { FormikProps } from 'formik';
import { IForm } from '../../../../../../utils/Interfaces/interfaces';
import { SectionRow } from '../SectionGenerator';
import ActualUniversityStudiesDialogFormContent from './ActualUniversityStudiesDialogFormContent';

export type ActualUniversityStudiesDialogFormProps = {
    formikProps: FormikProps<IForm>;
    actualUniversityStudiesFields: Input[];
    optionData: [] | Option<string, string>[];
    handleCancel: () => void;
    row: SectionRow | undefined;
};

export default function ActualUniversityStudiesDialogForm(props: ActualUniversityStudiesDialogFormProps) {
    const { handleCancel } = props;

    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <ActualUniversityStudiesDialogFormContent {...props} />
            </Grid>
            <Grid item xs={12}>
                <DialogActions>
                    <Button variant="outlined" onClick={handleCancel}>
                        Mégsem
                    </Button>
                    <LoadingButton variant="contained" type="submit">
                        Mentés
                    </LoadingButton>
                </DialogActions>
            </Grid>
        </Grid>
    );
}
