import Grid from '@mui/material/Grid';
import { endpoints } from '../../../utils/endPoints';
import DesktopView from './DesktopView';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileView from './MobileView';
import { useEffect, useState } from 'react';
import instance from '../../../api';
import { IRow } from './types';

export default function Table({ selectedProgram }: { selectedProgram: null | number }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IRow[]>([]);

    useEffect(() => {
        setLoading(true);
        setData([]);
        instance
            .get(
                endpoints.students.requirements.mandatoryCourseList +
                    (selectedProgram ? '?program=' + selectedProgram : ''),
            )
            .then((res) => {
                setData(res.data);
                setLoading(false);
            });
    }, [selectedProgram, setData, setLoading]);

    const matches = useMediaQuery('(min-width:960px)');

    return (
        <Grid item container xs={12} alignSelf="flex-start">
            {matches ? <DesktopView loading={loading} data={data} /> : <MobileView loading={loading} data={data} />}
        </Grid>
    );
}
