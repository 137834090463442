import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { CancelButton } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import courseListService from '../../../../services/courseManagement/courseList';
import { RootState } from '../../../../store';
import { Option } from '../../../../utils/AppConst';
import { ITargetAudienceForm } from './EditTargetAudienceButton';
import { endpoints } from '../../../../utils/endPoints';
import instance from '../../../../api';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function EditTargetAudienceForm({
    submitBtnTitle,
    handleClose,
    ...props
}: FormikProps<ITargetAudienceForm> & { submitBtnTitle: string; handleClose: () => void }) {
    const { count } = useSelector((state: RootState) => state.loading);
    const [tenantOptions, setTenantOptions] = useState<Option[]>([{ label: 'Összes', value: '-1' }]);
    const [campusOptions, setCampusOptions] = useState<Option[]>([]);
    const [trainingLevelOptions, setTrainingLevelOptions] = useState<Option[]>([]);
    const [trainingProgramOptions, setTrainingProgramOptions] = useState([]);
    const [collegeYearOptions, setCollegeYearOptions] = useState([]);
    const { values } = props;
    const { courseId } = useParams() as { courseId: string };

    useEffect(() => {
        if (values.tenant) {
            if (+values.tenant === -1) {
                setCampusOptions([]);
                setTrainingLevelOptions([]);
            } else {
                axios
                    .all([
                        courseListService.campusesByTenant(values.tenant),
                        instance.get(endpoints.masterData.trainingLevels.byTenant(values.tenant)),
                    ])
                    .then(
                        axios.spread((campusResponse, trainingLevelResponse) => {
                            const campusOptions = campusResponse.data;
                            const trainingLevelOptions = trainingLevelResponse.data;

                            setCampusOptions(campusOptions);
                            setTrainingLevelOptions(trainingLevelOptions);
                        }),
                    );
            }
        }
    }, [values.tenant]);

    useEffect(() => {
        if (values.tenant) {
            if (+values.tenant === -1) {
                setCampusOptions([]);
                setTrainingLevelOptions([]);
            } else {
                axios
                    .all([
                        courseListService.campusesByTenant(values.tenant),
                        instance.get(endpoints.masterData.trainingLevels.byTenant(values.tenant)),
                    ])
                    .then(
                        axios.spread((campusResponse, trainingLevelResponse) => {
                            const campusOptions = campusResponse.data;
                            const trainingLevelOptions = trainingLevelResponse.data;

                            setCampusOptions(campusOptions);
                            setTrainingLevelOptions(trainingLevelOptions);
                        }),
                    );
            }
        }
    }, [values.tenant]);

    useEffect(() => {
        if (values.tenant && values.trainingLevel) {
            if (+values.trainingLevel === -1) {
                setTrainingProgramOptions([]);
                setCollegeYearOptions([]);
            } else {
                instance
                    .get(endpoints.masterData.trainingPrograms.byTenant(values.tenant, values.trainingLevel.toString()))
                    .then((response) => {
                        const options = response.data;
                        setTrainingProgramOptions(options);
                    });
            }
        }
    }, [values.trainingLevel, values.tenant]);

    useEffect(() => {
        if (values.tenant && values.trainingLevel && values.trainingProgram) {
            instance
                .get(endpoints.masterData.collegeYears.byTrainingProgram(values.trainingProgram.toString()))
                .then((response) => {
                    const options = response.data;
                    setCollegeYearOptions(options);
                });
        }
    }, [values.trainingProgram, values.trainingLevel, values.tenant]);

    useEffect(() => {
        axios.all([
            courseListService.tenantsByCourse('' + courseId).then((res) => {
                setTenantOptions(res.data);
            }),
        ]);
    }, [courseId, values.id, values.tenant]);

    return (
        <>
            {FieldGenerator({
                name: 'tenant',
                label: 'Tenant',
                type: 'select',
                format: { xs: 12 },
                fieldType: 'base',
                options: tenantOptions,
                props: {
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        props.setFieldValue('tenant', e.target.value);
                        props.setFieldValue('campus', '');
                        props.setFieldValue('trainingLevel', '');
                        props.setFieldValue('trainingProgram', '');
                        props.setFieldValue('collegeYear', '');
                    },
                },
                ...props,
            })}

            {FieldGenerator({
                name: 'campus',
                label: 'Campus',
                type: 'select',
                format: { xs: 12 },
                fieldType: 'base',
                options: campusOptions,
                props: {
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        props.setFieldValue('campus', e.target.value);
                        props.setFieldValue('trainingLevel', '');
                        props.setFieldValue('trainingProgram', '');
                        props.setFieldValue('collegeYear', '');
                    },
                    disabled: tenantOptions.length === 0,
                },
                ...props,
            })}

            {FieldGenerator({
                name: 'trainingLevel',
                label: 'Képzési szint',
                type: 'select',
                format: { xs: 12 },
                fieldType: 'base',
                options: trainingLevelOptions,
                props: {
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        props.setFieldValue('trainingLevel', e.target.value);
                        props.setFieldValue('trainingProgram', '');
                        props.setFieldValue('collegeYear', '');
                    },
                    disabled: tenantOptions.length === 0 || trainingLevelOptions.length === 0,
                },
                ...props,
            })}

            {FieldGenerator({
                name: 'trainingProgram',
                label: 'Képzési program',
                type: 'select',
                format: { xs: 12 },
                fieldType: 'base',
                options: trainingProgramOptions,
                props: {
                    disabled: trainingProgramOptions.length === 0,
                },
                ...props,
            })}

            {FieldGenerator({
                name: 'collegeYear',
                label: 'Évfolyam',
                type: 'select',
                format: { xs: 12 },
                fieldType: 'base',
                options: collegeYearOptions,
                props: {
                    disabled: collegeYearOptions.length === 0,
                },
                ...props,
            })}

            <Grid item container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <LoadingButton loading={count > 0} variant="contained" type="submit">
                        {submitBtnTitle}
                    </LoadingButton>
                </Grid>
                <Grid item>
                    <CancelButton onClick={handleClose}>Bezár</CancelButton>
                </Grid>
            </Grid>
        </>
    );
}
