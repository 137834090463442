import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { removeLayoutTyper } from '../../../utils/AuthHelper';
import useSwitchCheck from '../../../utils/useSwitchCheck';
import useProfilePicture from './useProfilePicture';
import { AxiosError, AxiosResponse } from 'axios';
import userService from '../../../services/masterData/users';
import { setJWTToken } from '../../../utils/AuthHelper';
import { useSelector } from 'react-redux';
import { history, RootState } from '../../../store';

export default function Account() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { loading, profilePicture } = useProfilePicture();
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const canSwitch = useSwitchCheck();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        removeLayoutTyper();
        window.location.href = process.env.REACT_APP_AUTH_URL + '/logout';
        handleClose();
    };
    const handleProfileClick = () => {
        navigate('/student/profile');
        handleClose();
    };
    const handleSystemSelector = () => {
        window.location.href = process.env.REACT_APP_AUTH_URL as string;
        handleClose();
    };

    const handleLogoutInLoginedAs = () => {
        userService
            .userLoginInAs()
            .then((response: AxiosResponse) => {
                setJWTToken(response.data.token);
                removeLayoutTyper();
                window.location.href = `${process.env.REACT_APP_AUTH_URL || 'https://auth01.mcc.hu/'}`;
            })
            .catch((error: AxiosError) => {
                console.error(error.response?.data?.error);
            });
        handleClose();
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                {loading ? <Skeleton variant="circular" /> : <Avatar alt="profile picture" src={profilePicture} />}
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleSystemSelector}>Rendszerválasztó</MenuItem>
                {canSwitch.layoutTypes.length === 2 ? (
                    <MenuItem
                        onClick={() => {
                            history.push('/switch-layout');
                        }}
                    >
                        Nézetváltás
                    </MenuItem>
                ) : null}
                {user.realUser !== null ? (
                    <MenuItem onClick={handleLogoutInLoginedAs}>Kilépés a megszemélyesítésből</MenuItem>
                ) : null}
                <MenuItem onClick={handleProfileClick}>Profil</MenuItem>
                <MenuItem onClick={handleLogout}>Kijelentkezés</MenuItem>
            </Menu>
        </>
    );
}
