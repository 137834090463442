import Chip from '@mui/material/Chip';
import i18n from '../../../../i18n';
import ErrorStatusButton, { IEmailErrorText } from './ErrorStatusButton';

export default function StateCellRenderer({ value, row }: { value: string; row?: IEmailErrorText }) {
    if (value === 'error') {
        return <ErrorStatusButton errorText={row?.errorText} />;
    }
    const colors = new Map([
        ['success', 'success'],
        ['error', 'error'],
        ['waiting', 'warning'],
        ['generation', 'info'],
    ]);
    const color = (colors.get(value) || 'default') as
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';

    return <Chip color={color} label={i18n.t('administration.email_log.status.' + value) as string} />;
}
