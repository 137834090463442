import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PreviewTemplate from '../../../components/PreviewTemplate';
import questionnaireTemplateService from '../../../services/selfEvalution/questionnaireTemplate';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { endpoints } from '../../../utils/endPoints';
import { setRefreshLocalStorage } from '../../../components/PreviewTemplate/utils';

export default function SelfAssessmentQuestionnaire() {
    const { id } = useParams() as { id: string };

    const handleSubmit = useCallback(
        async (values: IForm, formIndex: number, finalization?: boolean) => {
            await questionnaireTemplateService.fillForm2(id, values, formIndex, !!finalization);
            return setRefreshLocalStorage();
        },
        [id],
    );
    return (
        <PreviewTemplate
            url={endpoints.students.selfEvaluation.form(id)}
            keyPrefix="student_preview"
            onSubmit={handleSubmit}
            formName="Önértékelés és célkitűzés"
            type="selfEvaluation"
        />
    );
}
