import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import useFetch from '../../utils/useFetch';
import { endpoints } from '../../utils/endPoints';
import { Loading } from '@silinfo/front-end-template';
import Row from './Row';
import { IDetailedEvent } from './types';
import moment from 'moment';
import { getScoringTypeText } from '../../pages/CourseManagement/Courses/BaseData/utils';

const rowGenerator = (data: IDetailedEvent) => [
    {
        label: 'Időpont',
        value:
            moment(data.start).format('YYYY. MM. DD. HH:mm:ss') +
            (data.end ? ' - ' + moment(data.end).format('YYYY. MM. DD. HH:mm:ss') : ''),
    },
    {
        //label: 'Oktató' + (data.instructors && data.instructors.length > 1 ? 'k' : ''),
        label: 'Oktatók',
        value: data.instructors ?? '-',
    },
    ...(data.guestInstructors
        ? [
              {
                  //label: 'Vendégoktató' + (data.guestInstructors && data.guestInstructors.length > 1 ? 'k' : ''),
                  label: 'Vendégoktatók',
                  value: data.guestInstructors ?? '',
              },
          ]
        : []),
    {
        label: 'Helyszín',
        value: data?.location?.map((location, index) => <div key={index}>{location}</div>),
    },
    {
        label: 'Pontozás',
        value:
            data.pointMin && data.pointMax ? (
                'Min: ' +
                data.pointMin +
                ' ' +
                getScoringTypeText(data.scoringType || 'point') +
                ', max: ' +
                data.pointMax +
                ' ' +
                getScoringTypeText(data.scoringType || 'point')
            ) : (
                <i>Ismeretlen</i>
            ),
    },
];

export default function Content({ id }: { id: string | number }) {
    const { data, loading } = useFetch<IDetailedEvent>(endpoints.students.calendar.main + '/' + id, '') as {
        data: IDetailedEvent;
        loading: boolean;
    };

    if (loading) {
        return <Loading noHeight />;
    }
    const rows = rowGenerator(data);

    return (
        <DialogContent>
            <Grid container>
                <Grid item container xs={12} spacing={2}>
                    {rows.map((row) => (
                        <Row key={row.label} {...row} />
                    ))}
                </Grid>
            </Grid>
        </DialogContent>
    );
}
