import { SemesterStatus } from '../../studentPages/Profile/Tabs/SemesterStatusDialog';
import { IForm } from './interfaces';

export default interface IStudentRequestApproval extends IForm {
    id: number;
    reason: string;
    course?: IStudentRequestCourse;
    requestData?: IStudentRequestData;
    status: string;
    student: string;
    type: string;
    typeOrig: string;
    tenant?: string;
}

export interface IStudentRequestCourse extends IForm {
    code: string;
    name: string;
    semesterCode: string;
    normalEnrollments: number;
    maxHeadCount: number;
    minHeadCount: number;
    closed?: boolean;
}

export interface IStudentRequestData {
    id: number;
    actualStatus?: SemesterStatus;
    hostCity?: string;
    hostCountry?: string;
    hostMajor?: string;
    hostName?: string;
    reason?: string;
    requestedStatus?: SemesterStatus;
    stayAbroadEnd?: string;
    stayAbroadStart?: string;
    stayAbroadType?: string;
    student?: string;
    studentId?: number;
}

export const initialStudentRequestApproval: IStudentRequestApproval = {
    id: 0,
    reason: '',
    course: undefined,
    requestData: undefined,
    status: '',
    student: '',
    type: '',
    typeOrig: '',
};
