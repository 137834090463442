import SettingsForm from '../../pages/Administration/Settings/SettingsForm';
import Settings from '../../pages/Administration/Settings/Settings';

export const settings = {
    path: 'settings',
    children: [
        {
            path: '',
            element: <Settings />,
            name: 'admin_settings_list',
        },
        {
            path: ':settingId/edit',
            element: <SettingsForm />,
            name: 'admin_settings_form',
        },
    ],
};
