import { endpoints } from '../../utils/endPoints';
import { Address } from '../../utils/Interfaces/interfaces';
import { crudTemplate } from '../templates';
import instance from '../../api';

export interface ICampusesForm {
    name: string;
    shortName: string;
    active: boolean;
    country: string;
    address: Address;
}

export const initialForm = {
    name: '',
    shortName: '',
    active: false,
    country: '',
    address: {
        postCode: '',
        city: '',
        streetType: '',
        streetName: '',
        houseNumber: '',
    },
};

const campusesService = {
    ...crudTemplate<ICampusesForm>(endpoints.masterData.campuses.main),
    sync: () => instance.get(endpoints.masterData.campuses.sync),
};

export default campusesService;
