import { Dispatch, SetStateAction } from 'react';
import DialogBasic from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { CancelButton } from '@silinfo/front-end-template';

export default function QuestionnaireDialog({
    questionnaireDialogOpen,
    setQuestionnaireDialogOpen,
    handleSubmit,
}: {
    questionnaireDialogOpen: boolean;
    setQuestionnaireDialogOpen: Dispatch<SetStateAction<boolean>>;
    handleSubmit: () => void;
}) {
    const msg =
        'A kurzushoz nem került beállításra kérdőív. Biztosan kérdőív nélkül szeretnéd létrehozni a kurzust?\n' +
        'Amennyiben később szeretnéd megadni a kérdőívet, úgy azt a kurzus szerkesztésekor is megteheted.';

    return (
        <DialogBasic open={questionnaireDialogOpen} fullWidth>
            <DialogTitle>Figyelmeztetés</DialogTitle>
            <DialogContent>
                <Grid container style={{ paddingTop: 16, paddingBottom: 16 }}>
                    {msg}
                </Grid>
                <Grid item container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <LoadingButton
                            variant="contained"
                            loading={false}
                            type="submit"
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Mentés
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <CancelButton
                            onClick={() => {
                                setQuestionnaireDialogOpen((prev) => !prev);
                            }}
                        >
                            Vissza
                        </CancelButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </DialogBasic>
    );
}
