import { useEffect, useState } from 'react';
import MCCLoading from '../../../../../../components/MCCLoading';
import { useParams } from 'react-router-dom';
import { Page, TabsCompleted } from '@silinfo/front-end-template';
import trainingProgramRequirementsService from '../../../../../../services/masterData/trainingProgramRequirements';
import Grid from '@mui/material/Grid';
import BaseRequirements from './Base/BaseRequirements';
import CourseTypeRequirements from './CourseType/CourseTypeRequirements';
import RequiredCourses from './RequiredCourses';
import { clientEndPoints } from '../../../../../../utils/clientEndPoints';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import trainingLevelService from '../../../../../../services/masterData/trainingLevels';
import trainingProgramsService from '../../../../../../services/masterData/trainingPrograms';
import { Option } from '../../../../../../utils/AppConst';
import { useTenant } from '../../../../../../components/TenantContext';

export default function Requirements() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState<boolean>(true);
    const { id, programId, campusId } = useParams();
    const [campus, setCampus] = useState<{ id: number; name: string } | null>(null);
    const [levelName, setLevelName] = useState('');
    const [programName, setProgramName] = useState('');
    const tenant = useTenant();

    useEffect(() => {
        trainingProgramRequirementsService.campus(id, programId, campusId).then((response) => {
            setCampus(response.data);
            setLoading(false);
        });

        trainingLevelService.trainingLevels().then((response) => {
            setLevelName(response.data.find((element: Option) => element.value == id).label);
        });

        trainingProgramsService.trainingPrograms().then((response) => {
            setProgramName(response.data.find((element: Option) => element.value == programId).label);
        });
    }, [campusId, id, programId]);

    if (loading) {
        return <MCCLoading />;
    }

    const header = {
        project: tenant || 'TAR',
        title: 'Képzési programok',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: levelName + ' szint',
            [clientEndPoints.md_training_levels_programs_list.replace(':id', id?.toString() || '')]:
                programName + ' program',
            [clientEndPoints.md_training_levels_programs_requirements_campuses
                .replace(':id', id?.toString() || '')
                .replace(':programId', programId?.toString() || '')]: campus?.name + ' követelményei',
        },
    };

    const tabContents = () => [
        {
            label: 'Általános követelmények',
            value: <BaseRequirements readonly={!!user.archive} />,
        },
        {
            label: 'Kötelező kurzusok',
            value: <RequiredCourses readonly={!!user.archive} />,
        },
        {
            label: 'Választható kurzustípusok',
            value: <CourseTypeRequirements readonly={!!user.archive} />,
        },
    ];

    return (
        <Page header={header}>
            <Grid item xs={12}>
                <TabsCompleted tabContents={tabContents()} />
            </Grid>
        </Page>
    );
}
