import InfoIcon from '@mui/icons-material/Info';
import { TableButton } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import { useStyles } from '../../../../../Administration/Approvals/RequestFormDialog/ApprovalActualStatusDialog';
import { ProcessDialogProps } from './types';

export const ProcessDialogButton = (props: {
    id: number;
    Dialog: (props: ProcessDialogProps) => JSX.Element;
    isMoveOut?: boolean;
}) => {
    const { id, Dialog, isMoveOut } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const dialogProps = {
        id: id,
        open: open,
        isMoveOut: isMoveOut,
        handleClose: handleClose,
    };

    return (
        <>
            <TableButton onClick={handleOpen} tooltip={`Információ`} color={'info'} className={classes.eyeIcon}>
                <InfoIcon />
            </TableButton>
            <Dialog {...dialogProps} />
        </>
    );
};
