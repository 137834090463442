import Grid from '@mui/material/Grid';
import { CancelButton, Link, SubmitButton } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import { ISemestersForm } from '../../../../services/masterData/semesters';
import { RootState } from '../../../../store';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import Holidays from './Holidays';
import { formFields } from './fields';
import ClosingButtons from './ClosingButtons';

export default function SemesterFormFields({
    form,
    setForm,
    readonly,
    setPageLoading,
    lastClosingProcesssLog,
    ...props
}: FormikProps<ISemestersForm> & {
    form: ISemestersForm;
    setForm: React.Dispatch<React.SetStateAction<ISemestersForm>>;
    setPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
    lastClosingProcesssLog: null | number;
    readonly: boolean;
}) {
    const { count } = useSelector((state: RootState) => state.loading);
    const { user } = useSelector((state: RootState) => state.auth);
    const { semesterId } = useParams() as { semesterId: string };
    const { setFieldValue, values } = props;

    useEffect(() => {
        if (!semesterId) {
            if (values.period === 'spring' && values.year) {
                const y = (parseInt(values?.year) - 1).toString();
                const y2 = parseInt(values?.year).toString();
                setFieldValue('processName', y2?.slice(-2) + 'T');
                setFieldValue('name', y + '-' + (parseInt(y) + 1) + ' tavasz');
            } else if (values.period === 'autumn' && values.year) {
                setFieldValue('processName', values.year?.slice(-2) + 'O');
                setFieldValue('name', values?.year + '-' + (parseInt(values?.year) + 1) + ' ősz');
            }
        }
    }, [semesterId, setFieldValue, values.period, values.year]);

    return (
        <>
            {formFields(readonly, semesterId).map((field) =>
                FieldGenerator({
                    ...field,
                    ...props,
                    props: {
                        ...field.props,
                    },
                }),
            )}
            {!readonly || user.admin ? (
                <ClosingButtons
                    closed={form.closed}
                    endDateLater={form.endDateLater}
                    setPageLoading={setPageLoading}
                    lastClosingProcesssLog={lastClosingProcesssLog}
                />
            ) : null}
            <Grid item xs={12}>
                <Holidays
                    rows={form.holidays || []}
                    editProps={{
                        setSemesterForm: setForm,
                        setFieldValue: props.setFieldValue,
                        holidays: form.holidays || [],
                    }}
                    readOnly={readonly}
                />
            </Grid>

            <Grid item container xs={12} spacing={2}>
                {!readonly ? (
                    <Grid item>
                        <SubmitButton loading={count > 0} type="submit">
                            Mentés
                        </SubmitButton>
                    </Grid>
                ) : (
                    <></>
                )}
                <Grid item>
                    <Link to={clientEndPoints.md_general_semesters_list}>
                        <CancelButton>Vissza</CancelButton>
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}
