import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { store } from './store';
import './i18n';

if (process.env.REACT_APP_SENTRY_DNS) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        environment: process.env.REACT_APP_SENTRY_ENV_NAME,
        ignoreErrors: [
            'Request failed with status code 401',
            'Network request failed',
            'Failed to fetch',
            'NetworkError',
            'Network Error',
            'Request aborted',
            'timeout exceeded',
            'Request failed with status code 422',
            'Non-Error promise rejection captured',
            /feature named 'clickToLoad' was not found/,
        ],
    });
    Sentry.setTag('host', process.env.REACT_APP_SENTRY_HOST);
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
