import Grid from '@mui/material/Grid';
import { FieldGenerator } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { ActualStatusForm } from '../../../../studentPages/Profile/Tabs/ActualStudies/ActualStatusDialog';
import { actualStatusOptions } from '../../../MasterData/General/Students/Form/options';

export default function ApprovalActualStatusDialogForm(props: FormikProps<ActualStatusForm>) {
    return (
        <Grid item xs={12}>
            {FieldGenerator({
                ...props,
                label: 'Hallgató',
                name: 'student',
                type: 'text',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
            {FieldGenerator({
                ...props,
                label: 'Típus',
                name: 'type',
                type: 'text',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
            {FieldGenerator({
                ...props,
                label: 'Aktuális státusz',
                name: 'actualStatus',
                type: 'select',
                options: actualStatusOptions,
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
            {FieldGenerator({
                ...props,
                label: 'Igényelt státusz',
                name: 'requestedStatus',
                type: 'select',
                options: actualStatusOptions,
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
            {FieldGenerator({
                ...props,
                label: 'Indoklás',
                name: 'reason',
                props: {
                    multiline: true,
                    rows: 4,
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
        </Grid>
    );
}
