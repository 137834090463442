import { GridAlignment, GridColDef } from '@mui/x-data-grid-pro';
import { Input } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import selfEvaluationReportService from '../../../services/selfEvalution/report';
import { Option } from '../../../utils/AppConst';
import { theme } from '../../../utils/theme';
import { DocumentCellRenderer, OperationsCellRenderer } from './CellRenderers';

export const breadcrumbs = {
    administration: 'Önértékelések',
    selfEvaluation: 'Önértékelési riport',
};

export const header = (tenant: string | null) => {
    return {
        title: 'Önértékelési riport',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

export interface IStudentSelfAssessment {
    id: number;
    semester: string;
    student: string;
    previousSummary?: string;
    selfAssessmentQuestionnaire?: string;
    selfAssessmentQuestionnaireFilled?: boolean;
    selfAssessmentConversation?: string;
    summaryDocument?: string;
    universityIndex?: string;
    careerGuidanceSheet?: string;
    completionSheet?: string;
}

export const documentColumns = [
    {
        field: 'previousSummary',
        headerName: 'Korábbi célkitűzés',
    },
    {
        field: 'selfAssessmentQuestionnaire',
        headerName: 'Önértékelés és célkitűzés',
    },
    {
        field: 'selfAssessmentConversation',
        headerName: 'Önértékelési beszélgetés',
    },
    /*
    {
        field: 'summaryDocument',
        headerName: 'Összefoglaló dokumentum',
    },
     */
    {
        field: 'universityIndex',
        headerName: 'Egyetemi index',
    },
    {
        field: 'careerGuidanceSheet',
        headerName: 'Pályaorientációs lap',
    },
    {
        field: 'completionSheet',
        headerName: 'MCC teljesítési lap',
    },
].map((column) => ({
    ...column,
    align: 'center' as GridAlignment,
    renderCell: DocumentCellRenderer,
}));

export const columns: GridColDef[] = [
    {
        field: 'student',
        headerName: 'Hallgató',
        sortable: true,
        align: 'left' as GridAlignment,
    },
    ...documentColumns,
    {
        field: 'operations',
        headerName: '',
        renderCell: OperationsCellRenderer,
    },
].map((column) => ({
    flex: 1,
    minWidth: 150,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    ...column,
}));

export const filterFields = (options: Record<string, Option[]>): Input[] => [
    {
        name: 'student',
        label: 'Hallgató',
        type: 'autocomplete',
        multiple: true,
        getter: selfEvaluationReportService.studentSearch,
        createOptions: (data: Option[]) => data.map((option: Option) => ({ value: option.value, label: option.label })),
        autocompleteProps: {
            ChipProps: {
                sx: {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    '& .MuiChip-deleteIcon': {
                        color: theme.palette.primary.main,
                    },
                },
            },
        },
    },
    {
        name: 'campus',
        label: 'Campus',
        type: 'multiselect',
        options: options.campus,
    },
    {
        name: 'trainingLevel',
        label: 'Képzési szint',
        type: 'multiselect',
        options: options.trainingLevel,
    },
    {
        name: 'collegeYear',
        label: 'Évfolyam',
        type: 'multiselect',
        options: options.collegeYear,
    },
    {
        name: 'trainingProgram',
        label: 'Képzési program',
        type: 'multiselect',
        options: options.trainingProgram,
    },
];

const autoload = true as const;

export const asyncFunction = async (data: IStudentSelfAssessment[]): Promise<AxiosResponse> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                data: {
                    data: data,
                    metadata: {
                        'page': 1,
                        'lastPage': 0,
                        'allCount': 0,
                        'filteredCount': 0,
                    },
                },
                config: {},
                headers: {},
                status: 200,
                statusText: 'OK',
            });
        }, 1000);
    });
};

export const tableProps = (semester: string, options: Record<string, Option[]>, tenant?: string) => ({
    autoload,
    header: header(tenant || 'TAR'),
    columns,
    filter: { fields: filterFields(options) },
    refresh: semester,
    defaultSort: { student: 'asc' as const },
    responseKey: 'data',
    tableProps: {
        columnBuffer: columns.length,
        disableSelectionOnClick: true,
    },
});
