import { Input } from '@silinfo/front-end-template';
import { languageExamTypeOptions, languageOptions } from '../../../../pages/MasterData/General/Students/Form/options';
import { endpoints } from '../../../../utils/endPoints';

export const fields = (id?: number): Input[] => [
    {
        name: 'language',
        label: 'Nyelv',
        type: 'select',
        options: languageOptions,
    },
    {
        name: 'type',
        label: 'Típus',
        type: 'select',
        options: languageExamTypeOptions,
    },
    {
        name: 'level',
        label: 'Szint',
        type: 'select',
        options: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((level) => ({ value: level, label: level })),
    },
    {
        name: 'category',
        label: 'Kategória',
        type: 'select',
        options: [
            { value: 'complex', label: 'Komplex' },
            { value: 'oral', label: 'Szóbeli' },
            { value: 'written', label: 'Írásbeli' },
        ],
    },
    {
        name: 'examDate',
        label: 'Szerzés ideje',
        type: 'date',
    },
    {
        name: 'examLocation',
        label: 'Szerzés helye',
    },
    {
        name: 'certification',
        label: 'Bizonyítvány',
        type: 'file',
        origFileName: 'origFileName',
        uuidFileName: 'uuidFileName',
        tempType: 'student-document',
        downloadUrl: `${endpoints.fileManagement.download}?id=${id}&type=student-document&subType=student-language-exam`,
    },
];
