export const clientEndPoints = {
    md_general: '/master-data/general/',
    md_general_users_list: '/master-data/general/users/',
    md_general_users_create: '/master-data/general/users/new',
    md_general_users_edit: '/master-data/general/users/edit/:id',
    md_general_users_show: '/master-data/general/users/show/:id',
    md_general_students_list: '/master-data/general/students/',
    md_general_students_list_base: '/master-data/general/students/base/',
    md_general_students_list_base_show: '/master-data/general/students/base/show/:id',
    md_general_students_list_base_read_only: '/master-data/general/students/base-read-only',
    md_general_students_list_read_only: '/master-data/general/students/read-only',
    md_general_students_list_read_only_show: '/master-data/general/students/read-only/show/:id',
    md_general_students_list_base_read_only_show: '/master-data/general/students/base-read-only/show/:id',
    md_general_students_edit_base: '/master-data/general/students/:id/edit/base',
    md_general_students_edit_detailed: '/master-data/general/students/:id/edit/detailed',
    md_general_students_create: '/master-data/general/students/new',
    md_general_campuses_list: '/master-data/general/campuses/',
    md_general_campuses_show: '/master-data/general/campuses/:campusId/show',
    md_general_campuses_buildings_list: '/master-data/general/campuses/:campusId/buildings/',
    md_general_campuses_buildings_show: '/master-data/general/campuses/:campusId/buildings/:buildingId/show',
    md_general_campuses_buildings_floors_list: '/master-data/general/campuses/:campusId/buildings/:buildingId/floors/',
    md_general_campuses_buildings_floors_show:
        '/master-data/general/campuses/:campusId/buildings/:buildingId/floors/:floorId/show',
    md_general_campuses_buildings_floors_rooms_list:
        '/master-data/general/campuses/:campusId/buildings/:buildingId/floors/:floorId/rooms/',
    md_general_campuses_buildings_floors_rooms_show:
        '/master-data/general/campuses/:campusId/buildings/:buildingId/floors/:floorId/rooms/:roomId/show',
    md_general_campuses_buildings_floors_rooms_items_list:
        '/master-data/general/campuses/:campusId/buildings/:buildingId/floors/:floorId/rooms/:roomId/items/',
    md_general_campuses_buildings_floors_rooms_items_show:
        '/master-data/general/campuses/:campusId/buildings/:buildingId/floors/:floorId/rooms/:roomId/items/:itemId/show',
    md_general_universities_list: '/master-data/general/universities/',
    md_general_universities_create: '/master-data/general/universities/new',
    md_general_universities_edit: '/master-data/general/universities/:id/edit',
    md_general_universities_show: '/master-data/general/universities/:id/show',
    md_training_levels_list: '/master-data/general/training-levels/',
    md_training_levels_create: '/master-data/general/training-levels/new',
    md_training_levels_edit: '/master-data/general/training-levels/:id/edit',
    md_training_levels_show: '/master-data/general/training-levels/:id/show',
    md_training_levels_programs_list: '/master-data/general/training-levels/:id/training-programs/',
    md_training_levels_programs_create: '/master-data/general/training-levels/:id/training-programs/new',
    md_training_levels_programs_edit: '/master-data/general/training-levels/:id/training-programs/:programId/edit',
    md_training_levels_programs_show: '/master-data/general/training-levels/:id/training-programs/:programId/show',
    md_training_levels_programs_requirements_campuses:
        '/master-data/general/training-levels/:id/training-programs/:programId/requirements',
    md_training_levels_programs_requirements:
        '/master-data/general/training-levels/:id/training-programs/:programId/requirements/:campusId/',
    md_training_levels_language_exam_requirements_list:
        '/master-data/general/training-levels/:trainingLevelId/language-exam-requirements/',
    md_training_levels_language_exam_requirements_create:
        '/master-data/general/training-levels/:trainingLevelId/language-exam-requirements/new',
    md_training_levels_language_exam_requirements_edit:
        '/master-data/general/training-levels/:trainingLevelId/language-exam-requirements/:languageExamRequirementId/edit',
    md_general_semesters_list: '/master-data/general/semesters/',
    md_general_semesters_create: '/master-data/general/semesters/new',
    md_general_semesters_edit: '/master-data/general/semesters/:semesterId/edit',
    md_general_semesters_show: '/master-data/general/semesters/:semesterId/show',
    md_courses: '/master-data/courses/',
    md_courses_course_types_list: '/master-data/courses/course-types/',
    md_courses_course_types_edit: '/master-data/courses/course-types/:id/edit',
    md_courses_course_types_show: '/master-data/courses/course-types/:id/show',
    md_courses_course_types_new: '/master-data/courses/course-types/new',
    md_finance: '/master-data/finance/',
    md_finance_administration_fees_list: '/master-data/finance/administration-fees/',
    md_finance_administration_fees_new: '/master-data/finance/administration-fees/new',
    md_finance_administration_fees_edit: '/master-data/finance/administration-fees/:id/edit',
    md_finance_administration_fees_semesters_list: '/master-data/finance/administration-fees/:feeId/semesters/',
    md_finance_administration_fees_semesters_edit:
        '/master-data/finance/administration-fees/:feeId/semesters/:feeValId/edit',
    md_finance_administration_fees_semesters_new: '/master-data/finance/administration-fees/:feeId/semesters/:new',
    admin_settings_list: '/administration/settings/',
    admin_settings_form: '/administration/settings/:settingId/edit',
    admin_api_keys_list: '/administration/api-keys',
    admin_api_keys_edit: '/administration/api-keys/:apiKeyId/edit',
    admin_api_keys_new: '/administration/api-keys/new',
    admin_sys_log: '/administration/log/',
    admin_sys_logs_list: '/administration/log/system-logs/',
    admin_charm_logs_list: '/administration/log/charm-logs/',
    admin_sys_log_critical_data: '/administration/log/critical-data',
    admin_email_logs_list: '/administration/log/email-logs/',
    admin_email_logs_show: '/administration/log/email-logs/:id/show',
    admin_pages_list: '/administration/pages/',
    admin_pages_new: '/administration/pages/new',
    admin_pages_edit: '/administration/pages/:pageId/edit',
    admin_pages_versions_list: '/administration/pages/:pageId/versions',
    admin_pages_versions_edit: '/administration/pages/:pageId/versions/:versionId/edit',
    admin_pages_versions_show: '/administration/pages/:pageId/versions/:versionId/show',
    admin_pages_versions_create: '/administration/pages/:pageId/versions/new',
    admin_lang_exam_report_list: '/administration/lang-exam-report/',
    admin_approvals_list: '/administration/approvals/',
    admin_access_management_list: '/administration/access-management/',
    admin_notifications_list: '/administration/notifications/',
    admin_notifications_edit: '/administration/notifications/:id/edit',
    admin_event_logs_list: '/administration/event-logs/',
    admin_course_completions: '/administration/course-completions/',
    admin_program_closing: '/administration/program-closing/',
    admin_documents: '/administration/documents/',
    admin_documents_create: '/administration/documents/new',
    admin_documents_edit: '/administration/documents/:id/edit',
    admin_documents_show: '/administration/documents/:id/show',
    admin_documents_delete: '/administration/documents/:id/delete',
    admin_questionnaire_templates: '/administration/questionnaire-templates',
    admin_questionnaire_templates_preview: '/self-evaluation/templates/:id/preview',
    admin_questionnaire_template_groups_preview: '/self-evaluation/template-groups/:id/preview',
    admin_questionnaire_templates_create: '/administration/questionnaire-templates/new',
    admin_questionnaire_templates_edit: '/administration/questionnaire-templates/:id/edit',
    admin_questionnaire_groups_create: '/administration/questionnaire-templates/new-group',
    admin_questionnaire_groups_edit: '/administration/questionnaire-templates/:id/edit-group',
    admin_certificates: '/administration/certificates',
    admin_certificates_membership: '/administration/certificates/membership',
    admin_certificates_transcript: '/administration/certificates/transcript',
    admin_certificates_confirmation_semester: '/administration/certificates/confirmation/semester',
    admin_certificates_confirmation_training_program: '/administration/certificates/confirmation/training-program',
    self_assessment_administration: '/self-evaluation/administration',
    self_assessment_report: '/self-evaluation/report',
    self_assessment_report_preview: '/self-evaluation/report/:id/preview',
    course_evaluation_report_preview: '/course-evaluation/report/:courseId/:studentId/preview',
    self_assessment_summary_preview: '/self-evaluation/report/:id/summary',
    self_assessment_previous_summary_preview: '/self-evaluation/report/:id/previous-summary',
    course_manamagement_course_list: '/course-management/course-list/',
    course_manamagement_course_edit: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_show: '/course-management/course-list/:courseId/show',
    course_manamagement_course_copy: '/course-management/course-list/copy',
    course_manamagement_course_attendance_show: '/course-management/course-list/:courseId/attendance/show',
    course_manamagement_course_attendance_edit: '/course-management/course-list/:courseId/attendance/edit',
    course_manamagement_course_student_rating: '/course-management/course-list/:courseId/attendance/edit',
    course_manamagement_course_student_rating_show: '/course-management/course-list/:courseId/attendance/edit',
    course_manamagement_course_create: '/course-management/course-list/new',
    course_manamagement_course_delete: '/course-management/course-list/',
    course_manamagement_course_show_base: '/course-management/course-list/:courseId/show',
    course_manamagement_course_edit_base: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_edit_course: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_event: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_event_edit: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_event_show: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_add_document: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_add_user: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_roster: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_roster_edit: '/course-management/course-list/:courseId/edit',
    course_manamagement_course_roster_show: '/course-management/course-list/:courseId/show',
    finance_administration_fees_list: '/finance/administration-fees/',
    finance_administration_fees_edit: '/finance/administration-fees/new',
    finance_financial_approvals_list: '/finance/financial-approvals/',
    finance_financial_approvals_automatic_approval: '/finance/financial-approvals/automatic-approval',
    finance_financial_approvals_professional_approval: '/finance/financial-approvals/professional-approval',
    finance_financial_approvals_financial_approval: '/finance/financial-approvals/financial-approval',
    finance_financial_approvals_timeline_show: '/finance/financial-approvals/timeline/:id/show',
    finance_financial_approvals_status_edit: '/finance/financial-approvals/status-edit',
    scholarship_administration: '/scholarship/administration',
    scholarship_group_management: '/scholarship/group-management',
    scholarship_group_management_create: '/scholarship/group-management/:semesterId/new',
    scholarship_group_management_edit: '/scholarship/group-management/:semesterId/edit/:id',
    scholarship_group_management_view: '/scholarship/group-management/:semesterId/view/:id',
    scholarship_report: '/scholarship/report',
    student_profile: '/student/profile',
    student_profile_study_data: '/student/profile/study_data',
    student_course_enrollment: '/student/course-enrollment',
    student_requirements: '/student/requirements',
    student_finances: '/student/finances',
    student_calendar: '/student/calendar',
    student_processes: '/student/processes',
    student_questionnaires: '/student/questionnaires',
    student_questionnaires_fill: '/student/questionnaires/fill/:id',
    student_self_assessment: '/student/self-assessment',
    student_self_assessment_questionnaire: '/student/self-assessment/:semesterId',
    student_self_assessment_summary: '/student/self-assessment/summary/:semesterId',
    student_self_assessment_previous_summary: '/student/self-assessment/previous-summary/:semesterId',
    system_messages: '/system-messages',
    student_documents: '/student/documents',
    course_evaluation_report: '/course-management/course-evaluation-report',
    admission_administration: '/admission/administration',
    admission_riport: '/admission/riport',
    admission_riport_templates_preview: '/admission/riport/templates/:id/preview',
    admission_applicant_report: '/admission/applicant-report',
    admission_applicant_report_templates_preview: '/admission/applicant-report/templates/:id/preview',
    admission_administration_new_group: '/admission/administration/:semesterId/new',
    admission_administration_modify_group: '/admission/administration/:semesterId/:groupId',
    admission_questionnaire_preview: '/admission/questionnaire/:id/preview',
    admission_landing_page: '/felveteli',
    admissin_expired_token: '/felveteli/expired-token',
    admission_mcc: '/felveteli/mcc',
    admission_mccWithToken: '/felveteli/:tokenId',
    admission_token_questionnaire: '/felveteli/:tokenId/:serial/kerdoiv',
    admission_mcc_questionnaire: '/felveteli/mcc/:serial/kerdoiv',
    admission_mcc_questionnaire_preview: '/felveteli/mcc/:serial/elonezet',
    admission_token_questionnaire_preview: '/felveteli/:tokenId/:serial/elonezet',
    admission_token_not_found: '/felveteli/token-not-found',
};
