import CourseTypes from '../../../pages/MasterData/Courses/CourseTypes';
import CourseTypesForm from '../../../pages/MasterData/Courses/CourseTypes/Form';

export const courseTypes = {
    path: 'course-types',
    children: [
        {
            path: '',
            element: <CourseTypes />,
            name: 'md_courses_course_types_list',
        },
        {
            path: ':id/edit',
            element: <CourseTypesForm />,
            name: 'md_courses_course_types_edit',
        },
        {
            path: ':id/show',
            element: <CourseTypesForm readOnly={true} />,
            name: 'md_courses_course_types_show',
        },
        {
            path: 'new',
            element: <CourseTypesForm />,
            name: 'md_courses_course_types_new',
        },
    ],
};
