import Grid from '@mui/material/Grid';
import PriceTags from './PriceTags';
import ScholarshipsTable from './Table';

interface ScholarshipProps {
    currency?: string;
}

export default function Scholarships(props: ScholarshipProps) {
    const { currency } = props;
    return (
        <Grid item container xs={12} md={8} spacing={4}>
            <PriceTags currency={currency} />
            <ScholarshipsTable currency={currency} />
        </Grid>
    );
}
