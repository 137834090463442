import { FormikListPage, Header, TextField } from '@silinfo/front-end-template';
import { defaultTheme, Option } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { header, breadcrumbs, tableProps, service } from './utils';
import { ThemeProvider } from '@mui/styles';
import SelectedExportButton from '../../../../components/Buttons/SelectedExportButton';
import { MembershipCertificateSelected, initialMembershipCertificateSelected } from './types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useTenant } from '../../../../components/TenantContext';
import useFetch from '../../../../utils/useFetch';

export default function Membership() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [semester, setSemester] = useState<Option | null>(null);
    const [selected, setSelected] = useState<MembershipCertificateSelected>(initialMembershipCertificateSelected);
    const { data, loading } = useFetch<Option[]>(endpoints.administration.certificates.membership.semesterOptions, '');
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');

    const exportContext = {
        initiateExport: () => service(semester?.value).initiateExport(selected),
        checkExport: service(semester?.value).checkExport,
        downloadExport: service(semester?.value).downloadExport,
        selected: selected,
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...headerWithTenant, breadcrumbs }} />
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    <Skeleton height="50px" />
                ) : (
                    <Autocomplete
                        options={data}
                        value={semester}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => setSemester(value)}
                        getOptionLabel={(option) => option.label}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Szemeszter" />}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {semester ? (
                    <FormikListPage
                        {...tableProps(setSelected, semester.value, user.archive, tenant)}
                        customButtons={
                            !user.archive ? (
                                <Grid item>
                                    <SelectedExportButton
                                        exportContext={exportContext}
                                        title={'Generálás'}
                                        disabled={selected.selected.length === 0}
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )
                        }
                    />
                ) : (
                    <ThemeProvider theme={defaultTheme}>
                        <Alert severity="info">Kérjük, válassz szemesztert az adatok megtekintéséhez</Alert>
                    </ThemeProvider>
                )}
            </Grid>
        </Grid>
    );
}
