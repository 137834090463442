import Notifications from '../../pages/Administration/Notifications';
import NotificationsEdit from '../../pages/Administration/Notifications/Edit';

export const notifications = {
    path: 'notifications',
    children: [
        {
            path: '',
            element: <Notifications />,
            name: 'admin_notifications_list',
        },
        {
            path: ':id/edit',
            element: <NotificationsEdit />,
            name: 'admin_notifications_edit',
        },
    ],
};
