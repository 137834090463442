import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';

export interface Form {
    name: string;
    shortName: string;
    country: string;
}

const universitiesService = {
    ...crudTemplate(endpoints.masterData.universities.main),
};

export default universitiesService;
