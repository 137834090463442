import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

interface DeletePageConfirmModalProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    pageName: string;
    handleDelete: () => void;
}
export default function DeletePageConfirmModal({ openState, pageName, handleDelete }: DeletePageConfirmModalProps) {
    const [open, setOpen] = openState;

    const [currentPageName, setCurrentPageName] = useState<string | null>(pageName);
    const handleClose = () => {
        setCurrentPageName(null);
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Oldal törlése</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>
                <DialogContentText>
                    Biztosan törölni szeretnéd a(z) {currentPageName || pageName} oldalt? A művelet nem vonható vissza!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleDelete}>
                    Igen
                </Button>
                <Button onClick={handleClose} variant="outlined">
                    Nem
                </Button>
            </DialogActions>
        </Dialog>
    );
}
