import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import RefreshContext from '../../../../../studentPages/SelfAssessment/RefreshContextProvider';
import instance from '../../../../../api';
import { endpoints } from '../../../../../utils/endPoints';
import { create } from '../../../../../store/notification';
import { useDispatch } from 'react-redux';
import ConfirmationDialogScholarship from '../ConfirmationDialogScholarship';

interface IState {
    color: 'error' | 'success' | 'action' | 'disabled' | 'inherit' | 'primary' | 'secondary' | 'info' | 'warning';
    Icon: typeof LockIcon | typeof LockOpenIcon;
    text: string;
    confirmationText: string;
}

const getState = (finalised: boolean): IState =>
    finalised
        ? {
              Icon: LockIcon,
              color: 'error',
              text: 'Ösztöndíjszámítás véglegesítése, zárása',
              confirmationText:
                  'Biztosan véglegesíted az ösztöndíj kalkulációt? Az ösztöndíjak ezt követően automatikusan legenerálásra kerülnek minden érintett hónapra vonatkozóan.',
          }
        : {
              Icon: LockOpenIcon,
              color: 'success',
              text: 'Ösztöndíjszámítás feloldása, újranyitása',
              confirmationText:
                  'Biztosan újranyitod az ösztöndíj kalkulációt? A csoport ösztöndíj tételei a továbbiakban nem kerülnek automatikusan legenerálásra. Az ösztöndíjszámítás újranyitásával a csoport minden hallgatójának ösztöndíja megváltozhat. Alkalmazása csak rendkívül indokolt esetben javasolt.',
          };

export default function StatusChanger({
    finalized,
    id,
    setAnchorEl,
}: {
    finalized?: boolean;
    id: string;
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}) {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [sumValues, setSumValues] = useState<number | null>(null);
    const handleOpen = useCallback(() => setOpen(true), []);
    const { Icon, color, text, confirmationText } = getState(!finalized);
    const refresh = useContext(RefreshContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!finalized) {
            instance.get(endpoints.scholarships.admin.sumValues(id), {}).then((r) => {
                setSumValues(r.data?.sum);
            });
        } else {
            setSumValues(1);
        }
    }, [setSumValues, finalized, id]);

    const handleClick = useCallback(() => {
        setLoading(true);
        if (finalized) {
            instance
                .post(endpoints.scholarships.admin.reopen(id), {})
                .then(() => {
                    dispatch(
                        create({
                            type: 'success',
                            message: 'Az ösztönídj csoport újranyitása sikeres!',
                        }),
                    );
                })
                .finally(() => {
                    setAnchorEl(null);
                    setLoading(false);
                    setOpen(false);
                    refresh();
                });
        } else {
            instance
                .post(endpoints.scholarships.admin.finalization(id), {})
                .then((res) => {
                    if (res.data.success) {
                        dispatch(
                            create({
                                type: 'success',
                                message: 'Az ösztönídj csoport zárása sikeres!',
                            }),
                        );
                    } else if (res.data.error && res.data.success === false) {
                        dispatch(
                            create({
                                type: 'error',
                                message: res.data.error,
                                autoHideDuration: 10000,
                            }),
                        );
                    } else {
                        dispatch(
                            create({
                                type: 'error',
                                message: 'Az ösztönídj csoport zárása sikertelen volt!',
                            }),
                        );
                    }
                })
                .finally(() => {
                    setAnchorEl(null);
                    setLoading(false);
                    setOpen(false);
                    refresh();
                });
        }
    }, [finalized, id, setAnchorEl, dispatch, refresh]);

    return (
        <>
            <MenuItem onClick={handleOpen}>
                <ListItemIcon>
                    <Icon color={color} />
                </ListItemIcon>
                <ListItemText>{text}</ListItemText>
            </MenuItem>
            <ConfirmationDialogScholarship
                text={confirmationText}
                openState={[open, setOpen]}
                onConfirm={handleClick}
                loading={loading}
                sumValues={sumValues}
            />
        </>
    );
}
