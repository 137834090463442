import BasicPaper, { PaperProps } from '@mui/material/Paper';

export const Paper = (props: PaperProps) => (
    <BasicPaper
        sx={{
            padding: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
        }}
        elevation={4}
        {...props}
    />
);
