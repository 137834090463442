import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { Children } from 'react';
import TabCard, { TabCardProps } from './TabCard';

export interface TabContentProps {
    cards: TabCardProps[];
    value: number;
}

export default function TabContent({ cards, value }: TabContentProps) {
    return (
        <TabPanel value={'' + value} style={{ padding: '24px 0 0 24px', flexGrow: 1 }}>
            <Grid item xs={12}>
                <Grid item container xs={12} spacing={2}>
                    {Children.toArray(cards.map(TabCard))}
                </Grid>
            </Grid>
        </TabPanel>
    );
}
