import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CKEditor, FieldGenerator } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { IEditor } from '../../../utils/Interfaces/interfaces';
import { INotificationForm, NotificationKey } from './types';

const LanguageTab = ({ language, value }: { language: 'hu' | 'en'; value: NotificationKey }) => {
    const formikProps = useFormikContext<INotificationForm>();
    const { setFieldValue, values } = formikProps;

    return (
        <Grid item container spacing={1} key={language}>
            {FieldGenerator({
                ...formikProps,
                name: `types.${value}.message.translations.${language}.title`,
                label: 'Címsor',
                fieldType: 'base',
                props: {
                    value: values.types[value].message.translations[language]?.title || '',
                    error: formikProps.errors[
                        `types.${value}.message.translations.${language}.title` as keyof INotificationForm
                    ],
                    helperText:
                        formikProps.errors[
                            `types.${value}.message.translations.${language}.title` as keyof INotificationForm
                        ],
                },
                format: {
                    xs: 12,
                },
            })}
            <Grid item xs={12}>
                <CKEditor
                    name={`types.${value}.message.translations.${language}.content`}
                    format={{ xs: 12 }}
                    data={values.types[value].message.translations[language]?.content || ''}
                    onChange={(_: unknown, editor: IEditor) =>
                        setFieldValue(`types.${value}.message.translations[${language}].content`, editor.getData())
                    }
                />
            </Grid>
            {formikProps.errors[
                `types.${value}.message.translations.${language}.content` as keyof INotificationForm
            ] && (
                <Grid item xs={12}>
                    <Typography color="error">
                        {
                            formikProps.errors[
                                `types.${value}.message.translations.${language}.content` as keyof INotificationForm
                            ] as string
                        }
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default LanguageTab;
