import Grid from '@mui/material/Grid';
import { ListPage, MultiselectField, TextField } from '@silinfo/front-end-template';
import { setInfo as setInfo2 } from '@silinfo/front-end-template/lib/esm/components/Table/setInfo';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MCCLoading from '../../../../components/MCCLoading';
import i18n from '../../../../i18n';
import emailLogsService from '../../../../services/administration/emailLogs';
import { create } from '../../../../store/notification';
import { deleteFieldIfEmpty, findLabelByValue, Option, paginatorInfoBuild } from '../../../../utils/AppConst';
import { IInfo } from '../../../../utils/Interfaces/IInfo';
import { EmailLogFilter, initialEmailLogFilterState } from './types';
import { EmailLogTypes, columns, header, statuses } from './utils';
import { addOffsetToDate, formatDate } from '../../../../utils/timezoneConverter';
import { useTenant } from '../../../../components/TenantContext';

export default function EmailLogs() {
    const [data, setData] = useState<[]>([]);
    const [types, setTypes] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [info, setInfo] = useState<IInfo<EmailLogFilter>>(initialEmailLogFilterState);
    const dispatch = useDispatch();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    useEffect(() => {
        emailLogsService.getEmailLogTypes().then((res) => {
            setTypes(
                res.data['hydra:member'].map((item: EmailLogTypes) => item.type).filter((type: string) => type !== ''),
            );
        });
    }, []);

    const search = useCallback(
        (form: IInfo<EmailLogFilter>) => {
            const newForm: IInfo<EmailLogFilter> = deleteFieldIfEmpty(form, [
                'sentAt[after]',
                'generatedAt[after]',
                'sentAt[before]',
                'generatedAt[before]',
            ]);
            setTableLoading(true);
            emailLogsService
                .filter({
                    ...newForm.filter,
                    'generatedAt[after]':
                        typeof newForm.filter['generatedAt[after]'] === 'string'
                            ? addOffsetToDate(newForm.filter['generatedAt[after]'])
                            : newForm.filter['generatedAt[after]'],
                    'generatedAt[before]':
                        typeof newForm.filter['generatedAt[before]'] === 'string'
                            ? addOffsetToDate(newForm.filter['generatedAt[before]'])
                            : newForm.filter['generatedAt[before]'],
                    'sentAt[after]':
                        typeof newForm.filter['sentAt[after]'] === 'string'
                            ? addOffsetToDate(newForm.filter['sentAt[after]'])
                            : newForm.filter['sentAt[after]'],
                    'sentAt[before]':
                        typeof newForm.filter['sentAt[before]'] === 'string'
                            ? addOffsetToDate(newForm.filter['sentAt[before]'])
                            : newForm.filter['sentAt[before]'],
                    ...paginatorInfoBuild(newForm),
                })
                .then((response) => {
                    const timezoneAdjustedData = response.data['hydra:member'].map((item: EmailLogFilter) => {
                        return {
                            ...item,
                            generatedAt: formatDate(item.generatedAt),
                            sentAt: formatDate(item.sentAt),
                        };
                    });
                    setData(timezoneAdjustedData);
                    const totalItems = response.data['hydra:totalItems'];
                    setInfo((prev) => ({
                        ...prev,
                        metadata: {
                            ...prev.metadata,
                            'allCount': totalItems,
                            'filteredCount': totalItems,
                            'lastPage': totalItems ? Math.ceil(totalItems / +prev.metadata.perpage) : 1,
                            'rowCount': totalItems,
                        },
                    }));
                })
                .catch(() => {
                    dispatch(create({ type: 'error', message: 'Sikertelen betöltés...' }));
                })
                .finally(() => {
                    setLoading(false);
                    setTableLoading(false);
                });
        },
        [dispatch],
    );

    const fetchData = useCallback(
        (form: IInfo<EmailLogFilter>) => {
            setTableLoading(true);
            search(form);
        },
        [search],
    );

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        setLoading(true);
        if (firstLoad) {
            setFirstLoad(false);
            fetchData(initialEmailLogFilterState);
        }
    }, [fetchData, firstLoad]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInfo((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [event.target.name]: event.target.value,
            },
        }));
    };

    const propsSearch = {
        info: info,
        setInfo: (name: string, data: EmailLogFilter) =>
            setInfo((prevState) => ({
                ...prevState,
                [name]: data,
            })),
        search: (form: IInfo<EmailLogFilter>) => {
            search(form);
        },
    };

    const handleMultiChange = (fieldName: string) => {
        //name prop is not used in any way in multiselect
        //TODO: This is not too generic. Should rebuild MultiselectField to handle name prop and expose onChange properly
        return (_: unknown, v: { value: string }[]) => {
            setInfo((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    [fieldName]: v?.map((obj) => obj.value || obj),
                },
            }));
        };
    };

    const resetForm = () => {
        setInfo(initialEmailLogFilterState);
    };

    const filterParam = {
        form: info.filter,
        setForm: () => {
            resetForm();
            setInfo2(propsSearch, 'filter', {});
        },
        onSubmit: () => {
            setInfo2(propsSearch, 'filter', info.filter);
        },
    };
    if (loading) {
        return <MCCLoading />;
    }

    return (
        <ListPage
            header={headerWithTenant}
            filter={filterParam}
            table={{
                columnDefs: columns,
                rowData: data,
                loading: tableLoading,
                searchfunc: fetchData,
                info: info,
                server: true,
                initialState: {
                    sorting: {
                        sortModel: [{ field: 'generatedAt', sort: 'desc' }],
                    },
                },
                setInfo: setValueByKey,
                columnBuffer: columns.length,
            }}
        >
            <Grid item container spacing={2} xs={12} wrap={'wrap'} style={{ marginTop: 3 }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="E-mail cím"
                        name="mailingAddress"
                        value={info?.filter['mailingAddress'] || ''}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MultiselectField
                        onChange={handleMultiChange('type')}
                        label="Típus"
                        options={types.map((type: string) => ({
                            value: type,
                            label: i18n.t('administration.email_log.type.' + type),
                        }))}
                        value={(info.filter.type as Option[]) || []}
                        getOptionLabel={(option: Option | string) =>
                            i18n.t(
                                typeof option === 'string'
                                    ? findLabelByValue(
                                          option,
                                          types.map((type: string) => ({
                                              value: type,
                                              label: i18n.t('administration.email_log.type.' + type),
                                          })),
                                      ) || ''
                                    : option.label,
                            )
                        }
                        isOptionEqualToValue={(option: Option, value: Option | string) =>
                            typeof value === 'string' ? value === option.value : option.value === value.value
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Tárgy"
                        name="subject"
                        value={info?.filter['subject'] || ''}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Generálás eleje"
                        name="generatedAt[after]"
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={info.filter['generatedAt[after]'] || ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Generálás vége"
                        name="generatedAt[before]"
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={info.filter['generatedAt[before]'] || ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Küldés eleje"
                        name="sentAt[after]"
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={info.filter['sentAt[after]'] || ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={handleChange}
                        label="Küldés vége"
                        name="sentAt[before]"
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={info.filter['sentAt[before]'] || ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MultiselectField
                        onChange={handleMultiChange('status')}
                        label="Státusz"
                        options={statuses.map((status: string) => ({
                            value: status,
                            label: i18n.t('administration.email_log.status.' + status),
                        }))}
                        value={(info.filter.status as Option[]) || []}
                        getOptionLabel={(option: Option | string) =>
                            i18n.t(
                                typeof option === 'string'
                                    ? findLabelByValue(
                                          option,
                                          statuses.map((status: string) => ({
                                              value: status,
                                              label: i18n.t('administration.email_log.status.' + status),
                                          })),
                                      ) || ''
                                    : option.label,
                            )
                        }
                        isOptionEqualToValue={(option: Option, value: Option | string) =>
                            typeof value === 'string' ? value === option.value : option.value === value.value
                        }
                    />
                </Grid>
            </Grid>
        </ListPage>
    );
}
