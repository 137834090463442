import { SemesterStatus } from '../../../../../studentPages/Profile/Tabs/SemesterStatusDialog';
import { Option } from '../../../../../utils/AppConst';
import { Size, sizes } from './interfaces';

export const nationalityOptions: Option[] = [
    { value: 'hu', label: 'Magyar' },
    { value: 'pl', label: 'Lengyel' },
    { value: 'at', label: 'Osztrák' },
    { value: 'de', label: 'Német' },
];

export const selfEvaluationSemesterStatusOptions: Option<SemesterStatus>[] = [
    { label: 'Aktív', value: 'active' },
    { label: 'Külföldi ösztöndíjas', value: 'foreign_scholarship' },
];

export const semesterStatusOptions: Option<SemesterStatus>[] = [
    { label: 'Aktív', value: 'active' },
    { label: 'Külföldi ösztöndíjas', value: 'foreign_scholarship' },
    { label: 'Passzív', value: 'passive' },
];

export const genderOptions: Option[] = [
    { label: 'Férfi', value: 'male' },
    { label: 'Nő', value: 'female' },
    { label: 'Ismeretlen', value: 'unknown' },
];

export const legalRelationshipOptions: Option[] = [
    { label: 'Aktív', value: 'active' },
    { label: 'Passzív', value: 'passive' },
];

export const actualStatusOptions: Option[] = [
    { label: 'Bentlakó', value: 'internal' },
    { label: 'Külsős', value: 'external' },
];
export const statusOptions: Option[] = [
    { label: 'Bentlakó', value: 'internal' },
    { label: 'Külsős', value: 'external' },
];

/**
 * Átírtam, mert enrollment-ből jönnek ezek az adatok és ott így van, ezért szükséges egységesíteni őket
 */
export const trainingFormOptions: Option[] = [
    { value: 'ba', label: 'BA' },
    { value: 'bsc', label: 'BSc' },
    { value: 'ma', label: 'MA' },
    { value: 'msc', label: 'MSc' },
    { value: 'undivided', label: 'Osztatlan képzés' },
    { value: 'other', label: 'Egyéb' },
];

export const financingFormOptions = [
    { value: 'state_subsidized', label: 'Államilag támogatott' },
    { value: 'cost', label: 'Önköltséges' },
];

const languageCodes = [
    'EN',
    'FR',
    'ZH',
    'DE',
    'IT',
    'RU',
    'ES',
    'AR',
    'BG',
    'RB',
    'RL',
    'CS',
    'DA',
    'EO',
    'FI',
    'HE',
    'NL',
    'HR',
    'JP',
    'LA',
    'PL',
    'HU',
    'HY',
    'OG',
    'EL',
    'PT',
    'RO',
    'RS',
    'SV',
    'SR',
    'SK',
    'SL',
    'TR',
    'UK',
    'WE',
];

export const languageOptions = [
    { value: 'EN', label: 'Angol' },
    { value: 'Francia', label: 'Francia' },
    { value: 'Kínai', label: 'Kínai' },
    { value: 'Német', label: 'Német' },
    { value: 'Olasz', label: 'Olasz' },
    { value: 'Orosz', label: 'Orosz' },
    { value: 'Spanyol', label: 'Spanyol' },
    { value: 'Arab', label: 'Arab' },
    { value: 'BG', label: 'Bolgár' },
    { value: 'Cigány (beás)', label: 'Cigány (beás)' },
    { value: 'Cigány (lovári)', label: 'Cigány (lovári)' },
    { value: 'Cseh', label: 'Cseh' },
    { value: 'Dán', label: 'Dán' },
    { value: 'Eszperentó', label: 'Eszperentó' },
    { value: 'Finn', label: 'Finn' },
    { value: 'Héber', label: 'Héber' },
    { value: 'Holland', label: 'Holland' },
    { value: 'Horvát', label: 'Horvát' },
    { value: 'Japán', label: 'Japán' },
    { value: 'Latin', label: 'Latin' },
    { value: 'Lengyel', label: 'Lengyel' },
    { value: 'Magyar', label: 'Magyar' },
    { value: 'Örmény', label: 'Örmény' },
    { value: 'Újgörög', label: 'Újgörög' },
    { value: 'Ógörög', label: 'Ógörög' },
    { value: 'Portugál', label: 'Portugál' },
    { value: 'Román', label: 'Román' },
    { value: 'Ruszin', label: 'Ruszin' },
    { value: 'Svéd', label: 'Svéd' },
    { value: 'Szerb', label: 'Szerb' },
    { value: 'Szlovák', label: 'Szlovák' },
    { value: 'Szlovén', label: 'Szlovén' },
    { value: 'Török', label: 'Török' },
    { value: 'Ukrán', label: 'Ukrán' },
    { value: 'WE', label: 'Egyéb' },
].map((e, i) => ({ ...e, value: languageCodes[i] }));

export const languageExamTypeOptions: Option[] = [
    { value: 'general', label: 'Általános' },
    { value: 'professional', label: 'Szakmai' },
];

export const validatedOptions = [
    { value: 'yes', label: 'Igen' },
    { value: 'no', label: 'Nem' },
    { value: 'waiting', label: 'Várakozó' },
];

export const onlyActiveStudentsOptions = [
    { value: 'yes', label: 'Igen' },
    { value: 'no', label: 'Nem' },
];

export const levelOptions = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((elem) => ({
    value: elem,
    label: elem,
}));

export const categoryOptions = [
    { value: 'complex', label: 'Komplex' },
    { value: 'oral', label: 'Szóbeli' },
    { value: 'written', label: 'Írásbeli' },
];

export const boolOptions: Option[] = [
    {
        value: '1',
        label: 'Igen',
    },
    {
        value: '0',
        label: 'Nem',
    },
];

export const stayAbroadTypeOptions = [
    { value: 'erasmus', label: 'Erasmus' },
    { value: 'fellowship', label: 'Fellowship' },
];

export const sizeOptions: Option<Size>[] = sizes.map((size) => ({ value: size, label: size.toUpperCase() }));
