import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { CancelButton } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import EndpointProvider from '../../../components/EndpointProvider';
import courseEnrollmentService from '../../../services/studentServices/courseEnrollment';
import { ICourseEnrollment } from './CourseEnrollment/types';
import StudentsListButton from './StudentsListButton';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export default function RosterButton(params: GridRenderCellParams) {
    const [enrollments, setEnrollments] = useState([]);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            courseEnrollmentService.getAllByCourse({}, params.row.id, params.row.courseTenantId).then((res) => {
                setEnrollments(res.data);
            });
        }
    }, [open, params.row.id, params.row.courseTenantId]);

    const handleOpen = () => setOpen(!open);

    return (
        <EndpointProvider endpoints={['course_manamagement_course_roster']}>
            <StudentsListButton style={{ marginRight: '5px' }} tooltip={'Névsor'} onClick={handleOpen} />
            <Dialog open={open} onClose={() => setOpen(!open)} fullWidth maxWidth={'md'}>
                <DialogTitle>Névsor</DialogTitle>
                <DialogContent>
                    {enrollments?.filter((elem: ICourseEnrollment) => elem.type == 'normal')?.length > 0 ? (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                            Tenant
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                            Név
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                            E-mail cím
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {enrollments
                                        ?.filter((elem: ICourseEnrollment) => elem.type == 'normal')
                                        .map((elem: ICourseEnrollment) => (
                                            <>
                                                <TableRow>
                                                    <TableCell align={'center'}>{elem.tenant}</TableCell>
                                                    <TableCell align={'center'}>{elem.studentName}</TableCell>
                                                    <TableCell align={'center'}>{elem.studentEmail}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                </TableBody>
                            </Table>
                        </>
                    ) : (
                        <Box mt={3} mb={3}>
                            <Typography variant="h5" gutterBottom component="div">
                                Nincsenek hallgatók a kurzuson.
                            </Typography>
                        </Box>
                    )}

                    {enrollments?.filter((elem: ICourseEnrollment) => elem.type == 'waiting_list')?.length > 0 && (
                        <>
                            <Box mt={3} mb={3}>
                                <Divider textAlign={'left'}>
                                    <Typography variant={'h5'}>Várólista</Typography>
                                </Divider>
                            </Box>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                            Tenant
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                            Név
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align={'center'}>
                                            E-mail cím
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {enrollments
                                        ?.filter((elem: ICourseEnrollment) => elem.type == 'waiting_list')
                                        .map((elem: ICourseEnrollment) => (
                                            <>
                                                <TableRow>
                                                    <TableCell align={'center'}>{elem.tenant}</TableCell>
                                                    <TableCell align={'center'}>{elem.studentName}</TableCell>
                                                    <TableCell align={'center'}>{elem.studentEmail}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={handleOpen}>Bezár</CancelButton>
                </DialogActions>
            </Dialog>
        </EndpointProvider>
    );
}
