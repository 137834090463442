import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';

interface ConfirmationDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    onConfirm: () => void;
    onDecline?: () => void;
    text?: string;
    title?: string;
}

export default function ConfirmationDialogScholarship({
    openState,
    onConfirm,
    onDecline,
    text,
    title,
    loading,
    sumValues,
}: ConfirmationDialogProps & { loading: boolean; sumValues: number | null }) {
    const [open, setOpen] = openState;

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    if (sumValues === 0) {
        return (
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Hiba</DialogTitle>
                <DialogContent style={{ paddingTop: '16px' }}>
                    <DialogContentText>
                        Még nincs ösztöndíj összeg rögzítve, ezért nincs lehetőség véglegesítésre
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDecline || handleClose} variant="outlined">
                        Bezárás
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            {sumValues === null ? (
                <>Betöltés...</>
            ) : (
                <>
                    <DialogTitle>{title || 'Megerősítés'}</DialogTitle>
                    <DialogContent style={{ paddingTop: '16px' }}>
                        <DialogContentText>{text || 'Biztosan mented a változtatásokat?'}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onDecline || handleClose} variant="outlined">
                            Nem
                        </Button>
                        <LoadingButton onClick={onConfirm} variant="contained" loading={loading}>
                            Igen
                        </LoadingButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
