import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { componentMap } from './utils';
import { TSelfAssessmentDocumentStatus } from '../types';
import { STATUS } from '../../../../studentPages/SelfAssessment/CellRenderers/Status/utils';
import PendingIcon from '@mui/icons-material/Pending';

const handleClick = (id: string | number) =>
    window.open(clientEndPoints.self_assessment_report_preview.replace(':id', '' + id), '_blank');

export default function SelfAssessmentQuestionnaireCellRenderer(
    params: GridRenderCellParams<TSelfAssessmentDocumentStatus>,
) {
    const Core = componentMap[params.value || 'none'];
    const onClick = useCallback(() => {
        handleClick(params.id);
    }, [params.id]);

    if (params.value === 'wait_to_fill' && params.row?.isGroupTemplate === true) {
        return (
            <IconButton onClick={onClick}>
                <PendingIcon
                    style={{
                        color: '#22344C',
                    }}
                />
            </IconButton>
        );
    }

    if (params.value === STATUS.COMPLETED) {
        return <IconButton onClick={onClick}>{Core}</IconButton>;
    }

    return Core;
}
