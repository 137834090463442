import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import courseListService from '../../../services/courseManagement/courseList';
import { create } from '../../../store/notification';

type Status = 'open' | 'closed';

interface Props {
    color: 'success' | 'error';
    action: 'coursesOpen' | 'coursesClose';
    tooltip: string;
    Icon: React.FC;
}

const propsByStatus: Record<Status, Props> = {
    open: {
        color: 'success',
        tooltip: 'Kurzus nyitása',
        Icon: LockOpen,
        action: 'coursesOpen',
    },
    closed: {
        color: 'error',
        tooltip: 'Kurzus zárása',
        Icon: Lock,
        action: 'coursesClose',
    },
};

export default function CourseActionButton({
    setLoading,
    ...params
}: GridRenderCellParams & { setLoading: Dispatch<SetStateAction<boolean>> }) {
    const status = params.row.closed ? 'open' : 'closed';
    const text = `Figyelem! A(z) '${params.row.courseName}' kurzushoz nyitott státuszban lévő kérvény tartozik az Adminisztráció / Jóváhagyások menüpontban. Biztosan le szeretnéd zárni a kurzust?`;
    const { color, tooltip, Icon, action } = propsByStatus[status];
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    if (action === 'coursesOpen' && !params.row.openable) {
        return null;
    }
    if (action === 'coursesClose' && !params.row.closable) {
        return null;
    }

    function handleCourseOpenClose() {
        const courseOpenCloseFunction = courseListService[action];
        if (!open && params.row.hasOpenRequests && !params.row.closed) {
            setOpen(true);
            return;
        }
        setOpen(false);
        setLoading(true);
        courseOpenCloseFunction(params.row.id)
            .then((r) => {
                if (r.data.error) {
                    dispatch(
                        create({
                            type: 'error',
                            message: r.data.error,
                        }),
                    );
                } else {
                    dispatch(create({ type: 'success', message: 'Sikeres művelet!' }));
                }
            })
            .catch(() =>
                dispatch(
                    create({
                        type: 'error',
                        message: 'Sikertelen művelet!',
                    }),
                ),
            )
            .finally(() => setLoading(false));
    }

    return (
        <>
            <ConfirmationDialog
                title="Kurzus zárása"
                openState={[open, setOpen]}
                text={text}
                onConfirm={handleCourseOpenClose}
            />
            <TableButton color={color} onClick={handleCourseOpenClose} tooltip={tooltip} sx={{ marginRight: '5px' }}>
                <Icon />
            </TableButton>
        </>
    );
}
