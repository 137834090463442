import Approvals from '../../pages/Administration/Approvals/Approvals';

export const approvals = {
    path: 'approvals',
    children: [
        {
            path: '',
            element: <Approvals />,
            name: 'admin_approvals_list',
        },
    ],
};
