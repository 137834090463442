import Grid from '@mui/material/Grid';
import { FieldGenerator, Input } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { Children, useContext, useMemo } from 'react';
import { ForeignScholarshipSemesterStatusForm } from '../../../../studentPages/Profile/Tabs/SemesterStatusDialog';
import { foreignScholarship } from '../../../MasterData/General/Students/Form/fields';
import { semesterStatusOptions } from '../../../MasterData/General/Students/Form/options';
import { CountryContext } from '../Approvals';

export default function ApprovalSemesterStatusDialogForm(
    formikProps: FormikProps<ForeignScholarshipSemesterStatusForm>,
) {
    const data = useContext(CountryContext);
    const foreignScholarshipFields: Input[] = useMemo(
        () =>
            foreignScholarship(data).map((field) => {
                return {
                    ...field,
                    props: { ...field.props, disabled: true },
                    format: { xs: 12 },
                };
            }),
        [data],
    );

    const ForeignScholarshipFields = (props: FormikProps<ForeignScholarshipSemesterStatusForm>) => (
        <>{Children.toArray(foreignScholarshipFields.map((field) => FieldGenerator({ ...props, ...field })))}</>
    );

    return (
        <Grid item xs={12}>
            {FieldGenerator({
                ...formikProps,
                label: 'Hallgató',
                name: 'student',
                type: 'text',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
            {FieldGenerator({
                ...formikProps,
                label: 'Típus',
                name: 'type',
                type: 'text',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
            {FieldGenerator({
                ...formikProps,
                label: 'Jelenlegi szemeszter státusz',
                name: 'actualStatus',
                type: 'select',
                options: semesterStatusOptions,
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
            {FieldGenerator({
                ...formikProps,
                label: 'Igényelt státusz',
                name: 'requestedStatus',
                type: 'select',
                options: semesterStatusOptions,
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
            {formikProps.values.requestedStatus === 'foreign_scholarship' && (
                <ForeignScholarshipFields {...formikProps} />
            )}
            {FieldGenerator({
                ...formikProps,
                label: 'Indoklás',
                name: 'reason',
                props: {
                    multiline: true,
                    rows: 4,
                    disabled: true,
                },
                format: {
                    xs: 12,
                },
            })}
        </Grid>
    );
}
