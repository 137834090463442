import { FormikListPage } from '@silinfo/front-end-template';
import CachedIcon from '@mui/icons-material/Cached';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import settingsService from '../../../services/administration/settings';
import { header } from './utils';
import { BtnCellRenderer } from './CellRenderers/BtnCellRenderer';
import { NameCellRenderer } from './CellRenderers/NameCellRenderer';
import { ValueCellRenderer } from './CellRenderers/ValueCellRenderer';
import { RootState } from '../../../store';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { endpoints } from '../../../utils/endPoints';
import { create } from '../../../store/notification';
import API from '../../../api';
import { useTenant } from '../../../components/TenantContext';

export default function Settings() {
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');

    const handleRedisCacheDelete = () => {
        API.delete(endpoints.administration.settings.deleteRedisCache)
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres redis cache törlés.' }));
            })
            .catch((error) => {
                console.error(error);
                dispatch(create({ type: 'error', message: 'Sikertelen redis cache törlés.' }));
            });
    };

    const columns = [
        {
            headerName: 'Megnevezés',
            field: 'settingKey',
            renderCell: NameCellRenderer,
            flex: 1,
            minWidth: 350,
        },
        { headerName: 'Érték', field: 'settingVal', renderCell: ValueCellRenderer, flex: 1, minWidth: 150 },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: BtnCellRenderer,
            flex: 1,
            minWidth: 100,
        },
    ];

    return (
        <FormikListPage
            autoload
            header={headerWithTenant}
            columns={columns}
            filter={{
                fields: [
                    {
                        name: 'settingName',
                        label: 'Megnevezés',
                        format: { xs: 12, md: 12, lg: 12 },
                    },
                ],
            }}
            service={{
                filter: (form) => {
                    return settingsService.filter(form);
                },
            }}
            customButtons={
                <>
                    {!user.archive ? (
                        <Grid item>
                            <Tooltip title="Redis cache törlés">
                                <IconButton onClick={handleRedisCacheDelete}>
                                    <CachedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </>
            }
        />
    );
}
