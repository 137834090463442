import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Link } from '@silinfo/front-end-template';
import { theme } from '../../../../../../utils/theme';
import TableCell from '../CollapsibleTable/TableCell';
import { ICourse } from '../types';
import { getColorByEvaluationStatus } from '../utils';
import { clientEndPoints } from './../../../../../../utils/clientEndPoints';
import { scoringTypeLabel } from '../../../../../../utils/AppConst';
import { notCountedText } from '../../../../../../studentPages/Requirements/SemesterLevel/Table/utils';

export default function Row(row: ICourse & { print?: boolean }) {
    const point =
        (row.point[0] === row.point[1] ? row.point[0] : row.point.join('-')) + ' ' + scoringTypeLabel(row.scoringType);

    let courseUrl = '';

    if (!row.print) {
        if (row.isSharedCourse) {
            courseUrl =
                clientEndPoints.course_manamagement_course_show.replace(':courseId', '' + row.id) +
                '?courseTenant=' +
                row.courseTenantId;
        } else {
            courseUrl = clientEndPoints.course_manamagement_course_show.replace(':courseId', '' + row.id);
        }
    }

    return (
        <Tooltip title={!row.worthCourse || !row.worthCourseType ? notCountedText : ''}>
            <TableRow
                key={row.id}
                style={{ ...(row.print ? { height: '76px', borderWidth: 0 } : {}) }}
                sx={{ backgroundColor: !row.worthCourse ? '#ddd' : '' }}
            >
                <TableCell align="left" style={{ whiteSpace: 'nowrap', borderWidth: 0 }}>
                    {row.courseCode}
                </TableCell>
                <TableCell align="left">{row.courseName}</TableCell>
                <TableCell>
                    {(row.evaluationStatus === 'success' && !row.worthCourseType) || !row.worthCourse ? '-' : point}
                </TableCell>
                <TableCell>
                    <Tooltip
                        title={
                            <>
                                <div>Jelenlétek száma: {row.presence.attended}</div>
                                <div>Elvárt minimum jelenlét: {row.presence.minimum}</div>
                            </>
                        }
                    >
                        <div>
                            <span
                                style={{
                                    color: theme.palette[
                                        row.presence.attended >= row.presence.minimum ? 'success' : 'error'
                                    ].main,
                                }}
                            >
                                {row.presence.attended}
                            </span>
                            /<span>{row.presence.minimum}</span>
                        </div>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <span style={{ color: getColorByEvaluationStatus(row.evaluationStatus) }}>{row.evaluation}</span>
                </TableCell>
                {!row.print ? (
                    <TableCell>
                        <Link to={courseUrl}>
                            <Tooltip title="Adatok megtekintése">
                                <IconButton
                                    color="primary"
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.hover,
                                        },
                                    }}
                                >
                                    <Visibility />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </TableCell>
                ) : null}
            </TableRow>
        </Tooltip>
    );
}
