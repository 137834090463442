import { Input } from '@silinfo/front-end-template';
import { ADMIN_FEE_CATEGORIES } from '../../../../utils/AppConst';

export const fields = (flag: boolean, used: boolean): Input[] => [
    {
        name: 'category',
        label: 'Díjkategória',
        type: 'select',
        options: ADMIN_FEE_CATEGORIES,
        props: {
            disabled: flag || used,
        },
    },
    {
        name: 'type',
        label: 'Megnevezés',
        props: {
            disabled: flag,
        },
    },
    {
        name: 'typeShort',
        label: 'Azonosító',
        props: {
            disabled: flag || used,
        },
    },
];
