import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ItemProps } from './types';

const Item = ({ label, Icon, children, color }: ItemProps) => (
    <ListItem sx={{ paddingLeft: 0 }}>
        <ListItemAvatar>
            <Avatar
                sx={{
                    bgcolor: (color || 'primary') + '.main',
                }}
            >
                {Icon}
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={label} />
        <Typography variant="body2" component="div" textAlign="right">
            {children}
        </Typography>
    </ListItem>
);

export default Item;
