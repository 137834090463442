import Grid from '@mui/material/Grid';
import { Accordion, Loading, Page, TextField } from '@silinfo/front-end-template';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Buttons from '../../../../components/Form/Buttons';
import trainingLevelsService, { ITrainingLevelsForm } from '../../../../services/masterData/trainingLevels';
import { create } from '../../../../store/notification';
import { transformApiErrors } from '../../../../utils/AppConst';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { useTenant } from '../../../../components/TenantContext';

const TrainingLevelFormSchema = Yup.object().shape({
    name: Yup.string().max(128, 'Nem lehet több, mint 128 karatker').required('Kötelező megadni'),
});

const initialState = {
    name: '',
};

export default function TrainingLevelForm({ readOnly = false }: { readOnly?: boolean }) {
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [form, setForm] = useState<ITrainingLevelsForm>(initialState);
    const { id } = useParams();
    const dispatch = useDispatch();
    const tenant = useTenant();

    const title = (id: string | undefined, readOnly: boolean) => {
        if (readOnly) {
            return 'Képzési szint megtekintése';
        } else if (id) {
            return 'Képzési szint szerkesztése';
        } else {
            return 'Képzési szint hozzáadása';
        }
    };

    const header = {
        project: tenant || 'TAR',
        title: 'Képzési szintek',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: 'Képzési szintek',
            [id ? 'trainingLevelsEdit' : 'trainingLevelsNew']: title(id, readOnly),
        },
    };
    const URL = clientEndPoints.md_training_levels_list;

    useEffect(() => {
        setLoading(true);
        if (id) {
            trainingLevelsService
                .get(id)
                .then((response: AxiosResponse<{ name: string }>) => {
                    const data = response.data;
                    setForm(() => ({
                        name: data.name,
                    }));
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleSubmit = (form: ITrainingLevelsForm, { setErrors }: FormikHelpers<ITrainingLevelsForm>) => {
        setFormLoading(true);

        (id ? trainingLevelsService.update(id, form) : trainingLevelsService.create(form))
            .then(() => dispatch(create({ type: 'success', message: 'Sikeres mentés!', redirect: URL })))
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => setFormLoading(false));
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Page header={header}>
            <Accordion title={title(id, readOnly)}>
                <Formik initialValues={form} onSubmit={handleSubmit} validationSchema={TrainingLevelFormSchema}>
                    {({ errors, touched, values }) => {
                        return (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Field as={'div'} name={'name'}>
                                            <TextField
                                                name="name"
                                                label="Képzési szint"
                                                error={Boolean(errors.name && touched.name)}
                                                helperText={touched.name && errors.name}
                                                value={values.name}
                                                onChange={(e) => setForm({ ...form, name: e.target.value })}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Buttons
                                        buttonLoading={formLoading}
                                        cancel={clientEndPoints.md_training_levels_list}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}
