import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CounterBadge from './CounterBadge';
import { CounterProps } from './types';

const Counter = ({ label, value, color }: CounterProps) => (
    <Grid item container xs={3}>
        <Grid item xs={12}>
            <Typography textAlign="center" variant="h6">
                {label}
            </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
            <CounterBadge value={value} color={color} />
        </Grid>
    </Grid>
);

export default Counter;
