import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FieldGenerator, Input } from '@silinfo/front-end-template';
import { Children } from 'react';
import { useSelector } from 'react-redux';
import studentProfileService from '../../../services/studentServices/studentProfileService';
import { RootState } from '../../../store';
import { filterUnusedKeys } from '../../../utils/form';
import EditForm from '../EditForm';
import Data from './Data';
import { isKep } from '../../../utils/AppConst';

const contactFields = (contact: string): Input[] => [
    {
        name: contact + 'LastName',
        label: 'Vezetéknév',
        format: { xs: 12, sm: 6, md: 3 },
    },
    {
        name: contact + 'FirstName',
        label: 'Keresztnév',
        format: { xs: 12, sm: 6, md: 3 },
    },
    {
        name: contact + 'Phone',
        label: 'Telefonszám',
        type: 'phone',
        format: { xs: 12, sm: 6, md: 3 },
        props: {
            country: isKep() ? 'ro' : 'hu',
        },
    },
    {
        name: contact + 'Email',
        label: 'E-mail cím',
        format: { xs: 12, sm: 6, md: 3 },
    },
];

export default function Contacts() {
    const { user } = useSelector((state: RootState) => state.auth);
    const { profile } = useSelector((state: RootState) => state.studentProfile);

    const Normal = () => (
        <Grid item container spacing={2} xs={12}>
            <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="gray">
                        Elsődleges kapcsolattartó
                    </Typography>
                </Grid>
                <Data label="Név">
                    {profile.primaryContactPersonLastName} {profile.primaryContactPersonFirstName || '-'}
                </Data>
                <Data label="Telefonszám">{profile.primaryContactPersonPhone || '-'}</Data>
                <Data label="E-mail cím">{profile.primaryContactPersonEmail || '-'}</Data>
            </Grid>
            <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="gray">
                        Másodlagos kapcsolattartó
                    </Typography>
                </Grid>
                <Data label="Név">
                    {profile.secondaryContactPersonLastName} {profile.secondaryContactPersonFirstName || '-'}
                </Data>
                <Data label="Telefonszám">{profile.secondaryContactPersonPhone || '-'}</Data>
                <Data label="E-mail cím">{profile.secondaryContactPersonEmail || '-'}</Data>
            </Grid>
        </Grid>
    );

    return (
        <Grid item container spacing={2} xs={12}>
            {!user.archive && (
                <EditForm
                    fields={[]}
                    onSubmit={studentProfileService.saveBaseDataSecondaryForm}
                    initialValues={filterUnusedKeys(
                        profile,
                        [...contactFields('primaryContactPerson'), ...contactFields('secondaryContactPerson')].map(
                            (field) => field.name,
                        ),
                    )}
                >
                    {(props) => (
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="gray">
                                    Elsődleges kapcsolattartó
                                </Typography>
                            </Grid>
                            {Children.toArray(
                                contactFields('primaryContactPerson').map((field) =>
                                    FieldGenerator({ ...field, ...props }),
                                ),
                            )}
                            <Grid item xs={12}>
                                <Typography variant="h5" color="gray">
                                    Másodlagos kapcsolattartó
                                </Typography>
                            </Grid>
                            {Children.toArray(
                                contactFields('secondaryContactPerson').map((field) =>
                                    FieldGenerator({ ...field, ...props }),
                                ),
                            )}
                        </Grid>
                    )}
                </EditForm>
            )}
            <Grid item xs={12}>
                <Normal />
            </Grid>
        </Grid>
    );
}
