import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { fixDateRangeFields } from '../../utils/AppConst';
import { IForm } from '../../utils/Interfaces/interfaces';

const langExamReportService = {
    filter: (form: IForm) =>
        API.get(endpoints.administration.langExamReport.main, { params: fixDateRangeFields(form) }),
    get: (objectId: number) =>
        API.get(endpoints.administration.langExamReport.get.replace('{id}', objectId.toString())),
    save: (objectId: number, form: IForm) =>
        API.post(endpoints.administration.langExamReport.save.replace('{id}', objectId.toString()), form),
    // FIXME: végpontok!
    categories: () => API.get(endpoints.masterData.languageExamRequirements.categories),
    levels: () => API.get(endpoints.masterData.languageExamRequirements.levels),
    filterByTrainingLevel: (form: IForm, trainingLevel: string | number) =>
        API.get(endpoints.masterData.languageExamRequirements.main + '?trainingLevel=' + trainingLevel, {
            params: fixDateRangeFields(form),
        }),
    /** Nyelvvizsga riport export */
    initiateExport: (form: { format: string }, filter: IForm) =>
        API.post(endpoints.administration.langExamReport.export.initiate, form, { params: fixDateRangeFields(filter) }),
    checkExport: (id: number) =>
        API.get(endpoints.administration.langExamReport.export.check.replace('{id}', id.toString())),
    downloadExport: (id: number) =>
        API.get(endpoints.administration.langExamReport.export.download.replace('{id}', id.toString()), {
            responseType: 'arraybuffer',
        }),
};

export default langExamReportService;
