import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Dispatch, SetStateAction } from 'react';
import { ComponentMap, ComponentMapKeys, ComponentMapMobile } from './utils';
import { STATUS } from '../Status/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { DataRow } from '../..';

export interface StudentOperationsCellRendererProps {
    params: GridRenderCellParams;
    setRefresh: Dispatch<SetStateAction<boolean>>;
}

export interface StudentOperationsCellRendererMobileProps {
    params: { row: DataRow };
    setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function OperationsCellRenderer({ params, setRefresh }: StudentOperationsCellRendererProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    if (params.row.status === STATUS.UNAVAILABLE) return null; //FIXME: ha kész lesz a backend, akkor ezt ki kell venni
    if (user.archive && (params.row.status === STATUS.WAITING || params.row.status === STATUS.UPLOAD)) return null;
    const Component = ComponentMap[params.row.name as ComponentMapKeys] ?? null;
    if (!Component) {
        return null;
    }
    return <Component setRefresh={setRefresh} params={params} />;
}

export function OperationsCellRendererMobile({ params, setRefresh }: StudentOperationsCellRendererMobileProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    if (params.row.status === STATUS.UNAVAILABLE) return null; //FIXME: ha kész lesz a backend, akkor ezt ki kell venni
    if (user.archive && (params.row.status === STATUS.WAITING || params.row.status === STATUS.UPLOAD)) return null;
    const Component = ComponentMapMobile[params.row.name as ComponentMapKeys] ?? null;
    if (!Component) {
        return null;
    }
    return <Component setRefresh={setRefresh} params={params} />;
}
