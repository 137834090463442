import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { createContext } from 'react';

type GridRenderCellParamsContextType = GridRenderCellParams;

const GridRenderCellParamsContext = createContext<GridRenderCellParamsContextType | null>(null);

interface RefreshContextProviderProps {
    children: React.ReactNode;
    params: GridRenderCellParamsContextType;
}

export function GridRenderCellParamsContextProvider(props: RefreshContextProviderProps) {
    return (
        <GridRenderCellParamsContext.Provider value={props.params}>
            {props.children}
        </GridRenderCellParamsContext.Provider>
    );
}

export default GridRenderCellParamsContext;
