import Checkbox from '@mui/material/Checkbox';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { SetStateAction, useEffect, useState } from 'react';

interface CheckBoxSelectButtonRendererProps extends GridRenderCellParams {
    refresh: () => void;
    setCheckbox: React.Dispatch<SetStateAction<number[]>>;
    allChecked: boolean;
    visibleChecked: boolean;
}

export default function CheckBoxSelectButton(props: CheckBoxSelectButtonRendererProps) {
    const { row, setCheckbox, allChecked, visibleChecked } = props;
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (allChecked || visibleChecked) {
            setChecked(true);
            setCheckbox((prev) => [...prev, row.id]);
        } else {
            setChecked(allChecked);
            setCheckbox([]);
        }
    }, [allChecked, row.id, setCheckbox, visibleChecked]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        setChecked(!checked);
        if (e.target.checked) {
            setCheckbox((prev) => [...prev, id]);
        } else {
            setCheckbox((prev) => prev.filter((value) => value != id));
        }
    };

    return <Checkbox id={'' + row.id} onChange={(e) => handleChange(e, row.id)} checked={checked} />;
}
