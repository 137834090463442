import { endpoints } from '../../utils/endPoints';
import instance from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
import { SectionRow } from '../../pages/MasterData/General/Students/Form/SectionGenerator';

const actualUniversityStudiesService = {
    saveActualUniversityStudies: (row: SectionRow | undefined, form: IForm) =>
        instance.post(
            endpoints.masterData.students.actualUniversityStudies.main.split('/').slice(0, -1).join('/') +
                (row ? '/' + row.id : ''),
            form,
        ),
    deleteActualUniversityStudies: (row: SectionRow | undefined) =>
        instance.delete(
            endpoints.masterData.students.actualUniversityStudies.main.split('/').slice(0, -1).join('/') +
                (row ? '/' + row.id : ''),
        ),
};

export default actualUniversityStudiesService;
