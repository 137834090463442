import { ICompletionData, ITrainingCompletionData } from '../../types';
import RequirementCellRenderer from './RequirementCellRenderer';

export default function LanguageExamRequirementsCellRenderer({
    row,
    url,
}: {
    row: ICompletionData | ITrainingCompletionData;
    url: (id: string | number) => string;
}) {
    return <RequirementCellRenderer url={url} row={row} name="languageExamRequirements" idKey="completionId" />;
}
