import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import BackgroundLogo from '../../images/MCClogo_negacolor.svg';
import MobileLogo from '../../images/mcc_logo_mobile.svg';
import Avatar from '@mui/material/Avatar';
import Logo from '../../images/image.svg';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100dvh',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('sm')]: {
            backgroundSize: '80%',
            backgroundImage: `url(${BackgroundLogo})`,
        },
        backgroundPosition: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '10%',
            backgroundSize: 'auto',
            backgroundImage: `url(${MobileLogo})`,
            boxShadow: 'none',
        },
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(2),
    },
}));

export default function OffLayout({
    children,
    hideLogo = false,
}: {
    children: JSX.Element[] | JSX.Element;
    hideLogo?: boolean;
}) {
    const classes = useStyles();
    const theme = useTheme() as Theme;

    return (
        <Grid container component="main" className={classes.root} style={{ paddingTop: 0 }}>
            <CssBaseline />
            <Grid
                item
                xs={12}
                sm={4}
                md={7}
                style={{ backgroundColor: theme.palette.secondary.main }}
                className={classes.image}
                component={Paper}
                elevation={20}
                square
            />
            <Grid item xs={12} sm={8} md={5} sx={{ overflow: 'auto' }}>
                <div className={classes.paper}>
                    {!hideLogo && (
                        <Avatar
                            className={classes.avatar}
                            src={Logo}
                            variant="square"
                            sx={{ height: '100%', width: '45%' }}
                        />
                    )}
                    {children}
                </div>
            </Grid>
        </Grid>
    );
}
