import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CancelButton, SubmitButton } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { getScoringTypeText } from './utils';

export default function CustomPointPerEvent<T extends IForm>({
    customCoursePointPerEventPoints,
    customCoursePointPerEventPointsMin,
    isArchiveUser = false,
}: {
    customCoursePointPerEventPoints: number;
    customCoursePointPerEventPointsMin: number;
    isArchiveUser?: boolean;
}) {
    const [open, setOpen] = useState(false);
    const formikProps = useFormikContext<T>();
    const { values, setFieldValue } = formikProps;
    const scoringType = values.scoringType || 'point';
    const text = getScoringTypeText('' + scoringType);

    const handleSubmit = useCallback(() => {
        setFieldValue('customCoursePointPerEvent', '0');
        setOpen(false);
    }, [setFieldValue]);

    const handleCancel = useCallback(() => {
        setFieldValue('customCoursePointPerEvent', '1');
        setOpen(false);
    }, [setFieldValue]);

    return (
        <Grid item container xs={12}>
            {FieldGenerator({
                ...{
                    name: 'customCoursePointPerEvent',
                    label: 'Eseményenként egyéni elbírálású pontozás',
                    type: 'checkbox',
                    props: {
                        disabled: isArchiveUser || formikProps.values.readonly,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            const { checked } = e.target;

                            if (!checked) {
                                setOpen(true);
                            }
                        },
                    },
                    format: { xs: 6 },
                },
                ...formikProps,
            })}
            {formikProps.values.customCoursePointPerEvent == 1 && (
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <strong>{`${customCoursePointPerEventPointsMin} - ${customCoursePointPerEventPoints}`}</strong>{' '}
                        {text} összesen eseményeknél
                    </div>
                </Typography>
            )}
            <Dialog open={open}>
                <DialogTitle>Megerősítés</DialogTitle>
                <DialogContent style={{ marginTop: '8px' }}>
                    <DialogContentText>
                        A jelölés eltávolításával az eseményekhez rögzített összes egyéni elbírálású pont törlésre
                        kerül. Biztosan folytatja a műveletet?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <CancelButton onClick={handleCancel}>Nem</CancelButton>
                        </Grid>
                        <Grid item>
                            <SubmitButton onClick={handleSubmit}>Igen</SubmitButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
