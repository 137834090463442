import DependentSelectInputGenerator from '../../../../../../components/Form/DependentSelectInputGenerator';
import IEntityBase from '../../../../../../interface/IEntityBase';
import { createOptionsWithArchive } from '../../../../../../utils/AppConst';
import { endpoints } from '../../../../../../utils/endPoints';
import { inputProps } from './utils';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const TrainingInput = ({ disabled, detailed }: { disabled: boolean; detailed: boolean }) => {
    const { id } = useParams();
    const input = useMemo(() => inputProps(disabled, id ?? 'new'), [disabled, id]);
    const isEdit = id && !detailed ? true : false;

    return (
        <DependentSelectInputGenerator
            parentInput={input}
            endpoint={`${endpoints.masterData.trainingLevels.forStudent.replace('{studentId}', id ?? 'new')}`}
            keyOfResponse={''}
            optionCreator={(data: IEntityBase[]) => createOptionsWithArchive(data)}
            disabled={disabled || isEdit}
        />
    );
};

export default TrainingInput;
