import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelButton, NewButton, Option } from '@silinfo/front-end-template';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Autocomplete from '../../../../components/Form/Autocomplete';
import { default as API } from '../../../../api';
import { endpoints } from '../../../../utils/endPoints';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';

export default function AddFromEventButton({ setTableLoading }: { setTableLoading: (val: boolean) => void }) {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | Option<string>>('');
    const { courseId } = useParams() as { courseId: string };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setValue('');
        setOpen(false);
    };

    const handleSubmit = () => {
        if (value) {
            setLoading(true);
            API.post(endpoints.courseManagement.courseEvent.addEventTar, {
                'event': (value as Option<string>).value,
                'course': courseId,
            })
                .then(() => {
                    setTableLoading(true);
                    setTableLoading(false);
                    handleClose();
                })
                .finally(() => setLoading(false));
        }
    };

    const getter = (term: string) =>
        API.get(endpoints.courseManagement.courseEvent.getEventAutocomplete + '?term=' + term);

    return (
        <>
            <NewButton onClick={handleOpen}>Hozzáadás rendezvénynaptárból</NewButton>
            <Dialog open={open} onClose={handleClose} fullWidth onClick={(e) => e.stopPropagation()}>
                <DialogTitle>Hozzáadás rendezvénynaptárból</DialogTitle>
                <DialogContent>
                    <Grid container sx={{ paddingTop: '10px' }}>
                        <Grid item xs={12}>
                            <Alert severity="info">
                                Az alábbi listából gépelés után kiválaszthatók a rendezvénynaptárban
                                <span style={{ fontWeight: 'bold' }}> publikált </span>
                                események, melyeket hozzá lehet rendelni a kurzushoz.
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: '10px' }}>
                            <Autocomplete
                                getter={getter}
                                textFieldProps={{ label: 'Esemény' }}
                                autocompleteProps={{
                                    value: value || '',
                                    onChange: (_, v) => setValue(v || ''),
                                    isOptionEqualToValue: undefined,
                                }}
                                createOptions={(options: { value: number; label: string }[]) =>
                                    options.map((option) => ({
                                        value: option.value.toString(),
                                        label: option.label,
                                    }))
                                }
                            />
                        </Grid>
                        <Grid item container spacing={2} justifyContent="flex-end" sx={{ marginTop: '10px' }}>
                            <Grid item>
                                <LoadingButton
                                    loading={loading}
                                    onClick={handleSubmit}
                                    variant="contained"
                                    type="submit"
                                >
                                    Hozzáadás
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <CancelButton onClick={handleClose}>Bezár</CancelButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
