import { GridAlignment, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Input, Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Dispatch, SetStateAction } from 'react';
import { OperationsCellRenderer } from './CellRendererers';
import QuestionnaireStatusCellRenderer from './CellRendererers/QuestionnaireStatusCellRenderer';
import QuestionnaireTemplateCellRenderer from './CellRendererers/QuestionnaireTemplateCellRenderer';
import SemesterStatusCellRenderer from './CellRendererers/SemesterStatusCellRenderer';
import { endpoints } from '../../../utils/endPoints';
import { TemplateAndGroupOptions } from './types';

export const SESSION_KEY = 'selfEvalAdministration';
export const TYPE_QUESTIONNAIRE = 'questionnaire';
export const TYPE_SUMMARY = 'summary';

export type SemesterStatusType = 'active' | 'foreign_scholarship' | null;

export type QuestionnaireTemplateType = 'summary' | 'questionnaire';

export interface QuestionnaireProgramTemplate {
    id: number;
    name: string;
    status: SemesterStatusType;
}
export interface QuestionnaireProgram {
    id: number;
    trainingProgram: string;
    semesterStatus: SemesterStatusType;
    questionnaireTemplate?: QuestionnaireProgramTemplate;
    questionnaireTemplateGroup?: QuestionnaireProgramTemplate;
    questionnaireTemplateSummary?: QuestionnaireProgramTemplate;
    active: boolean;
    activeSummary: boolean;
    activatedAt?: string;
    questionnaireSummaryActivatedAt?: string;
    filledStudentSelfEvaluationExists: boolean;
}

export const breadcrumbs = {
    selfAssessment: 'Önértékelések',
    administration: 'Adminisztráció',
};

export const header = (tenant: string | null) => {
    return {
        title: 'Önértékelések',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

export const semesterStatus = [
    { value: 'active', label: 'Aktív' },
    { value: 'foreign_scholarship', label: 'Külföldi ösztöndíjas' },
];

export const semesterStatusAll = semesterStatus.concat([{ value: 'passive', label: 'Passzív' }]);

export const documentStatus = [
    { value: '1', label: 'Aktiválva' },
    { value: '0', label: 'Nincs aktiválva' },
];

export const columns = (setRefresh: Dispatch<SetStateAction<boolean>>, archive?: boolean): GridColDef[] => {
    const cols: GridColDef[] = [
        {
            field: 'trainingProgram',
            headerName: 'Kézpési program',
            sortable: true,
        },
        {
            field: 'semesterStatus',
            headerName: 'Szemeszter státusz',
            sortable: true,
            align: 'center',
            renderCell: (params: GridRenderCellParams) => <SemesterStatusCellRenderer params={params} />,
        },
        {
            field: 'summaryTemplate',
            headerName: 'Sablon',
            sortable: true,
            align: 'center',
            renderCell: (params: GridRenderCellParams) => (
                <QuestionnaireTemplateCellRenderer type={TYPE_SUMMARY} params={params} />
            ),
        },
        {
            field: 'summaryStatus',
            headerName: 'Státusz',
            sortable: true,
            align: 'center',
            renderCell: (params: GridRenderCellParams) => (
                <QuestionnaireStatusCellRenderer params={params} type={TYPE_SUMMARY} />
            ),
        },

        ...(!archive
            ? [
                  {
                      field: 'summaryOperations',
                      headerName: 'Műveletek',
                      renderCell: (params: GridRenderCellParams) => (
                          <OperationsCellRenderer setRefresh={setRefresh} params={params} type={TYPE_SUMMARY} />
                      ),
                      align: 'center' as GridAlignment,
                      minWidth: 100,
                  },
              ]
            : []),
        {
            field: 'questionnaireTemplate',
            headerName: 'Sablon',
            sortable: true,
            align: 'center',
            renderCell: (params: GridRenderCellParams) => (
                <QuestionnaireTemplateCellRenderer type={TYPE_QUESTIONNAIRE} params={params} />
            ),
        },
        {
            field: 'questionnaireStatus',
            headerName: 'Státusz',
            sortable: true,
            align: 'center',
            renderCell: (params: GridRenderCellParams) => (
                <QuestionnaireStatusCellRenderer params={params} type={TYPE_QUESTIONNAIRE} />
            ),
        },
        ...(!archive
            ? [
                  {
                      field: 'questionnaireOperations',
                      headerName: 'Műveletek',
                      renderCell: (params: GridRenderCellParams) => (
                          <OperationsCellRenderer setRefresh={setRefresh} params={params} type={TYPE_QUESTIONNAIRE} />
                      ),
                      align: 'center' as GridAlignment,
                      minWidth: 110,
                  },
              ]
            : []),
    ];

    return cols.map((column) => ({
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        sortable: false,
        ...column,
    }));
};

export const filterFields = (options: Record<string, Option[]>): Input[] => {
    return [
        {
            name: 'trainingLevel',
            label: 'Képzési szint',
            type: 'multiselect',
            options: options.trainingLevels,
        },
        {
            name: 'trainingProgram',
            label: 'Képzési program',
            type: 'multiselect',

            options: options.trainingPrograms,
        },
        {
            name: 'semesterStatus',
            label: 'Szemeszter státusz',

            type: 'multiselect',
            options: options.semesterStatus,
        },
        {
            name: 'questionnaireTemplate',
            label: 'Kérdőívsablon',
            type: 'backendSelect',
            url: endpoints.selfEvaluation.questionnaireTemplate.templateAndGroupOptions,
            multiple: true,
            justValue: true,
            props: {
                groupBy: (option: TemplateAndGroupOptions) => option.type,
                renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                    <li {...props} style={{ color: 'black' }}>
                        {option.label}
                    </li>
                ),
            },
            format: {
                xs: 12,
                md: 4,
            },
        },
        {
            name: 'questionnaireStatus',
            label: 'Kérdőív státusza',
            type: 'multiselect',
            options: options.questionnaireStatus,
        },
        {
            name: 'summaryTemplate',

            label: 'Összefoglaló dokumentum sablon',
            type: 'multiselect',
            options: options.summaryTemplates,
            props: {
                renderOption: (props: object, option: Option) => {
                    const { label } = option;
                    if (label.includes('(archív)')) {
                        return (
                            <li {...props} style={{ color: '#999' }}>
                                {option.label}
                            </li>
                        );
                    } else {
                        return <li {...props}>{option.label}</li>;
                    }
                },
            },
        },
        {
            name: 'summaryStatus',

            label: 'Összefoglaló dokumentum státusza',
            type: 'multiselect',
            options: options.summaryStatus,
        },
    ];
};

const autoload = true as const;

export const typeOptions: { label: string; value: string }[] = [
    {
        label: 'Kérdőívsablon',
        value: 'template',
    },
    {
        label: 'Kérdőívcsoport',
        value: 'template_group',
    },
];

export const tableProps = (
    options: Record<string, Option[]>,
    refresh: boolean,
    setRefresh: Dispatch<SetStateAction<boolean>>,
    archive?: boolean,
    tenant?: string,
) => ({
    autoload,
    header: header(tenant || 'TAR'),
    columns: columns(setRefresh, archive),
    filter: { fields: filterFields(options) },
    refresh: refresh,
    defaultSort: { trainingProgram: 'asc' as const },
    responseKey: 'data',
    tableProps: {
        columnBuffer: columns(setRefresh).length,
        disableSelectionOnClick: true,
        columnGroupingModel: [
            {
                groupId: 'Összefoglaló dokumentum',
                headerAlign: 'center' as GridAlignment,
                children: [
                    {
                        field: 'summaryTemplate',
                    },
                    {
                        field: 'summaryStatus',
                    },
                    {
                        field: 'summaryOperations',
                    },
                ],
            },
            {
                groupId: 'Kérdőívsablon',
                headerAlign: 'center' as GridAlignment,
                children: [
                    {
                        field: 'questionnaireTemplate',
                    },
                    {
                        field: 'questionnaireStatus',
                    },
                    {
                        field: 'questionnaireOperations',
                    },
                ],
            },
        ],
        experimentalFeatures: {
            columnGrouping: true,
        },
    },
});
