import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { create, remove } from '../../../store/notification';
import { downloadFile } from '../../../utils/AppConst';
import { IForm } from '../../../utils/Interfaces/interfaces';

export interface SelectedExportContext {
    initiateExport: (form: { format: string }, filter: IForm) => Promise<AxiosResponse>;
    checkExport: (id: number) => Promise<AxiosResponse>;
    downloadExport: (id: number) => Promise<AxiosResponse>;
    selected: IForm;
}

export type SelectedExportButtonProps = {
    exportContext: SelectedExportContext;
    format?: string;
    multipleFormat?: string;
    title?: string;
    disabled?: boolean;
};

export function createExportHandleClick(
    exportContext: SelectedExportContext,
    dispatch: Dispatch<unknown>,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
) {
    return () => {
        const toSendForm = {
            multiple: true,
            multipleFormat: 'zip',
            format: 'docx',
        };
        dispatch(remove());
        dispatch(create({ type: 'info', message: 'A fájl/fájlok előállítása folyamatban...' }));
        const searchForm = exportContext.selected;
        setIsLoading(true);
        exportContext
            .initiateExport(toSendForm, searchForm)
            .then((data: AxiosResponse) => {
                const id = data.data.id;
                let ready = false;
                let isError = false;
                let fileName = '';
                let msg = '';

                const interval = setInterval(() => {
                    exportContext
                        .checkExport(id)
                        .then((d: AxiosResponse) => {
                            const status = d.data.status;
                            fileName = d.data.fileName;
                            msg = d.data.message;
                            ready = status === 'finished' || status === 'unknown_error';
                            isError = status !== 'finished';
                        })
                        .catch((error: AxiosError) => console.log(error, 'hiba'));
                    if (ready) {
                        clearInterval(interval);
                        if (!isError) {
                            exportContext
                                .downloadExport(id)
                                .then((response: AxiosResponse) => {
                                    downloadFile(response.data, fileName);

                                    dispatch(remove());
                                    dispatch(create({ type: 'success', message: `A fájl sikeresen előállítva!` }));
                                    setIsLoading(false);
                                })
                                .catch(() => {
                                    dispatch(remove());
                                    dispatch(
                                        create({ type: 'error', message: `Hiba történt a fájl előállítása során` }),
                                    );
                                });
                        } else {
                            dispatch(remove());
                            if (msg.includes('Unable to write file at location')) {
                                dispatch(
                                    create({
                                        type: 'error',
                                        message: `Sikertelen generálás, a generálandó fájl neve túl hosszú!`,
                                    }),
                                );
                            } else {
                                dispatch(create({ type: 'error', message: `Hiba történt a fájl előállítása során` }));
                            }
                            setIsLoading(false);
                            clearInterval(interval);
                        }
                    }
                }, 2000);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };
}

export default function SelectedExportButton(props: SelectedExportButtonProps): JSX.Element {
    const { exportContext } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    return (
        <LoadingButton
            variant="contained"
            disabled={isLoading || props.disabled}
            loading={isLoading}
            loadingPosition="start"
            onClick={createExportHandleClick(exportContext, dispatch, setIsLoading)}
            startIcon={<DownloadIcon />}
        >
            {props.title || 'Exportálás'}
        </LoadingButton>
    );
}
