import { Input } from '@silinfo/front-end-template';
import { intervalFields, yearOptions } from './utils';

const activeOptions = [
    { value: 'true', label: 'Aktív' },
    { value: 'false', label: 'Inaktív' },
];

//TODO: itt lehetnek multiselect filterek -> backend-en is kezelni ugyanígy
const fields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'processName',
        label: 'Azonosító',
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'active',
        label: 'Szemeszter',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'enrollmentActive',
        label: 'Beiratkozási időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'terminationActive',
        label: 'Kiiratkozási időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'moveOutActive',
        label: 'Kiköltözési időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'courseEnrollmentActive',
        label: 'Kurzusfelvételi időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'courseTerminationActive',
        label: 'Kurzuskiiratkozási időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'gradingActive',
        label: 'Értékelési időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'roomAllocationActive',
        label: 'Szobabeosztási időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'relocationActive',
        label: 'Átköltözési időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: 'admissionActive',
        label: 'Felvételi időszak',
        type: 'select',
        options: activeOptions,
        format: { xs: 12, md: 6, lg: 6 },
    },
];

export const formFields = (readonly: boolean, semesterId: string): Input[] => [
    {
        name: 'year',
        label: 'Év',
        type: 'select',
        fieldType: 'base',
        props: {
            disabled: readonly || !!semesterId,
        },
        options: yearOptions,
        format: { md: 3, xs: 12 },
    },
    {
        name: 'period',
        label: 'Időszak',
        type: 'select',
        fieldType: 'base',
        props: {
            disabled: readonly || !!semesterId,
        },
        options: [
            {
                value: 'spring',
                label: 'Tavasz',
            },
            {
                value: 'autumn',
                label: 'Ősz',
            },
        ],
        format: { md: 3, xs: 12 },
    },
    {
        name: 'name',
        label: 'Szemeszter neve',
        format: { md: 3, xs: 12 },
        props: {
            disabled: true,
        },
    },
    {
        name: 'processName',
        label: 'Azonosító',
        props: {
            disabled: true,
        },
        format: { md: 3, xs: 12 },
    },
    ...intervalFields,
];

export default fields;
