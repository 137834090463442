import Grid from '@mui/material/Grid';
import { Accordion, Loading, Page, Select, TextField } from '@silinfo/front-end-template';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Buttons from '../../../../../components/Form/Buttons';
import { FieldGenerator } from '../../../../../components/Form/FieldGenerator';
import trainingProgramsService, { ITrainingProgramsForm } from '../../../../../services/masterData/trainingPrograms';
import trainingLevelService from '../../../../../services/masterData/trainingLevels';
import { create } from '../../../../../store/notification';
import { Option, transformApiErrors } from '../../../../../utils/AppConst';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { makeOptionsStart, YEAR_OPTIONS } from './index';
import { useTenant } from '../../../../../components/TenantContext';

const initialState = {
    name: '',
    englishName: '',
    mccDegreeHungarian: '',
    mccDegreeEnglish: '',
    duration: '1',
    trainingProgramLeader: '',
    collegeYears: [],
    parentTrainingProgram: null,
};

const ID_SCHOOL_SYSTEM = 2; // Iskolarendszeri képzés
const ID_SCHOOL_SYSTEM_NEXT = 4; // Iskolarendszeri képzés - Hosszabbítás

export default function TrainingProgramForm({ readOnly = false }: { readOnly?: boolean }) {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<ITrainingProgramsForm>(initialState);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const { id, programId } = useParams();
    const dispatch = useDispatch();
    const [collegeYears, setCollegeYears] = useState<[]>([]);
    const [parentPrograms, setParentPrograms] = useState<Option[]>([]);
    const [levelName, setLevelName] = useState('');
    const tenant = useTenant();

    const title = (id: string | undefined, readOnly: boolean) => {
        if (readOnly) {
            return 'Képzési program megtekintése';
        } else if (id) {
            return 'Képzési program szerkesztése';
        } else {
            return 'Képzési program hozzáadása';
        }
    };

    const URL = clientEndPoints.md_training_levels_programs_list.replace(':id', id ? id : '');

    const header = {
        project: tenant || 'TAR',
        title: 'Képzési programok',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: levelName + ' szint',
            [clientEndPoints.md_training_levels_programs_list.replace(':id', id?.toString() || '')]:
                'Képzési programok',
            [programId ? 'trainingProgramsEdit' : 'trainingProgramsNew']: title(id, readOnly),
        },
    };

    useEffect(() => {
        setLoading(true);
        if (programId) {
            axios
                .all([
                    trainingProgramsService.get(programId).then((response: AxiosResponse) => {
                        const data = response.data;

                        setForm(() => ({
                            name: data.name,
                            englishName: data.englishName,
                            duration: data.duration,
                            collegeYears: makeOptionsStart(data.collegeYears),
                            mccDegreeHungarian: data.mccDegreeHungarian,
                            mccDegreeEnglish: data.mccDegreeEnglish,
                            trainingProgramLeader: data.trainingProgramLeader,
                            parentTrainingProgram: data.parentTrainingProgram
                                ? data.parentTrainingProgram['@id']
                                : null,
                        }));
                    }),
                    trainingProgramsService
                        .collegeYearsEdit(programId)
                        .then((response: AxiosResponse) => setCollegeYears(response.data || [])),
                ])
                .finally(() => {
                    setLoading(false);
                });
        } else {
            trainingProgramsService
                .collegeYearsNew()
                .then((response: AxiosResponse) => setCollegeYears(response.data || []))
                .finally(() => {
                    setLoading(false);
                });
        }

        if (id === ID_SCHOOL_SYSTEM_NEXT.toString()) {
            trainingProgramsService
                .optionsByTrainingLevel(ID_SCHOOL_SYSTEM.toString())
                .then((response: AxiosResponse) => {
                    if (response?.data) {
                        setParentPrograms([{ value: null, label: 'Nincs' }, ...response.data]);
                    }
                });
        }

        trainingLevelService.trainingLevels().then((response) => {
            setLevelName(response.data.find((element: Option) => element.value == id).label);
        });
    }, [programId, id]);

    const handleSubmit = (form: ITrainingProgramsForm, { setErrors }: FormikHelpers<ITrainingProgramsForm>) => {
        setFormLoading(true);

        (programId
            ? trainingProgramsService.update(programId, {
                  ...form,
                  trainingLevel: '/training-levels/' + id,
              })
            : trainingProgramsService.create({
                  ...form,
                  trainingLevel: '/training-levels/' + id,
              })
        )
            .then((response: AxiosResponse) => {
                setForm(response.data['hydra:member'] || {});
                dispatch(create({ type: 'success', message: 'Sikeres szerkesztés', redirect: URL }));
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
            })
            .finally(() => {
                setFormLoading(false);
            });
    };

    if (loading) {
        return <Loading />;
    }
    return (
        <Page header={header}>
            <Accordion title={title(id, readOnly)}>
                <Formik initialValues={form} onSubmit={handleSubmit}>
                    {(formikProps) => {
                        return (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Field as="div" field="name">
                                            <TextField
                                                name="name"
                                                label="Név"
                                                error={!!(formikProps.touched.name && formikProps.errors.name)}
                                                helperText={formikProps.touched.name && formikProps.errors.name}
                                                value={formikProps.values.name}
                                                onChange={(e) => formikProps.setFieldValue('name', e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Field as="div" field="englishName">
                                            <TextField
                                                name="englishName"
                                                label="Angol név"
                                                error={
                                                    !!(
                                                        formikProps.touched.englishName &&
                                                        formikProps.errors.englishName
                                                    )
                                                }
                                                helperText={
                                                    formikProps.touched.englishName && formikProps.errors.englishName
                                                }
                                                value={formikProps.values.englishName}
                                                onChange={(e) =>
                                                    formikProps.setFieldValue('englishName', e.target.value)
                                                }
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Field as="div" field="duration">
                                            <Select
                                                name="duration"
                                                label="Időtartam"
                                                error={!!(formikProps.touched.duration && formikProps.errors.duration)}
                                                helperText={formikProps.touched.duration && formikProps.errors.duration}
                                                value={formikProps.values.duration}
                                                onChange={(e) => formikProps.setFieldValue('duration', e.target.value)}
                                                options={YEAR_OPTIONS}
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    {FieldGenerator({
                                        name: 'collegeYears',
                                        label: 'Évfolyam',
                                        options: collegeYears,
                                        type: 'multiselect',
                                        format: { xs: 12, md: 3, lg: 4 },
                                        ...formikProps,
                                        props: { disabled: readOnly },
                                    })}
                                    {id === ID_SCHOOL_SYSTEM_NEXT.toString() &&
                                        FieldGenerator({
                                            name: 'parentTrainingProgram',
                                            label: 'Kapcsolódó iskolarendszeri képzés',
                                            options: parentPrograms,
                                            type: 'select',
                                            format: { xs: 12, md: 3, lg: 4 },
                                            ...formikProps,
                                        })}
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Field as="div" field="mccDegreeHungarian">
                                            <TextField
                                                name="mccDegreeHungarian"
                                                label="MCC Degree (magyar)"
                                                error={
                                                    !!(
                                                        formikProps.touched.mccDegreeHungarian &&
                                                        formikProps.errors.mccDegreeHungarian
                                                    )
                                                }
                                                helperText={
                                                    formikProps.touched.mccDegreeHungarian &&
                                                    formikProps.errors.mccDegreeHungarian
                                                }
                                                value={formikProps.values.mccDegreeHungarian}
                                                onChange={(e) =>
                                                    formikProps.setFieldValue('mccDegreeHungarian', e.target.value)
                                                }
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Field as="div" field="mccDegreeEnglish">
                                            <TextField
                                                name="mccDegreeEnglish"
                                                label="MCC Degree (angol)"
                                                error={
                                                    !!(
                                                        formikProps.touched.mccDegreeEnglish &&
                                                        formikProps.errors.mccDegreeEnglish
                                                    )
                                                }
                                                helperText={
                                                    formikProps.touched.mccDegreeEnglish &&
                                                    formikProps.errors.mccDegreeEnglish
                                                }
                                                value={formikProps.values.mccDegreeEnglish}
                                                onChange={(e) =>
                                                    formikProps.setFieldValue('mccDegreeEnglish', e.target.value)
                                                }
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Field as="div" field="trainingProgramLeader">
                                            <TextField
                                                name="trainingProgramLeader"
                                                label="Képzési program vezető"
                                                error={
                                                    !!(
                                                        formikProps.touched.trainingProgramLeader &&
                                                        formikProps.errors.trainingProgramLeader
                                                    )
                                                }
                                                helperText={
                                                    formikProps.touched.trainingProgramLeader &&
                                                    formikProps.errors.trainingProgramLeader
                                                }
                                                value={formikProps.values.trainingProgramLeader}
                                                onChange={(e) =>
                                                    formikProps.setFieldValue('trainingProgramLeader', e.target.value)
                                                }
                                                disabled={readOnly}
                                            />
                                        </Field>
                                    </Grid>
                                    <Buttons buttonLoading={formLoading} cancel={URL} readOnly={readOnly} />
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}
