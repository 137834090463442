import CommentCellRenderer from './CommentCellRenderer';
import { IEvent } from './types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

export const tableColumns = (renderCell: (row: GridRenderCellParams<IEvent, IEvent>) => JSX.Element): GridColDef[] =>
    [
        {
            headerName: 'Eseménytípus',
            field: 'eventType',
        },
        {
            headerName: 'Oktató',
            field: 'instructors',
        },
        {
            headerName: 'Vendégelőadó',
            field: 'guestInstructors',
        },
        {
            headerName: 'Helyszín',
            field: 'locations',
        },
        {
            headerName: 'Tartalom',
            field: 'comment',
            renderCell: CommentCellRenderer,
            sortable: false,
        },
        {
            headerName: 'Időpont',
            field: 'eventDate',
            minWidth: 350,
        },
        { headerName: 'Műveletek', field: 'operations', renderCell, sortable: false },
    ].map((col) => ({ flex: 1, minWidth: 160, ...col }));
