import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import financeFinancialApprovalsService from '../../../services/masterData/finances/financialApprovals/financialApprovals';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { create, remove } from '../../../store/notification';

interface RefreshCharmDataButtonProps {
    outerLoading?: (loading: boolean) => void;
}

function RefreshCharmDataButton(props: RefreshCharmDataButtonProps) {
    const { outerLoading } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        setIsLoading(true);

        if (outerLoading) {
            outerLoading(true);
        }

        financeFinancialApprovalsService
            .refreshCharmData()
            .then((response: AxiosResponse) => {
                const sessionId = response.data.sessionId;
                let ready = false;
                let isError = false;

                const interval = setInterval(() => {
                    financeFinancialApprovalsService
                        .checkCharmRequest(sessionId)
                        .then((d: AxiosResponse) => {
                            const status = d.data.status;

                            ready = status === 'request_success' || status === 'request_error';
                            isError = status !== 'request_success';
                        })
                        .catch((error: AxiosError) => {
                            setIsLoading(false);
                            if (outerLoading) {
                                outerLoading(false);
                            }
                            clearInterval(interval);
                            dispatch(remove());
                            dispatch(create({ type: 'error', message: `Hiba történt az adatok lekérése során!` }));
                            console.log(error);
                        });
                    if (ready) {
                        clearInterval(interval);
                        if (isError) {
                            setIsLoading(false);
                            if (outerLoading) {
                                outerLoading(false);
                            }
                            dispatch(remove());
                            dispatch(create({ type: 'error', message: `Hiba történt az adatok lekérése során!` }));
                        } else {
                            setIsLoading(false);
                            if (outerLoading) {
                                outerLoading(false);
                            }
                            dispatch(remove());
                            dispatch(create({ type: 'success', message: `A számlák lekérése sikeres volt!` }));
                        }
                    }
                }, 2000);
            })
            .catch((error: AxiosError) => {
                setIsLoading(false);
                if (outerLoading) {
                    outerLoading(false);
                }
                dispatch(remove());
                dispatch(create({ type: 'error', message: `Hiba történt az adatok lekérése során!` }));
                console.log(error);
            });
    };

    return (
        <LoadingButton
            variant="contained"
            disabled={isLoading}
            loading={isLoading}
            loadingPosition="start"
            onClick={handleClick}
            startIcon={<DownloadIcon />}
        >
            Számlák lekérdezése
        </LoadingButton>
    );
}

export default RefreshCharmDataButton;
