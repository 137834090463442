import CharmLogs from '../../../pages/Administration/SystemLog/CharmLogs/CharmLogs';

export const charmLogs = {
    path: 'charm-logs',
    children: [
        {
            path: '',
            element: <CharmLogs />,
            name: 'admin_charm_logs_list',
        },
    ],
};
