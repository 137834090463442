import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FieldGenerator, Option } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { IAddress, IEventForm, IExternalAddress } from '../utils';
import { endpoints } from '../../../../../utils/endPoints';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SeparateLocationsCheckBox from '../SeparateLocationsCheckBox';
import Tooltip from '@mui/material/Tooltip';
import { useTabState } from '../TabStateContext';

const Inputs = ({ readOnly, currentTab }: { readOnly: boolean | undefined; currentTab?: number }) => {
    const formikProps = useFormikContext<IEventForm>();
    const currentIndex = currentTab ?? 0;
    const addresses = formikProps.values.addresses;
    const eventLocationId = addresses[currentIndex].id;

    const handleCheckboxChange = (checked: boolean, fieldName: string) => {
        if (currentTab !== undefined) {
            formikProps.setFieldValue('addresses.' + currentIndex + '.' + fieldName, checked);
        } else {
            addresses?.forEach((_, index) => {
                formikProps.setFieldValue('addresses.' + index + '.' + fieldName, checked);
            });
        }
    };

    const handleSelectChange = (_: unknown, value: Option | null) => {
        if (currentTab !== undefined) {
            formikProps.setFieldValue('addresses.' + currentIndex + '.' + 'receptionType', value?.value ?? '');
        } else {
            addresses?.forEach((_, index) => {
                formikProps.setFieldValue('addresses.' + index + '.' + 'receptionType', value?.value ?? '');
            });
        }
    };

    return (
        <Grid item xs={12} container spacing={2} key={'editable'}>
            {FieldGenerator({
                name: 'waterForPresenter',
                label: 'Előadónak víz bekészítése',
                type: 'checkbox',
                format: {
                    xs: 12,
                },
                options: {
                    checked: true,
                    unChecked: false,
                },
                props: {
                    disabled: readOnly,
                    checked: addresses?.[currentIndex].waterForPresenter ?? false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCheckboxChange(e.target.checked, 'waterForPresenter'),
                },
                fieldType: 'base',
                ...formikProps,
            })}
            {FieldGenerator({
                name: 'receptionType',
                label: 'Fogadás jellege',
                type: 'backendSelect',
                url: endpoints.courseManagement.courseList.listReceptionTypes,
                format: {
                    xs: 12,
                },
                justValue: true,
                props: {
                    disabled: readOnly,
                    value: addresses?.[currentIndex].receptionType ?? false,
                    onChange: handleSelectChange,
                },
                fieldType: 'base',
                ...formikProps,
            })}
            <Grid item container xs={12}>
                {FieldGenerator({
                    name: 'waterFoodNeeded',
                    label: 'Ital-, ételbekészítés',
                    type: 'checkbox',
                    format: {
                        xs: 12,
                    },
                    options: {
                        checked: true,
                        unChecked: false,
                    },
                    props: {
                        disabled: readOnly,
                        checked: addresses?.[currentIndex].waterFoodNeeded ?? false,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCheckboxChange(e.target.checked, 'waterFoodNeeded'),
                    },
                    fieldType: 'base',
                    ...formikProps,
                })}
                <Grid item>
                    <Typography variant="body2" color="textSecondary">
                        Kérjük, hogy catering igényeteket egyeztessétek a feneketlento@scruton.hu e-mail címen.
                    </Typography>
                </Grid>
            </Grid>
            {addresses &&
                addresses[currentIndex].waterFoodNeeded &&
                FieldGenerator({
                    type: 'file',
                    downloadUrl: `${endpoints.fileManagement.download}?id=${eventLocationId}&type=catering`,
                    name: 'cateringOffer',
                    tempType: 'catering',
                    origFileName: 'addresses.' + currentIndex + '.cateringFileName',
                    uuidFileName: 'addresses.' + currentIndex + '.cateringFilePath',
                    label: 'Catering ajánlat',
                    format: {
                        xs: 12,
                    },
                    props: {
                        disabled: readOnly,
                    },
                    ...formikProps,
                })}
        </Grid>
    );
};

export default function Catering({ readOnly }: { readOnly: boolean | undefined }) {
    const formikProps = useFormikContext<IEventForm>();
    const [separateLocations, setSeparateLocations] = useState(formikProps.values.cateringPerLocation);
    const [value, setValue] = useState('0');
    const addresses = formikProps.values.addresses;
    const { tabState, setTabState } = useTabState();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const handleChange = (newValue: boolean) => {
        formikProps.setFieldValue('cateringPerLocation', newValue);
        if (separateLocations) {
            addresses?.forEach((_, index) => {
                if (index !== 0) {
                    formikProps.setFieldValue(
                        'addresses.' + index + '.catering.' + 'waterForPresenter',
                        addresses[0].waterForPresenter,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.catering.' + 'receptionType',
                        addresses[0].receptionType,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.catering.' + 'waterFoodNeeded',
                        addresses[0].waterFoodNeeded,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.catering.' + 'cateringFileName',
                        addresses[0].cateringFileName,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.catering.' + 'cateringFilePath',
                        addresses[0].cateringFilePath,
                    );
                }
            });
        }
    };

    useEffect(() => {
        if (firstLoad) {
            tabState.forEach((address, index) => {
                if (
                    (address.cateringFileName ||
                        address.cateringFilePath ||
                        address.waterFoodNeeded ||
                        address.waterForPresenter ||
                        address.receptionType) &&
                    index < addresses.length
                ) {
                    formikProps.setFieldValue('addresses.' + index + '.cateringFileName', address.cateringFileName);
                    formikProps.setFieldValue('addresses.' + index + '.cateringFilePath', address.cateringFilePath);
                    formikProps.setFieldValue('addresses.' + index + '.waterFoodNeeded', address.waterFoodNeeded);
                    formikProps.setFieldValue('addresses.' + index + '.waterForPresenter', address.waterForPresenter);
                    formikProps.setFieldValue('addresses.' + index + '.receptionType', address.receptionType);
                }
            });
            setFirstLoad(false);
        }
    }, [addresses.length, firstLoad, formikProps, tabState]);

    useEffect(() => {
        return () => {
            setTabState((prevTabState) => {
                const updatedTabState = addresses.map((address, index) => {
                    return {
                        ...address,
                        ...prevTabState[index],
                        waterFoodNeeded: separateLocations
                            ? addresses[index]?.waterFoodNeeded ?? false
                            : addresses[0]?.waterFoodNeeded ?? false,
                        waterForPresenter: separateLocations
                            ? addresses[index]?.waterForPresenter ?? false
                            : addresses[0]?.waterForPresenter ?? false,
                        cateringFileName: separateLocations
                            ? addresses[index]?.cateringFileName ?? ''
                            : addresses[0]?.cateringFileName ?? '',
                        cateringFilePath: separateLocations
                            ? addresses[index]?.cateringFilePath ?? ''
                            : addresses[0]?.cateringFilePath ?? '',
                        receptionType: separateLocations
                            ? addresses[index]?.receptionType ?? ''
                            : addresses[0]?.receptionType ?? '',
                    };
                });

                return updatedTabState;
            });
        };
    }, [addresses, separateLocations, setTabState]);

    return (
        <Grid item xs={12} container spacing={4} sx={{ paddingLeft: '2rem' }}>
            {separateLocations ? (
                <>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '0.5rem' }} width="100%">
                            <TabList
                                variant="fullWidth"
                                onChange={(_, val) => setValue(val)}
                                aria-label="lab API tabs example"
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        backgroundColor: '#fff !important',
                                        color: '#22344c !important',
                                    },
                                }}
                            >
                                {addresses?.map((element: IAddress | IExternalAddress, index) =>
                                    'roomName' in element ? (
                                        <Tab
                                            key={index}
                                            label={
                                                <Tooltip title={element.roomName ?? 'Nincs megadva'}>
                                                    <span>
                                                        {element.roomName.length > 32
                                                            ? element.roomName.substring(0, 32) + '...'
                                                            : element.roomName}
                                                    </span>
                                                </Tooltip>
                                            }
                                            value={'' + index}
                                        />
                                    ) : (
                                        <Tab
                                            key={index}
                                            label={
                                                <Tooltip title={element.location ?? 'Nincs megadva'}>
                                                    <span>
                                                        {element.location.length > 32
                                                            ? element.location.substring(0, 32) + '...'
                                                            : element.location}
                                                    </span>
                                                </Tooltip>
                                            }
                                            value={'' + index}
                                        />
                                    ),
                                )}
                            </TabList>
                            {addresses?.map((_, index) => (
                                <TabPanel key={index} value={'' + index}>
                                    <Inputs currentTab={index} readOnly={readOnly} />
                                </TabPanel>
                            ))}
                        </Box>
                    </TabContext>
                    <SeparateLocationsCheckBox
                        checkedState={[separateLocations, setSeparateLocations]}
                        label="Helyszínenként külön szeretném beállítani a cateringet"
                        handleChange={handleChange}
                        readOnly={readOnly}
                    />
                </>
            ) : (
                <Grid container item xs={12} spacing={2}>
                    <SeparateLocationsCheckBox
                        checkedState={[separateLocations, setSeparateLocations]}
                        label="Helyszínenként külön szeretném beállítani a cateringet"
                        handleChange={handleChange}
                        readOnly={readOnly}
                    />
                    <Inputs readOnly={readOnly} />
                </Grid>
            )}
        </Grid>
    );
}
