import API from '../../api';
import { fixDateRangeFields } from '../../utils/AppConst';
import { IForm } from '../../utils/Interfaces/interfaces';
import { IReportExport } from '../../pages/Admission/Riport/types';
import { endpoints } from '../../utils/endPoints';

const admissionApplicantService = {
    initiateExport: (request: IReportExport) => (form: { format: string }, filter: IForm) => {
        return API.post(endpoints.admission.export.initiateApplicant, request, {
            params: fixDateRangeFields(filter),
        });
    },
    checkExport: () => (exportId: number) =>
        API.get(endpoints.admission.export.checkApplicant.replace('{id}', `${exportId}`)),
    downloadExport: () => (exportId: number) =>
        API.get(endpoints.admission.export.downloadApplicant.replace('{id}', `${exportId}`), {
            responseType: 'arraybuffer',
        }),
};

export default admissionApplicantService;
