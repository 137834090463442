import { makeStyles } from '@mui/styles';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import InfoCellRenderer from './InfoCellRenderer';
import StateCellRenderer from './StateCellRenderer';
import TypeCellRenderer from './TypeCellRenderer';

export const header = (tenant: string | null) => {
    return {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_sys_log]: 'Napló',
            [clientEndPoints.admin_email_logs_list]: 'E-mail napló',
        },
        title: 'E-mail napló',
        project: tenant || 'TAR',
    };
};

export const useStyles = makeStyles(() => ({
    eyeIcon: {
        color: 'white',
        backgroundColor: '#0288d1',
        '&:hover': {
            backgroundColor: 'white',
            color: '#0288d1',
        },
    },
}));

export const statuses = ['generation', 'waiting', 'error', 'success'];

export interface EmailLogTypes {
    type: string;
}

export const columns = [
    { headerName: 'E-mail cím', field: 'mailingAddress' },
    { headerName: 'Típus', field: 'type', renderCell: TypeCellRenderer, sortable: false },
    { headerName: 'Tárgy', field: 'subject', sortable: false },
    { headerName: 'Kiküldés dátuma', field: 'sentAt' },
    { headerName: 'Generálás dátuma', field: 'generatedAt' },
    {
        headerName: 'Státusz',
        field: 'status',
        renderCell: StateCellRenderer,
        sortable: false,
        minWidth: 100,
    },
    {
        headerName: 'Műveletek',
        field: 'operations',
        renderCell: InfoCellRenderer,
        sortable: false,
        minWidth: 100,
    },
].map((col) => ({ flex: 1, minWidth: 200, ...col }));
