import Grid from '@mui/material/Grid';
import { StudentOperationsCellRendererMobileProps, StudentOperationsCellRendererProps } from '.';
import { endpoints } from '../../../../utils/endPoints';
import DownloadButton from './Download/DownloadButton';
import FileUploadDialog from './FileUploadDialog';
import PreviewUniversityIndex from './PreviewUniversityIndex';
import { STATUS } from '../Status/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export default function UniversityIndex({ setRefresh, params }: StudentOperationsCellRendererProps) {
    const { user } = useSelector((state: RootState) => state.auth);

    return (
        <Grid item container xs={12} spacing={1} justifyContent="center">
            {!user.archive && <FileUploadDialog setRefresh={setRefresh} filename={params.row.file?.filename} />}
            {params.row.status === STATUS.UPLOADED && params.row.file?.filename && params.row.file?.id && (
                <>
                    <Grid item>
                        <PreviewUniversityIndex file={params.row.file} />
                    </Grid>
                    <Grid item>
                        <DownloadButton
                            filename={params.row.file.filename || 'egyetemiindex.pdf'}
                            url={`${
                                endpoints.fileManagement.download
                            }?id=${params.row.file.id.toString()}&type=university-index`}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export function UniversityIndexMobile({ setRefresh, params }: StudentOperationsCellRendererMobileProps) {
    const { user } = useSelector((state: RootState) => state.auth);

    return (
        <Grid item container xs={12} spacing={2} justifyContent="center">
            {!user.archive && <FileUploadDialog setRefresh={setRefresh} filename={params.row.file?.filename} />}
            {params.row.status === STATUS.UPLOADED && params.row.file?.filename && params.row.file?.id && (
                <>
                    <Grid item>
                        <PreviewUniversityIndex file={params.row.file} />
                    </Grid>
                    <Grid item>
                        <DownloadButton
                            filename={params.row.file.filename || 'egyetemiindex.pdf'}
                            url={`${
                                endpoints.fileManagement.download
                            }?id=${params.row.file.id.toString()}&type=university-index`}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
}
