import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import studentsService from '../../../../services/masterData/students';
import axios from 'axios';
import { Option } from '../../../../utils/AppConst';
import { IActualUniversityStudies } from '../../../../utils/Interfaces/interfaces';
import { NormalMode } from './ActualStudiesNormal';
import { AddMode } from './ActualStudiesAdd';
import { IActualStudies } from './utils';

const initialValues: IActualStudies = {
    id: 0,
    university: '',
    major: '',
    trainingForm: '',
    financeForm: '',
    semesterStatus: '',
    neptun: '',
    fileName: '',
    filePath: '',
};

export default function ActualStudies() {
    const { user } = useSelector((state: RootState) => state.auth);
    const { profile } = useSelector((state: RootState) => state.studentProfile);
    const [universities, setUniversities] = useState<Option[]>([]);
    const [legalRelationships, setLegalRelationships] = useState<Option[]>([]);
    const [trainingForms, setTrainingForms] = useState<Option[]>([]);

    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        axios
            .all([
                studentsService.universities(),
                studentsService.legalRelationships(),
                studentsService.trainingForms(),
            ])
            .then((res) => {
                setUniversities(res[0].data);
                setLegalRelationships(res[1].data);
                setTrainingForms(res[2].data);
                setLoaded(true);
            });
    }, []);

    if (!loaded) {
        return <>Betöltés...</>;
    }

    return (
        <Grid item container>
            {profile.actualUniversityStudies.map((aus: IActualUniversityStudies) => (
                <Grid item key={aus.id}>
                    <NormalMode
                        aus={aus}
                        universities={universities}
                        legalRelationships={legalRelationships}
                        trainingForms={trainingForms}
                        initialValues={initialValues}
                    />
                    <br />
                </Grid>
            ))}
            {!user.archive && profile.actualUniversityStudies.length < 3 && (
                <Grid container justifyContent={'center'}>
                    <AddMode
                        universities={universities}
                        legalRelationships={legalRelationships}
                        trainingForms={trainingForms}
                        initialValues={initialValues}
                    />
                </Grid>
            )}
        </Grid>
    );
}
