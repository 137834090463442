import Grid from '@mui/material/Grid';
import { TableEditButton } from '@silinfo/front-end-template';
import { DownloadButton } from '@silinfo/front-end-template/lib/esm/components/Button';
import EndpointProvider from '../../../../components/EndpointProvider';
import { endpoints } from '../../../../utils/endPoints';
import AddEditDocumentButton from './AddEditDocumentButton';
import DeleteDocument from './DeleteDocument';
import { IDocument } from './types';
import { Dispatch, SetStateAction } from 'react';

export const OperationCellRenderer = ({
    row,
    setTableLoading,
    readOnly,
    courseTenant,
}: {
    row: IDocument;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    readOnly?: boolean;
    courseTenant: string | null;
}) => {
    return (
        <Grid container spacing={1} flexDirection={'row'}>
            {!closed && !readOnly && (
                <EndpointProvider endpoints={['course_manamagement_course_add_document']}>
                    <Grid item>
                        <AddEditDocumentButton
                            title="Dokumentum szerkesztése"
                            setTableLoading={setTableLoading}
                            submitBtnTitle="Mentés"
                            opener={<TableEditButton tooltip="Dokumentum szerkesztése" />}
                            objectId={row.id}
                            wasSyllabus={row.type == 'syllabus'}
                            courseTenant={courseTenant}
                        />
                    </Grid>
                    <Grid item>
                        <DeleteDocument
                            documentToDelete={row}
                            setLoading={setTableLoading}
                            courseTenant={courseTenant}
                        />
                    </Grid>
                </EndpointProvider>
            )}
            <Grid item>
                <DownloadButton
                    downloadName={row.fileName}
                    url={`${endpoints.fileManagement.download}?id=${row.id.toString()}&type=course_document`}
                />
            </Grid>
        </Grid>
    );
};
