import QuizIcon from '@mui/icons-material/Quiz';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { QuestionnaireProgram, TYPE_QUESTIONNAIRE, TYPE_SUMMARY } from '../utils';
import UpdateTemplateDialogForm from './UpdateTemplateDialogForm';

type UpdateTemplateDialogProps = {
    row: QuestionnaireProgram;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    searchType: string;
    type: string;
};

export default function UpdateTemplateDialog(props: UpdateTemplateDialogProps) {
    const { setRefresh, row, searchType, type } = props;
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    let textToShow = '';
    if (type === TYPE_QUESTIONNAIRE) {
        textToShow = 'Önértékelés és célkitűzés sablon kiválasztása';
    } else if (type === TYPE_SUMMARY) {
        textToShow = 'Összefoglaló dokumentum kiválasztása';
    }

    return (
        <>
            <TableButton
                onClick={handleOpen}
                tooltip={textToShow}
                style={{ marginRight: '5px' }}
                color="warning"
                size="small"
                disabled={type === TYPE_QUESTIONNAIRE ? row?.active : row?.activeSummary}
            >
                {type === TYPE_QUESTIONNAIRE ? <QuizIcon /> : <SummarizeIcon />}
            </TableButton>
            <Dialog open={open} onClose={handleClose}>
                <Grid item container>
                    <Grid item xs={12}>
                        <DialogTitle>{textToShow}</DialogTitle>
                    </Grid>
                    <Grid item container xs={12}>
                        <DialogContent>
                            <UpdateTemplateDialogForm
                                handleClose={handleClose}
                                setRefresh={setRefresh}
                                row={row}
                                searchType={searchType}
                                type={type}
                            />
                        </DialogContent>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}
