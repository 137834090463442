import { AutoloadTable } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import selfEvaluationService from '../../../../../services/selfEvalution/selfEvaluation';
import { RefreshContextProvider } from './../../../../../studentPages/SelfAssessment/RefreshContextProvider';
import { columns } from './utils';

export default function SelfAssessments() {
    const { id } = useParams() as { id: string };
    const [refresh, setRefresh] = useState(false);

    const filter = useCallback(async () => {
        const { data, ...rest } = await selfEvaluationService.list(id);
        return {
            ...rest,
            data: {
                data,
            },
        };
    }, [id]);

    const toggleRefresh = useCallback(() => setRefresh((prev) => !prev), []);

    return (
        <RefreshContextProvider refresh={toggleRefresh}>
            <AutoloadTable
                filter={filter}
                columns={columns}
                responseKey="data"
                hideFooter
                disableSelectionOnClick
                disableColumnMenu
                noAccordionHeader={true}
                refresh={refresh}
            />
        </RefreshContextProvider>
    );
}
