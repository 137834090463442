import { FormikListPage, Link, TableButton } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { create } from '../../../../../store/notification';
import Home from '@mui/icons-material/Home';
import { useDispatch } from 'react-redux';
import { AddressCellRenderer } from '../../../../../utils/AppConstElements';
import { paginatorInfoBuild } from '../../../../../utils/AppConst';
import buildingsService from '../../../../../services/masterData/buildings';
import { useParams } from 'react-router-dom';
import campusesService from '../../../../../services/masterData/campuses';
import MCCLoading from '../../../../../components/MCCLoading';
import { Building, Campus, IForm } from '../../../../../utils/Interfaces/interfaces';
import { initialCampus } from '../../../../../utils/Interfaces/initialObjects';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import EndpointProvider from '../../../../../components/EndpointProvider';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IInfo } from '../../../../../utils/Interfaces/IInfo';
import { useTenant } from '../../../../../components/TenantContext';
import Visibility from '@mui/icons-material/Visibility';

const initiaInfo: IInfo<IForm> = {
    filter: {},
    sort: {
        name: 'asc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

export default function Buildings() {
    const [data, setData] = useState<Building[]>([]);
    const [campus, setCampus] = useState<Campus>(initialCampus);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { campusId } = useParams() as { campusId: string };
    const [info, setInfo] = useState(initiaInfo);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const tenant = useTenant();

    const fetchData = useCallback(
        (form: IInfo<IForm>) => {
            setTableLoading(true);
            if (campusId)
                axios
                    .all([
                        buildingsService
                            .filter({ ...form.filter, ...paginatorInfoBuild(form) }, campusId)
                            .then((d: AxiosResponse) => {
                                const totalItems = d.data['hydra:totalItems'];
                                setData(d.data['hydra:member']);
                                setInfo((prev) => ({
                                    ...prev,
                                    metadata: {
                                        'allCount': totalItems,
                                        'filteredCount': totalItems,
                                        'lastPage': totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                                        'page': info.page,
                                        'perpage': info.perpage,
                                        'rowCount': totalItems,
                                    },
                                }));
                            }),
                        campusesService.get(campusId).then((response) => setCampus(response.data)),
                    ])
                    .catch(() =>
                        dispatch(
                            create({
                                type: 'error',
                                message: 'Hiba a betöltés során!',
                            }),
                        ),
                    )
                    .finally(() => {
                        setTableLoading(false);
                        setLoading(false);
                    });
        },
        [campusId, dispatch, info.page, info.perpage],
    );

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            fetchData(initiaInfo);
        }
    }, [fetchData, firstLoad]);

    function transformForm(form: IForm) {
        const ret = form;
        const fields = ['postCode', 'city', 'streetType', 'streetName', 'houseNumber'];

        fields.forEach((field) => {
            if (field in form) {
                if (form[field] !== '') {
                    ret[`address.${field}`] = form[field];
                }
                delete form[field];
            }
        });
        return ret;
    }

    // const loadTable = () => {
    //     setTableLoading(true);
    //     buildingsService
    //         .filter({}, campusId)
    //         .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
    //         .finally(() => setTableLoading(false));
    // };

    const header = {
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_general_campuses_list]: 'Campusok',
            [clientEndPoints.md_general_campuses_buildings_list.replace(':campusId', campusId.toString())]: 'Épületek',
        },
        title: 'Épületek',
        project: tenant || 'TAR',
    };

    const onSubmit = (form: IForm) => {
        setTableLoading(true);
        const newForm = transformForm(form);
        setInfo((prevState) => ({
            ...prevState,
            filter: newForm,
        }));
        const filter = { ...newForm, ...paginatorInfoBuild(info) };

        return buildingsService
            .filter(filter, campusId)
            .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
            .finally(() => {
                setTableLoading(false);
                setLoading(false);
            });
    };

    function BtnCellRenderer(params: GridRenderCellParams) {
        return (
            /*            <EndpointProvider
                endpoints={['md_general_campuses_buildings_edit', 'md_general_campuses_buildings_floors_list']}
            >*/
            <>
                <EndpointProvider endpoints={['md_general_campuses_buildings_show']}>
                    <Link
                        to={clientEndPoints.md_general_campuses_buildings_show
                            .replace(':campusId', campusId?.toString() ?? '')
                            .replace(':buildingId', params.row.id.toString())}
                    >
                        <TableButton tooltip={'Megtekintés'} sx={{ marginRight: '5px' }}>
                            <Visibility />
                        </TableButton>
                    </Link>
                </EndpointProvider>
                <EndpointProvider endpoints={['md_general_campuses_buildings_floors_list']}>
                    <Link
                        to={clientEndPoints.md_general_campuses_buildings_floors_list
                            .replace(':campusId', campusId?.toString() ?? '')
                            .replace(':buildingId', params.row.id.toString())}
                    >
                        <TableButton tooltip={'Szintek'} sx={{ marginRight: '5px' }}>
                            <Home />
                        </TableButton>
                    </Link>
                </EndpointProvider>
                {/*
     </EndpointProvider>
*/}
            </>
        );
    }

    if (loading) {
        return <MCCLoading />;
    }
    return (
        <FormikListPage
            header={header}
            filter={{
                initialValues: {
                    campusName: campus.name,
                },
                onSubmit,
                fields: [
                    {
                        name: 'campusName',
                        label: 'Campus',
                        props: {
                            disabled: true,
                        },
                        format: { xs: 12, md: 6, lg: 6 },
                    },
                    {
                        name: 'name',
                        label: 'Épület',
                        format: { xs: 12, md: 6, lg: 6 },
                    },
                    {
                        name: 'postCode',
                        label: 'Irányítószám',
                        format: { xs: 12, md: 3 },
                    },
                    {
                        name: 'city',
                        label: 'Település',
                        format: { xs: 12, md: 9 },
                    },
                    {
                        name: 'streetName',
                        label: 'Közterület neve',
                        format: { xs: 12, md: 4 },
                    },
                    {
                        name: 'streetType',
                        label: 'Közterület jellege',
                        format: { xs: 12, md: 4 },
                    },
                    {
                        name: 'houseNumber',
                        label: 'Házszám',
                        format: { xs: 12, md: 4 },
                    },
                ],
            }}
            table={{
                columnDefs: [
                    { headerName: 'Épület', field: 'name' },
                    {
                        headerName: 'Cím',
                        field: 'address',
                        cellRenderer: 'addressCellRenderer',
                        sortable: false,
                        minWidth: 300,
                    },
                    {
                        headerName: 'Szintek száma',
                        field: 'floorCount',
                        sortable: false,
                        minWidth: 120,
                        align: 'center',
                    },
                    { headerName: 'Műveletek', field: 'operations', cellRenderer: 'btnCellRenderer', sortable: false },
                ].map((col) => ({ flex: 1, minWidth: 150, headerAlign: 'center', ...col })),
                rowData: data,
                loading: tableLoading,
                searchfunc: fetchData,
                info: info,
                server: true,
                initialState: {
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                },
                setInfo: setValueByKey,
                frameworkComponents: {
                    btnCellRenderer: BtnCellRenderer,
                    addressCellRenderer: AddressCellRenderer,
                },
            }}
        />
    );
}
