import * as Yup from 'yup';

Yup.setLocale({
    mixed: {
        required: 'Kötelező megadni',
    },
    string: {
        email: 'Hibás e-mail formátum',
        min: (min) => `Legalább ${min.min} karakternek kell lennie`,
        max: (max) => `Legfeljebb ${max.max} karakter lehet`,
    },
    number: {
        min: (min) => `Nem lehet kevesebb, mint ${min.min}`,
        max: (max) => `Nem lehet több, mint ${max.max}`,
    },
});

export default Yup;
