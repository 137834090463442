import { IResponse } from './types';

const columns = [
    { id: 'name', label: 'Hallgató neve' },
    { id: 'campus', label: 'Campus' },
    { id: 'trainingLevel', label: 'Képzési szint / képzési program' },
    { id: 'scholarshipAmount', label: 'Ösztöndíj összege' },
    { id: 'relevant', label: 'Releváns' },
];

const isResponse = (data: IResponse | []): data is IResponse => (data as IResponse).data !== undefined;

const isRelevant = (relevant: boolean): string => (relevant ? 'Igen' : 'Nem');

export { columns, isResponse, isRelevant };
