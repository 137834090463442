import instance from '../../api';
import { endpoints } from '../../utils/endPoints';
import { IForm } from '../../utils/Interfaces/interfaces';
import { crudTemplate } from '../templates';

export interface IHoliday {
    '@id'?: string;
    id?: number | string;
    name: string;
    startDate: string;
    endDate: string;
}

export interface ISemestersForm extends IForm {
    year: string;
    period: string;
    name: string;
    processName: string;
    startDate: string;
    endDate: string;
    enrollmentStartDate?: string;
    enrollmentEndDate?: string;
    terminationStartDate?: string;
    terminationEndDate?: string;
    courseEnrollmentStartDate?: string;
    courseEnrollmentEndDate?: string;
    courseTerminationStartDate?: string;
    courseTerminationEndDate?: string;
    gradingPeriodStartDate?: string;
    gradingPeriodEndDate?: string;
    roomAllocationStartDate?: string;
    roomAllocationEndDate?: string;
    holidays?: IHoliday[];
    closed?: string;
    endDateLater?: boolean;
}

const semestersService = {
    ...crudTemplate<ISemestersForm>(endpoints.masterData.semesters.main),
    deleteHoliday: (id: string | number) => instance.delete(endpoints.masterData.semesters.deleteHoliday + '/' + id),
};

export default semestersService;
