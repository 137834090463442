import { AutoloadTable } from '@silinfo/front-end-template';
import Grid from '@mui/material/Grid';
import { columns, tableStyling } from '../../../../../Scholarship/Report/utils';
import { useParams } from 'react-router-dom';
import { service } from './utils';
import scholarshipService from '../../../../../../services/studentServices/scholarship';
import ExportButton from '../../../../../../components/Buttons/ExportButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import api from '../../../../../../api';
import { endpoints } from '../../../../../../utils/endPoints';
import { useEffect, useState } from 'react';

export default function Scholarships() {
    const { id } = useParams() as { id: string };
    const { user } = useSelector((state: RootState) => state.auth);
    const [currency, setCurrency] = useState();
    const exportContext = {
        initiateExport: scholarshipService(id).initiateExport,
        checkExport: scholarshipService(id).checkExport,
        downloadExport: scholarshipService(id).downloadExport,
        filter: {},
    };

    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setCurrency(response.data.currencyTrans);
            })
            .catch((error) => {
                console.error('Error fetching current tenant currency:', error);
            });
    }, []);

    return (
        <Grid item container xs={12} spacing={2}>
            {!user.archive && (
                <Grid item container justifyContent="flex-end" xs={12}>
                    <Grid item>
                        <ExportButton exportContext={exportContext} />
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12}>
                <AutoloadTable
                    filter={service(id).filter}
                    columns={columns(undefined, undefined, currency)}
                    responseKey=""
                    {...tableStyling}
                    hideFooter
                />
            </Grid>
        </Grid>
    );
}
