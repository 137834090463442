import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import Pending from '@mui/icons-material/Pending';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

const IconMapper: Record<string, OverridableComponent<SvgIconTypeMap>> = {
    success: Done,
    failed: Close,
    in_progress: Pending,
};

const colorMapper: Record<string, 'success' | 'error' | 'info'> = {
    success: 'success',
    failed: 'error',
    in_progress: 'info',
};

const labelMapper: Record<string, string> = {
    success: 'Teljesítve',
    failed: 'Nem teljesítve',
    in_progress: 'Folyamatban',
};

export default function SemesterCell(params: GridRenderCellParams) {
    const value = params.row.semesters[params.field];
    const Icon = IconMapper[value];
    const color = colorMapper[value];
    const label = labelMapper[value];

    if (!(color && label)) {
        return null;
    }

    return (
        <Tooltip title={label} placement="top">
            <Icon color={color} />
        </Tooltip>
    );
}
