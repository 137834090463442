import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import { Loading, TableButton } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import Form from '../../../components/Form/Form';
import { useStyles } from '../../../pages/Administration/Approvals/RequestFormDialog/ApprovalActualStatusDialog';
import { initialLangExamReportForm } from '../../../pages/Administration/LangExamReport/LangExportDialogForm';
import studentRequestService from '../../../services/studentServices/studentRequestService';
import { IInfoDialogProps } from './CourseInfoDialog';
import LanguageExamInfoDialogForm from './LanguageExamInfoDialogForm';

const LanguageExamInfoDialog = (props: IInfoDialogProps) => {
    const { objectId } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const [initialValues, setInitialValues] = useState(initialLangExamReportForm);
    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        studentRequestService
            .getLanguageExam(objectId)
            .then((res) => {
                setInitialValues(res.data.data);
            })
            .finally(() => setInitialLoading(false));
    }, [objectId]);

    if (initialLoading) return <Loading noHeight />;

    return (
        <>
            <TableButton onClick={handleOpen} tooltip={`Változtatások`} color={'info'} className={classes.eyeIcon}>
                <InfoIcon />
            </TableButton>
            <Dialog open={open} onClose={handleClose}>
                <Form fields={[]} onSubmit={() => console.log('/TODO')} initialValues={initialValues} hideButtons>
                    {(formikProps) => (
                        <LanguageExamInfoDialogForm objectId={objectId} handleClose={handleClose} {...formikProps} />
                    )}
                </Form>
            </Dialog>
        </>
    );
};

export default LanguageExamInfoDialog;
