import { Input } from '@silinfo/front-end-template';
import { Option } from '../../../../utils/AppConst';
import { names, translations } from './utils';

const filterFields = (options: Record<string, Option[]>): Input[] =>
    names.map((name) => ({
        name,
        label: translations[name],
        type: 'multiselect',
        options: options[name],
    }));

const formFields = (options: Record<string, Option[]>): Input[] => [
    {
        name: 'campus',
        label: translations.campus,
        type: 'multiselect',
        options: options.campus,
        props: {
            helperText: 'Kérem válasszon egyet!',
        },
    },
];
export { filterFields, formFields };
