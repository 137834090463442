import Grid from '@mui/material/Grid';
import { FormikListPage, Link, NewButton } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import EndpointProvider from '../../../../components/EndpointProvider';
import trainingLevelsService from '../../../../services/masterData/trainingLevels';
import { ArchiveCellRenderer } from './ArchiveCellRenderer';
import { BtnCellRenderer } from './BtnCellRenderer';
import { fields, header } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import ExportButton, { ExportContext } from '../../../../components/Buttons/ExportButton';
import { AxiosResponse } from 'axios';
import { initialInfo } from '../../../../utils/AppConst';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { useTenant } from '../../../../components/TenantContext';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';

export default function TrainingLevels() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [refresh, setRefresh] = useState(false);
    const [exportInfo, setExportInfo] = useState<IForm>(initialInfo({}));
    const tenant = useTenant();

    const exportContextRequirements: ExportContext = {
        initiateExport: trainingLevelsService.requirementsInitiateExport as () => Promise<AxiosResponse>,
        checkExport: trainingLevelsService.requirementsCheckExport,
        downloadExport: trainingLevelsService.requirementsDownloadExport,
        info: initialInfo(),
        filter: exportInfo,
    };

    const handleFilter = useCallback((filter: IForm) => {
        setExportInfo({ ...filter });
        return trainingLevelsService.filter(filter);
    }, []);

    return (
        <FormikListPage
            refresh={refresh}
            header={header(tenant || 'TAR') as HeaderProps}
            autoload
            service={{ filter: handleFilter }}
            columns={[
                { headerName: 'Képzési szint', field: 'name', flex: 1, minWidth: 100 },
                {
                    headerName: 'Archiválva?',
                    field: 'archive',
                    renderCell: (params) => ArchiveCellRenderer(params),
                    sortable: false,
                    flex: 1,
                    minWidth: 100,
                },
                {
                    headerName: 'Műveletek',
                    field: 'operations',
                    renderCell: (params) => (
                        <BtnCellRenderer setRefresh={setRefresh} readonly={!!user.archive} {...params} />
                    ),
                    sortable: false,
                    flex: 1,
                    minWidth: 200,
                },
            ]}
            filter={{ fields, initialValues: { archive: ['0'] } }}
            defaultSort={{ courseName: 'asc' }}
            customButtons={
                <>
                    {!user.archive ? (
                        <EndpointProvider endpoints={['md_training_levels_create']}>
                            <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <Link to="new">
                                        <NewButton>Képzési szint hozzáadása</NewButton>
                                    </Link>
                                </Grid>
                            </Grid>
                        </EndpointProvider>
                    ) : (
                        <></>
                    )}
                    {!user.archive && (
                        <Grid item>
                            <ExportButton
                                exportContext={exportContextRequirements}
                                title={'Követelmények exportálása (aktuális szemeszter)'}
                            />
                        </Grid>
                    )}
                </>
            }
        />
    );
}
