import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useMemo } from 'react';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';
import { IScoringType } from '../ProgramLevel/Semesters';
import PerformanceIndicator from './PerformanceIndicator';
import { performanceData } from './utils';

export interface IPerformance {
    completed: IScoringType;
    enrolledMin: IScoringType;
    enrolledMax: IScoringType;
    minToBeCompleted: number;
}

interface PerformanceProps {
    semester: string;
}

export default function Performance({ semester }: PerformanceProps) {
    const { data, loading } = useFetch<IPerformance>(
        endpoints.students.requirements.performance(semester),
        '',
        semester,
    );

    const performances = useMemo(() => (loading ? [] : performanceData(data)), [data, loading]);

    return (
        <>
            {loading ? (
                <Grid item xs={12} md={6}>
                    <Skeleton style={{ height: '100%' }} />
                </Grid>
            ) : (
                <>
                    {performances.map((p) => (
                        <PerformanceIndicator key={p.label} {...p} />
                    ))}
                </>
            )}
        </>
    );
}
