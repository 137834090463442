import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Dispatch, SetStateAction } from 'react';
import { IUserNotification } from '../../utils/Interfaces/IUserNotification';

interface ActionsProps {
    index: number;
    setIndex: Dispatch<SetStateAction<number>>;
    notifications: IUserNotification[];
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function Actions(props: ActionsProps) {
    const { index, setIndex, notifications, setOpen } = props;
    return (
        <DialogActions>
            <Grid container alignItems="center">
                <Grid item xs={4}>
                    {index > 0 && (
                        <Tooltip title="Előző">
                            <IconButton onClick={() => setIndex(index - 1)}>
                                <ChevronLeft style={{ fontSize: '2rem' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
                <Grid item xs={4} textAlign="center">
                    {index + 1} / {notifications.length}
                </Grid>
                <Grid item xs={4} textAlign="end">
                    {index < notifications.length - 1 ? (
                        <Tooltip title="Következő">
                            <IconButton color="secondary" onClick={() => setIndex(index + 1)}>
                                <ChevronRight style={{ fontSize: '2rem' }} />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Button variant="contained" onClick={() => setOpen(false)}>
                            Bezár
                        </Button>
                    )}
                </Grid>
            </Grid>
        </DialogActions>
    );
}
