import Grid from '@mui/material/Grid';
import { GridRowModel } from '@mui/x-data-grid';
import MCCLoading from '../../../components/MCCLoading';
import { FilterProps } from '../types';
import Filter from './Filter';
import InfoCard from './InfoCard';
import { InfoCardProps } from './types';

interface MobilViewProps extends FilterProps {
    data: GridRowModel[];
    loading: boolean;
    done: boolean;
}

export default function MobilView(props: MobilViewProps) {
    const { data, info, setInfo, loading } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} textAlign="end">
                <Filter info={info} setInfo={setInfo} />
            </Grid>
            <Grid item xs={12} container spacing={1}>
                {data.length || loading ? (
                    data.map((row) => <InfoCard key={row.id} {...(row as InfoCardProps)} />)
                ) : (
                    <Grid item xs={12}>
                        <i>Nincs megjeleníthető kurzus</i>
                    </Grid>
                )}
            </Grid>
            {loading ? <MCCLoading /> : null}
        </Grid>
    );
}
