import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { Children } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { clientEndPoints } from '../../utils/clientEndPoints';
import { Submenu } from '../../utils/Interfaces/interfaces';
import { theme } from '../../utils/theme';
import { useTenant } from '../../components/TenantContext';

export default function Group({ name, submenus }: { name: string; submenus: { [key: string]: Submenu } }) {
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const { user } = useSelector((state: RootState) => state.auth);
    const tenant = useTenant();

    const header: HeaderProps = {
        project: tenant || 'TAR',
        title: 'Törzsadatok - ' + name,
        breadcrumbs: {
            masterData: 'Törzsadatok',
            name,
        },
    };

    const filterSubmenu = (submenu: Submenu) => {
        const menuKey = Object.keys(clientEndPoints).find(
            (key) => clientEndPoints[key as keyof typeof clientEndPoints] === submenu.to,
        ) as keyof typeof accessTree;
        if (!accessTree[menuKey] || user.admin) return true;
        return accessTree[menuKey].some((e: string) => user.roles.includes(e));
    };

    return (
        <Page header={header}>
            <Grid item>
                <List>
                    {Children.toArray(
                        Object.values(submenus)
                            .filter(filterSubmenu)
                            .map((menu) => (
                                <ListItem disablePadding key={menu.to}>
                                    <Link to={menu.to}>
                                        <ListItemButton>
                                            <ListItemIcon style={{ color: theme.palette.secondary.main }}>
                                                {menu.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={menu.text}
                                                style={{ color: theme.palette.secondary.main }}
                                            />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            )),
                    )}
                </List>
            </Grid>
        </Page>
    );
}
