import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';

const eventLogsService = {
    ...crudTemplate<never>(endpoints.administration.eventLogs.main),
    getTypes: () => API.get(endpoints.administration.eventLogs.types),
    getActions: () => API.get(endpoints.administration.eventLogs.actions),
};

export default eventLogsService;
