import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

export interface ISettingsForm extends IForm {
    settingVal: string;
    settingKey: string;
    from: string;
    to: string;
}

const settingsService = {
    ...crudTemplate<ISettingsForm>(endpoints.administration.settings.main),
    changeSettingValue: (id: string | number, form: IForm) =>
        API.patch(endpoints.administration.settings.changeSettingValue.replace('{settingId}', id.toString()), form),
};

export default settingsService;
