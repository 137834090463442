import Grid from '@mui/material/Grid';

interface IconMenuItemProps {
    icon: JSX.Element;
    label: string;
}

export default function IconMenuItem({ icon, label }: IconMenuItemProps) {
    return (
        <Grid container>
            <Grid item xs={2} sm={1}>
                {icon}
            </Grid>
            <Grid item xs={10} sm={11}>
                {label}
            </Grid>
        </Grid>
    );
}
