import { AutoloadTable, Page } from '@silinfo/front-end-template';
import { columns, filter, header } from './utils';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../utils/theme';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import OperationsMobile, { Document } from './OperationsMobile';
import { useTenant } from '../../components/TenantContext';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    headingMain: {
        color: theme.palette.info.main,
        fontSize: '18px',
    },
    headingPaper: {
        backgroundColor: '#D9D9D9',
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(2.5),
        fontSize: '18px',
    },
    q: {
        padding: theme.spacing(1.5),
    },
    qContainer: {
        display: 'flex',
        border: '1px solid #647C9B',
        margin: theme.spacing(1),
        padding: theme.spacing(1.5),
        boxSizing: 'border-box',
    },
    qBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    courseName: {
        fontSize: '12px',
    },
    courseCode: {
        fontSize: '11px',
    },
    deadline: {
        marginTop: theme.spacing(0.5),
        fontSize: '11px',
    },
    operations: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
}));

export default function Documents() {
    const [documents, setDocuments] = useState<Document[]>([]);

    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    const tenant = useTenant();

    useEffect(() => {
        const fetchData = async () => {
            const response = await filter();
            setDocuments(response.data.data);
        };
        fetchData();
    }, []);

    return matches ? (
        <Page header={header(tenant || 'TAR')}>
            <Grid item xs={12}>
                <Paper>
                    <AutoloadTable columns={columns} filter={filter} noAccordionHeader responseKey="data" hideFooter />
                </Paper>
            </Grid>
        </Page>
    ) : (
        <Grid item xs={12}>
            <Paper className={classes.paper} elevation={3}>
                <Typography className={classes.headingPaper}>Dokumentumok</Typography>
                {documents.map((item) => (
                    <div key={item.id} className={classes.qContainer}>
                        <div className={classes.qBox}>
                            <Typography className={classes.courseName}>{item.name}</Typography>
                            <Typography className={classes.deadline}>
                                {`Utolsó frissítés dátuma: ${item.updatedAt ?? 'nincs'}`}
                            </Typography>
                        </div>
                        <div className={classes.operations}>
                            <OperationsMobile row={item} />
                        </div>
                    </div>
                ))}
            </Paper>
        </Grid>
    );
}
