import { SectionRow } from '../SectionGenerator';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { create } from '../../../../../../store/notification';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import actualUniversityStudiesService from '../../../../../../services/studentServices/actualUniversityStudiesService';

export const DeleteButton = ({
    row,
    setRefresh,
}: {
    row: SectionRow;
    setRefresh: Dispatch<SetStateAction<boolean>>;
}) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleClose = useCallback(() => setOpen(false), []);
    const handleOpen = useCallback(() => setOpen(true), []);

    const handleDelete = useCallback(() => {
        actualUniversityStudiesService
            .deleteActualUniversityStudies(row)
            .then(() => {
                setRefresh((prev) => !prev);
                dispatch(create({ type: 'success', message: 'Sikeres törlés!' }));
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Hiba a törlés során' })))
            .finally(() => setOpen(false));
    }, [dispatch, setRefresh, row]);

    return (
        <>
            <Grid item>
                <IconButton color="error" onClick={handleOpen}>
                    <Delete />
                </IconButton>
            </Grid>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle mb={3}>Törlés</DialogTitle>
                <DialogContent>
                    <DialogContentText>Biztosan törli az elemet?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Mégsem
                    </Button>
                    <LoadingButton onClick={handleDelete} variant="contained">
                        Törlés
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
