import {
    DataGridProProps,
    GridValueGetterParams,
    GridColDef,
    GridRenderCellParams,
    GridRowClassNameParams,
} from '@mui/x-data-grid-pro';
import './style.css';
import { AxiosResponse } from 'axios';
import instance from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { IStudentCompletionData } from '../../Administration/CourseCompletions/types';
import { Input } from '@silinfo/front-end-template';
import MccLogo from '../Admissions/Icons/mcc-icon.png';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import QuizIcon from '@mui/icons-material/Quiz';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import RiportsStyle from './Riports.module.scss';
import { CampusOption } from '../../Administration/Certificates/ConfirmationSemester/types';
import { Option } from '../../../utils/AppConst';
import { theme } from '../../../utils/theme';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import EndpointProvider from '../../../components/EndpointProvider';
import { Applicant, IReportExport, TrainingProgramOption } from './types';
import ExportButton, { ExportContext } from '../../../components/Buttons/ExportButton';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import { Dispatch, SetStateAction } from 'react';
import TableExportButton from '../../../components/Buttons/TableExportButton';
import admissionApplicantService from '../../../services/admission/admissionApplicant';

export const header = (tenant: string | null) => {
    return {
        title: 'Jelentkezések elbírálása',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

export const breadcrumbs = {
    admission: 'Felvételi',
    riport: 'Jelentkezések elbírálása',
};

const autoload = true as const;

const MCC_OPTIONS: Option[] = [
    { value: '1', label: 'Igen' },
    { value: '0', label: 'Nem' },
];

export const basicDataTypes = ['email', 'firstName', 'lastName', 'gender', 'phone'];

export const sortOrder = [
    'birthDate',
    'birthPlace',
    'nationality',
    'motherLastName',
    'motherFirstName',
    'omId',
    'taxId',
    'socialSecurityNumber',
    'idCardNumber',
    'kepIdCardNumber',
    'identificationNumberCnp',
    'bankName',
    'bankAccountNumber',
    'postCode',
    'city',
    'streetName',
    'streetType',
    'houseNumber',
    'addressFloor',
    'door',
];

const statusesForFinalization = ['Felvett-igazolt', 'Nem felvett'];

const exportApplicantContext = (request: IReportExport, info: IInfo<AdmissionRiportFilter, IForm>): ExportContext => ({
    initiateExport: admissionApplicantService.initiateExport(request),
    checkExport: admissionApplicantService.checkExport(),
    downloadExport: admissionApplicantService.downloadExport(),
    info,
    filter: info.filter,
});

export const columns = (
    info: IInfo<AdmissionRiportFilter, IForm>,
    handleOpenModal?: (applicantId: number) => void,
    handleOpenStatusModal?: (applicantId: number, programId: number, isDisabled: boolean) => void,
    handleOpenDocumentModal?: (applicantId: number, programId: number) => void,
    handleOpenScoringModal?: (applicantId: number, programId: number, isDisabled: boolean) => void,
    handleOpenFinalizeModal?: (applicantId: number, programId: number, applicantNameLabel: string) => void,
    loadingButtonsDisabled?: boolean,
    setLoadingButtonsDisabled?: Dispatch<SetStateAction<boolean>>,
    archive?: boolean,
): GridColDef<IStudentCompletionData>[] =>
    [
        {
            field: 'email',
            headerName: 'E-mail cím',
            minWidth: 200,
        },
        {
            field: 'mccep',
            headerName: 'MCC-s hallgató',
            minWidth: 250,
            renderCell: (params: GridRenderCellParams) => {
                if (typeof params?.row?.mccep === 'boolean') {
                    return params?.row?.mccep ? <img src={MccLogo} width="25" height="25" /> : 'Nem';
                } else {
                    return (
                        <Tooltip placement="bottom" title={params?.row?.mccep} sx={{ fontSize: '0.875rem' }}>
                            <Typography
                                variant="inherit"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}
                            >
                                {params?.row?.mccep}
                            </Typography>
                        </Tooltip>
                    );
                }
            },
        },
        {
            field: 'status',
            headerName: 'Státusz',
            headerAlign: 'center' as const,
            align: 'center' as const,
            minWidth: 100,
        },
        {
            field: 'points',
            headerName: 'Pontszámok',
            headerAlign: 'center' as const,
            align: 'center' as const,
            minWidth: 100,
        },
        {
            field: 'group',
            headerName: 'Csoport',
            headerAlign: 'center' as const,
            align: 'center' as const,
            minWidth: 100,
        },
        ...(!archive
            ? [
                  {
                      field: 'actions',
                      headerName: 'Műveletek',
                      headerAlign: 'center' as const,
                      minWidth: 280,
                      renderCell: (params: GridRenderCellParams) => {
                          const disabled = params.row.finalizedAt !== null;

                          const handlePreview = () => {
                              window.open(
                                  clientEndPoints.admission_riport_templates_preview.replace(
                                      ':id',
                                      `${params.row.programId}`,
                                  ),
                                  '_blank',
                              );
                          };

                          return (
                              <Grid item container sx={{ justifyContent: 'center' }} spacing={1}>
                                  {typeof params?.row?.mccep === 'boolean' ? (
                                      <>
                                          <Tooltip title="Általános felvételi adatok megtekintése">
                                              <Grid item>
                                                  <IconButton
                                                      size="small"
                                                      sx={{
                                                          borderWidth: '1px',
                                                          borderStyle: 'solid',
                                                          color: theme.palette.primary.main,
                                                      }}
                                                      disableRipple
                                                  >
                                                      <AssignmentIndIcon
                                                          onClick={() => {
                                                              if (
                                                                  handleOpenModal !== undefined &&
                                                                  params !== undefined
                                                              ) {
                                                                  handleOpenModal(params.row.applicantId);
                                                              }
                                                          }}
                                                          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                                      />
                                                  </IconButton>
                                              </Grid>
                                          </Tooltip>
                                          <Grid item>
                                              <TableExportButton
                                                  title="Jelentkező mappájának exportálása"
                                                  exportContext={exportApplicantContext(
                                                      {
                                                          applicants: [
                                                              {
                                                                  id: params.row.applicantId,
                                                                  admissions: [],
                                                              },
                                                          ],
                                                      },
                                                      info,
                                                  )}
                                                  otherLoading={loadingButtonsDisabled}
                                                  setOtherLoading={setLoadingButtonsDisabled}
                                              />
                                          </Grid>
                                      </>
                                  ) : (
                                      <Grid item container sx={{ justifyContent: 'center' }} spacing={0.5}>
                                          <Grid item>
                                              <TableExportButton
                                                  title="Jelentkezés adatainak letöltése"
                                                  exportContext={exportApplicantContext(
                                                      {
                                                          applicants: [
                                                              {
                                                                  id: params.row.applicantId,
                                                                  admissions: [params.row.programId],
                                                              },
                                                          ],
                                                      },
                                                      info,
                                                  )}
                                                  otherLoading={loadingButtonsDisabled}
                                                  setOtherLoading={setLoadingButtonsDisabled}
                                              />
                                          </Grid>
                                          <Tooltip title="Feltöltött dokumentumok megtekintése">
                                              <Grid item>
                                                  <IconButton
                                                      size="small"
                                                      sx={{
                                                          borderWidth: '1px',
                                                          borderStyle: 'solid',
                                                          color: theme.palette.primary.main,
                                                      }}
                                                      disableRipple
                                                  >
                                                      <UploadFileIcon
                                                          onClick={() => {
                                                              if (
                                                                  handleOpenDocumentModal !== undefined &&
                                                                  params !== undefined
                                                              ) {
                                                                  handleOpenDocumentModal(
                                                                      params.row.applicantId,
                                                                      params.row.programId,
                                                                  );
                                                              }
                                                          }}
                                                          sx={{ cursor: 'pointer' }}
                                                      />
                                                  </IconButton>
                                              </Grid>
                                          </Tooltip>
                                          <Tooltip
                                              title={
                                                  params.row.filled
                                                      ? 'Kitöltött felvételi űrlap megtekintése'
                                                      : 'A jelentkező nem töltötte ki a felvételi űrlapot!'
                                              }
                                          >
                                              <Grid item>
                                                  <IconButton
                                                      size="small"
                                                      sx={{
                                                          borderWidth: '1px',
                                                          borderStyle: 'solid',
                                                          color: '#364354',
                                                      }}
                                                      disableRipple
                                                      disabled={!params.row.filled}
                                                  >
                                                      <QuizIcon onClick={handlePreview} sx={{ cursor: 'pointer' }} />
                                                  </IconButton>
                                              </Grid>
                                          </Tooltip>
                                          <Tooltip title="Jelentkező pontjai">
                                              <Grid item>
                                                  <IconButton
                                                      size="small"
                                                      sx={{
                                                          borderWidth: '1px',
                                                          borderStyle: 'solid',
                                                          color: '#364354',
                                                      }}
                                                      disableRipple
                                                  >
                                                      <AssessmentIcon
                                                          onClick={() => {
                                                              if (
                                                                  handleOpenScoringModal !== undefined &&
                                                                  params !== undefined
                                                              ) {
                                                                  handleOpenScoringModal(
                                                                      params.row.applicantId,
                                                                      params.row.programId,
                                                                      disabled,
                                                                  );
                                                              }
                                                          }}
                                                          sx={{ cursor: 'pointer' }}
                                                      />
                                                  </IconButton>
                                              </Grid>
                                          </Tooltip>
                                          <Tooltip title="Felvételi státusz beállítása">
                                              <Grid item>
                                                  <IconButton
                                                      size="small"
                                                      sx={{
                                                          borderWidth: '1px',
                                                          borderStyle: 'solid',
                                                          color: theme.palette.primary.main,
                                                      }}
                                                      disableRipple
                                                  >
                                                      <DriveFileRenameOutlineIcon
                                                          onClick={() => {
                                                              if (
                                                                  handleOpenStatusModal !== undefined &&
                                                                  params !== undefined
                                                              ) {
                                                                  handleOpenStatusModal(
                                                                      params.row.applicantId,
                                                                      params.row.programId,
                                                                      disabled,
                                                                  );
                                                              }
                                                          }}
                                                          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                                      />
                                                  </IconButton>
                                              </Grid>
                                          </Tooltip>
                                          {statusesForFinalization.includes(params.row.status) && (
                                              <EndpointProvider endpoints={['admission_riport_finalize']}>
                                                  <Tooltip
                                                      title={
                                                          disabled
                                                              ? 'A jelentkezés lezárásra került.'
                                                              : 'Jelentkezés véglegesítése'
                                                      }
                                                  >
                                                      <Grid item>
                                                          <IconButton
                                                              size="small"
                                                              sx={{
                                                                  borderWidth: '1px',
                                                                  borderStyle: 'solid',
                                                                  color: 'green',
                                                              }}
                                                              disableRipple
                                                              disabled={disabled}
                                                          >
                                                              <HowToRegIcon
                                                                  onClick={() => {
                                                                      if (
                                                                          handleOpenFinalizeModal !== undefined &&
                                                                          params !== undefined
                                                                      ) {
                                                                          const nameLabel = `${params.row.applicantFullName} (${params.row.applicantEmail}) ${params.row.trainingProgram}`;
                                                                          handleOpenFinalizeModal(
                                                                              params.row.applicantId,
                                                                              params.row.programId,
                                                                              nameLabel,
                                                                          );
                                                                      }
                                                                  }}
                                                                  sx={{ cursor: 'pointer' }}
                                                              />
                                                          </IconButton>
                                                      </Grid>
                                                  </Tooltip>
                                              </EndpointProvider>
                                          )}
                                      </Grid>
                                  )}
                              </Grid>
                          );
                      },
                  },
              ]
            : []),
    ].map((column) => ({
        flex: 1,
        headerAlign: 'center',
        align: 'left',
        sortable: false,
        ...column,
    }));

const rowsConverter = (
    rows: {
        applicantId: number | undefined;
        applicantFullName: string | null;
        applicantEmail: string | null;
        id: string;
        fullName: string;
        email: string;
        mccep: boolean | string;
        admissions: {
            id: number;
            campus: string;
            serial: string;
            trainingLevel: string;
            trainingProgram: string;
            status: string;
        }[];
    }[],
): Applicant[] => {
    const result: Applicant[] = [];

    rows.forEach((row) => {
        result.push({ ...row });
        row.admissions.forEach((el, index) => {
            result.push({
                ...el,
                serial: el.serial + '.',
                fullName: el.campus,
                id: row.id + '-' + el.id + index,
                email: el.trainingLevel,
                mccep: el.trainingProgram,
                admissions: [],
                applicantId: row.applicantId,
                applicantFullName: row.fullName,
                applicantEmail: row.email,
            });
        });
    });

    return result;
};
export const service = (
    id: string | number,
    setInfo: Dispatch<SetStateAction<IInfo<AdmissionRiportFilter, IForm>>>,
) => ({
    filter: async (form: IForm): Promise<AxiosResponse> => {
        const convertedForm: IForm = {
            ...form,
            ...(form['order[__tree_data_group__]'] ? { ['order[name]']: form['order[__tree_data_group__]'] } : {}),
        };
        const { data, ...rest } = await instance.get(endpoints.admission.getApplicants(id), {
            params: convertedForm,
        });

        setInfo((prevState) => {
            return {
                ...prevState,
                filter: { ...convertedForm, semester: prevState.filter.semester },
                metadata: { ...prevState.metadata, length: data.data.length },
            };
        });
        return {
            ...rest,
            data: {
                ...data,
                data: rowsConverter(
                    data.data.map(
                        (
                            e: {
                                id: string;
                                serial: number;
                                fullName: string;
                                email: string;
                                mccep: boolean;
                                admissions: object[];
                            },
                            index: number,
                        ) => ({
                            ...e,
                            id: index,
                        }),
                    ),
                ),
            },
        };
    },
});

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => {
    return ('' + row.id).split('-');
};

export const filterFields = (): Input[] => {
    return [
        {
            name: 'name',
            label: 'Hallgató',
            type: 'text',
            format: {
                xs: 12,
                md: 4,
            },
        },
        {
            name: 'email',
            label: 'E-mail cím',
            type: 'text',
            format: {
                xs: 12,
                md: 4,
            },
        },
        {
            name: 'campus',
            label: 'Campus',
            type: 'backendSelect',
            url: endpoints.admission.getCampusOptions,
            multiple: true,
            justValue: true,
            props: {
                renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: CampusOption) => (
                    <li {...props} style={{ color: option.active ? 'black' : '#606060' }}>
                        {option.label}
                    </li>
                ),
            },
            format: {
                xs: 12,
                md: 4,
            },
        },
        {
            name: 'trainingLevel',
            label: 'Képzési szint',
            type: 'backendSelect',
            url: endpoints.admission.getTrainingLevelOptions,
            multiple: true,
            justValue: true,
            props: {
                renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                    <li {...props} style={{ color: 'black' }}>
                        {option.label}
                    </li>
                ),
            },
            format: {
                xs: 12,
                md: 4,
            },
        },
        {
            name: 'trainingProgram',
            label: 'Képzési program',
            type: 'backendSelect',
            url: endpoints.admission.getTrainingProgramsOptions,
            multiple: true,
            justValue: true,
            props: {
                groupBy: (option: TrainingProgramOption) => option.trainingLevel,
                renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                    <li {...props} style={{ color: 'black' }}>
                        {option.label}
                    </li>
                ),
            },
            format: {
                xs: 12,
                md: 4,
            },
        },
        {
            name: 'mccep',
            label: 'MCC-s hallgató',
            type: 'multiselect',
            options: MCC_OPTIONS,
            format: {
                xs: 12,
                md: 4,
            },
        },
        {
            name: 'status',
            label: 'Státusz',
            type: 'backendSelect',
            url: endpoints.admission.getStatusOptions,
            justValue: true,
            multiple: true,
            props: {
                renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                    <li {...props} style={{ color: 'black' }}>
                        {option.label}
                    </li>
                ),
            },
            format: {
                xs: 12,
                md: 4,
            },
        },
        {
            name: 'group',
            label: 'Csoport',
            type: 'text',
            format: {
                xs: 12,
                md: 4,
            },
        },
    ];
};

export interface AdmissionRiportFilter {
    student: string;
    email: string;
    campus: string;
    trainingLevel: string;
    trainingProgram: string;
    mcc: boolean;
    status: string;
    group: string;
}

export const initialAdmissionRiportFilterState = (): IInfo<AdmissionRiportFilter> => ({
    filter: {},
    sort: {},
    page: 1,
    perpage: 25,
    metadata: {},
});

export const tableProps = (
    id: number,
    exportContext: ExportContext,
    setInfo: Dispatch<SetStateAction<IInfo<AdmissionRiportFilter, IForm>>>,
    info: IInfo<AdmissionRiportFilter, IForm>,
    handleOpenModal: (applicantId: number) => void,
    handleOpenStatusModal: (applicantId: number, programId: number, isDisabled: boolean) => void,
    handleOpenDocumentModal: (applicantId: number, programId: number) => void,
    handleOpenScoringModal: (applicantId: number, programId: number, isDisabled: boolean) => void,
    handleOpenFinalizeModal: (applicantId: number, programId: number, applicantNameLabel: string) => void,
    loadingButtonsDisabled?: boolean,
    setLoadingButtonsDisabled?: Dispatch<SetStateAction<boolean>>,
    archive?: boolean,
    tenant?: string,
) => ({
    autoload,
    header: header(tenant || 'TAR'),
    service: service(id, setInfo),
    columns: columns(
        info,
        handleOpenModal,
        handleOpenStatusModal,
        handleOpenDocumentModal,
        handleOpenScoringModal,
        handleOpenFinalizeModal,
        loadingButtonsDisabled,
        setLoadingButtonsDisabled,
        archive,
    ),
    filter: {
        fields: filterFields(),
    },
    refresh: id,
    defaultSort: { __tree_data_group__: 'asc' as const },
    responseKey: 'data',
    customButtons: (
        <>
            {!archive && (
                <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        <ExportButton
                            exportContext={exportContext}
                            title="Összesítő export"
                            otherLoading={loadingButtonsDisabled}
                            setOtherLoading={setLoadingButtonsDisabled}
                            isEmpty={info.metadata.length === 0}
                        />
                    </Grid>
                    <Grid item>
                        <ExportButton
                            exportContext={exportApplicantContext(
                                {
                                    applicants: [],
                                },
                                info,
                            )}
                            title="Felvételi mappák letöltése"
                            otherLoading={loadingButtonsDisabled}
                            setOtherLoading={setLoadingButtonsDisabled}
                            isEmpty={info.metadata.length === 0}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    ),
    tableProps: {
        className: 'treeDataStyle',
        treeData: true,
        getTreeDataPath,
        groupingColDef: {
            sortable: true,
            headerName: 'Hallgató',
            valueGetter: (params: GridValueGetterParams) =>
                params.row.serial ? (
                    <div style={{ display: 'flex', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Typography variant="inherit" sx={{ marginRight: '15px' }}>
                            {params.row.serial}
                        </Typography>
                        <Tooltip title={params.row.fullName}>
                            <Typography variant="inherit">{params.row.fullName}</Typography>
                        </Tooltip>
                    </div>
                ) : (
                    <Typography variant="inherit" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.row.fullName}
                    </Typography>
                ),
            hideDescendantCount: true,
            width: 300,
        },
        getRowClassName: (params: GridRowClassNameParams) => {
            if (typeof params.row.mccep === 'boolean') {
                return RiportsStyle.userRow;
            }
            return RiportsStyle.admissionRow;
        },
        columnBuffer: columns(info).length,
        disableSelectionOnClick: true,
        initialState: {
            pinnedColumns: {
                right: ['operations'],
            },
            sorting: {
                sortModel: [{ field: '__tree_data_group__', sort: 'asc' as const }],
            },
        },
    },
});
