import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { TableButton } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import Form from '../../../../components/Form/Form';
import { ActualStatusForm } from '../../../../studentPages/Profile/Tabs/ActualStudies/ActualStatusDialog';
import ApprovalActualStatusDialogForm from './ApprovalActualStatusDialogForm';

export const useStyles = makeStyles(() => ({
    eyeIcon: {
        backgroundColor: 'white',
        color: '#0288d1',
    },
}));

const ApprovalActualStatusDialog = (row: ActualStatusForm) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const initialValues: ActualStatusForm = row;

    return (
        <>
            <TableButton onClick={handleOpen} tooltip={`Változtatások`} color={'info'} className={classes.eyeIcon}>
                <InfoIcon />
            </TableButton>
            <Dialog open={open} onClose={handleClose}>
                <Grid item container>
                    <Grid item xs={12}>
                        <DialogTitle>Hallgatói kérvény</DialogTitle>
                    </Grid>
                    <Grid item container xs={12}>
                        <DialogContent>
                            <Form
                                fields={[]}
                                onSubmit={() => console.log('/TODO')}
                                initialValues={initialValues}
                                hideButtons
                            >
                                {(props) => <ApprovalActualStatusDialogForm {...props} />}
                            </Form>
                        </DialogContent>
                    </Grid>
                    <Grid item xs={12}>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleClose}>
                                Bezár
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export default ApprovalActualStatusDialog;
