import Delete from '@mui/icons-material/Delete';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Dialog, SubmitButton, TableButton } from '@silinfo/front-end-template';
import courseEnrollmentService from '../../../../services/courseManagement/courseEnrollment';
import { create } from '../../../../store/notification';
import { ICourseEnrollmentCentral } from './types';

export default function DeleteCourseEnrollment({
    enrollmentToDelete,
    setLoading,
    courseId,
    courseTenant,
    refreshCourseInfo,
}: {
    enrollmentToDelete: ICourseEnrollmentCentral;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    courseId: string;
    courseTenant: string | null;
    refreshCourseInfo: () => void;
}) {
    const handleDelete = () => {
        setLoading(true);
        courseEnrollmentService
            .delete(courseId, enrollmentToDelete.id, courseTenant)
            .then(() => {
                create({ type: 'success', message: 'Sikeres törlés!' });
                refreshCourseInfo();
            })
            .catch(() => create({ type: 'error', message: 'Sikertelen törlés!' }))
            .finally(() => setLoading(false));
    };

    return (
        <Dialog
            title="Jelentkezés törlése"
            opener={
                <TableButton color="error" tooltip="Jelentkezés törlése">
                    <Delete />
                </TableButton>
            }
            action={<SubmitButton onClick={handleDelete}>Igen</SubmitButton>}
            cancelText="Mégsem"
        >
            <DialogContent>
                <DialogContentText>
                    Biztosan törli {enrollmentToDelete.student}
                    hallgató jelentkezését a kurzusról?
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
