import React, { createContext } from 'react';

type EditContextType = [boolean, React.Dispatch<React.SetStateAction<boolean>>] | undefined;

const defaultContext: EditContextType = undefined;

const EditContext = createContext<EditContextType>(defaultContext);

interface EditContextProviderProps {
    children: React.ReactNode;
    editState: EditContextType;
}

export function EditContextProvider(props: EditContextProviderProps) {
    return <EditContext.Provider value={props.editState}>{props.children}</EditContext.Provider>;
}

export default EditContext;
