import { Dialog, NewButton, SubmitButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { IHoliday, ISemestersForm } from '../../../../../services/masterData/semesters';
import Form, { isValidHoliday } from './Form';

const initialForm = { name: '', startDate: '', endDate: '' };
type Key = 'name' | 'startDate' | 'endDate';

export default function AddHoliday({
    setSemesterForm,
    setFieldValue,
    holidays,
}: {
    setSemesterForm: React.Dispatch<React.SetStateAction<ISemestersForm>>;
    setFieldValue: (field: string, value: IHoliday[], shouldValidate?: boolean | undefined) => void;
    holidays: IHoliday[];
}) {
    const [form, setForm] = useState<Omit<IHoliday, 'id'>>(initialForm);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const [touched, setTouched] = useState<{ [key in Key]?: boolean }>({});

    const handleSubmit = () => {
        setTouched({});
        const newHolidays = [...holidays, { ...form, id: Math.random().toString(36).slice(2) }];
        setForm(initialForm);
        setFieldValue('holidays', newHolidays);
        setSemesterForm((prev) => ({
            ...prev,
            holidays: newHolidays,
        }));
    };

    return (
        <Dialog
            title="Szünet hozzáadása"
            opener={<NewButton>Szünet hozzáadása</NewButton>}
            action={
                <SubmitButton disabled={!isValidHoliday(form)} onClick={handleSubmit}>
                    Hozzáadás
                </SubmitButton>
            }
        >
            <Form handleChange={handleChange} form={form} touched={touched} setTouched={setTouched} />
        </Dialog>
    );
}
