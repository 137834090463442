import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import DialogBasic from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { CancelButton } from '@silinfo/front-end-template';

export default function BaseDataFormCourseCodeConfirmDialog({
    courseCodeConfirmDialogOpen,
    setCourseCodeConfirmDialogOpen,
    setCourseCodeError,
    isCopy,
    handleSubmit,
}: {
    courseCodeConfirmDialogOpen: boolean;
    setCourseCodeConfirmDialogOpen: Dispatch<SetStateAction<boolean>>;
    setCourseCodeError: Dispatch<SetStateAction<string>>;
    isCopy: boolean;
    handleSubmit: () => void;
}) {
    const msg =
        '                    A megadott kurzuskód egy korábban már más néven felvett kurzushoz kapcsolódik. A kurzuskód\n' +
        '                    elfogadásával közös kurzustörzset hoz létre a rendszer. Biztosan folytatod?';

    return (
        <DialogBasic open={courseCodeConfirmDialogOpen} fullWidth>
            <DialogTitle>Megerősítés</DialogTitle>
            <DialogContent>
                <Grid container>{msg}</Grid>
                <Grid item container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <LoadingButton
                            variant="contained"
                            loading={false}
                            type="submit"
                            onClick={() => {
                                setCourseCodeError('');
                                handleSubmit();
                            }}
                        >
                            Igen
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <CancelButton
                            onClick={() => {
                                setCourseCodeConfirmDialogOpen(false);
                                if (!isCopy) {
                                    setCourseCodeError('Azonos kurzuskódtörzs');
                                }
                            }}
                        >
                            Nem
                        </CancelButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </DialogBasic>
    );
}
