import Cookies from 'universal-cookie';
import { store } from '../store';
import { setLayoutTypeRedux } from '../store/auth';
import { IUser } from './Interfaces/interfaces';
import ROLES from './Roles';
import { LayoutType } from './useSwitchCheck';

const cookies = new Cookies();

export const logout = () => {
    removeLayoutTyper();
    removeJWTToken();
    removeUser();
    removeImpersonationToken();
};

export const isLogin = () => {
    return !!getJWTToken() && !!getUser();
};

export const authUser = () => {
    return getUser() ? JSON.parse(getUser()) : null;
};

export const hasPermission = (permissionToCheck: string) => {
    if (!isLogin() || !authUser()) {
        return false;
    }

    return (
        authUser().permissions &&
        authUser().permissions.filter((permission: string) => {
            return permission === permissionToCheck;
        }).length > 0
    );
};

export const hasRole = (roleToCheck: string) => {
    if (!isLogin() || !authUser()) {
        return false;
    }

    return (
        authUser().role &&
        authUser().role.filter((role: string) => {
            return role === roleToCheck;
        }).length > 0
    );
};

export const getImpersonationToken = () => {
    return cookies.get('impersonation_token');
};

export const setImpersonationToken = (token: string | null) => {
    cookies.set('impersonation_token', token);
};

export const getLastBackendRequestTime = () => {
    return cookies.get('lastBackendRequestTime');
};

export const setLastBackendRequestTime = (value = new Date().getTime()) => {
    cookies.set('lastBackendRequestTime', value);
};

export const removeImpersonationToken = () => {
    cookies.remove('impersonation_token');
};

export const getIsNewLogin = (): string | undefined => {
    return cookies.get('is_new_login');
};

export const setIsNewLogin = (value: string) => {
    cookies.set('is_new_login', value, {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
    });
};

export const getForceShowFirstLoginPopup = (): string | undefined => cookies.get('force_show_firstlogin_popup');

export const setForceShowFirstLoginPopup = (value: string) => {
    cookies.set('force_show_firstlogin_popup', value, {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
    });
};

export const getJWTToken = (): string | undefined => {
    return cookies.get(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt');
};

export const setJWTToken = (token: string | null) => {
    cookies.set(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt', token, {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};

export const removeJWTToken = () => {
    cookies.remove(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt');
};

export const getUser = (): string => {
    return localStorage.getItem('user') ?? '{}';
};

export const removeUser = () => {
    localStorage.removeItem('user');
};

export const getLayoutType = (): LayoutType | undefined => {
    return cookies.get('layout_type');
};
export const setLayoutType = (type: LayoutType) => {
    store.dispatch(setLayoutTypeRedux(type));
};
export const removeLayoutTyper = () => {
    cookies.remove('layout_type', {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};

export const onlyTeacherRole = (user: IUser) => {
    if (
        user.roles.indexOf(ROLES.ADMIN) !== -1 ||
        //user.roles.indexOf(ROLES.TEACHER) !== -1 ||
        user.roles.indexOf(ROLES.COURSE_MANAGER) !== -1 ||
        user.roles.indexOf(ROLES.TRAINING_PROGRAM_LEADER) !== -1 ||
        user.roles.indexOf(ROLES.TRAINING_PROGRAM_ADMIN) !== -1 ||
        user.roles.indexOf(ROLES.EP_COORDINATOR) !== -1 ||
        user.roles.indexOf(ROLES.FINANCIAL_ADMIN) !== -1 ||
        user.roles.indexOf(ROLES.APPROVER_DECISION_MAKER) !== -1 ||
        user.roles.indexOf(ROLES.OPERATOR) !== -1 ||
        user.roles.indexOf(ROLES.STUDENT) !== -1
    ) {
        return false;
    }
    return true;
};

export const onlyCourseManagerRole = (user: IUser) => {
    if (
        user.roles.indexOf(ROLES.ADMIN) !== -1 ||
        user.roles.indexOf(ROLES.TEACHER) !== -1 ||
        //user.roles.indexOf(ROLES.COURSE_MANAGER) !== -1 ||
        user.roles.indexOf(ROLES.TRAINING_PROGRAM_LEADER) !== -1 ||
        user.roles.indexOf(ROLES.TRAINING_PROGRAM_ADMIN) !== -1 ||
        user.roles.indexOf(ROLES.EP_COORDINATOR) !== -1 ||
        user.roles.indexOf(ROLES.FINANCIAL_ADMIN) !== -1 ||
        user.roles.indexOf(ROLES.APPROVER_DECISION_MAKER) !== -1 ||
        user.roles.indexOf(ROLES.OPERATOR) !== -1 ||
        user.roles.indexOf(ROLES.STUDENT) !== -1
    ) {
        return false;
    }
    return true;
};
