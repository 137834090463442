import VisibilityIcon from '@mui/icons-material/Visibility';
import { StudentOperationsCellRendererMobileProps, StudentOperationsCellRendererProps } from '.';
import { STATUS } from '../Status/utils';
import FillQuestionnaire, { FillQuestionnaireMobile } from './FillQuestionnaire';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { endpoints } from '../../../../utils/endPoints';

export default function SelfAssessmentQuestionnaire({ params }: StudentOperationsCellRendererProps) {
    return (
        <FillQuestionnaire
            url={clientEndPoints.student_self_assessment_questionnaire}
            filename="onertekeles.jpg"
            Icon={<VisibilityIcon />}
            status={STATUS.COMPLETED}
            errorStatus={STATUS.WAITING}
            endpoint={endpoints.students.selfEvaluation.form}
            type="selfEvaluation"
            {...params}
        />
    );
}

export function SelfAssessmentQuestionnaireMobile({ params }: StudentOperationsCellRendererMobileProps) {
    return (
        <FillQuestionnaireMobile
            url={clientEndPoints.student_self_assessment_questionnaire}
            filename="onertekeles.jpg"
            Icon={<VisibilityIcon />}
            status={STATUS.COMPLETED}
            errorStatus={STATUS.WAITING}
            endpoint={endpoints.students.selfEvaluation.form}
            type="selfEvaluation"
            {...params}
        />
    );
}
