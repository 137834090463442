import { FieldGenerator } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { IUsersForm } from '../../../../services/masterData/users';
import { Options } from './Form';
import { Option } from '../../../../utils/AppConst';
import ROLES from '../../../../utils/Roles';

interface BaseUsersFormProps {
    props: FormikProps<IUsersForm>;
    options: Options;
    readonly: boolean;
    existsInOtherTenants: boolean;
    setActivated: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EditUsersForm({ props, options, readonly, setActivated }: BaseUsersFormProps) {
    setActivated(props.values.activated);
    return (
        <>
            {FieldGenerator({
                ...props,
                name: 'lastName',
                label: 'Vezetéknév',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'firstName',
                label: 'Keresztnév',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'email',
                label: 'E-mail',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'mccId',
                label: 'MCC azonosító',
                props: {
                    disabled: true,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'campus',
                label: 'Campus',
                type: 'select',
                options: options.campuses,
                fieldType: 'base',
                props: {
                    disabled: readonly,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
            {FieldGenerator({
                ...props,
                name: 'roles',
                label: 'Jogosultságok',
                type: 'multiselect',
                options: options.roles.filter((e: Option) => e.value !== ROLES.STUDENT),
                fieldType: 'base',
                props: {
                    disabled: readonly,
                },
                format: {
                    xs: 12,
                    md: 6,
                },
            })}
        </>
    );
}
