import Grid from '@mui/material/Grid';
import ColorExplanation from './ColorExplanation';
import { colors } from './utils';

export default function ColorExplanations() {
    return (
        <Grid item xs={12} container spacing={2}>
            {colors.map((color) => (
                <ColorExplanation key={color.label} {...color} />
            ))}
        </Grid>
    );
}
